import { Avatar, Box, Typography } from "@mui/material";
import { formatPrice, formatDateRange } from "common/utils";
import DateDisplay from "components/common/DateDisplay";
import useIsMobile from "components/hooks/useIsMobile";
import LinkRouter from "components/navigation/LinkRouter";
import { DollarSign, ExternalLink } from "lucide-react";
import { Invoice } from "protogen/billing_service_pb";

export default ({ invoice }: { invoice: Invoice }) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexGrow: 1,
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            height: "inherit",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            width: "40px",
          }}
        >
          <Avatar
            sx={{
              background: "#F2F4F7",
              color: "#475467",
              textAlign: "center",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "28px",
            }}
          >
            <DollarSign />
          </Avatar>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FAF9FA",
          flexGrow: 1,
          borderRadius: "16px",
          padding: "16px 24px",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>Invoice Paid</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "4px", flexDirection: "column" }}>
            <DateDisplay
              date={new Date(Number(invoice.createdSec) * 1000)}
              sx={{ fontSize: "14px", color: "#6B6E7B" }}
            />
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Typography
            variant="body"
            sx={{ fontWeight: 500, marginRight: "4px" }}
          >
            {formatPrice(invoice.invoiceAmountCents, invoice.invoiceCurrency)}
          </Typography>
          <Typography variant="body">
            {invoice.periodStartSec
              ? `(${formatDateRange(
                  invoice.periodStartSec,
                  invoice.periodEndSec,
                )})`
              : ""}
          </Typography>
        </Box>
        <LinkRouter
          targetNew={true}
          to={`https://dashboard.stripe.com/invoices/${invoice.stripeId}`}
          sx={{ alignItems: "center", fontWeight: 500, fontSize: "14px" }}
        >
          Stripe <ExternalLink size={16} style={{ marginLeft: "8px" }} />
        </LinkRouter>
      </Box>
    </Box>
  );
};
