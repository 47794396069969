import { FeedEntry, Medium } from "protogen/advisors_service_pb";
import { IconButton, Tooltip } from "@mui/material";

import { DocumentType } from "protogen/documents_pb";
import { useNavigate } from "react-router-dom";
import { FilePlus, FileSymlink } from "lucide-react";
import { useCopyDocument } from "services/documents";
import { CopyDocumentRequest } from "protogen/documents_service_pb";
import { format } from "date-fns";

const SuggestedAttachmentAction = ({
  entry,
  familyRef,
}: {
  entry: FeedEntry;
  familyRef: string;
}) => {
  const navigate = useNavigate();
  const { request: copyDocumentRequest, loading: copyDocumentLoading } =
    useCopyDocument();

  // Only handle the first suggestion for now
  const suggestion = entry.textMessage?.documentSuggestion;

  const copyDocument = async () => {
    let title = "Note";
    if (suggestion?.name) {
      title = suggestion.name;
    } else if (entry.textMessage?.timestampSec) {
      title = `Text on ${format(
        new Date(Number(entry.textMessage?.timestampSec) * 1000),
        "MMMM d, yyyy",
      )}`;
    }
    const result = await copyDocumentRequest(
      new CopyDocumentRequest({
        fromDocumentType: DocumentType.TEXT_MESSAGE,
        fromEntityType: "text-message",
        fromEntityRef: entry.ref,
        toDocumentType: DocumentType.NOTE,
        toEntityType: "family",
        toEntityRef: familyRef,
        title: title,
        replaceTitle: true,
        shouldClearOriginalContent: false,
        creationSourceName: "text-message",
      }),
    );
    if (result?.document?.ref) {
      navigate(
        `/documents/${encodeURIComponent(
          result?.document?.ref || "",
        )}?created=1`,
      );
    }
  };
  return (
    <Tooltip title="Save as a document">
      <IconButton
        sx={{
          paddingRight: 0,
          paddingLeft: 0,
        }}
        disabled={copyDocumentLoading}
        onClick={copyDocument}
      >
        <FilePlus />
      </IconButton>
    </Tooltip>
  );
};

const ExistingAttachmentAction = ({ documentRef }: { documentRef: string }) => {
  const navigate = useNavigate();
  return (
    <Tooltip title="View document">
      <IconButton
        onClick={() => {
          navigate(`/documents/${encodeURIComponent(documentRef)}`);
        }}
      >
        <FileSymlink />
      </IconButton>
    </Tooltip>
  );
};

const actionForEntry = (entry: FeedEntry, familyRef?: string) => {
  if (entry.medium === Medium.SMS && entry.textMessage?.attachments.length) {
    // Handle existing case.
    if (entry.textMessage?.documentSuggestion?.documentRef) {
      return (
        <ExistingAttachmentAction
          documentRef={entry.textMessage?.documentSuggestion?.documentRef}
        />
      );
    }
    if (familyRef) {
      return <SuggestedAttachmentAction entry={entry} familyRef={familyRef} />;
    }
  }
  return null;
};

export { actionForEntry };
