import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import {
  useAssignEmailToInbox,
  useFetchUnassignedEmails,
} from "services/email";
import Loading from "components/common/Loading";
import { FetchUnassignedEmailsResponse_UnassignedEmail as UnassignedEmail } from "protogen/email_service_pb";
import DateDisplay from "components/common/DateDisplay";

import { Alert, Typography } from "@mui/material";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";
import EmailAttachmentsTable from "components/activity/EmailAttachmentsTable";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate } from "react-router-dom";
import GridPage from "components/layout/GridPage";
import EmailLetter from "components/email/EmailLetter";
import { useAdminListAllAdvisors } from "services/admin";
import { Advisor } from "protogen/common_pb";
import useIsMobile from "components/hooks/useIsMobile";
import SearchAdvisorSelect from "components/common/SearchAdvisorSelect";

const InboxAssigner = ({
  entry,
  advisors,
  addMessage,
  disabled,
}: {
  entry: UnassignedEmail;
  advisors: Advisor[];
  addMessage: (m: SnackbarMessage) => void;
  disabled?: boolean;
}) => {
  const [selectedAdvisor, setSelectedAdvisor] = useState<string | null>(null);
  const { request, loading } = useAssignEmailToInbox((r) => {
    addMessage({
      key: r?.email?.ref || "",
      message: `Assigned email to advisor inbox ${selectedAdvisor}`,
    });
  });

  const onAssign = () => {
    if (selectedAdvisor && entry.email?.ref) {
      request({
        emailRef: entry.email.ref,
        inboxType: "advisor",
        inboxEntityRef: selectedAdvisor,
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <SearchAdvisorSelect
        selectedAdvisor={selectedAdvisor}
        onChange={setSelectedAdvisor}
        title="Select Advisor"
        advisors={advisors}
        disabled={disabled}
      />
      <IconButton
        color="primary"
        aria-label="assign"
        disabled={loading || !selectedAdvisor || disabled}
        onClick={onAssign}
      >
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};

const EmailRow = ({
  entry,
  advisors,
  addMessage,
  initOpen = false,
}: {
  entry: UnassignedEmail;
  advisors: Advisor[];
  addMessage: (m: SnackbarMessage) => void;
  initOpen: boolean;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(initOpen);
  const [isAssigning, setIsAssigning] = useState(false);
  const time = entry.email?.timestampSec
    ? DateDisplay({
        date: new Date(Number(entry.email.timestampSec) * 1000),
      })
    : "";

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!open && entry.email?.ref) {
                const newQueryParams = new URLSearchParams();
                newQueryParams.set("ref", entry.email.ref);
                navigate({ search: newQueryParams.toString() });
              }
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ maxWidth: "200px" }}>
          <Box>
            <Typography variant="bodyHeavy" noWrap>
              {entry.toAddress}
            </Typography>
            <Typography variant="caption" color="text.secondary" noWrap>
              From: {entry.fromAddress}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Typography noWrap>
            <i>{entry.email?.subject}</i>
          </Typography>
        </TableCell>
        <TableCell>{time}</TableCell>
        <TableCell sx={{ width: "300px", minWidth: "300px" }}>
          <InboxAssigner
            entry={entry}
            advisors={advisors}
            addMessage={(message) => {
              setIsAssigning(true);
              addMessage(message);
            }}
            disabled={isAssigning}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: "2vw",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                padding: "12px 12px 12px calc(142px)",
                borderRadius: "10px",
              }}
            >
              {entry.email && <EmailLetter email={entry.email} />}
            </Box>
            {entry.email?.attachments && entry.email.attachments.length > 0 && (
              <EmailAttachmentsTable attachments={entry.email.attachments} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const {
    data: emailData,
    request: emailRequest,
    loading,
  } = useFetchUnassignedEmails();
  const { data: advisorsData, request: advisorsRequest } =
    useAdminListAllAdvisors();
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedRef = queryParams.get("ref") || "";

  useEffect(() => {
    emailRequest({
      cursor: "",
    });
    advisorsRequest({});
  }, []);

  if (loading || emailData === null) {
    return <Loading />;
  }

  const allAdvisors = advisorsData ? advisorsData.advisors : [];
  return (
    <GridPage
      sx={{
        margin: isMobile ? "" : "64px auto",
        maxWidth: "1000px",
        padding: isMobile ? "16px" : "0",
      }}
    >
      <Typography variant="title" id="tableTitle" sx={{ mb: 2 }}>
        Lost Emails
      </Typography>
      <Alert severity="info" sx={{ mb: 2 }}>
        These are emails that were received by our system without being matched
        to a family. If an email was sent from an alternate address from one of
        your families, consider adding the alias on the family's profile page.
      </Alert>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <SnackPack snackPack={snackPack} />
        {emailData.emails.length === 0 && (
          <Alert severity="info">No unassigned emails.</Alert>
        )}
        {emailData.emails.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "48px", flex: "0 0 48px" }} />
                  <TableCell sx={{ flex: "1 1 0%", minWidth: 0 }}>To</TableCell>
                  <TableCell sx={{ flex: "2 2 0%", minWidth: 0 }}>
                    Subject
                  </TableCell>
                  <TableCell sx={{ flex: "0.5 0.5 0%", minWidth: 0 }}>
                    Received
                  </TableCell>
                  <TableCell sx={{ flex: "1 1 0%", minWidth: 0 }}>
                    Assign
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {emailData.emails.map((entry) => (
                  <EmailRow
                    key={entry.email?.ref || ""}
                    entry={entry}
                    advisors={allAdvisors}
                    addMessage={(message: SnackbarMessage) => {
                      setSnackPack((prev) => [...prev, message]);
                    }}
                    initOpen={selectedRef === entry.email?.ref}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </GridPage>
  );
};
