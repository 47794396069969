import { useEffect, useRef, useState, useMemo, useContext } from "react";
import useIsVisible from "components/hooks/useIsVisible";
import { ScrollContainerHandle } from "components/common/ScrollContainer";
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useGetConversationFeed,
  useUpdateExternalContact,
  useGetExternalContactPhone,
} from "services/advisor";
import {
  FeedEntry,
  GetConversationFeedResponse,
} from "protogen/advisors_service_pb";
import {
  AccountStub,
  AccountType,
  Attachment,
  Member,
} from "protogen/common_pb";
import {
  dedupeFeedEntries,
  sortEntryTimestamps,
} from "components/activity/utils";
import FeedEntryComponent from "components/activity/FeedEntryComponent";
import ScrollableActivityPanel from "components/details-page/ScrollableActivityPanel";
import AttachmentDialog from "components/common/AttachmentDialog";
import TextMessageCompose from "components/activity/TextMessageCompose";
import PhoneCallInstantiation from "components/activity/PhoneCallInstantiation";
import FaceIcon from "@mui/icons-material/Face";
import { insertTaskSuggestion } from "../activity/Suggestions";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "../activity/SuggestionActions";
import InboxThreadHeader from "../inbox/InboxThreadHeader";
import { ConversationStarter } from "../creation/StartExternalConversation";
import { CurrentUserContext } from "../context/RequireAuth";
import { commaSeparatedEnglishList } from "../../common/utils";
import { useLocation } from "react-router-dom";
import { Check, Pencil, Phone } from "lucide-react";
import useIsMobile from "components/hooks/useIsMobile";

const AccountChip = ({ account }: { account: AccountStub }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(account.displayName);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const editable = account.accountType === AccountType.CONTACT;
  const { request, loading } = useUpdateExternalContact();
  const { request: getPhone } = useGetExternalContactPhone((r) => {
    setPhoneNumber(r.phoneNumber);
  });

  useEffect(() => {
    if (editable) {
      getPhone({ contactRef: account.ref });
    }
  }, [editable, account.ref]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const onSave = async () => {
    if (label !== account.displayName) {
      await request({
        contactRef: account.ref,
        updatedDisplayName: label,
      });
    }
    setIsEditing(false);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSave();
    } else if (event.key === "Escape") {
      setIsEditing(false);
    }
  };
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      {isEditing ? (
        <TextField
          disabled={loading}
          value={label}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          size="small"
          autoFocus
          sx={{
            width: "auto",
            maxWidth: 200,
            margin: 0,
            padding: 0,
            "& .MuiInputBase-root": {
              padding: "8px 12px",
              minHeight: "unset",
            },
            input: {
              padding: "0",
              margin: "0",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  paddingRight: "2px !important",
                  paddingTop: "2px !important",
                }}
              >
                <IconButton onClick={onSave} size="small" disabled={loading}>
                  <Check />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Tooltip title={phoneNumber} placement="top">
          <Chip
            icon={<FaceIcon />}
            label={label}
            onClick={() => editable && setIsEditing(true)}
            sx={{
              padding: "10px",
              height: "40px",
              borderRadius: "20px",
              ".MuiChip-deleteIcon": {
                height: "24px",
                width: "24px",
              },
            }}
            deleteIcon={
              editable ? (
                <IconButton onClick={handleEditClick} size="small">
                  <Pencil
                    style={{
                      height: "24px",
                      width: "24px",
                      marginRight: "8px",
                      marginLeft: "2px",
                    }}
                  />
                </IconButton>
              ) : undefined
            }
            onDelete={editable ? handleEditClick : undefined}
          />
        </Tooltip>
      )}
    </div>
  );
};

const MessageFeed = ({
  conversationRef,
  onUpdates,
}: {
  conversationRef: string;
  onUpdates: () => void;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const scrollRef = useRef<ScrollContainerHandle | null>(null);
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const hasLoadedOnce = useRef(false);
  const [localEntries, setLocalEntries] = useState<FeedEntry[]>([]);
  const [feedCursor, setFeedCursor] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [entries, setEntries] = useState<FeedEntry[]>([]);
  const [accountMap, setAccountMap] = useState<Record<string, AccountStub>>({});
  const [callModalOpen, setCallModalOpen] = useState<boolean>(false);
  const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
  const [contact, setContact] = useState<AccountStub | null>(null);
  const isMobile = useIsMobile();
  const updateEntries = (r: GetConversationFeedResponse) => {
    hasLoadedOnce.current = true;
    setContact(r.contact);
    setEntries((pastEntries) => {
      return dedupeFeedEntries(
        [...r.entries, ...pastEntries].sort(sortEntryTimestamps),
      );
    });
    setAccountMap((pastMap) => {
      return {
        ...pastMap,
        ...r.accounts.reduce(
          (acc: Record<string, AccountStub>, account: AccountStub) => {
            acc[account.ref] = account;
            return acc;
          },
          {},
        ),
      };
    });
    if (!r.hasMore) {
      // End of the line, currently you can't get out of this state
      setHasMore(false);
    }
    // Don't blow away a good cursor on a refresh If the prev request used a cursor update the next one OR if
    // we haven't set one yet.
    if ((r.startCursor || !feedCursor) && feedCursor !== r.nextCursor) {
      setFeedCursor(() => r.nextCursor);
    }
  };
  const { request } = useGetConversationFeed(updateEntries);

  const refresh = async (feedCursor?: string) => {
    await request({
      conversationRef,
      startCursor: feedCursor || "",
      // Only mark conversations as read if the feed is visible.
      markConversationsRead: isVisible,
    });
  };
  const { isVisible } = useIsVisible({
    onRefocus: async (blurSecs) => {
      if (blurSecs > 10) {
        await refresh();
      }
    },
  });
  useEffect(() => {
    if (!hasLoadedOnce.current) {
      refresh();
    }
    const intervalId = setInterval(async () => {
      if (isVisible) {
        await refresh();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [conversationRef]);

  useEffect(() => {
    setLocalEntries([]);
    setFeedCursor("");
    setHasMore(true);
    setEntries([]);
    setAccountMap({});
    refresh();
  }, [conversationRef]);

  // Dedupe.
  const allEntries = dedupeFeedEntries(
    [...entries, ...localEntries].sort(sortEntryTimestamps),
  );
  const otherContacts = Object.entries(accountMap)
    .map(([_, v]) => v)
    .filter((v) => v.ref !== currentUser.ref);
  const createFeedEntryComponent = (e: FeedEntry, i: number): JSX.Element => {
    return (
      <FeedEntryComponent
        key={`${e.medium}-${e.ref}`}
        entry={e}
        feedFocusState={{
          // Arggh.
          selectedContact: new Member({
            ref: contact?.ref || "",
            firstName: contact?.firstName || "",
            lastName: contact?.lastName || "",
            displayName: contact?.displayName || "",
            avatarUrl: contact?.avatarUrl || "",
            primaryPhone: "",
            primaryEmail: "",
            alternateEmails: [],
          }),
          selectedMedium: null,
          selectedEntryKey: null,
          replyToEmail: null,
          editEmailDraft: null,
          isDirty: false,
          groupText: false,
        }}
        setFeedFocusState={() => {}}
        accountMap={accountMap}
        openAttachment={(attachment) => {
          setOpenAttachment(attachment);
        }}
        // Be confused! We sort these in reverse from our CSS rendering which makes life more confusing.
        nextEntry={allEntries[i - 1]}
        prevEntry={allEntries[i + 1]}
      />
    );
  };
  const scrollEntries = useMemo(() => {
    return insertTaskSuggestion(
      allEntries,
      {
        suggestTask: actionsRef.current?.suggestTask || (() => {}),
        suggestFact: actionsRef.current?.suggestFact || (() => {}),
        suggestEvent: actionsRef.current?.suggestEvent || (() => {}),
      },
      createFeedEntryComponent,
      { marginLeft: "54px" },
    );
  }, [entries, localEntries]);

  const addFeedEntry = (entry: FeedEntry) => {
    setLocalEntries([...localEntries, entry]);
    scrollRef.current?.setSticky();
    refresh();
    onUpdates();
  };
  const textsDisabled = otherContacts.some(
    (r) => r.accountType === AccountType.CONTACT && !r.canText,
  );

  return (
    <>
      <ScrollableActivityPanel
        scrollEntries={scrollEntries}
        fetchScrollEntries={() => refresh(feedCursor)}
        hasMoreScrollEntries={hasMore}
        scrollRef={scrollRef}
        headerPanel={
          <InboxThreadHeader
            sx={{
              marginTop: isMobile ? "12px" : "0px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                {otherContacts.map((c) => (
                  <AccountChip key={c.ref} account={c} />
                ))}
              </Box>
              <Button
                sx={{
                  border: "1px solid #D4D4D4",
                  borderRadius: "5px",
                  padding: "8px 24px !important",
                  color: "#6B6E7B",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                }}
                startIcon={<Phone height="20px" width="20px" />}
                variant="text"
                size="small"
                onClick={() => setCallModalOpen(true)}
              >
                Call
              </Button>
            </Box>
          </InboxThreadHeader>
        }
        footerPanel={
          <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
            {contact && (
              <TextMessageCompose
                forceDisabled={textsDisabled ? true : undefined}
                familyRef={null}
                recipientRefs={otherContacts.map((c) => c.ref)}
                contactEnabled={true}
                placeholder={
                  textsDisabled
                    ? "Cannot text landline phone numbers"
                    : `Message ${commaSeparatedEnglishList(
                        otherContacts.map((c) => c.firstName),
                      )}`
                }
                onSent={addFeedEntry}
              />
            )}
          </Box>
        }
        sx={{ height: "100%", padding: "0px" }}
      />
      <AttachmentDialog
        attachment={openAttachment}
        onClose={() => setOpenAttachment(null)}
      />
      <PhoneCallInstantiation
        closed={!callModalOpen}
        onClose={() => setCallModalOpen(false)}
        recipientRef={contact?.ref || ""}
        onStarted={addFeedEntry}
      />
      <SuggestionActions ref={actionsRef} familyRef={""} />
    </>
  );
};

interface MessageThreadProps {
  entryRef: string | undefined;
  onUpdates: () => void;
  isNewConversation: boolean;
}

export default ({
  entryRef,
  onUpdates,
  isNewConversation,
}: MessageThreadProps) => {
  // Handle /inbox/phone/create?phone=foo@bar.com
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (isNewConversation && !entryRef) {
    return (
      <ConversationStarter
        initialPhoneNumber={queryParams.get("phone") || null}
        initialPhoneDisplayName={queryParams.get("displayName") || null}
        initialPhoneFirstName={queryParams.get("firstName") || null}
        initialPhoneLastName={queryParams.get("lastName") || null}
      />
    );
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <MessageFeed conversationRef={entryRef || ""} onUpdates={onUpdates} />
    </Box>
  );
};
