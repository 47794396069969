import { Box, Button } from "@mui/material";
import CalendlyEmbed from "../components/CalendlyEmbed";
import { forwardRef, useImperativeHandle } from "react";
import { StepProps, StepRef } from "../types";
import useIsMobile from "../../hooks/useIsMobile";
import { StepHeader } from "../components/StepHeader";

const CALENDLY_CALENDAR = process.env.REACT_APP_CALENDLY_URL!;

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }, ref) => {
    useImperativeHandle(ref, () => ({}));
    const isMobile = useIsMobile();
    const hasAppointment = signupState?.consultation?.inviteeUri;

    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: hasAppointment ? "space-around" : "center",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <StepHeader
            title={
              hasAppointment ? "Reschedule your call" : "Schedule your call"
            }
          />

          {hasAppointment && (
            <Button
              variant={isMobile ? "text" : "outlined"}
              disabled={disabled}
              onClick={() => {
                onNext?.({
                  consultation: signupState.consultation,
                });
              }}
            >
              Continue
            </Button>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <CalendlyEmbed
            prefill={{
              email: signupState?.consultationDetails?.email,
              name: `${signupState?.consultationDetails?.firstName} ${signupState?.consultationDetails?.lastName}`,
              customAnswers: {
                a1: signupState?.consultationDetails?.phone,
              },
            }}
            schedulingUrl={CALENDLY_CALENDAR}
            scheduledEventInviteeUrl={signupState?.consultation?.inviteeUri}
            styles={{
              width: "100%",
              height: isMobile ? "925px" : "80vh",
              display: "flex",
              justifyContent: "center",
            }}
            pageSettings={{
              backgroundColor: "white",
              textColor: "#3d3d3d",
              primaryColor: "#198282",
              hideGdprBanner: true,
              hideLandingPageDetails: true,
              hideEventTypeDetails: true,
            }}
            onCompletion={(event) => {
              onNext?.({
                consultation: {
                  eventUri: event.event.uri,
                  inviteeUri: event.invitee.uri,
                },
              });
            }}
          />
        </Box>
      </Box>
    );
  },
);
