import ReactiveDialog from "../common/ReactiveDialog";
import { Alert, Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { useActivateFamily } from "../../services/advisor";
import { Family } from "../../protogen/advisors_service_pb";

type Props = {
  family: Family;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export default ({ family, open, setOpen }: Props) => {
  const [dateOpen, setDateOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(
    family.startDate ? new Date(family.startDate) : null,
  );
  const { request, loading } = useActivateFamily();
  const today = new Date();

  const onActivation = async () => {
    if (!date) return;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const startDate = `${year}-${month}-${day}`;
    const resp = await request({ familyRef: family.ref, startDate });
    if (resp) {
      setOpen(false);
      // refresh the page. Hamfisted, but this is an infrequent operation.
      window.location.reload();
    }
  };
  const billingFields =
    family.billingInfo?.product &&
    family.platformFeeSchedule &&
    family.billingInfo?.stripeCustomerId;
  const enabled = !!date && !loading && billingFields;
  return (
    <ReactiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title={"Start service"}
      primaryActionName={"Activate"}
      primaryActionEnabled={enabled}
      primaryAction={onActivation}
      fullWidthSize={"sm"}
    >
      <Box display="flex" flexDirection="column" gap="12px">
        {!billingFields && (
          <Alert severity="warning">
            To activate a client, a product, platform fee schedule, and Stripe
            Customer ID must all be present.
          </Alert>
        )}
        <Typography variant="bodyHeavy">
          This will be your client's billing start date. If needed, please
          adjust to the day in which you actually started work (solving tasks,
          holding the onboarding call, etc).
        </Typography>
        <DatePicker
          sx={{
            width: "100%",
            ".MuiButtonBase-root": { marginRight: "unset" },
          }}
          label="Start date"
          defaultValue={date}
          disabled={loading}
          onChange={(d) => setDate(d)}
          format="MM/dd/yyyy"
          open={dateOpen}
          maxDate={today}
          // today - three weeks
          minDate={new Date(today.getTime() - 1000 * 60 * 60 * 24 * 21)}
          onClose={() => setDateOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setDateOpen(true),
            },
            inputAdornment: {
              onClick: () => setDateOpen(true),
            },
          }}
        />
      </Box>
    </ReactiveDialog>
  );
};
