import { ReactElement, useRef, useState } from "react";
import useIsMobile from "components/hooks/useIsMobile";
import { Box, Typography, Card, CardContent, TextField } from "@mui/material";
import { ChoiceSelections } from "./../types";
import { StepHeader } from "../components/StepHeader";
import { NextStepButton } from "./NextStepButton";

export type Choice = {
  id: string;
  header?: string;
  text: string;
  icon?: ReactElement;
  freeform?: boolean;
  fullWidth?: boolean;
  freeformPlaceholder?: string;
  isSingleChoice?: boolean;
};

const cardSx = (selected: boolean, fullWidth?: boolean) => {
  return {
    cursor: "pointer",
    bgcolor: selected ? "#198282" : "white",
    color: selected ? "white" : "inherit",
    transition: "background-color 0.3s",
    borderRadius: "16px",
    border: "1px solid #EAEBEC",
    boxShadow: "none",
    gridColumn: fullWidth ? "1 / -1" : "",

    "& svg": { color: selected ? "white" : "#8E9598" },

    "&:hover": {
      bgcolor: selected ? "#276767" : "white",
      boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    },
  };
};

const cardContentSx = (header: boolean) => {
  const isMobile = useIsMobile();
  return {
    display: "flex",
    flexDirection: header ? "row" : "column",
    alignItems: header ? "start" : "center",
    alignSelf: "center",
    height: "100%",
    justifyContent: "center",
    gap: isMobile || !header ? "0px" : "12px",
    padding: isMobile ? "20px 12px !important" : "24px",
  };
};

const ChoiceCard = ({
  choice,
  value,
  onSelected,
  fullWidth,
}: {
  choice: Choice;
  value: boolean;
  onSelected: (v: boolean) => void;
  fullWidth?: boolean;
}) => {
  return (
    <Card
      onClick={!choice.freeform ? () => onSelected(!value) : undefined}
      sx={cardSx(value, fullWidth)}
    >
      <CardContent sx={cardContentSx(choice.header != null)}>
        <Box sx={{ width: "24px", "& svg": { width: "24px" } }}>
          {choice.icon}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          {choice.header && (
            <Typography variant="bodyHeavy" color="inherit">
              {choice.header}
            </Typography>
          )}
          <Typography
            variant="body"
            sx={{
              userSelect: "none",
              color: "inherit",
              textAlign: !choice.header ? "center" : "",
            }}
          >
            {choice.text}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const FreeformChoiceCard = ({
  choice,
  value,
  fullWidth = false,
  onSelected,
}: {
  choice: Choice;
  value?: string;
  fullWidth?: boolean;
  onSelected: (v: boolean | string) => void;
}) => {
  const [freeformValue, setFreeformValue] = useState(value || "");
  const textFieldRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const handleBlur = () => {
    onSelected(freeformValue.trim() || false);
    setHighlighted(freeformValue.trim().length > 0);
  };
  const [highlighted, setHighlighted] = useState(!!value);

  return (
    <Card
      onClick={() => textFieldRef.current?.focus()}
      sx={cardSx(highlighted, fullWidth)}
    >
      <CardContent sx={cardContentSx(choice.header != null)}>
        {choice.icon}
        <TextField
          fullWidth
          multiline
          inputRef={textFieldRef}
          variant="standard"
          placeholder={choice.text}
          defaultValue={value ? value : ""}
          onChange={(e) => {
            setFreeformValue(e.target.value);
            onSelected(e.target.value);
          }}
          onBlur={handleBlur}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            cursor: "pointer",

            "& .MuiInputBase-root": {
              color: "inherit",
              fontSize: "1rem",
              fontWeight: "500",
              "& textarea": { textAlign: "center" },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export interface Props {
  title: string;
  subtitle: string;
  onNext: (choices: ChoiceSelections) => Promise<void>;
  choiceOptions: Choice[];
  columns?: number;
  initialChoices?: ChoiceSelections;
  disabled: boolean;
  skippable?: boolean;
  singleSelectStep?: boolean;
}

export default ({
  title,
  subtitle,
  onNext,
  choiceOptions,
  columns = 2,
  initialChoices,
  disabled,
  skippable,
  singleSelectStep = false,
}: Props) => {
  const [selected, setSelected] = useState<ChoiceSelections>(
    initialChoices || {},
  );
  const [loading, setLoading] = useState(false);

  const isMobile = useIsMobile();
  const hasFullWidthChoice = choiceOptions.filter((choice) => choice.fullWidth);

  const handleSelect = (
    id: string,
    v: string | boolean,
    isSingleChoice: boolean = false,
  ) => {
    setSelected((prev) => {
      if (isSingleChoice) {
        return { [id]: v };
      }
      const newSelection = { ...prev };

      // Check for any options with isSingleChoice
      choiceOptions.forEach((option) => {
        if ((singleSelectStep || option.isSingleChoice) && option.id !== id) {
          // Remove the option from the selection if it is not the option being selected
          delete newSelection[option.id];
        }
      });

      if (!v) {
        delete newSelection[id];
      } else {
        newSelection[id] = v;
      }
      return newSelection;
    });
  };
  const stackedLayout = choiceOptions[0].header && isMobile;
  const handleContinue = async () => {
    setLoading(true);
    try {
      await onNext(selected);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
      }}
    >
      <StepHeader title={title} subtitle={subtitle} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: stackedLayout
            ? "repeat(1, 1fr)"
            : isMobile
              ? "repeat(2, 1fr)"
              : `repeat(${columns}, 1fr)`,
          gap: isMobile ? "12px" : "24px",
          marginBottom: isMobile ? "90px" : "",
          alignItems: "stretch",
          width: "100%",
          maxWidth: "850px",
        }}
      >
        {choiceOptions.map((choice) =>
          choice.freeform ? (
            <FreeformChoiceCard
              key={choice.id}
              onSelected={(v) => handleSelect(choice.id, v)}
              value={
                choice.id in selected
                  ? (selected[choice.id] as string)
                  : undefined
              }
              choice={choice}
              fullWidth={choice.fullWidth}
            />
          ) : (
            <ChoiceCard
              key={choice.id}
              onSelected={(v) =>
                handleSelect(choice.id, v, choice.isSingleChoice)
              }
              value={!!selected[choice.id]}
              choice={choice}
              fullWidth={choice.fullWidth}
            />
          ),
        )}

        {/* Continue button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            gridColumn:
              hasFullWidthChoice || choiceOptions.length % columns == 0
                ? "span " + columns
                : "auto",
            position: isMobile ? "fixed" : "relative",
            bottom: isMobile ? "0px" : "",
            width: isMobile ? "calc(100% - 32px)" : "auto",
            backgroundColor: "#FDFAF7",
            paddingBottom: isMobile ? "22px" : "0px",
          }}
        >
          <NextStepButton
            skippable={skippable}
            continueClick={handleContinue}
            disabled={loading || disabled}
            hasValue={Object.keys(selected).length > 0}
          />
        </Box>
      </Box>
    </Box>
  );
};
