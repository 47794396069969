import { useEffect } from "react";

export default (pixelId?: string) => {
  useEffect(() => {
    if (!pixelId) {
      return;
    }

    // Load the Facebook Pixel tracking script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://connect.facebook.net/en_US/fbevents.js";
    document.head.appendChild(script);

    // Facebook Pixel setup script
    const pixelSetupScript = document.createElement("script");
    pixelSetupScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelId}');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(pixelSetupScript);

    // Add noscript fallback for tracking without JavaScript
    const noscriptFallback = document.createElement("noscript");
    noscriptFallback.innerHTML = `
      <img height="1" width="1" style="display:none"
           src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
    `;
    document.body.appendChild(noscriptFallback);

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(pixelSetupScript);
      document.body.removeChild(noscriptFallback);
    };
  }, [pixelId]);
};
