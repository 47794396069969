import * as React from "react";
import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import AuthService from "services/auth";
import { validatePassword } from "common/utils";
import { CurrentUser } from "protogen/auth_pb";
import PasswordInput from "components/common/PasswordInput";
import { useGlobalModalContext } from "components/context/GlobalModalContext";

const NewPasswordModal = ({ user }: { user?: CurrentUser }) => {
  const { setModalOpen } = useGlobalModalContext();
  const [password, setPassword] = useState("");
  const { request, error } = AuthService.useUpdatePassword();
  const [open, setOpen] = React.useState(true);
  const onClick = async () => {
    const errors = {
      ...validatePassword(password),
    };
    if (Object.keys(errors).length > 0) {
      return;
    }

    const resp = await request({ password });
    if (resp) {
      localStorage.removeItem("isUrlLogin");
      setOpen(false);
    }
    useEffect(() => {
      setModalOpen(open);
      return () => setModalOpen(false);
    }, [open, setModalOpen]);
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 1301 }}
    >
      <DialogTitle id="alert-dialog-title">Set up your password</DialogTitle>
      <DialogContent>
        <Typography variant="bodySmall">
          You now have the option to use a password for login. Would you like to
          set one up now?
        </Typography>
        <form onSubmit={onClick} style={{ width: "100%" }}>
          <FormControl fullWidth style={{ marginTop: 16 }}>
            <TextField
              disabled={true}
              sx={{ flexGrow: 1 }}
              autoFocus
              label="Username"
              name="username"
              type="text"
              fullWidth
              autoComplete="on"
              variant="outlined"
              value={user?.email}
            />
          </FormControl>
          <PasswordInput
            password={password}
            onChange={(pw) => {
              setPassword(pw);
            }}
            sx={{ marginTop: "16px" }}
          />
        </form>
        {error && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
            localStorage.removeItem("isUrlLogin");
          }}
        >
          Skip
        </Button>
        <Button onClick={onClick}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export const showNewPasswordModal = () => {
  const currentUser = AuthService.getCurrentUser();
  const isPasswordSet = currentUser?.isPasswordSet;
  const isUrlLogin = localStorage.getItem("isUrlLogin");
  return !isPasswordSet && isUrlLogin;
};

export default () => {
  const currentUser = AuthService.getCurrentUser();
  return (
    <>{showNewPasswordModal() && <NewPasswordModal user={currentUser} />}</>
  );
};
