import { Avatar, Box, Button, Typography } from "@mui/material";
import DateDisplay from "components/common/DateDisplay";
import useIsMobile from "components/hooks/useIsMobile";
import { Bird } from "lucide-react";
import { AnnotationFlags } from "protogen/annotations_pb";
import { useState } from "react";
import { FlagEntry } from "../FlagsSection";
import { sortFlags } from "../utils";

export default ({ flags }: { flags: AnnotationFlags[] }) => {
  const isMobile = useIsMobile();
  const [showAll, setShowAll] = useState(false);
  if (!flags.length) return null;
  const firstFlag = flags[0];
  const hasMore = flags.filter((f) => !f.active).length > 0;
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexGrow: 1,
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            height: "inherit",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            width: "40px",
          }}
        >
          <Avatar
            sx={{
              background: "#F2F4F7",
              color: "#475467",
              textAlign: "center",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "28px",
            }}
          >
            <Bird />
          </Avatar>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FAF9FA",
          flexGrow: 1,
          borderRadius: "16px",
          padding: "16px 24px",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>Automated Flags</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "4px", flexDirection: "column" }}>
            <DateDisplay
              date={new Date(Number(firstFlag.lastUpdatedSec) * 1000)}
              sx={{ fontSize: "14px", color: "#6B6E7B" }}
            />
          </Box>
        </Box>
        <Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"8px"}
            alignItems={"start"}
            sx={{
              transition: "height 0.3s",
            }}
          >
            {sortFlags(flags, showAll).map((flag) => (
              <FlagEntry key={flag.name} flag={flag} />
            ))}
            {hasMore && !showAll && (
              <Button onClick={() => setShowAll(true)} variant="text">
                Show all
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
