// helps you detect mobile browsers (to show a relevant message as the process of installing your PWA changes from browser to browser)
import { format, formatDistance } from "date-fns";

const isMobileAndroid = () => {
  return !!navigator.userAgent.match(/Android/i);
};

const isMobileSamsung = () => {
  return !!navigator.userAgent.match(
    /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
  );
};

const isMobileWindows = () => {
  return !!(
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/WPDesktop/i)
  );
};

const isMobileiOS = () => {
  return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

const isStandaloneWebapp = (): boolean => {
  return "standalone" in window.navigator && !!window.navigator.standalone;
};

const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const isYesterday = (date: Date): boolean => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

const isThisPastWeek = (date: Date): boolean => {
  const thisWeek = new Date();
  thisWeek.setDate(thisWeek.getDate() - 7);
  return date >= thisWeek;
};

const dateObjectToshortDate = (dateObj: Date): string => {
  const currentYear = new Date().getFullYear();

  // If it's the current year, omit the year; otherwise include it
  if (dateObj.getFullYear() === currentYear) {
    return format(dateObj, "MMM d");
  } else {
    return format(dateObj, "MMM d, yyyy");
  }
};

const relativeTime = (date: Date): string => {
  const relativeDateStr = formatDistance(date, new Date(), { addSuffix: true });
  return relativeDateStr.split(" ").slice(-3).join(" ");
};

const absoluteTime = (date: Date): string => {
  return format(date, "h:mmaaa MMMM d, yyyy");
};

const formatDateForFilename = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}_${hours}-${minutes}`;
};

const getFormattedDuration = (
  timestamp1: number,
  timestamp2?: number,
  noSeconds?: boolean,
) => {
  // Calculate duration
  const duration = timestamp2 ? Math.abs(timestamp2 - timestamp1) : timestamp1;
  // Convert to seconds
  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor((duration / 60) % 60);
  const hours = Math.floor((duration / (60 * 60)) % 24);
  const days = Math.floor(duration / (60 * 60 * 24));
  // Format values to have at least two digits
  const fSeconds = String(seconds).padStart(2, "0");
  const fMinutes = String(minutes).padStart(2, "0");
  const fHours = String(hours).padStart(2, "0");
  // Combine the parts
  const parts = [];
  if (days > 0) {
    parts.push(`${days}d`);
  }
  if (hours > 0) {
    parts.push(`${fHours}h`);
  }
  parts.push(`${fMinutes}m`);
  if (!noSeconds) {
    parts.push(`${fSeconds}s`);
  }
  return parts.join(" ");
};

const formattedDate = (dte: Date): string => {
  return format(dte, "yyyy-MM-dd HH:mm:ss");
};

const dateToDayAndDate = (dateString: string, shortDates: boolean) => {
  let parts = dateString.split("-");

  const yearPart = parseInt(parts[0]);
  const monthPart = parseInt(parts[1]) - 1; // months are 0-based in JavaScript
  const dayPart = parseInt(parts[2]);

  // Passing in the parts makes the day explicit.  If not JS date will try to convert to users local tz
  const date = new Date(yearPart, monthPart, dayPart);
  const currentYear = new Date().getFullYear();
  const day = date.toLocaleDateString("en-US", {
    weekday: shortDates ? "short" : "long",
  });
  const dateStr = date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    ...(yearPart !== currentYear ? { year: "numeric" } : {}),
  });
  return [day, dateStr];
};

const daysBetween = (date1: string | Date, date2?: string): number => {
  const firstDate = typeof date1 === "string" ? new Date(date1) : date1;
  const secondDate = date2 ? new Date(date2) : new Date();

  // Calculate the difference in time
  const timeDifference = Math.abs(secondDate.getTime() - firstDate.getTime());

  // Convert time difference from milliseconds to days
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysDifference;
};

const isTouchDevice = () => {
  // https://stackoverflow.com/a/56326027
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

const normalizeUSPhoneNumber = (phoneNumber: string): string => {
  // Remove any non-digit characters from the phone number
  let digitsOnly = phoneNumber.replace(/\D/g, "");
  if (digitsOnly.length === 11 && digitsOnly[0] === "1") {
    digitsOnly = digitsOnly.slice(1);
  }
  return `+1${digitsOnly}`;
};

const formatUSPhoneNumber = (
  phoneNumber: string,
  addCountry?: boolean,
): string => {
  // Remove any non-digit characters from the phone number
  let digitsOnly = phoneNumber.replace(/\D/g, "");
  if (digitsOnly.length === 11 && digitsOnly[0] === "1") {
    digitsOnly = digitsOnly.slice(1);
  }
  // Check if the phone number has 10 digits
  if (digitsOnly.length === 10) {
    // Format the phone number as (XXX) XXX-XXXX
    return `${addCountry ? "+1 " : ""}(${digitsOnly.slice(
      0,
      3,
    )}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
  } else {
    // If the phone number does not have 10 digits, return it as is
    return phoneNumber;
  }
};

const cleanPhone = (phone: string): string => {
  return phone.replace(/[^\d+]/g, "");
};

const pluralize = (term: string, count?: number): string => {
  if (count === 1) {
    return term;
  }
  // Simple pluralization - just adds 's' at the end. Adjust as needed for special cases
  return term.endsWith("s") ? term : `${term}s`;
};

const articleForTerm = (term: string): string => {
  const vowels = new Set(["a", "e", "i", "o", "u"]);
  return vowels.has(term[0].toLowerCase()) ? "an" : "a";
};

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const numberToWord = (number: number): string => {
  switch (number) {
    case 1:
      return "one";
    case 2:
      return "two";
    default:
      return number.toString();
  }
};

const commaSeparatedEnglishList = (items: string[]): string => {
  if (items.length === 0) {
    return "";
  }
  if (items.length === 1) {
    return items[0];
  }
  if (items.length === 2) {
    return `${items[0]} and ${items[1]}`;
  }
  return `${items.slice(0, -1).join(", ")}, and ${items[items.length - 1]}`;
};

const getDomainFromURL = (url: string): null | string => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname
      .replace(/^www\./, "")
      .replace("https://", "")
      .replace("http://", "");
  } catch (error) {
    return null;
  }
};

// https://stackoverflow.com/a/14919494
const humanFileSize = (bytes: number, dp: number = 1) => {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );
  return bytes.toFixed(dp) + " " + units[u];
};

const stripImgSrc = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const imgElements = doc.querySelectorAll("img");
  imgElements.forEach((img) => {
    img.src = "";
  });
  return doc.documentElement.outerHTML;
};

const toMaybeNumber = (n: BigInt | number | null) => {
  if (!n) return null;
  if (typeof n === "number") return n;
  return Number(n);
};

type FormErrors = {
  [key: string]: string;
};

const validatePassword = (
  password: string,
  minLen: number = 8,
  maxLen: number = 20,
): FormErrors => {
  const errors: FormErrors = {};
  if (password.length < minLen || password.length > maxLen) {
    errors["password"] =
      `Password must be between ${minLen} and ${maxLen} characters.`;
  }

  // Check for at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    errors["password"] = "Password must contain at least one uppercase letter.";
  }

  // Check for at least one digit
  if (!/[0-9]/.test(password)) {
    errors["password"] = "Password must contain at least one digit.";
  }

  // Check for at least one special character (e.g., !@#$%^&*()_+-=[]{}|;:'",.<>/?)
  if (!/[!@#$%^&*()_+\-=\[\]{}|;:'",.<>\/?]/.test(password)) {
    errors["password"] =
      "Password must contain at least one special character.";
  }
  return errors;
};

// Utility formatters
const formatPrice = (amountCents: number, currency: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amountCents / 100);
};

const formatDateRange = (startSeconds: bigint, endSeconds: bigint) => {
  const startDate = new Date(Number(startSeconds) * 1000);
  const endDate = new Date(Number(endSeconds) * 1000);
  const year = endDate.getFullYear();
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  const startFormatted = startDate.toLocaleDateString("en-US", options);
  const endFormatted = endDate.toLocaleDateString("en-US", options);

  return `${startFormatted} - ${endFormatted}, ${year}`;
};

const shortDate = (dateString: string) => {
  const [month, day, year] = dateString.split("/");
  const date = new Date(`${year}-${month}-${day}`);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export {
  isMobileAndroid,
  isMobileSamsung,
  isMobileWindows,
  isMobileiOS,
  isStandaloneWebapp,
  urlBase64ToUint8Array,
  formattedDate,
  dateToDayAndDate,
  isToday,
  isYesterday,
  isThisPastWeek,
  getFormattedDuration,
  isTouchDevice,
  formatUSPhoneNumber,
  normalizeUSPhoneNumber,
  cleanPhone,
  pluralize,
  articleForTerm,
  numberToWord,
  capitalize,
  getDomainFromURL,
  commaSeparatedEnglishList,
  humanFileSize,
  relativeTime,
  absoluteTime,
  daysBetween,
  stripImgSrc,
  validatePassword,
  formatDateForFilename,
  formatPrice,
  formatDateRange,
  shortDate,
  dateObjectToshortDate,
  toMaybeNumber,
};
