import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
} from "react";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FactFormHandle, FactFormProps } from "../types";
import DragNDrop from "../../common/DragNDrop";
import { useUploader } from "../../creation/FileUploader";
import { PlainMessage } from "@bufbuild/protobuf";
import { UploadAttachment } from "protogen/common_pb";
import AttachmentList from "../../editor/AttachmentList";
import UploadDocumentsButton from "../UploadDocumentsButton";
import FactSectionSelect from "../FactSectionSelect";

export default forwardRef<FactFormHandle, FactFormProps>(
  ({ fact, sections, updateFormData, errors }: FactFormProps, ref) => {
    const [dragState, setDragState] = React.useState(false);
    const {
      onUpload,
      fileUploads,
      uploadPercentage,
      removeUpload,
      uploadsInProgress,
      withAttachments,
    } = useUploader({
      initialAttachments: fact.attachments || [],
    });
    useImperativeHandle(ref, () => ({
      isValid: () => {
        const notUploading = !uploadsInProgress || uploadPercentage === 100;
        const hasName = fact.name !== "";

        return notUploading && hasName;
      },
    }));

    // Use the useCallback here because draft can change while we are uploading so we need to make sure it isn't stale.
    // Upon further reflection, I'm not sure I need useCallback nor if the state I'm listening to is relevant. This
    // is a duct tape solution that seems to be fine.
    const setAttachments = useCallback(
      (attachments: PlainMessage<UploadAttachment>[]) => {
        // Note - there could be a race condition here if the user is uploading a file AND updating text fields.
        updateFormData({ ...fact, uploads: attachments });
      },
      [fact, fileUploads],
    );

    useEffect(() => {
      withAttachments(fact.attachments || []);
    }, [fact.attachments]);

    return (
      <DragNDrop
        setHover={setDragState}
        onUpload={(files) => {
          onUpload(files, undefined, setAttachments);
        }}
      >
        <Box
          sx={{
            ...(dragState ? { backgroundColor: "#e8f4f8" } : {}),
          }}
        >
          <FactSectionSelect
            sections={sections}
            selectedSectionRef={fact.sectionRef}
            onChange={(sectionRef) => {
              updateFormData({ ...fact, sectionRef: sectionRef });
            }}
          />
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            value={fact.name}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => updateFormData({ ...fact, name: e.target.value })}
          />
          <TextField
            rows={5}
            multiline
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            value={fact.value || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => updateFormData({ ...fact, value: e.target.value })}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            sx={{ marginTop: "24px" }}
          >
            <Typography variant="bodyHeavy">Documents</Typography>
            <AttachmentList
              attachments={fileUploads}
              onDelete={(filename) => removeUpload(filename, setAttachments)}
            />
            <UploadDocumentsButton
              uploadsInProgress={uploadsInProgress}
              uploadPercentage={uploadPercentage}
              onChange={(files) => onUpload(files, undefined, setAttachments)}
            />
          </Box>
        </Box>
      </DragNDrop>
    );
  },
);
