import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
  TextField,
  Divider,
} from "@mui/material";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import set from "lodash/set";
``;
import {
  StepProps,
  StepRef,
  MEMBERSHIP_OPTIONS,
  MembershipOption,
} from "./../types";
import {
  useCreatePaymentIntent,
  useCreateUnlinkedBilling,
} from "services/billing";
import { EphemeralMember } from "components/member/types";
import PhoneInput from "components/creation/PhoneInput";
import { cleanPhone } from "common/utils";
import useIsMobile from "components/hooks/useIsMobile";
import AddressAutocomplete, {
  Location,
} from "components/common/AddressAutocomplete";
import { FormErrors } from "components/member/types";
import { validateLocation, validateContact } from "components/member/utils";
import { PlanFeatures } from "../components/PlanFeatures";
import { StepHeader } from "../components/StepHeader";
import PromoCodeForm, { PromoCodeState } from "../components/PromoCodeForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface ExtendedStripeElementsOptions {
  clientSecret: string;
  appearance?: object;
  paymentMethodCreation?: string;
}

const AccountDetailsForm = ({
  formData,
  errors,
  createChangeHandler,
}: {
  formData: EphemeralMember;
  errors: FormErrors;
  createChangeHandler: (
    path: string[],
    event: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}) => {
  const isMobile = useIsMobile();
  const [previousPhone, setPreviousPhone] = useState<string>("");
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        borderRadius: "16px",
        border: isMobile ? "none" : `1px solid ${theme.palette.border}`,
        padding: isMobile ? "16px 0" : "32px 23px",
        background: isMobile ? "" : "white",
        gap: "16px",
        marginBottom: isMobile ? "16px" : "",
      })}
    >
      <Box>
        <Typography variant="h4">Account details</Typography>
        <Typography variant="body" color="textSecondary">
          Your advisor will contact you using this information{" "}
        </Typography>
      </Box>
      <TextField
        sx={{ width: "100%" }}
        margin="none"
        label="Email"
        name="username"
        type="email"
        variant="outlined"
        autoComplete="email"
        value={formData.primaryEmail || ""}
        error={!!errors?.primaryEmail}
        helperText={errors?.primaryEmail}
        onChange={(e) => createChangeHandler(["primaryEmail"], e)}
      />
      <Box sx={{ display: "flex", gap: "17px", width: "100%" }}>
        <TextField
          sx={{
            width: isMobile ? "100%" : "50%",
          }}
          margin="none"
          label="First name"
          type="text"
          variant="outlined"
          name="firstName"
          autoComplete="given-name"
          value={formData.firstName || ""}
          error={!!errors?.firstName}
          helperText={errors?.firstName}
          onChange={(e) => createChangeHandler(["firstName"], e)}
        />
        <TextField
          sx={{
            width: isMobile ? "100%" : "50%",
          }}
          margin="none"
          label="Last name"
          type="text"
          variant="outlined"
          name="lastName"
          autoComplete="family-name"
          value={formData.lastName || ""}
          error={!!errors?.lastName}
          helperText={errors?.lastName}
          onChange={(e) => createChangeHandler(["lastName"], e)}
        />
      </Box>
      <PhoneInput
        value={formData.primaryPhone || ""}
        error={!!errors?.primaryPhone}
        helperText={errors?.primaryPhone}
        onChange={(phone) => {
          let newPhone = cleanPhone(
            typeof phone == "string" ? phone : phone.target.value,
          );
          // Handle auto complete where the 1 country code is not included
          if (
            (!previousPhone || previousPhone.length === 1) &&
            newPhone.length > 10 &&
            !newPhone.startsWith("+1")
          ) {
            newPhone = `+1${newPhone.replace("+", "")}`;
          }
          createChangeHandler(["primaryPhone"], newPhone);
          setPreviousPhone(newPhone);
        }}
        label="Phone"
        name="phone"
        size="small"
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
        autoComplete="tel"
      />
      <AddressAutocomplete
        label={"Home address"}
        name={"address"}
        autoComplete={"address street-address"}
        error={errors?.location}
        initialValue={formData?.address || ""}
        setValue={(location) => {
          createChangeHandler(["address"], JSON.stringify(location));
        }}
        inputSx={{ backgroundColor: "#FFF" }}
      />
    </Box>
  );
};

const JoinButton = ({
  disabled,
  handleContinue,
}: {
  disabled: boolean;
  handleContinue: () => void;
}) => {
  return (
    <Box>
      <Button
        type={"submit"}
        fullWidth
        color="primary"
        onClick={handleContinue}
        disabled={disabled}
        sx={{ marginTop: "24px" }}
      >
        {disabled ? <CircularProgress size={24} color="inherit" /> : "Join"}
      </Button>
    </Box>
  );
};

const appearance = {
  theme: "stripe" as const,
  variables: {
    colorPrimary: "#1976d2",
    colorBackground: "#ffffff",
    colorText: "#3D3D3D",
    colorDanger: "#C14743",
    fontFamily: "AlbertSans, SourceSerifPro, sans-serif",
    borderRadius: "16px",
    border: "1px solid #D4D4D4",
  },
};

// This wrapper exists so we can load all the other form elements and load stripe
// separately.  the use context hooks need to be wrapped in Elements.
const StripePaymentWrapper = ({
  setStripeInstance,
  setElementsInstance,
  onReady,
}: {
  setStripeInstance: (stripe: Stripe) => void;
  setElementsInstance: (elements: StripeElements) => void;
  onReady: () => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (stripe) {
      setStripeInstance(stripe);
    }
  }, [stripe, setStripeInstance]);
  useEffect(() => {
    if (elements) {
      setElementsInstance(elements);
    }
  }, [elements, setElementsInstance]);

  return (
    <PaymentElement
      onReady={onReady}
      options={{
        layout: "tabs",
        fields: {
          billingDetails: {
            address: {
              postalCode: "never",
              country: "never",
            },
          },
        },
      }}
    />
  );
};

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }, ref) => {
    useImperativeHandle(ref, () => ({}));
    const isMobile = useIsMobile();
    const [clientSecret, setClientSecret] = useState("");
    const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null);
    const [onPaymentReady, setOnPaymentReady] = useState(false);
    const [elementsInstance, setElementsInstance] =
      useState<StripeElements | null>(null);
    const { request: createBillingRequest, error: createBillingErrors } =
      useCreateUnlinkedBilling();
    const { request } = useCreatePaymentIntent((r) =>
      setClientSecret(r.clientSecret),
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [errors, setErrors] = useState<FormErrors>({});
    const planContainer = useRef<HTMLDivElement>(null);
    const [promoCode, setPromoCode] = useState<PromoCodeState | undefined>(
      undefined,
    );
    const [formData, setFormData] = useState<EphemeralMember>({
      primaryEmail: "",
      firstName: "",
      lastName: "",
      primaryPhone: "",
    });
    const selectedPlan: MembershipOption =
      signupState.membership || MEMBERSHIP_OPTIONS[0];

    const options: ExtendedStripeElementsOptions = {
      clientSecret,
      appearance,
      paymentMethodCreation: "manual",
    };

    useEffect(() => {
      // In mobile with column reverse direction users are put to the bottom of scroll
      if (planContainer.current) {
        planContainer.current.scrollIntoView({ block: "end" });
      }
    }, [isMobile]);

    useEffect(() => {
      const membership = signupState.membership || MEMBERSHIP_OPTIONS[0];
      request({ productName: membership.name });
    }, []);

    const createChangeHandler = (
      path: string[],
      event: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const value = typeof event === "string" ? event : event.target.value;
      setFormData((prev) => {
        set(prev, path, value);
        return { ...prev };
      });
    };

    const validatePromoCode = (promo?: PromoCodeState) => {
      if (!promo) {
        return {};
      }
      if (promo.code && promo.validated !== "validated") {
        return { promoCode: "Invalid promo code." };
      }
    };

    const handleContinue = async () => {
      setLoading(true);
      setError(null);
      const errors = {
        ...validateLocation(formData.address),
        ...validateContact(formData, true),
        ...validatePromoCode(promoCode),
      };
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        setLoading(false);
        return;
      }

      if (!stripeInstance || !elementsInstance) {
        setError("Stripe.js has not loaded yet.");
        setLoading(false);
        return;
      }

      const { error: submitError } = await elementsInstance.submit();
      if (submitError) {
        setError(
          submitError.message ||
            "An error occurred while submitting the payment.",
        );
        setLoading(false);
        return;
      }

      const locationObject: Location = JSON.parse(formData.address || "{}");
      const country = locationObject.country;
      const zip = locationObject.zip;

      const { paymentMethod, error } = await stripeInstance.createPaymentMethod(
        {
          elements: elementsInstance,
          params: {
            billing_details: {
              address: {
                country: country,
                postal_code: zip,
              },
            },
          },
        },
      );

      if (error) {
        setError(error.message || "Failed to create payment method.");
        setLoading(false);
        return;
      }

      if (!paymentMethod) {
        setError("Failed to retrieve payment method.");
        setLoading(false);
        return;
      }

      // To sasitfy TS.  We already check for form validation above for email, but TS doesn't know that
      if (!formData.primaryEmail) {
        setLoading(false);
        return;
      }
      const billing = await createBillingRequest({
        email: formData.primaryEmail,
        paymentMethodId: paymentMethod.id,
      });
      if (!billing || !billing.customerAccount) {
        setLoading(false);
        return;
      }
      try {
        await onNext?.({
          payment: {
            paymentMethodId: paymentMethod.id,
            customerId: billing.customerAccount.id,
            promoCode: promoCode?.code,
          },
          userInfo: {
            email: formData.primaryEmail,
            firstName: formData.firstName!,
            lastName: formData.lastName!,
            phone: formData.primaryPhone!,
            address: formData.address!,
          },
        });
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to create customer.",
        );
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      setError(createBillingErrors);
    }, [createBillingErrors]);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "calc(100vh - 106px)",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "12px",
          }}
        >
          <StepHeader
            title="Start working with an Advisor"
            subtitle="We’ll introduce you to your Family Advisor within 48 hours."
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0",
            flexDirection: isMobile ? "column-reverse" : "row",
            maxWidth: "1000px",
            flex: 1,
          }}
        >
          {/* Left side */}
          <Box
            sx={{
              width: isMobile ? "100%" : "50%",
              flex: 1,
              padding: isMobile ? "0px" : "20px",
              marginBottom: isMobile ? "0" : "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: isMobile ? "0" : "25px",
              }}
            >
              <AccountDetailsForm
                formData={formData}
                createChangeHandler={createChangeHandler}
                errors={errors}
              />
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "16px",
                  background: isMobile ? "" : "white",
                  border: isMobile
                    ? "none"
                    : `1px solid ${theme.palette.border}`,
                  padding: isMobile ? "16px 0" : "32px 23px",
                  gap: "15px",
                })}
              >
                <Typography variant="h4">Payment</Typography>

                {isMobile && (
                  <Typography variant="body">
                    You won’t be billed until you start working with your
                    Advisor.
                  </Typography>
                )}

                {clientSecret && (
                  <Elements stripe={stripePromise} options={options}>
                    <StripePaymentWrapper
                      setStripeInstance={setStripeInstance}
                      setElementsInstance={setElementsInstance}
                      onReady={() => setOnPaymentReady(true)}
                    />
                    {onPaymentReady && (
                      <PromoCodeForm
                        initialCode={signupState.referralCode}
                        onChange={setPromoCode}
                      />
                    )}
                  </Elements>
                )}
                {isMobile && (
                  <Box>
                    {error && <Alert severity="error">{error}</Alert>}
                    <JoinButton
                      disabled={loading || disabled || !!promoCode?.loading}
                      handleContinue={handleContinue}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Right side */}
          <Box
            sx={{
              width: isMobile ? "100%" : "auto",
              position: isMobile ? "relative" : "sticky",
              top: 0,
              height: "fit-content",
              padding: isMobile ? "0px" : "20px",
              margin: isMobile ? "24px 0" : "0px",
            }}
          >
            <form
              autoComplete="on"
              onSubmit={async (e) => {
                e.preventDefault();
                if (loading || disabled || !!promoCode?.loading) {
                  return;
                }
                await handleContinue();
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "16px",
                  border: `1px solid ${theme.palette.border}`,
                  padding: isMobile ? "20px" : "24px 27px",
                  gap: "16px",
                  backgroundColor: "#FFF",
                  maxWidth: isMobile ? "none" : "400px",
                })}
              >
                <Box
                  ref={planContainer}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography variant="h3" sx={{ marginBottom: "8px" }}>
                    {selectedPlan.name}
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    {selectedPlan.supportHours} hours of task support
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    {selectedPlan.maxUsers}{" "}
                    {selectedPlan.maxUsers === 1 ? "account" : "accounts"}
                  </Typography>
                  {selectedPlan.additionalFeatures && (
                    <Box>
                      {selectedPlan.additionalFeatures.map((feature) => (
                        <Typography
                          variant="body"
                          color="textSecondary"
                          key={feature}
                        >
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Typography variant="bodyHeavy">
                    Membership details{" "}
                  </Typography>
                  <PlanFeatures />
                </Box>
                {!isMobile && <Divider sx={{ margin: "16px -27px" }}></Divider>}
                <Box>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <Typography variant="h2">
                      ${selectedPlan.amountCents / 100}
                    </Typography>
                    <Typography variant="body">/mo</Typography>
                  </Box>
                  {!isMobile && (
                    <Typography variant="body">
                      You won’t be billed until you start working with your
                      Advisor.
                    </Typography>
                  )}
                  {!isMobile && (
                    <Box>
                      {error && (
                        <Alert
                          severity="error"
                          icon={false}
                          sx={{ marginTop: "16px" }}
                        >
                          {error}
                        </Alert>
                      )}

                      <JoinButton
                        disabled={loading || disabled}
                        handleContinue={handleContinue}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    );
  },
);
