import * as React from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  showCardInfo: boolean;
  showPassword: boolean;
}

const CvvInput = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, showCardInfo, value, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="NNNNN"
        definitions={{
          N: /[0-9]/,
        }}
        value={value}
        inputRef={ref}
        onAccept={(val: any) =>
          onChange({ target: { name: props.name, value: val } })
        }
        overwrite
        unmask={true}
        displayChar={showCardInfo ? "" : "•"}
      />
    );
  },
);

const ExpiryDateInput = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, showCardInfo, value, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="MM/[YY]YY"
        // Could be stricter.
        definitions={{
          M: /[0-9]/,
          Y: /[0-9]/,
        }}
        value={value}
        inputRef={ref}
        onAccept={(val: any) =>
          onChange({ target: { name: props.name, value: val } })
        }
        overwrite
        displayChar={showCardInfo ? "" : "•"}
      />
    );
  },
);

const CreditCardNumberInput = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, value, showCardInfo, ...other } = props;
    // Amex has a different format than the rest
    const isAmex = /^3[47]/.test(value);
    return (
      <IMaskInput
        {...other}
        mask={isAmex ? "NNNN NNNNNN NNNNN" : "NNNN NNNN NNNN NNNN"}
        definitions={{
          N: /[0-9]/,
        }}
        inputRef={ref}
        value={value}
        onAccept={(val: any) =>
          onChange({ target: { name: props.name, value: val } })
        }
        overwrite
        unmask={true}
        displayChar={showCardInfo ? "" : "•"}
      />
    );
  },
);

const PasswordInput = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, showPassword, value, ...other } = props;
    return (
      <IMaskInput
        {...other}
        // Regex is not working
        mask={"*".repeat(1000)}
        definitions={{
          "*": /[\s\S]/,
        }}
        value={value}
        inputRef={ref}
        onAccept={(val: any) =>
          onChange({ target: { name: props.name, value: val } })
        }
        overwrite
        displayChar={showPassword ? "" : "•"}
        unmask={true}
      />
    );
  },
);
export { ExpiryDateInput, CreditCardNumberInput, CvvInput, PasswordInput };
