import {
  SignupStateChoiceValue,
  SignupSubmission,
  UpdateSubmissionRequest,
} from "../../protogen/signup_service_pb";
import { Family, Member } from "../family/types";
import { FamilyStatus } from "../../protogen/advisors_service_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import {
  approximateTimezoneForState,
  stateFromZip,
} from "../../common/geoUtils";
import { MEMBERSHIP_OPTIONS } from "components/signup/types";

type NormalizedLead = {
  goals: string[];
  superpowers: string[];
  household: string[];
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address?: string;
  zipCode?: string;
  location: string;
  shortLocation: string;
  membershipName?: string;
  membershipHours?: number;
  customerId?: string;
  promoCode?: string;
  couponId?: string;
  fun?: string[];
  lifeEvents?: string[];
};

const getMembershipHours = (membershipName: string): number => {
  const membership = MEMBERSHIP_OPTIONS.find((m) => m.name === membershipName);
  return membership ? membership.supportHours : 0;
};

const normalizeLeadState = (lead: SignupSubmission): NormalizedLead => {
  const flattenChoices = (
    values:
      | {
          [key: string]: SignupStateChoiceValue;
        }
      | undefined,
  ): string[] => {
    if (!values) return [];
    return Object.keys(values).filter((k) => values[k].value);
  };
  let location;
  let shortLocation;
  if (lead.state?.userInfo?.address) {
    const parsed = JSON.parse(lead.state.userInfo.address);
    location = parsed?.formattedAddress;
    shortLocation = `${parsed?.city}, ${parsed?.state}`;
  } else {
    location = lead.state?.consultationDetails?.zipCode;
    shortLocation = location;
  }
  return {
    goals: flattenChoices(lead.state?.goals),
    superpowers: flattenChoices(lead.state?.superpowers),
    household: flattenChoices(lead.state?.household),
    firstName:
      lead.state?.userInfo?.firstName ||
      lead.state?.consultationDetails?.firstName ||
      "",
    lastName:
      lead.state?.userInfo?.lastName ||
      lead.state?.consultationDetails?.lastName ||
      "",
    phone:
      lead.state?.userInfo?.phone ||
      lead.state?.consultationDetails?.phone ||
      "",
    email:
      lead.state?.userInfo?.email ||
      lead.state?.consultationDetails?.email ||
      "",
    address: lead.state?.userInfo?.address || undefined,
    location: location,
    shortLocation: shortLocation || "",
    zipCode: lead.state?.consultationDetails?.zipCode || undefined,
    membershipName: lead.state?.membership?.name,
    membershipHours: getMembershipHours(lead.state?.membership?.name || ""),
    customerId: lead.state?.payment?.customerId,
    promoCode: lead.state?.payment?.promoCode,
    couponId: lead.state?.payment?.couponId,
    fun: flattenChoices(lead.state?.fun),
    lifeEvents: flattenChoices(lead.state?.lifeEvents),
  };
};

const emptyUpdates = (
  leadRef: string,
): PlainMessage<UpdateSubmissionRequest> => {
  return {
    ref: leadRef,
    updatedPipelineStatus: "",
    shouldUpdateMetadata: false,
    updatedMetadata: undefined,
  };
};

const leadToFamily = async (
  lead: SignupSubmission,
): Promise<[Partial<Family>, Partial<Member>[]]> => {
  const normalized = normalizeLeadState(lead);

  let timezone = "";
  if (normalized.address) {
    const address = JSON.parse(normalized.address);
    timezone = approximateTimezoneForState(address["state"]);
  } else if (normalized.zipCode) {
    const state = await stateFromZip(normalized.zipCode);
    if (state) {
      timezone = approximateTimezoneForState(state);
    }
  }

  return [
    {
      name: normalized.lastName,
      status: FamilyStatus.PROSPECT,
      address: normalized.address,
      stripeCustomerId: normalized.customerId,
      signupSubmissionRef: lead.ref,
      introCouponId: normalized.couponId,
      productName: normalized.membershipName,
      startDate:
        lead.pipelineStatus === "signup-complete"
          ? new Date(Number(lead.completionSec) * 1000).toLocaleDateString()
          : undefined,
    },
    [
      {
        firstName: normalized.firstName,
        lastName: normalized.lastName,
        email: normalized.email,
        phone: normalized.phone,
        timezone: timezone,
        isEphemeral: true,
      },
    ],
  ];
};
const leadToKeys = () => {};
const leadStringMatch = (lead: SignupSubmission, query: string): boolean => {
  const queryLower = query.toLowerCase();
  const leadString = [
    lead.state?.consultationDetails?.firstName,
    lead.state?.consultationDetails?.lastName,
    lead.state?.consultationDetails?.email,
    lead.state?.consultationDetails?.phone,
    lead.state?.consultationDetails?.zipCode,
    lead.state?.userInfo?.email,
    lead.state?.userInfo?.firstName,
    lead.state?.userInfo?.lastName,
    lead.state?.userInfo?.phone,
  ]
    .filter((s) => !!s)
    .join(" ")
    .toLowerCase();
  return leadString.includes(queryLower);
};

export {
  leadToKeys,
  leadStringMatch,
  normalizeLeadState,
  leadToFamily,
  emptyUpdates,
  getMembershipHours,
};
