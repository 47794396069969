import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Task, TaskState } from "protogen/tasks_pb";
import { PlanUtilization } from "protogen/common_pb";
import { Family } from "protogen/advisors_service_pb";
import LinkRouter from "../navigation/LinkRouter";
import TasksTable from "components/tasks/TasksTable";
import useIsMobile from "../hooks/useIsMobile";
import TimeUtilization from "components/time-utilization/TimeUtilization";
import {
  AddFilterContext,
  FilterContext,
} from "components/tasks/FilterContext";
import { TaskFilterDrawer } from "components/tasks/TaskFiltersDrawer";
import { useListTasksForAdvisor } from "services/tasks";
import { TaskFilter } from "components/tasks/FilterContext";
import { buildRequest } from "components/tasks/utils";

interface Props {
  title: string;
  family: Family;
  initialTasks: Task[];
  planUtilization: PlanUtilization;
}
const _Wrapped = ({ family, initialTasks, planUtilization }: Props) => {
  const initialStates = [
    TaskState.OPEN.toString(),
    TaskState.INPROGRESS.toString(),
  ];
  const [tasks, setTasks] = useState<Task[]>(initialTasks);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([
    "low",
    "medium",
    "high",
  ]);
  const [selectedStates, setSelectedStates] = useState<string[]>(initialStates);
  const [selectedFamilies, setSelectedFamilies] = useState<string[]>([
    family.ref,
  ]);
  const navigate = useNavigate();
  const onAddTaskClick = () => {
    navigate("/tasks/add", { state: { family } });
  };
  const isMobile = useIsMobile();
  const { upsertFilter } = useContext(FilterContext);
  const { loading, request } = useListTasksForAdvisor((r) => {
    setTasks(r.tasks);
  });
  const fetchTasks = (filters: TaskFilter[]) => {
    request(buildRequest(filters, false));
  };

  const stateSetters: {
    [key: string]: (values: string[]) => void;
  } = {
    priority: setSelectedPriorities,
    status: setSelectedStates,
    family: setSelectedFamilies,
  };
  const stateGetters: {
    [key: string]: string[];
  } = {
    priority: selectedPriorities,
    status: selectedStates,
    family: selectedFamilies,
  };

  const handleChange = (field: string, value: string, isChecked: boolean) => {
    const state = stateGetters[field];
    const newValues = isChecked
      ? state.includes(value)
        ? state
        : [...state, value]
      : state.filter((v: string) => v !== value);

    stateSetters[field](newValues);

    upsertFilter({
      field: field,
      operator: "oneOf",
      value: newValues,
    });
  };
  const handleUpdate = (field: string, operator: string, value: string) => {
    upsertFilter({
      field: field,
      operator: operator,
      value: value,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: "24px",
          }}
        >
          <TimeUtilization
            planUtilization={planUtilization}
            serviceEnding={!!family.endDate}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          gap: "8px",
        }}
      >
        <Button
          startIcon={
            <AdjustmentsHorizontalIcon
              height="20px"
              width="20px"
              stroke="#198282"
            />
          }
          variant="outlined"
          onClick={() => setShowFilters(true)}
        >
          Filters
        </Button>
        <Button onClick={onAddTaskClick}>Add task</Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
          flexDirection: "column",
        }}
      >
        <TasksTable
          tasks={tasks}
          isSortable={true}
          hideStatus={true}
          loading={loading}
          fetchTasks={fetchTasks}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <LinkRouter to={`/tasks?familyRefs=${encodeURIComponent(family.ref)}`}>
          <Button
            variant="text"
            endIcon={
              <ArrowRightIcon
                height="20px"
                width="20px"
                strokeWidth="2.5px"
                color="#198282"
              />
            }
          >
            See all tasks
          </Button>
        </LinkRouter>
      </Box>
      <TaskFilterDrawer
        open={showFilters}
        onClose={() => {
          setShowFilters(false);
        }}
        onChange={handleChange}
        onUpdate={handleUpdate}
        initialPriorites={selectedPriorities}
        initialStates={initialStates}
        initialFamilies={[family.ref]}
        families={[{ label: family.name, value: family.ref }]}
        showFamilyFilter={false}
      />
    </Box>
  );
};

export default ({ family, initialTasks, planUtilization, title }: Props) => {
  const initialStates = [
    TaskState.OPEN.toString(),
    TaskState.INPROGRESS.toString(),
  ];
  const initialFamilies = [family.ref];
  const initialPriorities = ["low", "medium", "high"];
  return (
    <AddFilterContext
      initialStates={initialStates}
      initialFamilies={initialFamilies}
      initialPriorities={initialPriorities}
    >
      <_Wrapped
        title={title}
        family={family}
        initialTasks={initialTasks}
        planUtilization={planUtilization}
      />
    </AddFilterContext>
  );
};
