import { Box, Typography } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";
import { Task, TaskChangeEvent, TaskChangeEventType } from "protogen/tasks_pb";
import { AccountStub } from "protogen/common_pb";
import { CircleCheckBig } from "lucide-react";

export default ({
  task,
  taskChangeEvent,
  accountMap,
  senderRef,
}: {
  task: Task;
  taskChangeEvent: TaskChangeEvent;
  accountMap: Record<string, AccountStub>;
  senderRef: string;
}) => {
  const sender = accountMap[senderRef];
  const createTaskEventTitle = (taskChangeEvent: TaskChangeEvent) => {
    if (
      taskChangeEvent.type === TaskChangeEventType.TASK_CHANGE_EVENT_CREATED
    ) {
      return `${sender.firstName} created a new task`;
    }
    if (
      taskChangeEvent.type === TaskChangeEventType.TASK_CHANGE_EVENT_COMPLETED
    ) {
      return `${sender.firstName} completed a task`;
    }
    if (
      taskChangeEvent.type === TaskChangeEventType.TASK_CHANGE_EVENT_DELETED
    ) {
      return `${sender.firstName} deleted a task`;
    }
  };

  return (
    <LinkRouter to={`/tasks/${encodeURIComponent(task.ref)}`}>
      <Box
        sx={{
          backgroundColor: "#FFF",
          border: "1px solid #EAEBEC",
          borderRadius: "12px",
          display: "flex",
          padding: "12px 16px",
          width: "100%",
          gap: "8px",
        }}
      >
        <CircleCheckBig width={24} height={24} color="#6B6E7B" />
        <Typography
          variant="bodySmall"
          color="text.primary"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            fontWeight: 600,
          }}
        >
          {createTaskEventTitle(taskChangeEvent)}
          <Typography
            variant="bodySmall"
            component="span"
            color="primary.main"
            sx={{ fontWeight: "600" }}
          >
            {task.title}
          </Typography>
        </Typography>
      </Box>
    </LinkRouter>
  );
};
