import { useRef, useState, useEffect } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { TrashIcon } from "@heroicons/react/24/outline";
import { FACT_TYPES, DEFAULT_FACT_TYPE } from "./Constants";
import { EphemeralFact, FactFormHandle } from "./types";
import useIsMobile from "../hooks/useIsMobile";
import { articleForTerm } from "../../common/utils";
import { FactSectionStub } from "protogen/facts_pb";

export const getTypeViewByCategory = (category: string) => {
  const categoryObj = FACT_TYPES.find((cat) => cat.value === category);
  return categoryObj ? categoryObj : DEFAULT_FACT_TYPE;
};

const getTypeView = (fact: EphemeralFact) => {
  const isNewFact = fact.name === "" || fact.value === "";
  let isValidJSON = checkIsValidJson(fact.value);
  const categoryObj = FACT_TYPES.find((cat) => cat.value === fact.valueType);

  return categoryObj && (!categoryObj.json || isNewFact || isValidJSON)
    ? categoryObj
    : DEFAULT_FACT_TYPE;
};

function checkIsValidJson(jsonString: string): boolean {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
  return true;
}

export default ({
  fact,
  sections,
  isEdit,
  updateFormData,
  onSave,
  onCancel,
  onDelete,
  children,
}: {
  fact: EphemeralFact;
  sections: FactSectionStub[];
  isEdit: boolean;
  updateFormData: (
    f: EphemeralFact | ((f_: EphemeralFact) => EphemeralFact),
  ) => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
  children?: React.ReactNode;
}) => {
  const isMobile = useIsMobile();
  const [isValid, setIsValid] = useState(false);
  const viewRef = useRef<FactFormHandle | null>(null);
  const factType = getTypeView(fact);
  let factLabel = factType.label.toLowerCase();
  factLabel = factLabel == "other" ? "fact" : factLabel;

  const view = (
    <factType.view
      ref={viewRef}
      sections={sections}
      fact={fact}
      updateFormData={updateFormData}
      errors={null}
    />
  );

  useEffect(() => {
    setIsValid(viewRef.current?.isValid() ?? false);
  }, [viewRef.current]);

  return (
    <Box
      sx={{
        ...(!isMobile && {
          padding: "24px",
          paddingTop: "8px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Typography variant="title">
          {isEdit
            ? `Edit ${factLabel}`
            : `Add ${articleForTerm(factLabel)} ${factLabel}`}
        </Typography>
        {isEdit && (
          <IconButton onClick={onDelete}>
            <TrashIcon height="24px" width="24px" stroke="#E57171" />
          </IconButton>
        )}
      </Box>
      <Box>{children}</Box>
      <Box sx={{ marginBottom: "24px" }}>{view}</Box>
      <Box>
        <Button
          sx={{ marginBottom: "12px" }}
          fullWidth
          disabled={!isValid}
          onClick={onSave}
        >
          Save
        </Button>
        <Button fullWidth variant="outlinedSecondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
