import React, { forwardRef, useEffect } from "react";
import { StepRef, StepProps } from "../types";
import { Typography, Box, Button } from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";
import { celebrate } from "../../../common/celebrations";
import StaticStep from "../components/StaticStep";

export default forwardRef<StepRef, StepProps>(({ onNext, disabled }, _ref) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    setTimeout(() => {
      celebrate();
    }, 500);
  }, []);

  return (
    <StaticStep
      title="Welcome!"
      img="/assets/images/nux/emily.png"
      mobileImg="/assets/images/nux/emily-mobile.png"
      fullImageHeight={true}
      bodyContent={
        <>
          <Typography variant="h3" sx={{ marginBottom: "12px" }}>
            Let's find your perfect match.
          </Typography>
          <Box>
            Now that the basics are covered, we have a few questions to match
            you with the right Advisor. The more details you share, the better
            we are at finding the perfect fit for your family.
          </Box>
          <Box key={1}>
            After you submit, we'll be in touch within 48 hours.
          </Box>
        </>
      }
      actions={
        <>
          <Button
            key={0}
            variant="contained"
            color="primary"
            size={isMobile ? "medium" : "large"}
            disabled={disabled}
            onClick={() => onNext?.({})}
          >
            Let's do it
          </Button>
        </>
      }
    />
  );
});
