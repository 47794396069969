import { ReactNode, useState } from "react";
import { Box, Select, MenuItem, SelectProps } from "@mui/material";
import { ChevronDown, ChevronUp } from "lucide-react";

interface Option {
  label: string;
  value: any;
}
type SelectWrapperProps = SelectProps & {
  options?: Option[];
  children?: ReactNode;
};

const DefaultMenuItems = ({ options }: { options?: Option[] }) => {
  if (!options) return <></>;
  return (
    <Box>
      {options.map((option, index) => {
        return (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </Box>
  );
};
const SelectWrapper: React.FC<SelectWrapperProps> = ({
  options,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const menuItems = children ? (
    children
  ) : (
    <DefaultMenuItems options={options} />
  );
  return (
    <Select
      {...props}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      sx={{
        ".MuiOutlinedInput-input": {
          margin: "0px",
          padding: "0px",
          marginLeft: "20px",
          color: "#8E9598",
          fontSize: "14px",
          fontWeight: "500",
        },
        margin: "0px",
        padding: "0px",
        height: "44px",
        minHeight: "44px",
        fieldset: {
          border: "2px solid #EAEBEC",
          borderRadius: "100px",
        },
        cursor: "pointer",
        ...props.sx,
      }}
      IconComponent={() => {
        return (
          <Box
            onClick={() => setOpen((o) => !o)}
            sx={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {open ? (
              <ChevronUp size={20} strokeWidth="2px" color="#8E9598" />
            ) : (
              <ChevronDown size={20} strokeWidth="2px" color="#8E9598" />
            )}
          </Box>
        );
      }}
    >
      {menuItems}
    </Select>
  );
};

export default SelectWrapper;
