import {
  Family as FamilyProto,
  FetchInvoiceHistoryResponse_InvoiceMonth,
  UpdateFamilyRequest,
} from "protogen/advisors_service_pb";
import { Box, Button, Collapse, Typography } from "@mui/material";
import { SectionProps } from "../details-page/Section";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  useFetchInvoiceHistory,
  useUpdateFamily,
} from "../../services/advisor";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdmin } from "../../common/userUtils";
import { pluralize } from "../../common/utils";
import { useLocation } from "react-router-dom";
import { Family, FormErrors, Member } from "./types";
import { validateFamily } from "./utils";
import {
  Advisor,
  UserPermission,
  UserPermission as UserPermissionProto,
} from "../../protogen/common_pb";
import Loading from "../common/Loading";
import LinkRouter from "../navigation/LinkRouter";
import EmbeddedForm from "./EmbeddedForm";
import { updates } from "./updateUtils";
import useIsMobile from "components/hooks/useIsMobile";
import SnackPack, { SnackbarMessage } from "../common/SnackPack";

const formatMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours}h ${remainingMinutes}m`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else {
    return `${remainingMinutes}m`;
  }
};

const InvoiceMonth = ({
  defaultExpanded = false,
  month,
}: {
  defaultExpanded?: boolean;
  month: FetchInvoiceHistoryResponse_InvoiceMonth;
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        border: "1px solid #D4D4D4",
        padding: "20px 16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          userSelect: "none",
          alignItems: "center",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
          }}
        >
          <Typography
            variant={isMobile ? "bodyHeavy" : "body"}
            sx={{ flex: "3" }}
          >
            {month.startDate} - {month.endDate}
          </Typography>
          {/*<Typography variant="body" sx={{ flex: "1" }}>*/}
          {/*  ${month.amountCents / 100}*/}
          {/*</Typography>*/}
          <Box
            sx={{
              display: "flex",
              gap: isMobile ? "" : "4px",
              flex: isMobile ? "" : "2",
            }}
          >
            <Typography
              variant={isMobile ? "bodySmall" : "body"}
              sx={{ flex: isMobile ? "" : "1" }}
            >
              {Math.round(month.totalMinutes / 60 / 0.25) * 0.25} hours
            </Typography>
            {isMobile && (
              <Typography variant="bodySmall" sx={{ marginRight: "4px" }}>
                ,{" "}
              </Typography>
            )}
            <Typography
              variant={isMobile ? "bodySmall" : "body"}
              sx={{ flex: "1" }}
            >
              {month.tasksCount} {pluralize("task", month.tasksCount)}
            </Typography>
          </Box>
        </Box>
        {expanded ? (
          <ChevronUp size={24} stroke="#756F6C" cursor="pointer" />
        ) : (
          <ChevronDown size={24} stroke="#756F6C" cursor="pointer" />
        )}
      </Box>
      <Collapse in={expanded}>
        {/* Collapse content container*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "12px 0 0 0",
          }}
        >
          {!month.tasks.length && (
            <Typography variant="body">No work recorded</Typography>
          )}
          {/* Task entry item */}
          {month.tasks.map((task) => (
            <Box
              key={task.ref}
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? "4px" : "16px",
                borderTop: "1px solid #E2E3E4",
                paddingTop: "16px",
                alignItems: "start",
              }}
            >
              <LinkRouter
                to={`/tasks/${encodeURIComponent(task.ref)}`}
                sx={{
                  display: "flex",
                  flex: "2",
                }}
              >
                <Button
                  variant="text"
                  href=""
                  sx={{
                    height: "auto",
                    textAlign: "start",
                    lineHeight: "1.3",
                  }}
                >
                  {task.title}
                </Button>
              </LinkRouter>

              <Box sx={{ display: "flex", flexDirection: "column", flex: "3" }}>
                {/* Task time records */}
                {task.chunks.map((chunk, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      gap: "8px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <Typography variant="bodyHeavy" sx={{ minWidth: "54px" }}>
                        {formatMinutes(chunk.minutes)}
                      </Typography>
                      <Typography variant="body">
                        {chunk.description || "No description"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="bodySmall"
                      color="#686868"
                      sx={{ textWrap: "nowrap", marginTop: "2px" }}
                    >
                      {chunk.date}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

const BillingHistory = ({ family }: { family: FamilyProto }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { request, data, loading } = useFetchInvoiceHistory();
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    request({
      familyRef: family.ref,
    });
  }, []);
  useEffect(() => {
    if (params.has("currentMonth")) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [params]);
  if (loading) {
    return <Loading />;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }} ref={scrollRef}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Typography variant="h3">Monthly summaries</Typography>
        {!data?.invoices?.length && (
          <Typography variant="body">No invoices found</Typography>
        )}
        {(data?.invoices || []).map((month, i) => (
          <InvoiceMonth
            key={i}
            month={month}
            defaultExpanded={params.has("currentMonth") && i === 0}
          />
        ))}
      </Box>
    </Box>
  );
};

const FamilyDetails = ({
  family,
  familyOnly,
  contactOnly,
  primaryAdvisor,
  advisorPermissions,
}: {
  family: FamilyProto;
  familyOnly?: boolean;
  contactOnly?: string;
  // Should be required.
  primaryAdvisor: Advisor;
  advisorPermissions?: UserPermissionProto[];
}) => {
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [familyErrors, setFamilyErrors] = useState<FormErrors | null>(null);
  const [familyData, setFamilyData] = useState<Family>({
    ref: family.ref,
    name: family.name,
    advisorRef: family.advisorRef,
    status: family.status,
    startDate: family.startDate || null,
    product: family.billingInfo?.product?.id
      ? family.billingInfo?.product
      : undefined,
    introCoupon: family.billingInfo?.introCoupon?.id
      ? family.billingInfo?.introCoupon
      : undefined,
    address: family.address,
    endDate: family.endDate || null,
    platformFeeSchedule: family.platformFeeSchedule,
    permissions: (advisorPermissions || []).map((permission) => ({
      ref: permission.ref,
      account: permission.account,
      advisorRef: permission?.account?.ref || "",
      active: permission.active,
      expiration: permission.expiration,
      actions: permission.actions,
    })),
    billingInfo: family.billingInfo,
    metadata: family.metadata,
  });
  const [members, setMembers] = useState<Member[]>(
    family.familyMembers.map((member) => ({
      ref: member.ref,
      firstName: member.firstName,
      lastName: member.lastName,
      phone: member.primaryPhone,
      email: member.primaryEmail,
      altEmails: member.alternateEmails,
      timezone: member.timezone,
    })),
  );

  const { request, loading } = useUpdateFamily(() => {});

  const _validate = () => {
    const famErrors = validateFamily(familyData);
    setFamilyErrors(famErrors);
    let hasErrors = Object.keys(famErrors).length !== 0;
    return !hasErrors;
  };
  const handleEditFamily = async () => {
    if (_validate()) {
      const r = updates(family, familyData, members, advisorPermissions || []);
      const resp = await request(new UpdateFamilyRequest(r));
      if (resp) {
        setSnackPack((p) => [
          ...p,
          {
            message: "Family details updated successfully",
            saveNotification: true,
            key: new Date().getTime(),
          },
        ]);
      }
      if (r.updatedStatus) {
        // Force a reload as broader state depends on the family status (sidebar, timetracker etc).
        window.location.reload();
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <EmbeddedForm
        familyData={familyData}
        members={members}
        setFamilyData={(changes) =>
          setFamilyData((fd) => ({
            ...fd,
            ...changes,
          }))
        }
        setMembers={setMembers}
        familyErrors={familyErrors}
        handleSubmit={handleEditFamily}
        familyOnly={familyOnly}
        contactOnly={contactOnly}
        primaryAdvisor={primaryAdvisor}
      />
      <Box>
        <Button disabled={loading} onClick={handleEditFamily}>
          Save
        </Button>
      </Box>
      <SnackPack
        autoHideDuration={6000}
        snackPack={snackPack}
        alertSeverity="success"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </Box>
  );
};

interface Props extends SectionProps {
  family: FamilyProto;
  advisorPermissions: UserPermission[];
  primaryAdvisor: Advisor;
}
export default ({ family, primaryAdvisor, advisorPermissions }: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const isAdvisor = currentUser?.ref === family.advisorRef;
  const isAdmin = isUserAdmin(currentUser);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "48px",
        maxWidth: "900px",
        margin: "auto",
      }}
    >
      <FamilyDetails
        family={family}
        advisorPermissions={advisorPermissions}
        primaryAdvisor={primaryAdvisor}
      />
      {isAdvisor || isAdmin ? <BillingHistory family={family} /> : null}
    </Box>
  );
};
