import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Chip, Typography, Button } from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useGetFamilyDetails } from "services/advisor";
import ActivityFeed from "components/activity/ActivityFeed";
import Loading from "components/common/Loading";
import { Family, FamilyStatus } from "protogen/advisors_service_pb";
import TwoPanelGrid, { Panel } from "components/details-page/TwoPanelGrid";
import TabbedDetailsPanel from "components/details-page/TabbedDetailsPanel";
import { Note } from "protogen/notes_pb";
import useIsMobile from "components/hooks/useIsMobile";
import { Task } from "protogen/tasks_pb";
import { SearchContext } from "components/context/SearchContextProvider";
import FamilyTaskSection from "components/family/FamilyTaskSection";
import FamilySecretsSection from "components/family/FamilySecretsSection";
import { SecretStub } from "protogen/secrets_pb";
import Breadcrumbs from "components/common/Breadcrumbs";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "components/activity/SuggestionActions";
import { useNavigate } from "react-router";
import { Advisor, UserPermission } from "protogen/common_pb";
import { PlanUtilization } from "protogen/common_pb";
import TimeUtilization from "components/time-utilization/TimeUtilization";
import NotFound from "components/NotFound";
import useTitle from "components/hooks/useTitle";
import FamilyCalendarSection from "components/family/FamilyCalendarSection";
import FamilySettingsSection from "../components/family/FamilySettingsSection";
import ActivationDialog from "../components/family/ActivationDialog";
import ContactSection from "components/activity/ContactSection";
import { Medium } from "protogen/advisors_service_pb";
import { CurrentUserContext } from "../components/context/RequireAuth";
import { isUserAdmin } from "../common/userUtils";
import FamilyAdminSection from "../components/family/FamilyAdminSection";
import FamilyDocumentsSection from "../components/family/FamilyDocumentsSection";

interface LeftFamilyPanelProps {
  family: Family;
  familyNotes: Note;
  tasks: Task[];
  secrets: SecretStub[];
  setShowMessages: (s: boolean) => void;
  primaryAdvisor: Advisor;
  advisorPermissions: UserPermission[];
  planUtilization: PlanUtilization;
  calendarRefreshTrigger: number;
}
const LeftFamilyPanel = ({
  family,
  familyNotes,
  tasks,
  secrets,
  primaryAdvisor,
  advisorPermissions,
  planUtilization,
  calendarRefreshTrigger,
}: LeftFamilyPanelProps) => {
  const [searchParams] = useSearchParams();
  const currentUser = useContext(CurrentUserContext);
  let panels = [
    // <Section key={"Suggestions"} title={"Suggestions"}>
    //   <CardsContainer />
    // </Section>,
    <FamilyDocumentsSection
      title={"Documents"}
      key={"documents"}
      family={family}
      note={familyNotes}
    />,
    <FamilyCalendarSection
      title={"Calendar"}
      key={"Calendar"}
      family={family}
      fullHeight
      refreshTrigger={calendarRefreshTrigger}
    />,
    <FamilyTaskSection
      title={"Tasks"}
      key={"Open Tasks"}
      family={family}
      initialTasks={tasks}
      planUtilization={planUtilization}
    />,
    <FamilySecretsSection
      title={"Secrets"}
      secrets={secrets}
      family={family}
      key="secrets"
    />,
    <FamilySettingsSection
      title={"Settings"}
      key={"settings"}
      family={family}
      primaryAdvisor={primaryAdvisor}
      advisorPermissions={advisorPermissions}
    />,
    ...(isUserAdmin(currentUser)
      ? [
          <FamilyAdminSection
            title={"Admin"}
            key={"administration"}
            family={family}
            primaryAdvisor={primaryAdvisor}
          />,
        ]
      : []),
  ];
  let defaultTab = undefined;
  if (searchParams.has("factRef")) {
    defaultTab = panels.findIndex((p) => p.type === FamilyDocumentsSection);
  } else if (searchParams.has("secretRef")) {
    defaultTab = panels.findIndex((p) => p.type === FamilySecretsSection);
  }
  return (
    <TabbedDetailsPanel
      panels={panels}
      defaultTab={defaultTab}
      sx={{ overflowY: "hidden", paddingRight: "20px", gap: "0px" }}
    />
  );
};

const getFamilyBadge = (family: Family) => {
  const values = new Map<FamilyStatus, [string, string, string]>([
    [FamilyStatus.ACTIVE, ["Active", "#ECF4EC", "#198282"]],
    [
      FamilyStatus.PROSPECT,
      ["Prospect", "rgba(251, 242, 229, 0.95)", "#9A6518"],
    ],
    [FamilyStatus.DEACTIVATED, ["Inactive", "#F5F5F5", "#6B6E7B"]],
    [FamilyStatus.TEST, ["Demo", "#FFF", "#6B6E7B"]],
    [FamilyStatus.DEMO, ["Demo", "#FFF", "#6B6E7B"]],
  ]);
  if (!values.has(family.status)) {
    // Includes NOT_SET and ACTIVE
    return null;
  }
  const [copy, bgColor, textColor] = values.get(family.status)!;
  return (
    <Chip
      label={copy}
      sx={{
        height: "35px",
        fontSize: "16px",
        fontWeight: "600",
        borderRadius: "100px",
        padding: "8px 12px",
        background: bgColor,
        span: { color: textColor },
        ...(bgColor === "#FFF" && { border: "1px solid #D4D4D4" }),
      }}
    />
  );
};

const getInvitationButton = (family: Family, onClick: () => void) => {
  return (
    <Button onClick={onClick} variant="outlined" size="small">
      Invite to join
    </Button>
  );
};

const _TaskTimeUtilization = ({
  planUtilization,
  family,
}: {
  planUtilization?: PlanUtilization;
  family: Family;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = () => {
    const pathname = location.pathname;
    // Parse the current search params
    const searchParams = new URLSearchParams(location.search);
    // Update the parameter value
    searchParams.set("currentMonth", "1");

    // Construct the new URL
    const newUrl = `${pathname}?${searchParams.toString()}#tab-4`;

    // Navigate to the new URL
    navigate(newUrl);
  };
  return (
    <TimeUtilization
      planUtilization={planUtilization}
      onClick={onClick}
      serviceEnding={!!family?.endDate}
    />
  );
};

const ActivateAction = ({
  family,
  mobile,
}: {
  family: Family;
  mobile?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "end",
        gap: "16px",
        ...(mobile ? { justifyContent: "center", padding: "12px" } : {}),
      }}
    >
      <Button onClick={() => setOpen(true)} size="small">
        Start service
      </Button>
      <ActivationDialog family={family} open={open} setOpen={setOpen} />
    </Box>
  );
};
const getPanelHeaderActions = (
  family: Family,
  onClick: () => void,
  planUtilization?: PlanUtilization,
  mobile?: boolean,
  selectedPanel?: Panel,
  onMediumChange?: (m: Medium) => void,
) => {
  const includeInviteButton =
    family.status === FamilyStatus.PROSPECT ||
    family.status === FamilyStatus.PREACTIVATION;
  let module;
  if (mobile && selectedPanel === Panel.Right) {
    module = (
      <Box>
        <ContactSection
          family={family}
          feedFocusState={{
            selectedContact: family.familyMembers[0],
            selectedMedium: null,
            selectedEntryKey: null,
            replyToEmail: null,
            editEmailDraft: null,
            isDirty: false,
            groupText: false,
          }}
          setFeedFocusState={(s) => {
            onMediumChange && onMediumChange(s.selectedMedium!);
          }}
        />
      </Box>
    );
  } else if (family.status === FamilyStatus.ACTIVE) {
    module = (
      <Box>
        <_TaskTimeUtilization
          planUtilization={planUtilization}
          family={family}
        />
      </Box>
    );
  } else if (family.status === FamilyStatus.PREACTIVATION) {
    module = <ActivateAction family={family} mobile={mobile} />;
  } else {
    module = (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "end",
          gap: "16px",
          ...(mobile ? { justifyContent: "center", padding: "12px" } : {}),
        }}
      >
        {includeInviteButton && getInvitationButton(family, onClick)}
        {!mobile ? getFamilyBadge(family) : null}
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "end",
        justifyContent: "end",
        gap: "16px",
      }}
    >
      {module}
    </Box>
  );
};

export default () => {
  const { setFamily } = useContext(SearchContext);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const defaultPanel = hash.match(/#tab-\d+/) ? Panel.Left : Panel.Right;
  const isMobile = useIsMobile();
  const [showMessages, setShowMessages] = useState(true);
  const [selectedPanel, setSelectedPanel] = useState(defaultPanel);
  const [selectedMedium, setSelectedMedium] = useState<Medium | null>(null);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [calendarRefreshTrigger, setCalendarRefreshTrigger] = useState(0);
  let params = useParams();
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const { data, request, errorCode } = useGetFamilyDetails((r) => {
    if (r.family) {
      setFamily(r.family);
      setHasLoaded(true);
    }
  });
  useTitle(
    data?.family?.name ? `Families | ${data?.family?.name}` : "Families",
  );

  const onMediumChange = (m: Medium) => {
    setSelectedMedium(m);
  };

  useEffect(() => {
    // Clear it so we don't show the wrong family while loading.
    setHasLoaded(false);
    request({ familyRef: params.familyRef! });
  }, [params.familyRef]);
  if (errorCode === 404) return <NotFound title={"Family not found"} />;
  if (!hasLoaded || !data) {
    return <Loading />;
  }
  const { family, familyNotes, activeTasks, secrets, planUtilization } = data;
  // Not sure why these are nullable. TS+protobuf problem for another day.
  if (!family) {
    throw Error("Family type expected.");
  }
  const refresh = () => {
    request({ familyRef: params.familyRef! });
  };

  const onInviteClick = () => {
    navigate(`/families-invitation/${encodeURIComponent(family.ref)}`);
  };
  return (
    <>
      <TwoPanelGrid
        key={family.ref}
        defaultPanel={defaultPanel}
        headerAction={getPanelHeaderActions(
          family,
          onInviteClick,
          planUtilization,
          isMobile,
          selectedPanel,
          onMediumChange,
        )}
        showHeaderAction={!isMobile || selectedPanel === Panel.Right}
        onChangePanel={(panel) => {
          setSelectedPanel(panel);
          panel === Panel.Right
            ? navigate("#", { replace: true })
            : navigate("#tab-0", { replace: true });
        }}
        leftPanel={{
          title: (
            <Box sx={{}}>
              <Typography variant="display">{family.name}</Typography>
            </Box>
          ),
          subtitle: !isMobile ? (
            <Breadcrumbs breadcrumbs={[{ name: "Family details" }]} />
          ) : null,
          switchContent: <Typography variant="body">Family details</Typography>,
          content: (
            <>
              {isMobile
                ? getPanelHeaderActions(family, onInviteClick, undefined, true)
                : null}
              <LeftFamilyPanel
                family={family}
                tasks={activeTasks}
                familyNotes={familyNotes}
                secrets={secrets}
                setShowMessages={setShowMessages}
                primaryAdvisor={data.primaryAdvisor}
                advisorPermissions={data.advisorPermissions}
                planUtilization={planUtilization}
                calendarRefreshTrigger={calendarRefreshTrigger}
              />
            </>
          ),
        }}
        rightPanel={{
          sx: { height: "100%" },
          title: null,
          subtitle: isMobile ? (
            <Box sx={{}}>
              <Typography variant="h1Serif">{family.name}</Typography>
            </Box>
          ) : null,
          switchContent: <Typography variant="body">Activity feed</Typography>,
          content: family.familyMembers.length ? (
            <ActivityFeed
              family={data.family!}
              showMessages={showMessages}
              suggestionActions={{
                suggestTask: (t) => {
                  actionsRef.current?.suggestTask(t);
                },
                suggestFact: (f) => {
                  actionsRef.current?.suggestFact(f);
                },
                suggestEvent: (e) => {
                  actionsRef.current?.suggestEvent(e);
                },
              }}
              primaryAdvisor={data.primaryAdvisor}
              advisorPermissions={data.advisorPermissions}
              selectedMedium={selectedMedium}
            />
          ) : null,
        }}
      />
      <SuggestionActions
        ref={actionsRef}
        familyRef={family.ref}
        onFactSuggested={refresh}
        onEventSuggested={() => {
          refresh();
          // The calendar controls it's own state + data.  Manually trigger
          // a refresh from a parent component.
          setCalendarRefreshTrigger(calendarRefreshTrigger + 1);
        }}
        // These get redirected.
        // onTaskSuggested={refresh}
      />
    </>
  );
};
