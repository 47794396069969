import { Box, Typography, Switch } from "@mui/material";
import { CSSProperties } from "react";

export default ({
  disabled,
  onChange,
  checked,
  label,
  helperText,
  sx,
}: {
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean | undefined;
  label: string;
  helperText?: string;
  sx?: CSSProperties;
}) => {
  return (
    <Box sx={{ display: "flex", ...sx }}>
      <Switch disabled={disabled} onChange={onChange} checked={checked} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="body"
          color="text.primary"
          sx={{ fontWeight: 600 }}
        >
          {label}
        </Typography>
        <Typography variant="bodySmall" color="body">
          {helperText}
        </Typography>
      </Box>
    </Box>
  );
};
