import { useEffect } from "react";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { useAdminListAllAdvisors } from "services/admin";
import { Advisor } from "protogen/common_pb";
import { ChevronDown } from "lucide-react";

interface Props {
  selectedAdvisor: string | null;
  onChange: (advisorRef: string | null) => void;
  error?: string | null;
  disabled?: boolean;
  title?: string;
  advisors?: Advisor[];
}

export default ({
  selectedAdvisor,
  onChange,
  error,
  disabled,
  title = "Select Advisor",
  advisors: providedAdvisors,
}: Props) => {
  const { data: advisorsData, request } = useAdminListAllAdvisors();

  useEffect(() => {
    if (!providedAdvisors) {
      request();
    }
  }, [providedAdvisors]);

  const options = providedAdvisors || advisorsData?.advisors || [];

  // Find the selected advisor in the options list
  const value =
    options.find((advisor) => advisor.ref === selectedAdvisor) || null;

  return (
    <FormControl sx={{ width: "100%", minWidth: "250px" }} fullWidth>
      <Autocomplete<Advisor>
        popupIcon={<ChevronDown />}
        disabled={disabled}
        value={value}
        onChange={(event, value) => {
          onChange(value?.ref || null);
        }}
        options={options}
        isOptionEqualToValue={(option, value) => option.ref === value?.ref}
        getOptionLabel={(option) => option.displayName || ""}
        renderOption={(props, option) => (
          <li {...props} key={option.ref}>
            <ListItem
              component="div"
              disablePadding
              sx={{ overflow: "hidden" }}
            >
              {option.displayName}
            </ListItem>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ".MuiInputBase-root": {
                paddingTop: "3px",
                paddingBottom: "3px",
              },
            }}
            label={title}
            error={!!error}
          />
        )}
      />
    </FormControl>
  );
};
