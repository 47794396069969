// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/calendar.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { NoteContent } from "./notes_pb.ts";

/**
 * @generated from enum protogen.RecurringUnit
 */
export enum RecurringUnit {
  /**
   * @generated from enum value: RecurringUnit_UNSPECIFIED = 0;
   */
  RecurringUnit_UNSPECIFIED = 0,

  /**
   *  RecurringUnit_DAY = 1;
   *
   * @generated from enum value: RecurringUnit_WEEK = 2;
   */
  RecurringUnit_WEEK = 2,

  /**
   * @generated from enum value: RecurringUnit_MONTH = 3;
   */
  RecurringUnit_MONTH = 3,

  /**
   * @generated from enum value: RecurringUnit_YEAR = 4;
   */
  RecurringUnit_YEAR = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(RecurringUnit)
proto3.util.setEnumType(RecurringUnit, "protogen.RecurringUnit", [
  { no: 0, name: "RecurringUnit_UNSPECIFIED" },
  { no: 2, name: "RecurringUnit_WEEK" },
  { no: 3, name: "RecurringUnit_MONTH" },
  { no: 4, name: "RecurringUnit_YEAR" },
]);

/**
 * @generated from enum protogen.EventType
 */
export enum EventType {
  /**
   * @generated from enum value: EventType_UNSPECIFIED = 0;
   */
  EventType_UNSPECIFIED = 0,

  /**
   * @generated from enum value: EventType_REMINDER = 1;
   */
  EventType_REMINDER = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(EventType)
proto3.util.setEnumType(EventType, "protogen.EventType", [
  { no: 0, name: "EventType_UNSPECIFIED" },
  { no: 1, name: "EventType_REMINDER" },
]);

/**
 * @generated from enum protogen.EventRSVPStatus
 */
export enum EventRSVPStatus {
  /**
   * @generated from enum value: EventRSVPStatus_UNSPECIFIED = 0;
   */
  EventRSVPStatus_UNSPECIFIED = 0,

  /**
   * @generated from enum value: EventRSVPStatus_ACCEPTED = 1;
   */
  EventRSVPStatus_ACCEPTED = 1,

  /**
   * @generated from enum value: EventRSVPStatus_DECLINED = 2;
   */
  EventRSVPStatus_DECLINED = 2,

  /**
   * @generated from enum value: EventRSVPStatus_MAYBE = 3;
   */
  EventRSVPStatus_MAYBE = 3,

  /**
   * @generated from enum value: EventRSVPStatus_PENDING = 4;
   */
  EventRSVPStatus_PENDING = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(EventRSVPStatus)
proto3.util.setEnumType(EventRSVPStatus, "protogen.EventRSVPStatus", [
  { no: 0, name: "EventRSVPStatus_UNSPECIFIED" },
  { no: 1, name: "EventRSVPStatus_ACCEPTED" },
  { no: 2, name: "EventRSVPStatus_DECLINED" },
  { no: 3, name: "EventRSVPStatus_MAYBE" },
  { no: 4, name: "EventRSVPStatus_PENDING" },
]);

/**
 * @generated from message protogen.EventAttendee
 */
export class EventAttendee extends Message<EventAttendee> {
  /**
   * We can probably get by with just one of these.
   *
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string memberRef = 2;
   */
  memberRef = "";

  /**
   * @generated from field: protogen.EventRSVPStatus rsvpStatus = 3;
   */
  rsvpStatus = EventRSVPStatus.EventRSVPStatus_UNSPECIFIED;

  /**
   * @generated from field: int64 creationSec = 4;
   */
  creationSec = protoInt64.zero;

  /**
   * @generated from field: int64 respondedSec = 5;
   */
  respondedSec = protoInt64.zero;

  /**
   * @generated from field: string firstName = 6;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 7;
   */
  lastName = "";

  constructor(data?: PartialMessage<EventAttendee>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EventAttendee";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "memberRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rsvpStatus", kind: "enum", T: proto3.getEnumType(EventRSVPStatus) },
    { no: 4, name: "creationSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "respondedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventAttendee {
    return new EventAttendee().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventAttendee {
    return new EventAttendee().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventAttendee {
    return new EventAttendee().fromJsonString(jsonString, options);
  }

  static equals(a: EventAttendee | PlainMessage<EventAttendee> | undefined, b: EventAttendee | PlainMessage<EventAttendee> | undefined): boolean {
    return proto3.util.equals(EventAttendee, a, b);
  }
}

/**
 * @generated from message protogen.EventReminder
 */
export class EventReminder extends Message<EventReminder> {
  /**
   * @generated from field: string scheduleRef = 1;
   */
  scheduleRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: int32 value = 3;
   */
  value = 0;

  /**
   * @generated from field: string unit = 4;
   */
  unit = "";

  constructor(data?: PartialMessage<EventReminder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EventReminder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "scheduleRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventReminder {
    return new EventReminder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventReminder {
    return new EventReminder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventReminder {
    return new EventReminder().fromJsonString(jsonString, options);
  }

  static equals(a: EventReminder | PlainMessage<EventReminder> | undefined, b: EventReminder | PlainMessage<EventReminder> | undefined): boolean {
    return proto3.util.equals(EventReminder, a, b);
  }
}

/**
 * @generated from message protogen.CalendarEvent
 */
export class CalendarEvent extends Message<CalendarEvent> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string familyRef = 2;
   */
  familyRef = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: protogen.NoteContent details = 4;
   */
  details?: NoteContent;

  /**
   * @generated from field: int64 startSec = 5;
   */
  startSec = protoInt64.zero;

  /**
   * @generated from field: int64 endSec = 6;
   */
  endSec = protoInt64.zero;

  /**
   * @generated from field: bool allDay = 7;
   */
  allDay = false;

  /**
   * For all day events we need to specifically store the start and end dates so there's no
   * ambiguity on timezone converted dates.
   *
   * @generated from field: string startDisplayDate = 8;
   */
  startDisplayDate = "";

  /**
   * @generated from field: string endDisplayDate = 9;
   */
  endDisplayDate = "";

  /**
   * @generated from field: protogen.RecurringUnit recurringUnit = 10;
   */
  recurringUnit = RecurringUnit.RecurringUnit_UNSPECIFIED;

  /**
   * @generated from field: int32 recurringInterval = 11;
   */
  recurringInterval = 0;

  /**
   * @generated from field: int64 recurringUntilSec = 12;
   */
  recurringUntilSec = protoInt64.zero;

  /**
   * @generated from field: repeated protogen.EventAttendee attendees = 13;
   */
  attendees: EventAttendee[] = [];

  /**
   * @generated from field: repeated protogen.EventReminder reminders = 14;
   */
  reminders: EventReminder[] = [];

  /**
   * @generated from field: string displayTimezone = 15;
   */
  displayTimezone = "";

  /**
   * @generated from field: string location = 16;
   */
  location = "";

  /**
   *  EventType eventType = 14;
   * RSVPS, category, etc
   *
   * @generated from field: string transparency = 17;
   */
  transparency = "";

  constructor(data?: PartialMessage<CalendarEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CalendarEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "details", kind: "message", T: NoteContent },
    { no: 5, name: "startSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "endSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "allDay", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "startDisplayDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "endDisplayDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "recurringUnit", kind: "enum", T: proto3.getEnumType(RecurringUnit) },
    { no: 11, name: "recurringInterval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "recurringUntilSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "attendees", kind: "message", T: EventAttendee, repeated: true },
    { no: 14, name: "reminders", kind: "message", T: EventReminder, repeated: true },
    { no: 15, name: "displayTimezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "transparency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CalendarEvent {
    return new CalendarEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CalendarEvent {
    return new CalendarEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CalendarEvent {
    return new CalendarEvent().fromJsonString(jsonString, options);
  }

  static equals(a: CalendarEvent | PlainMessage<CalendarEvent> | undefined, b: CalendarEvent | PlainMessage<CalendarEvent> | undefined): boolean {
    return proto3.util.equals(CalendarEvent, a, b);
  }
}

/**
 * @generated from message protogen.EventNotice
 */
export class EventNotice extends Message<EventNotice> {
  /**
   * @generated from field: protogen.EventNotice.NoticeType noticeType = 1;
   */
  noticeType = EventNotice_NoticeType.NoticeType_UNSPECIFIED;

  /**
   * @generated from field: int64 startSec = 2;
   */
  startSec = protoInt64.zero;

  /**
   * @generated from field: int64 endSec = 3;
   */
  endSec = protoInt64.zero;

  /**
   * @generated from field: bool allDay = 4;
   */
  allDay = false;

  /**
   * @generated from field: protogen.RecurringUnit recurringUnit = 7;
   */
  recurringUnit = RecurringUnit.RecurringUnit_UNSPECIFIED;

  /**
   * @generated from field: int32 recurringInterval = 8;
   */
  recurringInterval = 0;

  /**
   * @generated from field: string title = 9;
   */
  title = "";

  /**
   * @generated from field: string eventRef = 10;
   */
  eventRef = "";

  /**
   * @generated from field: string taskRef = 11;
   */
  taskRef = "";

  /**
   * @generated from field: string familyRef = 12;
   */
  familyRef = "";

  /**
   * @generated from field: string displayTimezone = 13;
   */
  displayTimezone = "";

  /**
   * @generated from field: string startDate = 14;
   */
  startDate = "";

  /**
   * @generated from field: int32 unreadStatusCount = 15;
   */
  unreadStatusCount = 0;

  /**
   * @generated from field: string externalCalendarRef = 16;
   */
  externalCalendarRef = "";

  /**
   * @generated from field: string externalCalendarName = 17;
   */
  externalCalendarName = "";

  /**
   * @generated from field: string externalCalendarId = 18;
   */
  externalCalendarId = "";

  /**
   * @generated from field: string externalAdvisorCalendarRef = 19;
   */
  externalAdvisorCalendarRef = "";

  /**
   * @generated from field: repeated protogen.EventAttendee attendees = 20;
   */
  attendees: EventAttendee[] = [];

  /**
   * @generated from field: string textOnlyDetails = 21;
   */
  textOnlyDetails = "";

  /**
   * @generated from field: string locationString = 22;
   */
  locationString = "";

  /**
   * @generated from field: string endDate = 23;
   */
  endDate = "";

  /**
   * @generated from field: bool hasDuplicate = 24;
   */
  hasDuplicate = false;

  /**
   * @generated from field: int64 recurringReferenceStartSec = 25;
   */
  recurringReferenceStartSec = protoInt64.zero;

  constructor(data?: PartialMessage<EventNotice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EventNotice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "noticeType", kind: "enum", T: proto3.getEnumType(EventNotice_NoticeType) },
    { no: 2, name: "startSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "endSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "allDay", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "recurringUnit", kind: "enum", T: proto3.getEnumType(RecurringUnit) },
    { no: 8, name: "recurringInterval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "eventRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "displayTimezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "unreadStatusCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "externalCalendarRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "externalCalendarName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "externalCalendarId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "externalAdvisorCalendarRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "attendees", kind: "message", T: EventAttendee, repeated: true },
    { no: 21, name: "textOnlyDetails", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "locationString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "hasDuplicate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "recurringReferenceStartSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventNotice {
    return new EventNotice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventNotice {
    return new EventNotice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventNotice {
    return new EventNotice().fromJsonString(jsonString, options);
  }

  static equals(a: EventNotice | PlainMessage<EventNotice> | undefined, b: EventNotice | PlainMessage<EventNotice> | undefined): boolean {
    return proto3.util.equals(EventNotice, a, b);
  }
}

/**
 * @generated from enum protogen.EventNotice.NoticeType
 */
export enum EventNotice_NoticeType {
  /**
   * @generated from enum value: NoticeType_UNSPECIFIED = 0;
   */
  NoticeType_UNSPECIFIED = 0,

  /**
   * @generated from enum value: NoticeType_EVENT = 1;
   */
  NoticeType_EVENT = 1,

  /**
   * @generated from enum value: NoticeType_RECURRING_EVENT = 2;
   */
  NoticeType_RECURRING_EVENT = 2,

  /**
   * @generated from enum value: NoticeType_OVERDUE_TASK = 3;
   */
  NoticeType_OVERDUE_TASK = 3,

  /**
   * @generated from enum value: NoticeType_UPCOMING_TASK = 4;
   */
  NoticeType_UPCOMING_TASK = 4,

  /**
   * @generated from enum value: NoticeType_EXTERNAL_EVENT = 5;
   */
  NoticeType_EXTERNAL_EVENT = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(EventNotice_NoticeType)
proto3.util.setEnumType(EventNotice_NoticeType, "protogen.EventNotice.NoticeType", [
  { no: 0, name: "NoticeType_UNSPECIFIED" },
  { no: 1, name: "NoticeType_EVENT" },
  { no: 2, name: "NoticeType_RECURRING_EVENT" },
  { no: 3, name: "NoticeType_OVERDUE_TASK" },
  { no: 4, name: "NoticeType_UPCOMING_TASK" },
  { no: 5, name: "NoticeType_EXTERNAL_EVENT" },
]);

