import { TableWrapper } from "./CustomTable";
import TiptapTable from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import { TableHeader } from "@tiptap/extension-table-header";
import { CustomTableCell } from "./CustomTableCell";
import { TagParseRule } from "prosemirror-model/dist";

const UNTABLE_CLASS = "-faye-untable";

function commonParseHTML(this: any): TagParseRule[] {
  // Explicitly type the parent rules as an array of ParseRule
  const parentRules: TagParseRule[] = this.parent?.() ?? [];
  return parentRules.map((rule: TagParseRule) => ({
    ...rule,
    getAttrs: (node: Node) => {
      if (node instanceof HTMLElement) {
        // Check if the parent has the given class
        if (node.closest(`.${UNTABLE_CLASS}`)) {
          return false; // Prevent this node from being parsed
        }

        // Call getAttrs with HTMLElement
        return rule.getAttrs ? rule.getAttrs(node) : {};
      }

      // Fallback for unexpected types
      return {};
    },
  }));
}

// Now use the helper function in your extensions:
const __TableHeader = TableHeader.extend({
  parseHTML: commonParseHTML,
});

const __TableWrapper = TableWrapper.extend({
  parseHTML: commonParseHTML,
});

const __TiptapTable = TiptapTable.extend({
  parseHTML: commonParseHTML,
});

const __TableRow = TableRow.extend({
  parseHTML: commonParseHTML,
});

const __CustomTableCell = CustomTableCell.extend({
  parseHTML: commonParseHTML,
});
const getTableExtensions = (editable: boolean) => {
  return [
    // Support tables. Custom table manager when in edit mode.
    ...(editable ? [__TableWrapper] : [__TiptapTable]),
    __TableRow,
    __TableHeader,
    __CustomTableCell,
  ];
};

const markTables = (doc: Document, mark: string = UNTABLE_CLASS) => {
  // Select all tables
  const nestedTables = doc.querySelectorAll("table table");
  if (nestedTables.length === 0) {
    return false;
  }
  // If there exist nested tables, we assume this is an email and we should ignore all tables. Mark them all.
  const tables = doc.querySelectorAll("table");
  tables.forEach((table) => {
    table.classList.add(mark);
  });
  return true;
};

export { getTableExtensions, markTables };
