import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import TwoPanelGrid, { Panel } from "components/details-page/TwoPanelGrid";
import { useDeepgramLiveTranscriptions, useGetPhoneCall } from "services/phone";
import Loading from "components/common/Loading";
import PhoneCallActivityPanel, {
  ActivityEntry,
  aggregateEntries,
} from "components/details-page/PhoneCallActivityPanel";
import { AccountStub } from "protogen/common_pb";
import PhoneCallDetailsPanel from "components/details-page/PhoneCallDetailsPanel";
import useIsVisible from "components/hooks/useIsVisible";
import { SearchContext } from "components/context/SearchContextProvider";
import GridPage from "components/layout/GridPage";
import {
  Alert,
  AlertTitle,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  commaSeparatedEnglishList,
  formatDateForFilename,
  getFormattedDuration,
} from "../common/utils";
import { PhoneCall, PhoneCall_State } from "protogen/conversation_pb";
import Duration from "components/common/Duration";
import { Download } from "lucide-react";
import { LiveTranscriptionsRequest } from "../protogen/phone_service_pb";
import useIsMobile from "../components/hooks/useIsMobile";
import Breadcrumbs from "../components/common/Breadcrumbs";
import { ReactComponent as EllipseIcon } from "icons/Menu/Ellipse.svg";
import DateDisplay from "../components/common/DateDisplay";
import PhoneCallDropDown from "../components/phone/PhoneCallDropDown";

const DownloadTranscript = ({
  phoneCall,
  trackToUser,
}: {
  phoneCall: PhoneCall;
  trackToUser: Record<string, AccountStub>;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { request } = useDeepgramLiveTranscriptions();
  const download = async () => {
    const resp = await request(
      new LiveTranscriptionsRequest({
        callRef: phoneCall.ref,
      }),
    );
    if (!resp) {
      throw new Error("Failed to download transcript");
    }
    return aggregateEntries(resp.segments.filter((s) => s.stable));
  };

  const transcriptToString = (transcript: ActivityEntry[]) => {
    return (
      transcript
        .map((s) => `${trackToUser[s.track].displayName}: ${s.text}`)
        .join("\n") + "\n"
    );
  };

  const copyTranscript = async () => {
    const transcript = await download();
    await navigator.clipboard.writeText(transcriptToString(transcript));
  };
  const downloadTranscript = async () => {
    const transcript = await download();
    const blob = new Blob([transcriptToString(transcript)], {
      type: "text/plain;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `Call-${formatDateForFilename(
        new Date(Number(phoneCall.creationSec) * 1000),
      )}.txt`,
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        aria-label="download options"
      >
        <Download />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={copyTranscript}>Copy to clipboard</MenuItem>
        <MenuItem onClick={downloadTranscript}>Download</MenuItem>
      </Menu>
    </>
  );
};

export default () => {
  const location = useLocation();
  let params = useParams();
  const isMobile = useIsMobile();
  const queryParams = new URLSearchParams(location.search);
  let segmentRefs: string[] = [];
  if (queryParams.get("segmentRefs")) {
    segmentRefs = queryParams.get("segmentRefs")!.split(",");
  }
  const { isVisible } = useIsVisible({});
  const { setPhoneCall } = useContext(SearchContext);
  const { data, request, errorCode } = useGetPhoneCall((r) => {
    setPhoneCall(r.phoneCall);
  });
  const callRef = params.callRef!;

  useEffect(() => {
    request({ callRef: callRef });
    const intervalId = setInterval(async () => {
      if (isVisible) {
        request({ callRef: callRef });
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [callRef, isVisible]);

  if (errorCode === 404) {
    // Only happens when you delete a draft without other threads.
    return (
      <GridPage>
        <Alert severity="warning">
          <AlertTitle>Phone Call Not Found</AlertTitle>
          No phone call with the given ID was found.
        </Alert>
      </GridPage>
    );
  }
  if (!data) {
    return <Loading />;
  }
  const { phoneCall, caller, recipients, callNotes, familyRef } = data;
  const trackToUser: Record<string, AccountStub> = {
    inbound: caller,
    outbound: recipients[0],
  };

  const title = caller.isAdvisor
    ? `Call with ${commaSeparatedEnglishList(
        recipients.map((r) => r.firstName).sort(),
      )}`
    : `Call from ${caller.firstName}`; // Can only receive a call from one person
  const mainHeader = (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box>
        {data?.family?.ref && (
          <Breadcrumbs
            breadcrumbs={[
              {
                name: data.family.name,
                link: `/families/${encodeURIComponent(data.family.ref)}`,
              },
            ]}
          />
        )}
        <Typography
          variant="h1Serif"
          sx={{
            margin: "4px 0",
          }}
        >
          {title}
        </Typography>
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
          gap={"8px"}
          sx={{
            "svg circle": { fill: "#B3AFB6" },
          }}
        >
          <DateDisplay
            variant="body"
            date={new Date(Number(phoneCall.creationSec) * 1000)}
          />
          {!isMobile ? <EllipseIcon height={4} width={4} /> : null}
          <Typography variant={"body"}>
            {phoneCall.state === PhoneCall_State.ACTIVE ? (
              <Duration
                startTime={new Date(Number(phoneCall.creationSec) * 1000)}
              />
            ) : (
              getFormattedDuration(
                Number(phoneCall.lastUpdatedSec),
                Number(phoneCall.creationSec),
              )
            )}
          </Typography>
        </Box>
      </Box>
      <PhoneCallDropDown
        phoneCall={phoneCall}
        familyRef={familyRef}
        refresh={async () => {
          await request({ callRef: callRef });
        }}
      />
    </Box>
  );
  const transcriptHeader = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent:
          phoneCall.state === PhoneCall_State.COMPLETED && !isMobile
            ? "space-between"
            : "start",
        padding: "16px 0",
        gap: "12px",
        borderBottom: "1px solid #975d331a",
      }}
    >
      <Typography variant="h4">Transcript</Typography>
      {phoneCall.state === PhoneCall_State.COMPLETED ? (
        <DownloadTranscript phoneCall={phoneCall} trackToUser={trackToUser} />
      ) : null}
    </Box>
  );
  return (
    <TwoPanelGrid
      defaultPanel={Panel.Right}
      showHeaderAction={!isMobile}
      leftPanel={{
        title: mainHeader,
        switchContent: "Call details",
        content: (
          <PhoneCallDetailsPanel
            familyRef={familyRef}
            suggestions={data.suggestions}
            callNotes={callNotes}
            caller={caller}
            recipient={recipients[0]}
            phoneCall={phoneCall}
          />
        ),
        sx: { overflow: "hidden" },
      }}
      rightPanel={{
        title: isMobile ? mainHeader : transcriptHeader,
        switchContent: "Transcript",
        content: (
          <PhoneCallActivityPanel
            phoneCall={phoneCall}
            trackToUser={trackToUser}
            segmentRefs={segmentRefs ? segmentRefs : undefined}
            refresh={() => request({ callRef: callRef })}
            familyRef={familyRef}
          />
        ),
      }}
    />
  );
};
