import useProtoMethod from "./useProtoMethod";
import {
  CreateFamilyBillingRequest,
  CreateFamilyBillingResponse,
  UpdateFamilyBillingRequest,
  UpdateFamilyBillingResponse,
  CreateMemberBillingRequest,
  CreateMemberBillingResponse,
  ListBillingProductsResponse,
  ListBillingProductsRequest,
  ListBillingCouponsResponse,
  ListBillingCouponsRequest,
  ListInvoicesRequest,
  ListInvoicesResponse,
  UpdateInvoiceResponse,
  UpdateInvoiceRequest,
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
  CreateUnlinkedBillingRequest,
  CreateUnlinkedBillingResponse,
  ValidatePromoCodeRequest,
  ValidatePromoCodeResponse,
} from "protogen/billing_service_pb";

const useUpdateFamilyBilling = (
  callback?: (r: UpdateFamilyBillingResponse) => void,
) => {
  return useProtoMethod<
    UpdateFamilyBillingRequest,
    UpdateFamilyBillingResponse
  >("UpdateFamilyBilling", UpdateFamilyBillingResponse, { callback });
};

const useCreateFamilyBilling = (
  callback?: (r: CreateFamilyBillingResponse) => void,
) => {
  return useProtoMethod<
    CreateFamilyBillingRequest,
    CreateFamilyBillingResponse
  >("CreateFamilyBilling", CreateFamilyBillingResponse, { callback });
};

const useCreateMemberBilling = (
  callback?: (r: CreateMemberBillingResponse) => void,
) => {
  return useProtoMethod<
    CreateMemberBillingRequest,
    CreateMemberBillingResponse
  >("CreateMemberBilling", CreateMemberBillingResponse, { callback });
};

const useCreateUnlinkedBilling = (
  callback?: (r: CreateUnlinkedBillingResponse) => void,
) => {
  return useProtoMethod<
    CreateUnlinkedBillingRequest,
    CreateUnlinkedBillingResponse
  >("CreateUnlinkedBilling", CreateUnlinkedBillingResponse, { callback });
};

const useListBillingProducts = (
  callback?: (r: ListBillingProductsResponse) => void,
) => {
  return useProtoMethod<
    ListBillingProductsRequest,
    ListBillingProductsResponse
  >("ListBillingProducts", ListBillingProductsResponse, { callback });
};

const useListBillingCoupons = (
  callback?: (r: ListBillingCouponsResponse) => void,
) => {
  return useProtoMethod<ListBillingCouponsRequest, ListBillingCouponsResponse>(
    "ListBillingCoupons",
    ListBillingCouponsResponse,
    { callback },
  );
};

const useListInvoices = (callback?: (r: ListInvoicesResponse) => void) => {
  return useProtoMethod<ListInvoicesRequest, ListInvoicesResponse>(
    "ListInvoices",
    ListInvoicesResponse,
    { callback },
  );
};

const useUpdateInvoice = (callback?: (r: UpdateInvoiceResponse) => void) => {
  return useProtoMethod<UpdateInvoiceRequest, UpdateInvoiceResponse>(
    "UpdateInvoice",
    UpdateInvoiceResponse,
    { callback },
  );
};

const useCreatePaymentIntent = (
  callback?: (r: CreatePaymentIntentResponse) => void,
) => {
  return useProtoMethod<
    CreatePaymentIntentRequest,
    CreatePaymentIntentResponse
  >("CreatePaymentIntent", CreatePaymentIntentResponse, { callback });
};

const useValidatePromoCode = (
  callback?: (r: ValidatePromoCodeResponse) => void,
) => {
  return useProtoMethod<ValidatePromoCodeRequest, ValidatePromoCodeResponse>(
    "ValidatePromoCode",
    ValidatePromoCodeResponse,
    { callback },
  );
};

export {
  useCreateFamilyBilling,
  useUpdateFamilyBilling,
  useCreateMemberBilling,
  useListBillingProducts,
  useListBillingCoupons,
  useListInvoices,
  useUpdateInvoice,
  useCreatePaymentIntent,
  useCreateUnlinkedBilling,
  useValidatePromoCode,
};
