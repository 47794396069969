import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, MenuItem } from "@mui/material";
import {
  ListDocumentsRequest,
  UpdateDocumentRequest,
  CopyDocumentRequest,
} from "protogen/documents_service_pb";
import { Family, FamilyStatus } from "protogen/advisors_service_pb";
import { Document, DocumentType, DocumentStatus } from "protogen/documents_pb";
import GridPage from "components/layout/GridPage";
import useIsMobile from "components/hooks/useIsMobile";
import useIsDesktop from "components/hooks/useIsDesktop";
import {
  useCreateDocument,
  useListDocuments,
  useUpdateDocument,
  useCopyDocument,
} from "services/documents";
import { useListAdvisorFamilies } from "services/advisor";
import SearchBar from "components/forum/SearchBar";
import { Filter } from "components/search/types";
import { entityTypeInFilter } from "components/search/utils";
import { useSearch } from "services/search";
import { RetrievalResult } from "protogen/search_service_pb";
import { StyledMenu } from "components/common/DropdownMenu";
import Checkbox from "components/common/Checkbox";
import DocumentsTable from "components/documents/DocumentsTable";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "components/common/ConfirmationDialog";
import Loading from "components/common/Loading";

const FilterMenu = ({
  families,
  anchorEl,
  open,
  onClose,
  checkedFamilies,
  setCheckedFamilies,
  sortOrder,
  setSortOrder,
}: {
  families: Family[];
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  checkedFamilies: Set<string>;
  setCheckedFamilies: (families: Set<string>) => void;
  sortOrder: string;
  setSortOrder: (sortOrder: string) => void;
}) => {
  return (
    <StyledMenu anchorEl={anchorEl} open={open} onClose={onClose}>
      <Box>
        <Typography variant="bodyHeavy" sx={{ paddingLeft: "20px" }}>
          Family
        </Typography>
        {families.map((family) => (
          <MenuItem
            key={family.ref}
            onClick={() => {
              const newFilters = new Set(checkedFamilies);
              if (newFilters.has(family.ref)) {
                newFilters.delete(family.ref);
              } else {
                newFilters.add(family.ref);
              }
              setCheckedFamilies(newFilters);
            }}
            disableRipple
            sx={{
              gap: "2px",
              padding: "3px 21px 3px 12px",
            }}
          >
            <Checkbox disableRipple checked={checkedFamilies.has(family.ref)} />
            {family.name}
          </MenuItem>
        ))}
        <Typography variant="bodyHeavy" sx={{ paddingLeft: "20px" }}>
          Sort by
        </Typography>
        <MenuItem
          onClick={() => {
            setSortOrder("last-updated");
          }}
          disableRipple
          sx={{
            gap: "2px",
            padding: "3px 21px 3px 12px",
          }}
        >
          <Checkbox disableRipple checked={sortOrder === "last-updated"} />
          Last updated
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortOrder("title");
          }}
          disableRipple
          sx={{
            gap: "2px",
            padding: "3px 21px 3px 12px",
          }}
        >
          <Checkbox disableRipple checked={sortOrder === "title"} />
          Name
        </MenuItem>
      </Box>
    </StyledMenu>
  );
};

const getSearchFilters = (): Filter[] => {
  const filters = ["document"];
  return [entityTypeInFilter(filters)];
};

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [families, setFamilies] = useState<Family[]>([]);
  const [allDocuments, setAllDocuments] = useState<Document[]>([]);
  const [filteredAndSortedDocuments, setFilteredAndSortedDocuments] = useState<
    Document[] | null
  >(null);
  const [sortOrder, setSortOrder] = useState<string>("last-updated");
  const [query, setQuery] = useState<string>("");
  const { request: familiesRequest } = useListAdvisorFamilies();
  const { request: createDocumentRequest } = useCreateDocument();
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const { request: listDocumentsRequest } = useListDocuments();
  const { request: copyDocumentRequest } = useCopyDocument();
  const { request: updateDocumentRequest } = useUpdateDocument();
  const { request: searchRequest, data: searchData } = useSearch();
  const confirmState = useConfirmationDialog();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [checkedFamilies, setCheckedFamilies] = useState<Set<string>>(
    new Set(),
  );

  const createDocument = async () => {
    const result = await createDocumentRequest();
    navigate(`/documents/${encodeURIComponent(result?.document?.ref || "")}`);
  };

  const _activeFamilies = (families_?: Family[]) =>
    (families_ || []).filter(
      (f) =>
        f.status !== FamilyStatus.DEACTIVATED &&
        f.status !== FamilyStatus.DEACTIVATED_PROSPECT,
    );

  const onLoad = async () => {
    const familiesData = await familiesRequest();
    const loadedFamilies = familiesData?.families || [];
    const activeFamilies = _activeFamilies(loadedFamilies);
    setFamilies(activeFamilies);
    setCheckedFamilies(new Set(activeFamilies.map((f) => f.ref)));

    listDocuments(activeFamilies.map((f) => f.ref) || []);
  };

  const listDocuments = async (familyRefs: string[]) => {
    const r = await listDocumentsRequest(
      new ListDocumentsRequest({
        includeAdvisorDocuments: true,
        documentTypes: [DocumentType.NOTE],
        familyRefs: familyRefs,
      }),
    );
    if (!r) return;
    // Maybe in the future when we have more document types, we can filter at the API level.
    const filteredDocuments = r.documents.filter(
      (d) => d.creationSource !== "family-quick-note",
    );
    setAllDocuments(filteredDocuments);
    setHasLoadedOnce(true);
  };
  const handleQuery = async (query: string) => {
    if (!query) return;
    searchRequest({
      queryText: query,
      adminSearchMode: false,
      strictlyChronological: false,
      maxResults: 10,
      filters: getSearchFilters().map((filter) => ({
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
      })),
    });
  };

  const onDuplicate = async (document: Document) => {
    const entityType = document?.inboxType === "family" ? "family" : "advisor";
    await copyDocumentRequest(
      new CopyDocumentRequest({
        fromDocumentType: document?.documentType,
        fromEntityType: entityType,
        fromEntityRef: document?.ref,
        toDocumentType: document?.documentType,
        toEntityType: entityType,
        toEntityRef: document?.inboxEntityRef,
        creationSourceName: "note",
        title: document?.title,
        replaceTitle: true,
      }),
    );
    listDocuments(families.map((f) => f.ref) || []);
  };

  const onDelete = async (document: Document) => {
    confirmState.openDialog(async () => {
      await updateDocumentRequest(
        new UpdateDocumentRequest({
          documentRef: document.ref,
          status: DocumentStatus.DELETED,
        }),
      );
      listDocuments(families.map((f) => f.ref) || []);
    });
  };

  useEffect(() => {
    if (!hasLoadedOnce) return;
    let filtered = allDocuments;
    if (query && searchData?.results) {
      const documentMap = allDocuments.reduce(
        (acc: Record<string, Document>, d: Document) => {
          acc[d.ref] = d;
          return acc;
        },
        {},
      );
      filtered = searchData.results
        .map((item: RetrievalResult) => documentMap[item.entityRef])
        .filter((d) => !!d)
        .map((d) => d as Document);
    }
    if (sortOrder === "last-updated") {
      filtered = filtered.sort((a, b) => {
        return (
          new Date(Number(b.lastUpdatedSec) * 1000).getTime() -
          new Date(Number(a.lastUpdatedSec) * 1000).getTime()
        );
      });
    } else {
      filtered = filtered.sort((a, b) => {
        const titleA = (a.title || "Untitled").toLowerCase();
        const titleB = (b.title || "Untitled").toLowerCase();
        return titleA.localeCompare(titleB);
      });
    }
    setFilteredAndSortedDocuments(filtered || []);
  }, [allDocuments, searchData, query, checkedFamilies, sortOrder]);

  useEffect(() => {
    handleQuery(query);
  }, [query]);

  useEffect(() => {
    if (hasLoadedOnce) {
      listDocuments(Array.from(checkedFamilies));
    }
  }, [checkedFamilies]);

  useEffect(() => {
    setMenuOpen(false);
  }, [sortOrder]);

  useEffect(() => {
    onLoad();
  }, []);
  return (
    <GridPage
      sx={{
        maxWidth: "1000px",
        margin: isMobile ? "" : "auto",
        padding: isMobile ? "32px 24px" : "64px 48px",
        gap: "20px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Typography variant="display">Documents</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: isMobile ? "start" : "center",
              flexDirection: isMobile ? "column" : "row",
              width: isMobile ? "100%" : "auto",
            }}
          >
            <SearchBar
              captureHotkeys={true}
              onQuery={(q) => setQuery(q)}
              expand={false}
              fullWidth={true}
              sx={{
                width: isMobile ? "100%" : "auto",
                maxWidth: isDesktop ? "280px" : "none",
              }}
              debounceTimeoutMs={1200}
            />
            <Box
              sx={{
                display: "flex",
                width: isMobile ? "100%" : "auto",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={(event) => {
                  setMenuAnchorEl(event.currentTarget);
                  setMenuOpen(true);
                }}
              >
                Filters
              </Button>
              {isMobile && (
                <Button size="small" onClick={createDocument}>
                  Create document
                </Button>
              )}
            </Box>
          </Box>
          {!isMobile && (
            <Button size="small" onClick={createDocument}>
              Create document
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        {!filteredAndSortedDocuments ? (
          <Loading />
        ) : (
          <DocumentsTable
            documents={filteredAndSortedDocuments}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
          />
        )}
      </Box>
      <FilterMenu
        families={families}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
          setMenuAnchorEl(null);
        }}
        checkedFamilies={checkedFamilies}
        setCheckedFamilies={setCheckedFamilies}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
      />
      <ConfirmationDialog
        content={"Are you sure you want to remove this document?"}
        {...confirmState.dialogProps}
      />
    </GridPage>
  );
};
