import { useState } from "react";

type ReturnType<T> = [T, (c: T) => void];

// Like useState, but also caches the value in sessionStorage.
// Uses session storage so that the value is not persisted in perpetuity.
function useCached<T>(key: string | null, defaultVal: T): ReturnType<T> {
  let itemVal: T = defaultVal;
  try {
    const cacheVal = key ? localStorage.getItem(key) : null;
    if (cacheVal !== null) {
      itemVal = JSON.parse(cacheVal);
    }
  } catch (e) {
    console.error(`Error loading cached value: ${e}`);
  }
  const [content, setContent] = useState<T>(itemVal);

  const setter = (c: T) => {
    if (c === null || c === undefined) {
      if (key !== null) {
        localStorage.removeItem(key);
      }
    } else {
      if (key !== null) {
        try {
          localStorage.setItem(key, JSON.stringify(c));
        } catch (e) {
          // Quote probably exceeded storage quota
          console.error(`Error saving cached value: ${e}`);
        }
      }
    }
    setContent(c);
  };
  return [content, setter];
}

export default useCached;
