// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/signup_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { EntityMetadata, FamilyStub } from "./common_pb.ts";

/**
 * @generated from message protogen.SignupStateChoiceValue
 */
export class SignupStateChoiceValue extends Message<SignupStateChoiceValue> {
  /**
   * @generated from field: bool value = 1;
   */
  value = false;

  /**
   * @generated from field: string valueString = 2;
   */
  valueString = "";

  constructor(data?: PartialMessage<SignupStateChoiceValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignupStateChoiceValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "valueString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupStateChoiceValue {
    return new SignupStateChoiceValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupStateChoiceValue {
    return new SignupStateChoiceValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupStateChoiceValue {
    return new SignupStateChoiceValue().fromJsonString(jsonString, options);
  }

  static equals(a: SignupStateChoiceValue | PlainMessage<SignupStateChoiceValue> | undefined, b: SignupStateChoiceValue | PlainMessage<SignupStateChoiceValue> | undefined): boolean {
    return proto3.util.equals(SignupStateChoiceValue, a, b);
  }
}

/**
 * @generated from message protogen.Payment
 */
export class Payment extends Message<Payment> {
  /**
   * @generated from field: string paymentMethodId = 1;
   */
  paymentMethodId = "";

  /**
   * @generated from field: string customerId = 2;
   */
  customerId = "";

  /**
   * @generated from field: string promoCode = 3;
   */
  promoCode = "";

  /**
   * @generated from field: string couponId = 4;
   */
  couponId = "";

  constructor(data?: PartialMessage<Payment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Payment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "paymentMethodId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "promoCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "couponId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Payment {
    return new Payment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Payment {
    return new Payment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Payment {
    return new Payment().fromJsonString(jsonString, options);
  }

  static equals(a: Payment | PlainMessage<Payment> | undefined, b: Payment | PlainMessage<Payment> | undefined): boolean {
    return proto3.util.equals(Payment, a, b);
  }
}

/**
 * @generated from message protogen.Membership
 */
export class Membership extends Message<Membership> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int32 supportHours = 2;
   */
  supportHours = 0;

  /**
   * @generated from field: int32 maxUsers = 3;
   */
  maxUsers = 0;

  /**
   * @generated from field: int32 amountCents = 4;
   */
  amountCents = 0;

  /**
   * @generated from field: repeated string additionalFeatures = 5;
   */
  additionalFeatures: string[] = [];

  constructor(data?: PartialMessage<Membership>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Membership";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "supportHours", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "maxUsers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "amountCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "additionalFeatures", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Membership {
    return new Membership().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Membership {
    return new Membership().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Membership {
    return new Membership().fromJsonString(jsonString, options);
  }

  static equals(a: Membership | PlainMessage<Membership> | undefined, b: Membership | PlainMessage<Membership> | undefined): boolean {
    return proto3.util.equals(Membership, a, b);
  }
}

/**
 * @generated from message protogen.UserInfo
 */
export class UserInfo extends Message<UserInfo> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: string address = 5;
   */
  address = "";

  constructor(data?: PartialMessage<UserInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UserInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInfo {
    return new UserInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJsonString(jsonString, options);
  }

  static equals(a: UserInfo | PlainMessage<UserInfo> | undefined, b: UserInfo | PlainMessage<UserInfo> | undefined): boolean {
    return proto3.util.equals(UserInfo, a, b);
  }
}

/**
 * Consider merging with UserInfo.
 *
 * @generated from message protogen.SignupConsultationDetails
 */
export class SignupConsultationDetails extends Message<SignupConsultationDetails> {
  /**
   * @generated from field: string firstName = 1;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: string zipCode = 5;
   */
  zipCode = "";

  constructor(data?: PartialMessage<SignupConsultationDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignupConsultationDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "zipCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupConsultationDetails {
    return new SignupConsultationDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupConsultationDetails {
    return new SignupConsultationDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupConsultationDetails {
    return new SignupConsultationDetails().fromJsonString(jsonString, options);
  }

  static equals(a: SignupConsultationDetails | PlainMessage<SignupConsultationDetails> | undefined, b: SignupConsultationDetails | PlainMessage<SignupConsultationDetails> | undefined): boolean {
    return proto3.util.equals(SignupConsultationDetails, a, b);
  }
}

/**
 * @generated from message protogen.SignupState
 */
export class SignupState extends Message<SignupState> {
  /**
   * @generated from field: string stepName = 1;
   */
  stepName = "";

  /**
   * @generated from field: map<string, protogen.SignupStateChoiceValue> goals = 2;
   */
  goals: { [key: string]: SignupStateChoiceValue } = {};

  /**
   * @generated from field: map<string, protogen.SignupStateChoiceValue> household = 3;
   */
  household: { [key: string]: SignupStateChoiceValue } = {};

  /**
   * @generated from field: map<string, protogen.SignupStateChoiceValue> superpowers = 4;
   */
  superpowers: { [key: string]: SignupStateChoiceValue } = {};

  /**
   * @generated from field: protogen.Payment payment = 5;
   */
  payment?: Payment;

  /**
   * @generated from field: string consultationEventUri = 6;
   */
  consultationEventUri = "";

  /**
   * @generated from field: string consultationInviteeUri = 7;
   */
  consultationInviteeUri = "";

  /**
   * @generated from field: int64 consultationEventStartSec = 8;
   */
  consultationEventStartSec = protoInt64.zero;

  /**
   * @generated from field: string consultationEventHost = 9;
   */
  consultationEventHost = "";

  /**
   * @generated from field: protogen.Membership membership = 10;
   */
  membership?: Membership;

  /**
   * @generated from field: protogen.UserInfo userInfo = 11;
   */
  userInfo?: UserInfo;

  /**
   * @generated from field: protogen.SignupConsultationDetails consultationDetails = 12;
   */
  consultationDetails?: SignupConsultationDetails;

  /**
   * @generated from field: string familyInfo = 13;
   */
  familyInfo = "";

  /**
   * @generated from field: map<string, protogen.SignupStateChoiceValue> fun = 14;
   */
  fun: { [key: string]: SignupStateChoiceValue } = {};

  /**
   * @generated from field: map<string, protogen.SignupStateChoiceValue> lifeEvents = 15;
   */
  lifeEvents: { [key: string]: SignupStateChoiceValue } = {};

  /**
   * @generated from field: string anythingElse = 16;
   */
  anythingElse = "";

  /**
   * @generated from field: string referralSource = 17;
   */
  referralSource = "";

  /**
   * @generated from field: string referralCode = 18;
   */
  referralCode = "";

  constructor(data?: PartialMessage<SignupState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignupState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stepName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "goals", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SignupStateChoiceValue} },
    { no: 3, name: "household", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SignupStateChoiceValue} },
    { no: 4, name: "superpowers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SignupStateChoiceValue} },
    { no: 5, name: "payment", kind: "message", T: Payment },
    { no: 6, name: "consultationEventUri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "consultationInviteeUri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "consultationEventStartSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "consultationEventHost", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "membership", kind: "message", T: Membership },
    { no: 11, name: "userInfo", kind: "message", T: UserInfo },
    { no: 12, name: "consultationDetails", kind: "message", T: SignupConsultationDetails },
    { no: 13, name: "familyInfo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "fun", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SignupStateChoiceValue} },
    { no: 15, name: "lifeEvents", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SignupStateChoiceValue} },
    { no: 16, name: "anythingElse", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "referralSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "referralCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupState {
    return new SignupState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupState {
    return new SignupState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupState {
    return new SignupState().fromJsonString(jsonString, options);
  }

  static equals(a: SignupState | PlainMessage<SignupState> | undefined, b: SignupState | PlainMessage<SignupState> | undefined): boolean {
    return proto3.util.equals(SignupState, a, b);
  }
}

/**
 * @generated from message protogen.GetOrCreateSessionRequest
 */
export class GetOrCreateSessionRequest extends Message<GetOrCreateSessionRequest> {
  /**
   * @generated from field: map<string, string> trackingParameters = 1;
   */
  trackingParameters: { [key: string]: string } = {};

  constructor(data?: PartialMessage<GetOrCreateSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetOrCreateSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "trackingParameters", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrCreateSessionRequest {
    return new GetOrCreateSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrCreateSessionRequest {
    return new GetOrCreateSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrCreateSessionRequest {
    return new GetOrCreateSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrCreateSessionRequest | PlainMessage<GetOrCreateSessionRequest> | undefined, b: GetOrCreateSessionRequest | PlainMessage<GetOrCreateSessionRequest> | undefined): boolean {
    return proto3.util.equals(GetOrCreateSessionRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetOrCreateSessionResponse
 */
export class GetOrCreateSessionResponse extends Message<GetOrCreateSessionResponse> {
  /**
   * @generated from field: protogen.SignupState state = 1;
   */
  state?: SignupState;

  constructor(data?: PartialMessage<GetOrCreateSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetOrCreateSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "message", T: SignupState },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrCreateSessionResponse {
    return new GetOrCreateSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrCreateSessionResponse {
    return new GetOrCreateSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrCreateSessionResponse {
    return new GetOrCreateSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrCreateSessionResponse | PlainMessage<GetOrCreateSessionResponse> | undefined, b: GetOrCreateSessionResponse | PlainMessage<GetOrCreateSessionResponse> | undefined): boolean {
    return proto3.util.equals(GetOrCreateSessionResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateSessionRequest
 */
export class UpdateSessionRequest extends Message<UpdateSessionRequest> {
  /**
   * @generated from field: string updatedStepName = 1;
   */
  updatedStepName = "";

  /**
   * Fields.
   *
   * @generated from field: protogen.SignupState updatedState = 2;
   */
  updatedState?: SignupState;

  /**
   * @generated from field: bool shouldUpdateGoals = 3;
   */
  shouldUpdateGoals = false;

  /**
   * @generated from field: bool shouldUpdateHousehold = 4;
   */
  shouldUpdateHousehold = false;

  /**
   * @generated from field: bool shouldUpdateSuperpowers = 5;
   */
  shouldUpdateSuperpowers = false;

  /**
   * @generated from field: bool shouldUpdatePayment = 6;
   */
  shouldUpdatePayment = false;

  /**
   * @generated from field: bool shouldUpdateMembership = 7;
   */
  shouldUpdateMembership = false;

  /**
   * @generated from field: bool shouldUpdateUserInfo = 8;
   */
  shouldUpdateUserInfo = false;

  /**
   * @generated from field: bool shouldUpdateConsultationDetails = 9;
   */
  shouldUpdateConsultationDetails = false;

  /**
   * @generated from field: bool shouldUpdateConsultation = 10;
   */
  shouldUpdateConsultation = false;

  /**
   * @generated from field: bool shouldUpdateFamilyInfo = 11;
   */
  shouldUpdateFamilyInfo = false;

  /**
   * @generated from field: bool shouldUpdateFun = 12;
   */
  shouldUpdateFun = false;

  /**
   * @generated from field: bool shouldUpdateLifeEvents = 13;
   */
  shouldUpdateLifeEvents = false;

  /**
   * @generated from field: bool shouldUpdateAnythingElse = 14;
   */
  shouldUpdateAnythingElse = false;

  /**
   * @generated from field: bool shouldUpdateReferralSource = 15;
   */
  shouldUpdateReferralSource = false;

  constructor(data?: PartialMessage<UpdateSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updatedStepName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedState", kind: "message", T: SignupState },
    { no: 3, name: "shouldUpdateGoals", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "shouldUpdateHousehold", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "shouldUpdateSuperpowers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "shouldUpdatePayment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "shouldUpdateMembership", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "shouldUpdateUserInfo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "shouldUpdateConsultationDetails", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "shouldUpdateConsultation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "shouldUpdateFamilyInfo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "shouldUpdateFun", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "shouldUpdateLifeEvents", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "shouldUpdateAnythingElse", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "shouldUpdateReferralSource", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSessionRequest {
    return new UpdateSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSessionRequest {
    return new UpdateSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSessionRequest {
    return new UpdateSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSessionRequest | PlainMessage<UpdateSessionRequest> | undefined, b: UpdateSessionRequest | PlainMessage<UpdateSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSessionRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateSessionResponse
 */
export class UpdateSessionResponse extends Message<UpdateSessionResponse> {
  /**
   * @generated from field: protogen.SignupState state = 1;
   */
  state?: SignupState;

  constructor(data?: PartialMessage<UpdateSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "message", T: SignupState },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSessionResponse {
    return new UpdateSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSessionResponse {
    return new UpdateSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSessionResponse {
    return new UpdateSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSessionResponse | PlainMessage<UpdateSessionResponse> | undefined, b: UpdateSessionResponse | PlainMessage<UpdateSessionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSessionResponse, a, b);
  }
}

/**
 * @generated from message protogen.SignupSubmissionRequest
 */
export class SignupSubmissionRequest extends Message<SignupSubmissionRequest> {
  /**
   * @generated from field: string submissionState = 1;
   */
  submissionState = "";

  constructor(data?: PartialMessage<SignupSubmissionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignupSubmissionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "submissionState", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupSubmissionRequest {
    return new SignupSubmissionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupSubmissionRequest {
    return new SignupSubmissionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupSubmissionRequest {
    return new SignupSubmissionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignupSubmissionRequest | PlainMessage<SignupSubmissionRequest> | undefined, b: SignupSubmissionRequest | PlainMessage<SignupSubmissionRequest> | undefined): boolean {
    return proto3.util.equals(SignupSubmissionRequest, a, b);
  }
}

/**
 * @generated from message protogen.SignupSubmissionResponse
 */
export class SignupSubmissionResponse extends Message<SignupSubmissionResponse> {
  /**
   * @generated from field: protogen.SignupState state = 1;
   */
  state?: SignupState;

  constructor(data?: PartialMessage<SignupSubmissionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignupSubmissionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "message", T: SignupState },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupSubmissionResponse {
    return new SignupSubmissionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupSubmissionResponse {
    return new SignupSubmissionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupSubmissionResponse {
    return new SignupSubmissionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignupSubmissionResponse | PlainMessage<SignupSubmissionResponse> | undefined, b: SignupSubmissionResponse | PlainMessage<SignupSubmissionResponse> | undefined): boolean {
    return proto3.util.equals(SignupSubmissionResponse, a, b);
  }
}

/**
 * @generated from message protogen.SignupSubmission
 */
export class SignupSubmission extends Message<SignupSubmission> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: protogen.SignupState state = 2;
   */
  state?: SignupState;

  /**
   * @generated from field: protogen.FamilyStub family = 3;
   */
  family?: FamilyStub;

  /**
   * @generated from field: string pipelineStatus = 4;
   */
  pipelineStatus = "";

  /**
   * @generated from field: protogen.EntityMetadata metadata = 5;
   */
  metadata?: EntityMetadata;

  /**
   * @generated from field: map<string, string> trackingParameters = 6;
   */
  trackingParameters: { [key: string]: string } = {};

  /**
   * @generated from field: int64 completionSec = 7;
   */
  completionSec = protoInt64.zero;

  /**
   * @generated from field: string blurb = 8;
   */
  blurb = "";

  constructor(data?: PartialMessage<SignupSubmission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SignupSubmission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "message", T: SignupState },
    { no: 3, name: "family", kind: "message", T: FamilyStub },
    { no: 4, name: "pipelineStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "metadata", kind: "message", T: EntityMetadata },
    { no: 6, name: "trackingParameters", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 7, name: "completionSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "blurb", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupSubmission {
    return new SignupSubmission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupSubmission {
    return new SignupSubmission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupSubmission {
    return new SignupSubmission().fromJsonString(jsonString, options);
  }

  static equals(a: SignupSubmission | PlainMessage<SignupSubmission> | undefined, b: SignupSubmission | PlainMessage<SignupSubmission> | undefined): boolean {
    return proto3.util.equals(SignupSubmission, a, b);
  }
}

/**
 * @generated from message protogen.ListSubmissionsRequest
 */
export class ListSubmissionsRequest extends Message<ListSubmissionsRequest> {
  /**
   * @generated from field: repeated string submissionStates = 1;
   */
  submissionStates: string[] = [];

  /**
   * @generated from field: repeated string submissionRefs = 2;
   */
  submissionRefs: string[] = [];

  /**
   * @generated from field: protogen.ListSubmissionsRequest.ListSubmissionsFilter filter = 3;
   */
  filter = ListSubmissionsRequest_ListSubmissionsFilter.UNKNOWN;

  constructor(data?: PartialMessage<ListSubmissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListSubmissionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "submissionStates", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "submissionRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "filter", kind: "enum", T: proto3.getEnumType(ListSubmissionsRequest_ListSubmissionsFilter) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSubmissionsRequest {
    return new ListSubmissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSubmissionsRequest {
    return new ListSubmissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSubmissionsRequest {
    return new ListSubmissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSubmissionsRequest | PlainMessage<ListSubmissionsRequest> | undefined, b: ListSubmissionsRequest | PlainMessage<ListSubmissionsRequest> | undefined): boolean {
    return proto3.util.equals(ListSubmissionsRequest, a, b);
  }
}

/**
 * @generated from enum protogen.ListSubmissionsRequest.ListSubmissionsFilter
 */
export enum ListSubmissionsRequest_ListSubmissionsFilter {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: AWAITING_ACTION = 1;
   */
  AWAITING_ACTION = 1,

  /**
   * @generated from enum value: DECLINED = 2;
   */
  DECLINED = 2,

  /**
   * @generated from enum value: CONVERTED = 3;
   */
  CONVERTED = 3,

  /**
   * @generated from enum value: ALL = 4;
   */
  ALL = 4,

  /**
   * @generated from enum value: UPCOMING_CONSULTATION = 5;
   */
  UPCOMING_CONSULTATION = 5,

  /**
   * @generated from enum value: MAYBE_LATER = 6;
   */
  MAYBE_LATER = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ListSubmissionsRequest_ListSubmissionsFilter)
proto3.util.setEnumType(ListSubmissionsRequest_ListSubmissionsFilter, "protogen.ListSubmissionsRequest.ListSubmissionsFilter", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "AWAITING_ACTION" },
  { no: 2, name: "DECLINED" },
  { no: 3, name: "CONVERTED" },
  { no: 4, name: "ALL" },
  { no: 5, name: "UPCOMING_CONSULTATION" },
  { no: 6, name: "MAYBE_LATER" },
]);

/**
 * @generated from message protogen.ListSubmissionsResponse
 */
export class ListSubmissionsResponse extends Message<ListSubmissionsResponse> {
  /**
   * @generated from field: repeated protogen.SignupSubmission submissions = 1;
   */
  submissions: SignupSubmission[] = [];

  constructor(data?: PartialMessage<ListSubmissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListSubmissionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "submissions", kind: "message", T: SignupSubmission, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSubmissionsResponse {
    return new ListSubmissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSubmissionsResponse {
    return new ListSubmissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSubmissionsResponse {
    return new ListSubmissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSubmissionsResponse | PlainMessage<ListSubmissionsResponse> | undefined, b: ListSubmissionsResponse | PlainMessage<ListSubmissionsResponse> | undefined): boolean {
    return proto3.util.equals(ListSubmissionsResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateSubmissionRequest
 */
export class UpdateSubmissionRequest extends Message<UpdateSubmissionRequest> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string updatedPipelineStatus = 2;
   */
  updatedPipelineStatus = "";

  /**
   * @generated from field: bool shouldUpdateMetadata = 3;
   */
  shouldUpdateMetadata = false;

  /**
   * @generated from field: protogen.EntityMetadata updatedMetadata = 4;
   */
  updatedMetadata?: EntityMetadata;

  constructor(data?: PartialMessage<UpdateSubmissionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateSubmissionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedPipelineStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "shouldUpdateMetadata", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "updatedMetadata", kind: "message", T: EntityMetadata },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSubmissionRequest {
    return new UpdateSubmissionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSubmissionRequest {
    return new UpdateSubmissionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSubmissionRequest {
    return new UpdateSubmissionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSubmissionRequest | PlainMessage<UpdateSubmissionRequest> | undefined, b: UpdateSubmissionRequest | PlainMessage<UpdateSubmissionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSubmissionRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateSubmissionResponse
 */
export class UpdateSubmissionResponse extends Message<UpdateSubmissionResponse> {
  /**
   * @generated from field: protogen.SignupSubmission submission = 1;
   */
  submission?: SignupSubmission;

  constructor(data?: PartialMessage<UpdateSubmissionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateSubmissionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "submission", kind: "message", T: SignupSubmission },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSubmissionResponse {
    return new UpdateSubmissionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSubmissionResponse {
    return new UpdateSubmissionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSubmissionResponse {
    return new UpdateSubmissionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSubmissionResponse | PlainMessage<UpdateSubmissionResponse> | undefined, b: UpdateSubmissionResponse | PlainMessage<UpdateSubmissionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSubmissionResponse, a, b);
  }
}

