import React, { forwardRef, useImperativeHandle } from "react";
import { ChoiceSelections, StepProps, StepRef } from "../../types";
import ChoiceStep from "../../components/ChoiceStep";
import { Baby, TreePalm, Truck, Heart } from "lucide-react";
export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }: StepProps, ref) => {
    useImperativeHandle(ref, () => ({}));
    return (
      <ChoiceStep
        title={"Are there any exciting events coming up?"}
        subtitle={"This will help your Advisor make proactive suggestions."}
        disabled={disabled}
        skippable={true}
        choiceOptions={[
          {
            id: "expecting",
            text: "I’m expecting a child",
            icon: <Baby />,
          },
          {
            id: "vacation",
            text: "I'm planning a vacation",
            icon: <TreePalm />,
          },
          {
            id: "move",
            text: "I'm planning a move",
            icon: <Truck />,
          },
          {
            id: "wedding",
            text: "I'm planning a wedding",
            icon: <Heart />,
          },
          {
            id: "other",
            text: "Something else...",
            freeform: true,
            fullWidth: true,
          },
        ]}
        onNext={async (choices: ChoiceSelections) => {
          return onNext?.({ lifeEvents: choices });
        }}
        initialChoices={signupState?.lifeEvents || undefined}
      />
    );
  },
);
