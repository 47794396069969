import { Box, FormControlLabel } from "@mui/material";
import Checkbox from "components/common/Checkbox";

export default ({
  actionLabel,
  isRecurringSingleInstance,
  onToggle,
}: {
  actionLabel: string;
  isRecurringSingleInstance: boolean;
  onToggle: () => void;
}) => {
  return (
    <Box>
      <FormControlLabel
        sx={{ marginRight: "0px" }}
        label={`${actionLabel} this and all events.`}
        control={
          <Checkbox
            checked={!isRecurringSingleInstance}
            onChange={() => onToggle()}
          />
        }
      />
    </Box>
  );
};
