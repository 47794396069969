import { Box, Typography } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";
import { TaskBlock, Task } from "protogen/tasks_pb";
import { MessageCircle } from "lucide-react";

export default ({ block, task }: { block: TaskBlock; task: Task }) => {
  return (
    <LinkRouter to={`/tasks/${encodeURIComponent(task.ref)}`}>
      <Box
        sx={{
          backgroundColor: "#FFF",
          border: "1px solid #EAEBEC",
          borderRadius: "12px",
          display: "flex",
          padding: "12px 16px",
          width: "100%",
          gap: "8px",
        }}
      >
        <MessageCircle width={24} height={24} color="#6B6E7B" />
        <Typography
          variant="bodySmall"
          color="text.primary"
          sx={{
            width: "100%",
          }}
        >
          {block.createdBy?.firstName} left a comment on:{" "}
          <Typography
            color="primary.main"
            component="span"
            sx={{ fontWeight: "600", fontSize: "14px" }}
          >
            {task.title}
          </Typography>
        </Typography>
      </Box>
    </LinkRouter>
  );
};
