import { PlainMessage } from "@bufbuild/protobuf";
import {
  Box,
  Button,
  Link,
  Snackbar,
  Typography,
  TextField,
  FormControl,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/auth";
import { RichContent } from "components/editor/utils";
import { useUploader } from "components/creation/FileUploader";
import DocumentEditor from "components/editor/DocumentEditor";
import GridPage from "components/layout/GridPage";
import { UploadAttachment } from "protogen/common_pb";
import { Advisor } from "protogen/common_pb";
import { useFetchAdvisorProfile, useUpdateAdvisor } from "services/advisor";
import { CurrentUserContext } from "components/context/RequireAuth";
import { LoadingContactCard } from "components/common/ContactCardEntry";
import CurrentUserAvatar from "components/common/CurrentUserAvatar";
import { AvatarBadgeType } from "components/common/CurrentUserAvatar";
import AdvisorDetails from "components/advisor/AdvisorDetails";
import useIsMobile from "components/hooks/useIsMobile";
import { refreshToken } from "services/api";
import TimezoneDropdown from "components/common/TimezoneDropdown";
import { ExternalLink } from "components/advisor/types";
import { validateExternalLinks } from "components/advisor/utils";
import { FormErrors } from "components/advisor/types";
import AddressAutocomplete from "components/common/AddressAutocomplete";
import SpecialtyChip from "components/advisor/SpecialtyChip";
import useTitle from "components/hooks/useTitle";
import { downsizeImage } from "common/imageUtils";
import { userReferralLink } from "../components/referrals/utils";
import CopyTextField from "../components/common/CopyTextField";
import Toggle from "components/common/Toggle";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ReferralLink = ({ advisorRef }: { advisorRef: string }) => {
  const referralLink = userReferralLink(advisorRef, true);
  // Lets disable this until we settle on the coupon terms.
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="h3">Affiliate Link</Typography>
      <Typography variant="bodyHeavy">
        If your client load is full, you can still earn by sharing your unique
        referral link with friends & family.
      </Typography>
      <Typography variant="body" color="body">
        They'll get 15% off their first month with another Advisor and you'll
        earn 15% of their payment when they sign up.
      </Typography>
      <CopyTextField
        label="Link"
        sx={{
          border: "1px solid #D4D4D4",
          backgroundColor: "#FFF",
        }}
        value={referralLink}
      />
    </Box>
  );
};

const AdvisorProfile = ({ advisorRef }: { advisorRef: string }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isFirstRender = useRef(true);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<RichContent | null>(null);
  const [advisor, setAdvisor] = useState<Advisor | undefined>(undefined);
  const [errors, setFormErrors] = useState<FormErrors>({});
  const [isProfilePublic, setIsProfilePublic] = useState<boolean | undefined>(
    undefined,
  );
  const [externalLinkData, setExternalLinkData] = useState<ExternalLink[]>([]);
  const [newSpeciality, setNewSpeciality] = useState<string>("");
  const [specialties, setSpecialties] = useState<string[] | null>(null);
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState<
    boolean | undefined
  >(undefined);
  const [forumNotificationsEnabled, setForumNotificationsEnabled] = useState<
    boolean | undefined
  >(undefined);
  const [timezone, setTimezone] = useState<string>("");
  const [parsedAddress, setParsedAddress] = useState<string>(
    JSON.stringify(advisor?.address),
  );
  const [profileImage, setProfileImage] = useState<
    PlainMessage<UploadAttachment> | null | undefined
  >(null);
  const {
    request: fetchRequest,
    data: fetchData,
    loading: fetchLoading,
  } = useFetchAdvisorProfile((r) => {
    setAdvisor(r.advisor);
    setExternalLinkData(r?.advisor?.externalLinks || []);
  });
  const { request: updateRequest, loading: updateLoading } = useUpdateAdvisor(
    (r) => {
      setAdvisor(r.advisor);

      setSpecialties(r?.advisor?.specialties || []);
      setExternalLinkData(r?.advisor?.externalLinks || []);
    },
  );

  const handleCopyLink = () => {
    setOpen(true);
    navigator.clipboard.writeText(advisor?.profilePath || "");
  };

  const { onUpload } = useUploader({
    maxUploadBytes: 4900000,
  });
  const handleSave = async () => {
    const shouldUpdateProfileImage = profileImage !== null;
    const errors = validateExternalLinks(externalLinkData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    // TODO: @kegami move to "types" for reuse
    await updateRequest({
      advisorRef: advisorRef,
      updatedFirstName: advisor?.firstName || "",
      updatedLastName: advisor?.lastName || "",
      updatedPersonalEmail: advisor?.personalEmail || "",
      updatedPersonalPhone: advisor?.personalPhone || "",
      body: {
        contentType: "html",
        payload: content?.html || advisor?.blurbContent || "",
        textContent: content?.text || advisor?.blurbText || "",
        attachments: [],
      },
      profileImage: profileImage || undefined,
      isProfilePublic: isProfilePublic ?? advisor?.isProfilePublic ?? false,
      shouldUpdateProfileImage: shouldUpdateProfileImage,
      externalLinks: externalLinkData || [],
      updatedTimezone: timezone ?? (advisor?.timezone || ""),
      updatedSpecialties: specialties || advisor?.specialties || [],
      updatedStatus: null,
      updatedAddress: parsedAddress,
      shouldUpdateEmailNotifications: emailNotificationsEnabled !== undefined,
      emailNotificationsEnabled:
        emailNotificationsEnabled ??
        advisor?.emailNotificationsEnabled ??
        false,
      shouldUpdateForumNotifications: forumNotificationsEnabled !== undefined,
      forumNotificationsEnabled:
        forumNotificationsEnabled ??
        advisor?.forumNotificationsEnabled ??
        false,
      shouldUpdateMetadata: false,
      updatedMetadata: undefined,
    });
    if (shouldUpdateProfileImage) {
      await refreshToken();
    }
  };

  const handleLogout = async () => {
    await AuthService.logout();
    navigate("/");
  };

  useEffect(() => {
    fetchRequest({ advisorRef });
  }, [advisorRef]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSave();
  }, [
    profileImage,
    isProfilePublic,
    timezone,
    parsedAddress,
    emailNotificationsEnabled,
    forumNotificationsEnabled,
    JSON.stringify(specialties),
  ]);

  if (!fetchData && !fetchLoading) {
    return (
      <Typography
        color="primary"
        onClick={handleLogout}
        style={{
          fontWeight: "600",
          cursor: "pointer",
        }}
      >
        Logout
      </Typography>
    );
  }

  if (!fetchData || fetchLoading) return <LoadingContactCard />;
  return (
    <Box
      sx={{
        maxWidth: "850px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        paddingBottom: "70px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "32px",
        }}
      >
        <Box
          sx={{
            aspectRatio: "1/1",
            flexGrow: 1,
            maxWidth: isMobile ? "100px" : "158px",
            width: "100%",
          }}
        >
          <CurrentUserAvatar
            user={advisor}
            size={158}
            autoscale={true}
            badgeType={AvatarBadgeType.FILE_UPLOAD}
            onUpload={async (files) => {
              if (!files) return;
              const downsizedFile = await downsizeImage(files[0], 256, 256);
              onUpload([downsizedFile.file], undefined, (u) => {
                setProfileImage(u.pop());
              });
            }}
          />
        </Box>
        {isMobile ? (
          <AdvisorDetails advisor={advisor} hideSocials={true} />
        ) : (
          <AdvisorDetails
            advisor={advisor}
            onLogout={handleLogout}
            hideSocials={true}
          />
        )}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Typography variant="h2">Profile</Typography>

        {/* Social media */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography variant="h4">Social media</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              flexWrap: "wrap",
            }}
          >
            {externalLinkData.map((link) => (
              <FormControl key={link.name} sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  value={link.url}
                  error={!!errors?.[`externalLinks${link.name}`]}
                  helperText={errors?.[`externalLinks${link.name}`]}
                  onChange={(e) => {
                    const newData: ExternalLink[] = [...externalLinkData].map(
                      (l) => {
                        if (l.name !== link.name) return l;
                        return { ...link, url: e.target.value };
                      },
                    );
                    setExternalLinkData(newData);
                  }}
                  label={link.name}
                  placeholder={`https://www.${link.name.toLowerCase()}.com`}
                  margin="normal"
                  size="small"
                  sx={{ marginTop: 0 }}
                />
              </FormControl>
            ))}
          </Box>
        </Box>

        {/* Specialties */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography variant="h4">Specialties</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography variant="bodyHeavy" sx={{ fontWeight: 600 }}>
              What’s something you’re great at?
            </Typography>
            <Typography variant="body" color="text.secondary">
              Include as many as you’d like, separated by the ‘enter’ key
            </Typography>
            <TextField
              fullWidth
              value={newSpeciality}
              label="ex. travel plans, credit card points, etc."
              placeholder="ex. travel plans, credit card points, etc."
              autoComplete="off"
              onChange={(e) => {
                setNewSpeciality(e.target.value);
              }}
              onKeyDown={(event) => {
                if (advisor && (event.key === "Enter" || event.key === ",")) {
                  setNewSpeciality("");
                  setSpecialties([...advisor?.specialties, newSpeciality]);
                  event.preventDefault();
                }
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "12px",
              flexWrap: "wrap",
            }}
          >
            {advisor?.specialties.map((specialty, i) => (
              <SpecialtyChip
                key={i}
                specialty={specialty}
                onDelete={() => {
                  setSpecialties(
                    advisor?.specialties.filter((s) => s !== specialty),
                  );
                }}
              />
            ))}
          </Box>
        </Box>

        {/* About me */}
        <Box>
          <Typography variant="h4">About me</Typography>
          <DocumentEditor
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "24px",
              border: "2px solid #F1F1F1",
              borderRadius: "8px",
            }}
            initialContent={advisor?.blurbContent || ""}
            disabled={fetchLoading}
            placeholder="Tell families a little about yourself..."
            attachmentsEnabled={false}
            setContent={setContent}
            primaryAction={
              <Button
                onClick={handleSave}
                disabled={fetchLoading || updateLoading}
              >
                Save
              </Button>
            }
            secondaryAction={
              updateLoading && (
                <Box
                  sx={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  Saving...
                </Box>
              )
            }
          />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
            sx={{
              marginTop: "10px",
              alignItems: isMobile ? "start" : "center",
              flexDirection: isMobile ? "column-reverse" : "row",
            }}
          >
            {/* Public profile toggle */}
            <Toggle
              disabled={fetchLoading || updateLoading}
              onChange={() => {
                setIsProfilePublic(!advisor?.isProfilePublic);
              }}
              checked={advisor?.isProfilePublic}
              label="Enable public profile"
              helperText="Your public profile can be shared to generate new leads."
            />

            {/* Copy profile link */}
            <Box
              sx={{
                padding: "8px 16px",
              }}
            >
              <Button variant="text" onClick={handleCopyLink}>
                Copy profile link
              </Button>
              <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied to clipboard"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider
        sx={{
          marginTop: "32px",
        }}
      />

      {/* Settings */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "48px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="h2">Settings</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography variant="h4">Timezone</Typography>
              <TimezoneDropdown
                timezone={advisor?.timezone || ""}
                setTimezone={setTimezone}
                sx={{
                  width: isMobile ? "100%" : "auto",
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography variant="h4">Address</Typography>
              <AddressAutocomplete
                label={"Address"}
                error={errors?.location}
                initialValue={
                  advisor?.address
                    ? JSON.parse(advisor?.address || "")?.formattedAddress
                    : ""
                }
                setValue={(location) => {
                  setParsedAddress(JSON.stringify(location));
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Typography variant="h4">Notifications</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {/* Email notifications */}
              <Toggle
                disabled={updateLoading}
                onChange={(e) => setEmailNotificationsEnabled(e.target.checked)}
                checked={advisor?.emailNotificationsEnabled}
                label="Email notifications"
                helperText="Receive email notifications for new messages and family activity."
              />

              {/* Forum notifications */}
              <Toggle
                disabled={updateLoading}
                onChange={(e) => setForumNotificationsEnabled(e.target.checked)}
                checked={advisor?.forumNotificationsEnabled}
                label="Forum Activity"
                helperText="Get notified of new community content."
              />
            </Box>
          </Box>
        </Box>

        <ReferralLink advisorRef={advisorRef} />

        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="h3">References</Typography>
          {[
            {
              url: "https://findfaye.com/privacy-policy",
              name: "Privacy Policy",
            },
            {
              url: "https://findfaye.com/advisor-terms",
              name: "Advisor Terms of Service",
            },
          ].map(({ name, url }) => (
            <Link
              key={url}
              target="_blank"
              href={url}
              style={{
                textDecoration: "none",
              }}
            >
              {name}
            </Link>
          ))}
        </Box>
        {isMobile && (
          <Box
            onClick={handleLogout}
            style={{
              fontWeight: "600",
              color: "#198282",
            }}
          >
            Logout
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const currentUser = useContext(CurrentUserContext);
  useTitle("Settings");

  return (
    <GridPage sx={{ padding: isMobile ? "25px" : "70px" }}>
      <CenteredBox>
        <AdvisorProfile advisorRef={currentUser.ref} />
      </CenteredBox>
    </GridPage>
  );
};
