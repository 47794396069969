import { Box, Typography } from "@mui/material";
import { Check } from "lucide-react";
import { PlanFeatures as Features } from "../types";
export const PlanFeatures = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {Features.map((feature) => (
        <Box sx={{ display: "flex", gap: "7px" }} key={feature}>
          <Check size={24} color="#198282" />
          <Typography variant="body">{feature}</Typography>
        </Box>
      ))}
    </Box>
  );
};
