import { BlockHandle, BlockProps, usePollForBlockUpdates } from "./utils";
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";
import useAutoSave from "../../editor/useAutoSave";
import { Box, Typography, Collapse } from "@mui/material";
import { ChevronUp, ChevronDown } from "lucide-react";
import {
  useUpdateBlockContent,
  useUpdateMemberBlockContent,
} from "services/tasks";
import { useEditor } from "@tiptap/react";
import StyledEditorContent from "../../editor/StyledEditorContent";
import { StarterKit } from "@tiptap/starter-kit";
import DetailsList from "../../editor/extensions/DetailsList";
import { Editor } from "@tiptap/core";
import { Transaction } from "@tiptap/pm/state";
import { TaskBlock } from "protogen/tasks_pb";
import { RichContent } from "../../editor/utils";
import BlockContainer from "./BlockContainer";
import BlockGutterMap from "./BlockGutterMap";
import useIsMobile from "components/hooks/useIsMobile";
import { useUpdateBlockMetadata } from "services/tasks";
import { toMaybeNumber } from "common/utils";

const getExtensions = ({ entityUrlsEnabled = false }) => {
  return [
    StarterKit.configure({
      bulletList: false,
      horizontalRule: false,
      listItem: false,
      orderedList: false,
    }),
    DetailsList.configure({
      entityUrlsEnabled,
    }),
  ];
};

// const GenerateDetails = ({
//   task,
//   setContent,
// }: {
//   task: Task;
//   setContent: (s: string) => void;
// }) => {
//   const { request: workflowRequest, loading: workflowLoading } =
//     useRunWorkflow();
//   const generateDetails = async () => {
//     const resp = await workflowRequest({
//       workflowName: "generate-task-requirements",
//       arguments: [],
//       entityType: SuggestionEntityType.TASK,
//       entityRef: task.ref,
//     });
//     if (!resp) return;
//     const newContent = JSON.parse(JSON.parse(resp.result));
//     setContent(newContent);
//   };
//   return (
//     <Button
//       variant={"text"}
//       onClick={generateDetails}
//       disabled={workflowLoading}
//     >
//       Generate details
//     </Button>
//   );
// };

const EditorArea = ({
  initialContent,
  setEditorContent,
  entityUrlsEnabled,
  isCollapsed,
  toggleCollapse,
}: {
  initialContent: any;
  setEditorContent: (content: RichContent) => void;
  entityUrlsEnabled: boolean;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}) => {
  const isMobile = useIsMobile();
  const onContentUpdate = async (props: {
    editor: Editor;
    transaction: Transaction;
  }) => {
    setEditorContent({
      html: props.editor.getHTML(),
      json: JSON.stringify(props.editor.getJSON()),
      text: props.editor.getText({ blockSeparator: "\n" }),
      attachments: [],
    });
  };

  const editor = useEditor({
    editable: false,
    extensions: getExtensions({ entityUrlsEnabled: entityUrlsEnabled }),
    content: initialContent,
    onUpdate: onContentUpdate,
    parseOptions: {
      preserveWhitespace: "full",
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isCollapsed ? "0" : "12px",
        padding: isMobile ? "20px" : "16px 24px",
        background: "#FFF",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="bodyHeavy">Additional details</Typography>
        <Box onClick={toggleCollapse} sx={{ cursor: "pointer" }}>
          {isCollapsed ? (
            <ChevronDown size={24} stroke="#8E9598" />
          ) : (
            <ChevronUp size={24} stroke="#8E9598" />
          )}
        </Box>
      </Box>

      <Collapse in={!isCollapsed}>
        <StyledEditorContent
          minHeight={"65px"}
          scrollable={true}
          editor={editor}
        />
      </Collapse>
    </Box>
  );
};

export default forwardRef<BlockHandle, BlockProps>(
  ({ block: initialBlock, onUpdate, accountType }: BlockProps, ref) => {
    const [parsedMetadata, setParsedMetadata] = useState<any>();
    const { block, polling } = usePollForBlockUpdates({
      initialBlock,
      isComplete: (b: TaskBlock) =>
        JSON.parse(b.metadata).processing_state !== "processing",
      onCompleted: () => {
        hasContent.current = true;
      },
      accountType,
    });
    // const _initialContent = block.content ? JSON.parse(block.content) : "";

    const { request, error } =
      accountType === "advisor"
        ? useUpdateBlockContent()
        : useUpdateMemberBlockContent();

    const { request: updateMetadataRequest } = useUpdateBlockMetadata((r) => {
      if (!r.block?.metadata) return;
      setParsedMetadata(JSON.parse(r.block?.metadata));
    });

    const hasContent = useRef<boolean>(!!block.content);
    useImperativeHandle(ref, () => ({
      isPolling: () => polling,
    }));

    const { setEditorContent } = useAutoSave(
      block.content || null,
      toMaybeNumber(block?.lastUpdatedSec),
      async (c) => {
        const resp = await request({
          taskBlockRef: block.ref,
          content: c.json,
          contentType: "json",
          textContent: c.text,
          attachments: c.attachments,
        });
        if (resp) {
          onUpdate && onUpdate(resp?.block?.lastUpdatedSec || BigInt(0));
        }
      },
      !!error,
    );

    const handleToggleCollapsed = () => {
      updateMetadataRequest({
        taskBlockRef: block.ref,
        key: "isCollapsed",
        value: (!parsedMetadata.isCollapsed).toString(),
      });
    };

    useEffect(() => {
      if (block.metadata) {
        setParsedMetadata(JSON.parse(block.metadata));
      }
    }, [block]);

    if (polling || !hasContent?.current) return <></>;

    return (
      <BlockContainer
        gutterIcon={BlockGutterMap.requirements.icon}
        advisorViewOnly={false}
      >
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/*<GenerateDetails*/}
            {/*  task={task}*/}
            {/*  setContent={(c) => {*/}
            {/*    editor?.commands.setContent(c);*/}
            {/*    hasContent.current = true;*/}
            {/*  }}*/}
            {/*/>*/}
          </Box>
          {hasContent?.current && (
            <EditorArea
              initialContent={block.content ? JSON.parse(block.content) : ""}
              setEditorContent={setEditorContent}
              entityUrlsEnabled={accountType === "advisor"}
              isCollapsed={parsedMetadata?.isCollapsed}
              toggleCollapse={handleToggleCollapsed}
            />
          )}
        </Box>
      </BlockContainer>
    );
  },
);
