import { useEffect, useState } from "react";
import {
  useCreateCalendarIntegration,
  useGetAdvisorCalendarIntegrations,
  useGetMemberCalendarIntegrations,
} from "services/calendar";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import useIsMobile from "components/hooks/useIsMobile";
import Loading from "components/common/Loading";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import SnackPack, { SnackbarMessage } from "../components/common/SnackPack";
import CalendarFlow from "components/calendar/integrations/ImportCalendarFlow";
import { REDIRECT_URI } from "components/calendar/integrations/GoogleCalendar";
import ExportCalendar from "components/calendar/integrations/ExportCalendarFlow";
import DropdownMenu from "components/common/DropdownMenu";
import {
  EXPORT_CALENDARS,
  ExportedCalendar,
} from "components/calendar/integrations/exports";
import {
  MEMBER_IMPORT_CALENDARS,
  ADVISOR_IMPORT_CALENDARS,
  ImportCalendar,
} from "components/calendar/integrations/imports";
import ImportedIntegration from "components/calendar/integrations/ImportedIntegration";
import ExportedIntegration from "components/calendar/integrations/ExportedIntegration";
import { CalendarIntegration } from "protogen/calendar_service_pb";

type IntegrationResponse = {
  type: string;
  code?: string;
  scope?: string;
  redirectUri?: string;
  calendarUrl?: string;
};

export default ({ accountType }: { accountType: "member" | "advisor" }) => {
  let params = useParams();
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [integrationSuccess, setIntegrationSuccess] = useState(
    searchParams.get("calendarAdded") === "success",
  );
  const [exportType, setExportType] = useState<ExportedCalendar | null>(null);
  const [importType, setImportType] = useState<ImportCalendar | null>(null);
  const [integrationResponse, setIntegrationResponse] =
    useState<IntegrationResponse | null>(null);
  const getIntegrationsFn =
    accountType === "advisor"
      ? useGetAdvisorCalendarIntegrations
      : useGetMemberCalendarIntegrations;
  const { request, loading, data } = getIntegrationsFn();
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const {
    request: createRequest,
    error: createError,
    loading: createLoading,
  } = useCreateCalendarIntegration();
  // Should move this somewhere else and manage different callbacks differently.
  useEffect(() => {
    setIntegrationSuccess(searchParams.get("calendarAdded") === "success");
  }, [searchParams]);
  useEffect(() => {
    if (params.calendarType === "google") {
      const code = searchParams.get("code");
      const scope = searchParams.get("scope");
      if (code && scope) {
        setIntegrationResponse({
          code,
          scope,
          redirectUri: REDIRECT_URI,
          type: "google",
        });
      }
    }
    request();
  }, [params.calendarType]);

  const doToast = (key: number) => {
    setSnackPack((p) => [
      ...p,
      {
        message: "Changes saved!",
        saveNotification: true,
        key: `${key}`,
      },
    ]);
  };

  // Should move this somewhere else and manage different callbacks differently.
  useEffect(() => {
    const create = async (ir: IntegrationResponse) => {
      await createRequest({
        calendarType: ir.type,
        accessCode: ir.code || "",
        accessScope: ir.scope || "",
        accessRedirectUri: ir.redirectUri || "",
        calendarUrl: ir.calendarUrl || "",
      });
      navigate({
        pathname: window.location.pathname,
        search: `?calendarAdded=success`,
      });
      request();
    };
    if (integrationResponse) {
      create(integrationResponse);
    }
  }, [integrationResponse]);
  let openIntegration: CalendarIntegration | null = null;
  if (integrationSuccess && data?.importIntegrations?.length) {
    openIntegration = data.importIntegrations.sort(
      (a, b) => Number(a.createdSec) - Number(b.createdSec),
    )[data.importIntegrations.length - 1];
  }
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "0 auto",
        maxWidth: "850px",
        padding: isMobile ? "20px" : "64px",
        display: "flex",
        flexDirection: "column",
        gap: isMobile ? "16px" : "",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" width="100%">
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Calendar", link: "/calendar" },
            ]}
          />
          <Typography
            variant="display"
            sx={{
              marginTop: "13px",
              marginBottom: "24px",
            }}
          >
            Calendar settings
          </Typography>
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: isMobile ? "start" : "center",
                  gap: "8px",
                }}
              >
                <Typography variant="h3">Imported calendars</Typography>
                <DropdownMenu
                  size="small"
                  noIcon={true}
                  fullWidth
                  buttonContent="Add a calendar"
                  options={(accountType === "advisor"
                    ? ADVISOR_IMPORT_CALENDARS
                    : MEMBER_IMPORT_CALENDARS
                  ).map((c) => ({
                    title: c.title,
                    onClick: () => setImportType(c),
                  }))}
                />
              </Box>
              <Typography variant="body">
                Connect external calendars like Google. Apple, Outlook, Cozi and
                more. Imported calendars will give Faye a read-only view of
                these events so that they can be used for scheduling and seeing
                upcoming to-dos.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        {!createLoading && integrationSuccess && !createError && (
          <Alert>
            <AlertTitle>New Calendar Added</AlertTitle>
            New calendar added!
          </Alert>
        )}
        {integrationResponse && createError && (
          <Alert severity="error">
            {/* Error? */}
            <AlertTitle>Error Adding Calendar</AlertTitle>
            {createError}
          </Alert>
        )}
        {(data?.importIntegrations || []).map((i) => (
          <ImportedIntegration
            key={i.ref}
            integration={i}
            // Default new entries to open.
            defaultOpen={openIntegration?.ref === i.ref}
            onUpdate={() => {
              doToast(new Date().getTime());
              request();
            }}
          />
        ))}
        {!loading &&
          data?.importIntegrations.filter((i) => i.status !== "deactivated")
            .length === 0 && (
            <Typography
              variant="body"
              sx={{ color: "#6B6E7B", marginTop: isMobile ? "16px" : "" }}
            >
              No calendars connected
            </Typography>
          )}
        {integrationResponse && createLoading && <Loading />}
        {!createLoading && loading && !data && <Loading />}
        <Box
          display="flex"
          width="100%"
          marginTop="24px"
          gap={isMobile ? "12px" : "24px"}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gap="8px">
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: isMobile ? "start" : "center",
                gap: "8px",
              }}
            >
              <Typography variant="h3">Faye calendar sync</Typography>
              <DropdownMenu
                size="small"
                noIcon={true}
                fullWidth
                buttonContent="Export"
                options={EXPORT_CALENDARS.map((c) => ({
                  title: c.title,
                  onClick: () => setExportType(c),
                }))}
              />
            </Box>
            <Typography variant="body">
              Connect your Faye calendar to other tools where you manage your
              calendar. This way your Faye events can appear on calendars like
              Google, Apple, Outlook, Skylight and more.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {(data?.exportIntegrations || []).length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            {(data?.exportIntegrations || []).map(
              (int) =>
                int.status !== "deactivated" && (
                  <ExportedIntegration
                    key={int.ref}
                    link={int}
                    refresh={() => request()}
                  />
                ),
            )}
          </Box>
        )}
        {!loading &&
          data?.exportIntegrations.filter((i) => i.status !== "deactivated")
            .length === 0 && (
            <Typography
              variant="body"
              sx={{ color: "#6B6E7B", marginTop: isMobile ? "16px" : "" }}
            >
              No calendars connected
            </Typography>
          )}
      </Box>
      {importType && (
        <CalendarFlow
          importType={importType}
          open={!!importType}
          onClose={() => setImportType(null)}
          onSuccess={async () => {
            await request();
            setIntegrationSuccess(true);
            setImportType(null);
          }}
        />
      )}
      {exportType && (
        <ExportCalendar
          open={!!exportType}
          calendarType={exportType}
          onClose={(refresh: boolean) => {
            if (refresh) {
              request();
            }
            setExportType(null);
          }}
        />
      )}
      <SnackPack
        autoHideDuration={2000}
        snackPack={snackPack}
        alertSeverity="success"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );
};
