import { IconButton, Menu, MenuItem } from "@mui/material";
import { Hash } from "lucide-react";
import { useState } from "react";

export default ({
  defaultType,
  setType,
  typeOptions,
}: {
  defaultType: string;
  setType: (t: string) => void;
  typeOptions: string[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [buttonText, setButtonText] = useState(defaultType);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (text: string) => {
    setButtonText(text);
    handleClose();
    setType(text);
  };

  return (
    <>
      <IconButton
        size="small"
        sx={{
          marginRight: "4px",
          fontSize: "16px",
          alignItems: "center",
          fontWeight: 550,
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Hash size={16} /> {buttonText}
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {typeOptions.map((option) => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
