import { Box, Typography, Chip, Divider } from "@mui/material";
import { EllipsisVertical } from "lucide-react";
import { Document } from "protogen/documents_pb";
import useIsMobile from "components/hooks/useIsMobile";
import { dateObjectToshortDate } from "common/utils";
import { getScopeGroupLabel, isPrivate } from "./utils";
import EmptyTableOverlay from "components/common/EmptyTableOverlay";
import DropdownMenu from "components/common/DropdownMenu";
import LinkRouter from "components/navigation/LinkRouter";

const FixedColumnWidth = "120px";

const VisibilityChip = ({ document }: { document: Document }) => {
  return (
    <Chip
      label={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {getScopeGroupLabel(document.scope) || ""}
        </Box>
      }
      sx={{
        color: isPrivate(document) ? "#3D3D3D" : "#198282",
        background: isPrivate(document) ? "#FFF" : "#ECF4EC",
        border: isPrivate(document) ? "1px solid #D4D4D4" : "none",
        borderRadius: "100px",
        fontSize: "14px",
        fontWeight: "600",
        width: "100px",
      }}
    />
  );
};

const DocumentActionMenu = ({
  document,
  onDuplicate,
  onDelete,
}: {
  document: Document;
  onDuplicate: (document: Document) => void;
  onDelete: (document: Document) => void;
}) => {
  return (
    <DropdownMenu
      key={document.ref}
      disabled={false}
      fullWidth
      buttonContent={<EllipsisVertical size={20} color="#8E9598" />}
      dropdownIcon={false}
      variant="text"
      options={[
        {
          title: "Duplicate",
          onClick: () => onDuplicate(document),
        },
        {
          title: "Delete",
          onClick: () => onDelete(document),
        },
      ]}
      sx={{
        minWidth: "auto",
      }}
    />
  );
};

const TableHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
        height: "60px",
      }}
    >
      <Typography
        variant="bodyHeavy"
        color="text.primary"
        sx={{ width: "1%", flexGrow: 1 }}
      >
        Name
      </Typography>
      <Typography
        variant="bodyHeavy"
        color="text.primary"
        sx={{ width: FixedColumnWidth, textAlign: "end" }}
      >
        Owner
      </Typography>
      <Typography
        variant="bodyHeavy"
        color="text.primary"
        sx={{ width: FixedColumnWidth, textAlign: "center" }}
      >
        Visibility
      </Typography>
      <Typography
        variant="bodyHeavy"
        color="text.primary"
        sx={{ width: FixedColumnWidth, textAlign: "center" }}
      >
        Last updated
      </Typography>
    </Box>
  );
};

const DocumentRow = ({
  document,
  onDuplicate,
  onDelete,
}: {
  document: Document;
  onDuplicate: (document: Document) => void;
  onDelete: (document: Document) => void;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "12px",
        height: "60px",
      }}
    >
      <LinkRouter
        sx={{ width: "1%", flexGrow: 1 }}
        to={`/documents/${encodeURIComponent(document.uuid)}`}
        key={document.ref}
      >
        <Typography variant="body" color="text.primary">
          {document.title || "Untitled"}
        </Typography>
      </LinkRouter>
      <Typography
        variant="body"
        color="text.primary"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: FixedColumnWidth,
          textAlign: "end",
        }}
      >
        {document.family?.name || "Me"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: FixedColumnWidth,
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <VisibilityChip document={document} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: FixedColumnWidth,
          justifyContent: "flex-end",
          gap: "24px",
        }}
      >
        <Typography variant="body" color="text.primary">
          {dateObjectToshortDate(
            new Date(Number(document.lastUpdatedSec) * 1000),
          )}
        </Typography>
        <DocumentActionMenu
          document={document}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      </Box>
    </Box>
  );
};

const DocumentRowMobile = ({ document }: { document: Document }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "16px 0px",
      }}
    >
      <Typography variant="bodyHeavy" color="text.primary">
        {document.title || "Untitled"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography
            variant="body"
            color="text.secondary"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {document.family?.name || "Owned by me"}
          </Typography>
          <Typography variant="body" color="text.secondary">
            •
          </Typography>
          <Typography
            variant="body"
            color="text.secondary"
            sx={{ flexShrink: 0 }}
          >
            {dateObjectToshortDate(
              new Date(Number(document.lastUpdatedSec) * 1000),
            )}
          </Typography>
        </Box>
        <VisibilityChip document={document} />
      </Box>
    </Box>
  );
};

type Props = {
  documents: Document[];
  loading?: boolean;
  onDuplicate: (document: Document) => void;
  onDelete: (document: Document) => void;
};

export default ({ documents, loading, onDuplicate, onDelete }: Props) => {
  const isMobile = useIsMobile();

  return (
    <Box>
      {loading ||
        (documents.length === 0 && (() => EmptyTableOverlay("No Documents")))}
      {!isMobile && (
        <Box>
          <TableHeader />
          <Divider />
          {documents.map((document) => {
            return (
              <Box
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
                key={document.ref}
              >
                <DocumentRow
                  document={document}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                />
                <Divider />
              </Box>
            );
          })}
        </Box>
      )}
      {isMobile && (
        <Box>
          {documents.map((document) => {
            return (
              <Box
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
                key={document.ref}
              >
                <LinkRouter
                  to={`/documents/${encodeURIComponent(document.uuid)}`}
                >
                  <DocumentRowMobile document={document} />
                </LinkRouter>
                <Divider />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
