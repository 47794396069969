import { usePlacesWidget } from "react-google-autocomplete";
import {
  TextField,
  InputAdornment,
  IconButton,
  Fade,
  Typography,
  Box,
} from "@mui/material";
import { Copy } from "lucide-react";
import { useState } from "react";
import "./AddressAutocomplete.css";

export type Location = {
  latitude: number;
  longitude: number;
  address: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  formattedAddress: string;
};

const getAddressComponent = (
  components: { long_name: string; short_name: string; types: string[] }[],
  type: string,
  shortName = false,
) => {
  const component = components.find((c) => c.types.includes(type));
  if (!component) return "";
  return shortName ? component.short_name : component.long_name;
};

type Props = {
  label: string;
  error?: string;
  name?: string;
  autoComplete?: string;
  initialValue?: string;
  setValue: (location: Location) => void;
  handleCopy?: boolean;
  restrictToAddresses?: boolean;
  inputSx?: React.CSSProperties;
};

export default ({
  label,
  error,
  name,
  initialValue,
  setValue,
  handleCopy,
  restrictToAddresses = true, // Default to restricting to addresses
  autoComplete,
  inputSx,
}: Props) => {
  const { ref: materialRef } = usePlacesWidget<HTMLInputElement>({
    onPlaceSelected: (place) => {
      if (
        !place.geometry ||
        !place.address_components ||
        !place.formatted_address ||
        !place.geometry.location
      )
        return;
      // Extract lat and lng
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      // Extract individual address components
      const address =
        getAddressComponent(place.address_components, "street_number") +
        " " +
        getAddressComponent(place.address_components, "route");
      const unit = getAddressComponent(place.address_components, "subpremise");
      const city =
        getAddressComponent(place.address_components, "locality") ||
        getAddressComponent(place.address_components, "sublocality");
      const state = getAddressComponent(
        place.address_components,
        "administrative_area_level_1",
      );
      const zip = getAddressComponent(place.address_components, "postal_code");
      const country = getAddressComponent(
        place.address_components,
        "country",
        true,
      );

      // Form the final location object
      const location: Location = {
        latitude,
        longitude,
        address,
        unit,
        city,
        state,
        zip,
        country,
        formattedAddress: place.formatted_address,
      };
      setValue(location);
    },
    language: "en",
    inputAutocompleteValue: "address",
    options: {
      types: restrictToAddresses ? ["address"] : [], // Restrict to addresses or allow any type
      componentRestrictions: { country: ["us", "ca"] },
    },
  });

  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    if (materialRef.current) {
      const textToCopy = materialRef.current.value;
      if (textToCopy) {
        await navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      }
    }
  };

  return (
    <TextField
      error={!!error}
      helperText={error}
      label={label}
      name={name}
      autoComplete={autoComplete}
      fullWidth
      color="secondary"
      variant="outlined"
      inputRef={materialRef}
      margin="normal"
      size="small"
      defaultValue={initialValue}
      inputProps={{
        autoComplete: !autoComplete ? "nope" : undefined,
      }}
      sx={{ marginTop: 0, marginBottom: 0, ...inputSx }}
      InputProps={{
        endAdornment: handleCopy &&
          (materialRef?.current?.value || initialValue) && (
            <InputAdornment
              position="end"
              sx={{
                paddingRight: "12px !important",
                paddingTop: "0 !important",
                paddingLeft: "15px !important",
              }}
            >
              <Box position="relative" display="flex" alignItems="center">
                <Fade in={!copied} timeout={300}>
                  <IconButton
                    onClick={handleCopyClick}
                    edge="end"
                    disabled={!(materialRef?.current?.value || initialValue)}
                  >
                    <Copy width="20" />
                  </IconButton>
                </Fade>
                <Fade in={copied} timeout={300}>
                  <Typography
                    variant="body"
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "700",
                      paddingRight: "10px",
                    }}
                  >
                    Copied
                  </Typography>
                </Fade>
              </Box>
            </InputAdornment>
          ),
      }}
    />
  );
};
