import { Children, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as SparklesIcon } from "../../icons/Advisor/Sparkles.svg";

type Props = {
  open: boolean;
  children: ReactNode | ReactNode[];
  childHeight?: number;
  title?: string | ReactNode;
  onClick?: () => void;
  inlineMode?: boolean;
};
export default ({
  open,
  children,
  title,
  childHeight = 40,
  onClick,
  inlineMode = false,
}: Props) => {
  const length = (Children.count(children) || 0) + 1;
  return (
    <Box
      sx={{
        maxHeight: open ? `${childHeight * (length + 1) + 48}px` : "0px",
        transition: "max-height 0.2s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
          ...(inlineMode
            ? {}
            : {
                backgroundColor: "#FFF",
                padding: "16px 20px",
                border: "1px solid #F2F2F2",
                boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.11)",
              }),
        }}
      >
        {title && (
          <Typography
            sx={{
              marginBottom: "12px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
            variant={inlineMode ? "h4" : "bodySmallHeavy"}
          >
            {inlineMode ? <SparklesIcon /> : undefined}
            {title}
          </Typography>
        )}
        <Box display={"flex"} flexDirection={"column"} gap={"6px"}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
