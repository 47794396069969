import useIsMobile from "../hooks/useIsMobile";
import { Typography } from "@mui/material";
import { MoveDown, MoveUp } from "lucide-react";
import React from "react";
import { SortFields } from "./utils";

export default ({
  title,
  sortKey,
  sorts,
  setSorts,
  hideMobile = false,
  flex = 1,
}: {
  title: string;
  sortKey: string;
  sorts: SortFields;
  setSorts: (s: SortFields) => void;
  hideMobile?: boolean;
  flex?: number;
}) => {
  const isMobile = useIsMobile();
  if (hideMobile && isMobile) return null;
  const arrowSx = {
    height: "16px",
    width: "16px",
    paddingTop: "3px",
    marginRight: "3px",
  };
  // Default to down unless it is present and set to false.
  const currentSortVal = sorts.find((s) => s.field === sortKey);
  const upArrow = currentSortVal && !currentSortVal.asc;

  const onClick = () => {
    const newSorts = sorts.filter((s) => s.field !== sortKey);
    // First sort should always be ascending.
    if (upArrow || !currentSortVal) {
      newSorts.unshift({ field: sortKey, asc: true });
    } else {
      newSorts.unshift({ field: sortKey, asc: false });
    }
    setSorts(newSorts);
  };
  return (
    <Typography
      flex={flex}
      variant="bodyHeavy"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      {upArrow ? <MoveUp style={arrowSx} /> : <MoveDown style={arrowSx} />}
      {title}
    </Typography>
  );
};
