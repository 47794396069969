import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import set from "lodash/set";
import { Box, Button, Typography, TextField } from "@mui/material";
import auth from "components/nux/auth";
import { useAddPersonalDetailsAdvisorNux } from "../../..//services/nux";
import { getStepPath } from "components/nux/utils";
import NuxContentLayout from "components/nux/NuxContentLayout";
import { NuxDetailsContext } from "components/context/AdvisorNuxContext";
import PhoneInput from "components/creation/PhoneInput";
import { cleanPhone } from "../../../common/utils";
import { EphemeralAdvisor } from "components/advisor/types";
import { validateForm, validateAddress } from "components/advisor/utils";
import { validatePassword } from "common/utils";
import { FormErrors } from "components/advisor/types";
import AuthService from "services/auth";
import useIsMobile from "components/hooks/useIsMobile";
import CurrentUserAvatar, {
  AvatarBadgeType,
} from "components/common/CurrentUserAvatar";
import { useUploader } from "components/creation/FileUploader";
import { AccountStub } from "protogen/common_pb";
import { useUpdateAdvisor } from "services/advisor";
import { Camera } from "lucide-react";
import { USER_NUX_TYPE } from "components/nux/constants";
import AddressAutocomplete from "components/common/AddressAutocomplete";
import PasswordInput from "components/common/PasswordInput";

export default () => {
  const navigate = useNavigate();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [filesList, setFilesList] = useState<FileList>();
  const [formData, setFormData] = useState<EphemeralAdvisor>({});
  // We haven't created an "Advisor" yet.  Use this for real time profile image upload.
  const [accountStub, setAccountStub] = useState<AccountStub>(
    new AccountStub(),
  );
  const [errors, setFormErrors] = useState<FormErrors>({});
  const nuxDetails = useContext(NuxDetailsContext);
  const isMobile = useIsMobile();

  const { request: updateRequest } = useUpdateAdvisor();

  const { request, loading } = useAddPersonalDetailsAdvisorNux((r) => {
    AuthService.advisorNuxLogin(auth.getAuthData()).then(() => {
      if (r?.nuxDetails?.stepName) {
        if (filesList) {
          onUpload(filesList, undefined, async (u) => {
            const profileImage = u.pop();
            const currentUser = AuthService.getCurrentUser();

            await updateRequest({
              advisorRef: currentUser?.ref || "",
              updatedFirstName: "",
              updatedLastName: "",
              updatedPersonalEmail: "",
              updatedPersonalPhone: "",
              profileImage: profileImage,
              isProfilePublic: false,
              shouldUpdateProfileImage: true,
              externalLinks: [],
              updatedTimezone: "",
              updatedSpecialties: [],
              updatedStatus: null,
              updatedAddress: "",
              shouldUpdateEmailNotifications: false,
              emailNotificationsEnabled: false,
              shouldUpdateForumNotifications: false,
              forumNotificationsEnabled: false,
              shouldUpdateMetadata: false,
              updatedMetadata: undefined,
            });
          });
        }
        const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.ADVISOR);
        navigate(path);
      }
    });
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = {
      ...validateForm(formData),
      ...validatePassword(formData.password || ""),
      ...validateAddress(formData.address || ""),
    };
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    request({
      token: auth.getAuthData(),
      firstName: formData.firstName || "",
      lastName: formData.lastName || "",
      personalEmail: formData.personalEmail || "",
      personalPhone: formData.personalPhone || "",
      password: formData.password || "",
      timezone: formData.timezone || "",
      address: formData.address || "",
    });
  };
  const { onUpload } = useUploader({
    maxUploadBytes: 4900000,
  });

  const createChangeHandler = (
    path: string[],
    event: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const updatedFormData = { ...formData };
    const value = typeof event === "string" ? event : event.target.value;

    set(updatedFormData, path, value);
    setFormData(updatedFormData);
  };
  useEffect(() => {
    const parsed: EphemeralAdvisor =
      nuxDetails?.state === "" ||
      nuxDetails?.state === undefined ||
      nuxDetails?.state === "null"
        ? {}
        : JSON.parse(nuxDetails.state);

    parsed.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setFormData(parsed);
  }, [nuxDetails]);

  return (
    <NuxContentLayout>
      <Box
        component="form"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "48px",
          alignItems: "center",
        }}
        onSubmit={onSubmit}
      >
        <Typography variant="title">
          Let's gather some contact information
        </Typography>
        <Box
          sx={{
            alignSelf: "flex-start",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <Typography variant="h3Serif">Contact Information</Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
            <Box
              sx={{
                aspectRatio: "1/1",
                flexGrow: 1,
                maxWidth: isMobile ? "88px" : "88px",
                width: "100%",
              }}
            >
              <CurrentUserAvatar
                user={accountStub}
                size={88}
                autoscale={true}
                badgeType={AvatarBadgeType.NONE}
                clickable={false}
                placeholder={<Camera size={49} stroke="#8E9598" />}
              />
            </Box>
            <input
              ref={inputFileRef}
              type="file"
              style={{ display: "none", height: "100%", width: "100%" }}
              onChange={(e) => {
                if (!onUpload) return;
                const files = e.target.files;
                if (!files) return;
                const imageUrl = URL.createObjectURL(files[0]);
                setAccountStub((prevState) => {
                  const newState = prevState.clone();
                  newState.avatarUrl = imageUrl;
                  return newState;
                });
                setFilesList(files);
              }}
              id="input-file-upload"
            />
            <label htmlFor="input-file-upload">
              <Box>
                <Button
                  variant="outlined"
                  onClick={() =>
                    inputFileRef.current && inputFileRef.current.click()
                  }
                >
                  Upload an image
                </Button>
              </Box>
            </label>
          </Box>
          <Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap" }}>
            <TextField
              sx={{ width: isMobile ? "100%" : "calc(50% - 12px)" }}
              margin="dense"
              label="First name"
              type="text"
              variant="outlined"
              autoComplete="off"
              value={formData.firstName || ""}
              error={!!errors?.firstName}
              helperText={errors?.firstName}
              onChange={(e) => createChangeHandler(["firstName"], e)}
            />
            <TextField
              sx={{ width: isMobile ? "100%" : "calc(50% - 12px)" }}
              margin="dense"
              label="Last name"
              type="text"
              variant="outlined"
              autoComplete="off"
              value={formData.lastName || ""}
              error={!!errors?.lastName}
              helperText={errors?.lastName}
              onChange={(e) => createChangeHandler(["lastName"], e)}
            />
            <PhoneInput
              value={formData.personalPhone || ""}
              error={!!errors?.personalPhone}
              helperText={errors?.personalPhone}
              onChange={(phone) => {
                createChangeHandler(
                  ["personalPhone"],
                  cleanPhone(
                    typeof phone == "string" ? phone : phone.target.value,
                  ),
                );
              }}
              label="Phone"
              size="small"
              sx={{
                width: isMobile ? "100%" : "calc(50% - 12px)",
                marginTop: "8px",
                marginBottom: "4px",
                flexGrow: 1,
              }}
              autoComplete="off"
            />
            <TextField
              sx={{ width: isMobile ? "100%" : "calc(50% - 12px)" }}
              disabled
              margin="dense"
              label="Email"
              name="username"
              type="email"
              variant="outlined"
              autoComplete="off"
              value={formData.personalEmail || ""}
            />
            <PasswordInput
              password={formData.password || ""}
              onChange={(pw) => {
                createChangeHandler(["password"], pw);
              }}
              sx={{ width: isMobile ? "100%" : "calc(50% - 12px)" }}
            />
          </Box>
        </Box>
        <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
          <Box>
            <Typography variant="h3Serif">Home Address</Typography>
            <Typography
              variant="bodySmall"
              sx={{ marginTop: "8px", marginBottom: "16px" }}
            >
              We might occasionally send you materials or goodies!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              flexWrap: "wrap",
            }}
          >
            <AddressAutocomplete
              label={"Address"}
              error={errors?.location}
              initialValue={
                formData.address
                  ? JSON.parse(formData?.address || "")?.formattedAddress
                  : ""
              }
              setValue={(location) => {
                setFormData((prev) => ({
                  ...prev,
                  address: JSON.stringify(location),
                }));
              }}
            />
          </Box>
        </Box>
        <Box sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}>
          <Button disabled={loading} type="submit" sx={{ width: "100%" }}>
            Continue
          </Button>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
