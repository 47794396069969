import { useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { generateSearchQueryUrl } from "../search/utils";
import { debounce } from "lodash";

type Props = {
  topics?: string[];
  fullWidth?: boolean;
  initialQuery?: string;
  onQuery?: (query: string) => void;
  captureHotkeys?: boolean;
  autofocus?: boolean;
  expand?: boolean;
  onKeyPress?: boolean;
  sx?: React.CSSProperties;
  debounceTimeoutMs?: number;
};
export default ({
  topics,
  initialQuery,
  onQuery,
  fullWidth = false,
  captureHotkeys = false,
  autofocus = false,
  expand = true,
  onKeyPress = false,
  sx,
  debounceTimeoutMs,
}: Props) => {
  const navigate = useNavigate();
  const textFieldRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState(false);

  // Create debounced query handler if timeout is specified
  const debouncedQuery = debounceTimeoutMs
    ? debounce((query: string) => {
        onQuery?.(query);
      }, debounceTimeoutMs)
    : null;

  const handleQuery = (immediate = false) => {
    const query = textFieldRef.current?.value.trim();
    if (onQuery) {
      if (debounceTimeoutMs && !immediate) {
        // Use debounced version
        debouncedQuery?.(query || "");
      } else {
        // Cancel any pending debounced calls and execute immediately
        debouncedQuery?.cancel();
        onQuery(query || "");
      }
    } else if (query) {
      navigate(generateSearchQueryUrl(query, topics, "community"));
    }
  };

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedQuery?.cancel();
    };
  }, [debouncedQuery]);

  // Capture search events and focus the search bar.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        captureHotkeys &&
        (event.ctrlKey || event.metaKey) &&
        event.key === "k"
      ) {
        textFieldRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [captureHotkeys]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        flex: "1 1 auto",
        ...(fullWidth
          ? {}
          : {
              transition: "max-width 0.5s",
              maxWidth:
                expand && (focused || textFieldRef.current?.value.trim())
                  ? "500px"
                  : "250px",
            }),
        ...sx,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          borderRadius: "12px",
          border: "1px solid #E2E3E4",
        }}
      >
        <IconButton
          type="button"
          sx={{ p: "10px", paddingRight: "0px", color: "#B3AFB6" }}
          aria-label="search"
          onClick={() => handleQuery(true)}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          autoFocus={autofocus}
          inputRef={textFieldRef}
          fullWidth
          defaultValue={initialQuery}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "Search" }}
          onKeyUp={(event) => {
            if (onKeyPress) {
              event.preventDefault();
              handleQuery(true);
              return;
            }
          }}
          onKeyDown={(event) => {
            // Allow shift for newlines.
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleQuery(true);
              return;
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
    </Box>
  );
};
