import React, { forwardRef } from "react";
import { StepRef, StepProps } from "../../types";
import StaticStep from "../../components/StaticStep";
import { Box, Button } from "@mui/material";
import { Linkedin, Instagram } from "lucide-react";

const socialButtonSx = {
  minWidth: "unset",
  width: "48px",
  height: "48px",
  padding: "0",
};

export default forwardRef<StepRef, StepProps>(({}, _ref) => {
  return (
    <StaticStep
      title="That's it! 🎉"
      img="/assets/images/nux/high-five.gif"
      mobileImg="/assets/images/nux/high-five.gif"
      layout="vertical"
      bodyContent={
        <>
          <Box>
            Thanks for taking the time to tell us more about you. This info will
            help us match you with an Advisor. We'll be in touch soon with an
            introduction!
          </Box>
          <Box>
            <Box>
              In the meantime, follow us for task inspiration and member tips.
            </Box>
            <Box sx={{ display: "flex", gap: "12px", marginTop: "16px" }}>
              <Button
                href="https://www.linkedin.com/company/tryfaye"
                sx={socialButtonSx}
              >
                <Linkedin width="24px" height="24px" />
              </Button>

              <Button
                size="medium"
                href="https://www.instagram.com/tryfaye"
                sx={socialButtonSx}
              >
                <Instagram width="24px" height="24px" />
              </Button>
            </Box>
          </Box>
        </>
      }
    />
  );
});
