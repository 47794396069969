import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FayeIcon } from "../icons/FayeBrand.svg";
import AuthService from "services/auth";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";
import { FayeAppBar } from "components/layout/NavigationLayout";
import LinkRouter from "components/navigation/LinkRouter";
import { isStandaloneWebapp } from "common/utils";

interface FormValues {
  username: string;
  password: string;
}

const cleanPhone = (phone: string): string => {
  const clean = phone.replace(/[^\d+]/g, "");
  if (clean.length === 11 && clean[0] === "1") {
    return `+${clean}`;
  } else if (clean.length === 10) {
    return `+1${clean}`;
  } else {
    return clean;
  }
};

const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const isValidPhone = (phone: string) => {
  return /^\+1\d{10}$/.test(cleanPhone(phone));
};

export default () => {
  const navigate = useNavigate();
  // const params = new URLSearchParams(window.location.search);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [sent, setSent] = useState(false);
  const isStandalone = isStandaloneWebapp();
  const [usePassword, setUsePassword] = useState<boolean>(isStandalone);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const intendedPath = queryParams.get("destination");
  // Default to the value in the local state, then the intended path, then null.
  const redirectedFrom = location.state?.from?.pathname || intendedPath || null;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    username: "",
    password: "",
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    if (email) {
      setFormValues({ ...formValues, username: email });
    }
  }, []);

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      navigate(intendedPath || "/");
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      !(isValidEmail(formValues.username) || isValidPhone(formValues.username))
    ) {
      setError("Please enter a valid email or phone number");
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
    if (usePassword) {
      AuthService.login(
        formValues.username,
        formValues.password,
        "member",
        null,
      ).then(
        () => {
          // Send them back to the page they tried to visit when they were
          // redirected to the login page. Use { replace: true } so we don't create
          // another entry in the history stack for the login page.  This means that
          // when they get to the protected page and click the back button, they
          // won't end up back on the login page, which is also really nice for the
          // user experience.
          navigate(redirectedFrom, { replace: true });
          setLoading(false);
          setError(null);
          // navigate(0); // Note - this still is stored ephemerally rather than persisted in the URL.
          // window.location.reload();
        },
        () => {
          setLoading(false);
          setError("Username or password is incorrect");
        },
      );
    } else {
      AuthService.initiateMemberLogin(
        isValidEmail(formValues.username) ? formValues.username : "",
        isValidPhone(formValues.username)
          ? cleanPhone(formValues.username)
          : "",
        redirectedFrom,
      ).then(
        () => {
          setSent(true);
          setLoading(false);
          setError(null);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setError(resMessage);
        },
      );
    }
  };

  useEffect(() => {
    if (location.state?.calendarEvent?.action === "confirm") {
      setSnackPack((prev) => [
        ...prev,
        {
          message: `Your event RSVP has been recorded`,
          key: new Date().getTime(),
        },
      ]);
    }
  }, []);

  return (
    <>
      <FayeAppBar />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "500px",
            padding: "0px 28px",
            alignSelf: "stretch",
          }}
        >
          <div>
            <FayeIcon
              style={{
                width: "112.762px",
                height: "64px",
                flexShrink: "0",
                marginBottom: "29px",
              }}
            />
          </div>
          <Typography
            variant="h2Serif"
            sx={{
              marginBottom: "8px",
            }}
          >
            Sign in to Faye
          </Typography>
          {/*<Typography*/}
          {/*  variant="body"*/}
          {/*  sx={{*/}
          {/*    marginBottom: "32px",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Your Family Advisor awaits.*/}
          {/*</Typography>*/}
          {sent && (
            <Alert
              severity="success"
              sx={{
                marginBottom: "12px",
                borderRadius: "12px",
                border: "2px solid #BEDDBB",
                background: "#FBFDFB",
                fontWeight: "500",
                color: "#3D7C37",
                alignSelf: "stretch",
                "& .MuiSvgIcon-root": {
                  color: "#468E3E",
                },
              }}
            >
              A link to sign in has been sent if there is a matching account.
              See you soon!
            </Alert>
          )}
          {error && (
            <Alert
              icon={false}
              severity="error"
              sx={{
                alignSelf: "stretch",
                marginBottom: "12px",
                borderRadius: "12px",
                border: "2px solid #FAD3D0",
                background: "#FFFBFA",
              }}
            >
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FormControl fullWidth style={{ marginTop: 16 }} disabled={loading}>
              <TextField
                sx={{ flexGrow: 1 }}
                disabled={loading || sent}
                autoFocus
                label="Email or phone number"
                name="username"
                type="text"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={formValues.username}
                onChange={handleInputChange}
              />
            </FormControl>
            {usePassword && (
              <FormControl
                fullWidth
                style={{ marginTop: 16 }}
                disabled={loading || sent}
              >
                <TextField
                  sx={{ flexGrow: 1 }}
                  disabled={loading}
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="on"
                  value={formValues.password}
                  onChange={handleInputChange}
                />
              </FormControl>
            )}
            <Button
              disabled={loading || sent}
              type="submit"
              fullWidth
              style={{ marginTop: "12px" }}
            >
              Sign in
            </Button>
            {!sent && !usePassword && (
              <Box
                sx={(theme) => ({
                  padding: "12px",
                  marginTop: "16px",
                  border: `1px solid ${theme.palette.border} `,
                  borderRadius: "12px",
                })}
              >
                <Typography
                  variant="bodySmall"
                  sx={{ marginBottom: "8px", display: "inline" }}
                >
                  We'll send you a one time link for a password-free sign in. Or
                  you can{" "}
                  <Typography
                    onClick={() => setUsePassword(true)}
                    variant="bodySmall"
                    sx={{
                      color: "primary.main",
                      cursor: "pointer",
                      display: "inline",
                    }}
                  >
                    sign in with a password instead
                  </Typography>
                  .
                </Typography>
              </Box>
            )}
            {usePassword && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
              >
                <LinkRouter
                  to={`/forgot-password${
                    formValues.username
                      ? `?username=${encodeURIComponent(formValues.username)}`
                      : ""
                  }`}
                >
                  <Button variant="text" sx={{ mt: 1, mr: 1 }}>
                    Forgot password?
                  </Button>
                </LinkRouter>
              </Box>
            )}
            {!isStandalone && (
              <>
                <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <Button
                    variant="text"
                    sx={{ mt: 1, mr: 1 }}
                    onClick={() => {
                      window.location.href = `https://advisor.findfaye.com/login${
                        isValidEmail(formValues.username)
                          ? `?username=${encodeURIComponent(
                              formValues.username,
                            )}`
                          : ""
                      }`;
                    }}
                  >
                    Are you a Family Advisor? Login here
                  </Button>
                </Box>
              </>
            )}
          </form>
        </Box>
        <SnackPack
          snackPack={snackPack}
          alertSeverity="success"
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        />
      </Box>
    </>
  );
};
