import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { FactSuggestion } from "protogen/suggestions_pb";
import { EphemeralFact } from "../facts/types";
import { getTypeViewByCategory } from "../facts/FactView";
import React from "react";
import SuggestionItemContainer from "./SuggestionItemContainer";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ExternalLink } from "lucide-react";

const FactSuggestionItem = ({
  familyRef,
  entityRef,
  entityType,
  suggestion,
  suggestFact,
  index,
  disabled = false,
}: {
  familyRef: string;
  entityRef: string;
  entityType: string;
  suggestion: FactSuggestion;
  suggestFact: (t: EphemeralFact) => void;
  index: number;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const factCreated = suggestion.factRef;
  const factType = getTypeViewByCategory(suggestion.valueType);
  const onClick = () => {
    if (factCreated) {
      navigate(
        `/families/${encodeURIComponent(
          familyRef,
        )}?factRef=${encodeURIComponent(suggestion.factRef)}`,
      );
    } else {
      suggestFact({
        name: suggestion.name,
        value: suggestion.value,
        valueType: factType.value,
        attachments: [],
        uploads: [],
        sourceEntityType: entityType,
        sourceEntityRef: entityRef,
        sourceEntityIndex: index,
      });
    }
  };
  return (
    <Box sx={{}}>
      <Button
        size="small"
        variant="text"
        disabled={disabled}
        sx={{
          display: "flex",
          alignItems: "start",
          lineHeight: "normal",
          textAlign: "left",
          color: "#198282",
          strokeWidth: "2px",
          height: "auto",
          ".MuiButton-startIcon": {
            marginLeft: "0px",
          },
        }}
        onClick={onClick}
        startIcon={
          factCreated ? (
            <ExternalLink height="20px" width="20px" strokeWidth="2px" />
          ) : (
            <PlusIcon height="20px" width="20px" strokeWidth="2px" />
          )
        }
      >
        {suggestion.name} -{" "}
        {factType.renderText
          ? factType.renderText(suggestion.value)
          : suggestion.value}
      </Button>
    </Box>
  );
};

export default ({
  familyRef,
  entityRef,
  entityType,
  suggestions,
  suggestFact,
  isOpen,
  disabled = false,
  inlineMode = false,
}: {
  familyRef: string;
  entityRef: string;
  entityType: string;
  suggestions: FactSuggestion[];
  suggestFact: (f: EphemeralFact) => void;
  isOpen: boolean;
  disabled?: boolean;
  inlineMode?: boolean;
}) => {
  return (
    <SuggestionItemContainer
      open={isOpen}
      inlineMode={inlineMode}
      title={`Add fact${suggestions.length === 1 ? "" : "s"}`}
    >
      {suggestions.map((suggestion: FactSuggestion, i: number) => {
        return (
          <FactSuggestionItem
            familyRef={familyRef}
            key={`suggestion-${suggestion.name}-${i}`}
            entityRef={entityRef}
            entityType={entityType}
            suggestion={suggestion}
            suggestFact={suggestFact}
            index={i}
            disabled={disabled}
          />
        );
      })}
    </SuggestionItemContainer>
  );
};
