import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FlexPage from "../components/layout/FlexPage";
import { useListEvents } from "../services/calendar";
import { ListEventsRequest } from "protogen/calendar_service_pb";
import { defaultParametersV2, CalendarParametersV2 } from "types/calendars";
import { useListAdvisorFamilies } from "services/advisor";
import { EventNotice } from "protogen/calendar_pb";
import FayeCalendar from "components/calendar/FayeCalendar";
import { createColorMap, defaultColor } from "components/calendar/utils";
import useIsMobile from "components/hooks/useIsMobile";
import { AdvisorCalendarTodos } from "components/calendar/CalendarTodos";
import { Family, FamilyStatus } from "protogen/advisors_service_pb";
import useDateParams from "components/hooks/calendar/useDateParams";
import { deduplicate } from "components/calendar/utils";
import { useSearchParams } from "react-router-dom";

const ADVISOR_CALENDAR = "my-events";

const usePersistedFilteredFamilies = (): [
  Set<string> | null,
  (f: Set<string> | null) => void,
] => {
  const initialParams = new URLSearchParams(window.location.search);
  const [, setSearchParams] = useSearchParams();
  let initial = null;

  if (initialParams.get("calendars")) {
    const queryResults = new Set(
      (initialParams.get("calendars") || "").split(",").filter((s) => s),
    );
    initial = queryResults.size ? queryResults : null;
  }
  const [filteredFamilies, setFilteredFamilies] = useState<Set<string> | null>(
    initial,
  );
  useEffect(() => {
    setSearchParams(
      filteredFamilies && filteredFamilies.size > 0
        ? {
            calendars: Array.from(filteredFamilies).join(","),
          }
        : {},
    );
  }, [filteredFamilies]);
  return [filteredFamilies, setFilteredFamilies];
};

export default ({}: { eventRef?: string }) => {
  const isMobile = useIsMobile();
  const defaultDate = useDateParams();
  const [events, setEvents] = useState<EventNotice[]>([]);
  const [params, setParams] = useState<CalendarParametersV2>({
    ...defaultParametersV2(),
  });
  const [filteredFamilies, setFilteredFamilies] =
    usePersistedFilteredFamilies();
  const { data: familiesData, request: familiesRequest } =
    useListAdvisorFamilies();

  const _activeFamilies = (families_?: Family[]) =>
    (families_ || []).filter(
      (f) =>
        f.status !== FamilyStatus.DEACTIVATED &&
        f.status !== FamilyStatus.DEACTIVATED_PROSPECT,
    );

  const { request: eventsRequest, loading } = useListEvents();
  const [familyColorMap, setFamilyColorMap] = useState<Record<
    string,
    [string, string]
  > | null>(null);
  const onLoad = async () => {
    const families = _activeFamilies((await familiesRequest())?.families || []);
    if (!familyColorMap) {
      setFamilyColorMap(createColorMap(families.map((f) => f.ref)));
    }
    // For the first request, pass in families.  Using the data or setState is async
    // and will be null when we make this request
    fetchEvents(defaultDate, families);
  };
  useEffect(() => {
    onLoad();
  }, []);
  const fetchEvents = async (
    newDate: Date,
    families?: Family[],
    includeDuplicates = false,
  ) => {
    const familyData = families || _activeFamilies(familiesData?.families);
    const todayMonth = newDate.getMonth() + 1;
    setParams({
      ...params,
      month: todayMonth,
      year: newDate.getFullYear(),
      day: newDate.getDate(),
    });

    // For week view - there can be two months in a week
    const oneWeekLater = new Date(newDate);
    oneWeekLater.setDate(oneWeekLater.getDate() + 7);
    const oneWeekMonth = oneWeekLater.getMonth() + 1;
    let oneWeekYear = oneWeekLater.getFullYear();
    // Check if the month goes over December
    if (oneWeekMonth > 12) {
      oneWeekYear += 1;
    }

    const requests = [
      eventsRequest(
        new ListEventsRequest({
          ...params,
          familyRefs: familyData.map((f) => f.ref),
          month: todayMonth,
          year: newDate.getFullYear(),
          includeExternalEvents: true,
          includeAdvisorEvents: true,
          forceIncludeUnreadEvents: true,
          includeDuplicateEvents: includeDuplicates,
        }),
      ),
    ];

    // This can occur on week view where months overlap
    if (todayMonth !== oneWeekMonth) {
      requests.push(
        eventsRequest(
          new ListEventsRequest({
            ...params,
            familyRefs: familyData.map((f) => f.ref),
            month: oneWeekMonth,
            year: oneWeekYear,
            includeExternalEvents: true,
            includeAdvisorEvents: true,
            includeOverdueTasks: false,
            includeDuplicateEvents: includeDuplicates,
          }),
        ),
      );
    }

    const results = await Promise.all(requests);
    // A basic dedupe on eventRef and startSec.  The FE requests month/year,
    // but the backend request an extra day window on each end to account for
    // timezone.  A more robust API that accepts more granular time or other improvements
    // can remove this dedupe.
    const allEvents = deduplicate(
      results.flatMap((result) => result?.eventNotices || []),
      ["eventRef", "startSec"],
    );

    setEvents(allEvents);
  };

  return (
    <FlexPage
      leftAligned
      fullHeight
      sx={{
        padding: isMobile ? "16px 6px 0" : "16px 32px 0",
        maxWidth: "1400px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <AdvisorCalendarTodos />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "10px",
            flexGrow: 1,
          }}
        >
          <FayeCalendar
            accountType="advisor"
            families={familiesData?.families}
            events={events.filter((e) => {
              if (filteredFamilies === null) {
                return true;
              }
              return (
                filteredFamilies.has(e.familyRef) ||
                ((e.externalAdvisorCalendarRef ||
                  // Handle faye owned non-family events.
                  !e.familyRef) &&
                  filteredFamilies.has(ADVISOR_CALENDAR))
              );
            })}
            calendarFilters={[
              ..._activeFamilies(familiesData?.families)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((f) => ({
                  key: f.ref,
                  name: f.name,
                  color: familyColorMap?.[f.ref][0],
                  defaultUnchecked:
                    filteredFamilies !== null && !filteredFamilies.has(f.ref),
                })),
              // Handle faye owned non-family events.
              {
                name: "My Calendar",
                key: ADVISOR_CALENDAR,
                defaultUnchecked:
                  filteredFamilies !== null &&
                  !filteredFamilies.has(ADVISOR_CALENDAR),
              },
              // When we have a lot of families, add a quick filter for only my calendar.
              ...(_activeFamilies(familiesData?.families).length > 3
                ? [
                    {
                      // add divider, flg as mutually exclusive.
                      name: "Show only my calendar",
                      key: "only_my_calendar",
                      action: () => {
                        return [ADVISOR_CALENDAR];
                      },
                    },
                  ]
                : []),
            ]}
            setEnabledFilters={(filters) => {
              setFilteredFamilies(new Set(filters.map((f) => f.key)));
            }}
            getEventStyles={(event) => {
              const colors = familyColorMap?.[event.familyRef];
              return {
                backgroundColor: colors?.[0] || defaultColor[0],
                color: colors?.[1] || defaultColor[1],
              };
            }}
            refreshEvents={async (includeDuplicates) => {
              fetchEvents(
                new Date(params.year, params.month - 1, params.day),
                undefined,
                includeDuplicates,
              );
            }}
            handleNavigate={async (d) => {
              fetchEvents(d);
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </FlexPage>
  );
};
