import { CSSProperties, ReactNode, useState, useEffect } from "react";
import {
  Calendar,
  DateLocalizer,
  NavigateAction,
  TitleOptions,
  View,
  ViewProps,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  addMinutes,
  format,
  startOfDay,
  parse,
  endOfDay,
  fromUnixTime,
  getHours,
  addHours,
} from "date-fns";
import { EventNotice, EventNotice_NoticeType } from "protogen/calendar_pb";
import { Family } from "protogen/advisors_service_pb";
import "./CalendarComponent.css";
import CalendarWeekHeader from "./CalendarWeekHeader";
import CalendarMonthHeader from "./CalendarMonthHeader";
import CustomToolbar, { BaseToolbarProps } from "./CustomToolbar";
import CustomAgenda from "./CustomAgenda";
import {
  defaultColor,
  convertTimeToDate,
  getEarliestEventOfWeek,
} from "./utils";

interface CalendarProps {
  filtersDelegate?: ReactNode | null;
  events: EventNotice[];
  localizer: DateLocalizer;
  handleSelectEvent?: (event: EventNotice) => void;
  handleNewEvent?: () => void;
  handleRefresh?: () => void;
  handleSelectSlot?: ({ start, end }: { start: Date; end: Date }) => void;
  handleNavigate?: (newDate: Date) => Promise<void>;
  families?: Family[];
  embeddedMode?: boolean;
  getEventStyles?: (event: EventNotice) => CSSProperties | null;
  defaultDate: Date;
  defaultView: View;
}

const timeRangeFormat = ({ start, end }: { start: Date; end: Date }) => {
  const startTime =
    format(start, "m") === "0" ? format(start, "h") : format(start, "h:mm");
  const endTime =
    format(end, "m") === "0" ? format(end, "h") : format(end, "h:mm");
  const endPeriod = format(end, "a").toLowerCase(); // pm or am

  return `${startTime} - ${endTime}${endPeriod}`;
};

const dateRangeFormat = ({ start, end }: { start: Date; end: Date }) => {
  const sameMonth = start.getMonth() === end.getMonth();
  const sameYear = start.getFullYear() === end.getFullYear();

  if (sameMonth && sameYear) {
    // Case 1: Same month and year
    return `${format(start, "MMMM yyyy")}`;
  } else if (sameYear) {
    // Case 2: Different months in the same year
    return `${format(start, "MMM")} - ${format(end, "MMM yyyy")}`;
  } else {
    // Case 3: Spanning different years
    return `${format(start, "MMM yyyy")} - ${format(end, "MMM yyyy")}`;
  }
};

export interface CalendarEvents extends EventNotice {
  start: Date;
  end: Date;
}

export default ({
  localizer,
  events,
  handleSelectEvent,
  handleSelectSlot,
  handleNewEvent,
  handleRefresh,
  handleNavigate,
  filtersDelegate,
  families,
  embeddedMode,
  getEventStyles,
  defaultDate,
  defaultView,
}: CalendarProps) => {
  const [scrollToTime, setScrollToTime] = useState(
    addMinutes(startOfDay(new Date()), 450),
  );
  const [initialScrollSet, setInitialScrollSet] = useState(false);
  const [view, setView] = useState<View>(defaultView);
  const calendarEvents = events.map((e) => {
    const event = new EventNotice({ ...e }) as CalendarEvents;

    // All day events are stored as UTC at midnight, so we need to adjust for timezones
    event.start = event.allDay
      ? startOfDay(parse(event.startDate, "yyyy-MM-dd", new Date()))
      : new Date(Number(e.startSec) * 1000);
    event.end = event.allDay
      ? endOfDay(
          parse(event.endDate || event.startDate, "yyyy-MM-dd", new Date()),
        )
      : new Date(Number(e.endSec) * 1000);

    // Recurring events are projected on the backend in epoch (UTC) and is timezone agnostic
    // If the recurring event overlaps daylight savings changes, we need to refer to the original reference date
    // for the "original" hour of the event.
    if (event.recurringReferenceStartSec && !event.allDay) {
      const referenceDate = fromUnixTime(
        Number(event.recurringReferenceStartSec),
      );
      const projectedDate = fromUnixTime(Number(event.startSec));

      const hourDiff = getHours(referenceDate) - getHours(projectedDate);

      if (hourDiff !== 0) {
        event.start = addHours(projectedDate, hourDiff);
        event.end = addHours(event.end, hourDiff);
      }
    }
    return event;
  });

  // Create a custom Wrapper to send in our own props.
  const CustomAgendaWrapper = ((props: ViewProps<EventNotice>) => {
    return (
      <CustomAgenda
        {...props}
        eventListProps={{
          events,
          families,
          familyPageView: embeddedMode,
          getEventStyles,
        }}
      />
    );
  }) as React.FC<ViewProps<EventNotice>> & {
    title: (date: Date, options: TitleOptions) => string;
    navigate: (date: Date, action: NavigateAction) => Date;
  };
  // Add the static properties
  CustomAgendaWrapper.title = (date: Date, { localizer }: TitleOptions) =>
    localizer.format(date, "MMMM yyyy");

  CustomAgendaWrapper.navigate = (date: Date, action: NavigateAction) => {
    switch (action) {
      case "PREV":
        return new Date(date.getFullYear(), date.getMonth() - 1, 1);
      case "NEXT":
        return new Date(date.getFullYear(), date.getMonth() + 1, 1);
      default:
        return date;
    }
  };

  const getEventStyle = (event: CalendarEvents, start: Date, end: Date) => {
    const styles = getEventStyles?.(event);
    const shortEvent = end.getTime() - start.getTime() < 1000 * 60 * 60;
    let classNames = [];
    if (shortEvent) {
      classNames.push("hide-event-label");
    }
    if (
      event.noticeType === EventNotice_NoticeType.NoticeType_EVENT ||
      event.noticeType === EventNotice_NoticeType.NoticeType_RECURRING_EVENT
    ) {
      classNames.push("faye-internal-event");
    }
    if (event.allDay) {
      classNames.push("rbc-all-day-event-cell");
    }
    const [bgColor, textColor] = defaultColor;
    return {
      className: classNames.length ? classNames.join(" ") : undefined,
      style: {
        // Should be overridden by getEventStyles/...styles
        color: textColor,
        backgroundColor: bgColor,
        borderColor: bgColor,
        borderRadius: "5px",
        outline: "1px solid white",
        padding: "2px 5px",
        ...styles,
      },
    };
  };

  const _handleNavigate = (newDate: Date) => {
    handleNavigate && handleNavigate(newDate);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("year", newDate.getFullYear().toString());
    queryParams.set("month", newDate.getMonth().toString());
    queryParams.set("day", newDate.getDate().toString());
    queryParams.delete("dateCursor");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}${
      window.location.hash
    }`;
    window.history.replaceState({}, "", newUrl);
  };

  const handleView = (view: View) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("view", view);
    queryParams.delete("dateCursor");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}${
      window.location.hash
    }`;
    window.history.replaceState({}, "", newUrl);
    setView(view);
  };

  const handleShowMore = (date: Date) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("view", "agenda");
    queryParams.set("dateCursor", date.getTime().toString());
    const newUrl = `${window.location.pathname}?${queryParams.toString()}${
      window.location.hash
    }`;
    window.history.replaceState({}, "", newUrl);
    setView("agenda");
  };

  const CustomToolbarWrapper = (props: BaseToolbarProps) => {
    return (
      <CustomToolbar
        {...props}
        handleNewEvent={handleNewEvent}
        handleRefresh={handleRefresh}
        filtersDelegate={filtersDelegate}
        minimalHeader={embeddedMode}
      />
    );
  };

  // Custom event rendering for the month view
  const CustomMonthlyEvent = ({ event }: { event: CalendarEvents }) => {
    const formatTime = (date: Date) => {
      const minutes = date.getMinutes();
      const ft = minutes === 0 ? format(date, "ha") : format(date, "h:mma");
      return ft.toLowerCase();
    };
    return (
      <span className={"rbc-month-event"}>
        {!event.allDay && (
          <span className={"rbc-month-event-time"}>
            {formatTime(event.start)}
          </span>
        )}
        <span className={"rbc-month-event-label"}>{event.title}</span>
      </span>
    );
  };

  useEffect(() => {
    if (events.length > 0 && !initialScrollSet) {
      setScrollToTime(
        convertTimeToDate(getEarliestEventOfWeek(calendarEvents, defaultDate)),
      );
      setInitialScrollSet(true);
    }
  }, [events]);

  return (
    <div className="calendar-container" style={{ width: "100%" }}>
      <Calendar<CalendarEvents>
        key={scrollToTime.toString()}
        defaultDate={defaultDate}
        localizer={localizer}
        events={calendarEvents}
        defaultView={view}
        view={view}
        views={{
          week: true,
          month: true,
          agenda: CustomAgendaWrapper,
        }}
        step={30}
        scrollToTime={scrollToTime}
        formats={{
          eventTimeRangeFormat: timeRangeFormat,
          timeGutterFormat: (date, culture, localizer) => {
            if (localizer === undefined) {
              return "";
            }

            return localizer?.format(date, "h a", culture);
          },
          dayRangeHeaderFormat: dateRangeFormat,
          agendaHeaderFormat: dateRangeFormat,
          monthHeaderFormat: (date) => `${format(date, "MMMM yyyy")}`, // Displays "Nov 2025" for the month header
        }}
        components={{
          toolbar: CustomToolbarWrapper,
          week: {
            header: CalendarWeekHeader,
          },
          month: {
            header: CalendarMonthHeader,
            event: CustomMonthlyEvent,
          },
        }}
        onNavigate={_handleNavigate}
        onView={handleView}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        className="custom-calendar"
        eventPropGetter={(event, start, end) =>
          getEventStyle(event, start, end)
        }
        doShowMoreDrillDown={false}
        onShowMore={(events: CalendarEvents[], date: Date) => {
          handleShowMore(date);
        }}
      />
    </div>
  );
};
