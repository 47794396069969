import React, { forwardRef, useState } from "react";
import { StepRef, StepProps } from "../types";
import { Box, Button, Link, TextField, Typography } from "@mui/material";
import StaticStep from "../components/StaticStep";
import { format } from "date-fns";
import useIsMobile from "components/hooks/useIsMobile";

export default forwardRef<StepRef, StepProps>(
  ({ signupState, onNext }, _ref) => {
    const [inputValue, setInputValue] = useState(
      signupState.referralSource || "",
    );
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isMobile = useIsMobile();
    const handleClick = async () => {
      onNext && (await onNext({ referralSource: inputValue }));
      setIsSubmitted(true);
    };

    return (
      <StaticStep
        title="You're booked!"
        img="/assets/images/nux/consultation-confirmation.png"
        bodyContent={
          <>
            <Box>
              {signupState.consultation?.eventDate && (
                <Typography variant="h4">
                  {format(
                    signupState.consultation.eventDate,
                    "EEEE, MMMM d 'at' h:mm a",
                  )}
                </Typography>
              )}
            </Box>
            <Box>
              We’ll send a confirmation to your email and call you at the
              scheduled time.
            </Box>
            <Box key={2}>
              If you have any questions in the meantime, feel free to reach out
              to{" "}
              <Link href="mailto:support@findfaye.com" color="#198282">
                support@findfaye.com
              </Link>
              .
            </Box>
            <Box>Talk soon!</Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                marginTop: "32px",
              }}
            >
              <Typography variant="bodyHeavy">
                How did you hear about us?
              </Typography>
              <TextField
                multiline
                placeholder={"Instagram, Podcast, a friend, etc."}
                variant="outlined"
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value || "");
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    width: isMobile ? "100%" : "400px",
                    color: "inherit",
                    fontSize: "1rem",
                    fontWeight: "500",
                    alignItems: "start",
                    "& textarea": { marginTop: "0", padding: "20px 24px" },
                  },
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <Button
                  variant="contained"
                  sx={{ width: "fit-content" }}
                  onClick={handleClick}
                  disabled={isSubmitted}
                >
                  {isSubmitted ? "Submitted" : "Submit"}
                </Button>
                {isSubmitted && (
                  <Typography variant="bodyHeavy" sx={{ color: "#468E3E" }}>
                    Thanks!
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        }
      />
    );
  },
);
