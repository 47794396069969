import useIsMobile from "../hooks/useIsMobile";
import { Box, Typography } from "@mui/material";

type ResourceLink = {
  name: string;
  href: string;
  icon?: React.ReactNode;
  targetNew?: boolean;
};

export default ({ title, links }: { title: string; links: ResourceLink[] }) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isMobile ? "0px" : "8px",
      }}
    >
      <Typography variant="h4">{title}</Typography>
      {links.map((link) => (
        <Box
          key={`${link.name}-${link.href}`}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
          }}
        >
          {link.icon}
          <a
            style={{
              padding: isMobile ? "8px 0px" : "0px",
              fontWeight: "600",
              textDecoration: "none",
              color: "#198282",
              letterSpacing: "-0.15px",
            }}
            {...(link.targetNew && { target: "_blank" })}
            href={link.href}
          >
            {link.name}
          </a>
        </Box>
      ))}
    </Box>
  );
};
