import React, { forwardRef } from "react";
import { StepRef, StepProps, STEPS } from "../types";
import { Button } from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";
import StaticStep from "../components/StaticStep";

export default forwardRef<StepRef, StepProps>(({ onNext, disabled }, _ref) => {
  const isMobile = useIsMobile();

  return (
    <StaticStep
      title="Join now and start handing off tasks"
      img="/assets/images/nux/life-changing.png"
      mobileImg="/assets/images/nux/life-changing-mobile.png"
      bodyContent={
        <>
          We’ll match you with a Family Advisor within 48 hours. If you’re not
          feeling mental relief within the first two weeks, we’ll find you a
          better match.
        </>
      }
      actions={
        <>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? "medium" : "large"}
            disabled={disabled}
            onClick={() =>
              onNext?.({
                flowPath: STEPS.MEMBERSHIP,
              })
            }
            sx={{
              width: isMobile ? "100%" : "auto",
            }}
          >
            Choose my plan
          </Button>
          <Button
            variant="text"
            size={isMobile ? "medium" : "large"}
            disabled={disabled}
            onClick={() =>
              onNext?.({
                flowPath: STEPS.CONSULTATION_DETAILS,
              })
            }
            sx={{
              width: isMobile ? "100%" : "auto",
            }}
          >
            Or schedule a consultation
          </Button>
        </>
      }
    />
  );
});
