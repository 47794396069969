import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Snackbar,
  ButtonGroup,
  Alert,
} from "@mui/material";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import SearchBar from "../../components/forum/SearchBar";
import useIsMobile from "../../components/hooks/useIsMobile";
import { useListSubmissions } from "../../services/signup";
import {
  ListSubmissionsRequest_ListSubmissionsFilter,
  SignupSubmission,
} from "../../protogen/signup_service_pb";
import Loading from "../../components/common/Loading";
import LeadCard from "../../components/pipeline/LeadCard";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { leadStringMatch } from "../../components/pipeline/utils";

interface HeaderColumnProps {
  title: string;
  sortKey: keyof SignupSubmission;
  sorts: SortField[];
  setSorts: (s: SortField[]) => void;
  flex: number;
  end?: boolean;
}

interface SortField {
  field: keyof SignupSubmission;
  asc: boolean;
}

const HeaderColumn = ({
  title,
  sortKey,
  sorts,
  setSorts,
  flex,
  end,
}: HeaderColumnProps) => {
  const currentSortVal = sorts.find((s) => s.field === sortKey);
  const upArrow = currentSortVal && !currentSortVal.asc;

  const onClick = () => {
    const newSorts = sorts.filter((s) => s.field !== sortKey);
    newSorts.unshift({ field: sortKey, asc: !upArrow });
    setSorts(newSorts);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={flex}
      justifyContent={end ? "end" : "start"}
    >
      <Typography
        component="span"
        variant="bodySmallHeavy"
        sx={{
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={onClick}
      >
        {/*{upArrow ? <MoveUp size={16} /> : <MoveDown size={16} />}*/}
        {title}
      </Typography>
    </Box>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize filter from URL or default
  const initialFilter =
    searchParams.get("filter") ||
    ListSubmissionsRequest_ListSubmissionsFilter.AWAITING_ACTION;
  const [mainFilter, setMainFilter] =
    useState<ListSubmissionsRequest_ListSubmissionsFilter>(
      Number(initialFilter) as ListSubmissionsRequest_ListSubmissionsFilter,
    );
  const [initialLeadRef] = useState<string | undefined>(params.leadRef);
  const [leadRefs, setLeadRefs] = useState<string[]>(
    params.leadRef ? [params.leadRef] : [],
  );
  const [sorts, setSorts] = useState<SortField[]>([]);
  const [query, setQuery] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const { request, data, loading } = useListSubmissions();
  const refresh = async () => {
    await request({
      submissionStates: [],
      submissionRefs: leadRefs,
      filter: mainFilter,
    });
  };
  useEffect(() => {
    refresh();
  }, [mainFilter]);

  // Update URL when filter changes
  useEffect(() => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("filter", mainFilter.toString());
      return newParams;
    });
  }, [mainFilter]);

  // Update URL when lead card is opened/closed
  const handleLeadCardOpen = (ref: string) => {
    setLeadRefs([ref]);
    navigate(
      `/tools/pipeline-management/${ref}?filter=${mainFilter.toString()}`,
    );
  };

  const handleLeadCardClose = () => {
    setLeadRefs([]);
    navigate(`/tools/pipeline-management?filter=${mainFilter.toString()}`);
  };

  // const handleDownloadCsv = () => {
  //   const csvContent = [
  //     "Name,Date,Status,Source,Contact",
  //     // ...leads.map(
  //     //   (l) => `${l.name},${l.date},${l.status},${l.source},${l.contact}`,
  //     // ),
  //   ].join("\n");
  //   const blob = new Blob([csvContent], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "leads.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   setSnackbarOpen(true);
  // };

  let submissions = data?.submissions || [];
  // On initial load, we want to show the lead card for the lead that was referenced in the URL at the top.
  submissions = [
    ...submissions.filter((lead) => initialLeadRef === lead.ref),
    ...submissions.filter((lead) => initialLeadRef !== lead.ref),
  ];
  const actionableStatuses = new Set(["signup-complete", "awaiting-match"]);
  submissions = [
    ...submissions.filter((lead) =>
      actionableStatuses.has(lead.pipelineStatus),
    ),
    ...submissions.filter(
      (lead) => !actionableStatuses.has(lead.pipelineStatus),
    ),
  ];
  if (query) {
    submissions = submissions.filter((lead) => leadStringMatch(lead, query));
  }
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "64px",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              { name: "Home", link: "/" },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="12px"
              alignContent="center"
            >
              <Typography variant="display">Pipeline Management</Typography>
              {/*<IconButton*/}
              {/*  onClick={handleDownloadCsv}*/}
              {/*  // disabled={!data?.invoices}*/}
              {/*>*/}
              {/*  <Download size={16} />*/}
              {/*</IconButton>*/}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: isMobile ? "12px" : "24px",
        }}
      >
        <SearchBar
          initialQuery={query}
          fullWidth={isMobile}
          autofocus={true}
          onQuery={setQuery}
          onKeyPress={true}
          sx={{ marginLeft: "0" }}
        />
        <ButtonGroup
          style={{
            display: "flex",
            ...(isMobile && {
              marginBottom: "16px",
              width: "100%",
            }),
          }}
        >
          <Button
            sx={{ flex: "1", width: "100%" }}
            variant={
              mainFilter ===
              ListSubmissionsRequest_ListSubmissionsFilter.AWAITING_ACTION
                ? "buttonGroupSelected"
                : "buttonGroup"
            }
            onClick={() =>
              setMainFilter(
                ListSubmissionsRequest_ListSubmissionsFilter.AWAITING_ACTION,
              )
            }
          >
            Pending
          </Button>
          <Button
            sx={{ flex: "1", width: "100%" }}
            variant={
              mainFilter ===
              ListSubmissionsRequest_ListSubmissionsFilter.UPCOMING_CONSULTATION
                ? "buttonGroupSelected"
                : "buttonGroup"
            }
            onClick={() =>
              setMainFilter(
                ListSubmissionsRequest_ListSubmissionsFilter.UPCOMING_CONSULTATION,
              )
            }
          >
            Upcoming
          </Button>
          <Button
            sx={{
              flex: "1",
              width: "80px",
              minWidth: "unset",
            }}
            variant={
              mainFilter === ListSubmissionsRequest_ListSubmissionsFilter.ALL
                ? "buttonGroupSelected"
                : "buttonGroup"
            }
            onClick={() =>
              setMainFilter(ListSubmissionsRequest_ListSubmissionsFilter.ALL)
            }
          >
            All
          </Button>
        </ButtonGroup>
      </Box>

      {/* Table contents */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
        }}
      >
        {!isMobile && (
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              padding: isMobile ? "0" : "24px 32px 0 32px",
              marginRight: "34px",
              gap: "24px",
            }}
          >
            <HeaderColumn
              title="Lead"
              sortKey="pipelineStatus"
              sorts={sorts}
              setSorts={setSorts}
              flex={3}
            />
            <HeaderColumn
              title="Status"
              sortKey="pipelineStatus"
              sorts={sorts}
              setSorts={setSorts}
              flex={4}
            />
            <HeaderColumn
              title="Submitted"
              sortKey="pipelineStatus"
              sorts={sorts}
              setSorts={setSorts}
              flex={1}
              end={true}
            />
          </Box>
        )}
        {loading && <Loading />}
        {!data?.submissions?.length && !loading && (
          <Box>
            <Alert severity="info">No submissions found</Alert>
          </Box>
        )}
        {!loading &&
          submissions.map((lead) => (
            <LeadCard
              key={lead.ref}
              lead={lead}
              forceOpen={leadRefs.indexOf(lead.ref) >= 0}
              onClose={() => handleLeadCardClose()}
              onOpen={() => handleLeadCardOpen(lead.ref)}
              refresh={refresh}
            />
          ))}
      </Box>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={3000}
        message="CSV Downloaded"
      />
    </Box>
  );
};
