import { Button, IconButton } from "@mui/material";
import { Copy } from "lucide-react";
import React, { CSSProperties, useState } from "react";
import SnackPack, { SnackbarMessage } from "./SnackPack";

export default ({
  value,
  disabled = false,
  variant,
  label = "copy",
  sx,
}: {
  value: string;
  disabled?: boolean;
  variant?: "text" | "icon";
  label?: string;
  sx?: CSSProperties;
}) => {
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);

  return (
    <>
      {variant == "text" ? (
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setSnackPack([{ key: value, message: "Copied to clipboard" }]);
            navigator.clipboard.writeText(value);
          }}
          disabled={disabled}
          startIcon={<Copy size={20} />}
          sx={sx}
        >
          {label}
        </Button>
      ) : (
        <IconButton
          onClick={() => {
            setSnackPack([{ key: value, message: "Copied to clipboard" }]);
            navigator.clipboard.writeText(value);
          }}
          disabled={disabled}
          sx={sx}
        >
          <Copy size={20} />
        </IconButton>
      )}

      <SnackPack
        snackPack={snackPack}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </>
  );
};
