import {
  Fragment,
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  ReactNode,
  CSSProperties,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import {
  HomeIcon,
  Cog6ToothIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { CurrentUserContext } from "components/context/RequireAuth";
import { UserAvatar } from "components/common/CurrentUserAvatar";
import FlexPage from "components/layout/FlexPage";
import { useGetMemberDetails } from "services/member";
import { CurrentUser } from "protogen/auth_pb";
import { Task, TaskState } from "protogen/tasks_pb";
import { PublicAdvisor } from "protogen/common_pb";
import useIsMobile from "components/hooks/useIsMobile";
import SnackPack, { SnackbarMessage } from "components/common/SnackPack";
import TaskInspiration from "components/tasks/TaskInspiration";
import AddTaskBar from "components/tasks/AddTaskBar";
import MemberTaskSection from "components/tasks/MemberTaskSection";
import { useAppBarSx } from "components/context/AppBarContext";
import { FamilyStatus } from "protogen/advisors_service_pb";
import { ArrowRight, Mail, Phone } from "lucide-react";
import { formatUSPhoneNumber } from "../common/utils";
import LinkRouter from "components/navigation/LinkRouter";
import { useGlobalModalContext } from "components/context/GlobalModalContext";

type QuickLink = {
  icon: typeof HomeIcon | typeof ListBulletIcon | typeof Cog6ToothIcon;
  name: string;
  path: string;
};

const QUICK_LINKS: QuickLink[] = [
  { icon: HomeIcon, name: "Secrets and family info", path: "/family-info" },
  { icon: ListBulletIcon, name: "Tasks", path: "/tasks" },
  { icon: Cog6ToothIcon, name: "Profile and settings", path: "/settings" },
];

const DesktopCardWrapper = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: CSSProperties;
}) => {
  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.border}`,
        borderRadius: "16px",
        padding: "24px 32px",
        backgroundColor: "#FFF",
        width: "100%",
        ...sx,
      })}
    >
      {children}
    </Box>
  );
};

const WelcomeHeader = ({ user }: { user: CurrentUser }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 500, textWrap: "nowrap" }}>
          Welcome back,{" "}
        </Typography>
        <Typography variant="display" sx={{ fontSize: "32px" }}>
          {user.firstName} {user.lastName}
        </Typography>
      </Box>
    </Box>
  );
};

const AdvisorInfo = ({ advisor }: { advisor: PublicAdvisor }) => {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant={isMobile ? "h3" : "h4"}>Family Advisor</Typography>
      {advisor && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <UserAvatar size={80} user={advisor} memberLink={true} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <LinkRouter to={`/profile/${advisor.ref}`}>
              <Typography
                variant="body"
                color="text.primary"
                sx={{
                  cursor: "pointer",
                  lineHeight: "18px",
                  fontWeight: "600",
                }}
              >
                {advisor.displayName}
              </Typography>
            </LinkRouter>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Phone height="20px" width="20px" color="#198282" />
              <Button
                sx={(theme) => ({
                  height: "unset",
                  fontWeight: 500,
                  lineHeight: "unset",
                  color: theme.palette.text.primary,
                })}
                variant="text"
                component="a"
                href={`sms:${advisor?.advisorPhone!}`}
              >
                {formatUSPhoneNumber(advisor?.advisorPhone)}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Mail height="20px" width="20px" color="#198282" />
              <Button
                sx={(theme) => ({
                  height: "unset",
                  fontWeight: 500,
                  lineHeight: "unset",
                  color: theme.palette.text.primary,
                })}
                variant="text"
                component="a"
                href={`mailto:${advisor?.username!}`}
              >
                {advisor?.username}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const TaskSummary = ({ tasks }: { tasks: Task[] }) => {
  const countTasks = (state: TaskState): number => {
    return tasks.filter((task) => task.state === state).length;
  };
  return (
    <Box
      sx={{
        height: "83px",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        gap: "16px",
      }}
    >
      <LinkRouter
        to={"/tasks"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#FEF8F5",
          borderRadius: "8px",
          padding: "16px 24px",
          width: "50%",
          cursor: "pointer",
        }}
      >
        <Typography variant="title">
          {countTasks(TaskState.COMPLETED)}
        </Typography>
        <Typography variant="bodySmall">completed tasks</Typography>
      </LinkRouter>
      <LinkRouter
        to={"/tasks"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#FEF8F5",
          borderRadius: "8px",
          padding: "16px 24px",
          width: "50%",
          cursor: "pointer",
        }}
      >
        <Typography variant="title">
          {countTasks(TaskState.OPEN) + countTasks(TaskState.INPROGRESS)}
        </Typography>
        <Typography variant="bodySmall">open tasks</Typography>
      </LinkRouter>
    </Box>
  );
};

const TaskList = ({ tasks }: { tasks: Task[] }) => {
  const navigate = useNavigate();
  const openTasks = tasks.filter((task) => {
    return task.state === TaskState.OPEN;
  });
  const maxVisibleTasks = 5;
  const showMore = openTasks.length > maxVisibleTasks;
  const isMobile = useIsMobile();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Typography variant={isMobile ? "h3" : "h4"}>Open tasks</Typography>
      <MemberTaskSection
        tasks={openTasks.slice(0, maxVisibleTasks)}
        showBadges={false}
        sx={{
          border: "1px solid #D4D4D4",
          borderRadius: "4px 12px 12px 4px",
          borderLeft: "6px solid #198282",
        }}
      />
      {showMore && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Typography
            variant="body"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/tasks");
            }}
          >
            +{openTasks.length - maxVisibleTasks} more
          </Typography>
          <Box>
            <Button
              variant="text"
              sx={{ height: "auto" }}
              onClick={() => {
                navigate("/tasks");
              }}
            >
              View all tasks <ArrowRight size={20} />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const QuickLinks = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <Box>
      <Typography variant={isMobile ? "h3" : "h4"}>Quick links</Typography>
      <Box sx={{ paddingTop: "13px" }}>
        <List
          sx={{
            border: "1px solid #D4D4D4",
            borderRadius: "8px",
            paddingTop: 0,
            paddingBottom: 0,
            width: "100%",
          }}
        >
          {QUICK_LINKS.map((link, index, array) => {
            return (
              <Fragment key={link.name}>
                <ListItem disablePadding>
                  <ListItemButton
                    dense
                    sx={{
                      height: "60px",
                      paddingTop: 0,
                      paddingBottom: 0,
                      display: "flex",
                      gap: "8px",
                    }}
                    onClick={() => {
                      navigate(link.path);
                    }}
                  >
                    <IconButton aria-label="comments">
                      {<link.icon height="24px" width="24px" />}
                    </IconButton>
                    <ListItemText
                      primary={
                        <Typography color="#3D3D3D" variant="body">
                          {link.name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {index < array.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const isMobile = useIsMobile();
  const { request, data } = useGetMemberDetails(() => {});
  const currentUser = useContext(CurrentUserContext);
  const location = useLocation();
  const { setSx } = useAppBarSx();
  const [themeBase] = useState(
    document.querySelector("meta[name=theme-color]")?.getAttribute("content") ||
      undefined,
  );
  const { isModalOpen } = useGlobalModalContext();

  const homeSx = {
    background: "transparent",
    borderBottom: "none",
  };
  const originalSx = {
    background: "#FDFAF7",
    borderBottom: "1px solid #E8E8E8",
  };
  useLayoutEffect(() => {
    if (!isMobile) {
      return;
    }
    setSx(homeSx);
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 210) {
        setSx(originalSx);
        revertThemeColor();
      } else {
        setSx(homeSx);
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
          metaThemeColor.setAttribute("content", "#D9F0EB");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      setSx(originalSx);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  const revertThemeColor = () => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", themeBase || "");
    }
  };
  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute(
        "content",
        isModalOpen ? "#6D7876" : "#D9F0EB",
      );
    } else {
      revertThemeColor();
    }
    return () => {
      revertThemeColor();
    };
  }, [isModalOpen]);

  useEffect(() => {
    request();
    if (location.state?.calendarEvent?.action === "confirm") {
      setSnackPack((prev) => [
        ...prev,
        {
          message: `Your event RSVP has been recorded`,
          key: new Date().getTime(),
        },
      ]);
    }
  }, []);

  if (data?.family?.status === FamilyStatus.PROSPECT) {
    navigate("/welcome/landing");
  }

  return (
    <FlexPage
      sx={{
        maxWidth: "100%",
        borderRadius: "16px 0 0 0",
        overflow: isMobile ? "visible" : "auto",
        background: isMobile
          ? "#FFF"
          : "radial-gradient(36.57% 38.63% at 75.43% 53.79%, rgba(213, 104, 135, 0.20) 0%, rgba(213, 104, 135, 0.00) 100%), radial-gradient(73.43% 67.94% at 8.96% 100%, rgba(243, 213, 205, 0.55) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(88.17% 83.57% at 29.56% 16.11%, rgba(161, 222, 219, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%), #FEF8F5",
      }}
      leftAligned
      headerPadding={false}
      fullHeight={true}
    >
      {isMobile ? (
        <Box sx={{ maxWidth: "900px" }}>
          <Box
            sx={{
              margin: "0px -24px",
              marginTop: "-82px",
              padding: "0px 24px",
              paddingTop: "82px",
              backgroundImage: isMobile
                ? "url('../../assets/images/sparkles.png')"
                : "none",
              backgroundPosition: isMobile ? "top right" : "initial",
              backgroundRepeat: "no-repeat",
            }}
          >
            <WelcomeHeader user={currentUser} />
            <Box sx={{ height: "24px" }}></Box>
            <AddTaskBar family={data?.family!} backgroundColor={"#E5FBF7"} />
            <Box sx={{ height: "64px" }}></Box>
          </Box>
          <Box
            sx={{
              boxShadow: "0px -4px 27px 0px rgba(0, 0, 0, 0.10)",
              borderRadius: "32px 32px 0px 0px",
              margin: "-24px",
              padding: isMobile ? "32px 24px" : "24px",
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "column",
              gap: isMobile ? "40px" : "24px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <AdvisorInfo advisor={data?.primaryAdvisor!} />
              <TaskSummary tasks={data?.activeTasks || []} />
            </Box>
            <TaskList tasks={data?.activeTasks || []}></TaskList>
            <TaskInspiration
              family={data?.family!}
              activeTasks={data?.activeTasks || []}
            />
            <QuickLinks />
          </Box>
        </Box>
      ) : (
        <Box sx={{ alignSelf: "center", maxWidth: "900px", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "36px" }}>
            <Box sx={{ display: "flex", width: "100%", gap: "32px" }}>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  gap: "4px",
                }}
              >
                <WelcomeHeader user={currentUser} />
              </Box>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <AddTaskBar
                  family={data?.family!}
                  backgroundColor={"#FFF"}
                  buttonPlacement="visible"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "32px" }}>
              <DesktopCardWrapper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <AdvisorInfo advisor={data?.primaryAdvisor!} />
                <TaskSummary tasks={data?.activeTasks || []} />
                <QuickLinks />
              </DesktopCardWrapper>
              <DesktopCardWrapper>
                <TaskList tasks={data?.activeTasks || []}></TaskList>
              </DesktopCardWrapper>
            </Box>
            <DesktopCardWrapper>
              <TaskInspiration
                family={data?.family!}
                layout="horizontal"
                activeTasks={data?.activeTasks || []}
              />
            </DesktopCardWrapper>
          </Box>
        </Box>
      )}
      <SnackPack
        snackPack={snackPack}
        alertSeverity="success"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={!isMobile ? { marginLeft: "240px" } : {}}
      />
    </FlexPage>
  );
};
