import { Box, Button, Typography } from "@mui/material";
import { SecretStub } from "protogen/secrets_pb";
import { ShieldCheck } from "lucide-react";
import SecretList from "../secrets/SecretListView";

interface Props {
  secrets: SecretStub[];
  handleSecretClick: (secretRef: string) => void;
}
export default ({ secrets, handleSecretClick }: Props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <SecretList secrets={secrets} handleSecretClick={handleSecretClick} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "8px 12px 8px 16px",
          borderRadius: "12px",
          backgroundColor: "#FAF9FA",
        }}
      >
        <ShieldCheck size={24} stroke="#8E9598" style={{ flexShrink: 0 }} />
        <Typography variant="bodySmall" color="text.secondary">
          This information has additional safeguards and is encrypted at rest
          for security.
        </Typography>
      </Box>
      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            handleSecretClick("");
          }}
        >
          New secret
        </Button>
      </Box>
    </Box>
  );
};
