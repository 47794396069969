import { Box } from "@mui/material";
import ReactiveDialog from "../common/ReactiveDialog";
import { useEffect, useState } from "react";
import {
  useCreateFact,
  useEditFact,
  useDeleteFact,
  useGetFamilyFactSections,
} from "services/facts";
import { EphemeralFact, FormErrors } from "./types";
import { FactStub } from "protogen/facts_pb";
import FactView from "./FactView";
import FactTypeSelect from "./FactTypeSelect";
import { attachmentsToUploads } from "../creation/FileUploader";
import { DEFAULT_FACT_TYPE } from "./Constants";

type Props = {
  open: boolean;
  onClose: () => void;
  onCreate: (s?: FactStub) => void;
  onEdit: (s?: FactStub) => void;
  onDelete: (factRef: string) => void;

  familyRef: string;
  isEdit: boolean;
  fact: EphemeralFact | null;
  category?: string;
  factSectionRef?: string;
};

const defaultFormData = (category?: string): EphemeralFact => ({
  name: "",
  value: "",
  valueType: category || DEFAULT_FACT_TYPE.value,
  attachments: [],
  uploads: [],
});

const validateFact = (s: EphemeralFact): FormErrors => {
  const errors: FormErrors = {};
  if (!s.name) {
    errors.name = "Name is required";
  }
  if (!s.valueType) {
    errors.valueType = "Value Type is required";
  }
  return errors;
};

export default ({
  open,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  familyRef,
  fact,
  isEdit,
  category,
  factSectionRef,
}: Props) => {
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formData, setFormData] = useState<EphemeralFact>(
    defaultFormData(category),
  );
  const [isDirty, setIsDirty] = useState(false);
  const { request: createRequest } = useCreateFact((r) => {
    onCreate(r?.fact);
  });
  const { request: editRequest } = useEditFact((r) => {
    onEdit(r?.fact);
  });
  const { request: deleteRequest } = useDeleteFact(() => {
    onDelete(formData?.ref!);
  });

  const { request: getSectionsRequest, data: sectionData } =
    useGetFamilyFactSections();

  useEffect(() => {
    getSectionsRequest({ familyRef: familyRef });
    if (!open && !isEdit) {
      // Clear the form on close.
      setFormData(defaultFormData());
      setIsDirty(false);
    } else if (fact !== null) {
      setFormData({
        ref: fact.ref,
        name: fact.name,
        value: fact.value,
        valueType: fact.valueType,
        attachments: fact.attachments,
        uploads: attachmentsToUploads(fact.attachments || []),
        sourceEntityType: fact.sourceEntityType,
        sourceEntityRef: fact.sourceEntityRef,
        sourceEntityIndex: fact.sourceEntityIndex,
        sectionRef: fact.sectionRef,
      });
    }
  }, [open, fact]);

  const updateFormData = (
    s: EphemeralFact | ((s_: EphemeralFact) => EphemeralFact),
  ) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...(typeof s === "function" ? s(prevState) : s),
    }));
  };
  const _validate = () => {
    const errors = validateFact(formData);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSave = async () => {
    if (isEdit) {
      await editRequest({
        factRef: formData.ref!,
        name: formData.name,
        value: formData.value,
        attachments: formData.uploads,
        sortOrder: formData.sortOrder || 0,
        sectionRef: formData.sectionRef || "",
      });
    } else {
      if (_validate()) {
        createRequest({
          familyRef: familyRef,
          valueType: formData.valueType!,
          name: formData.name!,
          value: formData.value!,
          attachments: formData.uploads!,
          sourceEntityType: formData.sourceEntityType || "",
          sourceEntityRef: formData.sourceEntityRef || "",
          sourceEntityIndex: formData.sourceEntityIndex || 0,
          sectionRef: formData.sectionRef || "",
        });
      }
    }
  };
  return (
    <ReactiveDialog fullWidthSize="sm" open={open} onClose={() => onClose()}>
      <FactView
        fact={
          factSectionRef
            ? { ...formData, sectionRef: factSectionRef }
            : formData
        }
        sections={sectionData?.factSections || []}
        updateFormData={(fact) => {
          setIsDirty(true);
          updateFormData(fact);
        }}
        onSave={onSave}
        onCancel={() => onClose()}
        isEdit={isEdit}
        onDelete={() => {
          deleteRequest({ factRef: formData.ref! });
        }}
        children={
          !isEdit &&
          !category && (
            <Box sx={{ marginBottom: "12px" }}>
              <FactTypeSelect
                disabled={isDirty}
                selectedCategory={
                  formData?.valueType || DEFAULT_FACT_TYPE.value
                }
                onChange={(category) => {
                  updateFormData({
                    ...formData,
                    valueType: category,
                  });
                }}
                error={formErrors?.valueType}
              />
            </Box>
          )
        }
      />
    </ReactiveDialog>
  );
};
