import { styled } from "@mui/system";
import { EditorContent } from "@tiptap/react";

//
// IMPORTANT NOTE:
// Any stylings which may be applicable to sent emails MUST have their styles mirrored
// in the email template. Otherwise customers may receive an email that looks different.
// resources/email-templates/tip-tap-content.html.template
//

export const tableStyles = () => ({
  // A bunch of issues with trying to use borderCollapse/separate/spacing and a border radius.
  // The corners will be cut off.  Solution here (not all of them work):
  // https://stackoverflow.com/questions/628301/the-border-radius-property-and-border-collapsecollapse-dont-mix-how-can-i-use
  ".ProseMirror": {
    "table tr th, table tr td": {
      borderRight: "1px solid  rgba(61, 37, 20, 0.12)",
      borderBottom: "1px solid  rgba(61, 37, 20, 0.12)",
    },
    "table tr th:first-of-type, table tr td:first-of-type": {
      borderLeft: "1px solid  rgba(61, 37, 20, 0.12)",
    },
    "table tr th": {
      borderTop: "solid 1px  rgba(61, 37, 20, 0.12)",
    },
    "table tr:first-of-type th:first-of-type": {
      borderTopLeftRadius: "8px",
    },
    "table tr:first-of-type th:last-child": {
      borderTopRightRadius: "8px",
    },
    "table tr:last-child td:first-of-type": {
      borderBottomLeftRadius: "8px",
    },
    "table tr:last-child td:last-child": {
      borderBottomRightRadius: "8px",
    },
    table: {
      borderCollapse: "separate",
      borderSpacing: "0",
      minWidth: "350px",
      margin: 0,
      overflow: "hidden",
      tableLayout: "fixed",
      width: "100%",
      background: "white",
      borderRadius: "8px",

      th: {
        backgroundColor: "rgba(61, 37, 20, 0.05)",
        fontWeight: "bold",
        textAlign: "left",
      },
      ".selectedCell:after": {
        background: "rgba(61, 37, 20, 0.08)",
        // content: "",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        pointerEvents: "none",
        position: "absolute",
        zIndex: 2,
      },
      ".column-resize-handle": {
        backgroundColor: "black",
        bottom: "-2px",
        pointerEvents: "none",
        position: "absolute",
        right: "-2px",
        top: 0,
        width: "4px",
      },
      ".tableWrapper": {
        margin: "1.5rem 0",
        overflowX: "auto",
      },
      "&.resize-cursor": {
        // cursor: "ew-resize",
        cursor: "col-resize",
      },
      "td, th": {
        boxSizing: "border-box",
        minWidth: "1em",
        padding: "6px 8px",
        position: "relative",
        verticalAlign: "top",
        "> *": {
          marginBottom: 0,
        },
      },
      tr: {
        wordBreak: "break-word",
      },
      "td a": {
        // Override a specific prosemirror setting.
        wordBreak: "break-word",
      },
      ".selectedCell": {
        backgroundColor: "#C8DBD7 !important",
      },
    },
  },
});

export const taskListStyles = () => ({
  // https://tiptap.dev/api/nodes/task-list
  listStyle: "none",
  padding: 0,
  margin: "4px 0",

  p: {
    margin: 0,
  },

  li: {
    display: "flex",

    "> label": {
      flex: "0 0 auto",
      marginRight: "0.4rem",
      userSelect: "none",
    },

    "> label > input": {
      width: "16px",
      height: "16px",
    },

    "> div": {
      flex: "1 1 auto",
    },

    "ul li, ol li": {
      display: "list-item",
    },

    'ul[data-type="taskList"] > li': {
      display: "flex",
    },
  },
});

export default styled(EditorContent, {
  shouldForwardProp: (prop) =>
    prop !== "minHeight" && prop !== "scrollable" && prop !== "maxHeight",
})(
  ({
    minHeight = "150px",
    maxHeight = "100%",
    scrollable = false,
    fontSize = "16px",
  }: {
    minHeight?: string;
    maxHeight?: string;
    scrollable?: boolean;
    fontSize?: string;
  }) => ({
    ...(scrollable && {
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
    fontSize: `${fontSize}`,
    fontWeight: "500",
    minHeight: `${minHeight}`,
    flex: `1 1 ${minHeight}`,
    maxHeight: `${maxHeight}`,
    outline: "none",
    div: {
      outline: "none",
    },
    "& .ProseMirror p": {
      margin: 0,
    },
    "& .ProseMirror image, & .ProseMirror img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    "& .ProseMirror": {
      outline: "none",
      height: "100%",
    },
    "& .ProseMirror-focused": {
      outline: "none",
    },
    ".ProseMirror p.is-editor-empty:first-of-type::before": {
      color: "#adb5bd",
      content: "attr(data-placeholder)",
      float: "left",
      height: 0,
      pointerEvents: "none",
    },
    '.ProseMirror ul[data-type="taskList"]': {
      ...taskListStyles(),
    },
    "& .ProseMirror a": {
      wordBreak: "break-all",
      cursor: "pointer",
      color: "#198282",
      fontWeight: 600,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    ...tableStyles(),
  }),
);
