import React, { forwardRef, useImperativeHandle } from "react";
import { ChoiceSelections, StepProps, StepRef } from "../types";
import ChoiceStep from "../components/ChoiceStep";
import { Store, X, Heart, Baby, Dog, Briefcase, HousePlus } from "lucide-react";
export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }: StepProps, ref) => {
    useImperativeHandle(ref, () => ({}));
    return (
      <ChoiceStep
        title={"Tell us more about your household"}
        subtitle={
          "This helps us match you to an Advisor experienced in serving families like yours."
        }
        disabled={disabled}
        choiceOptions={[
          {
            id: "partner",
            text: "I’m married or live with a partner",
            icon: <Heart />,
          },
          {
            id: "children",
            text: "I have children",
            icon: <Baby />,
          },
          {
            id: "pets",
            text: "I have pets",
            icon: <Dog />,
          },
          {
            id: "demanding-job",
            text: "I work a demanding job",
            icon: <Briefcase />,
          },
          {
            id: "business-owner",
            text: "I own a business",
            icon: <Store />,
          },
          {
            id: "homeowner",
            text: "I'm a homeowner",
            icon: <HousePlus />,
          },
          {
            id: "none",
            text: "None of the above",
            icon: <X />,
            isSingleChoice: true,
            fullWidth: true,
          },
        ]}
        onNext={async (choices: ChoiceSelections) => {
          return onNext?.({ household: choices });
        }}
        initialChoices={signupState?.household || undefined}
      />
    );
  },
);
