import { BusinessInfo } from "protogen/forum_service_pb";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";
// import { ReactComponent as SparklesIcon } from "../../icons/Advisor/Sparkles.svg";
import RecommendationInfoDialog from "./BusinessInfoDialog";
import { FormData } from "./BusinessInfoDialog";
import { useUpdateBusinessInfo } from "services/forum";

const StyledPromptBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        display: "flex",
        padding: "24px",
        alignItems: "flex-start",
        alignSelf: "stretch",
        borderRadius: "8px",
        border: "1px solid #D4D4D4",
        background: "#FFF",
        marginTop: "36px",
        marginBottom: "36px",
        gap: "24px",
      }}
    >
      {children}
    </Box>
  );
};

const DetailsPrompts = ({
  onEdit,
  onDismiss,
}: {
  onEdit: () => void;
  onDismiss: () => void;
}) => {
  return (
    <StyledPromptBox>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          alignItems: "flex-start",
          gap: "6px",
          flex: "1 0 0",
        }}
      >
        <Typography variant="bodyHeavy">
          We’re missing information about this business
        </Typography>
        <Typography variant="bodySmall">
          Would you like to contribute more information? (phone, website,
          address, etc.)
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <Button variant="outlined" onClick={onEdit}>
          Add info
        </Button>
        <IconButton onClick={onDismiss}>
          <Close />
        </IconButton>
      </Box>
    </StyledPromptBox>
  );
};

const BusinessInfoSection = ({ info }: { info: BusinessInfo }) => {
  const ifPresent = (value?: string) => {
    if (!value) return null;
    return <Typography variant="body">{value}</Typography>;
  };
  return (
    <>
      {/* TODO(Kip): Add image support. */}
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: "flex",*/}
      {/*    flexDirection: "column",*/}
      {/*    alignItems: "center",*/}
      {/*    gap: "4px",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    src={thumbnailUrl}*/}
      {/*    alt={title}*/}
      {/*    style={{*/}
      {/*      height: "100%",*/}
      {/*      aspectRatio: "1 / 1",*/}
      {/*      objectFit: "cover",*/}
      {/*      maxHeight: "116px",*/}
      {/*      cursor: "pointer",*/}
      {/*      borderRadius: "8px",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Box>*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          flex: "1 0 0",
        }}
      >
        <Typography variant="h3">{info.name}</Typography>
        {ifPresent(info.phone)}
        {ifPresent(info.website)}
        {ifPresent(info.email)}
        {ifPresent(info?.location?.formattedAddress)}
      </Box>
    </>
  );
};

const Details = ({
  info,
  onEdit,
}: {
  info: BusinessInfo;
  onEdit: () => void;
}) => {
  return (
    <StyledPromptBox>
      <BusinessInfoSection info={info} />
      <Box sx={{}}>
        <Button variant="text" sx={{ height: "inherit" }} onClick={onEdit}>
          edit business info
        </Button>
      </Box>
    </StyledPromptBox>
  );
};

// const SuggestedDetails = ({ info }: { info: BusinessInfo }) => {
//   return (
//     <StyledPromptBox>
//       <Box display={"flex"} flexDirection={"column"} gap={"16px"} width="100%">
//         <Box display={"flex"} flexDirection={"row"} gap={"8px"}>
//           <SparklesIcon />
//           <Typography color="#198282" variant="bodySmallHeavy">
//             We found information for this business. Does it look right?
//           </Typography>
//         </Box>
//         <Box display={"flex"} flexDirection={"row"} gap={"24px"}>
//           <BusinessInfoSection info={info} />
//           <Box
//             display={"flex"}
//             flexDirection={"row"}
//             alignItems={"end"}
//             gap={"12px"}
//             sx={{}}
//           >
//             <Button variant="outlined">Dismiss</Button>
//             <Button>Review</Button>
//           </Box>
//         </Box>
//       </Box>
//     </StyledPromptBox>
//   );
// };

const baseInfo = () => {
  return {
    empty: false,
    dismissed: false,
    name: "",
    phone: "",
    website: "",
    email: "",
    national: false,
    location: {
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      latitude: 0,
      longitude: 0,
      formattedAddress: "",
      unit: "",
    },
  };
};

export default ({
  businessInfo,
  postRef,
  onUpdated,
}: {
  businessInfo: BusinessInfo;
  postRef: string;
  onUpdated: (BusinessInfo: BusinessInfo) => void;
}) => {
  const [open, setOpen] = useState(false);
  const { request, loading } = useUpdateBusinessInfo();
  const onDismiss = () => {
    request({
      postRef: postRef,
      businessInfo: {
        ...baseInfo(),
        dismissed: true,
      },
    });
  };

  const onUpdate = async (data: FormData) => {
    const resp = await request({
      postRef: postRef,
      businessInfo: {
        ...baseInfo(),
        name: data.name || "",
        phone: data.phone && data.phone !== "+1" ? data.phone : "",
        website: data.website || "",
        email: data.email || "",
        national: data.national || false,
        ...(data.location?.formattedAddress && { location: data.location }),
      },
    });
    if (resp?.businessInfo) {
      onUpdated(resp.businessInfo);
    }
    return !!resp;
  };

  if (businessInfo.dismissed) {
    return null;
  }
  // <SuggestedDetails post={post} />
  return (
    <>
      {businessInfo.empty && (
        <DetailsPrompts onEdit={() => setOpen(true)} onDismiss={onDismiss} />
      )}
      {!businessInfo.empty && (
        <Details info={businessInfo} onEdit={() => setOpen(true)} />
      )}
      <RecommendationInfoDialog
        disabled={loading}
        info={businessInfo}
        open={open}
        onClose={() => setOpen(false)}
        handleSave={onUpdate}
      />
    </>
  );
};
