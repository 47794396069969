import React, { useState } from "react";
import {
  CalendlyEvent,
  formatCalendlyUrl,
  getReschedulingURl,
  PageSettings,
  Prefill,
  Utm,
} from "./calendly";

// Inspired by https://github.com/tcampb/react-calendly

type DateAndTimeSelectedEvent = MessageEvent<{
  event: CalendlyEvent.DATE_AND_TIME_SELECTED;
  payload: {};
}>;

type ScheduledEventPayload = {
  event: {
    /**
     * @description Canonical reference (unique identifier) to the event that was scheduled.
     * @example https://calendly.com/api/v2/scheduled_events/AAAAAAAAAAAAAA
     * @see {@link https://developer.calendly.com/docs/api-docs/reference/calendly-api/openapi.yaml/paths/~1scheduled_events~1%7Buuid%7D/get} for further information.
     */
    uri: string;
  };
  invitee: {
    /**
     * @description Canonical reference (unique identifier) for the invitee who scheduled the event.
     * @example https://calendly.com/api/v2/scheduled_events/AAAAAAAAAAAAAA/invitees/AAAAAAAAAAAAAA
     * @see {@link https://developer.calendly.com/docs/api-docs/reference/calendly-api/openapi.yaml/paths/~1scheduled_events~1%7Bevent_uuid%7D~1invitees~1%7Binvitee_uuid%7D/get} for further information.
     */
    uri: string;
  };
};

type EventScheduledEvent = MessageEvent<{
  event: CalendlyEvent.EVENT_SCHEDULED;
  payload: ScheduledEventPayload;
}>;

type CalendlyEventHandlers = {
  onDateAndTimeSelected?: (e: DateAndTimeSelectedEvent) => any;
  onEventScheduled?: (e: EventScheduledEvent) => any;
};

const EVENT_NAME = "message";

const useCalendlyEventListener = (eventHandlers: CalendlyEventHandlers) => {
  const { onDateAndTimeSelected, onEventScheduled } = eventHandlers || {};

  React.useEffect(() => {
    const onMessage = (e: MessageEvent) => {
      const eventName = e.data.event;
      if (eventName === CalendlyEvent.DATE_AND_TIME_SELECTED) {
        onDateAndTimeSelected && onDateAndTimeSelected(e);
      } else if (eventName === CalendlyEvent.EVENT_SCHEDULED) {
        onEventScheduled && onEventScheduled(e);
      }
    };

    window.addEventListener(EVENT_NAME, onMessage);

    return function cleanup() {
      window.removeEventListener(EVENT_NAME, onMessage);
    };
  }, [eventHandlers]);
};

type Props = {
  schedulingUrl: string;
  onCompletion: (event: ScheduledEventPayload) => void;
  iframeTitle?: string;
  styles?: React.CSSProperties;
  pageSettings?: PageSettings;
  prefill?: Prefill;
  utm?: Utm;
  // If this event has already been scheduled, we can provide the URL to the event.
  scheduledEventInviteeUrl?: string;
};

export default ({
  schedulingUrl,
  onCompletion,
  iframeTitle = "Calendly Scheduling Page",
  styles,
  pageSettings,
  prefill,
  utm,
  scheduledEventInviteeUrl,
}: Props) => {
  const defaultStyles = {
    minWidth: "320px",
    height: "630px",
  };
  const [, setLoading] = useState(true);
  useCalendlyEventListener({
    // This one doesn't do much for us.
    onDateAndTimeSelected: () => {},
    onEventScheduled: (e: EventScheduledEvent) => {
      onCompletion(e.data.payload);
    },
  });
  let src = formatCalendlyUrl({
    url: schedulingUrl,
    pageSettings: pageSettings,
    prefill: prefill,
    utm: utm,
    embedType: "Inline",
  });
  if (scheduledEventInviteeUrl) {
    src = getReschedulingURl(scheduledEventInviteeUrl);
  }
  return (
    <div
      className="calendly-inline-widget"
      style={{
        ...defaultStyles,
        ...styles,
      }}
    >
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        title={iframeTitle}
        onLoad={() => setLoading(false)}
        src={src}
      ></iframe>
    </div>
  );
};
