import MuiPhoneNumber, { MuiPhoneNumberProps } from "mui-phone-number";
import {
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Fade,
} from "@mui/material";
import { Copy } from "lucide-react";
import { useState } from "react";
import { formatUSPhoneNumber } from "common/utils";

type MyProps = MuiPhoneNumberProps & {
  onChange: (value: string) => void;
  disableDropdown?: boolean;
  handleCopy?: boolean; // New prop for enabling copy functionality
};

export default ({
  onChange,
  disableDropdown = true,
  handleCopy,
  sx,
  ...otherProps
}: MyProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    if (otherProps.value) {
      const textToCopy = formatUSPhoneNumber(otherProps.value as string, true);
      if (textToCopy) {
        await navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Show "Copied" for 1.5 seconds
      }
    }
  };

  return (
    <MuiPhoneNumber
      {...otherProps}
      sx={{
        ...sx,
        // Only relevant to international phone numbers
        ".MuiIconButton-root": {
          margin: "14px 0 0 14px !important",
          minWidth: "20px !important",
          height: "22px !important",
        },
        ".MuiOutlinedInput-input": {
          padding: disableDropdown ? undefined : 0,
        },
      }}
      variant="outlined"
      disableDropdown={disableDropdown}
      defaultCountry="us"
      preferredCountries={["us"]}
      autoFormat={true}
      disableAreaCodes={true}
      onChange={(e) => {
        onChange(typeof e === "string" ? e : e.target.value);
      }}
      placeholder="Enter phone number"
      InputProps={{
        autoComplete: otherProps.autoComplete,
        endAdornment: handleCopy && otherProps.value && (
          <InputAdornment position="end" sx={{}}>
            <Box position="relative" display="flex" alignItems="center">
              <Fade in={!copied} timeout={300}>
                <IconButton
                  onClick={handleCopyClick}
                  edge="end"
                  sx={{
                    // Padding at the bottom is to counteract the !important marginTop we don't want.
                    paddingBottom: "14px",
                    // marginTop: "0px !important",
                  }}
                >
                  <Copy width="20" />
                </IconButton>
              </Fade>
              <Fade in={copied} timeout={300}>
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "700",
                    marginRight: "12px",
                  }}
                >
                  Copied
                </Typography>
              </Fade>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};
