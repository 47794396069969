import confetti from "canvas-confetti";
// See inspiration from https://www.kirilv.com/canvas-confetti/

const getElementCenter = (element: Element): { x: number; y: number } => {
  const buttonRect = element.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const buttonCenterX = buttonRect.left + buttonRect.width / 2;
  const buttonCenterY = buttonRect.top + buttonRect.height / 2;

  return {
    x: buttonCenterX / windowWidth,
    y: buttonCenterY / windowHeight,
  };
};

// A quick cannon blast of confetti.
const celebrate = (origin?: { x: number; y: number } | undefined) => {
  const count = 200;
  const defaults = {
    origin: origin || { y: 0.7 },
    zIndex: 9999,
  };

  const fire = (particleRatio: number, opts: any) => {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  };

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

export { getElementCenter, celebrate };
