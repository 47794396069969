import { Select, MenuItem } from "@mui/material";
import useIsMobile from "../hooks/useIsMobile";
import { TimezoneAbbreviations, Timezones } from "./Timezones";
import { ChevronDown } from "lucide-react";

export default ({
  timezone,
  setTimezone,
  sx,
  placeholder = "Select timezone",
  isSelectionClearable = false,
}: {
  timezone: string;
  setTimezone: (t: string) => void;
  sx?: React.CSSProperties;
  placeholder?: string;
  isSelectionClearable?: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <Select
      displayEmpty
      value={timezone}
      IconComponent={ChevronDown}
      onChange={(e) => {
        const value = e.target.value;
        if (value === "clear") {
          setTimezone("");
        } else {
          setTimezone(value);
        }
      }}
      sx={{
        "& .MuiInputBase-input": {
          marginTop: "0px !important",
          color: timezone === "" ? "#8E9598" : "inherit",
        },
        width: isMobile ? "100%" : "340px",
        ...sx,
      }}
    >
      <MenuItem value="" disabled>
        {placeholder}
      </MenuItem>
      {Timezones.map((tz) => (
        <MenuItem key={tz} value={tz}>
          {TimezoneAbbreviations[tz] || tz}
        </MenuItem>
      ))}
      {isSelectionClearable && (
        <MenuItem key="clear" value="clear">
          Clear
        </MenuItem>
      )}
    </Select>
  );
};
