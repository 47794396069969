// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/documents.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub, FamilyStub } from "./common_pb.ts";
import { Note } from "./notes_pb.ts";
import { TaskBlock } from "./tasks_pb.ts";
import { FactStub } from "./facts_pb.ts";

/**
 * @generated from enum protogen.DocumentType
 */
export enum DocumentType {
  /**
   * @generated from enum value: DOCUMENT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_TYPE_NOTE = 1;
   */
  NOTE = 1,

  /**
   * @generated from enum value: DOCUMENT_TYPE_TASK_BLOCK = 2;
   */
  TASK_BLOCK = 2,

  /**
   * @generated from enum value: DOCUMENT_TYPE_FACT = 3;
   */
  FACT = 3,

  /**
   * @generated from enum value: DOCUMENT_TYPE_CALL = 4;
   */
  CALL = 4,

  /**
   * @generated from enum value: DOCUMENT_TYPE_FACT_FAMILY = 5;
   */
  FACT_FAMILY = 5,

  /**
   * @generated from enum value: DOCUMENT_TYPE_FACT_FAMILY_MEMBER = 6;
   */
  FACT_FAMILY_MEMBER = 6,

  /**
   * Not a real document type, just one that we can copy from.gs
   *
   * @generated from enum value: DOCUMENT_TYPE_TEXT_MESSAGE = 7;
   */
  TEXT_MESSAGE = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentType)
proto3.util.setEnumType(DocumentType, "protogen.DocumentType", [
  { no: 0, name: "DOCUMENT_TYPE_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_TYPE_NOTE" },
  { no: 2, name: "DOCUMENT_TYPE_TASK_BLOCK" },
  { no: 3, name: "DOCUMENT_TYPE_FACT" },
  { no: 4, name: "DOCUMENT_TYPE_CALL" },
  { no: 5, name: "DOCUMENT_TYPE_FACT_FAMILY" },
  { no: 6, name: "DOCUMENT_TYPE_FACT_FAMILY_MEMBER" },
  { no: 7, name: "DOCUMENT_TYPE_TEXT_MESSAGE" },
]);

/**
 * @generated from enum protogen.DocumentScope
 */
export enum DocumentScope {
  /**
   * @generated from enum value: DOCUMENT_SCOPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_SCOPE_PRIVATE = 1;
   */
  PRIVATE = 1,

  /**
   * @generated from enum value: DOCUMENT_SCOPE_INTERNAL = 2;
   */
  INTERNAL = 2,

  /**
   * @generated from enum value: DOCUMENT_SCOPE_INTERNAL_DISCOVERABLE = 3;
   */
  INTERNAL_DISCOVERABLE = 3,

  /**
   * @generated from enum value: DOCUMENT_SCOPE_PUBLIC = 4;
   */
  PUBLIC = 4,

  /**
   * @generated from enum value: DOCUMENT_SCOPE_PUBLIC_DISCOVERABLE = 5;
   */
  PUBLIC_DISCOVERABLE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentScope)
proto3.util.setEnumType(DocumentScope, "protogen.DocumentScope", [
  { no: 0, name: "DOCUMENT_SCOPE_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_SCOPE_PRIVATE" },
  { no: 2, name: "DOCUMENT_SCOPE_INTERNAL" },
  { no: 3, name: "DOCUMENT_SCOPE_INTERNAL_DISCOVERABLE" },
  { no: 4, name: "DOCUMENT_SCOPE_PUBLIC" },
  { no: 5, name: "DOCUMENT_SCOPE_PUBLIC_DISCOVERABLE" },
]);

/**
 * @generated from enum protogen.DocumentStatus
 */
export enum DocumentStatus {
  /**
   * @generated from enum value: DOCUMENT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_STATUS_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: DOCUMENT_STATUS_DELETED = 2;
   */
  DELETED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentStatus)
proto3.util.setEnumType(DocumentStatus, "protogen.DocumentStatus", [
  { no: 0, name: "DOCUMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_STATUS_ACTIVE" },
  { no: 2, name: "DOCUMENT_STATUS_DELETED" },
]);

/**
 * @generated from enum protogen.Permission
 */
export enum Permission {
  /**
   * @generated from enum value: PERMISSION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PERMISSION_READ = 1;
   */
  READ = 1,

  /**
   * @generated from enum value: PERMISSION_WRITE = 2;
   */
  WRITE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Permission)
proto3.util.setEnumType(Permission, "protogen.Permission", [
  { no: 0, name: "PERMISSION_UNSPECIFIED" },
  { no: 1, name: "PERMISSION_READ" },
  { no: 2, name: "PERMISSION_WRITE" },
]);

/**
 * @generated from message protogen.Document
 */
export class Document extends Message<Document> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string subtitle = 3;
   */
  subtitle = "";

  /**
   * @generated from field: protogen.DocumentType documentType = 4;
   */
  documentType = DocumentType.UNSPECIFIED;

  /**
   * @generated from field: int64 lastUpdatedSec = 5;
   */
  lastUpdatedSec = protoInt64.zero;

  /**
   * @generated from field: string inboxType = 6;
   */
  inboxType = "";

  /**
   * @generated from field: string inboxEntityRef = 7;
   */
  inboxEntityRef = "";

  /**
   * @generated from field: protogen.FamilyStub family = 8;
   */
  family?: FamilyStub;

  /**
   * @generated from field: protogen.Note note = 9;
   */
  note?: Note;

  /**
   * @generated from field: protogen.TaskBlock taskBlock = 10;
   */
  taskBlock?: TaskBlock;

  /**
   * @generated from field: protogen.Permission permission = 11;
   */
  permission = Permission.UNSPECIFIED;

  /**
   * @generated from field: protogen.FactStub fact = 12;
   */
  fact?: FactStub;

  /**
   * @generated from field: string creationSource = 13;
   */
  creationSource = "";

  /**
   * @generated from field: protogen.DocumentScope scope = 14;
   */
  scope = DocumentScope.UNSPECIFIED;

  /**
   * @generated from field: string uuid = 15;
   */
  uuid = "";

  /**
   * @generated from field: protogen.AccountStub owner = 16;
   */
  owner?: AccountStub;

  constructor(data?: PartialMessage<Document>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Document";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subtitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "documentType", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 5, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "inboxType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "inboxEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "family", kind: "message", T: FamilyStub },
    { no: 9, name: "note", kind: "message", T: Note },
    { no: 10, name: "taskBlock", kind: "message", T: TaskBlock },
    { no: 11, name: "permission", kind: "enum", T: proto3.getEnumType(Permission) },
    { no: 12, name: "fact", kind: "message", T: FactStub },
    { no: 13, name: "creationSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "scope", kind: "enum", T: proto3.getEnumType(DocumentScope) },
    { no: 15, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "owner", kind: "message", T: AccountStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Document {
    return new Document().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Document {
    return new Document().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Document {
    return new Document().fromJsonString(jsonString, options);
  }

  static equals(a: Document | PlainMessage<Document> | undefined, b: Document | PlainMessage<Document> | undefined): boolean {
    return proto3.util.equals(Document, a, b);
  }
}

