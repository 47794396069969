import { useValidatePromoCode } from "../../../services/billing";
import { useEffect, useState } from "react";
import { Alert, Box, TextField } from "@mui/material";

export type PromoCodeState = {
  code: string;
  validated: "validated" | "invalid" | null;
  loading: boolean;
  error?: string;
  name?: string;
};

export default ({
  initialCode,
  onChange,
}: {
  initialCode?: string | undefined;
  // Set validated bool
  onChange(state: PromoCodeState): void;
}) => {
  const { request: validatePromoCodeRequest } = useValidatePromoCode();
  const [promoCode, setPromoCode] = useState<PromoCodeState>({
    code: initialCode || "",
    validated: null,
    loading: false,
  });
  useEffect(() => {
    if (promoCode.code && promoCode.validated === null) {
      checkValidation();
    }
  }, []);

  const checkValidation = async () => {
    if (
      !promoCode.code ||
      promoCode.validated === "validated" ||
      promoCode.validated === "invalid"
    ) {
      return;
    }
    setPromoCode({ ...promoCode, loading: true });
    const response = await validatePromoCodeRequest({
      promoCode: promoCode.code,
    });
    const update = {
      ...promoCode,
      loading: false,
      validated: response?.isValid ? "validated" : "invalid",
      error: response?.isValid ? undefined : "Invalid promo code.",
      name: response?.isValid ? response?.promoName : undefined,
    } as PromoCodeState;
    setPromoCode(update);
    onChange(update);
  };

  return (
    <Box>
      <TextField
        sx={{ width: "100%", backgroundColor: "#FFF" }}
        margin="none"
        label="Promo code  - Optional"
        name="promo code"
        type="string"
        size="small"
        variant="outlined"
        autoComplete="off"
        value={promoCode.code || ""}
        error={!!promoCode?.error}
        helperText={promoCode?.error}
        onChange={(e) => {
          const newCode = e.target.value.trim();
          if (newCode !== promoCode.code) {
            const update = {
              code: newCode,
              validated: null,
              loading: false,
              name: undefined,
            };
            setPromoCode(update);
            onChange(update);
          }
        }}
        onBlur={checkValidation}
      />
      {promoCode.validated === "validated" && promoCode.name && (
        <Alert
          severity={"success"}
          sx={{ marginTop: "15px", borderRadius: "12px" }}
        >
          Applied <i>{promoCode.name}</i>
        </Alert>
      )}
    </Box>
  );
};
