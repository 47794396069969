import { useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { AnnotationFlags } from "../../protogen/annotations_pb";
import { FLAGS_MAP } from "./flags";
import { relativeTime } from "../../common/utils";
import { getColor, getBgColor, getBorderColor, sortFlags } from "./utils";

export const FlagsSummary = ({ flags }: { flags: AnnotationFlags[] }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      maxWidth="300px"
      sx={{ margin: "0 auto" }}
    >
      {sortFlags(flags, false).map((flag) => {
        const flagDefinition = FLAGS_MAP[flag.name];
        const flagStatus = flag.active
          ? flagDefinition.activeStatus
          : "undefined";
        const IconComponent = flagDefinition.icon;
        const color = getColor(flagStatus);
        return (
          <Tooltip
            key={flag.name}
            title={
              <>
                <strong>{flagDefinition.title}</strong> {flag.summary}
              </>
            }
            arrow
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 30,
                height: 30,
                borderRadius: "50%",
              }}
            >
              <IconComponent style={{ color, width: 20, height: 20 }} />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export const FlagEntry = ({ flag }: { flag: AnnotationFlags }) => {
  const [open, setOpen] = useState(false);
  const flagDefinition = FLAGS_MAP[flag.name];
  const flagStatus = flag.active ? flagDefinition.activeStatus : "undefined";
  const IconComponent = flagDefinition.icon;
  const color = getColor(flagStatus);
  const bgColor = getBgColor(flagStatus);
  const borderColor = getBorderColor(flagStatus);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        gap: 1,
        padding: 1,
        borderRadius: 1,
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
        cursor: !open ? "pointer" : undefined,
        width: "100%",
      }}
      onClick={() => setOpen(true)}
    >
      <Box>
        <IconComponent style={{ color, width: 24, height: 24 }} />
      </Box>
      <Box>
        <Typography variant="body2" fontWeight="medium">
          {flagDefinition.title}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={
            !open
              ? {
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }
              : undefined
          }
        >
          {flag.summary}
        </Typography>
      </Box>
    </Box>
  );
};

const FlagsSection = ({ flags }: { flags: AnnotationFlags[] }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
      {sortFlags(flags).map((flag) => (
        <FlagEntry key={flag.name} flag={flag} />
      ))}
    </Box>
  );
};

type Props = {
  flags: AnnotationFlags[];
};

export default ({ flags }: Props) => {
  if (!flags.length) {
    return null;
  }
  const firstFlag = flags[0];
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ padding: "24px 32px" }}
      gap={"12px"}
    >
      <Typography variant="h3">Automated Flags</Typography>
      <Typography variant="subtitle2">
        Last analyzed{" "}
        {relativeTime(new Date(Number(firstFlag.lastUpdatedSec) * 1000))}
      </Typography>
      <FlagsSection flags={flags} />
    </Box>
  );
};
