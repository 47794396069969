import { FetchUrlMetadataResponse } from "protogen/url_service_pb";
import AuthService from "./auth";

// We use this without a hook, awkward name, but want to differentiate from "use".
const fetchFetchUrlMetadata = async (
  url: string,
): Promise<FetchUrlMetadataResponse> => {
  const resp = await AuthService.protoMethod("FetchUrlMetadata", { url });
  return new FetchUrlMetadataResponse(resp.data);
};

export { fetchFetchUrlMetadata };
