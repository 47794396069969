import { ReactNode, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Family } from "protogen/advisors_service_pb";
import { useListFamilyAnnotations } from "../../services/engagement";
import Loading from "../common/Loading";
import { MessageSquare } from "lucide-react";
import { useListInvoices } from "../../services/billing";
import { ListInvoicesRequest } from "../../protogen/billing_service_pb";
import LinkRouter from "../navigation/LinkRouter";
import { Advisor } from "protogen/common_pb";
import AnnotationCard from "components/engagement/notes/AnnotationCard";
import InvoiceCard from "components/engagement/notes/InvoiceCard";
import AutomatedFlagsCard from "components/engagement/notes/AutomatedFlagsCard";
import AddAnnotation from "components/engagement/notes/AddAnnotation";
import ChangeEventCard from "components/engagement/notes/ChangeEventCard";

const TYPE_OPTIONS = [
  "note",
  "billing",
  "client-feedback",
  "10-day-checkin",
  "30-day-checkin",
  "50-day-checkin",
];

interface Props {
  title: string;
  family: Family;
  primaryAdvisor: Advisor;
}
export default ({ family, primaryAdvisor }: Props) => {
  const {
    request: listInvoices,
    loading: invoicesLoading,
    data: invoicesResponse,
  } = useListInvoices();
  const {
    request: listAnnotations,
    data: annotationsResponse,
    loading: annotationsLoading,
  } = useListFamilyAnnotations();

  useEffect(() => {
    listAnnotations({ familyRef: family.ref });
    listInvoices(
      new ListInvoicesRequest({
        familyRef: family.ref,
      }),
    );
  }, [family.ref]);
  const loading =
    (invoicesLoading || annotationsLoading) &&
    !(annotationsResponse && invoicesResponse);
  // Merge results
  const feedEntries: [number, ReactNode][] = [
    ...(annotationsResponse?.annotations || []).map(
      (annotation): [number, ReactNode] => [
        Number(annotation.lastUpdatedSec),
        <AnnotationCard
          key={`annotation-${annotation.ref}`}
          annotation={annotation}
        />,
      ],
    ),
    ...(invoicesResponse?.invoices || []).map(
      (invoice): [number, ReactNode] => [
        Number(invoice.createdSec),
        <InvoiceCard key={`invoice-${invoice.ref}`} invoice={invoice} />,
      ],
    ),
    ...(annotationsResponse?.changeEvents || []).map(
      (annotation): [number, ReactNode] => [
        Number(annotation.lastUpdatedSec),
        <ChangeEventCard
          key={`annotation-${annotation.ref}`}
          annotation={annotation}
        />,
      ],
    ),
  ];
  if (
    annotationsResponse?.automatedFlags &&
    annotationsResponse?.automatedFlags.length > 0
  ) {
    feedEntries.push([
      Number(annotationsResponse?.automatedFlags[0].lastUpdatedSec),
      <AutomatedFlagsCard
        key={"automatedFlags"}
        flags={annotationsResponse.automatedFlags}
      />,
    ]);
  }
  feedEntries.sort((a, b) => b[0] - a[0]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"16px"}
        width={"100%"}
      >
        <AddAnnotation
          familyRef={family.ref}
          signupSessionRef={""}
          onUpdate={async () => {
            await listAnnotations({ familyRef: family.ref });
          }}
          noteTypes={TYPE_OPTIONS}
          includeFlags={true}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"16px"}
        width={"100%"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"16px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Activity</Typography>
          <Typography variant="bodySmall" color="textSecondary">
            <LinkRouter
              sx={{ alignItems: "center" }}
              targetNew={true}
              to={`/inbox/messages?recipientRefs=${encodeURIComponent(
                primaryAdvisor.ref,
              )}`}
            >
              <MessageSquare size={12} style={{ marginRight: 4 }} />
              Message {primaryAdvisor.firstName}
            </LinkRouter>
          </Typography>
        </Box>
        {loading && <Loading />}
        {feedEntries.map((entry) => entry[1])}
      </Box>
    </Box>
  );
};
