import { useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import {
  ChevronDown,
  ChevronUp,
  ExternalLink,
  MessageSquare,
} from "lucide-react";
import useIsMobile from "components/hooks/useIsMobile";
import { EngagementReport } from "protogen/engagement_service_pb";
import LinkRouter from "components/navigation/LinkRouter";
import { daysBetween, pluralize } from "../../common/utils";
import { WrappedRing } from "components/time-utilization/TimeUtilization";
import { FamilyStatus } from "protogen/advisors_service_pb";
import VibeCheckSection, { FlagsSummary } from "./FlagsSection";
import {
  formatProtoTime,
  reportToRowColor,
  stateToTextTreatment,
  lastContactToState,
  utilizationToState,
} from "./utils";

type FamilyReportRowProps = {
  report: EngagementReport;
};

const AdminReportRow = ({ report }: FamilyReportRowProps) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const percentUtilization = (
    (report.planUtilization?.periodHoursUtilized /
      report.planUtilization?.planHoursAllocated) *
    100
  ).toFixed(0);
  const daysUntilRenewal = daysBetween(report.planUtilization?.periodEndDate);
  let renewalDate = new Date(report.planUtilization.periodEndDate);
  renewalDate.setDate(renewalDate.getDate() + 1);
  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "1px solid #D4D4D4",
        background: "#FFF",
        overflow: "hidden",
      }}
    >
      <Box position="relative">
        <div
          style={{
            width: "6px",
            height: "100%",
            position: "absolute",
            background: reportToRowColor(report),
          }}
        ></div>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            padding: "27px 24px",
            alignItems: "center",
            cursor: !open ? "pointer" : undefined,
          }}
          onClick={() => !open && setOpen(!open)}
        >
          <Box flex={1.5}>
            <LinkRouter
              targetNew={true}
              to={`/families/${encodeURIComponent(report.family.ref)}#tab-5`}
              sx={{ alignItems: "center" }}
            >
              <Typography variant="bodyHeavy">{report.family.name}</Typography>
              <ExternalLink size={12} style={{ marginLeft: 6 }} />
            </LinkRouter>
            <Typography variant="bodySmall" color="textSecondary">
              <LinkRouter
                sx={{ alignItems: "center" }}
                targetNew={true}
                to={`/inbox/messages?recipientRefs=${encodeURIComponent(
                  report.advisor.ref,
                )}`}
              >
                {report.advisor.displayName}
                <MessageSquare size={12} style={{ marginLeft: 4 }} />
              </LinkRouter>
            </Typography>
          </Box>
          <Box
            flex={1.5}
            sx={{
              ...(report.family?.status === FamilyStatus.ACTIVE
                ? stateToTextTreatment(lastContactToState(report))
                : {}),
            }}
          >
            {formatProtoTime(report.stats?.activityLatestCommunicationSec)}
          </Box>
          <Box
            flex={1}
            display={isMobile ? "none" : undefined}
            sx={{
              ...(report.family?.status === FamilyStatus.ACTIVE
                ? stateToTextTreatment(utilizationToState(report))
                : {}),
            }}
          >{`${percentUtilization}%`}</Box>
          <Box flex={1} display={isMobile ? "none" : undefined}>
            {daysUntilRenewal} {pluralize("day", daysUntilRenewal)}
          </Box>
          <Box flex={1} display={isMobile ? "none" : undefined}>
            {report.family.startDate
              ? new Date(report.family.startDate).toLocaleDateString("en-US", {
                  month: "short",
                  year: "2-digit",
                  day: "numeric",
                })
              : ""}
          </Box>
          <Box flex={1.5} display={isMobile ? "none" : undefined}>
            <FlagsSummary flags={report.flags} />
          </Box>
          <Box>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            height: "1px",
            background: "#E2E3E4",
          }}
        />
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Box sx={{ padding: "24px 32px" }} flex={2}>
              <Typography variant="h3" marginBottom="10px">
                Tasks
              </Typography>
              <Box display="flex" flexDirection="row" gap="15px">
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmallHeavy">Open tasks</Typography>
                  <Typography variant="bodySmallHeavy">
                    Completed tasks
                  </Typography>
                  <Typography variant="bodySmallHeavy">
                    Latest Activity
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmall">
                    {report.stats?.tasksOpen}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.tasksCompleted}
                  </Typography>
                  <Typography variant="bodySmall">
                    {formatProtoTime(report.stats?.tasksLatestActivitySec)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <div
              style={{
                width: "1px",
                background: "#E2E3E4",
              }}
            />
            <Box sx={{ padding: "24px 32px" }} flex={2}>
              <Typography variant="h3" marginBottom="10px">
                Communications
              </Typography>
              <Box display="flex" flexDirection="row" gap="15px">
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmallHeavy">
                    Last activity
                  </Typography>
                  <Typography variant="bodySmallHeavy">
                    Unread Emails
                  </Typography>
                  <Typography variant="bodySmallHeavy">
                    Unread Messages
                  </Typography>
                  <Typography variant="bodySmallHeavy">Unread Tasks</Typography>
                  <Typography variant="bodySmallHeavy">
                    7 Day activity
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmall">
                    {new Date(
                      Number(report.stats?.activityLatestCommunicationSec) *
                        1000,
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activityUnreadEmailCount}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activityUnreadMessageCount}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activityTaskMessageCount}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activitySevenDayCount}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <div
              style={{
                width: "1px",
                background: "#E2E3E4",
              }}
            />
            <Box sx={{ padding: "24px 32px" }} flex={3}>
              <Typography variant="h3" marginBottom="10px">
                Plan
              </Typography>
              <Box display="flex" flexDirection="row" gap="15px">
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmallHeavy">Monthly rate</Typography>
                  <Typography variant="bodySmallHeavy">Renews</Typography>
                  <Typography variant="bodySmallHeavy">Avg. Hours</Typography>
                  <Typography variant="bodySmallHeavy">Member since</Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmall">
                    {report.family?.billingInfo?.product?.amountCents ? (
                      <>
                        $
                        {(
                          report.family?.billingInfo?.product?.amountCents / 100
                        ).toFixed(0)}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                  <Typography variant="bodySmall">
                    {/* format date as "Aug 13" */}
                    {renewalDate.toLocaleDateString("en-US", {
                      timeZone: "UTC",
                      month: "short",
                      day: "numeric",
                    })}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.averageMonthlyHours.toFixed(1)}
                  </Typography>
                  <Typography variant="bodySmall">
                    {new Date(report.family.startDate).toLocaleDateString(
                      "en-US",
                      { month: "short", year: "numeric" },
                    )}
                  </Typography>
                </Box>
                <WrappedRing planUtilization={report.planUtilization} />
              </Box>
            </Box>
          </Box>
          <div
            style={{
              height: "1px",
              background: "#E2E3E4",
            }}
          />
          <VibeCheckSection flags={report.flags} />
        </Box>
      </Collapse>
    </Box>
  );
};

export { AdminReportRow };
