import NavigationLayout from "./NavigationLayout";
import MemberDrawerContent from "./MemberDrawerContent";
import { AccountType } from "protogen/common_pb";
import { SnackpackContextProvider } from "../context/SnackpackContextProvider";
export default () => {
  return (
    <NavigationLayout
      drawerContent={MemberDrawerContent}
      loginRedirectPath="/member-login"
      tosAccountType={AccountType.MEMBER}
      authedContexts={[SnackpackContextProvider]}
    />
  );
};
