import { Badge, IconButton, Menu, MenuItem } from "@mui/material";
import { Flag } from "lucide-react";
import { useState } from "react";
import { FLAGS } from "../flags";
import { getColor } from "../utils";

export default ({
  flags,
  setFlags,
}: {
  flags: string[];
  setFlags: (f: string[]) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (text: string) => {
    if (flags.includes(text)) {
      setFlags(flags.filter((f) => f !== text));
    } else {
      setFlags([...flags, text]);
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        size="small"
        sx={{ marginRight: "4px", fontSize: "16px", alignItems: "center" }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Badge
          badgeContent={flags.length}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              right: 0,
              top: 15,
              padding: "0",
              fontSize: "10px",
              minWidth: "15px",
              height: "15px",
            },
          }}
        >
          <Flag size={16} />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {FLAGS.map((flag) => (
          <MenuItem
            key={flag.key}
            onClick={() => handleMenuItemClick(flag.key)}
            sx={{
              display: "flex",
              gap: "8px",
              color: flags.includes(flag.key)
                ? getColor(flag.activeStatus)
                : undefined,
            }}
          >
            {flag.icon && (
              <flag.icon
                size={16}
                color={
                  flags.includes(flag.key)
                    ? getColor(flag.activeStatus)
                    : undefined
                }
              />
            )}
            {flag.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
