import { AccountStub } from "../../protogen/common_pb";
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import { Box, ButtonGroup, Button } from "@mui/material";
import CheckedDropDown from "../common/CheckedDropDown";
import { EngagementReport } from "../../protogen/engagement_service_pb";
import { FLAGS } from "./flags";

const flagOptions = () => {
  return [
    {
      label: "Any flags",
      value: "_any-flags",
      mutuallyExclusive: true,
    },
    ...FLAGS.map((flag) => ({
      label: flag.title,
      value: flag.key,
      mutuallyExclusive: true,
    })),
  ];
};

const advisorOptions = (advisors: AccountStub[]) => {
  const options = advisors
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((advisor: AccountStub) => ({
      label: advisor.displayName,
      value: advisor.ref,
      mutuallyExclusive: true,
    }));
  options.unshift({
    label: "Any advisor",
    value: "",
    mutuallyExclusive: true,
  });
  return options;
};

type PersistedFilters = {
  advisor: string;
  setAdvisor: (s: string) => void;
  flags: string[];
  setFlags: (s: string[]) => void;
  query: string;
  setQuery: (s: string) => void;
  checkins: string;
  setCheckins: (s: string) => void;
};
export const usePersistedFilters = ({}: {}): PersistedFilters => {
  const initialParams = new URLSearchParams(window.location.search);
  const [, setSearchParams] = useSearchParams();
  const [advisor, setAdvisor] = useState<string>(
    initialParams.get("advisor") || "",
  );
  const [flags, setFlags] = useState<string[]>(
    (initialParams.get("flags") || "").split(",").filter((s) => s),
  );
  const [checkins, setCheckins] = useState<string>(
    initialParams.get("checkins") || "_all-reports",
  );
  const [query, setQuery] = useState<string>(initialParams.get("query") || "");
  useEffect(() => {
    setSearchParams({
      ...(advisor ? { advisor } : {}),
      ...(query ? { query } : {}),
      ...(checkins !== "_all-reports" ? { checkins } : {}),
    });
  }, [advisor, query, checkins]);
  return {
    advisor,
    setAdvisor,
    flags,
    setFlags,
    query,
    setQuery,
    checkins,
    setCheckins,
  };
};

export default ({
  reports,
  advisors,
  onFiltered,
  filters,
  sx,
}: {
  reports: EngagementReport[];
  advisors: AccountStub[];
  onFiltered: (f: EngagementReport[]) => void;
  filters: PersistedFilters;
  sx?: React.CSSProperties;
}) => {
  const isMobile = useIsMobile();
  const runFilters = () => {
    if (!reports.length) return;
    const cleanQuery = filters.query.toLowerCase().trim();
    const filteredReports = reports
      .filter((report) =>
        filters.advisor ? report.advisor?.ref === filters.advisor : true,
      )
      .filter((report) =>
        filters.flags.length
          ? filters.flags.includes("_any-flags") ||
            report.flags
              .filter((f) => f.active)
              .map((f) => f.name)
              .some((f) => filters.flags.indexOf(f) > -1)
          : true,
      )
      .filter((report) =>
        filters.checkins
          ? filters.checkins === "_all-reports" ||
            (filters.checkins === "10-day-overdue" &&
              report.needsDay10Checkin) ||
            (filters.checkins === "30-day-overdue" &&
              report.needsDay30Checkin) ||
            (filters.checkins === "50-day-overdue" && report.needsDay50Checkin)
          : true,
      )
      .filter((report) =>
        cleanQuery
          ? report.family?.name.toLowerCase().includes(cleanQuery) ||
            report.advisor?.displayName.toLowerCase().includes(cleanQuery)
          : true,
      );
    onFiltered(filteredReports);
  };
  useEffect(() => {
    runFilters();
  }, [
    reports,
    filters.advisor,
    filters.query,
    filters.flags,
    filters.checkins,
  ]);
  return (
    <Box
      display={"flex"}
      flexDirection={isMobile ? "column" : "row"}
      sx={{ overflowX: "auto", flexShrink: 0, ...sx }}
      justifyContent={"space-between"}
      width={"100%"}
      gap="12px"
    >
      <ButtonGroup
        sx={{ width: isMobile ? "100%" : "fit-content", minWidth: "120px" }}
      >
        <Button
          variant={
            filters.checkins === "_all-reports"
              ? "buttonGroupSelected"
              : "buttonGroup"
          }
          onClick={() => filters.setCheckins("_all-reports")}
        >
          All
        </Button>
        <Button
          variant={
            filters.checkins === "10-day-overdue"
              ? "buttonGroupSelected"
              : "buttonGroup"
          }
          onClick={() => filters.setCheckins("10-day-overdue")}
        >
          {!isMobile ? "Needs " : ""}10D
        </Button>
        <Button
          variant={
            filters.checkins === "30-day-overdue"
              ? "buttonGroupSelected"
              : "buttonGroup"
          }
          onClick={() => filters.setCheckins("30-day-overdue")}
        >
          {!isMobile ? "Needs " : ""}30D
        </Button>
        <Button
          variant={
            filters.checkins === "50-day-overdue"
              ? "buttonGroupSelected"
              : "buttonGroup"
          }
          onClick={() => filters.setCheckins("50-day-overdue")}
        >
          {!isMobile ? "Needs " : ""}50D
        </Button>
      </ButtonGroup>
      <Box
        display={"flex"}
        flexDirection="row"
        justifyContent="end"
        gap="10px"
        mb={isMobile ? "20px" : undefined}
      >
        <CheckedDropDown
          disabled={false}
          radio={true}
          initial={filters.flags}
          options={flagOptions()}
          sx={{ width: isMobile ? "33%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (filters.flags &&
              filters.flags
                .map((f) => flagOptions().find((s) => s.value === f)?.label)
                .join(", ")) ||
            "Flags"
          }
          onChange={(f) =>
            f.length === 1 && filters.setFlags(f.map((f_) => f_.value))
          }
        />
        <CheckedDropDown
          disabled={false}
          radio={true}
          initial={[filters.advisor]}
          options={advisorOptions(advisors)}
          sx={{ width: isMobile ? "33%" : "fit-content", minWidth: "120px" }}
          maxDropdownHeight={"343px"}
          displayValue={
            (filters.advisor &&
              advisors.find((a) => a.ref === filters.advisor)?.displayName) ||
            "Advisor"
          }
          onChange={(f) => f.length === 1 && filters.setAdvisor(f[0].value)}
        />
      </Box>
    </Box>
  );
};
