// Inspired/plagiarized from
// https://github.com/ueberdosis/tiptap/issues/333#issuecomment-1056434177

import { Tooltip, Typography, Box, IconButton } from "@mui/material";
import { Download, Info, LucideIcon, Paperclip, ZoomIn } from "lucide-react";
import {
  NodeViewWrapper,
  type NodeViewProps,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import {
  type CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import TipTapImage from "@tiptap/extension-image";
import EmbeddedImage from "./extensions/EmbeddedImage";
import useIsMobile from "components/hooks/useIsMobile";

const useEvent = <T extends (...args: any[]) => any>(handler: T): T => {
  const handlerRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    if (handlerRef.current === null) {
      throw new Error("Handler is not assigned");
    }
    return handlerRef.current(...args);
  }, []) as T;
};

const MIN_WIDTH = 60;
const BORDER_COLOR = "#0096fd";

const Action = ({
  Icon,
  tooltip,
  hidden = false,
  onClick,
}: {
  Icon: LucideIcon;
  tooltip: string | ReactNode;
  hidden?: boolean;
  onClick?: () => void;
}) => {
  if (hidden) return <div />;
  return (
    <Tooltip
      title={
        typeof tooltip === "string" ? (
          <Box>
            <Typography variant="bodySmall">{tooltip}</Typography>
          </Box>
        ) : (
          tooltip
        )
      }
      placement={"bottom-start"}
    >
      <IconButton
        sx={{
          borderRadius: "100px",
          backgroundColor: "white",
          padding: "4px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#EAEBEC",
          },
        }}
        onClick={onClick}
      >
        <Icon size={16} strokeWidth={2} color="#6B6E7B" />
      </IconButton>
    </Tooltip>
  );
};

const ResizableImageTemplate = ({
  node,
  updateAttributes,
  view,
}: NodeViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const isMobile = useIsMobile();
  const [selected, setSelected] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [resizingStyle, setResizingStyle] = useState<
    Pick<CSSProperties, "width"> | undefined
  >();
  const isResized = node.attrs.resized === "true";
  const isEditable = view.editable;

  const handleDownload = useCallback(() => {
    window.open(node.attrs.src, "_blank");
  }, [node.attrs.src]);

  // Lots of work to handle "not" div click events.
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setSelected(false);
      }
    };
    // Add click event listener and remove on cleanup
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selected]);

  const handleMouseDown = useEvent(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!imgRef.current) return;
      event.preventDefault();
      const direction = event.currentTarget.dataset.direction || "--";
      const initialXPosition = event.clientX;
      const currentWidth = imgRef.current.width;
      let newWidth = currentWidth;
      const transform = direction[1] === "w" ? -1 : 1;

      const removeListeners = () => {
        window.removeEventListener("mousemove", mouseMoveHandler);
        window.removeEventListener("mouseup", removeListeners);
        updateAttributes({ width: newWidth });
        setResizingStyle(undefined);
      };

      const mouseMoveHandler = (event: MouseEvent) => {
        newWidth = Math.max(
          currentWidth + transform * (event.clientX - initialXPosition),
          MIN_WIDTH,
        );
        setResizingStyle({ width: newWidth });
        // If mouse is up, remove event listeners
        if (!event.buttons) removeListeners();
      };

      window.addEventListener("mousemove", mouseMoveHandler);
      window.addEventListener("mouseup", removeListeners);
    },
  );

  const dragCornerButton = (direction: string) => (
    <div
      role="button"
      tabIndex={0}
      onMouseDown={handleMouseDown}
      data-direction={direction}
      style={{
        position: "absolute",
        height: "10px",
        width: "10px",
        backgroundColor: BORDER_COLOR,
        ...{ n: { top: 0 }, s: { bottom: 0 } }[direction[0]],
        ...{ w: { left: 0 }, e: { right: 0 } }[direction[1]],
        cursor: `${direction}-resize`,
      }}
    ></div>
  );
  return (
    <NodeViewWrapper
      ref={containerRef}
      as="div"
      draggable
      data-drag-handle
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        setSelected(true);
        // 'detail' is the number of clicks
        if (e.detail === 2) {
          setIsFullScreen(true);
        }
      }}
      onBlur={() => setSelected(false)}
      style={{
        overflow: "hidden",
        position: "relative",
        display: "inline-block",
        // Weird! Basically tiptap/prose wraps this in a span and the line height causes an annoying buffer.
        lineHeight: "0px",
        cursor: "pointer",
      }}
    >
      <img
        {...node.attrs}
        ref={imgRef}
        style={{
          ...resizingStyle,
          cursor: "default",
        }}
      />
      {selected && isEditable && (
        <>
          {/* Don't use a simple border as it pushes other content around. */}
          {/* {[
            { left: 0, top: 0, height: "100%", width: "1px" },
            { right: 0, top: 0, height: "100%", width: "1px" },
            { top: 0, left: 0, width: "100%", height: "1px" },
            { bottom: 0, left: 0, width: "100%", height: "1px" },
          ].map((style, i) => (
            <div
              key={i}
              style={{
                position: "absolute",
                backgroundColor: BORDER_COLOR,
                ...style,
              }}
            ></div>
          ))} */}
          {dragCornerButton("nw")}
          {dragCornerButton("ne")}
          {dragCornerButton("sw")}
          {dragCornerButton("se")}
        </>
      )}
      {selected && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "calc(100% - 32px)",
            margin: "16px",
            display: "flex",
            justifyContent: "space-between",
            zIndex: 100,
          }}
        >
          <Action
            hidden={!isResized || !isEditable}
            Icon={Info}
            tooltip={
              <Box>
                <Typography variant="bodySmall">
                  Your original image has been resized.
                </Typography>
                <Typography variant="bodySmall">
                  If you want to include a full sized version, please include as
                  a file attachment.{" "}
                  <Paperclip size={14} color="#6B6E7B"></Paperclip>
                </Typography>
              </Box>
            }
          />
          <div
            style={{
              display: "flex",
              gap: "8px",
            }}
          >
            {/* <Action Icon={Info} tooltip="Delete" /> */}
            <Action
              Icon={ZoomIn}
              tooltip="Expand"
              onClick={() => setIsFullScreen(true)}
            />
            <Action
              Icon={Download}
              tooltip="Download"
              onClick={handleDownload}
            />
          </div>
        </div>
      )}
      {selected && (
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            inset: 0,
            background: "rgba(35, 131, 226, 0.14)",
            zIndex: 81,
            borderRadius: "4px",
            opacity: 1,
            transitionDuration: "200ms",
            transitionTimingFunction: "ease",
            transitionProperty: "opacity",
          }}
        ></div>
      )}
      {isFullScreen && (
        <EmbeddedImage
          isMobile={isMobile}
          imageProps={{
            src: node.attrs.src,
            alt: node.attrs.alt,
          }}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        />
      )}
    </NodeViewWrapper>
  );
};

const ResizableImageExtension = TipTapImage.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      width: { renderHTML: ({ width }) => ({ width }) },
      height: { renderHTML: ({ height }) => ({ height }) },
      resized: {
        default: null,
        parseHTML: (element) => element.getAttribute("resized"),
        renderHTML: (attributes) => {
          if (!attributes.resized) {
            return {};
          }
          return {
            resized: attributes.resized,
          };
        },
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(ResizableImageTemplate);
  },
}).configure({ inline: true });

export default ResizableImageExtension;
