import React from "react";
import { Box, Typography } from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";
export const StepHeader: React.FC<{
  title?: string;
  subtitle?: string | React.ReactNode;
  alignment?: "left" | "center";
  tooltip?: React.ReactNode;
}> = ({ title, subtitle, alignment = "center", tooltip }) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        gap: isMobile ? "24px" : "48px",
        marginBottom: !subtitle ? "0px" : isMobile ? "16px" : "32px",
      }}
    >
      {title && (
        <Typography
          variant={isMobile ? "h2" : "h1"}
          align={alignment}
          gutterBottom
          sx={{
            fontSize: isMobile ? "24px" : "auto",
            marginTop: isMobile ? "0px" : "32px",
          }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="body"
          align={alignment}
          sx={{
            display: "flex",
            fontSize: "17px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            {subtitle}
          </Box>
          {tooltip}
        </Typography>
      )}
    </Box>
  );
};
