import DropdownMenu from "components/common/DropdownMenu";
import { Family } from "../types";
import DeactivationDialog from "./DeactivationDialog";
import ChangePlanDialog from "./ChangePlanDialog";
import { useState } from "react";

export default ({ familyData }: { familyData: Family }) => {
  const [deactivationDialogOpen, setDeactivationDialogOpen] = useState(false);
  const [changePlanDialogOpen, setChangePlanDialogOpen] = useState(false);

  const onUpdate = () => {
    // Refresh the page.
    window.location.reload();
  };

  return (
    <>
      <DropdownMenu
        noIcon={true}
        buttonContent="Actions"
        dropdownIcon={true}
        options={[
          {
            title: "Change plan",
            onClick: () => setChangePlanDialogOpen(true),
            disabled: !!familyData.endDate,
          },
          {
            title: "Deactivate family",
            onClick: () => setDeactivationDialogOpen(true),
            disabled: !!familyData.endDate,
          },
        ]}
      />
      <DeactivationDialog
        familyRef={familyData.ref || ""}
        open={deactivationDialogOpen}
        setOpen={setDeactivationDialogOpen}
        onUpdate={onUpdate}
      />
      <ChangePlanDialog
        familyRef={familyData.ref || ""}
        open={changePlanDialogOpen}
        setOpen={setChangePlanDialogOpen}
        onUpdate={onUpdate}
      />
    </>
  );
};
