import AuthService from "./auth";
import useProtoMethod from "./useProtoMethod";
import {
  CreateDocumentRequest,
  CreateDocumentResponse,
  GetDocumentRequest,
  GetDocumentResponse,
  UpdateDocumentRequest,
  UpdateDocumentResponse,
  ListDocumentsRequest,
  ListDocumentsResponse,
  CopyDocumentRequest,
  CopyDocumentResponse,
} from "protogen/documents_service_pb";

const useCreateDocument = (callback?: (r: CreateDocumentResponse) => void) => {
  return useProtoMethod<CreateDocumentRequest, CreateDocumentResponse>(
    "CreateDocument",
    CreateDocumentResponse,
    { callback },
  );
};

const useGetDocument = (callback?: (r: GetDocumentResponse) => void) => {
  return useProtoMethod<GetDocumentRequest, GetDocumentResponse>(
    "GetDocument",
    GetDocumentResponse,
    { callback },
  );
};

const fetchGetDocument = async (
  documentRef: string,
): Promise<GetDocumentResponse> => {
  const resp = await AuthService.protoMethod("GetDocument", { documentRef });
  return new GetDocumentResponse(resp.data);
};

const useUpdateDocument = (callback?: (r: UpdateDocumentResponse) => void) => {
  return useProtoMethod<UpdateDocumentRequest, UpdateDocumentResponse>(
    "UpdateDocument",
    UpdateDocumentResponse,
    { callback },
  );
};

const useListDocuments = (callback?: (r: ListDocumentsResponse) => void) => {
  return useProtoMethod<ListDocumentsRequest, ListDocumentsResponse>(
    "ListDocuments",
    ListDocumentsResponse,
    { callback },
  );
};

const useCopyDocument = (callback?: (r: CopyDocumentResponse) => void) => {
  return useProtoMethod<CopyDocumentRequest, CopyDocumentResponse>(
    "CopyDocument",
    CopyDocumentResponse,
    { callback },
  );
};
export {
  useCreateDocument,
  useGetDocument,
  fetchGetDocument,
  useUpdateDocument,
  useListDocuments,
  useCopyDocument,
};
