import React, { MouseEventHandler } from "react";
import { Button } from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";

export const NextStepButton: React.FC<{
  skippable?: boolean;
  hasValue?: boolean;
  disabled?: boolean;
  continueClick?: MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
}> = ({ skippable = false, hasValue, disabled, continueClick, buttonText }) => {
  const isMobile = useIsMobile();
  const skipButton = !hasValue && skippable;

  return (
    <Button
      variant={!skipButton ? "contained" : isMobile ? "text" : "outlined"}
      color="primary"
      disabled={disabled || (!hasValue && !skippable)}
      onClick={continueClick}
      sx={{
        width: isMobile ? "100%" : "145px",
        bgcolor: !skipButton ? "#198282" : "transparent",
        color: !skipButton ? "white" : "#198282",
        "&:hover": {
          bgcolor: !skipButton ? "#276767" : "none",
        },
        "&.Mui-disabled": { backgroundColor: "#EAEBEC" },
        borderRadius: "32px",
        fontSize: "1.15rem",
        px: 4,
        textTransform: "none",
        boxShadow: isMobile ? "0px -1px 20px 5px rgba(255, 255, 255, .2)" : "",
      }}
    >
      {buttonText || (!skipButton || hasValue ? "Continue" : "Skip")}
    </Button>
  );
};
