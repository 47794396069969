import { useEffect, useState, useRef, useContext, useMemo } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import debounce from "lodash/debounce";
import { ArrowLeft, Ellipsis, FileWarning } from "lucide-react";
import {
  Document,
  Permission,
  DocumentType,
  DocumentScope,
  DocumentStatus,
} from "protogen/documents_pb";
import {
  UpdateDocumentRequest,
  CopyDocumentRequest,
} from "protogen/documents_service_pb";
import { ContentUpdates } from "protogen/notes_pb";
import {
  useCopyDocument,
  useGetDocument,
  useUpdateDocument,
} from "services/documents";
import GridPage from "components/layout/GridPage";
import { stripImgSrc, toMaybeNumber } from "common/utils";
import DocumentViewer from "components/editor/DocumentViewer";
import DocumentEditor from "components/editor/DocumentEditor";
import useAutoSave from "components/editor/useAutoSave";
import Loading from "components/common/Loading";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import LinkRouter from "components/navigation/LinkRouter";
import { Attachment } from "protogen/common_pb";
import { Handle } from "components/editor/DocumentEditor";
import DropdownMenu from "components/common/DropdownMenu";
import { getScopeLabel } from "components/documents/utils";
import { CurrentUserContext } from "components/context/RequireAuth";
import { useSnackpack } from "components/context/SnackpackContextProvider";
import { useListAdvisorFamilies } from "services/advisor";
import PopperMenu, {
  createMenuOption,
  SubmenuMenuOption,
} from "components/common/PopperMenu";
import BookmarkToTaskDialog, {
  fromDocument,
} from "components/tasks/BookmarkToTaskDialog";
import { Family } from "protogen/advisors_service_pb";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "components/common/ConfirmationDialog";
import { TaskBlockStatus } from "protogen/tasks_pb";
import { useUpdateTaskBlockStatusRequest } from "services/tasks";
import CheckedDropDown from "components/common/CheckedDropDown";
import AddPostDialog, {
  Props as AddPostDialogProps,
} from "components/forum/AddPostDialog";
import { ForumPostType } from "protogen/forum_service_pb";

const DOCUMENTS_STATIC_CHOICE = "Family documents";

const postPropsFromDocument = (document: Document) => {
  const title = document?.title || "";
  const link = `${window.location.origin}/documents/${document?.uuid}`;
  const shareContent = {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "Checkout my document ",
          },
          {
            type: "text",
            marks: [
              {
                type: "italic",
              },
            ],
            text: title,
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "thumbnailURL",
            attrs: {
              url: link,
              displayMode: 1,
              title: title,
              header: "Document",
              description: `By ${document?.owner?.displayName}`,
              image: "/assets/images/document-placeholder.png",
              loaded: true,
            },
          },
        ],
      },
    ],
  };
  const textContent = `Checkout my document ${title}\n${link}`;
  return {
    initialSubject: document?.title || undefined,
    initialContent: {
      payload: JSON.stringify(shareContent),
      textContent: textContent,
    },
    postType: ForumPostType.POST_DISCUSSION,
    topics: ["Documents"],
  };
};

// A flattend generic initial content.  It could be a note, task block etc.
interface InitialContent {
  content: string;
  attachments: Attachment[];
  contentType?: string;
}

const TaskBlockButton = ({ document }: { document?: Document }) => {
  const isMobile = useIsMobile();
  return (
    <LinkRouter
      to={`/tasks/${document?.ref}`}
      sx={{
        justifyContent: isMobile ? "start" : "end",
        width: isMobile ? "100%" : "65%",
      }}
    >
      <Button
        sx={(theme) => ({
          height: "38px",
          padding: "0 16px !important",
          border: `1px solid ${theme.palette.border}`,
          borderRadius: "12px",
        })}
        size="small"
        variant="text"
      >
        <Typography
          variant="bodyHeavy"
          color="primary"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 600,
          }}
        >
          {document?.subtitle}
        </Typography>
      </Button>
    </LinkRouter>
  );
};

const _Wrapped = ({
  document,
  initialContent,
  error,
}: {
  document: Document | undefined;
  initialContent: InitialContent | undefined;
  error: string | null;
}) => {
  const [title, setTitle] = useState(document?.title);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const editorRef = useRef<Handle>(null);
  const docType = searchParams.get("doc_type");
  const isNew = searchParams.get("created") === "1";
  const isTaskBlock = Number(docType) === DocumentType.TASK_BLOCK;
  const isEdit = document?.permission === Permission.WRITE && !isTaskBlock;
  const documentInitialContent =
    initialContent?.contentType === "html"
      ? initialContent.content
      : JSON.parse(initialContent?.content || "");

  const { request } = useUpdateDocument();

  const saveTitle = useMemo(
    () =>
      debounce(
        (title: string) => {
          request(
            new UpdateDocumentRequest({
              documentRef: document?.ref || "",
              title: title || "",
              shouldUpdateTitle: true,
            }),
          );
        },
        1500,
        { trailing: true },
      ),
    [document?.ref],
  );

  useEffect(() => {
    return () => {
      saveTitle.cancel();
    };
  }, [saveTitle]);

  const { setEditorContent } = useAutoSave(
    initialContent?.content || null,
    toMaybeNumber(Number(document?.lastUpdatedSec)),
    async (c) => {
      await request(
        new UpdateDocumentRequest({
          documentRef: document?.ref || "",
          content: new ContentUpdates({
            contentType: "html",
            payload: stripImgSrc(c.html),
            textContent: c.text,
            attachments: c.attachments,
          }),
          shouldUpdateContent: true,
        }),
      );
    },
    !!error,
    1500,
    initialContent?.contentType,
  );

  useEffect(() => {
    if (inputRef.current && isNew) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length); // Move cursor to the end
    }
  }, []);

  const isMobile = useIsMobile();

  return (
    <Box sx={{ marginTop: "8px" }}>
      <Box>
        {isEdit ? (
          <TextField
            inputRef={inputRef}
            variant="standard"
            fullWidth
            multiline
            placeholder="New document"
            value={title}
            autoFocus={isNew}
            onChange={(e) => {
              const title = e.target.value;
              setTitle(title);
              saveTitle(title);
            }}
            margin="none"
            InputProps={{
              disableUnderline: true,
              sx: (theme) => ({
                ...theme.typography.h3,
              }),
            }}
          />
        ) : (
          <Typography
            variant="h3"
            color="text.primary"
            sx={{ marginBottom: isMobile ? "8px" : "12px" }}
          >
            {title}
          </Typography>
        )}
      </Box>

      <Box>
        {isEdit ? (
          <DocumentEditor
            setContent={setEditorContent}
            passiveEditor={false}
            placeholder={"Write something..."}
            initialContent={documentInitialContent}
            attachmentsEnabled={true}
            initialAttachments={initialContent?.attachments}
            editorDefaultFontSize={"15px"}
            placement={"top"}
            scrollable={true}
            ref={editorRef}
            editorMinHeight={
              isMobile ? "calc(100vh - 290px)" : "calc(100vh - 220px)"
            }
            linkUnfurlEnabled={true}
            editorMaxHeight={
              isMobile ? "calc(100vh - 290px)" : "calc(100vh - 220px)"
            }
          />
        ) : (
          <DocumentViewer
            content={documentInitialContent}
            attachments={initialContent?.attachments}
            minHeight="29px"
            sx={{ padding: "0" }}
          />
        )}
      </Box>
    </Box>
  );
};

export default () => {
  const defaultOptions = [
    createMenuOption("My documents", () => onCopyDocumentHandler(document), ""),
  ];
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [copyPopperOpen, setCopyPopperOpen] = useState(false);
  const [copyToOptions, setCopyToOptions] = useState(defaultOptions);
  const [copyToFamily, setCopyToFamily] = useState<Family | undefined>(
    undefined,
  );
  const currentUser = useContext(CurrentUserContext);
  const docType = searchParams.get("doc_type");
  const isMobile = useIsMobile();
  const [document, setDocument] = useState<Document>();
  const { pushSnack } = useSnackpack();
  const [initialContent, setInitialContent] = useState<InitialContent>();
  const [shareDialogOpen, setShareDialogOpen] =
    useState<Partial<AddPostDialogProps> | null>(null);
  const { request: familiesRequest } = useListAdvisorFamilies();
  const { request: copyDocumentRequest } = useCopyDocument();
  const { request: updateBlockStatus } = useUpdateTaskBlockStatusRequest();
  const confirmState = useConfirmationDialog();
  const { request, loading, error, errorCode } = useGetDocument((r) => {
    if (Number(docType) === DocumentType.TASK_BLOCK) {
      setInitialContent({
        content: r?.document?.taskBlock?.content || "",
        attachments: r?.document?.taskBlock?.attachments || [],
        contentType: r?.document?.taskBlock?.contentType,
      });
    } else {
      setInitialContent({
        content: r?.document?.note?.content?.payload || "",
        attachments: r?.document?.note?.content?.attachments || [],
        contentType: r?.document?.note?.content?.contentType,
      });
    }
    setDocument(r.document);
  });
  const { request: updateDocumentRequest } = useUpdateDocument((r) => {
    setDocument({ ...r.document, family: document?.family });
  });
  const breadcrumbs = [{ name: "Documents", back: true }];

  useEffect(() => {
    if (!params.documentRef) return;
    request({ documentRef: params.documentRef, documentType: docType });
  }, [params]);

  const isTaskBlock = Number(docType) === DocumentType.TASK_BLOCK;

  const onScopeChange = (scope: DocumentScope) => {
    updateDocumentRequest(
      new UpdateDocumentRequest({
        documentRef: document?.ref || "",
        scope: scope,
      }),
    );
  };

  const onLoad = async () => {
    const familiesData = await familiesRequest();
    const loadedFamilies = familiesData?.families || [];
    if (loadedFamilies.length > 0) {
      setCopyToOptions([
        createMenuOption(
          "My documents",
          () => {
            if (document) {
              onCopyDocumentHandler(document);
            }
          },
          "",
        ),
        ...(document?.documentType === DocumentType.NOTE &&
        document?.inboxType === "family"
          ? [
              createMenuOption(
                `${document?.family?.name} task`,
                () => {
                  const family = loadedFamilies.find(
                    (f) => f.ref === document?.family?.ref,
                  );
                  setCopyToFamily(family);
                },
                "",
              ),
            ]
          : []),
        <SubmenuMenuOption
          key="families"
          title="Families"
          onClick={(value) => {
            setCopyPopperOpen(false);
            const family = loadedFamilies.find((f) => f.ref === value);
            setCopyToFamily(family);
          }}
          submenuOptions={loadedFamilies.map((f) => ({
            label: f.name,
            value: f.ref,
          }))}
        />,
      ]);
    }
  };

  useEffect(() => {
    if (document) {
      onLoad();
    }
  }, [document]);

  const copyLinkHandler = () => {
    if (!document?.uuid) return;
    pushSnack({
      message: "Document link copied.",
      alertSeverity: "success",
      key: `document-${document?.ref}`,
    });
    navigator.clipboard.writeText(
      `${window.location.origin}/documents/${document?.uuid}`,
    );
  };

  const copyDocument = async (
    document: Document,
    toDocumentType: DocumentType,
    toEntityType: string,
    toEntityRef: string,
  ) => {
    const result = await copyDocumentRequest(
      new CopyDocumentRequest({
        fromDocumentType: document?.documentType,
        fromEntityType:
          document?.documentType === DocumentType.TASK_BLOCK
            ? "task-block"
            : document?.documentType === DocumentType.NOTE &&
                document?.inboxType === "family"
              ? "family"
              : "advisor",
        fromEntityRef:
          document?.documentType === DocumentType.TASK_BLOCK
            ? document?.taskBlock?.ref
            : document?.ref,
        toDocumentType: toDocumentType,
        toEntityType: toEntityType,
        toEntityRef: toEntityRef,
        creationSourceName:
          toDocumentType === DocumentType.NOTE ? "note" : "task-block",
        title: document?.title,
        replaceTitle: true,
      }),
    );
    return result;
  };

  const onCopyDocumentHandler = async (doc: Document | undefined) => {
    const result = await copyDocument(
      doc!,
      DocumentType.NOTE,
      "advisor",
      currentUser.ref,
    );
    setCopyPopperOpen(false);
    navigate(
      `/documents/${encodeURIComponent(result?.document?.ref || "")}?created=1`,
    );
  };

  const onBookmarkHandler = async (tasRefs: string[]) => {
    if (tasRefs.length < 1) return;
    const taskRef = tasRefs[0];
    const documentType =
      taskRef === DOCUMENTS_STATIC_CHOICE
        ? DocumentType.NOTE
        : DocumentType.TASK_BLOCK;
    const entityType =
      taskRef === DOCUMENTS_STATIC_CHOICE ? "family" : "task-block";
    const entityRef =
      taskRef === DOCUMENTS_STATIC_CHOICE ? copyToFamily?.ref || "" : taskRef;
    const result = await copyDocument(
      document!,
      documentType,
      entityType,
      entityRef,
    );
    setCopyToFamily(undefined);
    if (documentType === DocumentType.NOTE) {
      navigate(
        `/documents/${encodeURIComponent(
          result?.document?.ref || "",
        )}?created=1`,
      );
    } else {
      navigate(
        `/tasks/${encodeURIComponent(result?.document?.ref || "")}?created=1`,
      );
    }
  };

  const onDeleteDocument = async () => {
    if (!document) return;
    if (document.documentType === DocumentType.NOTE) {
      confirmState.openDialog(async () => {
        await updateDocumentRequest(
          new UpdateDocumentRequest({
            documentRef: document.ref,
            status: DocumentStatus.DELETED,
          }),
        );
        navigate(-1);
      });
    } else if (document.documentType === DocumentType.TASK_BLOCK) {
      confirmState.openDialog(async () => {
        await updateBlockStatus({
          taskBlockRef: document.taskBlock?.ref,
          status: TaskBlockStatus.DELETED,
        });
        navigate(-1);
      });
    }
  };

  const onPrintDocument = () => {
    const printWindow = window.open("", "_blank");
    const content =
      document?.documentType === DocumentType.NOTE
        ? document?.note?.content?.payload
        : document?.taskBlock?.content;
    const title = document?.title || "";
    const attachments =
      document?.documentType === DocumentType.NOTE
        ? document?.note?.content?.attachments
        : document?.taskBlock?.attachments;

    if (printWindow) {
      printWindow.document.write(content || "");
      printWindow.document.close();
      printWindow.focus();
      // Set the document title dynamically
      printWindow.document.title = title || "";
      setTimeout(
        () => {
          printWindow.print();
          printWindow.close();
        },
        1000 + attachments.length * 500,
      ); // Wait for a moment for image attachments to load.
    }
  };
  const additionalOptions = [
    {
      title: "Share in Community",
      disabled: document?.scope === DocumentScope.PRIVATE,
      onClick: () => {
        if (!document) return;
        setShareDialogOpen(postPropsFromDocument(document));
      },
    },
    {
      title: "Print",
      onClick: () => onPrintDocument(),
    },
    {
      title: "Delete",
      onClick: () => onDeleteDocument(),
    },
  ];
  if (
    !isTaskBlock &&
    (document?.scope === DocumentScope.INTERNAL ||
      document?.scope === DocumentScope.INTERNAL_DISCOVERABLE)
  ) {
    additionalOptions.unshift({
      title: "Copy link",
      onClick: () => copyLinkHandler(),
    });
  }
  // We need the document before calling the component.
  // The auto save hook takes a callback that needs the document ref.
  const _content = () => {
    if (!document && errorCode === 403) {
      return (
        <Alert
          severity="warning"
          icon={<FileWarning size={20} />}
          sx={{ borderRadius: "10px" }}
        >
          <AlertTitle>
            Sorry, you do not have permission to view this document
          </AlertTitle>
          If you believe this is a mistake, reach out to the owner and request
          that they update the document's settings.
        </Alert>
      );
    } else if (!document || loading) {
      return <Loading />;
    }
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: isMobile ? "start" : "center",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {/* if there's history, show the back button */}
            {window.history.length > 1 && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowLeft size={20} stroke="#198282" />
                </IconButton>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: isMobile ? "start" : "center",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "flex-end",
            }}
          >
            {!!document.scope && (
              <Box>
                <CheckedDropDown
                  key={document.scope}
                  radio={true}
                  disabled={false}
                  displayValue={getScopeLabel(document.scope)}
                  hasIcon={false}
                  sxSelect={{
                    fieldset: {
                      border: "none !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      paddingRight: "0px",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none !important",
                      },
                    ".MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple": {
                      paddingRight: "0px",
                      paddingLeft: "0px",
                      marginTop: "0px",
                      span: {
                        color: "#198282",
                        fontWeight: 600,
                      },
                    },
                  }}
                  options={[
                    {
                      label: "Private",
                      value: DocumentScope.PRIVATE.toString(),
                      defaultChecked: document?.scope === DocumentScope.PRIVATE,
                      mutuallyExclusive: true,
                    },
                    {
                      label: "Allow Advisors to view with link",
                      value: DocumentScope.INTERNAL.toString(),
                      defaultChecked:
                        document?.scope === DocumentScope.INTERNAL,
                      mutuallyExclusive: true,
                    },
                    {
                      label: "Discoverable by any Advisor",
                      value: DocumentScope.INTERNAL_DISCOVERABLE.toString(),
                      defaultChecked:
                        document?.scope === DocumentScope.INTERNAL_DISCOVERABLE,
                      mutuallyExclusive: true,
                    },
                  ]}
                  onChange={(r) => {
                    const value = parseInt(r[0].value);
                    onScopeChange(value as DocumentScope);
                  }}
                />
              </Box>
            )}

            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Box>
                <Button
                  ref={anchorRef}
                  onClick={() => {
                    setCopyPopperOpen((prev) => !prev);
                  }}
                  sx={(theme) => ({
                    height: "38px",
                    padding: "0 12px !important",
                    border: `1px solid ${theme.palette.border}`,
                    borderRadius: "12px",
                    width: "max-content",
                    minWidth: "90px",
                  })}
                  variant="text"
                >
                  Copy to...
                </Button>
                <PopperMenu
                  placement="bottom-end"
                  open={copyPopperOpen}
                  anchorRef={anchorRef}
                  handleClose={() => {
                    setCopyPopperOpen(false);
                  }}
                >
                  {copyToOptions}
                </PopperMenu>
              </Box>
              {document.family && !isMobile && (
                <LinkRouter to={`/families/${document.family.ref}`}>
                  <Button
                    sx={(theme) => ({
                      height: "38px",
                      padding: "0 16px !important",
                      border: `1px solid ${theme.palette.border}`,
                      width: "max-content",
                      borderRadius: "12px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    })}
                    variant="text"
                  >
                    {document.family.name}
                  </Button>
                </LinkRouter>
              )}

              {isTaskBlock && !isMobile && (
                <TaskBlockButton document={document} />
              )}

              <DropdownMenu
                disabled={false}
                fullWidth
                buttonContent={<Ellipsis size={20} stroke="#198282" />}
                dropdownIcon={false}
                variant="outlined"
                sx={{
                  padding: "0 11px",
                  border: "1px solid #D4D4D4",
                  borderRadius: "12px",
                  height: "38px",
                  minWidth: "20px",
                  "&:hover": {
                    border: "1px solid #D4D4D4",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                options={additionalOptions}
              />
            </Box>
          </Box>

          {isTaskBlock && isMobile && (
            <Box sx={{ width: "100%" }}>
              <TaskBlockButton document={document} />
            </Box>
          )}

          <BookmarkToTaskDialog
            familyRef={copyToFamily?.ref || ""}
            familyName={copyToFamily?.name || ""}
            open={!!copyToFamily}
            onClose={() => {
              setCopyToFamily(undefined);
            }}
            entry={document ? fromDocument(document) : null}
            onAction={onBookmarkHandler}
            singleSelection={true}
          />
        </Box>

        <_Wrapped
          document={document}
          error={error}
          initialContent={initialContent}
        />
        <ConfirmationDialog
          content={"Are you sure you want to remove this document?"}
          {...confirmState.dialogProps}
        />
      </Box>
    );
  };
  return (
    <GridPage
      sx={{
        maxWidth: "1000px",
        margin: isMobile ? "" : "auto",
        padding: isMobile ? "32px 24px" : "32px 48px",
        gap: "20px",
        paddingBottom: "0px",
      }}
    >
      {_content()}
      {shareDialogOpen && (
        <AddPostDialog
          open={!!shareDialogOpen}
          onClose={() => setShareDialogOpen(null)}
          {...shareDialogOpen}
          onCreated={(p) => {
            navigate(`/community/${encodeURIComponent(p.ref || "")}`);
          }}
        />
      )}
    </GridPage>
  );
};
