import React, { useEffect, useState } from "react";
import ReactiveDialog from "../common/ReactiveDialog";
import useIsMobile from "../hooks/useIsMobile";
import { FeedEntry, Medium } from "protogen/advisors_service_pb";
import { Box, FormControlLabel, Typography } from "@mui/material";
import Checkbox from "../common/Checkbox";
import { useListTasksForFamily, useUpdateTasksBookmarks } from "services/tasks";
import Loading from "../common/Loading";
import { Task, TaskState } from "protogen/tasks_pb";
import { EmailMessage } from "protogen/conversation_pb";
import { Document } from "protogen/documents_pb";
type BookmarkEntry = {
  content: string;
  taskBookmarks: Task[];
  entityType: string;
  entityRef: string;
  medium: Medium | null;
  saveMethodType: "bookmark" | "document";
};

const DOCUMENTS_STATIC_CHOICE = "Family documents";

const titleForEntry = (entry: BookmarkEntry, familyName?: string) => {
  if (entry.saveMethodType === "document") {
    return `Copy to ${familyName} family`;
  }
  switch (entry.medium) {
    case Medium.EMAIL:
      return "Which task would you like to save this email to?";
    case Medium.PHONE:
      return "Which task would you like to save this call to?";
    case Medium.SMS:
    default:
      return "Which task would you like to save this message to?";
  }
};

export const fromEmail = (email: EmailMessage): BookmarkEntry => {
  return {
    content: email.teaserText,
    taskBookmarks: email.taskBookmarks,
    entityType: Medium.EMAIL.toString(),
    entityRef: email.ref,
    medium: Medium.EMAIL,
    saveMethodType: "bookmark",
  };
};

export const fromFeedEntry = (entry: FeedEntry): BookmarkEntry => {
  switch (entry.medium) {
    case Medium.EMAIL:
      return {
        content: entry.emailMessage?.teaserText || "",
        taskBookmarks: entry.emailMessage?.taskBookmarks || [],
        entityType: entry.medium.toString(),
        entityRef: entry.emailMessage?.ref || "",
        medium: Medium.EMAIL,
        saveMethodType: "bookmark",
      };
    case Medium.SMS:
      return {
        content: entry.textMessage?.content || "",
        taskBookmarks: entry.textMessage?.taskBookmarks || [],
        entityType: entry.medium.toString(),
        entityRef: entry.textMessage?.ref || "",
        medium: Medium.SMS,
        saveMethodType: "bookmark",
      };
    case Medium.PHONE:
    default:
      return {
        content: "Phone call",
        taskBookmarks: entry.phoneCall?.taskBookmarks || [],
        entityType: entry.medium.toString(),
        entityRef: entry.phoneCall?.ref || "",
        medium: Medium.PHONE,
        saveMethodType: "bookmark",
      };
  }
};

export const fromDocument = (document: Document): BookmarkEntry => {
  return {
    content: document.title,
    taskBookmarks: [],
    entityType: "document",
    entityRef: document.ref,
    medium: null,
    saveMethodType: "document",
  };
};
type Props = {
  familyRef: string;
  open: boolean;
  onClose: () => void;
  entry?: BookmarkEntry | null;
  familyName?: string;
  onAction?: (taskRefs: string[]) => Promise<void>;
  singleSelection?: boolean;
};

export default ({
  open,
  onClose,
  entry = null,
  familyRef,
  familyName,
  onAction,
  singleSelection,
}: Props) => {
  const isMobile = useIsMobile();
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const {
    request: loadTasks,
    loading: tasksLoading,
    data: tasksData,
  } = useListTasksForFamily();
  const { request: updateBookmarks, loading: updateLoading } =
    useUpdateTasksBookmarks();
  useEffect(() => {
    if (!familyRef) return;
    if (entry) {
      setCheckedValues(entry.taskBookmarks.map((t: Task) => t.ref));
    }
    loadTasks({
      familyRef,
      taskStates: [TaskState.OPEN, TaskState.INPROGRESS],
    });
  }, [entry?.entityRef, familyRef]);

  const saveBookmarks = async () => {
    if (!entry) return;
    const initialBookmarks = new Set(
      entry.taskBookmarks.map((t: Task) => t.ref),
    );
    const newlyChecked = checkedValues.filter(
      (ref) => !initialBookmarks.has(ref),
    );
    const newlyUnchecked = Array.from(initialBookmarks).filter(
      (ref) => !checkedValues.includes(ref),
    );
    await updateBookmarks({
      bookmarks: Array.from(new Set([...newlyChecked, ...newlyUnchecked]))
        .map((ref) => ({
          taskRef: ref,
          entityType: entry?.medium?.toString() || "",
          entityRef: entry?.entityRef || "",
          add: newlyChecked.includes(ref),
          remove: newlyUnchecked.includes(ref),
        }))
        .filter((b) => b.add || b.remove),
    });
    onClose();
  };

  if (!entry) return null;
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title={!isMobile ? titleForEntry(entry, familyName) : undefined}
      // fullWidthSize={"sm"}
      // noActionsFullScreen={true}
      primaryActionName={"Save"}
      primaryAction={() => {
        if (entry?.saveMethodType === "bookmark") {
          saveBookmarks();
        }
        if (entry?.saveMethodType === "document" && onAction) {
          onAction(checkedValues);
        }
      }}
      primaryActionEnabled={!(tasksLoading || updateLoading)}
      sx={{
        h2: { paddingBottom: "0" },
        "h2.MuiDialogTitle-root span": {
          fontSize: "20px",
          fontFamily: "AlbertSans",
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap="24px">
        {isMobile && (
          <Typography variant="h4">
            {titleForEntry(entry, familyName)}
          </Typography>
        )}
        <Box
          sx={{
            borderRadius: "12px",
            border: "1px solid #D4D4D4",
            background: "#FAF9FA",
            padding: "24px 24px 20px 24px",
          }}
        >
          <Typography variant="body">{entry.content}</Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            gap: "16px",
            maxHeight: !isMobile ? "80vh" : undefined,
            overflowY: "scroll",
            width: "100%",
            padding: "5px",
          }}
        >
          {tasksLoading && <Loading />}
          {!tasksLoading && entry?.saveMethodType === "document" && (
            <FormControlLabel
              label={
                <Typography variant={"body"}>
                  {familyName} file cabinet
                </Typography>
              }
              control={
                <Checkbox
                  disabled={updateLoading}
                  sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                  checked={checkedValues.includes(DOCUMENTS_STATIC_CHOICE)}
                  onChange={() => {
                    setCheckedValues([DOCUMENTS_STATIC_CHOICE]);
                  }}
                />
              }
            />
          )}
          {!tasksLoading &&
            tasksData?.tasks.map((task: Task) => (
              <FormControlLabel
                key={task.ref}
                label={<Typography variant={"body"}>{task.title}</Typography>}
                control={
                  <Checkbox
                    disabled={updateLoading}
                    sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                    checked={checkedValues.includes(task.ref)}
                    onChange={(e: { target: { checked: any } }) => {
                      const isChecked = e.target.checked;
                      if (singleSelection) {
                        setCheckedValues([task.ref]);
                      } else {
                        setCheckedValues((prevCheckedValues) => {
                          return isChecked
                            ? [...prevCheckedValues, task.ref]
                            : prevCheckedValues.filter((v) => v !== task.ref);
                        });
                      }
                    }}
                  />
                }
              />
            ))}
        </Box>
      </Box>
    </ReactiveDialog>
  );
};
