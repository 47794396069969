import { Box, Typography } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";
import { ExternalLink } from "lucide-react";

export default ({ stripeCustomerId }: { stripeCustomerId: string }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderColor: "rgba(0, 0, 0, 0.23)",
        border: "1px solid #D4D4D4",
        borderRadius: "8px",
        padding: "7px 20px",
        alignItems: "start",
        overflow: "clip",
        width: "calc(33% - 8px)",
      }}
    >
      <Typography
        variant="bodySmall"
        sx={{
          color: "#6B6E7B",
          fontWeight: 500,
          fontSize: "12px",
          userSelect: "none",
          cursor: "default",
        }}
      >
        Stripe ID
      </Typography>
      <LinkRouter
        targetNew={true}
        sx={{ padding: 0, width: "100%" }}
        to={`https://dashboard.stripe.com/customers/${stripeCustomerId}`}
      >
        <Typography
          variant="bodySmall"
          sx={{
            display: "inline",
            marginRight: "6px",
            fontWeight: 600,
            flexShrink: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {stripeCustomerId}
        </Typography>
        <ExternalLink
          style={{
            flexShrink: 0,
          }}
          size={20}
        />
      </LinkRouter>
    </Box>
  );
};
