const approximateTimezoneForState = (state: string): string => {
  switch (state.toLowerCase()) {
    // Central Time Zone: America/Chicago
    case "al":
    case "alabama":
    case "ar":
    case "arkansas":
    case "il":
    case "illinois":
    case "ia":
    case "iowa":
    case "ks":
    case "kansas":
    case "ky":
    case "kentucky": // Note: western KY is Mountain but we default to Eastern
    case "la":
    case "louisiana":
    case "mi":
    case "michigan": // Most of Michigan is Eastern, except some parts
    case "mn":
    case "minnesota":
    case "ms":
    case "mississippi":
    case "mo":
    case "missouri":
    case "ne":
    case "nebraska": // Western Nebraska is Mountain, but defaulting to Central
    case "nd":
    case "north dakota": // Western ND is Mountain, defaulting to Central
    case "ok":
    case "oklahoma":
    case "sd":
    case "south dakota": // Western SD is Mountain, defaulting to Central
    case "tx":
    case "texas":
    case "wi":
    case "wisconsin":
    case "tn":
    case "tennessee": // Tennessee spans two zones; defaulting to Central for approximation
      return "America/Chicago";

    // Alaska
    case "ak":
    case "alaska":
      return "America/Anchorage";

    // Arizona
    case "az":
    case "arizona":
      return "America/Phoenix";

    // Mountain Time Zone: America/Denver
    case "co":
    case "colorado":
    case "mt":
    case "montana":
    case "nm":
    case "new mexico":
    case "ut":
    case "utah":
    case "wy":
    case "wyoming":
    case "id":
    case "idaho": // Idaho is split, but most of the state uses Mountain (America/Boise)
      return "America/Denver";

    // Pacific Time Zone: America/Los_Angeles
    case "ca":
    case "california":
    case "nv":
    case "nevada":
    case "or":
    case "oregon": // Eastern Oregon is Mountain, but defaulting to Pacific
    case "wa":
    case "washington":
      return "America/Los_Angeles";

    // Eastern Time Zone: America/New_York
    case "ct":
    case "connecticut":
    case "de":
    case "delaware":
    case "fl":
    case "florida": // Most of Florida is Eastern; the panhandle is Central
    case "ga":
    case "georgia":
    case "in":
    case "indiana":
    case "me":
    case "maine":
    case "md":
    case "maryland":
    case "ma":
    case "massachusetts":
    case "nh":
    case "new hampshire":
    case "nj":
    case "new jersey":
    case "ny":
    case "new york":
    case "nc":
    case "north carolina":
    case "oh":
    case "ohio":
    case "pa":
    case "pennsylvania":
    case "ri":
    case "rhode island":
    case "sc":
    case "south carolina":
    case "vt":
    case "vermont":
    case "va":
    case "virginia":
    case "wv":
    case "west virginia":
      return "America/New_York";

    // Hawaii
    case "hi":
    case "hawaii":
      return "Pacific/Honolulu";

    default:
      return "";
  }
};

const stateFromZip = async (zipCode: string): Promise<string | undefined> => {
  const geocoder: google.maps.Geocoder | null =
    new window.google.maps.Geocoder();
  try {
    const response = await geocoder.geocode({
      address: zipCode,
    });
    if (response.results.length > 0) {
      const addressComponents = response.results[0].address_components;
      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1"),
      );
      if (stateComponent) {
        return stateComponent.short_name;
      }
    }
  } catch (error) {
    console.error("Error fetching timezone:", error);
  }
  return undefined;
};

export { approximateTimezoneForState, stateFromZip };
