import { Box, Tooltip } from "@mui/material";
import { FLAGS } from "../flags";
import { getColor } from "../utils";

export default ({ flags }: { flags: string[] }) => {
  if (!flags.length) return null;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {flags.map((flag) => {
        const flagObj = FLAGS.find((f) => f.key === flag);
        if (!flagObj) return null;
        return (
          <Box
            key={flag}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F0F0F0",
              padding: "4px 8px",
              borderRadius: "16px",
            }}
          >
            <Tooltip title={flagObj.title}>
              {flagObj.icon && (
                <flagObj.icon
                  size={16}
                  color={getColor(flagObj.activeStatus)}
                />
              )}
            </Tooltip>
          </Box>
        );
      })}
    </Box>
  );
};
