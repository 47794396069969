import React, { useEffect, useState } from "react";
import { FormErrors } from "./types";
import ReactiveDialog from "../common/ReactiveDialog";
import {
  Box,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "../common/Checkbox";
import AddressAutocomplete from "../common/AddressAutocomplete";
import { Location } from "../common/AddressAutocomplete";
import PhoneInput from "../creation/PhoneInput";
import { cleanPhone } from "../../common/utils";
import { BusinessInfo } from "protogen/forum_service_pb";
import { checkPhone } from "../../common/userUtils";

export type FormData = {
  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  national?: boolean;
  location?: Location;
};

const RecommendationForm = ({
  errors,
  formData,
  updateFormData,
  disabled = false,
}: {
  errors: FormErrors;
  formData: FormData;
  updateFormData: (f: FormData) => void;
  disabled?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginTop: "16px",
        marginBottom: 4,
      }}
    >
      {/*<Typography variant={"bodyHeavy"}>Family Details</Typography>*/}
      <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
        <FormControl fullWidth>
          <TextField
            disabled={disabled}
            error={!!errors?.name}
            helperText={errors?.name}
            defaultValue={formData.name}
            onChange={(e) => updateFormData({ name: e.target.value })}
            label="Name*"
            margin="normal"
            size="small"
            sx={{ marginTop: 0 }}
          />
        </FormControl>
        <FormControl fullWidth>
          <PhoneInput
            value={formData.phone}
            onChange={(phone) =>
              updateFormData({
                phone: cleanPhone(
                  typeof phone === "string" ? phone : phone.target.value,
                ),
              })
            }
            disableDropdown={false}
            error={!!errors?.phone}
            helperText={errors?.phone}
            label="Phone"
            fullWidth
            size="small"
            sx={{ marginTop: 0 }}
            autoComplete="off"
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
        <FormControl fullWidth>
          <TextField
            type="email"
            disabled={disabled}
            error={!!errors?.email}
            helperText={errors?.email}
            defaultValue={formData.email}
            onChange={(e) => updateFormData({ email: e.target.value })}
            label="Email"
            margin="normal"
            size="small"
            sx={{ marginTop: 0 }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            disabled={disabled}
            error={!!errors?.website}
            helperText={errors?.website}
            defaultValue={formData.website}
            onChange={(e) => updateFormData({ website: e.target.value })}
            label="Website"
            margin="normal"
            size="small"
            sx={{ marginTop: 0 }}
          />
        </FormControl>
      </Box>
      <Typography variant={"bodyHeavy"}>Location</Typography>
      <FormControlLabel
        disabled={disabled}
        label={"Available nationwide"}
        control={
          <Checkbox
            defaultChecked={!!formData.national}
            onChange={(e) => updateFormData({ national: e.target.checked })}
          />
        }
      />
      <AddressAutocomplete
        label={"Address or general location (if relevant)"}
        error={errors?.location}
        initialValue={formData.location?.formattedAddress}
        setValue={(location) => updateFormData({ location: location })}
      />
    </Box>
  );
};

const defaultFormData = (info: BusinessInfo): FormData => {
  return {
    name: info.name,
    phone: info.phone,
    email: info.email,
    website: info.website,
    national: info.national,
    location: !info?.location?.formattedAddress
      ? undefined
      : {
          address: info?.location?.address,
          city: info?.location?.city,
          state: info?.location?.state,
          zip: info?.location?.zip,
          latitude: info?.location?.latitude,
          longitude: info?.location?.longitude,
          country: info?.location?.country,
          formattedAddress: info?.location?.formattedAddress,
          unit: "",
        },
  };
};

type Props = {
  open: boolean;
  onClose: () => void;
  handleSave: (f: FormData) => Promise<boolean>;
  disabled?: boolean;
  info: BusinessInfo;
};

export default ({
  open,
  onClose,
  handleSave,
  info,
  disabled = false,
}: Props) => {
  const [errors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState<FormData>(defaultFormData(info));

  useEffect(() => {
    setFormData(defaultFormData(info));
    setFormErrors({});
  }, [info]);

  const updateFormData = (s: FormData) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...s,
    }));
  };
  const _validate = () => {
    const err: FormErrors = {};
    if (!formData.name) {
      err["name"] = "Required";
    }
    if (
      formData.phone &&
      formData.phone !== "+1" &&
      !checkPhone(formData.phone)
    ) {
      err["phone"] = "Invalid phone number";
    }
    setFormErrors(err);
    return Object.keys(err).length === 0;
  };
  const handleUpdates = async () => {
    if (_validate()) {
      const success = await handleSave(formData);
      if (success) {
        onClose();
      }
    }
  };
  return (
    <ReactiveDialog
      open={open}
      fullWidthSize="sm"
      onClose={onClose}
      title={"Business information"}
      primaryAction={handleUpdates}
      primaryActionName={"Save"}
      primaryActionEnabled={!disabled}
    >
      <RecommendationForm
        disabled={disabled}
        updateFormData={updateFormData}
        errors={errors}
        formData={formData}
      />
    </ReactiveDialog>
  );
};
