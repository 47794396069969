import { useContext, useEffect, useRef } from "react";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Task } from "protogen/tasks_pb";
import { Box, Typography, BoxProps } from "@mui/material";
import {
  getPriorityLabel,
  getSortOrder,
  PriorityChip,
} from "../creation/PrioritySelect";
import { FilterContext, TaskFilter } from "./FilterContext";
import EmptyTableOverlay from "../common/EmptyTableOverlay";
import useIsMobile from "../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import TablePaginationFooter from "../common/TablePaginationFooter";
import { formattedDate } from "../../common/utils";
import { getStateIcon, getStateLabel } from "./constants";
import { dateStringToDayAndDate } from "./utils";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";

const TC = ({ children, ...otherProps }: BoxProps) => {
  return <Box {...otherProps}>{children}</Box>;
};

const TH = ({ title }: { title: string }) => {
  return (
    <Box>
      <Typography variant="bodySmallHeavy">{title}</Typography>
    </Box>
  );
};

interface RowData {
  id: string;
  ref: string;
  hoursReported: string;
  minutesReported: string;
  task: Task;
  family: string;
  summary: string;
  assignee: string;
  priority: {
    label: string;
    sortOrder: number;
  };
  status: string;
  dueDate: string;
}

const getColumns = (isMobile: boolean, isSortable?: boolean): GridColDef[] => [
  { field: "ref", headerName: "Ref" },
  { field: "hoursReported", headerName: "Hours Taken" },
  { field: "minutesReported", headerName: "Minutes Taken" },
  { field: "family", headerName: "Family" },
  {
    field: "summary",
    headerName: "Summary",
    flex: 1,
    sortable: isSortable,
    renderCell: (params: GridRenderCellParams<RowData, any>) => (
      <TC width="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            width: "100%",
          }}
        >
          {params.row.task.unreadStatusCount > 0 && (
            <Box>
              <EllipseIcon
                height={8}
                width={8}
                style={{ marginLeft: "6px", marginRight: "6px" }}
              />
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography
              variant="body"
              color="text.primary"
              sx={{
                marginRight: "8px",
              }}
            >
              {params.row.task.title}
            </Typography>
            <Typography
              variant="bodySmall"
              color="text.secondary"
              sx={{
                overflow: "hidden",
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                marginRight: "8px",
              }}
            >
              {params?.row?.task?.family?.name}
            </Typography>
          </Box>
        </Box>
      </TC>
    ),
    renderHeader: () => <TH title="Description"></TH>,
  },
  { field: "assignee", headerName: "Assignee" },
  {
    field: "status",
    headerName: "Status",
    sortable: isSortable,
    renderCell: (params: GridRenderCellParams<RowData, any>) => (
      <TC>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {getStateIcon(params.row.task.state)}{" "}
          <Typography variant="body" color="text.primary">
            {getStateLabel(params.row.task.state)}
          </Typography>
        </Box>
      </TC>
    ),
    renderHeader: () => <TH title="Status"></TH>,
    width: 160,
  },
  {
    field: "dueDate",
    headerName: "Due date",
    minWidth: 80,
    sortable: isSortable,
    sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
    renderCell: (params: GridRenderCellParams<RowData, any>) => (
      <TC>
        <Typography>
          {params.row.task.dueDate
            ? dateStringToDayAndDate(params.row.task.dueDate, false)[1]
            : ""}
        </Typography>
      </TC>
    ),
    renderHeader: () => <TH title="Due date"></TH>,
  },
  {
    field: "priority",
    headerName: "Priority",
    sortable: isSortable,
    minWidth: 126,
    sortComparator: (v1, v2) => v1.sortOrder - v2.sortOrder, // Use `sortOrder` directly
    renderCell: (params: GridRenderCellParams<RowData, any>) => (
      <TC>
        <PriorityChip priority={params.row.task.priority} isMobile={isMobile} />
      </TC>
    ),
    renderHeader: () => <TH title="Priority"></TH>,
  },
  {
    field: "creationDate",
    headerName: "Creation Date",
    valueGetter: (value, row) => {
      return formattedDate(new Date(Number(row.task.createdSec) * 1000));
    },
  },
  { field: "hoursReported", headerName: "Hours Taken" },
  { field: "minutesReported", headerName: "Minutes Taken" },
];

const fromTask = (task: Task): RowData => {
  return {
    id: task.ref,
    ref: task.ref,
    hoursReported: task.timing?.hoursReported.toString() || "",
    minutesReported: task.timing?.minutesReported.toString() || "",
    task: task,
    family: task.family?.name || "",
    summary: task.title,
    assignee: task.assignee?.displayName || "",
    priority: {
      label: getPriorityLabel(task.priority) || task.priority,
      sortOrder: getSortOrder(task.priority) || Infinity, // Ensure `sortOrder` exists in the task object
    },
    status: getStateLabel(task.state) || "",
    dueDate: task.dueDate || "",
  };
};

type Props = {
  tasks: Task[];
  loading?: boolean;
  fetchTasks?: (f: TaskFilter[]) => void;
  isSortable?: boolean;
  hideStatus?: boolean;
};

export default ({
  tasks,
  loading,
  fetchTasks,
  isSortable,
  hideStatus = false,
}: Props) => {
  const { filters } = useContext(FilterContext);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const onFirstRender = useRef(true);
  useEffect(() => {
    if (onFirstRender.current) {
      onFirstRender.current = false;
      return;
    }
    fetchTasks && fetchTasks(filters);
  }, [filters]);

  const rowClick: GridEventListener<"rowClick"> = (params, event) => {
    const to = `/tasks/${encodeURIComponent(params.row.task.ref)}`;
    if (event.metaKey || event.ctrlKey) {
      window.open(to, "_blank");
    } else {
      navigate(to);
    }
  };

  return (
    <DataGrid
      slots={{
        noRowsOverlay: () => EmptyTableOverlay("No Tasks"),
        footer: TablePaginationFooter,
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            ref: false,
            ...(hideStatus && { status: false }),
            hoursReported: false,
            minutesReported: false,
            creationDate: false,
            family: false,
            assignee: false,
            ...(isMobile
              ? {
                  priority: false,
                  assignee: false,
                  status: false,
                }
              : {}),
          },
        },
      }}
      sx={{
        width: "100%",
        border: "none",
        "--DataGrid-overlayHeight": "300px",
        ".MuiDataGrid-columnHeader": {
          padding: "0px",
          background: "white",
          border: "none !important",
          height: "32px !important",
        },
        ".MuiDataGrid-row": {
          padding: "8px 0",
        },
        ".MuiDataGrid-cell": {
          padding: "16px 0px",
          color: "#101828",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          borderTop: "1px solid #ECECEC",
          borderBottom: "1px solid #ECECEC !important",
          alignContent: "center",
        },
        ".MuiDataGrid-cell:nth-of-type(2)": {
          borderLeft: "1px solid #ECECEC",
          paddingLeft: "24px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        ".MuiDataGrid-cell:nth-last-of-type(1)": {
          borderRight: "1px solid #ECECEC",
          paddingRight: "24px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },
        ".MuiDataGrid-row:focus": {
          outline: "none",
        },
        ".MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        ".MuiDataGrid-row:hover": {
          backgroundColor: "inherit",
          cursor: "pointer",
        },
        ".MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        ".MuiDataGrid-columnSeparator": {
          // Need to override the default styles to make the column separator invisible
          visibility: "hidden !important",
        },
      }}
      getRowHeight={() => "auto"}
      filterMode="server"
      onFilterModelChange={() => {}}
      rows={tasks.map(fromTask)}
      columns={getColumns(isMobile, isSortable)}
      disableColumnMenu
      autoHeight
      onRowClick={rowClick}
      loading={loading}
    />
  );
};
