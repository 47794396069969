import { Typography } from "@mui/material";
import { SummarySuggestion } from "protogen/suggestions_pb";
import SuggestionItemContainer from "./SuggestionItemContainer";

export default ({
  isOpen,
  inlineMode = false,
  summary,
}: {
  entityRef: string;
  entityType: string;
  summary: SummarySuggestion;
  isOpen: boolean;
  disabled?: boolean;
  inlineMode?: boolean;
}) => {
  return (
    <SuggestionItemContainer
      inlineMode={inlineMode}
      open={isOpen}
      title={`Summary`}
    >
      <Typography
        variant={"body"}
        sx={{
          wordBreak: "break-word",
          whiteSpace: "pre-line", // Lets messages use new lines.
        }}
      >
        {summary?.content}
      </Typography>
    </SuggestionItemContainer>
  );
};
