import { SignupState } from "./types";
import {
  SignupState as SignupStateProto,
  SignupStateChoiceValue,
  UpdateSessionRequest,
} from "../../protogen/signup_service_pb";
import type { PartialMessage } from "@bufbuild/protobuf";

const toChoicesProto = (choices: { [key: string]: boolean | string }) => {
  return Object.fromEntries(
    Object.keys(choices).map((c) => [
      c,
      {
        value: choices[c] === true,
        valueString:
          choices[c] === true || choices[c] === false
            ? ""
            : choices[c].toString(),
      },
    ]),
  );
};

const buildSessionUpdate = (
  stateChanges?: Partial<SignupState>,
  nextStep?: string,
) => {
  const updates: PartialMessage<UpdateSessionRequest> = {};
  const updatedState: PartialMessage<SignupStateProto> = {};
  if (nextStep) {
    updates.updatedStepName = nextStep;
  }
  if (stateChanges?.goals) {
    updates.shouldUpdateGoals = true;
    updatedState.goals = toChoicesProto(stateChanges.goals);
  }
  if (stateChanges?.household) {
    updates.shouldUpdateHousehold = true;
    updatedState.household = toChoicesProto(stateChanges.household);
  }
  if (stateChanges?.superpowers) {
    updates.shouldUpdateSuperpowers = true;
    updatedState.superpowers = toChoicesProto(stateChanges.superpowers);
  }
  if (stateChanges?.membership) {
    updates.shouldUpdateMembership = true;
    updatedState.membership = stateChanges.membership;
  }
  if (stateChanges?.payment) {
    updates.shouldUpdatePayment = true;
    updatedState.payment = stateChanges.payment;
  }
  if (stateChanges?.userInfo) {
    updates.shouldUpdateUserInfo = true;
    updatedState.userInfo = stateChanges.userInfo;
  }
  if (stateChanges?.consultationDetails) {
    updates.shouldUpdateConsultationDetails = true;
    updatedState.consultationDetails = stateChanges.consultationDetails;
  }
  if (stateChanges?.consultation) {
    updates.shouldUpdateConsultation = true;
    updatedState.consultationEventUri = stateChanges.consultation.eventUri;
    updatedState.consultationInviteeUri = stateChanges.consultation.inviteeUri;
  }
  if (stateChanges?.familyInfo) {
    updates.shouldUpdateFamilyInfo = true;
    updatedState.familyInfo = stateChanges.familyInfo;
  }
  if (stateChanges?.fun) {
    updates.shouldUpdateFun = true;
    updatedState.fun = toChoicesProto(stateChanges.fun);
  }
  if (stateChanges?.lifeEvents) {
    updates.shouldUpdateLifeEvents = true;
    updatedState.lifeEvents = toChoicesProto(stateChanges.lifeEvents);
  }
  if (stateChanges?.anythingElse) {
    updates.shouldUpdateAnythingElse = true;
    updatedState.anythingElse = stateChanges.anythingElse;
  }
  if (stateChanges?.referralSource) {
    updates.shouldUpdateReferralSource = true;
    updatedState.referralSource = stateChanges.referralSource;
  }

  updates.updatedState = updatedState;
  return new UpdateSessionRequest(updates);
};

const fromChoicesProto = (choices: {
  [key: string]: SignupStateChoiceValue;
}) => {
  return Object.fromEntries(
    Object.entries(choices).map(([k, v]) => [k, v.valueString || v.value]),
  );
};

const buildStateFromProto = (proto: SignupStateProto) => {
  const state: SignupState = {};
  if (proto.goals) {
    state.goals = fromChoicesProto(proto.goals);
  }
  if (proto.household) {
    state.household = fromChoicesProto(proto.household);
  }
  if (proto.superpowers) {
    state.superpowers = fromChoicesProto(proto.superpowers);
  }
  if (proto.payment) {
    state.payment = proto.payment;
  }
  if (proto.consultationEventUri && proto.consultationInviteeUri) {
    state.consultation = {
      eventUri: proto.consultationEventUri,
      inviteeUri: proto.consultationInviteeUri,
      eventDate: proto.consultationEventStartSec
        ? new Date(Number(proto.consultationEventStartSec) * 1000)
        : undefined,
    };
  }
  if (proto.consultationDetails) {
    state.consultationDetails = {
      firstName: proto.consultationDetails.firstName,
      lastName: proto.consultationDetails.lastName,
      email: proto.consultationDetails.email,
      phone: proto.consultationDetails.phone,
      zipCode: proto.consultationDetails.zipCode,
    };
  }
  if (proto.familyInfo) {
    state.familyInfo = proto.familyInfo;
  }
  if (proto.fun) {
    state.fun = fromChoicesProto(proto.fun);
  }
  if (proto.lifeEvents) {
    state.lifeEvents = fromChoicesProto(proto.lifeEvents);
  }
  if (proto.anythingElse) {
    state.anythingElse = proto.anythingElse;
  }
  if (proto.referralSource) {
    state.referralSource = proto.referralSource;
  }
  if (proto.referralCode) {
    state.referralCode = proto.referralCode;
  }
  return state;
};

export { buildSessionUpdate, buildStateFromProto };
