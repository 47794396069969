import FamilyForm, { PlanDetailsForm } from "./FamilyForm";
import MemberForm from "./MemberForm";
import { Box, Typography } from "@mui/material";
import { cleanPhone, defaultMember, validateFamilyMember } from "./utils";
import { Family, FormErrors, Member } from "./types";
import { Advisor } from "protogen/common_pb";
import EmbeddedFamilyPermissions from "./EmbeddedFamilyPermissions";
import { useContext, useEffect, useState } from "react";
import ReactiveDialog from "../common/ReactiveDialog";
import { Plus } from "lucide-react";
import { emptyUpdates, memberUpdates } from "./updateUtils";
import { useUpdateFamily } from "../../services/advisor";
import useIsMobile from "components/hooks/useIsMobile";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdmin } from "../../common/userUtils";
import MetadataSection from "../common/MetadataSection";

const MemberModal = ({
  open,
  onClose,
  member,
  family,
}: {
  open: boolean;
  member: Member;
  onClose: (update?: Member) => void;
  family: Family;
}) => {
  const { request, loading } = useUpdateFamily();
  const [draftMember, setDraftMember] = useState<Member | null>(null);
  const [errors, setErrors] = useState<FormErrors | null>(null);
  const useMember = draftMember || member;
  useEffect(() => {
    setDraftMember(null);
  }, [open]);

  const onSave = async () => {
    if (!draftMember) return;
    const errors = validateFamilyMember(draftMember);
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    const data = emptyUpdates(family.ref || "");
    data.updatedMembers = [memberUpdates(draftMember, member)];
    const resp = await request(data);
    if (resp) {
      // Update parent state
      onClose(draftMember);
    }
  };
  return (
    <ReactiveDialog
      open={open}
      onClose={() => onClose()}
      title={`Edit ${member.firstName}`}
      primaryActionEnabled={!!draftMember && !loading}
      primaryAction={onSave}
      primaryActionName="Save"
      fullWidthSize={"md"}
    >
      <MemberForm
        errors={errors}
        member={useMember}
        onChange={(updatedMember) => {
          setDraftMember(updatedMember);
        }}
      />
    </ReactiveDialog>
  );
};

const NewMemberModal = ({
  open,
  onClose,
  family,
}: {
  open: boolean;
  onClose: (update?: Member) => void;
  family: Family;
}) => {
  const { request, loading } = useUpdateFamily();
  const [draftMember, setDraftMember] = useState<Member>(defaultMember());
  const [errors, setErrors] = useState<FormErrors | null>(null);
  useEffect(() => {
    setDraftMember(defaultMember());
  }, [open]);

  const onSave = async () => {
    if (!draftMember) return;
    const errors = validateFamilyMember(draftMember);
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    const data = emptyUpdates(family.ref || "");
    data.newMembers = [
      {
        ref: "",
        updatedFirstName: draftMember.firstName,
        updatedLastName: draftMember.lastName,
        updatedPhone: cleanPhone(draftMember.phone),
        updatedEmail: draftMember.email,
        shouldUpdateAltEmails: true,
        updatedAltEmails: draftMember.altEmails,
        updatedTimezone: draftMember.timezone,
      },
    ];
    const resp = await request(data);
    if (resp) {
      // Update parent state
      const member =
        resp?.family?.familyMembers[resp?.family.familyMembers.length - 1];
      if (!member) return;
      onClose({
        ref: member.ref,
        firstName: member.firstName,
        lastName: member.lastName,
        phone: member.primaryPhone,
        email: member.primaryEmail,
        altEmails: member.alternateEmails,
        timezone: member.timezone,
      });
    }
  };
  return (
    <ReactiveDialog
      open={open}
      onClose={() => onClose()}
      title={`Add account`}
      primaryAction={onSave}
      primaryActionName="Save"
      primaryActionEnabled={!loading}
      fullWidthSize={"md"}
    >
      <MemberForm
        errors={errors}
        member={draftMember}
        onChange={(newMember) => {
          setDraftMember(newMember);
        }}
      />
    </ReactiveDialog>
  );
};

const MembersSection = ({
  members,
  setMembers,
  family,
}: {
  members: Member[];
  setMembers: (members: Member[]) => void;
  family: Family;
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const isMobile = useIsMobile();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Typography variant="bodyHeavy">Accounts</Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap" // Allow wrapping to the next line
        gap={"12px"}
      >
        {members.map((member, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flex: "1 1 calc(50% - 12px)", // Set width to 50% minus half of the gap
              }}
            >
              <Box
                onClick={() => setOpenIndex(index)}
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "18px 16px 18px 20px",
                  alignItems: "center",
                  gap: "8px",
                  boxSizing: "border-box", // Include padding and borders in the width
                  borderRadius: "12px",
                  border: "1px solid #D4D4D4",
                  background: "#FFF",
                  cursor: "pointer",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body">{member.firstName}</Typography>
                <Typography variant="bodySmallHeavy" color="#198282">
                  edit
                </Typography>
              </Box>
              <MemberModal
                open={openIndex === index}
                onClose={(update) => {
                  if (update) {
                    const updatedMembers = [...members];
                    updatedMembers[index] = update;
                    setMembers(updatedMembers);
                  }
                  setOpenIndex(null);
                }}
                member={members[index]}
                family={family}
              />
            </Box>
          );
        })}
        <Box
          sx={{
            display: "flex",
            padding: "18px 16px 18px 20px",
            alignItems: "center",
            gap: "8px",
            flex: isMobile ? "1" : "0 1 calc(50% - 12px)", // Same width for "Add a new account" tile
            boxSizing: "border-box",
            borderRadius: "12px",
            border: "1px solid #D4D4D4",
            background: "#FFF",
            cursor: "pointer",
            justifyContent: "space-between",
          }}
          onClick={() => {
            setOpenIndex(members.length);
          }}
        >
          <Typography variant="body">Add a new account</Typography>
          <Plus />
        </Box>
      </Box>
      <NewMemberModal
        open={openIndex === members.length}
        onClose={(update) => {
          if (update) {
            setMembers([...members, update]);
          }
          setOpenIndex(null);
        }}
        family={family}
      />
    </Box>
  );
};

const FamilyMetadata = ({ familyData }: { familyData: Family }) => {
  const { request } = useUpdateFamily();
  const currentUser = useContext(CurrentUserContext);
  const isAdmin = isUserAdmin(currentUser);
  if (!isAdmin) return null;
  if (familyData.metadata === undefined) return null;
  return (
    <Box
      sx={{
        marginTop: "36px",
        marginBottom: "12px",
      }}
    >
      <MetadataSection
        metadata={familyData.metadata}
        saveMetadata={async (metadata) => {
          const data = emptyUpdates(familyData.ref || "");
          data.shouldUpdateMetadata = true;
          data.updatedMetadata = metadata;
          await request(data);
        }}
        entityType={"family"}
      />
    </Box>
  );
};

type Props = {
  familyData: Family;
  members: Member[];
  setFamilyData: (familyData: Partial<Family>) => void;
  setMembers: (members: Member[]) => void;
  familyErrors: FormErrors | null;
  handleSubmit: () => void;
  familyOnly?: boolean;
  contactOnly?: string | null;
  primaryAdvisor?: Advisor;
  selectAdvisor?: boolean;
  error?: string | null | undefined;
};

export default ({
  familyData,
  members,
  setFamilyData,
  setMembers,
  handleSubmit,
  familyErrors,
  contactOnly = null,
  primaryAdvisor,
  selectAdvisor = false,
  error = null,
}: Props) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {/* Family details */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            {!contactOnly && (
              <FamilyForm
                errors={familyErrors}
                familyData={familyData}
                setFamilyData={setFamilyData}
                selectAdvisor={false} // Should add this if it's the advisor
              />
            )}
            <MembersSection
              members={members}
              setMembers={setMembers}
              family={familyData}
            />
          </Box>

          {/* Plan details */}
          {!contactOnly && (
            <PlanDetailsForm
              errors={familyErrors}
              familyData={familyData}
              setFamilyData={setFamilyData}
              selectAdvisor={selectAdvisor}
              stacked={false}
            />
          )}

          {/* Advisor details */}
          {!contactOnly && primaryAdvisor && (
            <EmbeddedFamilyPermissions
              primaryAdvisor={primaryAdvisor}
              errors={familyErrors}
              familyData={familyData}
              setFamilyData={setFamilyData}
            />
          )}
        </Box>
        <FamilyMetadata familyData={familyData} />
        {error && (
          <Box sx={{ color: "error.main", marginBottom: "8px" }}>{error}</Box>
        )}
      </Box>
    </form>
  );
};
