import { IconButton, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Download } from "lucide-react";

interface Props {
  isMobile: boolean;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  imageProps: {
    src?: string;
    alt?: string;
    width?: number;
    height?: number;
  };
}

export default ({
  isMobile,
  isFullScreen,
  setIsFullScreen,
  imageProps,
}: Props) => {
  return (
    <Dialog
      open={isFullScreen}
      onClose={() => setIsFullScreen(false)}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : "auto",
          height: isMobile ? "100%" : "auto",
          maxWidth: "100%",
          maxHeight: "100%",
          position: "relative",
          borderRadius: isMobile ? 0 : "16px",
          padding: isMobile ? "10vh 10vw" : "35px",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => setIsFullScreen(false)}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: "4px",
          backgroundColor: "#e5e5e5",
          zIndex: 10,
          color: "#000",
          "&:hover": {
            backgroundColor: "#e5e5e5",
          },
          ...(isMobile
            ? {
                top: 24,
                right: 24,
                color: "#000",
              }
            : {}),
        }}
      >
        <CloseIcon style={{ color: "black" }} />
      </IconButton>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "transparent",
          padding: isMobile ? "0" : "20px",
          position: "relative",
        }}
      >
        <img
          {...imageProps}
          style={{
            maxWidth: isMobile ? "100%" : "80vw",
            maxHeight: isMobile ? "100%" : "80vh",
            objectFit: "contain",
            borderRadius: "8px",
          }}
        />
        <IconButton
          component="a"
          target="_blank"
          href={imageProps.src}
          download={imageProps.alt || "image"}
          aria-label="download"
          sx={{
            position: "absolute",
            bottom: 30,
            right: 30,
            backgroundColor: "#e5e5e5",
            padding: "5px",
            "&:hover": {
              backgroundColor: "rgb(229, 229, 229, 0.6)",
            },
          }}
        >
          <Download />
        </IconButton>
      </div>
    </Dialog>
  );
};
