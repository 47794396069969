import { Box, Button } from "@mui/material";
import { JSONContent } from "@tiptap/react";
import { Handle } from "components/editor/SimpleEditor";
import SimpleRichEditor from "components/editor/SimpleRichEditor";
import { RichContent } from "components/editor/utils";
import useCached from "components/hooks/useCached";
import { useEffect, useRef, useState } from "react";
import { useCreateFamilyAnnotation } from "services/engagement";
import FlagsSelect from "./FlagsSelect";
import TypeSelect from "./TypeSelect";

export default ({
  familyRef,
  signupSessionRef,
  onUpdate,
  noteTypes,
  includeFlags = true,
  placeholder = "Add family note",
  forceOpen = true,
}: {
  familyRef: string;
  signupSessionRef: string;
  noteTypes: string[];
  includeFlags?: boolean;
  placeholder?: string;
  onUpdate: () => Promise<void>;
  forceOpen?: boolean;
}) => {
  const editorRef = useRef<Handle>(null);
  const [content, setContent] = useCached<RichContent | null>(
    `admin-annotation-l${signupSessionRef}-f${familyRef}`,
    null,
  );
  const [_initialContent] = useState<string | JSONContent | undefined>(
    (content?.text?.length && content?.json && JSON.parse(content?.json)) ||
      undefined,
  );
  const [noteType, setNoteType] = useState(noteTypes[0]);
  const [flags, setFlags] = useState<string[]>([]);
  const [annotationKey, setAnnotationKey] = useState("");
  const [uploadsInProgress, setUploadsInProgress] = useState(false);
  const { request, loading } = useCreateFamilyAnnotation();
  const addAnnotation = async () => {
    if (!content) return;
    const resp = await request({
      familyRef: familyRef,
      signupSessionRef: signupSessionRef,
      contentType: "json",
      content: content.json,
      textContent: content.text,
      attachments: content.attachments,
      noteType: noteType,
      flags: flags,
    });
    if (!resp?.annotation) return;
    setContent(null);
    setAnnotationKey(resp.annotation.ref);
    setFlags([]);
    onUpdate();
  };

  useEffect(() => {
    setContent(null);
    setAnnotationKey(`admin-annotation-l${signupSessionRef}-f${familyRef}`);
    setFlags([]);
  }, [signupSessionRef, familyRef]);

  return (
    <Box>
      <SimpleRichEditor
        forceOpen={forceOpen}
        ref={editorRef}
        key={annotationKey}
        disabled={loading}
        setContent={setContent}
        placeholder={placeholder}
        setUploadsInProgress={setUploadsInProgress}
        initialContent={_initialContent}
        primaryAction={
          <Button
            size="small"
            sx={{
              height: "unset",
              padding: "8px 18px",
            }}
            onClick={addAnnotation}
            disabled={loading || !content || uploadsInProgress}
          >
            Add
          </Button>
        }
        leftActionsPrefix={
          <>
            <TypeSelect
              defaultType={noteType}
              setType={setNoteType}
              typeOptions={noteTypes}
            />
            {includeFlags && <FlagsSelect flags={flags} setFlags={setFlags} />}
          </>
        }
      />
    </Box>
  );
};
