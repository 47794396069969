import { FormControlLabel, IconButton, MenuItem, Switch } from "@mui/material";
import { Ellipsis } from "lucide-react";
import { Task } from "protogen/tasks_pb";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { taskDeletion } from "./utils";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../common/ConfirmationDialog";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdvisor } from "../../common/userUtils";
import { CurrentUser } from "protogen/auth_pb";
import { useUpdateTask, useUpdateMemberTask } from "services/tasks";
import { EditTaskRequest } from "protogen/tasks_service_pb";
import { TaskState } from "protogen/tasks_pb";
import PopperMenu from "../common/PopperMenu";
import { useSnackpack } from "../context/SnackpackContextProvider";

const DeleteTask = ({
  task,
  currentUser,
}: {
  task: Task;
  currentUser: CurrentUser;
}) => {
  const { confirmState, deleteTask } = taskDeletion(
    !isUserAdvisor(currentUser),
  );
  return (
    <>
      <MenuItem
        sx={{
          color: "#C14743",
          padding: "8px 16px",
          minHeight: "inherit",
        }}
        onClick={() => deleteTask(task.ref)}
      >
        Delete
      </MenuItem>
      <ConfirmationDialog
        title="Delete task"
        noIcon={true}
        content="Are you sure you want to delete this task?"
        {...confirmState.dialogProps}
      />
    </>
  );
};

const HideTask = ({ task, refresh }: { task: Task; refresh: () => void }) => {
  const { request, loading } = useUpdateTask();
  const [latestTask, setLatestTask] = useState<Task>(task);
  const hideTask = async (taskRef: string, hidden: boolean) => {
    const resp = await request(
      new EditTaskRequest({
        taskRef,
        shouldUpdateHidden: true,
        updatedHidden: hidden,
      }),
    );
    if (resp) {
      setLatestTask(resp.task);
    }
    refresh();
  };
  useEffect(() => {
    setLatestTask(task);
  }, [task]);
  return (
    <FormControlLabel
      sx={{ marginLeft: "16px" }}
      control={
        <Switch
          edge="end"
          disabled={loading}
          onChange={() => hideTask(task.ref, !latestTask.hidden)}
          checked={latestTask.hidden}
        />
      }
      label={"Hide from client"}
    />
  );
};

const HideUnsavedTask = ({
  toggleHidden,
}: {
  toggleHidden: (hidden: boolean) => void;
}) => {
  const [hidden, setHidden] = useState(false);
  return (
    <FormControlLabel
      control={
        <Switch
          edge="end"
          onChange={() => {
            setHidden(!hidden);
            toggleHidden(!hidden);
          }}
          checked={hidden}
        />
      }
      label={"Hide from client"}
    />
  );
};

const MarkTaskAsDone = ({
  task,
  refresh,
}: {
  task: Task;
  refresh: () => void;
}) => {
  const { request } = useUpdateMemberTask();
  const confirmState = useConfirmationDialog();
  const markAsDone = async (taskRef: string) => {
    await request(
      new EditTaskRequest({
        taskRef,
        updatedState: TaskState.COMPLETED,
      }),
    );
    refresh();
  };
  return (
    <>
      <MenuItem
        sx={{
          color: "#3D3D3D",
          padding: "8px 16px",
          minHeight: "inherit",
        }}
        onClick={() =>
          confirmState.openDialog(async () => markAsDone(task.ref))
        }
      >
        Mark as done
      </MenuItem>
      <ConfirmationDialog
        title="Complete task"
        noIcon={true}
        content="Are you sure you want to complete this task?"
        {...confirmState.dialogProps}
      />
    </>
  );
};

const EditTaskTitle = ({ close }: { close: () => void }) => {
  const focusTitleInput = useCallback(() => {
    const titleInput =
      document.querySelector<HTMLInputElement>("#task-title-field");
    if (titleInput) {
      titleInput.focus();
      titleInput.setSelectionRange(
        titleInput.value.length,
        titleInput.value.length,
      );
    }
    close();
  }, []);

  return (
    <MenuItem
      sx={{
        color: "#3D3D3D",
        padding: "8px 16px",
        minHeight: "inherit",
      }}
      onClick={focusTitleInput}
    >
      Edit title
    </MenuItem>
  );
};

const CopyMemberLink = ({ task, close }: { task: Task; close: () => void }) => {
  const { pushSnack } = useSnackpack();
  const copyMemberLink = async () => {
    // create URL
    const url = `https://app.findfaye.com/tasks/${encodeURIComponent(
      task.ref,
    )}`;
    // Copy to clipboard
    await navigator.clipboard.writeText(url);
    close();
    pushSnack({
      message: "Member task link copied to clipboard",
      alertSeverity: "success",
      key: `task-${task.ref}`,
    });
  };
  return (
    <MenuItem
      sx={{
        color: "#3D3D3D",
        padding: "8px 16px",
        minHeight: "inherit",
      }}
      onClick={copyMemberLink}
    >
      Copy member link
    </MenuItem>
  );
};

export default ({
  task,
  refresh,
  isUnsavedTask,
  toggleHiddenUnsavedTask,
}: {
  task: Task;
  refresh: () => void;
  isUnsavedTask?: boolean;
  toggleHiddenUnsavedTask?: (hidden: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const currentUser = useContext(CurrentUserContext);
  const canDelete =
    task?.createdBy?.ref === currentUser.ref || isUserAdvisor(currentUser);

  const canMarkAsDone =
    !isUserAdvisor(currentUser) && task.state !== TaskState.COMPLETED;
  const canCopyMemberLink = isUserAdvisor(currentUser);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };
  const options = [];
  if (isUserAdvisor(currentUser) && isUnsavedTask) {
    options.push(
      <HideUnsavedTask
        key="hide-unsaved-task"
        toggleHidden={(hidden) => {
          toggleHiddenUnsavedTask && toggleHiddenUnsavedTask(hidden);
        }}
      />,
    );
  }
  if (!isUnsavedTask) {
    options.push(
      <EditTaskTitle key="edit-title" close={() => setOpen(false)} />,
    );
    if (isUserAdvisor(currentUser)) {
      options.push(
        <HideTask key={"hide-task"} task={task} refresh={refresh} />,
      );
    }
    if (canMarkAsDone) {
      options.push(
        <MarkTaskAsDone task={task} refresh={refresh} key="mark-as-done" />,
      );
    }
    if (canCopyMemberLink) {
      options.push(
        <CopyMemberLink
          task={task}
          close={() => setOpen(false)}
          key="copy-member-link"
        />,
      );
    }
    if (canDelete) {
      options.push(
        <DeleteTask task={task} currentUser={currentUser} key="delete-task" />,
      );
    }
  }
  if (options.length === 0) return null;
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        sx={{
          borderRadius: "50px",
          border: "2px solid #E2E3E4",
          padding: "4px",
        }}
      >
        <Ellipsis size={20} />
      </IconButton>
      <PopperMenu open={open} anchorRef={anchorRef} handleClose={handleClose}>
        {options}
      </PopperMenu>
    </>
  );
};
