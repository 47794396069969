import { Box, TextField } from "@mui/material";
import { useState } from "react";
import useIsMobile from "components/hooks/useIsMobile";
import { StepHeader } from "../components/StepHeader";
import { NextStepButton } from "../components/NextStepButton";

export interface Props {
  title: string;
  subtitle: string;
  bodyContent?: React.ReactNode;
  placeholder?: string;
  skippable?: boolean;
  onNext?: (inputText: string) => Promise<void> | undefined;
  initialText?: string | undefined;
  buttonText?: string;
}

export default ({
  title,
  subtitle,
  bodyContent,
  placeholder,
  skippable = false,
  onNext,
  initialText,
  buttonText,
}: Props) => {
  const isMobile = useIsMobile();
  const [inputValue, setInputValue] = useState(initialText || "");
  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setLoading(true);
    onNext && (await onNext(inputValue));
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        padding: isMobile ? "" : "0 32px",
        gap: isMobile ? "32px" : "64px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: isMobile ? "100%" : "70%",
          maxWidth: "740px",
          gap: "12px",
        }}
      >
        <StepHeader title={title} subtitle={subtitle} />

        {/* Body context */}
        {bodyContent}

        {/* Open input */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "end",
          }}
        >
          <TextField
            multiline
            placeholder={placeholder}
            variant="outlined"
            value={inputValue}
            fullWidth
            onChange={(e) => setInputValue(e.target.value)}
            sx={{
              "& .MuiInputBase-root": {
                color: "inherit",
                fontSize: "1rem",
                fontWeight: "500",
                minHeight: "150px",
                alignItems: "start",
                "& textarea": { marginTop: "0", padding: "20px 24px" },
              },
            }}
          />

          <NextStepButton
            skippable={skippable}
            hasValue={inputValue !== ""}
            continueClick={handleContinue}
            disabled={loading}
            buttonText={buttonText}
          />
        </Box>
      </Box>
    </Box>
  );
};
