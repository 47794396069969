import React, { forwardRef, useImperativeHandle } from "react";
import { ChoiceSelections, StepProps, StepRef } from "../types";
import ChoiceStep from "../components/ChoiceStep";
import {
  Sparkles,
  PlaneTakeoff,
  ClipboardCheck,
  House,
  Utensils,
} from "lucide-react";
export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }: StepProps, ref) => {
    useImperativeHandle(ref, () => ({}));
    return (
      <ChoiceStep
        title={"What are superpowers you’d like your Advisor to have?"}
        subtitle={
          "Our Advisors can handle all these tasks, but which matter most to you?"
        }
        disabled={disabled}
        choiceOptions={[
          {
            id: "memory-maker",
            header: "Memory Maker",
            text: "Adds extra sparkle to holidays, gifts, and special occasions.",
            icon: <Sparkles width="24" />, // Replace with a relevant Lucide or custom icon
          },
          {
            id: "travel-trailblazer",
            header: "Travel Trailblazer",
            text: "Maximizes your points, streamlines logistics, and makes trips seamless.",
            icon: <PlaneTakeoff width="24" />, // Replace with a relevant Lucide or custom icon
          },
          {
            id: "admin-alchemist",
            header: "Admin Alchemist",
            text: "Books appointments, tracks refunds, manages invites, and sign-ups effortlessly.",
            icon: <ClipboardCheck width="24" />, // Replace with a relevant Lucide or custom icon
          },
          {
            id: "home-hero",
            header: "Home Hero",
            text: "Finds the right person for the job, from a local handyman to a trusted contractor.",
            icon: <House width="24" />, // Replace with a relevant Lucide or custom icon
          },
          {
            id: "meal-maestro",
            header: "Meal Maestro",
            text: "Plans delicious, balanced meals, and makes sure your grocery list is ready.",
            icon: <Utensils width="24" />, // Replace with a relevant Lucide or custom icon
          },
        ]}
        onNext={async (choices: ChoiceSelections) => {
          return onNext?.({ superpowers: choices });
        }}
        initialChoices={signupState?.superpowers || undefined}
      />
    );
  },
);
