import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useState } from "react";

import { ReactComponent as CheckboxIcon } from "../../icons/Checkbox.svg";
import { ReactComponent as CheckboxSelectedIcon } from "../../icons/CheckboxSelected.svg";

type Props = {
  onChange: (checked: boolean) => void;
  label: string;
  initialChecked?: boolean;
};

export default ({ onChange, label, initialChecked = false }: Props) => {
  const [checked, setChecked] = useState(initialChecked);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        padding: "8px 18px",
        borderRadius: "100px",
        border: "1px solid #D4D4D4",
        background: "#FFF",
        cursor: "pointer",
      }}
      onClick={() => {
        onChange(!checked);
        setChecked((c) => !c);
      }}
    >
      {checked ? (
        <CheckboxSelectedIcon height={18} width={18} />
      ) : (
        <CheckboxIcon height={18} width={18} />
      )}
      <Typography
        variant="body"
        color="#6B6E7B"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
