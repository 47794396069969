import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Fade,
} from "@mui/material";
import { Copy } from "lucide-react";
import { useState, useRef } from "react";

export default ({
  value,
  ...textFieldProps
}: {
  value?: string;
} & React.ComponentProps<typeof TextField>) => {
  const [editing, setEditing] = useState(false);
  const [copied, setCopied] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleCopy = async () => {
    if (value && textFieldRef.current) {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500); // Hide "Copied" after 1.5 seconds
    }
  };

  return (
    <TextField
      variant="outlined"
      value={value}
      inputRef={textFieldRef}
      onFocus={() => setEditing(true)}
      onBlur={() => setEditing(false)}
      InputProps={{
        endAdornment:
          !editing && value ? (
            <InputAdornment
              position="end"
              sx={{
                paddingTop: "0px !important",
                paddingLeft: "15px !important",
                paddingRight: "12px",
              }}
            >
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Fade in={!copied} timeout={300}>
                  <IconButton onClick={handleCopy} edge="end" disabled={!value}>
                    <Copy width="20" />
                  </IconButton>
                </Fade>
                <Fade in={copied} timeout={300}>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "700",
                      paddingRight: "10px",
                    }}
                  >
                    Copied
                  </Typography>
                </Fade>
              </Box>
            </InputAdornment>
          ) : undefined,
      }}
      {...textFieldProps}
    />
  );
};
