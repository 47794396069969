import React, { ReactNode } from "react";
import Linkify from "react-linkify";

const isLocalLink = (url: string) => {
  try {
    const inputUrl = new URL(url);
    const currentUrl = new URL(window.location.href);
    // Check if the domains are exactly the same, including subdomains
    return inputUrl.hostname === currentUrl.hostname;
  } catch (error) {
    // If the URL is invalid, log the error and return false
    console.error("Invalid URL:", error);
    return false;
  }
};

const LinkItPhone = ({
  children,
  handleTel,
}: {
  children: ReactNode;
  handleTel?: ((e: string) => string) | null;
}) => {
  // Woof. This double checks the number isn't part of a longer number sequence or a URL.
  const phoneRegex =
    /(?<!http:\/\/)(?<!https:\/\/)(?<!www\.)(?<!\/)(?<!\d)(?:1\s*[-. ]?)?\(?(\d{3})\)?\s*[-. ]?\s*(\d{3})\s*[-. ]?\s*(\d{4})(?!\d)/g;

  const matchDecorator = (text: string) => {
    const cleanedNumber = text.replace(/\D/g, "");
    if (cleanedNumber.length === 10) {
      return `+1${cleanedNumber}`;
    } else if (cleanedNumber.length === 11 && cleanedNumber.startsWith("1")) {
      return `+${cleanedNumber}`;
    }
    return text;
  };

  const processText = (text: string) => {
    const matches = text.match(phoneRegex);
    if (!matches) return text;

    const parts: ReactNode[] = [];
    let lastIndex = 0;

    matches.forEach((match, index) => {
      const start = text.indexOf(match, lastIndex);

      if (start > lastIndex) {
        parts.push(
          <span key={`text-${index}-before`}>
            {text.slice(lastIndex, start)}
          </span>,
        );
      }

      // Capture leading and trailing whitespace around the match
      const trimmedMatch = match.trim();
      const leadingWhitespace = match.match(/^\s*/)?.[0] || ""; // Default to empty string if null
      const trailingWhitespace = match.match(/\s*$/)?.[0] || ""; // Default to empty string if null

      // Add leading whitespace outside the link if it exists
      if (leadingWhitespace) {
        parts.push(
          <span key={`whitespace-before-${index}`}>{leadingWhitespace}</span>,
        );
      }

      const formattedNumber = matchDecorator(trimmedMatch);

      // Add the phone number in an anchor tag (or span if no `handleTel`)
      parts.push(
        handleTel ? (
          <a href={handleTel(formattedNumber)} key={`link-${index}`}>
            {trimmedMatch}
          </a>
        ) : (
          <span key={`link-${index}`}>{trimmedMatch}</span>
        ),
      );

      // Add trailing whitespace outside the link if it exists
      if (trailingWhitespace) {
        parts.push(
          <span key={`whitespace-after-${index}`}>{trailingWhitespace}</span>,
        );
      }

      lastIndex = start + match.length;
    });
    if (lastIndex < text.length) {
      parts.push(<span key="end">{text.slice(lastIndex)}</span>);
    }

    return parts;
  };

  const renderChildren = (node: ReactNode): ReactNode => {
    if (typeof node === "string") {
      return processText(node);
    }

    if (React.isValidElement(node) && node.props.children) {
      return React.cloneElement(
        node,
        { ...node.props },
        React.Children.map(node.props.children as ReactNode, renderChildren),
      );
    }

    return node;
  };

  return <>{React.Children.map(children, renderChildren)}</>;
};

interface Props {
  handleEmail?: ((e: string) => string) | null;
  handleTel?: ((e: string) => string) | null;
  children: ReactNode;
}

export default ({
  children,
  handleEmail = (e) => `/inbox/email/create?email=${encodeURIComponent(e)}`,
  handleTel = (e) => `/inbox/phone/create?phone=${encodeURIComponent(e)}`,
}: Props) => {
  const componentDecorator = (href: string, text: string, key: number) => {
    if (handleEmail && href.startsWith("mailto:")) {
      const email = href.replace("mailto:", "");
      return (
        <a key={key} href={handleEmail(email)}>
          {text}
        </a>
      );
    } else {
      return (
        <a
          {...(isLocalLink(href) ? {} : { target: "_new" })}
          href={href}
          key={key}
        >
          {text}
        </a>
      );
    }
  };
  return (
    <LinkItPhone handleTel={handleTel}>
      <Linkify componentDecorator={componentDecorator}>{children}</Linkify>
    </LinkItPhone>
  );
};
