import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default ({ onConfirm }: { onConfirm: () => void }) => {
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        alignItems={"center"}
        justifyContent={"center"}
        margin="32px 0"
      >
        <Typography
          variant={"body"}
          color={"text.tertiary"}
          sx={{ maxWidth: "400px", textAlign: "center" }}
        >
          AI research can lead to mistakes or hallucinations. Use this content
          as a guide but make sure to double check all content before sharing
          with a client.
        </Typography>
        <Button
          sx={{ maxWidth: "100px" }}
          variant="outlined"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};
