// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/annotations.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub, Attachment } from "./common_pb.ts";

/**
 * @generated from message protogen.AnnotationFlags
 */
export class AnnotationFlags extends Message<AnnotationFlags> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  /**
   * @generated from field: string summary = 3;
   */
  summary = "";

  /**
   * @generated from field: int64 lastUpdatedSec = 4;
   */
  lastUpdatedSec = protoInt64.zero;

  constructor(data?: PartialMessage<AnnotationFlags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AnnotationFlags";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnnotationFlags {
    return new AnnotationFlags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnnotationFlags {
    return new AnnotationFlags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnnotationFlags {
    return new AnnotationFlags().fromJsonString(jsonString, options);
  }

  static equals(a: AnnotationFlags | PlainMessage<AnnotationFlags> | undefined, b: AnnotationFlags | PlainMessage<AnnotationFlags> | undefined): boolean {
    return proto3.util.equals(AnnotationFlags, a, b);
  }
}

/**
 * @generated from message protogen.ChangeEventAnnotation
 */
export class ChangeEventAnnotation extends Message<ChangeEventAnnotation> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string familyRef = 2;
   */
  familyRef = "";

  /**
   * @generated from field: string signupRef = 3;
   */
  signupRef = "";

  /**
   * @generated from field: string type = 4;
   */
  type = "";

  /**
   * @generated from field: string content = 5;
   */
  content = "";

  /**
   * @generated from field: string contentJson = 6;
   */
  contentJson = "";

  /**
   * @generated from field: protogen.AccountStub user = 7;
   */
  user?: AccountStub;

  /**
   * @generated from field: int64 lastUpdatedSec = 8;
   */
  lastUpdatedSec = protoInt64.zero;

  constructor(data?: PartialMessage<ChangeEventAnnotation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ChangeEventAnnotation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "signupRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "contentJson", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "user", kind: "message", T: AccountStub },
    { no: 8, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeEventAnnotation {
    return new ChangeEventAnnotation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeEventAnnotation {
    return new ChangeEventAnnotation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeEventAnnotation {
    return new ChangeEventAnnotation().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeEventAnnotation | PlainMessage<ChangeEventAnnotation> | undefined, b: ChangeEventAnnotation | PlainMessage<ChangeEventAnnotation> | undefined): boolean {
    return proto3.util.equals(ChangeEventAnnotation, a, b);
  }
}

/**
 * @generated from message protogen.FamilyAnnotation
 */
export class FamilyAnnotation extends Message<FamilyAnnotation> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string familyRef = 2;
   */
  familyRef = "";

  /**
   * @generated from field: string signupRef = 3;
   */
  signupRef = "";

  /**
   * @generated from field: protogen.AccountStub author = 4;
   */
  author?: AccountStub;

  /**
   * @generated from field: string noteType = 5;
   */
  noteType = "";

  /**
   * @generated from field: repeated string flags = 6;
   */
  flags: string[] = [];

  /**
   * @generated from field: string contentType = 7;
   */
  contentType = "";

  /**
   * @generated from field: string content = 8;
   */
  content = "";

  /**
   * @generated from field: string textContent = 9;
   */
  textContent = "";

  /**
   * @generated from field: repeated protogen.Attachment attachments = 10;
   */
  attachments: Attachment[] = [];

  /**
   * @generated from field: int64 lastUpdatedSec = 11;
   */
  lastUpdatedSec = protoInt64.zero;

  constructor(data?: PartialMessage<FamilyAnnotation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FamilyAnnotation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "signupRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "author", kind: "message", T: AccountStub },
    { no: 5, name: "noteType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "flags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "attachments", kind: "message", T: Attachment, repeated: true },
    { no: 11, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FamilyAnnotation {
    return new FamilyAnnotation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FamilyAnnotation {
    return new FamilyAnnotation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FamilyAnnotation {
    return new FamilyAnnotation().fromJsonString(jsonString, options);
  }

  static equals(a: FamilyAnnotation | PlainMessage<FamilyAnnotation> | undefined, b: FamilyAnnotation | PlainMessage<FamilyAnnotation> | undefined): boolean {
    return proto3.util.equals(FamilyAnnotation, a, b);
  }
}

