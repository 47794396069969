import { Box, Typography } from "@mui/material";
import { UserAvatar } from "components/common/CurrentUserAvatar";
import DateDisplay from "components/common/DateDisplay";
import useIsMobile from "components/hooks/useIsMobile";
import { ChangeEventAnnotation } from "protogen/annotations_pb";

export default ({ annotation }: { annotation: ChangeEventAnnotation }) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexGrow: 1,
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            height: "inherit",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <UserAvatar user={annotation.user!} size={40} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FAF9FA",
          flexGrow: 1,
          borderRadius: "16px",
          padding: "16px 24px",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>
              {annotation.user?.displayName}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#6B6E7B" }}>
              {annotation.type}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "4px", flexDirection: "column" }}>
            <DateDisplay
              date={new Date(Number(annotation.lastUpdatedSec) * 1000)}
              sx={{ fontSize: "14px", color: "#6B6E7B" }}
            />
          </Box>
        </Box>
        <Box>
          <Typography>{annotation.content}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
