import { Box } from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";
import { StepHeader } from "../components/StepHeader";

export interface Props {
  title: string;
  bodyContent?: React.ReactNode;
  actions?: React.ReactNode;
  img?: string;
  mobileImg?: string;
  fullImageHeight?: boolean;
  layout?: "vertical" | "horizontal";
}

export default ({
  title,
  bodyContent,
  actions,
  img,
  mobileImg,
  fullImageHeight = false,
  layout = "horizontal",
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile || layout == "vertical" ? "column" : "row",
        justifyContent: "center",
        padding: isMobile ? "" : "0 32px",
        gap: isMobile || layout == "vertical" ? "12px" : "64px",
        alignItems: "center",
        marginTop: layout == "vertical" ? "5%" : "",
      }}
    >
      <Box sx={{ display: "flex" }}>
        {!isMobile && (
          <img src={img} style={{ maxHeight: "82vh", borderRadius: "16px" }} />
        )}
        {isMobile && (
          <img
            src={mobileImg}
            style={{
              width: "100%",
              borderRadius: "16px",
              maxHeight: fullImageHeight ? "none" : "35vh",
              objectFit: "cover",
              marginBottom: "16px",
            }}
          />
        )}
      </Box>

      {/* Right side */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "570px",
          width: "100%",
          padding: isMobile ? "0 8px" : "",
        }}
      >
        <StepHeader title={title} alignment="left" />

        {/* Body content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <StepHeader subtitle={bodyContent} alignment="left" />
        </Box>

        {/* Action buttons */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "8px",
          }}
        >
          {actions}
        </Box>
      </Box>
    </Box>
  );
};
