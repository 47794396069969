// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/suggestions.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { TaskState } from "./tasks_pb.ts";
import { EventReminder, RecurringUnit } from "./calendar_pb.ts";
import { AccountStub } from "./common_pb.ts";

/**
 * @generated from enum protogen.SuggestionEntityType
 */
export enum SuggestionEntityType {
  /**
   * @generated from enum value: SUGGESTION_ENTITY_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: SUGGESTION_ENTITY_TYPE_PHONECALL = 1;
   */
  PHONECALL = 1,

  /**
   * @generated from enum value: SUGGESTION_ENTITY_TYPE_TASK = 2;
   */
  TASK = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SuggestionEntityType)
proto3.util.setEnumType(SuggestionEntityType, "protogen.SuggestionEntityType", [
  { no: 0, name: "SUGGESTION_ENTITY_TYPE_UNKNOWN" },
  { no: 1, name: "SUGGESTION_ENTITY_TYPE_PHONECALL" },
  { no: 2, name: "SUGGESTION_ENTITY_TYPE_TASK" },
]);

/**
 * @generated from message protogen.TaskSuggestion
 */
export class TaskSuggestion extends Message<TaskSuggestion> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string initialDetailsText = 2;
   */
  initialDetailsText = "";

  /**
   * @generated from field: string dueDate = 3;
   */
  dueDate = "";

  /**
   * @generated from field: string category = 4;
   */
  category = "";

  /**
   * @generated from field: string taskRef = 5;
   */
  taskRef = "";

  /**
   * @generated from field: protogen.TaskState taskState = 6;
   */
  taskState = TaskState.UNSPECIFIED;

  constructor(data?: PartialMessage<TaskSuggestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskSuggestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "initialDetailsText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "taskRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "taskState", kind: "enum", T: proto3.getEnumType(TaskState) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskSuggestion {
    return new TaskSuggestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskSuggestion {
    return new TaskSuggestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskSuggestion {
    return new TaskSuggestion().fromJsonString(jsonString, options);
  }

  static equals(a: TaskSuggestion | PlainMessage<TaskSuggestion> | undefined, b: TaskSuggestion | PlainMessage<TaskSuggestion> | undefined): boolean {
    return proto3.util.equals(TaskSuggestion, a, b);
  }
}

/**
 * @generated from message protogen.DocumentSuggestion
 */
export class DocumentSuggestion extends Message<DocumentSuggestion> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string details = 2;
   */
  details = "";

  /**
   * @generated from field: string factRef = 3;
   */
  factRef = "";

  /**
   * @generated from field: string documentRef = 4;
   */
  documentRef = "";

  constructor(data?: PartialMessage<DocumentSuggestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DocumentSuggestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "factRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "documentRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentSuggestion {
    return new DocumentSuggestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentSuggestion {
    return new DocumentSuggestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentSuggestion {
    return new DocumentSuggestion().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentSuggestion | PlainMessage<DocumentSuggestion> | undefined, b: DocumentSuggestion | PlainMessage<DocumentSuggestion> | undefined): boolean {
    return proto3.util.equals(DocumentSuggestion, a, b);
  }
}

/**
 * @generated from message protogen.FactSuggestion
 */
export class FactSuggestion extends Message<FactSuggestion> {
  /**
   * If created
   *
   * @generated from field: string factRef = 1;
   */
  factRef = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  /**
   * @generated from field: string valueType = 4;
   */
  valueType = "";

  /**
   * The source text that the fact was extracted from.
   *
   * @generated from field: string sourceText = 5;
   */
  sourceText = "";

  constructor(data?: PartialMessage<FactSuggestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FactSuggestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "factRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "valueType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sourceText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FactSuggestion {
    return new FactSuggestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FactSuggestion {
    return new FactSuggestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FactSuggestion {
    return new FactSuggestion().fromJsonString(jsonString, options);
  }

  static equals(a: FactSuggestion | PlainMessage<FactSuggestion> | undefined, b: FactSuggestion | PlainMessage<FactSuggestion> | undefined): boolean {
    return proto3.util.equals(FactSuggestion, a, b);
  }
}

/**
 * @generated from message protogen.EventSuggestion
 */
export class EventSuggestion extends Message<EventSuggestion> {
  /**
   * If created
   *
   * @generated from field: string eventRef = 1;
   */
  eventRef = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string details = 3;
   */
  details = "";

  /**
   * @generated from field: string dateStr = 4;
   */
  dateStr = "";

  /**
   * @generated from field: string timeStr = 5;
   */
  timeStr = "";

  /**
   * @generated from field: protogen.RecurringUnit recurringUnit = 6;
   */
  recurringUnit = RecurringUnit.RecurringUnit_UNSPECIFIED;

  /**
   * @generated from field: int32 recurringInterval = 7;
   */
  recurringInterval = 0;

  /**
   * @generated from field: protogen.EventReminder reminder = 8;
   */
  reminder?: EventReminder;

  constructor(data?: PartialMessage<EventSuggestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EventSuggestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eventRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "dateStr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timeStr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "recurringUnit", kind: "enum", T: proto3.getEnumType(RecurringUnit) },
    { no: 7, name: "recurringInterval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "reminder", kind: "message", T: EventReminder },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventSuggestion {
    return new EventSuggestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventSuggestion {
    return new EventSuggestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventSuggestion {
    return new EventSuggestion().fromJsonString(jsonString, options);
  }

  static equals(a: EventSuggestion | PlainMessage<EventSuggestion> | undefined, b: EventSuggestion | PlainMessage<EventSuggestion> | undefined): boolean {
    return proto3.util.equals(EventSuggestion, a, b);
  }
}

/**
 * @generated from message protogen.RecommendationsSuggestions
 */
export class RecommendationsSuggestions extends Message<RecommendationsSuggestions> {
  /**
   * @generated from field: string suggestionRef = 1;
   */
  suggestionRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string queryString = 3;
   */
  queryString = "";

  /**
   * @generated from field: repeated protogen.RecommendationsSuggestions.Recommendation posts = 4;
   */
  posts: RecommendationsSuggestions_Recommendation[] = [];

  constructor(data?: PartialMessage<RecommendationsSuggestions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RecommendationsSuggestions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "suggestionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "queryString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "posts", kind: "message", T: RecommendationsSuggestions_Recommendation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendationsSuggestions {
    return new RecommendationsSuggestions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendationsSuggestions {
    return new RecommendationsSuggestions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendationsSuggestions {
    return new RecommendationsSuggestions().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendationsSuggestions | PlainMessage<RecommendationsSuggestions> | undefined, b: RecommendationsSuggestions | PlainMessage<RecommendationsSuggestions> | undefined): boolean {
    return proto3.util.equals(RecommendationsSuggestions, a, b);
  }
}

/**
 * @generated from message protogen.RecommendationsSuggestions.Recommendation
 */
export class RecommendationsSuggestions_Recommendation extends Message<RecommendationsSuggestions_Recommendation> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string textContent = 3;
   */
  textContent = "";

  /**
   * @generated from field: string queryString = 4;
   */
  queryString = "";

  /**
   * @generated from field: protogen.AccountStub createdBy = 5;
   */
  createdBy?: AccountStub;

  /**
   * @generated from field: repeated string topics = 6;
   */
  topics: string[] = [];

  /**
   * Phone / website / email
   *
   * @generated from field: string path = 7;
   */
  path = "";

  constructor(data?: PartialMessage<RecommendationsSuggestions_Recommendation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RecommendationsSuggestions.Recommendation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "queryString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "createdBy", kind: "message", T: AccountStub },
    { no: 6, name: "topics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendationsSuggestions_Recommendation {
    return new RecommendationsSuggestions_Recommendation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendationsSuggestions_Recommendation {
    return new RecommendationsSuggestions_Recommendation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendationsSuggestions_Recommendation {
    return new RecommendationsSuggestions_Recommendation().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendationsSuggestions_Recommendation | PlainMessage<RecommendationsSuggestions_Recommendation> | undefined, b: RecommendationsSuggestions_Recommendation | PlainMessage<RecommendationsSuggestions_Recommendation> | undefined): boolean {
    return proto3.util.equals(RecommendationsSuggestions_Recommendation, a, b);
  }
}

/**
 * @generated from message protogen.WebResult
 */
export class WebResult extends Message<WebResult> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * Not always present //
   *
   * @generated from field: string thumbnailUrl = 4;
   */
  thumbnailUrl = "";

  /**
   * @generated from field: string price = 5;
   */
  price = "";

  /**
   * @generated from field: string phone = 6;
   */
  phone = "";

  /**
   * @generated from field: string address = 7;
   */
  address = "";

  constructor(data?: PartialMessage<WebResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.WebResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "thumbnailUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WebResult {
    return new WebResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WebResult {
    return new WebResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WebResult {
    return new WebResult().fromJsonString(jsonString, options);
  }

  static equals(a: WebResult | PlainMessage<WebResult> | undefined, b: WebResult | PlainMessage<WebResult> | undefined): boolean {
    return proto3.util.equals(WebResult, a, b);
  }
}

/**
 * @generated from message protogen.WebResearchSuggestion
 */
export class WebResearchSuggestion extends Message<WebResearchSuggestion> {
  /**
   * @generated from field: string suggestionRef = 1;
   */
  suggestionRef = "";

  /**
   * @generated from field: string queryString = 2;
   */
  queryString = "";

  /**
   * @generated from field: string resultsUrl = 3;
   */
  resultsUrl = "";

  /**
   * @generated from field: repeated protogen.WebResult results = 4;
   */
  results: WebResult[] = [];

  constructor(data?: PartialMessage<WebResearchSuggestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.WebResearchSuggestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "suggestionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "queryString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "resultsUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "results", kind: "message", T: WebResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WebResearchSuggestion {
    return new WebResearchSuggestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WebResearchSuggestion {
    return new WebResearchSuggestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WebResearchSuggestion {
    return new WebResearchSuggestion().fromJsonString(jsonString, options);
  }

  static equals(a: WebResearchSuggestion | PlainMessage<WebResearchSuggestion> | undefined, b: WebResearchSuggestion | PlainMessage<WebResearchSuggestion> | undefined): boolean {
    return proto3.util.equals(WebResearchSuggestion, a, b);
  }
}

/**
 * @generated from message protogen.TaskRequirement
 */
export class TaskRequirement extends Message<TaskRequirement> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<TaskRequirement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskRequirement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskRequirement {
    return new TaskRequirement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskRequirement {
    return new TaskRequirement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskRequirement {
    return new TaskRequirement().fromJsonString(jsonString, options);
  }

  static equals(a: TaskRequirement | PlainMessage<TaskRequirement> | undefined, b: TaskRequirement | PlainMessage<TaskRequirement> | undefined): boolean {
    return proto3.util.equals(TaskRequirement, a, b);
  }
}

/**
 * @generated from message protogen.SummarySuggestion
 */
export class SummarySuggestion extends Message<SummarySuggestion> {
  /**
   * @generated from field: string suggestionRef = 1;
   */
  suggestionRef = "";

  /**
   * @generated from field: string content = 2;
   */
  content = "";

  constructor(data?: PartialMessage<SummarySuggestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.SummarySuggestion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "suggestionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummarySuggestion {
    return new SummarySuggestion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummarySuggestion {
    return new SummarySuggestion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummarySuggestion {
    return new SummarySuggestion().fromJsonString(jsonString, options);
  }

  static equals(a: SummarySuggestion | PlainMessage<SummarySuggestion> | undefined, b: SummarySuggestion | PlainMessage<SummarySuggestion> | undefined): boolean {
    return proto3.util.equals(SummarySuggestion, a, b);
  }
}

/**
 * @generated from message protogen.Suggestions
 */
export class Suggestions extends Message<Suggestions> {
  /**
   * @generated from field: bool hasSuggestions = 1;
   */
  hasSuggestions = false;

  /**
   * @generated from field: int64 maxCreatedSec = 2;
   */
  maxCreatedSec = protoInt64.zero;

  /**
   * @generated from field: repeated protogen.TaskSuggestion tasks = 3;
   */
  tasks: TaskSuggestion[] = [];

  /**
   * @generated from field: repeated protogen.FactSuggestion facts = 4;
   */
  facts: FactSuggestion[] = [];

  /**
   * @generated from field: repeated protogen.EventSuggestion events = 5;
   */
  events: EventSuggestion[] = [];

  /**
   * @generated from field: repeated protogen.RecommendationsSuggestions recommendations = 6;
   */
  recommendations: RecommendationsSuggestions[] = [];

  /**
   * @generated from field: repeated protogen.WebResearchSuggestion webResearch = 7;
   */
  webResearch: WebResearchSuggestion[] = [];

  /**
   * @generated from field: repeated protogen.TaskRequirement taskRequirements = 8;
   */
  taskRequirements: TaskRequirement[] = [];

  /**
   *  repeated EventSuggestion actions = 4;
   *
   * @generated from field: protogen.SummarySuggestion summary = 9;
   */
  summary?: SummarySuggestion;

  constructor(data?: PartialMessage<Suggestions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Suggestions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hasSuggestions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "maxCreatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "tasks", kind: "message", T: TaskSuggestion, repeated: true },
    { no: 4, name: "facts", kind: "message", T: FactSuggestion, repeated: true },
    { no: 5, name: "events", kind: "message", T: EventSuggestion, repeated: true },
    { no: 6, name: "recommendations", kind: "message", T: RecommendationsSuggestions, repeated: true },
    { no: 7, name: "webResearch", kind: "message", T: WebResearchSuggestion, repeated: true },
    { no: 8, name: "taskRequirements", kind: "message", T: TaskRequirement, repeated: true },
    { no: 9, name: "summary", kind: "message", T: SummarySuggestion },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Suggestions {
    return new Suggestions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Suggestions {
    return new Suggestions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Suggestions {
    return new Suggestions().fromJsonString(jsonString, options);
  }

  static equals(a: Suggestions | PlainMessage<Suggestions> | undefined, b: Suggestions | PlainMessage<Suggestions> | undefined): boolean {
    return proto3.util.equals(Suggestions, a, b);
  }
}

