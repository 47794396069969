import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { Box, Typography, TextField, IconButton, Button } from "@mui/material";
import { BlockHandle, BlockProps, usePollForBlockUpdates } from "./utils";
import BlockContainer from "./BlockContainer";
import BlockGutterMap from "./BlockGutterMap";
import DocumentViewer from "../../editor/DocumentViewer";
import DateDisplay from "../../common/DateDisplay";
import { TaskBlock, TaskBlockStatus } from "protogen/tasks_pb";
import { InsertableSuggestion } from "../../editor/extensions/utils";
import { ReactComponent as EllipseIcon } from "icons/Menu/Ellipse.svg";
import DocumentEditor, {
  Handle as DocumentHandle,
} from "../../editor/DocumentEditor";
import useAutoSave from "../../editor/useAutoSave";
import {
  useUpdateBlockContent,
  useUpdateMemberBlockContent,
  useCreateEmailDraftFromDocumentBlock,
  useUpdateBlockMetadata,
  useUpdateTaskBlockStatusRequest,
  useUpdateMemberTaskBlockStatusRequest,
  useUpdateTasksBookmarks,
} from "services/tasks";
import EmailCompose from "../../email/EmailComposeDialog";
import { EmailMessage } from "protogen/conversation_pb";
import { EllipsisVertical } from "lucide-react";
import SimpleDocument from "../SimpleDocument";
import { RichContent } from "components/editor/utils";
import { CurrentUserContext } from "components/context/RequireAuth";
import PopperMenu, { createMenuOption } from "../../common/PopperMenu";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../../common/ConfirmationDialog";
import { stripImgSrc } from "../../../common/utils";
import { toMaybeNumber } from "common/utils";
import BlockLoadingState from "./BlockLoadingState";
import BlockConfirmation from "./BlockConfirmation";
import { Medium } from "../../../protogen/advisors_service_pb";

const Header = ({
  block,
  toggleEdit,
  isEditMode,
  isShared,
  onEmail,
  title,
  onTitleChange,
  accountType = "advisor",
  isRichDocument = false,
  onDelete,
  isProcessing = false,
}: {
  block: TaskBlock;
  toggleEdit: () => void;
  isEditMode: boolean;
  isShared: boolean;
  onEmail: () => void;
  title?: string;
  onTitleChange?: (title: string) => void;
  accountType?: "advisor" | "member";
  isRichDocument?: boolean;
  onDelete?: () => void;
  isProcessing?: boolean;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const currentUserRef = currentUser.ref;
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const anchorRef = useRef<HTMLButtonElement>(null);
  const metadata = block.metadata ? JSON.parse(block.metadata) : {};
  const actionMenu = ({
    edit = false,
    email = false,
    remove = false,
  }: {
    edit?: boolean;
    email?: boolean;
    remove?: boolean;
  }) => {
    const options = [];
    if (edit) {
      options.push(
        createMenuOption(
          "Edit",
          () => {
            toggleEdit();
            handleClose();
          },
          "pencil",
        ),
      );
    }
    if (email && !isProcessing) {
      options.push(
        createMenuOption(
          "Send as email",
          () => {
            onEmail();
            handleClose();
          },
          "mail",
        ),
      );
    }
    if (remove) {
      options.push(
        createMenuOption(
          "Delete",
          () => {
            onDelete && onDelete();
            handleClose();
          },
          "trash",
        ),
      );
    }
    return (
      <Box>
        <IconButton ref={anchorRef} onClick={handleToggle}>
          <EllipsisVertical size={20} color="#8E9598" />
        </IconButton>
        <PopperMenu
          open={open}
          anchorRef={anchorRef}
          handleClose={() => {
            setOpen(false);
          }}
        >
          {options}
        </PopperMenu>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      {isRichDocument ? (
        <Box sx={{ width: "100%", paddingBottom: "2px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <Typography
              variant="bodyHeavy"
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
              gap={"4px"}
              sx={{
                color: "text.secondary",
                "svg circle": { fill: "#B3AFB6" },
              }}
            >
              Document
              {accountType === "advisor" && metadata.isDeepResearch ? (
                <>
                  <EllipseIcon height={4} width={4} /> Deep Research
                </>
              ) : (
                ""
              )}
            </Typography>
            {accountType === "advisor" &&
              actionMenu({ email: true, remove: true })}
          </Box>
          <Box>
            {accountType === "advisor" && !isProcessing ? (
              <TextField
                variant="standard"
                fullWidth
                multiline
                placeholder="Untitled document"
                value={title}
                onChange={(e) => {
                  onTitleChange && onTitleChange(e.target.value);
                }}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  sx: (theme) => ({
                    ...theme.typography.h3,
                  }),
                }}
              />
            ) : (
              <Typography variant="h3" color="#3D3D3D">
                {title}
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography variant="bodyHeavy" color="#3D3D3D">
            {isShared ? `${block?.createdBy?.displayName}` : "Private note"}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {!isRichDocument && !isEditMode && (
          <DateDisplay date={new Date(Number(block?.createdSec) * 1000)} />
        )}
        {!isRichDocument &&
          !isEditMode &&
          currentUserRef === block?.createdBy?.ref &&
          actionMenu({
            edit: true,
            remove: true,
          })}
      </Box>
    </Box>
  );
};

export interface Handle extends BlockHandle {
  insertSuggestion: (s: InsertableSuggestion) => void;
}

export default forwardRef<Handle, BlockProps>(
  (
    {
      task,
      block: initialBlock,
      onUpdate,
      accountType,
      onDeleteTaskBlock,
    }: BlockProps,
    ref,
  ) => {
    const initialMetadata = initialBlock.metadata
      ? JSON.parse(initialBlock.metadata)
      : {};
    const isFirstRender = useRef(true);
    const isRichDocument = initialMetadata.isRichDocument;
    const [title, setTitle] = useState(initialMetadata.title || "");
    const [updatedBlock, setUpdatedBlock] = useState<TaskBlock | null>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShared, setIsShared] = useState(initialMetadata.isShared);
    const [emailComposeOpen, setEmailComposeOpen] = useState(false);
    const [emailDraft, setEmailDraft] = useState<EmailMessage | undefined>(
      undefined,
    );
    const { request: updateBookmarks } = useUpdateTasksBookmarks();
    const { block, restart: startPolling } = usePollForBlockUpdates({
      initialBlock,
      isComplete: (b: TaskBlock) =>
        JSON.parse(b.metadata).isProcessing === false,
      onCompleted: (b: TaskBlock) => {
        const meta = b.metadata ? JSON.parse(b.metadata) : {};
        setIsShared(meta.isShared);
        setTitle(meta.title || "");
      },
      pollingSleep: 5000,
      maxIterations: 120,
    });
    const updateBlockContentRequest =
      accountType === "advisor"
        ? useUpdateBlockContent
        : useUpdateMemberBlockContent;
    const [updating, setUpdating] = useState(false);
    const { request, error } = updateBlockContentRequest((r) => {
      setUpdatedBlock(r.block);
    });
    const confirmState = useConfirmationDialog();
    const { request: updateBlockMetadata } = useUpdateBlockMetadata();
    const updateBlockStatusRequest =
      accountType === "advisor"
        ? useUpdateTaskBlockStatusRequest
        : useUpdateMemberTaskBlockStatusRequest;
    const { request: updateBlockStatus } = updateBlockStatusRequest();
    const [hovering, setHovering] = useState(false);
    const metadata = block.metadata ? JSON.parse(block.metadata) : {};

    const {
      request: createEmailDraftFromDocumentBlock,
      error: createEmailDraftError,
    } = useCreateEmailDraftFromDocumentBlock(async (r) => {
      if (accountType === "member") return;
      const draft = new EmailMessage({
        ...r.email,
        subject: title,
      });
      setEmailDraft(draft);
      setEmailComposeOpen(true);
    });
    const initialContent = updatedBlock?.content || block.content;
    const contentType = block.contentType;
    const content =
      initialContent && contentType === "json"
        ? JSON.parse(initialContent)
        : initialContent;
    const documentEditorRef = useRef<DocumentHandle | null>(null);
    useImperativeHandle(ref, () => ({
      insertSuggestion: (s: InsertableSuggestion) =>
        documentEditorRef.current?.insertSuggestion(s),
    }));

    const toggleEdit = () => {
      setIsEditMode(!isEditMode);
    };

    const deleteBlock = ({ block }: { block: TaskBlock }) => {
      confirmState.openDialog(() => {
        updateBlockStatus({
          taskBlockRef: block.ref,
          status: TaskBlockStatus.DELETED,
        });
        onDeleteTaskBlock && onDeleteTaskBlock(block.ref);
      });
    };

    const onSimpleUpdate = async (
      content: RichContent,
      isShareable: boolean,
    ) => {
      await request({
        taskBlockRef: block.ref,
        content: stripImgSrc(content.html),
        contentType: "html",
        textContent: content.text,
        attachments: content.attachments,
      });

      updateBlockMetadata({
        taskBlockRef: block.ref,
        key: "isShared",
        value: JSON.stringify(isShareable),
      });
      setIsEditMode(false);
      setIsShared(isShareable);
    };

    useEffect(() => {
      if (isFirstRender.current || !title) {
        isFirstRender.current = false;
        return;
      }
      updateBlockMetadata({
        taskBlockRef: block.ref,
        key: "title",
        value: JSON.stringify(title),
      });
    }, [title]);

    useEffect(() => {
      const meta = initialBlock.metadata
        ? JSON.parse(initialBlock.metadata)
        : {};
      if (meta.isProcessing) {
        startPolling();
      }
    }, [initialBlock]);

    const { setEditorContent, editorContent } = useAutoSave(
      block.content || null,
      toMaybeNumber(block?.lastUpdatedSec),
      async (c) => {
        const resp = await request({
          taskBlockRef: block.ref,
          content: stripImgSrc(c.html),
          contentType: "html",
          textContent: c.text,
          attachments: c.attachments,
        });
        if (resp) {
          onUpdate && onUpdate(resp?.block?.lastUpdatedSec || BigInt(0));
          setUpdatedBlock(resp.block);
        }
      },
      !!error,
      1500,
      "html",
    );
    const isProcessing = metadata.isProcessing;
    const mainContent = () => {
      if (metadata.isProcessing && metadata.processingStates) {
        return (
          <BlockLoadingState
            states={(metadata.processingStates || []) as string[]}
            actionTitle={"Notify me when done"}
            onAction={
              metadata.notifyOnCompletion
                ? undefined
                : () => {
                    updateBlockMetadata({
                      taskBlockRef: block.ref,
                      key: "notifyOnCompletion",
                      value: JSON.stringify(true),
                    });
                  }
            }
          />
        );
      }
      if (metadata.isDeepResearch && !metadata.confirmed) {
        return (
          <BlockConfirmation
            onConfirm={async () => {
              await updateBlockMetadata({
                taskBlockRef: block.ref,
                key: "confirmed",
                value: JSON.stringify(true),
              });
              // kick off a refresh
              startPolling();
            }}
          />
        );
      }

      if (isRichDocument && accountType === "advisor") {
        return (
          <DocumentEditor
            ref={documentEditorRef}
            setContent={setEditorContent}
            passiveEditor={false}
            placeholder={"Write something..."}
            initialContent={content}
            attachmentsEnabled={true}
            initialAttachments={block.attachments}
            editorDefaultFontSize={"15px"}
            placement={"top"}
            setDragState={setHovering}
            sx={{ marginTop: "0px", paddingBottom: "20px" }}
            editorMaxHeight={"calc(100vh - 210px)"}
            scrollable={true}
            linkUnfurlEnabled={true}
          />
        );
      } else if (isEditMode) {
        return (
          <SimpleDocument
            cacheKey={`task-comment-${block.ref}`}
            isCreate={false}
            onSaveDocument={async (content, isShareable) => {
              onSimpleUpdate(content, isShareable);
            }}
            initialContent={block.content}
            initialAttachments={block.attachments}
            isShared={isShared}
            isEditMode={isEditMode}
            onCancel={() => {
              setIsEditMode(false);
            }}
          />
        );
      } else {
        return (
          <DocumentViewer
            content={content}
            attachments={updatedBlock?.attachments || block.attachments}
            minHeight="29px"
            sx={{ padding: "0" }}
          />
        );
      }
    };
    const getGutterIcon = () => {
      if (metadata.isDeepResearch && !isShared) {
        return BlockGutterMap.deepResearch.icon;
      } else if (isRichDocument) {
        return BlockGutterMap.document.icon;
      } else {
        return BlockGutterMap.message.icon({ user: block.createdBy });
      }
    };
    return (
      <BlockContainer
        gutterIcon={getGutterIcon()}
        advisorViewOnly={!isShared}
        visibilityCTA={
          !isShared &&
          !isProcessing &&
          !(metadata.isDeepResearch && !metadata.confirmed) &&
          isRichDocument && (
            <Button
              variant="text"
              disabled={updating}
              sx={{
                fontSize: "16px",
                height: "19px",
                lineHeight: "19px",
              }}
              onClick={async () => {
                setUpdating(true);
                if (editorContent) {
                  await request({
                    taskBlockRef: block.ref,
                    content: stripImgSrc(editorContent.html),
                    contentType: "html",
                    textContent: editorContent.text,
                    attachments: editorContent.attachments,
                  });
                }
                const resp = await updateBlockMetadata({
                  taskBlockRef: block.ref,
                  key: "isShared",
                  value: JSON.stringify(!isShared),
                });
                onUpdate &&
                  onUpdate(
                    resp?.block?.lastUpdatedSec || BigInt(0),
                    "Published! Document has been sent to the client.",
                  );
                setIsShared(!isShared);
                setUpdating(false);
              }}
            >
              Publish
            </Button>
          )
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "16px 24px",
            gap: "4px",
            ...(hovering ? { backgroundColor: "#e8f4f8" } : {}),
          }}
        >
          <Header
            block={block}
            toggleEdit={toggleEdit}
            isEditMode={isEditMode}
            isShared={isShared}
            onEmail={() => {
              createEmailDraftFromDocumentBlock({
                taskBlockRef: block.ref,
              });
            }}
            title={title}
            onTitleChange={setTitle}
            accountType={accountType}
            isRichDocument={isRichDocument}
            onDelete={() => {
              deleteBlock({ block });
            }}
            isProcessing={isProcessing}
          />
          {mainContent()}
        </Box>
        <EmailCompose
          closed={!emailComposeOpen}
          onClose={() => {
            setEmailComposeOpen(false);
          }}
          onSent={async () => {
            // Bookmark the email to this task.
            await updateBookmarks({
              bookmarks: [
                {
                  taskRef: task.ref,
                  entityType: Medium.EMAIL.toString(),
                  entityRef: emailDraft?.ref || "",
                  add: true,
                  remove: false,
                },
              ],
            });
          }}
          onRemove={() => {}}
          initialDraft={emailDraft}
        />
        {createEmailDraftError && (
          <Typography color="secondary.block">
            {createEmailDraftError}
          </Typography>
        )}
        <ConfirmationDialog
          // Make dynamic for docs
          content={
            isRichDocument
              ? "Are you sure you want to remove this document?"
              : "Are you sure you want to remove this comment?"
          }
          {...confirmState.dialogProps}
        />
      </BlockContainer>
    );
  },
);
