import { forwardRef, useImperativeHandle } from "react";
import { ChoiceSelections, StepProps, StepRef } from "../../types";
import ChoiceStep from "../../components/ChoiceStep";
import {
  Hammer,
  MountainSnow,
  Music,
  PartyPopper,
  Plane,
  Utensils,
} from "lucide-react";

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }: StepProps, ref) => {
    useImperativeHandle(ref, () => ({}));
    return (
      <ChoiceStep
        title={"What does your family do for fun?"}
        subtitle={"Select as many as you'd like."}
        disabled={disabled}
        columns={3}
        skippable={true}
        choiceOptions={[
          {
            id: "travel",
            text: "Travel and explore new places",
            icon: <Plane />,
          },
          {
            id: "host-parties",
            text: "Host gatherings and parties",
            icon: <PartyPopper />,
          },
          {
            id: "cook",
            text: "Cook and try new recipes",
            icon: <Utensils />,
          },
          {
            id: "outdoors",
            text: "Explore the outdoors",
            icon: <MountainSnow />,
          },
          {
            id: "shows",
            text: "Go to museums, shows, or concerts",
            icon: <Music />,
          },
          {
            id: "home-improvement",
            text: "Work on home improvement",
            icon: <Hammer />,
          },
          {
            id: "other",
            text: "Something else...",
            freeform: true,
            fullWidth: true,
          },
        ]}
        onNext={async (choices?: ChoiceSelections) => {
          return onNext?.({ fun: choices });
        }}
        initialChoices={signupState?.fun || undefined}
      />
    );
  },
);
