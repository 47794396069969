import React from "react";
import { Box } from "@mui/material";
import { GenerateReportResponse, Report } from "protogen/reports_service_pb";
import {
  LineChart,
  BarChart,
  GaugeContainer,
  GaugeReferenceArc,
  GaugeValueArc,
} from "@mui/x-charts";
import GaugePointer from "./GaugePointer";

// Default colors array
const defaultColors = [
  "#198282",
  "#EF7B77",
  "#996A8A",
  "#D56887",
  "#6092A8",
  "#F59F51",
  "#468E3E",
  "#C14743",
];

interface ChartData {
  columns: string[];
  rows: { [key: string]: string | number }[];
}

interface SeriesConfig {
  yAxisKey: string; // The key from the data for the Y-axis
  xAxisKey?: string; // Optional, for charts that might need it per series
  label?: string; // Optional label for the series
  valueFormatter?: (value: number | null) => string; // Optional value formatter for the series
  color?: string; // Optional color for the series
}

interface AxisConfig {
  key: string;
  label?: string;
  valueFormatter?: (value: any) => string;
  scaleType?: "linear" | "band" | "time" | "log" | "pow" | "sqrt" | "point"; // Incomplete
  tickPlacement?: "end" | "start" | "middle" | "extremities" | undefined;
  tickLabelPlacement?: "middle" | "tick" | undefined;
}

interface ReportChartProps {
  data: ChartData;
  chartType: "bar" | "line" | "pie" | "gauge"; // Add other types as needed
  xAxis?: AxisConfig;
  yAxis?: AxisConfig;
  series: SeriesConfig[]; // An array of series configurations
  title?: string; // Optional title for the chart
  width?: number;
  height?: number;
}

const _ReportChart = ({
  data,
  chartType,
  xAxis,
  yAxis,
  series,
  title,
  height = 400,
}: ReportChartProps) => {
  // Keep track of used colors
  let colorIndex = 0;

  let ChartComponent;
  let chartProps: any = {
    height: height,
  };

  switch (chartType) {
    case "bar":
    case "line":
      // Extract the X-axis labels from the data
      const xAxisLabels = data.rows.map(
        (row) => row[xAxis?.key || ""] as string,
      );

      // Generate the series data for the chart
      const chartSeries = series.map((s) => {
        const dataPoints = data.rows.map((row) => row[s.yAxisKey] as number);
        // Assign color if not defined
        const color =
          s.color || defaultColors[colorIndex % defaultColors.length];
        colorIndex++;
        return {
          data: dataPoints,
          label: s.label || s.yAxisKey,
          valueFormatter: s.valueFormatter,
          color: color,
        };
      });

      // Define the axis configurations with correct typings
      const xAxisConfig = xAxis
        ? [
            {
              data: xAxisLabels,
              label: xAxis.label || xAxis.key,
              scaleType: xAxis.scaleType || "band",
              tickPlacement: xAxis.tickPlacement,
              tickLabelPlacement: xAxis.tickLabelPlacement,
              valueFormatter: xAxis.valueFormatter,
            },
          ]
        : undefined;

      const yAxisConfig = yAxis
        ? [
            {
              label: yAxis.label || yAxis.key,
              scaleType: yAxis.scaleType || "linear",
              valueFormatter: yAxis.valueFormatter,
            },
          ]
        : undefined;

      ChartComponent = chartType === "bar" ? BarChart : LineChart;

      chartProps = {
        ...chartProps,
        series: chartSeries,
        xAxis: xAxisConfig,
        yAxis: yAxisConfig,
      };
      break;
    case "gauge":
      if (data.rows.length !== 1) {
        throw new Error("Gauge chart requires exactly one row of data.");
      }
      const row = data.rows[0];

      if (!xAxis?.key || !row[series[0].yAxisKey]) {
        throw new Error("Gauge chart values must be numbers.");
      }
      const numerator = parseFloat(row[xAxis.key].toString());
      const denominator = parseFloat(row[series[0].yAxisKey].toString());
      const formatted = (v: number) => {
        // Note - we should do non-financial values here too.
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }).format(v);
      };
      return (
        <GaugeContainer
          // width={200}
          // height={200}
          {...chartProps}
          startAngle={-110}
          endAngle={110}
          value={(numerator / denominator) * 100}
        >
          <GaugeReferenceArc />
          <GaugeValueArc />
          <GaugePointer />
          <text
            x="50%"
            y="90%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="24"
            fontWeight="500"
          >
            {`${formatted(numerator)} of ${formatted(denominator)}`}
          </text>
        </GaugeContainer>
      );

    // Untested!
    // case "pie":
    //   ChartComponent = PieChart;
    //   // For PieChart, data needs to be restructured
    //   const pieData = data.rows.map((row, index) => ({
    //     id: index,
    //     value: row[series[0].yAxisKey] as number,
    //     label: row[xAxis?.key || ""] as string,
    //   }));
    //
    //   // Assign colors to the pie slices
    //   const colors = series[0].color
    //     ? [series[0].color]
    //     : defaultColors.slice(0, pieData.length);
    //
    //   chartProps = {
    //     ...chartProps,
    //     data: pieData,
    //     series: [
    //       {
    //         dataKey: "value",
    //         labelKey: "label",
    //         valueFormatter: series[0].valueFormatter,
    //         colors: colors,
    //       },
    //     ],
    //   } as PieChartProps;
    //   break;

    default:
      ChartComponent = BarChart;
      chartProps = {
        ...chartProps,
        // Include series, xAxis, yAxis as in 'bar' and 'line' case
      };
  }

  return (
    <Box sx={{ width: "100%" }}>
      {title && <h3>{title}</h3>}
      <ChartComponent {...chartProps} />
    </Box>
  );
};

export const ReportChart = ({
  report,
  reportData,
}: {
  report: Report;
  reportData: GenerateReportResponse;
}) => {
  const columnDefinitionsMap = new Map(
    report.columns.map((column) => [column.name, column]),
  );
  const columns = reportData.columns.map((column) => column.name);
  const rows = reportData.rows.map((row, rowIndex) => {
    const rowData: { id: number } & { [key: string]: any } = { id: rowIndex };
    row.values.forEach((value, colIndex) => {
      rowData[columns[colIndex]] = value;
    });
    return rowData;
  });

  const formatterForColumn = (column: string) => {
    const columnDefinition = columnDefinitionsMap.get(column);
    if (!columnDefinition) return undefined;
    if (columnDefinition.type === "percentage") {
      return (value: number | null) =>
        value !== null ? `${(100 * Number(value)).toFixed(1)}%` : "";
    } else if (columnDefinition.type === "currency") {
      return (value: number | null) =>
        value !== null
          ? Number(value).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : "";
    }
    return undefined;
  };

  // Prepare the data for the chart
  const data = {
    columns: columns,
    rows: rows,
  };
  if (
    !(
      report?.chartDefinition?.type &&
      new Set(["bar", "line", "pie", "gauge"]).has(
        report?.chartDefinition?.type,
      ) &&
      report?.chartDefinition?.xAxisKey &&
      report?.chartDefinition?.columnSeries
    )
  )
    return null;
  // @ts-ignore
  const chartType: "bar" | "line" | "pie" | "gauge" =
    report.chartDefinition.type;
  return (
    <div>
      <_ReportChart
        data={data}
        chartType={chartType}
        xAxis={{
          key: report?.chartDefinition?.xAxisKey,
          label: report?.chartDefinition?.xAxisKey,
          tickLabelPlacement: "middle",
          tickPlacement: "middle",
          scaleType: "band",
        }}
        yAxis={{
          key: "Values",
          label: "Values",
          scaleType: "linear",
        }}
        title={report.name}
        series={report.chartDefinition.columnSeries.map((series) => ({
          yAxisKey: series,
          label: series,
          valueFormatter: formatterForColumn(series),
        }))}
      />
    </div>
  );
};
