import React, { useContext, useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as FayeIcon } from "../icons/FayeBrand.svg";
import AuthService from "services/auth";
import useIsMobile from "components/hooks/useIsMobile";
import { FayeAppBar } from "components/layout/NavigationLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { SWRegistrationContext } from "components/context/AddServiceWorker";
import LinkRouter from "components/navigation/LinkRouter";

interface FormValues {
  username: string;
  password: string;
}

export default () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { subscription } = useContext(SWRegistrationContext);
  // Default to the value in the local state, then the intended path, then null.
  const intendedPath = queryParams.get("destination");
  let from = location.state?.from?.pathname || intendedPath || "/";
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    username: decodeURIComponent(queryParams.get("username") || ""),
    password: "",
  });
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    // If this device is already subscribed to notifications, we should send that along.
    const subscriptionString = subscription
      ? JSON.stringify(subscription.toJSON())
      : null;
    AuthService.login(
      formValues.username,
      formValues.password,
      "advisor",
      subscriptionString,
    ).then(
      () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
        setLoading(false);
        setError(false);
        // navigate(0); // Note - this still is stored ephemerally rather than persisted in the URL.
        // window.location.reload();
      },
      () => {
        setLoading(false);
        setError(true);
      },
    );
  };

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      navigate(intendedPath || "/");
    }
  }, []);

  return (
    <>
      <FayeAppBar />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "500px",
            padding: "0px 28px",
            alignSelf: "stretch",
          }}
        >
          {!isMobile && (
            <div>
              <FayeIcon
                style={{
                  width: "112.762px",
                  height: "64px",
                  flexShrink: "0",
                  marginBottom: "29px",
                }}
              />
            </div>
          )}
          <Typography
            variant="h2Serif"
            sx={{
              marginBottom: "8px",
            }}
          >
            Sign in to Faye
          </Typography>
          {/*<Typography*/}
          {/*  variant="body"*/}
          {/*  sx={{*/}
          {/*    marginBottom: "32px",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Your business awaits.*/}
          {/*</Typography>*/}
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {error && (
              <Alert
                icon={false}
                severity="error"
                sx={{
                  alignSelf: "stretch",
                  marginBottom: "12px",
                  borderRadius: "12px",
                  border: "2px solid #FAD3D0",
                  background: "#FFFBFA",
                }}
              >
                Username or password is incorrect
              </Alert>
            )}
            <FormControl fullWidth style={{ marginTop: 16 }} disabled={loading}>
              <TextField
                sx={{ flexGrow: 1 }}
                disabled={loading}
                autoFocus
                label="Username"
                type="email"
                error={error}
                name="username"
                autoComplete="on"
                value={formValues.username}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 16 }} disabled={loading}>
              <TextField
                sx={{ flexGrow: 1 }}
                disabled={loading}
                error={error}
                label="Password"
                name="password"
                type="password"
                autoComplete="on"
                value={formValues.password}
                onChange={handleInputChange}
              />
            </FormControl>
            <Button
              disabled={loading}
              type="submit"
              fullWidth
              style={{ marginTop: "12px" }}
            >
              Sign in
            </Button>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <LinkRouter
                to={`/forgot-password${
                  formValues.username
                    ? `?username=${encodeURIComponent(formValues.username)}`
                    : ""
                }`}
              >
                <Button variant="text" sx={{ mt: 1, mr: 1 }}>
                  Forgot password?
                </Button>
              </LinkRouter>
            </Box>
            <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
            >
              <Button
                variant="text"
                sx={{ mt: 1, mr: 1 }}
                onClick={() => {
                  window.location.href = `https://app.findfaye.com/member-login${
                    formValues.username
                      ? `?email=${encodeURIComponent(formValues.username)}`
                      : ""
                  }`;
                }}
              >
                Are you a Faye client? Log in here
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
