import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Pencil, Trash2 } from "lucide-react";
import ReactiveDialog from "./ReactiveDialog";
import { FormErrors } from "../family/types";
import {
  EntityMetadata,
  EntityMetadata_EntityMetadataField,
} from "../../protogen/common_pb";
import { PlainMessage } from "@bufbuild/protobuf";

const AUTOCOMPLETE_MAPPING: {
  [key: string]: {
    [key: string]: string[];
  };
} = {
  family: {
    channel: [
      "Connector/Influencer Program (Gifted Service)",
      "Direct Sales",
      "FA Direct",
      "Influencer",
      "LinkedIn",
      "Local Marketing",
      "Loop + Tie",
      "Other/Unattributed",
      "Paid Media",
      "Podcast",
      "Realtor Partnerships",
      "WOM/Organic Social",
    ],
    referrer: [],
    owner: ["Emily", "Jackie", "Liz"],
  },
  advisor: {
    channel: ["Paid media", "LinkedIn", "Referral", "Other"],
  },
};

const fromProto = (meta: EntityMetadata) => {
  return Object.fromEntries(
    meta.fields.map((field: EntityMetadata_EntityMetadataField) => [
      field.key,
      field.value,
    ]),
  );
};
const toProto = (obj: { [key: string]: string }) => {
  return {
    fields: Object.entries(obj).map(([key, value]) => ({
      key,
      value,
    })),
  };
};

type MetadataRow = {
  ind: number;
  key: string;
  value: string;
  deleted: boolean;
};

const EditMetadataModal = ({
  metadata,
  handleSave,
  open,
  handleClose,
  entityType,
}: {
  metadata: { [key: string]: string };
  handleSave: (metadata: { [key: string]: string }) => Promise<void>;
  open: boolean;
  handleClose: () => void;
  entityType?: "family" | "advisor";
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors | null>(null);
  const [editableMetadata, setEditableMetadata] = useState<MetadataRow[]>(
    Object.keys(metadata).length
      ? Object.entries(metadata).map(([key, value], ind) => ({
          ind,
          key,
          value,
          deleted: false,
        }))
      : [
          {
            ind: 0,
            key: "",
            value: "",
            deleted: false,
          },
        ],
  );

  const handleAddField = () => {
    setEditableMetadata((v) => [
      ...v,
      {
        ind: v.length,
        key: "",
        value: "",
        deleted: false,
      },
    ]);
  };

  const handleDeleteField = (key: string) => {
    setEditableMetadata((v) =>
      v.map((m) => (m.key === key ? { ...m, deleted: true } : m)),
    );
  };

  const validateMetadata = () => {
    const errors: FormErrors = {};
    const seenKeys = new Set<string>();
    editableMetadata.forEach((row) => {
      if (row.deleted) return;
      if (!row.key || !row.value) {
        errors[row.key] = "Key and value are required";
      }
      if (seenKeys.has(row.key)) {
        errors[row.key] = "Duplicate key";
      }
      seenKeys.add(row.key);
    });
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSave = async () => {
    if (!validateMetadata()) return;
    setLoading(true);
    await handleSave(
      Object.fromEntries(
        editableMetadata.filter((m) => !m.deleted).map((m) => [m.key, m.value]),
      ),
    );
    setLoading(false);
    handleClose();
  };

  return (
    <ReactiveDialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      title="Edit Metadata"
      primaryActionName="Save"
      primaryAction={onSave}
      primaryActionEnabled={!loading}
    >
      {editableMetadata
        .filter((e) => !e.deleted)
        .map((entry) => (
          <Box
            key={entry.ind}
            sx={{ display: "flex", alignItems: "center", gap: "8px", mb: 2 }}
          >
            {/* KEY Autocomplete */}
            <Autocomplete
              freeSolo
              options={
                entityType && entityType in AUTOCOMPLETE_MAPPING
                  ? Object.keys(AUTOCOMPLETE_MAPPING[entityType]).filter(
                      (k) => !editableMetadata.map((m) => m.key).includes(k),
                    )
                  : []
              }
              fullWidth
              value={entry.key}
              onChange={(_, newValue) => {
                setEditableMetadata((v) =>
                  v.map((m) =>
                    m.ind === entry.ind ? { ...m, key: newValue || "" } : m,
                  ),
                );
              }}
              onInputChange={(_, newInputValue) => {
                // If user types a new value, record it
                setEditableMetadata((v) =>
                  v.map((m) =>
                    m.ind === entry.ind ? { ...m, key: newInputValue } : m,
                  ),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Key"
                  error={!!errors?.[entry.key]}
                  fullWidth
                />
              )}
            />

            {/* VALUE Autocomplete */}
            <Autocomplete
              freeSolo
              fullWidth
              options={
                entityType &&
                entityType in AUTOCOMPLETE_MAPPING &&
                entry.key in AUTOCOMPLETE_MAPPING[entityType]
                  ? AUTOCOMPLETE_MAPPING[entityType][entry.key]
                  : []
              }
              value={entry.value}
              onChange={(_, newValue) => {
                setEditableMetadata((v) =>
                  v.map((m) =>
                    m.ind === entry.ind ? { ...m, value: newValue || "" } : m,
                  ),
                );
              }}
              onInputChange={(_, newInputValue) => {
                setEditableMetadata((v) =>
                  v.map((m) =>
                    m.ind === entry.ind ? { ...m, value: newInputValue } : m,
                  ),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Value"
                  error={!!errors?.[entry.key]}
                  fullWidth
                />
              )}
            />

            <IconButton onClick={() => handleDeleteField(entry.key)}>
              <Trash2 height="20px" width="20px" stroke="#198282" />
            </IconButton>
          </Box>
        ))}
      <Button variant="text" onClick={handleAddField}>
        + Add another item
      </Button>
    </ReactiveDialog>
  );
};

type Props = {
  metadata: EntityMetadata;
  saveMetadata: (
    metadata: EntityMetadata | PlainMessage<EntityMetadata>,
  ) => Promise<void>;
  entityType?: "family" | "advisor";
};

export default ({ metadata, saveMetadata, entityType }: Props) => {
  const [localMetadata, setLocalMetadata] = useState<{
    [key: string]: string;
  }>(fromProto(metadata));

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLocalMetadata(fromProto(metadata));
  }, [metadata]);

  const handleSave = async (meta: { [key: string]: string }) => {
    await saveMetadata(toProto(meta));
    setLocalMetadata(meta);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="bodyHeavy">Metadata</Typography>
        <IconButton onClick={() => setOpen(true)}>
          <Pencil size={20} />
        </IconButton>
      </Box>
      {Object.keys(localMetadata).length === 0 ? (
        <Box sx={{ border: "1px dashed", padding: "16px" }}>
          <Typography>No metadata</Typography>
        </Box>
      ) : (
        <Box sx={{ border: "1px dashed", padding: "16px" }}>
          {Object.entries(localMetadata).map(([key, value]) => (
            <Typography key={key}>
              <strong>{key}:</strong> {value}
            </Typography>
          ))}
        </Box>
      )}
      <EditMetadataModal
        metadata={localMetadata}
        handleSave={handleSave}
        open={open}
        handleClose={() => setOpen(false)}
        entityType={entityType}
      />
    </Box>
  );
};
