import { Document, DocumentType, DocumentScope } from "protogen/documents_pb";
import { FactStub } from "protogen/facts_pb";
import { dateObjectToshortDate } from "common/utils";
import { previewTextContact } from "components/facts/forms/ContactForm";

export const createEphemeralFactDocument = (
  fact: FactStub,
  type: DocumentType,
): Document => {
  return new Document({
    ref: fact.ref,
    title: fact.name,
    documentType: type,
    fact: fact,
  });
};

export const getDocumentTitle = (document: Document): string => {
  if (document.documentType === DocumentType.CALL) {
    return `Call notes on ${dateObjectToshortDate(
      new Date(Number(document.note?.lastEditSec * 1000)),
    )}`;
  } else {
    return document?.title || "Untitled document";
  }
};

const factFamilyFields = ["Location:"];
const factFamilyMemberFields = ["Phone:", "Email:"];

export const getDocumentSubtitle = (document: Document): string => {
  if (document.documentType === DocumentType.FACT_FAMILY) {
    const values = document?.fact?.value
      .split("\n")
      .reduce((acc: string[], line: string) => {
        const match = line.match(
          new RegExp(`^${factFamilyFields[0]}\\s+(.+)$`),
        );
        if (match) {
          acc.push(match[1]);
        }
        return acc;
      }, []);
    return values.join(", ");
  } else if (
    document.documentType === DocumentType.FACT &&
    document.fact?.valueType === "contact"
  ) {
    return previewTextContact(document?.fact?.value);
  } else if (document.documentType === DocumentType.FACT_FAMILY_MEMBER) {
    const values = document?.fact?.value
      .split("\n")
      .reduce((acc: string[], line: string) => {
        for (const field of factFamilyMemberFields) {
          const match = line.match(new RegExp(`^${field}\\s+(.+)$`));
          if (match) {
            acc.push(match[1]);
            break;
          }
        }
        return acc;
      }, []);
    return values.join(", ");
  } else {
    return document?.subtitle || "";
  }
};

export const getScopeLabel = (scope: DocumentScope) => {
  switch (scope) {
    case DocumentScope.PRIVATE:
      return "Private";
    case DocumentScope.INTERNAL:
      return "Viewable by Advisors with link";
    case DocumentScope.INTERNAL_DISCOVERABLE:
      return "Discoverable by any Advisor";
    case DocumentScope.UNSPECIFIED:
      return "";
  }
};

export const getScopeGroupLabel = (scope: DocumentScope) => {
  switch (scope) {
    case DocumentScope.PRIVATE:
      return "Private";
    case DocumentScope.INTERNAL:
      return "Shared";
    case DocumentScope.INTERNAL_DISCOVERABLE:
      return "Shared";
    case DocumentScope.UNSPECIFIED:
      return "";
  }
};

export const isPrivate = (document: Document) => {
  return document.scope === DocumentScope.PRIVATE;
};
