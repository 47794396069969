import { forwardRef, useState } from "react";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import { Info } from "lucide-react";
import useIsMobile from "components/hooks/useIsMobile";
import {
  MEMBERSHIP_OPTIONS,
  MembershipOption,
  StepProps,
  StepRef,
  STEPS,
} from "./../types";
import { PlanFeatures } from "./../components/PlanFeatures";
import { StepHeader } from "../components/StepHeader";

const MembershipOptionCard = ({
  option,
  isSelected,
}: {
  option: MembershipOption;
  isSelected: boolean;
}) => {
  const textColor = isSelected ? "white" : "textPrimary";
  const textSecondaryColor = isSelected ? "white" : "textSecondary";
  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${
          isSelected ? theme.palette.primary.main : theme.palette.border
        }`,
        borderRadius: "16px",
        padding: "19px 26px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: isSelected ? theme.palette.primary.main : "white",
        gap: "16px",
      })}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" color={textColor}>
          {option.name}
        </Typography>
        <Typography variant="body" color={textSecondaryColor}>
          {option.supportHours} hours of support a month
        </Typography>
        <Typography variant="body" color={textSecondaryColor}>
          {option.maxUsers} {option.maxUsers === 1 ? "account" : "accounts"}
        </Typography>
        {option.additionalFeatures && (
          <Box>
            {option.additionalFeatures.map((feature) => (
              <Typography
                variant="body"
                color={textSecondaryColor}
                key={feature}
              >
                {feature}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="h4" color={textColor}>
            ${option.amountCents / 100}
          </Typography>
          <Typography variant="body" color={textColor}>
            /mo
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default forwardRef<StepRef, StepProps>(
  ({ onNext, disabled, signupState }: StepProps, _ref) => {
    const isMobile = useIsMobile();
    const [selectedPlan, setSelectedPlan] = useState<number | null>(
      signupState.membership
        ? MEMBERSHIP_OPTIONS.findIndex(
            (m: MembershipOption) => m.name === signupState.membership?.name,
          )
        : null,
    );

    const handleContinue = async () => {
      if (selectedPlan === null) return;
      await onNext?.({ membership: MEMBERSHIP_OPTIONS[selectedPlan] });
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 8px",
          gap: "16px",
        }}
      >
        <StepHeader
          title="Choose your membership"
          subtitle="Try Faye risk-free for 14 days"
          tooltip={
            <Tooltip
              placement={isMobile ? "bottom" : "bottom-end"}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={
                <Box sx={{ padding: "12px" }}>
                  <Typography variant="bodyHeavy" mb="4px">
                    Happiness guarantee
                  </Typography>
                  <Typography variant="body">
                    If your Advisor isn’t providing the mental load relief you
                    need, let us know and we’ll find a better match.
                  </Typography>
                </Box>
              }
            >
              <Info size={20} color="#8E9598" />
            </Tooltip>
          }
        />

        <Box
          sx={{
            display: "flex",
            gap: isMobile ? "24px" : "48px",
            justifyContent: "center",
            alignSelf: "center",
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          {/* Left side */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
              gap: "12px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                width: "100%",
                minWidth: isMobile ? "none" : "350px",
              }}
            >
              {MEMBERSHIP_OPTIONS.map((option, idx) => {
                return (
                  <Box
                    onClick={() => !disabled && setSelectedPlan(idx)}
                    key={option.name}
                    sx={{ cursor: "pointer" }}
                  >
                    <MembershipOptionCard
                      option={option}
                      isSelected={selectedPlan === idx}
                    />
                  </Box>
                );
              })}
            </Box>
            {!isMobile && (
              <Button
                variant="text"
                disabled={disabled}
                onClick={() =>
                  onNext?.({
                    flowPath: STEPS.CONSULTATION_DETAILS,
                  })
                }
              >
                Not sure? Schedule a consultation
              </Button>
            )}
          </Box>

          {/* Right side */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: "32px",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Typography variant="h4">All plans include</Typography>
              <PlanFeatures />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "end",
                alignItems: "center",
                position: "relative",
                bottom: isMobile ? "0px" : "",
                width: isMobile ? "100%" : "auto",
                backgroundColor: "#FDFAF7",
                paddingBottom: isMobile ? "8px" : "0px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
                disabled={selectedPlan === null || disabled}
                sx={{
                  width: isMobile ? "100%" : "auto",
                  bgcolor: "#198282",
                  "&:hover": {
                    bgcolor: "#276767",
                  },
                  "&.Mui-disabled": { backgroundColor: "#EAEBEC" },
                  borderRadius: "32px",
                  fontSize: "1.15rem",
                  px: 4,
                  textTransform: "none",
                  boxShadow: isMobile
                    ? "0px -1px 20px 5px rgba(255, 255, 255, .2)"
                    : "",
                }}
              >
                Continue
              </Button>
              {isMobile && (
                <Button
                  variant="text"
                  disabled={disabled}
                  onClick={() =>
                    onNext?.({
                      flowPath: STEPS.CONSULTATION_DETAILS,
                    })
                  }
                >
                  Or schedule a consultation
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);
