import React, { useState } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  StaticDatePicker,
  StaticTimePicker,
  TimeView,
} from "@mui/x-date-pickers";
import { StaticTimePickerProps } from "@mui/x-date-pickers/StaticTimePicker";
import {
  PickersLayoutProps,
  usePickerLayout,
  pickersLayoutClasses,
  PickersLayoutRoot,
  PickersLayoutContentWrapper,
} from "@mui/x-date-pickers/PickersLayout";
import { renderMultiSectionDigitalClockTimeView } from "@mui/x-date-pickers/timeViewRenderers";
import useIsMobile from "../hooks/useIsMobile";
import { formatDate } from "../activity/TextMessageCompose";

interface StyledLayoutProps {
  isMobile: boolean;
}

const StyledLayout = styled(PickersLayoutRoot, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<StyledLayoutProps>(({ isMobile }) => ({
  overflow: "auto",
  borderLeft: "solid 1px #0000001f",
  ...(isMobile && {
    ul: {
      width: "113px",
    },
    li: {
      width: "106px",
    },
  }),
  ".MuiMultiSectionDigitalClockSection-root": {
    maxHeight: "unset",
  },
  [`.${pickersLayoutClasses.contentWrapper}`]: {
    "& .MuiClock-root": {
      width: "fit-content",
    },
  },
}));

function CustomLayout(props: PickersLayoutProps<Date | null, Date, TimeView>) {
  const isMobile = useIsMobile();
  const { content } = usePickerLayout(props);
  return (
    <StyledLayout ownerState={props} isMobile={isMobile}>
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        {content}
      </PickersLayoutContentWrapper>
    </StyledLayout>
  );
}

const getDynamicMinTime = (date: Date) => {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0); // Reset today to midnight
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59);

  if (date < startOfDay) {
    // If minDate is in the past, allow time selection until the end of the day
    return new Date(endOfDay);
  } else if (date > endOfDay) {
    // If minDate is in the future, restrict time selection to the start of the day
    return new Date(startOfDay);
  }
  // If minDate is today, use the current minDate
  return new Date();
};

interface DateTimePickerProps {
  disabled?: boolean; // Whether the picker is disabled
  defaultValue: Date; // Current selected date and time
  updateValue: (newValue: Date) => void; // Function to update the date and time
  disablePast?: boolean;
}

export default ({
  disabled,
  defaultValue,
  updateValue,
  disablePast = false,
}: DateTimePickerProps) => {
  const isMobile = useIsMobile();
  const [timeSelected, setTimeSelected] = useState(false);
  // Internal state to manage the selected date and time
  const [value, setValue] = useState<Date>(defaultValue);
  const minDate = disablePast ? new Date() : undefined;
  // Handle changes to the date
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const updatedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        value.getHours(),
        value.getMinutes(),
      );
      if (!minDate || updatedDate >= minDate) {
        setValue(updatedDate);
        updateValue(updatedDate);
      }
    }
  };

  // Handle changes to time (hour, minute, AM/PM)
  const handleTimeChange = (time: Date | null) => {
    if (time) {
      const updatedDate = new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        time.getHours(),
        time.getMinutes(),
      );
      if (!minDate || updatedDate >= minDate) {
        setValue(updatedDate);
        updateValue(updatedDate);
      }
    }
  };

  return (
    <Box>
      {isMobile && (
        <>
          <ButtonGroup
            sx={{
              boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
              justifySelf: "center",
              marginBottom: "16px",
            }}
          >
            <Button
              sx={{
                marginRight: "10px",
                height: "40px",
                width: "60px",
                padding: 0,
              }}
              variant={!timeSelected ? "buttonGroupSelected" : "buttonGroup"}
              onClick={() => setTimeSelected(false)}
            >
              Date
            </Button>
            <Button
              sx={{
                height: "40px",
                width: "60px",
                padding: 0,
              }}
              variant={timeSelected ? "buttonGroupSelected" : "buttonGroup"}
              onClick={() => setTimeSelected(true)}
            >
              Time
            </Button>
          </ButtonGroup>
          <Box sx={{ marginBottom: "16px", textAlign: "center" }}>
            {value && formatDate(value)}
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          margin: !isMobile ? "0 12px 12px 12px" : undefined,
          border: "solid 1px #0000001f",
          justifyContent: "space-between",
          maxHeight: isMobile ? undefined : "337px",
        }}
      >
        {(!isMobile || !timeSelected) && (
          <StaticDatePicker
            disabled={disabled}
            value={value}
            onChange={handleDateChange}
            displayStaticWrapperAs="desktop"
            slots={{
              actionBar: () => null,
              toolbar: () => null,
            }}
            minDate={minDate}
          />
        )}
        {(!isMobile || timeSelected) && (
          <StaticTimePicker
            disabled={disabled}
            value={value}
            onChange={handleTimeChange}
            slots={{
              layout: CustomLayout,
              toolbar: () => null,
              actionBar: () => null,
            }}
            viewRenderers={
              {
                hours: renderMultiSectionDigitalClockTimeView,
                minutes: renderMultiSectionDigitalClockTimeView,
                meridiem: renderMultiSectionDigitalClockTimeView,
              } as StaticTimePickerProps<Date>["viewRenderers"]
            }
            minTime={disablePast ? getDynamicMinTime(value) : undefined}
          />
        )}
      </Box>
    </Box>
  );
};
