import { useEffect } from "react";

export default (title: string | null | undefined) => {
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (title !== null && title !== undefined) {
      timer = setTimeout(() => {
        document.title = title;
      }, 50); // Adjust delay as needed
    }
    return () => {
      clearTimeout(timer);
    };
  }, [title]); // Updates the title whenever `title` changes
};
