import { Box, Button, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";

export default ({
  states,
  actionTitle,
  onAction,
}: {
  states: string[];
  actionTitle?: string;
  onAction?: () => void;
}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box display="flex" flexDirection="column" gap="8px" alignItems="start">
        {states.map((state, i) => (
          <Typography
            key={i}
            variant="body"
            color={i === states.length - 1 ? "text.tertiary" : undefined}
          >
            {state}
          </Typography>
        ))}
      </Box>
      <Box display="flex" flexDirection="row" gap="18px">
        <Skeleton
          variant="rounded"
          width={92}
          height={92}
          sx={{ borderRadius: "8px" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="8px"
          flex="1"
        >
          <Skeleton variant="rounded" width={"50%"} height={20} />
          <Skeleton variant="rounded" width={"74%"} height={8} />
          <Skeleton variant="rounded" width={"60%"} height={8} />
          <Skeleton variant="rounded" width={"70%"} height={8} />
        </Box>
      </Box>
      {actionTitle && (
        <Button
          variant="text"
          sx={{ height: "20px", alignSelf: "start" }}
          onClick={
            onAction
              ? () => {
                  setClicked(true);
                  onAction();
                }
              : undefined
          }
          disabled={!onAction || clicked}
        >
          {actionTitle}
        </Button>
      )}
    </Box>
  );
};
