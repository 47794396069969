import { daysBetween, relativeTime } from "../../common/utils";
import { EngagementReport } from "../../protogen/engagement_service_pb";
import { CSSProperties } from "react";
import { FamilyStatus } from "../../protogen/advisors_service_pb";
import { AnnotationFlags } from "../../protogen/annotations_pb";
import { FLAGS_MAP } from "./flags";

const formatProtoTime = (
  timeSec: undefined | BigInt | bigint | number,
  defaultVal?: null,
) => {
  const lastActivity = new Date(Number(timeSec || 0) * 1000);
  return timeSec && lastActivity.getFullYear() > 2020
    ? relativeTime(lastActivity)
    : defaultVal;
};

enum EngagementState {
  DANGER,
  WARNING,
  GOOD,
}

const lastContactToState = (report: EngagementReport): EngagementState => {
  const lastContactDays = daysBetween(
    new Date(Number(report.stats?.activityLatestCommunicationSec) * 1000),
  );
  if (lastContactDays > 7) {
    return EngagementState.DANGER;
  } else if (lastContactDays > 4) {
    return EngagementState.WARNING;
  } else {
    return EngagementState.GOOD;
  }
};

const utilizationToState = (report: EngagementReport): EngagementState => {
  const percentUtilization =
    report.planUtilization?.periodHoursUtilized /
    report.planUtilization?.planHoursAllocated;
  const daysLeftInPeriod = daysBetween(
    new Date(report.planUtilization?.periodEndDate || ""),
  );
  if (percentUtilization > 1) {
    return EngagementState.WARNING;
  } else if (percentUtilization < 0.4 && daysLeftInPeriod < 14) {
    return EngagementState.WARNING;
  } else {
    return EngagementState.GOOD;
  }
};

const getReportState = (report: EngagementReport): EngagementState => {
  const contactState = lastContactToState(report);
  const utilizationState = utilizationToState(report);
  if (
    contactState === EngagementState.DANGER ||
    utilizationState === EngagementState.DANGER
  ) {
    return EngagementState.DANGER;
  } else if (
    contactState === EngagementState.WARNING ||
    utilizationState === EngagementState.WARNING
  ) {
    return EngagementState.WARNING;
  } else {
    return EngagementState.GOOD;
  }
};

const stateToTextTreatment = (state: EngagementState): CSSProperties => {
  switch (state) {
    case EngagementState.DANGER:
      return { color: "#C14743", fontWeight: 500 };
    case EngagementState.WARNING:
      return { color: "#F59F51", fontWeight: 500 };
    case EngagementState.GOOD:
    default:
      return {};
  }
};

const reportToRowColor = (report: EngagementReport): string => {
  const state = getReportState(report);
  if (
    !new Set([FamilyStatus.ACTIVE, FamilyStatus.PREACTIVATION]).has(
      report.family?.status,
    )
  ) {
    return "#D4D4D4";
  }
  switch (state) {
    case EngagementState.DANGER:
      return "#EF7B77";
    case EngagementState.WARNING:
      return "#F59F51";
    case EngagementState.GOOD:
      return "#468E3E";
  }
};

export type SortFields = { field: string; asc: boolean }[];

const sortFamiliesByFields = (
  reports: EngagementReport[],
  sortFields: SortFields,
) => {
  const dateStrToNum = (dateStr: string) => {
    if (!dateStr) return 0;
    const date = new Date(dateStr);
    return date.getTime();
  };
  return reports.sort((a, b) => {
    for (const sort of sortFields) {
      let valueA: string | number | bigint | boolean | undefined;
      let valueB: string | number | bigint | boolean | undefined;

      // Determine which field to sort by based on "name"
      if (sort.field === "state") {
        valueA = getReportState(a);
        valueB = getReportState(b);
      } else if (sort.field === "familyName") {
        valueA = a.family?.name.toLowerCase();
        valueB = b.family?.name.toLowerCase();
      } else if (sort.field === "advisorName") {
        valueA = a.advisor?.displayName.toLowerCase();
        valueB = b.advisor?.displayName.toLowerCase();
      } else if (sort.field === "lastActivity") {
        // Reversed since we want the most recent activity first.
        valueA = b.stats?.activityLatestCommunicationSec;
        valueB = a.stats?.activityLatestCommunicationSec;
      } else if (sort.field === "utilization") {
        valueA =
          a.planUtilization?.periodHoursUtilized /
          a.planUtilization?.planHoursAllocated;
        valueB =
          b.planUtilization?.periodHoursUtilized /
          b.planUtilization?.planHoursAllocated;
      } else if (sort.field === "renewal") {
        valueA = dateStrToNum(a.planUtilization?.periodEndDate);
        valueB = dateStrToNum(b.planUtilization?.periodEndDate);
      } else if (sort.field === "startDate") {
        valueA = dateStrToNum(a.family?.startDate);
        valueB = dateStrToNum(b.family?.startDate);
      } else if (sort.field === "active") {
        valueA = a.family?.status === FamilyStatus.ACTIVE;
        valueB = b.family?.status === FamilyStatus.ACTIVE;
      }

      // Perform comparison
      if (valueA !== valueB) {
        if (valueA === undefined) return sort.asc ? -1 : 1;
        if (valueB === undefined) return sort.asc ? 1 : -1;

        if (valueA < valueB) return sort.asc ? -1 : 1;
        if (valueA > valueB) return sort.asc ? 1 : -1;
      }
    }
    return 0; // If all fields are equal
  });
};

// Helper function to get color based on flag status
const getColor = (status: string) => {
  switch (status) {
    case "success":
      return "#16a34a"; // green-600
    case "error":
      return "#dc2626"; // red-600
    case "warning":
      return "#f59e0b"; // yellow-600
    default:
      return "#6b7280"; // gray-500
  }
};

// Helper function to get background color based on flag status
const getBgColor = (status: string) => {
  switch (status) {
    case "success":
      return "#f0fdf4"; // green-50
    case "error":
      return "#fef2f2"; // red-50
    case "warning":
      return "#fff7ed"; // yellow-50
    default:
      return "#f9fafb"; // gray-50
  }
};

// Helper function to get border color based on flag status
const getBorderColor = (status: string) => {
  switch (status) {
    case "success":
      return "#bbf7d0"; // green-200
    case "error":
      return "#fecaca"; // red-200
    case "warning":
      return "#feeab3"; // yellow-200
    default:
      return "#e5e7eb"; // gray-200
  }
};

const sortFlags = (flags: AnnotationFlags[], withInactive: boolean = true) => {
  // Ignore unknown flags.
  const knownFlags = new Set(Object.keys(FLAGS_MAP));
  const _flags = flags.filter((f) => knownFlags.has(f.name));
  return [
    ..._flags.filter(
      (f) => f.active && FLAGS_MAP[f.name].activeStatus === "error",
    ),
    ..._flags.filter(
      (f) => f.active && FLAGS_MAP[f.name].activeStatus === "success",
    ),
    ..._flags.filter(
      (f) => f.active && FLAGS_MAP[f.name].activeStatus === "warning",
    ),
    ...(withInactive ? _flags.filter((f) => !f.active) : []),
  ];
};

export {
  formatProtoTime,
  getReportState,
  stateToTextTreatment,
  reportToRowColor,
  lastContactToState,
  sortFamiliesByFields,
  utilizationToState,
  getColor,
  getBgColor,
  getBorderColor,
  sortFlags,
};
