import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Container, IconButton } from "@mui/material";
import { ProgressBar } from "../../components/signup/components/ProgressBar";
import { ReactComponent as FayeIcon } from "../../icons/FayeBrand.svg";
import { motion, AnimatePresence } from "framer-motion";
import {
  useGetOrCreateSession,
  useSignupSubmission,
  useUpdateSession,
} from "../../services/signup";
import { ArrowLeft } from "lucide-react";
import useTitle from "../../components/hooks/useTitle";
import {
  SignupState,
  StepRef,
  STEPS,
  ValidationError,
} from "../../components/signup/types";
import useMetaTracking from "../../components/hooks/useMetaTracking";
import useIsMobile from "../../components/hooks/useIsMobile";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router";
import {
  getLongestPathFromEnd,
  getPathToStep,
  SIGNUP_FLOW,
} from "../../components/signup/flow";
import {
  buildSessionUpdate,
  buildStateFromProto,
} from "../../components/signup/utils";
import Loading from "../../components/common/Loading";
import TokenStore from "../../services/TokenStore";

const META_PIXEL_ID = process.env.REACT_APP_META_PIXEL_ID;

const getTrackingParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const trackingParams: { [key: string]: string } = {};
  searchParams.forEach((value, key) => {
    if (key in trackingParams) {
      trackingParams[key] = `${trackingParams[key]} ${value}`;
    } else {
      trackingParams[key] = `${value}`;
    }
  });
  return trackingParams;
};

const defaultFormData: SignupState = {};

export const SignupFlow = ({
  initialStep,
  initialFormData,
  setStepName,
  isDebug = false,
}: {
  initialStep?: STEPS;
  initialFormData?: SignupState;
  setStepName: (step: string) => void;
  isDebug?: boolean;
}) => {
  useTitle("Join Faye");
  const params = useParams();
  const stepRef = useRef<HTMLDivElement>(null);
  const [progressBarSx, setProgressBarSx] = useState({});
  const [formData, setFormData] = useState<SignupState>(
    initialFormData || defaultFormData,
  );
  const [currentStep, setCurrentStep] = useState(
    Math.max(
      SIGNUP_FLOW.findIndex((s) => s.name === initialStep),
      0,
    ),
  );
  const [, setIsMovingForward] = useState(true);
  // Using Map to preserve insertion order
  const stepRefs = useRef<Map<string, StepRef>>(new Map());
  const isMobile = useIsMobile();
  const initialStepDef =
    (initialStep && SIGNUP_FLOW.find((s) => s.name === initialStep)) ||
    SIGNUP_FLOW[0];
  const [showBack, setShowBack] = useState(
    !(initialStepDef?.backDisabled || false),
  );
  const { request: updateRequest, loading: updateLoading } = useUpdateSession();
  const { request: submissionRequest, loading: submissionLoading } =
    useSignupSubmission();
  const handleNext = async (stepData?: Partial<SignupState>) => {
    const currentStepDef = SIGNUP_FLOW[currentStep];
    if (currentStepDef.isValid && !currentStepDef.isValid(stepData || {})) {
      // TODO(Kip): not sure how/if we handle in the step UX.
      throw new ValidationError("Invalid step");
    }
    const nextStepName = currentStepDef.nextStep?.(stepData || {});
    const updates = buildSessionUpdate(stepData, nextStepName);
    if (!isDebug) {
      const resp = await updateRequest(updates);
      if (!resp) {
        // TODO(Kip): not sure how/if we handle in the step UX.
        throw new ValidationError("Error saving step");
      }
      if (currentStepDef.completionStateName) {
        const submissionResp = await submissionRequest({
          submissionState: currentStepDef.completionStateName,
        });
        if (!submissionResp) {
          throw new ValidationError("Error submitting signup");
        }
      }
    }
    // Not all steps have step data
    if (stepData) {
      setFormData((prev) => ({ ...prev, ...stepData }));
    }
    if (!nextStepName) return;
    const nextStepInd = SIGNUP_FLOW.findIndex(
      (step) => step.name === nextStepName,
    );
    if (nextStepInd < 0) return;
    setIsMovingForward(true);
    setCurrentStep(nextStepInd);
    const nextStepDef = SIGNUP_FLOW[nextStepInd];
    setShowBack(!nextStepDef.backDisabled);
  };
  useEffect(() => {
    setStepName(steps[currentStep][0]);
  }, [currentStep]);

  useEffect(() => {
    if (params.step) {
      const step = SIGNUP_FLOW.findIndex((s) => s.name === params.step);
      setCurrentStep(step);
    }
  }, [params.step]);

  const handleBack = () => {
    setIsMovingForward(false);
    const currentStepDef = SIGNUP_FLOW[currentStep];
    const backStepName = !currentStepDef.backDisabled
      ? currentStepDef.defaultBackStep
      : null;
    if (!backStepName) return;
    const backStepInd = SIGNUP_FLOW.findIndex(
      (step) => step.name === backStepName,
    );
    const backStepDef = SIGNUP_FLOW[backStepInd];
    if (backStepInd < 0) return;
    setCurrentStep(backStepInd);
    setShowBack(!backStepDef.backDisabled);
  };

  const handleScroll = () => {
    const el = stepRef.current;
    if (el && el.scrollTop > 0) {
      setProgressBarSx({
        boxShadow: isMobile ? "0px 5px 8px 0px rgba(151, 93, 51, 0.07);" : "",
      });
    } else {
      setProgressBarSx({});
    }
  };
  const steps: [STEPS, React.ReactNode][] = SIGNUP_FLOW.map((step) => [
    step.name,
    <Box
      key={step.name}
      sx={{
        width: "100%",
      }}
    >
      {React.createElement(step.component, {
        key: step.name,
        ref: (ref: StepRef) => {
          if (ref) stepRefs.current.set(step.name, ref);
        },
        signupState: formData,
        onNext: handleNext,
        disabled: submissionLoading || updateLoading,
        isDebug,
      })}
    </Box>,
  ]);

  const totalStepsToEnd = getLongestPathFromEnd(SIGNUP_FLOW, undefined, true);
  const totalStepsToVeryEnd = getLongestPathFromEnd(
    SIGNUP_FLOW,
    undefined,
    false,
  );
  const stepsLeftToEnd = getLongestPathFromEnd(
    SIGNUP_FLOW,
    SIGNUP_FLOW[currentStep].name,
    true,
  );
  const stepsLeftToVeryEnd = getLongestPathFromEnd(
    SIGNUP_FLOW,
    SIGNUP_FLOW[currentStep].name,
    false,
  );
  // Faster velocity towards completion as we approach signup than after.
  let progress = 0.85 * ((totalStepsToEnd - stepsLeftToEnd) / totalStepsToEnd);
  if (stepsLeftToEnd === 0) {
    progress +=
      0.15 * ((totalStepsToVeryEnd - stepsLeftToVeryEnd) / totalStepsToVeryEnd);
  }
  const [, currentStepNode] = steps[currentStep];
  const whiteBackground = SIGNUP_FLOW[currentStep].whiteBackground;

  return (
    <Container
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: whiteBackground && isMobile ? "white" : "#FDFAF7",
        "&.MuiContainer-root": {
          maxWidth: "none",
          padding: "0",
        },
      }}
    >
      <Box sx={{ paddingTop: "8px", backgroundColor: "white" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: isMobile ? "0 8px" : "0 16px",
          }}
        >
          <Collapse in={showBack} orientation="horizontal">
            <IconButton onClick={handleBack} disabled={!showBack}>
              <ArrowLeft />
            </IconButton>
          </Collapse>
          <FayeIcon
            style={{
              height: "26px",
              margin: "10px 0 8px",
            }}
          />
        </Box>
        <ProgressBar
          numerator={100 * progress}
          minimum={2.5}
          sx={progressBarSx}
        />
      </Box>
      <Box
        ref={stepRef}
        onScroll={handleScroll}
        sx={{
          flex: 1,
          position: "relative",
          overflow: "scroll",
          scrollbarWidth: "none",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { opacity: 0 },
              animate: {
                opacity: 1,
                transition: { duration: 0.2, ease: "easeIn" },
              },
              exit: {
                opacity: 0,
                transition: { duration: 0.2, ease: "easeOut" },
              },
            }}
            style={{
              width: "100%",
              height: "calc(100% - 64px)",
              padding: isMobile ? "" : "0 24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            {currentStepNode}
          </motion.div>
        </AnimatePresence>
      </Box>
    </Container>
  );
};

export default ({}: {}) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isDebug = !!new URLSearchParams(location.search).get("debug");
  const [initialState, setInitialState] = useState<
    [STEPS | undefined, SignupState] | null
  >(null);
  const { request, loading } = useGetOrCreateSession();
  useMetaTracking(META_PIXEL_ID);
  const updateStep = (step: string) => {
    const currentStep = params.step;
    if (currentStep !== step) {
      navigate(`/signup/${step}${location.search}`, { replace: false });
    }
  };
  useEffect(() => {
    const fetch = async () => {
      const resp = await request({
        trackingParameters: getTrackingParams(location.search),
      });
      if (!resp) return;
      let initialStep: STEPS | undefined;
      const signupState: SignupState = resp.state
        ? buildStateFromProto(resp.state)
        : {};
      const endStep =
        (params.step &&
          Object.values(STEPS).includes(params.step as STEPS) &&
          params.step) ||
        resp?.state?.stepName;
      // Load the current state, find current step, find all steps going back to the route by following back steps. Then
      // validate each step along the way going forward. Stop at the first invalid step, when it matches the current step
      // from the url, or when it matches the step from the code.
      if (endStep) {
        const path = getPathToStep(endStep as STEPS);
        for (const step of path) {
          initialStep = step.name;
          if (step.isValid && !step.isValid(signupState)) {
            break;
          }
          if (initialStep === endStep) {
            break;
          }
        }
      }
      setInitialState([initialStep, signupState]);
    };
    // Let's redirect if a user is logged in. Wouldn't be the end of the world, but could get confusing.
    if (TokenStore.getLocalCurrentUser()) {
      navigate("/home?from=signup");
      return;
    }
    if (isDebug) {
      setInitialState([undefined, {}]);
    } else {
      fetch();
    }
  }, []);

  if (!initialState || loading) {
    return <Loading />;
  }
  const [step, formData] = initialState;
  return (
    <SignupFlow
      initialStep={step}
      initialFormData={formData}
      setStepName={updateStep}
      isDebug={isDebug}
    />
  );
};
