import { Family } from "protogen/advisors_service_pb";
import React, { useContext } from "react";
import { RetrievalResult } from "protogen/search_service_pb";
import { SearchContext } from "../context/SearchContextProvider";
import { relativeTime } from "../../common/utils";
import { ReactComponent as DocumentIcon } from "../../icons/Menu/Document.svg";
import {
  Mail,
  MessageSquare,
  List,
  MessagesSquare,
  CalendarDays,
  Lock,
  Phone,
  MessageCircle,
  Info,
} from "lucide-react";
import { Filter } from "./types";

const familyFilter = (family: Family) => {
  return {
    field: "family",
    operator: "EQUALS",
    value: family.ref,
    presentationPrefix: "Family:",
    presentationValue: family.name,
  };
};

const familyRefFilter = (familyRef: string, display?: string) => {
  return {
    field: "family",
    operator: "EQUALS",
    value: familyRef,
    presentationPrefix: "Family:",
    presentationValue: display,
  };
};

const phoneCallFilter = (callRef: string, display?: string): Filter => {
  return {
    field: "phone_call",
    operator: "EQUALS",
    value: callRef,
    presentationPrefix: "Call:",
    presentationValue: display || "Phone Call",
  };
};

const entityTypeInFilter = (types_: string[]): Filter => {
  return {
    field: "entity_type",
    operator: "CONTAINED_IN",
    value: types_.join(","),
  };
};

const notThisTaskInFilter = (taskRef: string): Filter => {
  return {
    field: "entity_refs",
    operator: "NOT_CONTAINED_IN",
    value: taskRef,
  };
};

const tasksOnlyFilter = (): Filter => {
  return {
    ...entityTypeInFilter(["task", "task_block_document"]),
    presentationPrefix: "Type:",
    presentationValue: "Tasks",
  };
};

const emailOnlyFilter = (): Filter => {
  return {
    ...entityTypeInFilter(["email"]),
    presentationPrefix: "Type:",
    presentationValue: "Email",
  };
};

const documentsOnlyFilter = (): Filter => {
  return {
    ...entityTypeInFilter(["document"]),
    presentationPrefix: "Type:",
    presentationValue: "Documents",
  };
};

const messagesOnlyFilter = (): Filter => {
  return {
    ...entityTypeInFilter(["messages"]),
    presentationPrefix: "Type:",
    presentationValue: "Messages",
  };
};

const textsOnlyFilter = (): Filter => {
  return {
    ...entityTypeInFilter(["textmessage"]),
    presentationPrefix: "Type:",
    presentationValue: "Texts",
  };
};

const forumOnlyFilter = (): Filter => {
  return {
    ...entityTypeInFilter(["forum_post", "forum_comment"]),
    presentationPrefix: "In:",
    presentationValue: "Community",
  };
};

const urlForResult = (result: RetrievalResult): string | null => {
  const resultMetadata = new Map(result.metadata.map((i) => [i.key, i.value]));
  const encode = encodeURIComponent;
  switch (result.entityType) {
    case "phonecall":
      const segmentRefs = resultMetadata.get("segmentRefs");
      const param = segmentRefs ? `?segmentRefs=${segmentRefs}` : "";
      return `/calls/${encode(result.entityRef)}${param}`;
    case "email_conversation":
      return `/inbox/email/${encode(result.entityRef)}`;
    case "messages":
      if (result.familyRef) {
        return `/families/${encode(result.familyRef)}?activityRef=${encode(
          result.entityRef,
        )}`;
      } else {
        const conversationRef = resultMetadata.get("conversationRef");
        return `/inbox/phone/${encode(conversationRef || "")}`;
      }
    case "task":
      return `/tasks/${encode(result.entityRef)}`;
    case "forum_post":
      return `/community/${encode(result.entityRef)}`;
    case "forum_comment":
      const postRef = resultMetadata.get("postRef")!;
      return `/community/${encode(postRef)}?commentRef=${encode(
        result.entityRef,
      )}`;
    case "calendar_event":
      return `/calendar/${encode(result.entityRef)}`;
    case "fact_value":
      return `/families/${encode(result.familyRef)}?factRef=${encode(
        result.entityRef,
      )}`;
    case "directmessage":
      return `/inbox/messages/${encode(result.entityRef)}`;
    case "secret_value":
      return `/families/${encode(result.familyRef)}?secretRef=${encode(
        result.entityRef,
      )}`;
    default:
      return null;
  }
};

const iconForResult = (result: RetrievalResult): React.ReactNode => {
  switch (result.entityType) {
    case "phonecall":
      return <Phone width="20" />;
    case "email_conversation":
      return <Mail width="20" />;
    case "messages":
      return <MessageSquare width="20" />;
    case "task":
      return <List width="20" />;
    case "forum_post":
    case "forum_comment":
      return <MessagesSquare width="20" />;
    case "calendar_event":
      return <CalendarDays width="20" />;
    case "fact_value":
      return <Info width="20" />;
    case "directmessage":
      return <MessageCircle width="20" />;
    case "secret_value":
      return <Lock width="20" />;
    case "document":
      return <DocumentIcon width="20" />;
    default:
      return null;
  }
};

const useDetermineFilters = (): Filter[] => {
  const { family, phoneCall, tasksOnly, forumOnly } = useContext(SearchContext);
  const filters: Filter[] = [];
  if (family) {
    filters.push(familyFilter(family));
  }
  if (phoneCall) {
    const callName = `Call ${relativeTime(
      new Date(Number(phoneCall.creationSec) * 1000),
    )}`;
    filters.push(phoneCallFilter(phoneCall.ref, callName));
  }
  if (tasksOnly) {
    filters.push(tasksOnlyFilter());
  }
  if (forumOnly) {
    filters.push(forumOnlyFilter());
  }
  return filters;
};

const generateSearchQueryUrl = (
  query: string | null,
  topics?: string[] | null,
  domain?: string | null,
) => {
  const filters = [
    ...(query ? [`query=${encodeURIComponent(query)}`] : []),
    ...(topics ? [`topics=${encodeURIComponent(topics.join(","))}`] : []),
    ...(domain ? [`domain=${domain}`] : []),
    `referrer=${encodeURIComponent(location.pathname + location.search)}`,
  ];
  return `/search?${filters.join("&")}`;
};

const generateSearchQueryUrlWithFilters = (
  query: string | null,
  filters: Filter[],
) => {
  const parts = [
    ...(query ? [`query=${encodeURIComponent(query)}`] : []),
    `referrer=${encodeURIComponent(location.pathname + location.search)}`,
  ];
  for (const filter of filters) {
    if (filter.field === "entity_type") {
      parts.push(`domain=${filter.value}`);
    } else if (filter.field === "family") {
      parts.push(
        `familyRef=${encodeURIComponent(
          filter.value,
        )}&familyName=${encodeURIComponent(filter.presentationValue || "")}`,
      );
    } else if (filter.field === "phone_call") {
      parts.push(
        `callRef=${encodeURIComponent(
          filter.value,
        )}&callName=${encodeURIComponent(filter.presentationValue || "")}`,
      );
    }
  }
  return `/search?${parts.join("&")}`;
};

const generateSearchParamsWithFilters = (
  query: string | null,
  filters: Filter[],
) => {
  const parts: { [key: string]: string } = {};
  if (query) {
    parts["query"] = query;
  }
  for (const filter of filters) {
    if (filter.field === "entity_type") {
      parts["domain"] = filter.value;
    } else if (filter.field === "family") {
      parts["familyRef"] = filter.value;
      parts["familyName"] = filter.presentationValue || "";
    } else if (filter.field === "phone_call") {
      parts["callRef"] = filter.value;
      parts["callName"] = filter.presentationValue || "";
    }
  }
  return parts;
};

const parseSearchQueryUrl = (search: string) => {
  const queryParams = new URLSearchParams(search);
  const searchQuery = decodeURIComponent(queryParams.get("query") || "");
  const referrer = decodeURIComponent(queryParams.get("referrer") || "");
  const contentDomain =
    decodeURIComponent(queryParams.get("domain") || "") || null;

  const filters: Filter[] = [];
  if (queryParams.get("familyRef")) {
    const familyRef = decodeURIComponent(queryParams.get("familyRef") || "");
    const familyName = decodeURIComponent(queryParams.get("familyName") || "");
    filters.push(familyRefFilter(familyRef, familyName));
  }
  if (queryParams.get("callRef")) {
    const callRef = decodeURIComponent(queryParams.get("callRef") || "");
    const callName = decodeURIComponent(queryParams.get("callName") || "");
    filters.push(phoneCallFilter(callRef, callName || undefined));
  }
  if (contentDomain == "community") {
    filters.push(forumOnlyFilter());
  }
  if (contentDomain == "email") {
    filters.push(emailOnlyFilter());
  }
  if (contentDomain == "document") {
    filters.push(documentsOnlyFilter());
  }
  if (contentDomain == "messages") {
    filters.push(messagesOnlyFilter());
  }
  if (contentDomain == "textmessage") {
    filters.push(textsOnlyFilter());
  }
  if (contentDomain == "task") {
    filters.push(tasksOnlyFilter());
  }
  return {
    searchQuery,
    referrer,
    filters,
  };
};

export {
  familyFilter,
  familyRefFilter,
  phoneCallFilter,
  tasksOnlyFilter,
  forumOnlyFilter,
  textsOnlyFilter,
  messagesOnlyFilter,
  emailOnlyFilter,
  entityTypeInFilter,
  urlForResult,
  iconForResult,
  notThisTaskInFilter,
  useDetermineFilters,
  generateSearchQueryUrl,
  parseSearchQueryUrl,
  generateSearchQueryUrlWithFilters,
  generateSearchParamsWithFilters,
};
