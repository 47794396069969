import { createContext, useState, useContext } from "react";
import SnackPack, { SnackbarMessage } from "../common/SnackPack";

type contextType = {
  pushSnack: (snack: string | SnackbarMessage) => void;
};

export const SnackpackContext = createContext<contextType>({
  pushSnack: () => {},
});
export const useSnackpack = () => useContext(SnackpackContext);

export const SnackpackContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const pushSnack = (message: string | SnackbarMessage) => {
    if (typeof message === "string") {
      setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    } else {
      setSnackPack((prev) => [...prev, message]);
    }
  };

  return (
    <SnackpackContext.Provider value={{ pushSnack }}>
      {children}
      <SnackPack snackPack={snackPack} />
    </SnackpackContext.Provider>
  );
};
