import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Box, InputAdornment, TextField, IconButton } from "@mui/material";
import { Eye, EyeOff } from "lucide-react";
import { SecretFormHandle, SecretFormProps } from "../types";
import { PasswordInput } from "../../common/MaskedFields";

type LoginForm = {
  title?: string;
  username?: string;
  password?: string;
  notes?: string;
  [key: string]: string | undefined;
};
export default forwardRef<SecretFormHandle, SecretFormProps>(
  ({ secret, updateFormData, errors }: SecretFormProps, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const parsed: LoginForm =
      secret.value === "" ? {} : JSON.parse(secret.value);
    useImperativeHandle(ref, () => ({
      isValid: () => {
        return secret.name !== "";
      },
    }));

    const createChangeHandler = (
      path: string[],
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const updatedParsed = { ...parsed };
      const value = event.target.value;

      let currentPart: any = updatedParsed;
      for (let i = 0; i < path.length; i++) {
        if (i === path.length - 1) {
          currentPart[path[i]] = value;
        } else {
          if (
            typeof currentPart[path[i]] !== "object" ||
            currentPart[path[i]] === null
          ) {
            currentPart[path[i]] = {};
          } else {
            currentPart[path[i]] = { ...currentPart[path[i]] };
          }
          currentPart = currentPart[path[i]];
        }
      }
      const form = {
        ...secret,
        value: JSON.stringify(updatedParsed),
      };
      updateFormData(form);
    };
    return (
      <Box>
        <TextField
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={secret.name}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => updateFormData({ ...secret, name: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          value={parsed.username || ""}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => createChangeHandler(["username"], e)}
        />
        <TextField
          margin="dense"
          label="Password"
          type="text"
          variant="outlined"
          fullWidth
          value={parsed.password || ""}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => createChangeHandler(["password"], e)}
          InputProps={{
            inputComponent: PasswordInput as any,
            inputProps: {
              showPassword: showPassword,
              value: parsed.password,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <EyeOff /> : <Eye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          rows={5}
          margin="dense"
          label="Notes"
          type="text"
          fullWidth
          multiline
          variant="outlined"
          value={parsed.notes || ""}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => createChangeHandler(["notes"], e)}
        />
      </Box>
    );
  },
);
