export type ChoiceSelections = { [key: string]: string | boolean };

export class ValidationError extends Error {
  constructor(public error: string) {
    super(error);
  }
}

export type SignupState = {
  goals?: ChoiceSelections;
  household?: ChoiceSelections;
  superpowers?: ChoiceSelections;
  flowPath?: STEPS.CONSULTATION_DETAILS | STEPS.MEMBERSHIP;
  membership?: MembershipOption;
  fun?: ChoiceSelections;
  lifeEvents?: ChoiceSelections;
  familyInfo?: string;
  // todoList?: string;
  anythingElse?: string;
  referralSource?: string;
  payment?: {
    paymentMethodId: string;
    customerId: string;
    promoCode?: string;
  };
  consultationDetails?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    zipCode: string;
  };
  userInfo?: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    address: string;
  };
  consultation?: {
    eventUri: string;
    inviteeUri: string;
    eventDate?: Date;
  };
  referralCode?: string;
};

export interface StepRef {}

export interface StepProps {
  onNext?: (updates?: Partial<SignupState>) => Promise<void>;
  signupState: SignupState;
  disabled: boolean;
  isDebug: boolean;
}

export interface MembershipOption {
  name: string;
  supportHours: number;
  maxUsers: number;
  amountCents: number;
  additionalFeatures?: string[];
}

export const PlanFeatures = [
  "Task support from a dedicated Advisor",
  "Communication via text, call, or email",
  "Family Hub for collaboration",
  "Month-to-month plan, cancel any time",
];

const peaceOfMind: MembershipOption = {
  name: "Peace of Mind",
  supportHours: 4,
  maxUsers: 1,
  amountCents: 16900,
};
const freeTimeFinder: MembershipOption = {
  name: "Free Time Finder",
  supportHours: 8,
  maxUsers: 2,
  amountCents: 29900,
};
const totalGameChanger: MembershipOption = {
  name: "Total Game Changer",
  supportHours: 16,
  maxUsers: 2,
  additionalFeatures: ["Priority response time"],
  amountCents: 56900,
};
export const MEMBERSHIP_OPTIONS: MembershipOption[] = [
  peaceOfMind,
  freeTimeFinder,
  totalGameChanger,
];

export enum STEPS {
  GOALS = "goals",
  HOUSEHOLD = "household",
  SUPERPOWERS = "advisor-superpowers",
  JOIN_NOW = "join-now", // Forks

  // Branch 1
  MEMBERSHIP = "membership",
  PAYMENT = "payment",
  SIGNUP_CONFIRMATION = "signup-confirmation",
  // Follow-up questions
  FUN = "fun",
  LIFE_EVENTS = "life-events",
  FAMILY_INFO = "family-info",
  // TODO_LIST = "todo-list",
  ANYTHING_ELSE = "anything-else",
  REFERRAL_SOURCE = "referral-source",
  FOLLOWUP_CONFIRMATION = "followup-confirmation",

  // Branch 2
  CONSULTATION_DETAILS = "consultation-details",
  CONSULTATION_SCHEDULE = "consultation-schedule",
  CONSULTATION_CONFIRMATION = "consultation-confirmation",
}
