import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  buildRequest,
  buildUpdateRequest,
  EphemeralEvent,
  FormErrors,
  validateEvent,
  createDefaultEphemeralEvent,
} from "../../types/calendars";
import ReactiveDialog from "../common/ReactiveDialog";
import useIsMobile from "../hooks/useIsMobile";
import {
  useUpdateCalendarEvent,
  useGetCalendarEvent,
  useGetMemberCalendarEvent,
  useUpdateMemberCalendarEvent,
  useCreateCalendarEvent,
  useCreateMemberCalendarEvent,
} from "services/calendar";
import Loading from "components/common/Loading";
import EventForm from "./EventForm";
import { EventNotice } from "protogen/calendar_pb";
import { buildEphemeralEventFromCalendarEvent } from "../../types/calendars";
import useGetEmailAutocompleteEntries from "../email/useGetEmailAutocompleteEntries";

interface Props {
  eventRef: string;
  open: boolean;
  onClose: () => void;
  onEdited?: (t: EventNotice) => void;
  accountType?: "advisor" | "member";
  notice?: EventNotice;
}

export default ({
  eventRef,
  open,
  onClose,
  onEdited,
  accountType = "advisor",
  notice,
}: Props) => {
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formData, setFormData] = useState<EphemeralEvent>(
    createDefaultEphemeralEvent(),
  );
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { autocompleteEntries } =
    accountType === "advisor"
      ? useGetEmailAutocompleteEntries({
          familyRef: "",
        })
      : { autocompleteEntries: [] };
  const updateCalendarEventRequestFn =
    accountType === "advisor"
      ? useUpdateCalendarEvent
      : useUpdateMemberCalendarEvent;
  const { request, loading } = updateCalendarEventRequestFn((r) => {
    if (onEdited) {
      onEdited(r.notice!);
    } else {
      navigate(`/calendar/${encodeURIComponent(r?.event?.ref!)}`);
    }
  });

  // We are editing the recurring event, but really creating a recurring instance,
  // which is a new event both in our system and in ICS.
  const createCalendarEventRequestFn =
    accountType === "advisor"
      ? useCreateCalendarEvent
      : useCreateMemberCalendarEvent;

  const {
    request: createCalendarEventRequest,
    loading: createCalendarEventLoading,
  } = createCalendarEventRequestFn((r) => {
    if (onEdited) {
      onEdited(r.notice!);
    } else {
      navigate(`/calendar/${encodeURIComponent(r?.event?.ref!)}`);
    }
  });
  const getCalendarEventRequestFn =
    accountType === "advisor" ? useGetCalendarEvent : useGetMemberCalendarEvent;

  const {
    request: getEventRequest,
    data: getEventData,
    loading: getEventRequestLoading,
  } = getCalendarEventRequestFn((r) => {
    if (r.event !== undefined) {
      const event: EphemeralEvent = buildEphemeralEventFromCalendarEvent(
        r.event,
      );
      setFormData(event);
    }
  });

  useEffect(() => {
    if (open && eventRef !== "") {
      getEventRequest({ eventRef });
    }
  }, [open, eventRef]);

  const updateFormData = (t: EphemeralEvent) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...t,
    }));
  };

  const _validate = () => {
    const errors = validateEvent(formData);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleEditEvent = () => {
    if (_validate()) {
      if (getEventData?.event) {
        const calEvent = getEventData?.event;
        const isRecurring =
          calEvent.recurringUnit !== 0 && calEvent.recurringInterval;
        if (isRecurring && formData.isRecurringSingleInstance) {
          createCalendarEventRequest(
            buildRequest(formData, getEventData?.event, notice),
          );
        } else {
          const d = buildUpdateRequest(eventRef, getEventData?.event, formData);
          request(d);
        }
      }
    }
  };

  if (!getEventData) {
    return <></>;
  }
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title={"Edit Event"}
      primaryAction={handleEditEvent}
      primaryActionName={"Save"}
      noActionsFullScreen={true}
      unpad
      style={{
        width: "950px",
        maxWidth: "950px",
        padding: !isMobile ? "20px" : undefined,
      }}
    >
      {getEventRequestLoading ? (
        <Loading />
      ) : (
        <EventForm
          loading={loading || createCalendarEventLoading}
          formData={formData}
          updateFormData={updateFormData}
          errors={formErrors}
          hideAction={isMobile}
          onSubmit={handleEditEvent}
          onClose={onClose}
          attendeeAutocomplete={autocompleteEntries}
          actionName="Save"
          accountType={accountType}
        />
      )}
    </ReactiveDialog>
  );
};
