import { forwardRef } from "react";
import { StepProps, StepRef } from "../../types";
import OpenTextStep from "../../components/OpenTextStep";

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState }, _ref) => {
    return (
      <OpenTextStep
        title={"Is there anything else we should know?"}
        subtitle={
          "We’d love to hear about anything we didn’t ask that feels important to you, big or small!"
        }
        placeholder={"Additional details..."}
        skippable={true}
        onNext={async (inputText) => {
          return onNext?.({ anythingElse: inputText });
        }}
        initialText={signupState?.anythingElse}
      />
    );
  },
);
