// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/engagement_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Family } from "./advisors_service_pb.ts";
import { Advisor, PlanUtilization, UploadAttachment } from "./common_pb.ts";
import { AnnotationFlags, ChangeEventAnnotation, FamilyAnnotation } from "./annotations_pb.ts";

/**
 * @generated from message protogen.EngagementReport
 */
export class EngagementReport extends Message<EngagementReport> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: protogen.Advisor advisor = 2;
   */
  advisor?: Advisor;

  /**
   * @generated from field: protogen.EngagementReport.EngagementStats stats = 3;
   */
  stats?: EngagementReport_EngagementStats;

  /**
   * @generated from field: protogen.PlanUtilization planUtilization = 4;
   */
  planUtilization?: PlanUtilization;

  /**
   * @generated from field: repeated protogen.AnnotationFlags flags = 5;
   */
  flags: AnnotationFlags[] = [];

  /**
   * @generated from field: bool needsDay10Checkin = 6;
   */
  needsDay10Checkin = false;

  /**
   * @generated from field: bool needsDay30Checkin = 7;
   */
  needsDay30Checkin = false;

  /**
   * @generated from field: bool needsDay50Checkin = 8;
   */
  needsDay50Checkin = false;

  constructor(data?: PartialMessage<EngagementReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EngagementReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 2, name: "advisor", kind: "message", T: Advisor },
    { no: 3, name: "stats", kind: "message", T: EngagementReport_EngagementStats },
    { no: 4, name: "planUtilization", kind: "message", T: PlanUtilization },
    { no: 5, name: "flags", kind: "message", T: AnnotationFlags, repeated: true },
    { no: 6, name: "needsDay10Checkin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "needsDay30Checkin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "needsDay50Checkin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EngagementReport {
    return new EngagementReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EngagementReport {
    return new EngagementReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EngagementReport {
    return new EngagementReport().fromJsonString(jsonString, options);
  }

  static equals(a: EngagementReport | PlainMessage<EngagementReport> | undefined, b: EngagementReport | PlainMessage<EngagementReport> | undefined): boolean {
    return proto3.util.equals(EngagementReport, a, b);
  }
}

/**
 * @generated from message protogen.EngagementReport.EngagementStats
 */
export class EngagementReport_EngagementStats extends Message<EngagementReport_EngagementStats> {
  /**
   * @generated from field: int32 tasksOpen = 1;
   */
  tasksOpen = 0;

  /**
   * @generated from field: int32 tasksCompleted = 2;
   */
  tasksCompleted = 0;

  /**
   * @generated from field: int64 tasksOldestOpenSec = 3;
   */
  tasksOldestOpenSec = protoInt64.zero;

  /**
   * @generated from field: int64 tasksAverageTimeToCloseSec = 4;
   */
  tasksAverageTimeToCloseSec = protoInt64.zero;

  /**
   * @generated from field: int64 tasksLatestActivitySec = 5;
   */
  tasksLatestActivitySec = protoInt64.zero;

  /**
   * @generated from field: int64 activityLatestCommunicationSec = 11;
   */
  activityLatestCommunicationSec = protoInt64.zero;

  /**
   * @generated from field: int32 activitySevenDayCount = 12;
   */
  activitySevenDayCount = 0;

  /**
   * @generated from field: int32 activityUnreadCount = 13;
   */
  activityUnreadCount = 0;

  /**
   * @generated from field: int32 activityUnreadEmailCount = 14;
   */
  activityUnreadEmailCount = 0;

  /**
   * @generated from field: int32 activityUnreadMessageCount = 15;
   */
  activityUnreadMessageCount = 0;

  /**
   * @generated from field: int32 activityTaskMessageCount = 16;
   */
  activityTaskMessageCount = 0;

  /**
   * @generated from field: float averageMonthlyHours = 21;
   */
  averageMonthlyHours = 0;

  constructor(data?: PartialMessage<EngagementReport_EngagementStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EngagementReport.EngagementStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tasksOpen", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "tasksCompleted", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tasksOldestOpenSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "tasksAverageTimeToCloseSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "tasksLatestActivitySec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "activityLatestCommunicationSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "activitySevenDayCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "activityUnreadCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "activityUnreadEmailCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "activityUnreadMessageCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "activityTaskMessageCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "averageMonthlyHours", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EngagementReport_EngagementStats {
    return new EngagementReport_EngagementStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EngagementReport_EngagementStats {
    return new EngagementReport_EngagementStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EngagementReport_EngagementStats {
    return new EngagementReport_EngagementStats().fromJsonString(jsonString, options);
  }

  static equals(a: EngagementReport_EngagementStats | PlainMessage<EngagementReport_EngagementStats> | undefined, b: EngagementReport_EngagementStats | PlainMessage<EngagementReport_EngagementStats> | undefined): boolean {
    return proto3.util.equals(EngagementReport_EngagementStats, a, b);
  }
}

/**
 * @generated from message protogen.FetchEngagementReportsRequest
 */
export class FetchEngagementReportsRequest extends Message<FetchEngagementReportsRequest> {
  constructor(data?: PartialMessage<FetchEngagementReportsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchEngagementReportsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchEngagementReportsRequest {
    return new FetchEngagementReportsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchEngagementReportsRequest {
    return new FetchEngagementReportsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchEngagementReportsRequest {
    return new FetchEngagementReportsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchEngagementReportsRequest | PlainMessage<FetchEngagementReportsRequest> | undefined, b: FetchEngagementReportsRequest | PlainMessage<FetchEngagementReportsRequest> | undefined): boolean {
    return proto3.util.equals(FetchEngagementReportsRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchEngagementReportsResponse
 */
export class FetchEngagementReportsResponse extends Message<FetchEngagementReportsResponse> {
  /**
   * @generated from field: repeated protogen.EngagementReport reports = 1;
   */
  reports: EngagementReport[] = [];

  constructor(data?: PartialMessage<FetchEngagementReportsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchEngagementReportsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reports", kind: "message", T: EngagementReport, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchEngagementReportsResponse {
    return new FetchEngagementReportsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchEngagementReportsResponse {
    return new FetchEngagementReportsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchEngagementReportsResponse {
    return new FetchEngagementReportsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchEngagementReportsResponse | PlainMessage<FetchEngagementReportsResponse> | undefined, b: FetchEngagementReportsResponse | PlainMessage<FetchEngagementReportsResponse> | undefined): boolean {
    return proto3.util.equals(FetchEngagementReportsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListFamilyAnnotationsRequest
 */
export class ListFamilyAnnotationsRequest extends Message<ListFamilyAnnotationsRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<ListFamilyAnnotationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListFamilyAnnotationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFamilyAnnotationsRequest {
    return new ListFamilyAnnotationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFamilyAnnotationsRequest {
    return new ListFamilyAnnotationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFamilyAnnotationsRequest {
    return new ListFamilyAnnotationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListFamilyAnnotationsRequest | PlainMessage<ListFamilyAnnotationsRequest> | undefined, b: ListFamilyAnnotationsRequest | PlainMessage<ListFamilyAnnotationsRequest> | undefined): boolean {
    return proto3.util.equals(ListFamilyAnnotationsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListFamilyAnnotationsResponse
 */
export class ListFamilyAnnotationsResponse extends Message<ListFamilyAnnotationsResponse> {
  /**
   * @generated from field: repeated protogen.FamilyAnnotation annotations = 1;
   */
  annotations: FamilyAnnotation[] = [];

  /**
   * @generated from field: repeated protogen.AnnotationFlags automatedFlags = 2;
   */
  automatedFlags: AnnotationFlags[] = [];

  /**
   * @generated from field: repeated protogen.ChangeEventAnnotation changeEvents = 3;
   */
  changeEvents: ChangeEventAnnotation[] = [];

  constructor(data?: PartialMessage<ListFamilyAnnotationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListFamilyAnnotationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "annotations", kind: "message", T: FamilyAnnotation, repeated: true },
    { no: 2, name: "automatedFlags", kind: "message", T: AnnotationFlags, repeated: true },
    { no: 3, name: "changeEvents", kind: "message", T: ChangeEventAnnotation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFamilyAnnotationsResponse {
    return new ListFamilyAnnotationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFamilyAnnotationsResponse {
    return new ListFamilyAnnotationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFamilyAnnotationsResponse {
    return new ListFamilyAnnotationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListFamilyAnnotationsResponse | PlainMessage<ListFamilyAnnotationsResponse> | undefined, b: ListFamilyAnnotationsResponse | PlainMessage<ListFamilyAnnotationsResponse> | undefined): boolean {
    return proto3.util.equals(ListFamilyAnnotationsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListLeadAnnotationsRequest
 */
export class ListLeadAnnotationsRequest extends Message<ListLeadAnnotationsRequest> {
  /**
   * @generated from field: string signupSessionRef = 1;
   */
  signupSessionRef = "";

  constructor(data?: PartialMessage<ListLeadAnnotationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListLeadAnnotationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signupSessionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLeadAnnotationsRequest {
    return new ListLeadAnnotationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLeadAnnotationsRequest {
    return new ListLeadAnnotationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLeadAnnotationsRequest {
    return new ListLeadAnnotationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListLeadAnnotationsRequest | PlainMessage<ListLeadAnnotationsRequest> | undefined, b: ListLeadAnnotationsRequest | PlainMessage<ListLeadAnnotationsRequest> | undefined): boolean {
    return proto3.util.equals(ListLeadAnnotationsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListLeadAnnotationsResponse
 */
export class ListLeadAnnotationsResponse extends Message<ListLeadAnnotationsResponse> {
  /**
   * @generated from field: repeated protogen.FamilyAnnotation annotations = 1;
   */
  annotations: FamilyAnnotation[] = [];

  constructor(data?: PartialMessage<ListLeadAnnotationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListLeadAnnotationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "annotations", kind: "message", T: FamilyAnnotation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLeadAnnotationsResponse {
    return new ListLeadAnnotationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLeadAnnotationsResponse {
    return new ListLeadAnnotationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLeadAnnotationsResponse {
    return new ListLeadAnnotationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListLeadAnnotationsResponse | PlainMessage<ListLeadAnnotationsResponse> | undefined, b: ListLeadAnnotationsResponse | PlainMessage<ListLeadAnnotationsResponse> | undefined): boolean {
    return proto3.util.equals(ListLeadAnnotationsResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyAnnotationRequest
 */
export class CreateFamilyAnnotationRequest extends Message<CreateFamilyAnnotationRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string signupSessionRef = 2;
   */
  signupSessionRef = "";

  /**
   * flagType
   *
   * @generated from field: string content = 3;
   */
  content = "";

  /**
   * @generated from field: string textContent = 4;
   */
  textContent = "";

  /**
   * @generated from field: string contentType = 5;
   */
  contentType = "";

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 6;
   */
  attachments: UploadAttachment[] = [];

  /**
   * @generated from field: string noteType = 7;
   */
  noteType = "";

  /**
   * @generated from field: repeated string flags = 8;
   */
  flags: string[] = [];

  constructor(data?: PartialMessage<CreateFamilyAnnotationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyAnnotationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "signupSessionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
    { no: 7, name: "noteType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "flags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyAnnotationRequest {
    return new CreateFamilyAnnotationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyAnnotationRequest {
    return new CreateFamilyAnnotationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyAnnotationRequest {
    return new CreateFamilyAnnotationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyAnnotationRequest | PlainMessage<CreateFamilyAnnotationRequest> | undefined, b: CreateFamilyAnnotationRequest | PlainMessage<CreateFamilyAnnotationRequest> | undefined): boolean {
    return proto3.util.equals(CreateFamilyAnnotationRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyAnnotationResponse
 */
export class CreateFamilyAnnotationResponse extends Message<CreateFamilyAnnotationResponse> {
  /**
   * @generated from field: protogen.FamilyAnnotation annotation = 1;
   */
  annotation?: FamilyAnnotation;

  constructor(data?: PartialMessage<CreateFamilyAnnotationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyAnnotationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "annotation", kind: "message", T: FamilyAnnotation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyAnnotationResponse {
    return new CreateFamilyAnnotationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyAnnotationResponse {
    return new CreateFamilyAnnotationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyAnnotationResponse {
    return new CreateFamilyAnnotationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyAnnotationResponse | PlainMessage<CreateFamilyAnnotationResponse> | undefined, b: CreateFamilyAnnotationResponse | PlainMessage<CreateFamilyAnnotationResponse> | undefined): boolean {
    return proto3.util.equals(CreateFamilyAnnotationResponse, a, b);
  }
}

