import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Loading from "components/common/Loading";
import { FamilyStatus } from "protogen/advisors_service_pb";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useFetchAdminEngagementReports } from "services/engagement";
import { AdminReportRow } from "components/engagement/AdminReportTable";
import SearchBar from "components/forum/SearchBar";
import ColumnHeader from "../../components/engagement/ColumnHeader";
import { sortFamiliesByFields } from "../../components/engagement/utils";
import { EngagementReport } from "../../protogen/engagement_service_pb";
import Filters, {
  usePersistedFilters,
} from "../../components/engagement/Filters";
import { AccountStub } from "../../protogen/common_pb";
import { Search } from "lucide-react";

const getDistinctAdvisors = (advisors: AccountStub[]) => {
  const distinctAdvisors = [];
  const seen = new Set();
  for (const advisor of advisors) {
    if (!seen.has(advisor.ref)) {
      seen.add(advisor.ref);
      distinctAdvisors.push(advisor);
    }
  }
  return distinctAdvisors;
};

export default () => {
  const isMobile = useIsMobile();
  const [sorts, setSorts] = useState<{ field: string; asc: boolean }[]>([
    // Note the order is how they will be applied, so the first entry is the primary sort.
    { field: "state", asc: true },
    { field: "familyName", asc: true },
  ]);
  const [showSearch, setShowSearch] = useState(false);
  const { request, data, loading } = useFetchAdminEngagementReports();
  useEffect(() => {
    request();
  }, []);
  const filters = usePersistedFilters({});
  const distinctAdvisors = getDistinctAdvisors(
    (data?.reports || []).map((r) => r.advisor),
  );
  const [filteredReports, setFilteredReports] = useState<EngagementReport[]>(
    [],
  );
  const allReports = sortFamiliesByFields(
    filteredReports
      // .sort((a, b) => a.family.name.localeCompare(b.family.name))
      .filter((r) => r.family?.status === FamilyStatus.ACTIVE),
    sorts,
  );

  return (
    <Box
      sx={{
        margin: isMobile ? "" : "0 auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "64px 0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={"24px"}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{}}>
              <Typography variant="display">Family reports</Typography>
            </Box>
            {!isMobile ? (
              <SearchBar
                initialQuery={filters.query}
                expand={true}
                autofocus={true}
                onQuery={filters.setQuery}
                onKeyPress={true}
                sx={{ margin: 0 }}
              />
            ) : (
              <IconButton onClick={() => setShowSearch(true)}>
                <Search />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "12px",
          ...(isMobile
            ? { flexDirection: "column" }
            : {
                direction: "row",
                justifyContent: "space-between",
              }),
        }}
      >
        {isMobile && showSearch && (
          <SearchBar
            initialQuery={filters.query}
            expand={true}
            autofocus={true}
            onQuery={filters.setQuery}
            onKeyPress={true}
            fullWidth={isMobile}
            sx={{ margin: 0 }}
          />
        )}
        <Filters
          reports={data?.reports || []}
          filters={filters}
          advisors={distinctAdvisors}
          onFiltered={(f) => setFilteredReports(f)}
          sx={{ alignSelf: "end" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding={"0 24px"}
          mr="34px"
        >
          <ColumnHeader
            flex={1.5}
            sorts={sorts}
            setSorts={setSorts}
            title="Family"
            sortKey="familyName"
          />
          <ColumnHeader
            flex={1.5}
            sorts={sorts}
            setSorts={setSorts}
            title="Last activity"
            sortKey="lastActivity"
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Usage"
            sortKey="utilization"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Renews"
            sortKey="renewal"
            hideMobile
          />
          <ColumnHeader
            sorts={sorts}
            setSorts={setSorts}
            title="Started"
            sortKey="startDate"
            hideMobile
          />
          <ColumnHeader
            flex={1.5}
            sorts={sorts}
            setSorts={setSorts}
            title="Flags"
            sortKey="flags"
            hideMobile
          />
        </Box>
        {loading && <Loading />}
        {allReports.map((report) => (
          <AdminReportRow key={report.family.ref} report={report} />
        ))}
      </Box>
    </Box>
  );
};
