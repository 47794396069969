import { mergeAttributes, Node } from "@tiptap/core";
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  NodeViewProps,
} from "@tiptap/react";
import { nodePasteRule } from "@tiptap/core"; // Import paste rule utility
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { ExternalLink, X } from "lucide-react";
import { fetchFetchUrlMetadata } from "services/url";
import { TextSelection } from "@tiptap/pm/state";
import { fetchGetDocument } from "services/documents";
import { fetchGetTask } from "services/tasks";
import { fetchFetchPost } from "services/forum";

// Regular expression to detect URLs
const URL_REGEX_GLOBAL = /https?:\/\/[^\s]+/g;

enum UrlDisplay {
  URL,
  PREVIEW,
}

type UrlMetadata = {
  url: string;
  title: string;
  description: string;
  image: string;
  header: string;
};

const fetchGenericUrlMetadata = async (
  url: string,
): Promise<UrlMetadata | null> => {
  try {
    const response = await fetchFetchUrlMetadata(url);
    if (!response || !response.success) {
      return null;
    }
    return {
      url: response.url,
      title: response.title,
      description: response.description,
      image: response.image,
      header: response.siteName || response.domain,
    };
  } catch (error) {
    console.error("Error fetching URL metadata:", error);
    return null;
  }
};

const fetchTaskMetadata = async (
  url: string,
  taskRef: string,
): Promise<UrlMetadata | null> => {
  try {
    const response = await fetchGetTask(taskRef);
    if (!response) {
      return null;
    }
    return {
      url: url,
      title: response.task?.title || "",
      description: response.task?.family?.name || "",
      image: "/assets/images/document-placeholder.png",
      header: "Task",
    };
  } catch (error) {
    console.error("Error fetching URL metadata:", error);
    return null;
  }
};

const fetchPostMetadata = async (
  url: string,
  postRef: string,
): Promise<UrlMetadata | null> => {
  try {
    const response = await fetchFetchPost(postRef);
    if (!response) {
      return null;
    }
    const author = response.post?.author?.displayName;
    return {
      url: url,
      title: response.post?.title || "",
      description: author ? `From ${author}` : "",
      image: "/assets/images/document-placeholder.png",
      header: "Community Post",
    };
  } catch (error) {
    console.error("Error fetching URL metadata:", error);
    return null;
  }
};

const fetchDocumentMetadata = async (
  url: string,
  documentRef: string,
): Promise<UrlMetadata | null> => {
  try {
    const response = await fetchGetDocument(documentRef);
    if (!response) {
      return null;
    }
    const owner = response.document?.owner?.displayName || "";
    return {
      url: url,
      title: response.document?.title || "",
      description: owner ? `By ${owner}` : "",
      image: "/assets/images/document-placeholder.png",
      header: "Document",
    };
  } catch (error) {
    console.error("Error fetching URL metadata:", error);
    return null;
  }
};

const getUrlMetadata = async (url: string): Promise<UrlMetadata | null> => {
  let parsedUrl;
  try {
    // Parse URL to handle variations correctly
    parsedUrl = new URL(url, window.location.origin);
  } catch (error) {
    console.error("Invalid URL:", url);
    return null;
  }
  const isSameHost =
    parsedUrl.hostname.replace(/^www\./, "") ===
    window.location.hostname.replace(/^www\./, "");
  const decodedPath = decodeURIComponent(parsedUrl.pathname);
  // Match and extract task ID if URL matches /tasks/:id
  // Post / comment?
  const taskMatch = decodedPath.match(
    /\/tasks\/([A-Za-z0-9_-]+={0,2})(?:[?#].*)?$/,
  );
  const docsMatch = decodedPath.match(
    /\/documents\/([A-Za-z0-9_-]+={0,2})(?:[?#].*)?$/,
  );
  const forumPostMatch = decodedPath.match(
    /\/community\/([A-Za-z0-9_-]+={0,2})(?:[?#].*)?$/,
  );

  if (isSameHost && taskMatch) {
    return await fetchTaskMetadata(url, taskMatch[1]); // Fetch metadata for task
  } else if (isSameHost && docsMatch) {
    return await fetchDocumentMetadata(url, docsMatch[1]); // Fetch metadata for task
  } else if (isSameHost && forumPostMatch) {
    return await fetchPostMetadata(url, forumPostMatch[1]); // Fetch metadata for task
  } else {
    return await fetchGenericUrlMetadata(url);
  }
};

const URLPreviewCard = ({
  url,
  onClose,
  node,
  updateAttributes,
  isEditable,
  editorWidth,
}: {
  url: string;
  onClose: () => void;
  node: any;
  updateAttributes: (attrs: any) => void;
  isEditable: boolean;
  editorWidth: number | null;
}) => {
  const [isLoading, setIsLoading] = useState(!node.attrs.loaded);
  const [metadata, setMetadata] = useState<UrlMetadata | null>(
    node.attrs.loaded
      ? {
          url: url,
          title: node.attrs.title,
          description: node.attrs.description,
          image: node.attrs.image,
          header: node.attrs.header,
        }
      : null,
  );

  useEffect(() => {
    if (node.attrs.loaded) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    getUrlMetadata(url).then((meta) => {
      if (meta) {
        setMetadata(meta);
        // Update node attributes with metadata
        updateAttributes({
          ...meta,
          loaded: true,
        });
      } else {
        onClose();
      }
      setIsLoading(false);
    });
  }, [url]);

  const getContent = () => {
    if (isLoading || !metadata) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flexGrow={1}
        >
          <CircularProgress size={24} />
        </Box>
      );
    } else {
      return (
        <>
          {metadata.image && (
            <Box
              component="img"
              src={metadata.image}
              alt={metadata.title}
              sx={{
                flexShrink: 0,
                objectFit: "cover",
              }}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              flexShrink: 1,
              marginLeft: "16px",
              marginRight: "6px",
            }}
          >
            <Typography
              variant="bodyHeavy"
              color="#198282"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "14px",
              }}
            >
              {metadata.header}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {metadata.title}
            </Typography>
            <Typography
              variant="bodySmallHeavy"
              color="text.secondary"
              sx={{
                fontWeight: "600",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {metadata.description}
            </Typography>
          </Box>
        </>
      );
    }
  };
  return (
    <Box
      sx={{
        borderRadius: "12px",
        border: "1px solid #D4D4D4",
        background: "#FFF",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
        cursor: "pointer",
        paddingRight: "12px",
        "&:hover": {
          bgcolor: "action.hover",
        },
        height: "92px",
        margin: "12px 0",
        width: editorWidth ? `${editorWidth}px` : "350px",
      }}
      onClick={() => window.open(url, "_blank")}
      tabIndex={-1}
    >
      {getContent()}
      {isEditable ? (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <X size={16} />
        </IconButton>
      ) : (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer", display: "block", flexShrink: 0 }}
        >
          <ExternalLink size={20} color="#8E9598" />
        </a>
      )}
    </Box>
  );
};

const URLThumbnailComponent = ({
  node,
  updateAttributes,
  view,
  getPos,
}: NodeViewProps) => {
  const handleClose = () => {
    // Create a link mark with the URL
    const linkMark = view.state.schema.marks.link.create({
      href: node.attrs.url,
    });

    // Replace the node with linked text and set cursor at the end
    const transaction = view.state.tr.replaceWith(
      getPos(),
      getPos() + node.nodeSize,
      view.state.schema.text(node.attrs.url, [linkMark]),
    );

    // Calculate the correct end position after the replacement
    const endPos = getPos() + node.attrs.url.length;
    const resolvedPos = transaction.doc.resolve(endPos);

    // Set cursor at the end of the inserted text
    transaction.setSelection(TextSelection.near(resolvedPos));

    view.dispatch(transaction);
  };

  // We want 100% width, but don't have control over the parent to also set width,
  // We get the tiptap container width before we render the card.
  const getEditorWidth = () => {
    const editorElement = view.dom.closest(".ProseMirror");
    if (editorElement) {
      return editorElement.getBoundingClientRect().width;
    }
    return null;
  };

  const editorWidth = getEditorWidth();

  return (
    <NodeViewWrapper className="url-preview-component">
      <URLPreviewCard
        url={node.attrs.url}
        onClose={() => {
          handleClose();
        }}
        node={node}
        updateAttributes={updateAttributes}
        isEditable={view.editable}
        editorWidth={editorWidth}
      />
    </NodeViewWrapper>
  );
};

export default Node.create({
  name: "thumbnailURL",
  group: "inline",
  inline: true,
  atom: true, // Marks this as a leaf node

  addOptions() {
    return {
      addPasteHandler: true, // Default to enabling paste handling
    };
  },

  addAttributes() {
    return {
      url: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-url"),
        renderHTML: (attributes) => {
          if (!attributes.url) {
            return {};
          }
          return { "data-url": attributes.url };
        },
      },
      displayMode: {
        default: UrlDisplay.PREVIEW,
        parseHTML: (element) => element.getAttribute("data-display-mode"),
        renderHTML: (attributes) => {
          if (!attributes.displayMode) {
            return {};
          }
          return { "data-display-mode": attributes.displayMode };
        },
      },
      title: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-title"),
        renderHTML: (attributes) => {
          if (!attributes.title) {
            return {};
          }
          return { "data-title": attributes.title };
        },
      },
      header: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-header"),
        renderHTML: (attributes) => {
          return { "data-header": attributes.header };
        },
      },
      description: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-description"),
        renderHTML: (attributes) => {
          if (!attributes.description) {
            return {};
          }
          return { "data-description": attributes.description };
        },
      },
      image: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-image"),
        renderHTML: (attributes) => {
          if (!attributes.image) {
            return {};
          }
          return { "data-image": attributes.image };
        },
      },
      loaded: {
        default: false,
        parseHTML: (element) => element.getAttribute("data-loaded") === "true",
        renderHTML: (attributes) => {
          return { "data-loaded": attributes.loaded };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a[data-url][data-display-mode="1"]',
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const title = HTMLAttributes["data-title"] || HTMLAttributes["data-url"];
    return [
      "a",
      mergeAttributes(HTMLAttributes, {
        href: HTMLAttributes["data-url"], // Add href attribute
        target: "_blank",
        rel: "noopener noreferrer",
      }),
      title, // Set inner content to title or fallback to URL
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(URLThumbnailComponent);
  },

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: URL_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          return {
            url: match[0],
            displayMode: UrlDisplay.PREVIEW,
          };
        },
      }),
    ];
  },
});
