import { forwardRef, useImperativeHandle } from "react";
import { ChoiceSelections, StepProps, StepRef } from "../types";
import ChoiceStep from "../components/ChoiceStep";
import {
  ListTodo,
  Cross,
  Plane,
  Home,
  PartyPopper,
  CircleDollarSign,
} from "lucide-react";

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }: StepProps, ref) => {
    useImperativeHandle(ref, () => ({}));
    return (
      <ChoiceStep
        title={"What would you like to accomplish with a Family Advisor?"}
        subtitle={"Choose as many as you’d like."}
        disabled={disabled}
        choiceOptions={[
          {
            id: "mental-load",
            text: "Reduce my mental load",
            icon: <ListTodo />,
          },
          {
            id: "maintenance",
            text: "Keep up with maintenance",
            icon: <Home />,
          },
          {
            id: "fun",
            text: "Add more fun to my life",
            icon: <PartyPopper />,
          },
          {
            id: "health",
            text: "Stay on top of my health",
            icon: <Cross />,
          },
          { id: "travel", text: "Organize travel plans", icon: <Plane /> },
          {
            id: "save-money",
            text: "Save money by being proactive",
            icon: <CircleDollarSign />,
          },
        ]}
        onNext={async (choices: ChoiceSelections) => {
          return onNext?.({ goals: choices });
        }}
        initialChoices={signupState?.goals || undefined}
      />
    );
  },
);
