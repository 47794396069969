import { forwardRef } from "react";
import { StepProps, StepRef } from "../../types";
import OpenTextStep from "../../components/OpenTextStep";
import useIsMobile from "components/hooks/useIsMobile";

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState }, _ref) => {
    const isMobile = useIsMobile();

    return (
      <OpenTextStep
        title={"Tell us more about your family"}
        subtitle={
          "We'll match you with an Advisor experienced in serving families like yours."
        }
        placeholder={
          isMobile
            ? "ex. Occupations, children, pets"
            : "ex. I'm a marketer at Google, and my spouse is a lawyer who works remotely. We have two daughters, 3 and 7 years old. We have a golden retriever and a cat."
        }
        skippable={true}
        onNext={async (inputText) => {
          return onNext?.({ familyInfo: inputText });
        }}
        initialText={signupState?.familyInfo}
      />
    );
  },
);
