import ReactiveDialog from "../../common/ReactiveDialog";
import { Box, Typography } from "@mui/material";
import {
  useChangeFamilyPlan,
  useGetFamilyDetails,
} from "../../../services/advisor";
import Loading from "../../common/Loading";
import { useEffect, useState } from "react";
import { BillingProduct } from "protogen/billing_pb";
import { useListBillingProducts } from "../../../services/billing";
import { Select, MenuItem } from "@mui/material";
import { ChevronDown } from "lucide-react";
import { PlanUtilization } from "protogen/common_pb";
import { Family } from "protogen/advisors_service_pb";

type Props = {
  familyRef: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: (family: Family) => void;
};

const ChangePlanContent = ({
  currentProduct,
  onProductChange,
  disabled = false,
  planUtilization,
}: {
  currentProduct?: BillingProduct;
  onProductChange: (product: BillingProduct) => void;
  disabled?: boolean;
  planUtilization?: PlanUtilization;
}) => {
  const { request, data, loading } = useListBillingProducts();

  useEffect(() => {
    if (!data) {
      request();
    }
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <Loading />
      </Box>
    );
  }

  const products = data?.products || [];
  const mainOfferings = new Set([
    // Prod.
    "prod_QGpeOl3cDuxaiZ", // Total Game Changer
    "prod_QKBrlo0j4DuINP", // Free Time Finder
    "prod_QTbbvlRkHU1GKk", // Peace of Mind
    // Dev.
    "prod_QZAxZCP1JFyGGW",
    "prod_QZAx7GkKumuhHy",
    "prod_QZAvacnqgiPvls",
  ]);

  const nextBillingDateStr = new Date(
    planUtilization?.periodEndDate || 0,
  ).toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  });

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Typography variant="body">
        Changes to subscriptions will take effect on the next billing date,{" "}
        <strong>{nextBillingDateStr}</strong>. Reach out to support for any
        changes that should occur in the current billing period.
      </Typography>
      <Typography variant="bodyHeavy">
        Select the new plan for this family
      </Typography>
      <Select
        label="Service tier"
        margin="none"
        displayEmpty
        disabled={disabled}
        defaultValue={currentProduct?.id || ""}
        onChange={(e) => {
          const val = e.target.value;
          const product = products.find((p) => p.id === val);
          if (product) {
            onProductChange(product);
          }
        }}
        sx={{
          "& .MuiInputBase-input": {
            marginTop: "0px !important",
          },
          width: "100%",
          height: "60px",
        }}
        IconComponent={ChevronDown}
      >
        <MenuItem key={""} value={""} disabled>
          Service tier
        </MenuItem>
        {products
          .filter((p) => mainOfferings.has(p.id))
          .sort((a, b) => a.amountCents - b.amountCents)
          .map((product) => (
            <MenuItem
              key={product.id}
              value={product.id}
              disabled={product.id === currentProduct?.id}
            >
              <div>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "600", marginRight: "6px" }}>
                    ${`${(product.amountCents / 100).toFixed(2)}`}/mo
                  </span>
                  {product.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.description}
                </Typography>
              </div>
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};

export default ({ familyRef, open, setOpen, onUpdate }: Props) => {
  const {
    data: familyData,
    request: requestFamily,
    loading: loadingFamily,
  } = useGetFamilyDetails();
  const [selectedProduct, setSelectedProduct] = useState<BillingProduct>();
  const { request: requestChangePlan, loading: loadingChangePlan } =
    useChangeFamilyPlan();
  useEffect(() => {
    if (open) {
      requestFamily({ familyRef });
    }
  }, [open, familyRef]);

  const onPlanChange = async () => {
    if (!selectedProduct || !familyData?.family) return;
    const resp = await requestChangePlan({
      familyRef,
      product: selectedProduct,
    });
    if (resp?.family) {
      onUpdate(resp.family);
    }
  };

  return (
    <ReactiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title="Change family plan"
      primaryActionName="Change Plan"
      primaryActionEnabled={
        loadingChangePlan ||
        loadingFamily ||
        (!!familyData?.family &&
          !!selectedProduct?.id &&
          selectedProduct?.id !== familyData?.family.billingInfo?.product?.id)
      }
      primaryAction={onPlanChange}
      fullWidthSize="sm"
    >
      {loadingFamily ? (
        <Box display="flex" justifyContent="center" p={3}>
          <Loading />
        </Box>
      ) : familyData?.family ? (
        <ChangePlanContent
          disabled={loadingChangePlan}
          currentProduct={familyData.family.billingInfo?.product}
          onProductChange={setSelectedProduct}
          planUtilization={familyData.planUtilization}
        />
      ) : null}
    </ReactiveDialog>
  );
};
