import React, { ReactNode, useState } from "react";
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import MaybeTransition from "./MaybeTransition";
import useIsMobile from "../hooks/useIsMobile";
import useIsDesktop from "../hooks/useIsDesktop";
import { PAGE_FRAME_HEIGHT } from "common/constants";

export enum Panel {
  Left,
  Right,
}

const StyledHeader = ({ children }: { children: ReactNode }) => {
  const isMobile = useIsMobile();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        display: "inline-flex",
        padding: isMobile ? "12px 20px 0" : "0 20px",
        borderRadius: isMobile ? 0 : "16px 0 0 0",
        background: "white",
      }}
    >
      {children}
    </Box>
  );
};

type PanelProps = {
  content: React.ReactNode;
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  switchContent?: React.ReactNode | string;
  sx?: React.CSSProperties;
};

type TwoPanelGridProps = {
  leftPanel: PanelProps;
  rightPanel?: PanelProps;
  defaultPanel?: Panel;
  onChangePanel?: (panel: Panel) => void;
  headerAction?: ReactNode;
  showHeaderAction?: boolean;
};
export default ({
  leftPanel,
  rightPanel,
  onChangePanel,
  headerAction,
  showHeaderAction,
  defaultPanel = Panel.Left,
}: TwoPanelGridProps) => {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const [panel, setPanel] = useState<Panel>(defaultPanel);
  const changePanel = (panel: Panel) => {
    setPanel(panel);
    onChangePanel && onChangePanel(panel);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        height: isMobile ? "calc(100vh - 51px)" : PAGE_FRAME_HEIGHT,
        outline: "1px solid #FDFAF7",
      }}
    >
      {isMobile && (
        <Box>
          <StyledHeader>
            <Box display="flex" flexDirection="column" width="100%">
              {panel === Panel.Left ? leftPanel.title : rightPanel?.title}
              {panel === Panel.Left ? leftPanel.subtitle : rightPanel?.subtitle}
            </Box>
            {showHeaderAction && headerAction}
          </StyledHeader>

          {leftPanel?.switchContent && rightPanel?.switchContent && (
            <Box
              sx={{
                display: "flex",
                padding: "12px 16px 4px",
                boxShadow: "0px 2px 8px 0px rgba(151, 93, 51, 0.10)",
              }}
            >
              <ButtonGroup
                sx={{
                  display: "flex",
                  width: "100%",
                  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
                }}
              >
                <Button
                  sx={{
                    marginRight: "10px",
                    height: "40px",
                    width: "100%",
                    padding: 0,
                  }}
                  variant={
                    panel === Panel.Left ? "buttonGroupSelected" : "buttonGroup"
                  }
                  onClick={() => changePanel(Panel.Left)}
                >
                  {leftPanel.switchContent}
                </Button>
                <Button
                  sx={{
                    height: "40px",
                    width: "100%",
                    padding: 0,
                  }}
                  variant={
                    panel === Panel.Right
                      ? "buttonGroupSelected"
                      : "buttonGroup"
                  }
                  onClick={() => changePanel(Panel.Right)}
                >
                  {rightPanel?.switchContent}
                </Button>
              </ButtonGroup>
            </Box>
          )}
        </Box>
      )}
      <Grid
        container
        spacing={0}
        columnSpacing={isMobile ? "0" : "12px"}
        sx={{
          background: "#FDFAF7",
          height: "100%",
          marginLeft: 0,
          width: "100%",
          overflow: "hidden",
        }}
      >
        <MaybeTransition visible={panel === Panel.Left}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: !isDesktop ? "0" : "32px 12px 0",
              borderRadius: isMobile ? "0" : "16px 16px 0 0",
              border: isMobile ? undefined : "1px solid #EAECF0",
              background: "white",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            {showHeaderAction && (
              <StyledHeader>
                <Box display="flex" flexDirection="column" width="100%">
                  {leftPanel.subtitle}
                  {leftPanel.title}
                </Box>
                {headerAction}
              </StyledHeader>
            )}
            {leftPanel.content}
          </Grid>
        </MaybeTransition>
        <MaybeTransition visible={panel === Panel.Right}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              ...rightPanel?.sx,
            }}
          >
            {rightPanel && (
              <Box
                sx={{
                  borderTop: "1px solid #EAEBEC",
                  borderLeft: "1px solid #EAEBEC",
                  borderRadius: isMobile ? 0 : "16px 0 0 0",
                  overflow: "hidden",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!isMobile && (rightPanel.title || rightPanel.subtitle) && (
                  <StyledHeader>
                    <Box display="flex" flexDirection="column" width="100%">
                      {rightPanel.title}
                      {rightPanel.subtitle}
                    </Box>
                  </StyledHeader>
                )}
                <Box
                  sx={{
                    background: "white",
                    height: "100%",
                  }}
                >
                  {rightPanel.content}
                </Box>
              </Box>
            )}
          </Grid>
        </MaybeTransition>
      </Grid>
    </Box>
  );
};
