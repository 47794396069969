import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CurrentUserAvatar, {
  AvatarBadgeType,
} from "../common/CurrentUserAvatar";
import { ReactComponent as InboxIcon } from "../../icons/Menu/Inbox.svg";
import { ReactComponent as DoubleBubbleIcon } from "../../icons/Menu/DoubleBubble.svg";
import { ReactComponent as FayeIcon } from "../../icons/FayeBrand.svg";
import { ReactComponent as TodoListIcon } from "../../icons/Menu/TodoList.svg";
import { ReactComponent as DocumentIcon } from "../../icons/Menu/Document.svg";
import { CalendarIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { ReactNode, useContext, useEffect, useState } from "react";
import SearchDialog from "../search/SearchDialog";
import NavItem from "./NavItem";
import DrawerHeader from "./DrawerHeader";
import LinkRouter from "../navigation/LinkRouter";
import FamiliesNavEntry from "./FamiliesNavEntry";
import { Badge, Box } from "@mui/material";
import { StatusContext } from "../context/StatusContextProvider";
import ToolsNavEntry from "./ToolsNavEntry";
import { CircleHelp } from "lucide-react";

const isThereAnOpenModal = () => {
  return (
    document.querySelectorAll(
      '.MuiDialog-container[role="presentation"]:not([style*="visibility: hidden"])',
    ).length > 0
  );
};
const isCursorInInputOrTextarea = () => {
  const activeElement = document.activeElement;
  if (
    activeElement?.tagName === "INPUT" ||
    activeElement?.tagName === "TEXTAREA"
  ) {
    return true;
  }
  // Check tiptap editors.
  const editorElements = document.querySelectorAll(".ProseMirror");
  for (let i = 0; i < editorElements.length; i++) {
    if (editorElements[i].contains(activeElement)) {
      return true;
    }
  }
  return false;
};

const SearchNavEntry = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const [selected, setSelected] = useState(false);
  // Note(Kip): We made the choice to remove this en lieu of browser default.
  // Event listener to trigger the modal on Ctrl + F
  const handleKeyDown = (event: KeyboardEvent) => {
    if (window.location.pathname === "/search") return;
    if (isCursorInInputOrTextarea()) return;
    if (isThereAnOpenModal()) return;
    if (!selected && (event.ctrlKey || event.metaKey) && event.key === "k") {
      event.preventDefault(); // Prevent the browser's default find behavior
      setSelected(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Only add and remove the event listener once on mount and unmount

  return (
    <>
      <NavItem
        selected={false}
        onClick={() => {
          close();
          setSelected(true);
        }}
        open={open}
        icon={
          <MagnifyingGlassIcon height="20px" width="20px" stroke="#6B6E7B" />
        }
        text="Search"
      />
      <SearchDialog open={selected} onClose={() => setSelected(false)} />
    </>
  );
};

export const NotificationBadgedButton = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { badgeCount } = useContext(StatusContext);
  // Custom badge content
  const CustomBadge = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      style={{ marginLeft: 6 }}
    >
      <circle cx="4" cy="4" r="4" fill="#EF7B77" />
    </svg>
  );

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={badgeCount && badgeCount > 0 ? <CustomBadge /> : null}
    >
      {children}
    </Badge>
  );
};

type AdvisorDrawerContentProps = {
  isPersistent: boolean;
  selectedTab: string;
  open: boolean;
  setClosed(): void;
  isMobile: boolean;
};

export default ({
  isPersistent,
  selectedTab,
  open,
  setClosed,
  isMobile,
}: AdvisorDrawerContentProps) => {
  const {
    emailUnreadCount,
    phoneUnreadCount,
    messageUnreadCount,
    taskUnreadCount,
    datebookUnreadCount,
  } = useContext(StatusContext);
  const inboxCount = emailUnreadCount + phoneUnreadCount + messageUnreadCount;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#FDFAF7",
      }}
    >
      <DrawerHeader>
        {isPersistent && (
          <LinkRouter to="/">
            <FayeIcon
              style={{
                height: "26px",
                margin: "12px 0px",
              }}
            />
          </LinkRouter>
        )}
        {!isPersistent && (
          <IconButton
            onClick={() => setClosed()}
            sx={{ ml: "auto", marginRight: "10px" }}
          >
            <CloseIcon sx={{ color: "#545F71" }} />
          </IconButton>
        )}
      </DrawerHeader>
      <Box
        sx={{
          paddingTop: "17px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          /* Hide scrollbar for IE, Edge, and Firefox */
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <List sx={{ padding: 0 }}>
          <NavItem
            selected={
              selectedTab === "/community" ||
              selectedTab.startsWith("/community/")
            }
            open={open}
            onClick={setClosed}
            icon={<DoubleBubbleIcon height="20px" width="20px" />}
            text="Community"
            url="/community"
          />
          <NavItem
            selected={
              selectedTab === "/inbox" || selectedTab.startsWith("/inbox/")
            }
            open={open}
            onClick={setClosed}
            icon={<InboxIcon height="20px" width="20px" />}
            text="Inbox"
            actionIndicator={inboxCount > 0}
            url="/inbox"
          />
          <NavItem
            selected={
              selectedTab === "/tasks" || selectedTab.startsWith("/tasks/")
            }
            open={open}
            onClick={setClosed}
            icon={<TodoListIcon height="20px" width="20px" />}
            actionIndicator={taskUnreadCount > 0}
            text="Tasks"
            url="/tasks"
          />
          <NavItem
            selected={
              selectedTab === "/calendar" ||
              selectedTab.startsWith("/calendar/")
            }
            open={open}
            onClick={setClosed}
            icon={<CalendarIcon height="20px" width="20px" stroke="#6B6E7B" />}
            actionIndicator={datebookUnreadCount > 0}
            text="Calendar"
            url="/calendar"
          />
          <NavItem
            selected={
              selectedTab === "/documents" ||
              selectedTab.startsWith("/documents/")
            }
            open={open}
            onClick={setClosed}
            icon={<DocumentIcon height="20px" width="20px" stroke="#6B6E7B" />}
            text="Documents"
            url="/documents"
          />
          <FamiliesNavEntry
            selected={selectedTab === "/families"}
            open={open}
            onClick={setClosed}
          />
          {/*<NavItem selected={selectedTab === '/playbooks'} open={open} close={setClosed} icon={<LayersIcon />} text="Playbooks" url="/playbooks" />*/}
          {isMobile && (
            <ToolsNavEntry
              selected={
                selectedTab === "/tools" ||
                (selectedTab.startsWith("/tools/") &&
                  !(
                    selectedTab === "/tools/family-directory" ||
                    selectedTab === "/tools/advisor-administration" ||
                    selectedTab === "/tools/pipeline-management"
                  ))
              }
              open={open}
              onClick={setClosed}
            />
          )}
        </List>
        <List
          sx={{
            display: "flex",
            padding: "0px 0x 24px 0px",
            flexDirection: "column",
            alignItems: "flex-start",
            alignSelf: "stretch",
          }}
        >
          <SearchNavEntry open={open} close={setClosed} />
          {!isMobile && (
            <ToolsNavEntry
              selected={
                selectedTab === "/tools" ||
                (selectedTab.startsWith("/tools/") &&
                  !(
                    selectedTab === "/tools/family-directory" ||
                    selectedTab === "/tools/advisor-administration"
                  ))
              }
              open={open}
              onClick={setClosed}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingRight: "20px",
            }}
          >
            <NavItem
              selected={selectedTab === "/settings"}
              onClick={setClosed}
              open={open}
              icon={
                <CurrentUserAvatar
                  size={28}
                  badgeType={AvatarBadgeType.PHONE_STATE}
                />
              }
              text="Profile"
              url="/settings"
            />
            <LinkRouter to="/advisor/help">
              <CircleHelp size={20} color="#8E9598" />
            </LinkRouter>
          </Box>
        </List>
      </Box>
    </div>
  );
};
