import React, { forwardRef, useState } from "react";
import { StepRef, StepProps } from "../types";
import { Box, Button, TextField } from "@mui/material";
import PhoneInput from "../../creation/PhoneInput";
import { cleanPhone } from "../../../common/utils";
import useIsMobile from "../../hooks/useIsMobile";
import StaticStep from "../components/StaticStep";

const validZipCode = (zipCode: string) => {
  // Regex for a 5-digit zip code. Adjust as necessary if you want to allow more formats.
  const zipCodeRegex = /^\d{5}$/;
  return !(!zipCode || !zipCodeRegex.test(zipCode));
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  zipCode: string;
};

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState, disabled }, _ref) => {
    const isMobile = useIsMobile();
    const [loading, setLoading] = useState(false);
    const [previousPhone, setPreviousPhone] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<FormData>({
      firstName: signupState?.consultationDetails?.firstName || "",
      lastName: signupState?.consultationDetails?.lastName || "",
      email: signupState?.consultationDetails?.email || "",
      phone: signupState?.consultationDetails?.phone || "",
      zipCode: signupState?.consultationDetails?.zipCode || "",
    });
    const handleNext = () => {
      const newErrors: { [key: string]: string } = {};
      if (!formData.firstName) {
        newErrors.firstName = "First name is required";
      }
      if (!formData.lastName) {
        newErrors.lastName = "Last name is required";
      }
      if (!formData.email) {
        newErrors.email = "Email is required";
      }
      if (!formData.phone) {
        newErrors.phone = "Phone is required";
      }
      if (!validZipCode(formData.zipCode)) {
        newErrors.zipCode = "Zip code is required";
      }
      if (Object.keys(newErrors).length) {
        setErrors(newErrors);
        return;
      }
      setLoading(true);
      onNext?.({ consultationDetails: formData });
      setLoading(false);
    };
    return (
      <StaticStep
        title="Book your free consultation"
        img="/assets/images/nux/consultation-3.png"
        bodyContent={
          <>
            <>
              We’ll go over your to-do list, chat through available Advisors,
              and recommend a plan that best suits your needs.
            </>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile ? "center" : "space-between",
                maxWidth: "570px",
              }}
            >
              <form
                autoComplete="on"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await handleNext();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "32px",
                      display: "flex",
                      flexDirection: "column",
                      gap: isMobile ? "12px" : "18px",
                    }}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      sx={{
                        gap: isMobile ? "12px" : "20px",
                      }}
                    >
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        margin="none"
                        label="First name"
                        name="firstName"
                        variant="outlined"
                        autoComplete="given-name"
                        value={formData.firstName || ""}
                        error={!!errors?.firstName}
                        helperText={errors?.firstName}
                        onChange={(e) =>
                          setFormData((f) => ({
                            ...f,
                            firstName: e.target.value,
                          }))
                        }
                      />
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        margin="none"
                        label="Last name"
                        name="lastName"
                        variant="outlined"
                        autoComplete="family-name"
                        value={formData.lastName || ""}
                        error={!!errors?.lastName}
                        helperText={errors?.lastName}
                        onChange={(e) =>
                          setFormData((f) => ({
                            ...f,
                            lastName: e.target.value,
                          }))
                        }
                      />
                    </Box>
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      margin="none"
                      label="Email"
                      name="email"
                      type="email"
                      variant="outlined"
                      autoComplete="email"
                      value={formData.email || ""}
                      error={!!errors?.email}
                      helperText={errors?.email}
                      onChange={(e) =>
                        setFormData((f) => ({ ...f, email: e.target.value }))
                      }
                    />
                    <PhoneInput
                      value={formData.phone || ""}
                      error={!!errors?.phone}
                      helperText={errors?.phone}
                      onChange={(phone) => {
                        let newPhone = cleanPhone(
                          typeof phone == "string" ? phone : phone.target.value,
                        );
                        // Handle auto complete where the 1 country code is not included
                        if (
                          (!previousPhone || previousPhone.length === 1) &&
                          newPhone.length > 10 &&
                          !newPhone.startsWith("+1")
                        ) {
                          newPhone = `+1${newPhone.replace("+", "")}`;
                        }
                        setFormData((f) => ({
                          ...f,
                          phone: newPhone,
                        }));
                        setPreviousPhone(newPhone);
                      }}
                      name="phone"
                      label="Phone"
                      size="small"
                      sx={{
                        width: "100%",
                        flexGrow: 1,
                      }}
                      autoComplete="tel"
                    />
                    <TextField
                      sx={{
                        width: "100%",
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input": {
                          MozAppearance: "textfield",
                        },
                      }}
                      margin="none"
                      label="Zip code"
                      name="zipCode"
                      type="text"
                      variant="outlined"
                      autoComplete="zipcode postal-code"
                      value={formData.zipCode || ""}
                      error={!!errors?.zipCode}
                      helperText={errors?.zipCode}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]{0,5}", // Accepts 0 to 5 digits
                        maxLength: 5, // Prevents more than 5 characters from being entered
                      }}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          zipCode: e.target.value,
                        }))
                      }
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (!validZipCode(value)) {
                          setErrors((prev) => ({
                            ...prev,
                            zipCode: "Please enter a valid 5-digit zip code.",
                          }));
                        } else {
                          setErrors((prev) => ({ ...prev, zipCode: "" }));
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    mt: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    gap: "8px",
                    marginBottom: isMobile ? "22px" : undefined,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size={isMobile ? "medium" : "large"}
                    disabled={loading || disabled}
                    onClick={handleNext}
                    sx={{
                      bgcolor: "#198282",
                      "&:hover": {
                        bgcolor: "#276767",
                      },
                      borderRadius: "32px",
                      textTransform: "none",
                      width: isMobile ? "100%" : "auto",
                    }}
                    type={"submit"}
                  >
                    Continue
                  </Button>
                </Box>
              </form>
            </Box>
          </>
        }
      />
    );
  },
);
