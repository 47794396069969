import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import set from "lodash/set";
import {
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Eye, EyeOff } from "lucide-react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { SecretFormHandle, SecretFormProps } from "../types";
import { AddressForm } from "../../common/types";
import {
  CvvInput,
  ExpiryDateInput,
  CreditCardNumberInput,
} from "../../common/MaskedFields";
import { getFormattedLocation } from "../../family/utils";

type CreditCardForm = {
  title?: string;
  number?: string;
  cvv?: string;
  expiration?: string;
  name?: string;
  altAddress?: AddressForm;
  notes?: string;
  [key: string]: string | AddressForm | undefined;
};
export default forwardRef<SecretFormHandle, SecretFormProps>(
  ({ secret, updateFormData, errors, family }: SecretFormProps, ref) => {
    const parsed: CreditCardForm =
      secret.value === "" ? {} : JSON.parse(secret.value);
    useImperativeHandle(ref, () => ({
      isValid: () => {
        return secret.name !== "";
      },
    }));
    const [showCardInfo, setShowCardInfo] = React.useState(false);
    const [expiration, setExpiration] = React.useState(parsed.expiration || "");
    const [showDifferentBillingAddress, setShowDifferentBillingAddress] =
      useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [formattedAddress, setFormattedAddress] = useState<string | null>(
      null,
    );
    const creditCardInputRef = useRef<HTMLDivElement | null>(null);

    const createChangeHandler = (path: string[], value: string | null) => {
      const updatedParsed = { ...parsed };

      set(updatedParsed, path, value);

      const form = {
        ...secret,
        value: JSON.stringify(updatedParsed),
      };
      updateFormData(form);
    };

    // open address and notes if there is a value
    useEffect(() => {
      if (parsed.notes && parsed.notes.length > 0) {
        setShowNotes(true);
      }

      if (
        Object.values(parsed?.altAddress || []).some(
          (value) => value !== undefined,
        )
      ) {
        setShowDifferentBillingAddress(true);
      }
    }, [parsed]);

    useEffect(() => {
      const address = getFormattedLocation(family?.address || "");
      if (address) {
        setFormattedAddress(address);
      }
    }, [family]);

    const handleExpirationChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const value = event.target.value;
      const isValidPartial =
        /^((0[1-9]|1[0-2])\/([0-9][0-9])?[0-9]{0,2}|(0[1-9]|1[0-2])|[0-9]{0,2})$/.test(
          value,
        );

      console.log(isValidPartial, value);
      if (isValidPartial || value === "") {
        setExpiration(value);
        createChangeHandler(["expiration"], event.target.value);
      }
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <TextField
          sx={{ margin: "0px" }}
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={secret.name}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => updateFormData({ ...secret, name: e.target.value })}
        />
        <TextField
          inputRef={creditCardInputRef}
          sx={{ margin: "0px" }}
          margin="dense"
          label="Card number"
          type="text"
          fullWidth
          variant="outlined"
          value={parsed.number || ""}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => createChangeHandler(["number"], e.target.value)}
          InputProps={{
            inputComponent: CreditCardNumberInput as any,
            inputProps: {
              showCardInfo: showCardInfo,
              value: parsed.number,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCardInfo((prev) => !prev)}>
                  {showCardInfo ? <EyeOff /> : <Eye />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <TextField
            sx={{
              width: "50%",
              margin: "0px",
            }}
            margin="dense"
            label="CVV"
            type="text"
            variant="outlined"
            value={parsed.cvv || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["cvv"], e.target.value)}
            InputProps={{
              inputComponent: CvvInput as any,
              inputProps: {
                showCardInfo: showCardInfo,
                value: parsed.cvv,
              },
            }}
          />
          <TextField
            sx={{
              width: "50%",
              margin: "0px",
            }}
            margin="dense"
            label="Expiration"
            type="text"
            variant="outlined"
            value={expiration}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={handleExpirationChange}
            placeholder="MM/YY"
            InputProps={{
              inputComponent: ExpiryDateInput as any,
              inputProps: {
                showCardInfo: showCardInfo,
                value: expiration,
              },
            }}
          />
        </Box>
        <TextField
          sx={{ margin: "0px" }}
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={parsed.name || ""}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => createChangeHandler(["name"], e.target.value)}
        />
        {formattedAddress && !showDifferentBillingAddress && (
          <Box>
            <Typography variant="bodyHeavy">Home address</Typography>
            <Typography variant="body">{formattedAddress}</Typography>
          </Box>
        )}
        {!showDifferentBillingAddress && (
          <Button
            fullWidth
            variant="text"
            onClick={() => {
              setShowDifferentBillingAddress(!showDifferentBillingAddress);
            }}
            startIcon={<AddIcon />}
            sx={{
              justifyContent: "flex-start",
              paddingLeft: "0px",
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            Different billing address
          </Button>
        )}
        {showDifferentBillingAddress && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="bodyHeavy">Billing address</Typography>
              <IconButton
                onClick={() => {
                  setShowDifferentBillingAddress(false);
                  createChangeHandler(["altAddress"], null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              sx={{ margin: "0px" }}
              margin="dense"
              label="Street address"
              type="text"
              fullWidth
              variant="outlined"
              value={parsed.altAddress?.street1 || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) =>
                createChangeHandler(["altAddress", "street1"], e.target.value)
              }
            />
            <TextField
              sx={{ margin: "0px" }}
              margin="dense"
              label="Apt, Suite, etc."
              type="text"
              fullWidth
              variant="outlined"
              value={parsed.altAddress?.street2 || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) =>
                createChangeHandler(["altAddress", "street2"], e.target.value)
              }
            />
            <TextField
              sx={{ margin: "0px" }}
              margin="dense"
              label="City"
              type="text"
              fullWidth
              variant="outlined"
              value={parsed.altAddress?.city || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) =>
                createChangeHandler(["altAddress", "city"], e.target.value)
              }
            />

            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <TextField
                sx={{ margin: "0px" }}
                margin="dense"
                label="State"
                type="text"
                variant="outlined"
                value={parsed.altAddress?.state || ""}
                error={!!errors?.value}
                helperText={errors?.value}
                onChange={(e) =>
                  createChangeHandler(["altAddress", "state"], e.target.value)
                }
              />
              <TextField
                sx={{ margin: "0px" }}
                margin="dense"
                label="Zip"
                type="text"
                variant="outlined"
                value={parsed.altAddress?.zipCode || ""}
                error={!!errors?.value}
                helperText={errors?.value}
                onChange={(e) =>
                  createChangeHandler(["altAddress", "zipCode"], e.target.value)
                }
              />
            </Box>
          </Box>
        )}
        {!showNotes && (
          <Button
            fullWidth
            variant="text"
            onClick={() => {
              setShowNotes(!showNotes);
            }}
            startIcon={<AddIcon />}
            sx={{
              justifyContent: "flex-start",
              paddingLeft: "0px",
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            Notes
          </Button>
        )}
        {showNotes && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="bodyHeavy">Notes</Typography>
              <IconButton
                onClick={() => {
                  createChangeHandler(["notes"], null);
                  setShowNotes(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              margin="dense"
              rows={5}
              label="Notes"
              type="text"
              fullWidth
              multiline
              variant="outlined"
              value={parsed.notes || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) => createChangeHandler(["notes"], e.target.value)}
            />
          </Box>
        )}
      </Box>
    );
  },
);
