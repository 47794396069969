import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  ButtonGroup,
  Collapse,
  Divider,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Contact,
  StickyNote,
  FileText,
  Phone,
  ListTodo,
  Users,
  User,
} from "lucide-react";
import { Family } from "protogen/advisors_service_pb";
import { Note } from "protogen/notes_pb";
import {
  useCreateDocument,
  useListDocuments,
  useCopyDocument,
} from "services/documents";
import { useSearch } from "services/search";
import {
  CopyDocumentRequest,
  ListDocumentsRequest,
} from "protogen/documents_service_pb";
import { Document, DocumentType } from "protogen/documents_pb";
import NotesEditor, { Handle } from "components/details-page/NotesEditor";
import FactDialog from "components/facts/FactDialog";
import { EphemeralFact, buildEphemeralFact } from "components/facts/types";
import { createFamilyDetailsFactStub } from "components/facts/types";
import {
  createMemberFactStubs,
  FamilyFactDialog,
  MemberFactDialog,
} from "components/facts/FamilyMembers";
import {
  createEphemeralFactDocument,
  getDocumentSubtitle,
  getDocumentTitle,
} from "components/documents/utils";
import DropdownMenu from "components/common/DropdownMenu";
import SearchBar from "components/forum/SearchBar";
import useIsMobile from "components/hooks/useIsMobile";
import DateDisplay from "components/common/DateDisplay";
import LinkRouter from "components/navigation/LinkRouter";
import { Filter } from "components/search/types";
import { familyFilter, entityTypeInFilter } from "components/search/utils";
import { RetrievalResult } from "protogen/search_service_pb";
import useIsDesktop from "components/hooks/useIsDesktop";
import { format } from "date-fns";
import Loading from "../common/Loading";

const getSearchFilters = (
  family: Family,
  documentType: DocumentType,
): Filter[] => {
  let filters = [];
  if (documentType === DocumentType.FACT) {
    filters = ["fact"];
  } else if (documentType === DocumentType.CALL) {
    filters = ["phonecall"];
  } else if (documentType === DocumentType.NOTE) {
    filters = ["document"];
  } else {
    filters = ["document", "fact", "phonecall", "task_block_document"];
  }
  return [familyFilter(family), entityTypeInFilter(filters)];
};

interface Props {
  title: string;
  family: Family;
  note: Note;
}

const DocumentIcon = ({
  type,
  sx,
  factType,
}: {
  type: DocumentType;
  factType?: string;
  sx?: React.CSSProperties;
}) => {
  const iconStyle = {
    stroke: "#8E9598",
    height: "20px",
    width: "20px",
    ...sx,
  };
  if (type === DocumentType.FACT && factType === "contact") {
    return <Contact style={iconStyle} />;
  }
  const icons: Record<number, JSX.Element> = {
    1: <FileText style={iconStyle} /> /* Document */,
    2: <ListTodo style={iconStyle} /> /* Doc on a task? */,
    3: <StickyNote style={iconStyle} /> /* Core family or member detail */,
    4: <Phone style={iconStyle} /> /* Call */,
    5: <Users style={iconStyle} /> /* Ephemeral family fact */,
    6: <User style={iconStyle} /> /* Ephemeral family member fact */,
  };

  return icons[type] || undefined;
};

const TitleAction = ({
  document,
  handleClick,
  children,
}: {
  document: Document;
  handleClick?: (document: Document) => void;
  children: React.ReactNode;
}) => {
  if (
    document.documentType === DocumentType.FACT ||
    document.documentType === DocumentType.FACT_FAMILY ||
    document.documentType === DocumentType.FACT_FAMILY_MEMBER
  ) {
    return (
      <Box
        onClick={() => handleClick?.(document)}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    );
  } else {
    return (
      <LinkRouter
        to={
          document.documentType === DocumentType.NOTE
            ? `/documents/${encodeURIComponent(
                document?.uuid || "",
              )}?doc_type=1`
            : document.documentType === DocumentType.TASK_BLOCK
              ? `/documents/${encodeURIComponent(
                  document.taskBlock.ref || "",
                )}?doc_type=2`
              : document.documentType === DocumentType.CALL
                ? `/calls/${encodeURIComponent(document?.ref || "")}`
                : null
        }
        sx={{ padding: 0, maxWidth: "100%" }}
      >
        {children}
      </LinkRouter>
    );
  }
};

const SubtitleAction = ({
  document,
  children,
}: {
  document: Document;
  children: React.ReactNode;
}) => {
  if (document.documentType === DocumentType.TASK_BLOCK) {
    return (
      <LinkRouter
        sx={{ minWidth: 0, flex: 1 }}
        to={`/tasks/${encodeURIComponent(document?.ref || "")}`}
      >
        {children}
      </LinkRouter>
    );
  } else {
    return <>{children}</>;
  }
};

const DocumentSection = ({
  sectionName,
  sortName,
  shouldSort = true,
  documents,
  family,
  refresh,
  newFact,
  onFactDialogClose,
}: {
  sectionName: string;
  sortName?: string;
  shouldSort?: boolean;
  documents: Document[];
  family: Family;
  refresh: () => void;
  newFact?: boolean | string | null;
  onFactDialogClose?: () => void;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedFact, setSelectedFact] = useState<EphemeralFact | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editFamilyDialogSection, setEditFamilyDialogSection] = useState<
    string | null
  >(null);
  const handleClose = () => {
    setDialogOpen(false);
    // It takes a state update for the dialog to close.
    // We can clear the selected fact on close and prevent the form from flickering
    // between edit and create.
    setTimeout(() => {
      setSelectedFact(null);
    }, 150);
    onFactDialogClose && onFactDialogClose();
  };

  const handleFactClick = (document: Document) => {
    const fact = document.fact;
    if (fact.valueType === "family" || fact.valueType === "member") {
      setEditFamilyDialogSection(
        fact.valueType === "family" ? "family" : fact.ref,
      );
    } else {
      setSelectedFact(buildEphemeralFact(document.fact));
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    const factRef = searchParams.get("factRef");
    if (!factRef) return;

    const facts = documents
      .filter((d) => d.documentType === DocumentType.FACT)
      .map((d) => d.fact);

    const fact = facts.find((f) => f.ref === factRef);
    if (!fact) return;

    setSelectedFact(buildEphemeralFact(fact));
    setDialogOpen(true);

    // Not required but nice to remove the query parameters to avoid popping up the fact if
    // user manually refreshes the page.
    searchParams.delete("factRef");
    navigate("." + searchParams.toString(), { replace: true });
  }, [documents, searchParams]);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (newFact !== null && newFact !== undefined) {
      setDialogOpen(true);
    }
  }, [newFact]);

  const isMobile = useIsMobile();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: sortName ? "space-between" : "start",
          paddingBottom: "8px",
          borderBottom: "1px solid #D4D4D4",
        }}
      >
        <Typography variant="bodySmallHeavy">{sectionName}</Typography>
        {sortName && !isMobile && (
          <Typography variant="bodySmallHeavy">{sortName}</Typography>
        )}
      </Box>

      {/* Document list */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "12px 0 0 0",
          marginBottom: "8px",
        }}
      >
        {documents
          .sort((a, b) =>
            shouldSort
              ? Number(b.lastUpdatedSec) - Number(a.lastUpdatedSec)
              : 0,
          )
          .map((document, idx) => {
            const isFact =
              document.documentType === DocumentType.FACT ||
              document.documentType === DocumentType.FACT_FAMILY ||
              document.documentType === DocumentType.FACT_FAMILY_MEMBER;
            return (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: isMobile ? "4px" : "12px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    flexWrap: isMobile ? "wrap" : "nowrap",
                    gap: isMobile ? "2px" : "6px",
                    alignItems: isMobile ? "start" : "center",
                    width: isMobile ? "100%" : "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Icon, title, subtitle */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: isMobile ? "start" : "center",
                      width: isMobile ? "100%" : "10%",
                      flexGrow: 1,
                      cursor: "pointer",
                      minWidth: "100px",
                    }}
                  >
                    {/* Icon */}
                    <TitleAction
                      document={document}
                      handleClick={handleFactClick}
                    >
                      <DocumentIcon
                        type={document.documentType}
                        factType={document.fact?.valueType}
                        sx={{
                          flexShrink: 0,
                          width: "fit-content",
                          marginTop: isMobile ? "4px" : 0,
                        }}
                      />
                    </TitleAction>

                    {/* Title and task name */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: isMobile ? "" : "8px",
                        flexWrap: isMobile ? "wrap" : "nowrap",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: isMobile ? "start" : "center",
                        width: isMobile ? "100%" : "10%",
                        flexGrow: 1,
                        overflow: "hidden",
                      }}
                    >
                      {/* Title */}
                      <TitleAction
                        document={document}
                        handleClick={handleFactClick}
                      >
                        <Typography
                          variant="bodyHeavy"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: isMobile ? "normal" : "nowrap",
                          }}
                        >
                          {getDocumentTitle(document)}
                        </Typography>
                      </TitleAction>

                      {/* Task name */}
                      {getDocumentSubtitle(document) && (
                        <SubtitleAction document={document}>
                          <Typography
                            variant="bodySmall"
                            color={isFact ? "text.secondary" : "primary"}
                            sx={{
                              flex: 1,
                              display: "block",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              minWidth: 0,
                              cursor: isFact ? "default" : "pointer",
                            }}
                          >
                            {getDocumentSubtitle(document)}
                          </Typography>
                        </SubtitleAction>
                      )}
                    </Box>
                  </Box>

                  {/* Date */}
                  {sortName && (
                    <Typography
                      variant="bodySmall"
                      color="text.secondary"
                      sx={{
                        flexShrink: 0,
                        whiteSpace: "nowrap",
                        marginLeft: isMobile ? "28px" : "0",
                      }}
                    >
                      <DateDisplay
                        date={new Date(Number(document.lastUpdatedSec) * 1000)}
                      />
                    </Typography>
                  )}
                </Box>

                {isMobile && (
                  <Divider sx={{ marginTop: "8px", borderColor: "#EAEBEC" }} />
                )}
              </Box>
            );
          })}
      </Box>
      {dialogOpen && (
        <FactDialog
          open={dialogOpen}
          category={typeof newFact === "string" ? newFact : undefined}
          familyRef={family.ref}
          onClose={handleClose}
          onCreate={() => {
            refresh();
            handleClose();
          }}
          onDelete={() => {
            refresh();
            handleClose();
          }}
          onEdit={() => {
            refresh();
            handleClose();
          }}
          isEdit={selectedFact !== null}
          fact={selectedFact}
        />
      )}
      <FamilyFactDialog
        open={editFamilyDialogSection == "family"}
        onClose={() => {
          setEditFamilyDialogSection(null);
        }}
        family={family}
      />
      <MemberFactDialog
        open={
          !!editFamilyDialogSection &&
          editFamilyDialogSection !== "family" &&
          !!family.familyMembers.find((m) => m.ref === editFamilyDialogSection)
        }
        onClose={() => {
          setEditFamilyDialogSection(null);
        }}
        family={family}
        member={family.familyMembers.find(
          (m) => m.ref === editFamilyDialogSection,
        )}
      />
    </Box>
  );
};
export default ({ family, note }: Props) => {
  const navigate = useNavigate();
  const [newFact, setNewFact] = useState<string | null | boolean>(null);
  const [query, setQuery] = useState<string>("");
  const familyFactDocument = createEphemeralFactDocument(
    createFamilyDetailsFactStub(family),
    DocumentType.FACT_FAMILY,
  );
  const memberFactDocument = createMemberFactStubs(family).map((m) => {
    return createEphemeralFactDocument(m, DocumentType.FACT_FAMILY_MEMBER);
  });
  const [allDocuments, setAllDocuments] = useState<Document[]>([]);
  const editorRef = useRef<Handle>(null);
  const [documentFilter, setDocumentFilter] = useState<{
    docType: DocumentType;
    factType?: string;
  }>({ docType: DocumentType.UNSPECIFIED, factType: undefined });
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const { request: createDocumentRequest, loading: createDocumentLoading } =
    useCreateDocument();
  const [noteIsSaving, setNoteIsSaving] = useState(false);
  const { request: copyDocumentRequest, loading: copyDocumentLoading } =
    useCopyDocument();
  const { request: listDocumentsRequest } = useListDocuments((r) => {
    setAllDocuments(r.documents || []);
  });

  const {
    request: searchRequest,
    data: searchData,
    loading: searchLoading,
  } = useSearch();

  const createDocument = async () => {
    const result = await createDocumentRequest({
      familyRef: family.ref,
      title: "New document",
      content: "",
      textContent: "",
      attachments: [],
    });
    navigate(
      `/documents/${encodeURIComponent(
        result?.document?.uuid || "",
      )}?created=1`,
    );
  };

  const copyDocument = async (
    entityType: string,
    entityRef: string,
    sourceName: string,
  ) => {
    const result = await copyDocumentRequest(
      new CopyDocumentRequest({
        fromDocumentType: DocumentType.NOTE,
        fromEntityType: "family-quick-note",
        fromEntityRef: entityRef,
        toDocumentType: DocumentType.NOTE,
        toEntityType: entityType,
        toEntityRef: entityRef,
        title: `Note: ${format(new Date(), "MMMM d, yyyy")}`,
        replaceTitle: true,
        shouldClearOriginalContent: true,
        creationSourceName: sourceName,
      }),
    );
    navigate(
      `/documents/${encodeURIComponent(
        result?.document?.uuid || "",
      )}?created=1`,
    );
  };

  const refresh = () => {
    listDocumentsRequest(
      new ListDocumentsRequest({
        familyRefs: [family.ref],
        documentTypes: [
          DocumentType.CALL,
          DocumentType.NOTE,
          DocumentType.TASK_BLOCK,
          DocumentType.FACT,
        ],
      }),
    );
  };

  const handleQuery = async (query: string) => {
    if (!query) return;
    searchRequest({
      queryText: query,
      adminSearchMode: false,
      strictlyChronological: false,
      maxResults: 10,
      filters: getSearchFilters(family, documentFilter.docType).map(
        (filter) => ({
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        }),
      ),
    });
  };

  useEffect(() => {
    let filtered = allDocuments.filter((d) => {
      if (documentFilter.docType !== DocumentType.UNSPECIFIED) {
        if (documentFilter.docType === DocumentType.NOTE) {
          return (
            d.documentType === DocumentType.NOTE ||
            d.documentType === DocumentType.TASK_BLOCK
          );
        } else if (
          documentFilter.docType === DocumentType.FACT &&
          documentFilter.factType
        ) {
          return (
            d.documentType === DocumentType.FACT &&
            d.fact?.valueType === documentFilter.factType
          );
        } else {
          return d.documentType === documentFilter.docType;
        }
      }
      return true;
    });
    const entity_type_map: Record<string, DocumentType> = {
      fact_value: DocumentType.FACT,
      document: DocumentType.NOTE,
      phone_call: DocumentType.CALL,
      task_block: DocumentType.TASK_BLOCK,
    };
    if (query && searchData?.results) {
      const documentMap = allDocuments.reduce(
        (acc: Record<string, Document>, d: Document) => {
          let ref = d.ref;
          if (d.documentType === DocumentType.TASK_BLOCK) {
            // We have a hack where the document ref is the "Task" ref. The entity ref to match against
            // is on the "TaskBlock" ref.
            ref = d.taskBlock.ref;
          }
          const key = `${d.documentType}_${ref}`;
          acc[key] = d;
          return acc;
        },
        {},
      );
      filtered = searchData.results
        .map((item: RetrievalResult) => {
          const entityKey = entity_type_map[item.entityType];
          if (!entityKey) {
            return null;
          }
          const key = `${entityKey}_${item.entityRef}`;
          return documentMap[key];
        })
        .filter((d) => !!d)
        .map((d) => d as Document);
    }
    setFilteredDocuments(filtered || []);
  }, [documentFilter, allDocuments, searchData, query]);

  useEffect(() => {
    handleQuery(query);
  }, [query]);

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"24px"}
        width={"100%"}
      >
        <Box>
          <NotesEditor
            ref={editorRef}
            note={note}
            style={{ border: "none", padding: "0", boxShadow: "none" }}
            placeholder={"Family scratchpad"}
            editorType="simple"
            primaryAction={
              <Button
                size="small"
                sx={{
                  height: "unset",
                  padding: "8px 18px",
                  marginBottom: "4px",
                  marginRight: "2px",
                }}
                onClick={async () => {
                  setNoteIsSaving(true);
                  if (editorRef.current?.isDirty) {
                    await editorRef.current?.forceSave();
                  }
                  await copyDocument(
                    "family",
                    note.entityRef,
                    "family-quick-note",
                  );
                  setNoteIsSaving(false);
                }}
                disabled={
                  createDocumentLoading || copyDocumentLoading || noteIsSaving
                }
              >
                Create note
              </Button>
            }
          />
        </Box>

        {/* File cabinet section header */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">File cabinet</Typography>
            <DropdownMenu
              disabled={createDocumentLoading || copyDocumentLoading}
              fullWidth
              buttonContent="Add new"
              variant="text"
              dropdownIcon={true}
              options={[
                {
                  title: "New document",
                  IconComponent: FileText,
                  onClick: () => createDocument(),
                },
                {
                  title: "New contact",
                  IconComponent: Contact,
                  onClick: () => {
                    setNewFact("contact");
                  },
                },
                {
                  title: "New fact",
                  IconComponent: StickyNote,
                  onClick: () => {
                    setNewFact(true);
                  },
                },
              ]}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              gap: isMobile ? "12px" : "24px",
              width: isMobile ? "100%" : "auto",
            }}
          >
            <SearchBar
              captureHotkeys={true}
              onQuery={(q) => setQuery(q)}
              expand={false}
              fullWidth={true}
              sx={{
                width: isMobile ? "100%" : "auto",
                maxWidth: isDesktop ? "280px" : "none",
              }}
              debounceTimeoutMs={1200}
            />
            <ButtonGroup
              sx={{
                width: isMobile ? "100%" : "auto",
                maxWidth: isMobile ? "none" : "fit-content",
              }}
            >
              <Button
                sx={{ width: "100%" }}
                variant={
                  documentFilter.docType === DocumentType.NOTE
                    ? "buttonGroupSelected"
                    : "buttonGroup"
                }
                onClick={() =>
                  setDocumentFilter({
                    docType: DocumentType.NOTE,
                    factType: undefined,
                  })
                }
              >
                Docs
              </Button>
              <Button
                sx={{ width: "100%" }}
                variant={
                  documentFilter.docType === DocumentType.FACT &&
                  documentFilter.factType === undefined
                    ? "buttonGroupSelected"
                    : "buttonGroup"
                }
                onClick={() =>
                  setDocumentFilter({
                    docType: DocumentType.FACT,
                    factType: undefined,
                  })
                }
              >
                Facts
              </Button>
              <Button
                sx={{ width: "100%", minWidth: "80px !important" }}
                variant={
                  documentFilter.docType === DocumentType.FACT &&
                  documentFilter.factType === "contact"
                    ? "buttonGroupSelected"
                    : "buttonGroup"
                }
                onClick={() =>
                  setDocumentFilter({
                    docType: DocumentType.FACT,
                    factType: "contact",
                  })
                }
              >
                Contacts
              </Button>
              <Button
                sx={{ width: "100%" }}
                variant={
                  documentFilter.docType === DocumentType.UNSPECIFIED
                    ? "buttonGroupSelected"
                    : "buttonGroup"
                }
                onClick={() =>
                  setDocumentFilter({ docType: DocumentType.UNSPECIFIED })
                }
              >
                All
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        <Collapse
          in={
            !query &&
            (documentFilter.docType === DocumentType.UNSPECIFIED ||
              documentFilter.docType === DocumentType.FACT)
          }
        >
          <DocumentSection
            sectionName="Family members"
            documents={[familyFactDocument, ...memberFactDocument]}
            family={family}
            refresh={refresh}
          />
        </Collapse>
        {searchLoading ? (
          <Loading />
        ) : (
          <DocumentSection
            sectionName="All"
            sortName="Last updated"
            shouldSort={!query}
            documents={filteredDocuments || []}
            family={family}
            refresh={refresh}
            newFact={newFact}
            onFactDialogClose={() => setNewFact(null)}
          />
        )}
      </Box>
    </Box>
  );
};
