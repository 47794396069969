import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  DatePicker,
  DesktopTimePicker,
  PickersActionBarProps,
} from "@mui/x-date-pickers";
import DocumentEditor from "../editor/DocumentEditor";
import { useState } from "react";
import {
  DEFAULT_EVENT_DURATION_MS,
  EphemeralEvent,
  FormErrors,
} from "../../types/calendars";
import FamilySelect from "../creation/FamilySelect";
import { AutocompleteEntry } from "../email/AddressCompletion";
import { RecurringUnit, EventReminder } from "protogen/calendar_pb";
import AddressInputList from "../email/AddressInputList";
import useIsMobile from "../hooks/useIsMobile";
import TimezoneDropdown from "../common/TimezoneDropdown";
import { Event, AccessTime } from "@mui/icons-material";
import AddressAutocomplete from "components/common/AddressAutocomplete";
import RecurringEventCheckbox from "./RecurringEventCheckbox";
import { X } from "lucide-react";
import { ChevronDown } from "lucide-react";

type RecurringOption = {
  unit: RecurringUnit | undefined;
  interval: number | undefined;
};

const recurringOptions: Record<string, RecurringOption> = {
  "Does not repeat": { unit: undefined, interval: undefined },
  Weekly: { unit: RecurringUnit.RecurringUnit_WEEK, interval: 1 },
  "Bi-weekly": { unit: RecurringUnit.RecurringUnit_WEEK, interval: 2 },
  Monthly: { unit: RecurringUnit.RecurringUnit_MONTH, interval: 1 },
  Yearly: { unit: RecurringUnit.RecurringUnit_YEAR, interval: 1 },
};

const transparencyOptions: Record<string, string> = {
  Busy: "opaque",
  Free: "transparent",
};

const reminderUnitOptions = ["minutes", "hours", "days", "weeks"];

const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const EventDateActionBar = ({ onClear }: PickersActionBarProps) => {
  return (
    <Box
      sx={{ alignSelf: "flex-end", marginRight: "12px", marginTop: "-50px" }}
    >
      <Button onClick={onClear} variant="text">
        Clear
      </Button>
    </Box>
  );
};

const EventDate = ({
  label,
  disabled,
  value,
  update,
  error,
  format,
  placeholder,
  forceShrink,
  clearable,
}: {
  label: string;
  disabled: boolean;
  value?: Date;
  update: (d: Date | null) => void;
  error?: string | null;
  format?: string | null;
  placeholder?: string;
  forceShrink?: boolean;
  clearable?: boolean;
}) => {
  const [dateOpen, setDateOpen] = useState(false);
  return (
    <FormControl sx={{ width: "100%" }}>
      <DatePicker
        disabled={disabled}
        label={label}
        value={value}
        sx={{ width: "100%", ".MuiButtonBase-root": { marginRight: "unset" } }}
        onChange={update}
        format={format ? format : "MM/dd/yyyy"}
        open={dateOpen}
        onClose={() => setDateOpen(false)}
        slots={
          clearable
            ? {
                actionBar: EventDateActionBar,
              }
            : {}
        }
        slotProps={{
          textField: {
            error: !!error,
            helperText: error,
            onClick: (e) => {
              e.stopPropagation();
              setDateOpen(true);
              // @kegami - We don't control the datepicker render and focus.
              // We assume it's async and runs off useState.  We re-focus the input after the event loop.
              setTimeout(() => {
                (e.target as HTMLInputElement).focus();
              });
            },
            InputLabelProps: { ...(forceShrink ? { shrink: true } : {}) },
            placeholder:
              value === undefined && placeholder ? placeholder : undefined,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setDateOpen(true)} edge="end">
                    <Event />
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
          ...(clearable
            ? {
                actionBar: {
                  actions: ["clear"],
                },
                popper: {
                  // Popper is for desktop - change from grid to flex
                  sx: {
                    "& .MuiPickersLayout-root": {
                      display: "flex",
                      flexDirection: "column",
                    },
                  },
                },
                dialog: {
                  // dialog is for mobile - change grid to flex
                  sx: {
                    "& .MuiPickersLayout-root": {
                      display: "flex",
                      flexDirection: "column",
                    },
                  },
                },
              }
            : {}),
        }}
      />
    </FormControl>
  );
};

const EventTime = ({
  label,
  value,
  update,
  error,
  forceShrink,
}: {
  label: string;
  value?: Date;
  update: (d: Date | null) => void;
  error?: string | null;
  forceShrink?: boolean;
}) => {
  const [timeOpen, setTimeOpen] = useState(false);
  return (
    <DesktopTimePicker
      label={label}
      value={value}
      sx={{ ".MuiButtonBase-root": { marginRight: "unset" } }}
      onChange={update}
      open={timeOpen}
      onClose={() => setTimeOpen(false)}
      slotProps={{
        textField: {
          error: !!error,
          helperText: error,
          onClick: (e) => {
            e.stopPropagation();
            setTimeOpen(!timeOpen);
            // @kegami - We don't control the datepicker render and focus.
            // We assume it's async and runs off useState.  We re-focus the input after the event loop.
            setTimeout(() => {
              (e.target as HTMLInputElement).focus();
            });
          },
          InputLabelProps: { ...(forceShrink ? { shrink: true } : {}) },

          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setTimeOpen(!timeOpen)} edge="end">
                  <AccessTime />
                </IconButton>
              </InputAdornment>
            ),
          },
        },
      }}
    />
  );
};
interface SectionProps {
  loading: boolean;
  errors: FormErrors | null;
  formData: EphemeralEvent;
  updateFormData: (f: EphemeralEvent) => void;
  sectionDisabled?: boolean;
}
const EventRange = ({
  loading,
  errors,
  formData,
  updateFormData,
}: SectionProps) => {
  if (formData.allDay) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <EventDate
          disabled={loading}
          value={formData.startDate}
          label="Start date"
          error={errors?.startDate}
          format="EEE, MMM d"
          update={(v: Date | null) => {
            let updates: Partial<EphemeralEvent> = {
              startDate: v ? new Date(v) : undefined,
            };
            // Auto update the enddate for the user if start date is past or equal to end date
            if (v && formData.endDate && v >= formData.endDate) {
              updates.endDate = v ? new Date(v) : undefined;
            }
            if (v && formData.endDate && !isSameDay(v, formData.endDate)) {
              updates.recurringUnit = undefined;
              updates.recurringInterval = undefined;
              updates.isRecurring = false;
            }
            updateFormData(updates);
          }}
        />
        <Typography variant="h3" sx={{ color: "text.tertiary" }}>
          -
        </Typography>
        <EventDate
          disabled={loading}
          label="End date"
          value={formData.endDate}
          error={errors?.endDate}
          format="EEE, MMM d"
          update={(v: Date | null) => {
            let updates: Partial<EphemeralEvent> = {
              endDate: v ? new Date(v) : undefined,
            };
            // Auto update the start date for the user if end date is before start date
            if (v && formData.endDate && v < formData.endDate) {
              updates.startDate = v ? new Date(v) : undefined;
            }
            if (v && formData.startDate && !isSameDay(v, formData.startDate)) {
              updates.recurringUnit = undefined;
              updates.recurringInterval = undefined;
              updates.isRecurring = false;
            }
            updateFormData(updates);
          }}
        />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <Typography variant="bodyHeavy" sx={{ width: "48px" }}>
          Start
        </Typography>
        <FormControl>
          <EventDate
            value={formData.startDate}
            disabled={loading}
            label="Date"
            update={(v: Date | null) =>
              updateFormData({
                startDate: v || undefined,
              })
            }
            format="EEE, MMM d"
            error={errors?.startDate}
          />
        </FormControl>
        <EventTime
          label="Start time"
          value={formData.startDate}
          update={(v: Date | null) => {
            // Use the default if we don't have start/end, or their difference is 0.
            const interval =
              (formData.startDate &&
                formData.endDate &&
                formData.endDate.getTime() - formData.startDate.getTime()) ||
              DEFAULT_EVENT_DURATION_MS;
            if (v) {
              updateFormData({
                startDate: v,
                // Add 1 hour to the end date
                endDate: new Date(v.getTime() + interval),
              });
            } else {
              updateFormData({ startDate: v || undefined });
            }
          }}
          error={errors?.startDate}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <Typography variant="bodyHeavy" sx={{ width: "48px" }}>
          End
        </Typography>
        <FormControl>
          <EventDate
            value={formData.endDate}
            disabled={loading}
            label="Date"
            update={(v: Date | null) =>
              updateFormData({
                endDate: v || undefined,
              })
            }
            format="EEE, MMM d"
            error={errors?.startDate}
          />
        </FormControl>
        <EventTime
          label="End time"
          value={formData.endDate}
          update={(v: Date | null) =>
            updateFormData({ endDate: v || undefined })
          }
          error={errors?.endDate}
        />
      </Box>
    </Box>
  );
};

const RecurringSection = ({
  loading,
  errors,
  formData,
  updateFormData,
  sectionDisabled = false,
}: SectionProps) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  let value = Object.keys(recurringOptions).find(
    (key) =>
      recurringOptions[key].unit === formData?.recurringUnit &&
      recurringOptions[key].interval === formData?.recurringInterval,
  );
  if (!value || sectionDisabled) {
    value = "Does not repeat";
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <FormControl sx={{ width: "100%", cursor: "pointer" }}>
          <InputLabel id="select-label">Repeats</InputLabel>
          <Select
            sx={{ cursor: "pointer" }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            disabled={loading || sectionDisabled}
            labelId="select-label"
            id="select-label"
            error={!!errors?.recurringUnit}
            label={"Repeats"}
            IconComponent={ChevronDown}
            defaultValue="Does not repeat"
            value={value}
            renderValue={(value) => {
              if (sectionDisabled) {
                return "Multi-day events cannot repeat";
              }
              return value;
            }}
            onChange={(e) => {
              const selectedOption = recurringOptions[e.target.value];
              updateFormData({
                recurringUnit: selectedOption.unit,
                recurringInterval: selectedOption.interval,
                isRecurring: e.target.value !== "Does not repeat",
              });
            }}
          >
            {Object.keys(recurringOptions).map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {value !== "Does not repeat" && (
        <Box>
          <FormControl sx={{ width: "100%" }}>
            <EventDate
              value={formData.recurringUntil}
              disabled={loading}
              label="Repeat ends"
              update={(v: Date | null) =>
                updateFormData({
                  recurringUntil: v || undefined,
                })
              }
              format="EEEE, MMM d"
              error={errors?.recurringUntil}
              placeholder={"Never"}
              forceShrink={true}
              clearable={true}
            />
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

const TransparencySection = ({
  loading,
  errors,
  formData,
  updateFormData,
}: SectionProps) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  let value =
    Object.keys(transparencyOptions).find(
      (key) => transparencyOptions[key] === formData?.transparency,
    ) || "Busy";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <FormControl sx={{ width: "100%", cursor: "pointer" }}>
        <InputLabel id="select-label">Availability</InputLabel>
        <Select
          sx={{ cursor: "pointer" }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          disabled={loading}
          labelId="select-label"
          id="select-label"
          error={!!errors?.recurringUnit}
          label={"Repeats"}
          value={value}
          renderValue={(value) => {
            return value;
          }}
          IconComponent={ChevronDown}
          onChange={(e) => {
            const selectedOption = transparencyOptions[e.target.value];
            updateFormData({
              transparency: selectedOption,
            });
          }}
        >
          {Object.keys(transparencyOptions).map((k) => (
            <MenuItem value={k} key={k}>
              {k}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const ReminderInput = ({
  reminder,
  index,
  onUpdate,
  onDelete,
}: {
  reminder: EventReminder;
  index: number;
  onUpdate: (index: number, reminder: EventReminder) => void;
  onDelete: (index: number) => void;
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <TextField
        fullWidth
        value={reminder.value}
        type="number"
        onChange={(e) => {
          onUpdate(
            index,
            new EventReminder({
              ...reminder,
              value: parseInt(e.target.value, 10) || 0,
            }),
          );
        }}
        autoComplete="off"
        sx={{
          margin: "12px 0px",
          width: "40%",
          "& .MuiInputBase-root": {
            minHeight: "48px !important",
          },
          "& .MuiInputBase-input": {
            marginTop: "0px",
            paddingRight: "0px",
          },
        }}
      />
      <FormControl sx={{ width: "100%", cursor: "pointer" }}>
        <Select
          sx={{
            cursor: "pointer",
            minHeight: "48px",
            "& .MuiInputBase-input": {
              marginTop: "0px",
            },
          }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={reminder.unit}
          renderValue={(value) => {
            return value;
          }}
          onChange={(e) => {
            onUpdate(
              index,
              new EventReminder({
                ...reminder,
                unit: e.target.value,
              }),
            );
          }}
        >
          {reminderUnitOptions.map((v) => (
            <MenuItem value={v} key={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="text"
        sx={{ minWidth: isMobile ? "40px" : "auto" }}
        onClick={() => onDelete(index)}
      >
        <X size={24} stroke="#8E9598"></X>
      </Button>
    </Box>
  );
};

const ReminderSection = ({
  errors,
  formData,
  updateFormData,
}: SectionProps) => {
  return (
    <Box>
      {formData?.reminders &&
        formData.reminders.map((r, i) => {
          return (
            <ReminderInput
              key={i}
              reminder={r}
              index={i}
              onUpdate={(index, reminder) => {
                const reminders = formData.reminders || [];
                reminders[index] = reminder;
                updateFormData({ reminders });
              }}
              onDelete={(index) => {
                const reminders = formData.reminders || [];
                reminders.splice(index, 1);
                updateFormData({ reminders });
              }}
            />
          );
        })}
      <Button
        variant="text"
        onClick={() => {
          updateFormData({
            reminders: [
              ...(formData?.reminders || []),
              new EventReminder({ value: 5, unit: "minutes" }),
            ],
          });
        }}
      >
        Add a reminder
      </Button>
      {!!errors?.reminders && (
        <Typography color="secondary.dark">{errors.reminders}</Typography>
      )}
    </Box>
  );
};

interface Props {
  formData: EphemeralEvent;
  updateFormData: (f: EphemeralEvent) => void;
  errors: FormErrors | null;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  selectFamily?: boolean;
  hideAction?: boolean;
  actionName?: string;
  attendeeAutocomplete?: AutocompleteEntry[];
  accountType?: "advisor" | "member";
}

export default ({
  loading,
  errors,
  formData,
  updateFormData,
  onSubmit,
  selectFamily = false,
  hideAction = false,
  actionName = "Save event",
  attendeeAutocomplete,
  accountType = "advisor",
}: Props) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [isRecurringSingleInstance, setIsRecurringSingleInstance] =
    useState(true);
  const isRecurringEvent =
    formData?.recurringUnit !== 0 && formData?.recurringInterval;

  let initialDetailsText: string | undefined = undefined;
  if (formData?.description?.json) {
    initialDetailsText = JSON.parse(formData?.description?.json || "{}");
  } else if (formData?.description?.html) {
    initialDetailsText = formData?.description?.html;
  } else if (formData?.initialDetailsText) {
    initialDetailsText = formData?.initialDetailsText;
  }

  const [hover, setHover] = useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : undefined,
        gap: isMobile ? "24px" : "48px",
        height: "100%",
        paddingTop: "22px",
        ...(hover ? { backgroundColor: "#e8f4f8" } : {}),
      }}
    >
      <Box>
        <Typography variant={isMobile ? "h4" : "bodyHeavy"}>
          Event details
        </Typography>
        <TextField
          disabled={loading}
          label="Event name"
          fullWidth
          error={!!errors?.title}
          helperText={errors?.title}
          value={formData.title || ""}
          onChange={(e) => updateFormData({ title: e.target.value })}
          autoComplete="off"
          sx={{ margin: "12px 0px" }}
        />
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.border}`,
            padding: "0 20px 18px 20px",
            borderRadius: "8px",
          })}
        >
          <DocumentEditor
            initialContent={initialDetailsText}
            initialAttachments={formData?.initialAttachments || []}
            disabled={loading}
            placeholder="Description"
            setContent={(c) => {
              updateFormData({ description: c });
            }}
            attachmentsEnabled={true}
            setDragState={setHover}
          />
        </Box>
        <Box sx={{ marginTop: "12px" }}>
          <AddressAutocomplete
            restrictToAddresses={false}
            initialValue={
              formData.location
                ? JSON.parse(formData?.location || "")?.formattedAddress
                : ""
            }
            setValue={(location) => {
              updateFormData({
                location: JSON.stringify(location),
              });
            }}
            label={"Location"}
            error={errors?.location}
            handleCopy={!!formData.location}
          />
        </Box>
        <Box sx={{ margin: "14px 0px" }}>
          <ButtonGroup fullWidth>
            <Button
              sx={{
                marginRight: "10px",
              }}
              variant={formData.allDay ? "buttonGroupSelected" : "buttonGroup"}
              onClick={() =>
                updateFormData({ allDay: true, displayTimezone: "" })
              }
            >
              All day
            </Button>
            <Button
              variant={!formData.allDay ? "buttonGroupSelected" : "buttonGroup"}
              onClick={() => updateFormData({ allDay: false })}
            >
              Specific time
            </Button>
          </ButtonGroup>
        </Box>
        <Box sx={{ marginBottom: "14px" }}>
          <EventRange
            loading={loading}
            errors={errors}
            formData={formData}
            updateFormData={updateFormData}
          />
        </Box>
        {errors && errors["displayTimezone"] && (
          <Typography color="secondary.dark">
            {errors["displayTimezone"]}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "329px",
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" flexDirection="column" gap="24px">
          {selectFamily && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Typography variant={isMobile ? "h4" : "bodyHeavy"}>
                Family
              </Typography>
              <FamilySelect
                selectedFamily={formData.familyRef || null}
                onChange={(v) => updateFormData({ familyRef: v || undefined })}
                error={errors?.familyRef}
                disabled={loading}
              />
            </Box>
          )}

          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography variant={isMobile ? "h4" : "bodyHeavy"}>
              Attendees
            </Typography>
            {accountType === "advisor" && (
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <AddressInputList
                  recipients={formData.invitees || []}
                  setRecipients={(r) => updateFormData({ invitees: r })}
                  autocompleteEntries={attendeeAutocomplete}
                  placeholder={"Attendee email"}
                  fullWidthInput={true}
                  sx={{
                    minHeight: "60px",
                    border: `1px solid ${theme.palette.border}`,
                    borderRadius: "8px",
                    padding: "9px 20px",
                    gap: "6px",
                  }}
                />
              </FormControl>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginBottom: "22px",
            }}
          >
            <Typography variant={isMobile ? "h4" : "bodyHeavy"}>
              Settings
            </Typography>
            <Box>
              <TransparencySection
                loading={loading}
                errors={errors}
                formData={formData}
                updateFormData={updateFormData}
              />
            </Box>
            <Box>
              <RecurringSection
                loading={loading}
                errors={errors}
                formData={formData}
                updateFormData={updateFormData}
                sectionDisabled={
                  formData.startDate &&
                  formData.endDate &&
                  !isSameDay(formData.startDate, formData.endDate)
                }
              />
            </Box>
            {!formData.allDay && accountType === "advisor" && (
              <Box>
                <TimezoneDropdown
                  timezone={formData.displayTimezone || ""}
                  setTimezone={(timezone) => {
                    updateFormData({ displayTimezone: timezone });
                  }}
                  sx={{ width: "100%" }}
                  placeholder={"Event timezone (optional)"}
                  isSelectionClearable={true}
                />
                <FormHelperText
                  sx={{
                    padding: "0px 5px",
                    fontSize: "12px",
                    color: "#6B6E7B",
                  }}
                >
                  This changes the timezone in which the event is created. It
                  will be displayed in the viewer's local time.
                </FormHelperText>
              </Box>
            )}
          </Box>
        </Box>

        {accountType === "advisor" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "12px",
            }}
          >
            <Typography variant={isMobile ? "h4" : "bodyHeavy"}>
              Reminders
            </Typography>
            <ReminderSection
              loading={loading}
              errors={errors}
              formData={formData}
              updateFormData={updateFormData}
            />
          </Box>
        )}
        {hideAction && isRecurringEvent && actionName !== "Save event" && (
          <Box>
            <RecurringEventCheckbox
              actionLabel={"Edit"}
              isRecurringSingleInstance={isRecurringSingleInstance}
              onToggle={() => {
                setIsRecurringSingleInstance(!isRecurringSingleInstance);
                updateFormData({
                  isRecurringSingleInstance: !isRecurringSingleInstance,
                });
              }}
            />
          </Box>
        )}
        {!hideAction && (
          <Box sx={{ display: "flex", alignSelf: "end", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "end",
              }}
            >
              <Button
                sx={{ alignSelf: "flex-end" }}
                onClick={() => {
                  onSubmit();
                }}
              >
                {actionName}
              </Button>
              {isRecurringEvent && actionName !== "Save event" && (
                <Box sx={{ alignSelf: "flex-end" }}>
                  <RecurringEventCheckbox
                    actionLabel={"Edit"}
                    isRecurringSingleInstance={isRecurringSingleInstance}
                    onToggle={() => {
                      setIsRecurringSingleInstance(!isRecurringSingleInstance);
                      updateFormData({
                        isRecurringSingleInstance: !isRecurringSingleInstance,
                      });
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
