import { UploadAttachment } from "protogen/common_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import { ForumPostType } from "protogen/forum_service_pb";

export const SCROLL_TO_REF_KEY = "faye.forumpost.scrollToRef";

export type EphemeralPost = {
  ref?: string;
  postType?: ForumPostType;
  topics?: string[];
  title?: string;
  body?: {
    payload: string;
    contentType: string;
    textContent: string;
    attachments: PlainMessage<UploadAttachment>[];
  };
};

export type FormErrors = {
  [key: string]: string;
};
