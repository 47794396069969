import { Family, FormErrors, Member, UserPermission } from "./types";
import {
  FamilyStatus,
  Family as FamilyProto,
  PlatformFeeSchedule,
} from "protogen/advisors_service_pb";
import { checkEmail, checkPhone, cleanPhone } from "../../common/userUtils";
import { AccountStub } from "protogen/common_pb";

const promotionalDurations: { [key: number]: string } = {
  14: "Two weeks",
  30: "One month",
  60: "Two months",
  90: "Three months",
};

const defaultFamily = (advisorRef?: string): Family => ({
  name: "",
  advisorRef: advisorRef || "",
  status: FamilyStatus.PROSPECT,
  startDate: null,
  clearStartDate: false,
  permissions: [],
  endDate: null,
  clearEndDate: false,
});

const defaultMember = (): Member => ({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  altEmails: [],
  timezone: "",
  isEphemeral: true,
});

const defaultPermissions = (account: AccountStub): UserPermission => {
  return {
    advisorRef: account.ref,
    account: account,
    active: true,
    expiration: null,
    actions: {
      access: true,
      edit: false,
      communicate: false,
      secrets: false,
    },
    reactivate: false,
  };
};
const validateFamily = (family: Family): FormErrors => {
  const errors: FormErrors = {};
  if (!family.name) {
    errors["name"] = "Required";
  }
  if (!family.advisorRef) {
    errors["advisorRef"] = "Required";
  }
  return errors;
};

const validateFamilyMember = (member: Member): FormErrors => {
  const errors: FormErrors = {};

  if (!member.firstName) {
    errors["firstName"] = "Required";
  }
  if (!member.lastName) {
    errors["lastName"] = "Required";
  }
  if (!member.timezone) {
    errors["timezone"] = "Required";
  }

  if (cleanPhone(member.phone) && !checkPhone(member.phone)) {
    errors["phone"] = "Invalid phone number";
  }
  if (member.email && !checkEmail(member.email)) {
    errors["email"] = "Invalid email address";
  }

  if (!(member.email || cleanPhone(member.phone))) {
    errors["phone"] = "Phone or Email is required";
    errors["email"] = "Email or Phone is required";
  }

  if (
    member.altEmails &&
    member.altEmails.some((email) => !checkEmail(email))
  ) {
    errors["altEmails"] = "Invalid email address";
  }
  return errors;
};

const validateMemberInquiry = (member: Member): FormErrors => {
  const errors: FormErrors = {};

  if (!member.firstName) {
    errors["firstName"] = "Required";
  }
  if (!member.lastName) {
    errors["lastName"] = "Required";
  }
  if (!member.email) {
    errors["email"] = "Required";
  }
  if (!member.phone) {
    errors["phone"] = "Required";
  }

  if (cleanPhone(member.phone) && !checkPhone(member.phone)) {
    errors["phone"] = "Invalid phone number";
  }
  if (member.email && !checkEmail(member.email)) {
    errors["email"] = "Invalid email address";
  }
  return errors;
};

const validateFamilyMembers = (members: Member[]): [boolean, FormErrors[]] => {
  let hasErrors = false;
  const memberErrors: FormErrors[] = [];
  for (const member of members) {
    const memErrors = validateFamilyMember(member);
    memberErrors.push(memErrors);
    hasErrors = hasErrors || Object.keys(memErrors).length !== 0;
  }
  return [hasErrors, memberErrors];
};

const getFormattedLocation = (address?: string): string => {
  if (!address) return "";
  const parsed = JSON.parse(address);
  if (!parsed) return "";
  return parsed?.formattedAddress || "";
};

const getSearchableLocation = (address?: string): string => {
  if (!address) return "";
  const parsed = JSON.parse(address);
  if (!parsed) return "";
  return `${parsed.formattedAddress} ${parsed.state}`.toLowerCase();
};

const getLatLng = (address?: string): { lat?: number; lng?: number } => {
  if (!address) {
    return {
      lat: undefined,
      lng: undefined,
    };
  }
  const parsed = JSON.parse(address);
  if (!parsed) {
    return {
      lat: undefined,
      lng: undefined,
    };
  }
  return {
    lat: parsed.lat || parsed.latitude,
    lng: parsed.lng || parsed.long || parsed.longitude,
  };
};

const getCityState = (family: Family | FamilyProto): string => {
  if (!family.address) return "";
  const parsed = JSON.parse(family.address);
  if (!parsed) return "";
  return `${parsed.city}, ${parsed.state}`;
};

const feeSchedule = (value: PlatformFeeSchedule) => {
  switch (value) {
    case PlatformFeeSchedule.SCHEDULE_LEAD_GEN_35_35_35_20:
      return "35% / 35% / 35% / 20%";
    case PlatformFeeSchedule.SCHEDULE_LEAD_GEN_30_30_30_10:
      return "30% / 30% / 30% / 10%";
    case PlatformFeeSchedule.SCHEDULE_FLAT_10:
      return "Flat 10%";
    case PlatformFeeSchedule.SCHEDULE_LEAD_GEN_30_30_30_15:
      return "30% / 30% / 30% / 15%";
    case PlatformFeeSchedule.SCHEDULE_FLAT_15:
      return "Flat 15%";
    case PlatformFeeSchedule.SCHEDULE_FLAT_20:
      return "Flat 20%";
    case PlatformFeeSchedule.SCHEDULE_NO_FEE:
      return "No fee";
    default:
      return "";
  }
};

export {
  cleanPhone,
  defaultMember,
  defaultFamily,
  defaultPermissions,
  validateFamily,
  validateFamilyMember,
  validateFamilyMembers,
  validateMemberInquiry,
  promotionalDurations,
  getFormattedLocation,
  getSearchableLocation,
  getCityState,
  getLatLng,
  feeSchedule,
};
