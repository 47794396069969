import { Family } from "protogen/advisors_service_pb";
import { Box } from "@mui/material";
import { SectionProps } from "../details-page/Section";
import { EventNotice, EventNotice_NoticeType } from "protogen/calendar_pb";
import FayeCalendar from "components/calendar/FayeCalendar";
import { useListEvents } from "services/calendar";
import { ListEventsRequest } from "protogen/calendar_service_pb";
import { useEffect, useState } from "react";
import { defaultParametersV2, CalendarParametersV2 } from "types/calendars";
import { CalendarFilter } from "../calendar/CustomCalendarSettings";
import { createColorMap } from "../calendar/utils";
import useDateParams from "components/hooks/calendar/useDateParams";
import { deduplicate } from "components/calendar/utils";

const FAYE_CALENDAR = "_faye-events";

interface Props extends SectionProps {
  family: Family;
  refreshTrigger: number;
}
export default ({ family, refreshTrigger }: Props) => {
  const defaultDate = useDateParams();
  const [events, setEvents] = useState<EventNotice[]>([]);
  const [params, setParams] = useState<CalendarParametersV2>({
    ...defaultParametersV2(),
  });
  const [enabledCalendars, setEnabledCalendars] = useState<
    CalendarFilter[] | undefined
  >(undefined);
  const [calendarColorMap, setCalendarColorMap] = useState<Record<
    string,
    [string, string]
  > | null>(null);
  const { request, loading, data } = useListEvents((r) => {
    if (!calendarColorMap) {
      setCalendarColorMap(
        createColorMap(
          (r?.calendars || []).map(
            (c) => `${c.integrationRef}|${c.calendarId}`,
          ),
        ),
      );
    }
  });
  const fetchEvents = async (
    newDate: Date,
    includeDuplicates: boolean = false,
  ) => {
    const todayMonth = newDate.getMonth() + 1;
    setParams({
      ...params,
      month: todayMonth,
      year: newDate.getFullYear(),
      day: newDate.getDate(),
    });

    // For week view - there can be two months in a week
    const oneWeekLater = new Date(newDate);
    oneWeekLater.setDate(oneWeekLater.getDate() + 7);
    const oneWeekMonth = oneWeekLater.getMonth() + 1;
    let oneWeekYear = oneWeekLater.getFullYear();
    // Check if the month goes over December
    if (oneWeekMonth > 12) {
      oneWeekYear += 1;
    }
    const requests = [
      request(
        new ListEventsRequest({
          ...params,
          month: todayMonth,
          year: newDate.getFullYear(),
          includeExternalEvents: true,
          includeAdvisorEvents: false,
          familyRefs: [family.ref],
          includeDuplicateEvents: includeDuplicates,
        }),
      ),
    ];

    // This can occur on week view where months overlap
    if (todayMonth !== oneWeekMonth) {
      requests.push(
        request(
          new ListEventsRequest({
            ...params,
            familyRefs: [family.ref],
            month: oneWeekMonth,
            year: oneWeekYear,
            includeExternalEvents: true,
            includeAdvisorEvents: false,
            includeOverdueTasks: false,
            includeDuplicateEvents: includeDuplicates,
          }),
        ),
      );
    }

    const results = await Promise.all(requests);
    // A basic dedupe on eventRef and startSec.  The FE requests month/year,
    // but the backend request an extra day window on each end to account for
    // timezone.  A more robust API that accepts more granular time or other improvements
    // can remove this dedupe.
    const allEvents = deduplicate(
      results.flatMap((result) => result?.eventNotices || []),
      ["eventRef", "startSec"],
    );
    setEvents(allEvents);
  };
  useEffect(() => {
    fetchEvents(defaultDate);
  }, []);

  useEffect(() => {
    fetchEvents(new Date(params.year, params.month - 1, params.day));
  }, [refreshTrigger]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          alignSelf: "stretch",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <FayeCalendar
          accountType="advisor"
          familyRef={family.ref}
          events={events.filter((e) => {
            if (!enabledCalendars) {
              return true;
            }
            const calSet = new Set(enabledCalendars?.map((c) => c.key) || []);
            if (
              e.noticeType === EventNotice_NoticeType.NoticeType_EXTERNAL_EVENT
            ) {
              return calSet.has(
                `${e.externalCalendarRef}|${e.externalCalendarId}`,
              );
            }
            return calSet.has(FAYE_CALENDAR);
          })}
          getEventStyles={(event) => {
            const colors =
              calendarColorMap?.[
                `${event.externalCalendarRef}|${event.externalCalendarId}`
              ];
            return {
              backgroundColor: colors?.[0] || undefined,
              color: colors?.[1] || undefined,
            };
          }}
          calendarFilters={[
            ...(data?.calendars || [])
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => ({
                key: `${c.integrationRef}|${c.calendarId}`,
                name: c.name,
                color:
                  calendarColorMap?.[`${c.integrationRef}|${c.calendarId}`][0],
              })),
            {
              name: "Faye Events",
              key: FAYE_CALENDAR,
            },
          ]}
          setEnabledFilters={(filters) => {
            setEnabledCalendars(filters);
          }}
          embeddedMode={true}
          refreshEvents={async (includeDuplicates) => {
            await fetchEvents(
              new Date(params.year, params.month - 1, params.day),
              includeDuplicates,
            );
          }}
          handleNavigate={async (d) => {
            fetchEvents(d);
          }}
          loading={loading}
        />
      </Box>
    </>
  );
};
