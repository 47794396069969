// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/documents_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Attachment } from "./common_pb.ts";
import { Document, DocumentScope, DocumentStatus, DocumentType } from "./documents_pb.ts";
import { ContentUpdates } from "./notes_pb.ts";

/**
 * @generated from message protogen.CreateDocumentRequest
 */
export class CreateDocumentRequest extends Message<CreateDocumentRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string content = 3;
   */
  content = "";

  /**
   * @generated from field: string textContent = 4;
   */
  textContent = "";

  /**
   * @generated from field: repeated protogen.Attachment attachments = 5;
   */
  attachments: Attachment[] = [];

  constructor(data?: PartialMessage<CreateDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "attachments", kind: "message", T: Attachment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDocumentRequest {
    return new CreateDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDocumentRequest {
    return new CreateDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDocumentRequest {
    return new CreateDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDocumentRequest | PlainMessage<CreateDocumentRequest> | undefined, b: CreateDocumentRequest | PlainMessage<CreateDocumentRequest> | undefined): boolean {
    return proto3.util.equals(CreateDocumentRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateDocumentResponse
 */
export class CreateDocumentResponse extends Message<CreateDocumentResponse> {
  /**
   * @generated from field: protogen.Document document = 1;
   */
  document?: Document;

  constructor(data?: PartialMessage<CreateDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDocumentResponse {
    return new CreateDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDocumentResponse {
    return new CreateDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDocumentResponse {
    return new CreateDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDocumentResponse | PlainMessage<CreateDocumentResponse> | undefined, b: CreateDocumentResponse | PlainMessage<CreateDocumentResponse> | undefined): boolean {
    return proto3.util.equals(CreateDocumentResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetDocumentRequest
 */
export class GetDocumentRequest extends Message<GetDocumentRequest> {
  /**
   * @generated from field: string documentRef = 1;
   */
  documentRef = "";

  /**
   * @generated from field: protogen.DocumentType documentType = 2;
   */
  documentType = DocumentType.UNSPECIFIED;

  constructor(data?: PartialMessage<GetDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documentRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "documentType", kind: "enum", T: proto3.getEnumType(DocumentType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDocumentRequest | PlainMessage<GetDocumentRequest> | undefined, b: GetDocumentRequest | PlainMessage<GetDocumentRequest> | undefined): boolean {
    return proto3.util.equals(GetDocumentRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetDocumentResponse
 */
export class GetDocumentResponse extends Message<GetDocumentResponse> {
  /**
   * @generated from field: protogen.Document document = 1;
   */
  document?: Document;

  constructor(data?: PartialMessage<GetDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDocumentResponse {
    return new GetDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDocumentResponse {
    return new GetDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDocumentResponse {
    return new GetDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDocumentResponse | PlainMessage<GetDocumentResponse> | undefined, b: GetDocumentResponse | PlainMessage<GetDocumentResponse> | undefined): boolean {
    return proto3.util.equals(GetDocumentResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateDocumentRequest
 */
export class UpdateDocumentRequest extends Message<UpdateDocumentRequest> {
  /**
   * @generated from field: string documentRef = 1;
   */
  documentRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: protogen.ContentUpdates content = 3;
   */
  content?: ContentUpdates;

  /**
   * @generated from field: string prevHash = 4;
   */
  prevHash = "";

  /**
   * @generated from field: bool shouldUpdateTitle = 5;
   */
  shouldUpdateTitle = false;

  /**
   * @generated from field: bool shouldUpdateContent = 6;
   */
  shouldUpdateContent = false;

  /**
   * @generated from field: protogen.DocumentScope scope = 7;
   */
  scope = DocumentScope.UNSPECIFIED;

  /**
   * @generated from field: protogen.DocumentStatus status = 8;
   */
  status = DocumentStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documentRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "message", T: ContentUpdates },
    { no: 4, name: "prevHash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "shouldUpdateTitle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "shouldUpdateContent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "scope", kind: "enum", T: proto3.getEnumType(DocumentScope) },
    { no: 8, name: "status", kind: "enum", T: proto3.getEnumType(DocumentStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDocumentRequest {
    return new UpdateDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDocumentRequest {
    return new UpdateDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDocumentRequest {
    return new UpdateDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDocumentRequest | PlainMessage<UpdateDocumentRequest> | undefined, b: UpdateDocumentRequest | PlainMessage<UpdateDocumentRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDocumentRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateDocumentResponse
 */
export class UpdateDocumentResponse extends Message<UpdateDocumentResponse> {
  /**
   * @generated from field: protogen.Document document = 1;
   */
  document?: Document;

  constructor(data?: PartialMessage<UpdateDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDocumentResponse {
    return new UpdateDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDocumentResponse {
    return new UpdateDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDocumentResponse {
    return new UpdateDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDocumentResponse | PlainMessage<UpdateDocumentResponse> | undefined, b: UpdateDocumentResponse | PlainMessage<UpdateDocumentResponse> | undefined): boolean {
    return proto3.util.equals(UpdateDocumentResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListDocumentsRequest
 */
export class ListDocumentsRequest extends Message<ListDocumentsRequest> {
  /**
   * @generated from field: repeated string familyRefs = 1;
   */
  familyRefs: string[] = [];

  /**
   * @generated from field: repeated protogen.DocumentType documentTypes = 2;
   */
  documentTypes: DocumentType[] = [];

  /**
   * @generated from field: bool includeAdvisorDocuments = 3;
   */
  includeAdvisorDocuments = false;

  constructor(data?: PartialMessage<ListDocumentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListDocumentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "documentTypes", kind: "enum", T: proto3.getEnumType(DocumentType), repeated: true },
    { no: 3, name: "includeAdvisorDocuments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentsRequest {
    return new ListDocumentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentsRequest {
    return new ListDocumentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentsRequest {
    return new ListDocumentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentsRequest | PlainMessage<ListDocumentsRequest> | undefined, b: ListDocumentsRequest | PlainMessage<ListDocumentsRequest> | undefined): boolean {
    return proto3.util.equals(ListDocumentsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListDocumentsResponse
 */
export class ListDocumentsResponse extends Message<ListDocumentsResponse> {
  /**
   * @generated from field: repeated protogen.Document documents = 1;
   */
  documents: Document[] = [];

  constructor(data?: PartialMessage<ListDocumentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListDocumentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documents", kind: "message", T: Document, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentsResponse {
    return new ListDocumentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentsResponse {
    return new ListDocumentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentsResponse {
    return new ListDocumentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentsResponse | PlainMessage<ListDocumentsResponse> | undefined, b: ListDocumentsResponse | PlainMessage<ListDocumentsResponse> | undefined): boolean {
    return proto3.util.equals(ListDocumentsResponse, a, b);
  }
}

/**
 * @generated from message protogen.CopyDocumentRequest
 */
export class CopyDocumentRequest extends Message<CopyDocumentRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: protogen.DocumentType fromDocumentType = 2;
   */
  fromDocumentType = DocumentType.UNSPECIFIED;

  /**
   * @generated from field: string fromEntityType = 3;
   */
  fromEntityType = "";

  /**
   * @generated from field: string fromEntityRef = 4;
   */
  fromEntityRef = "";

  /**
   * @generated from field: protogen.DocumentType toDocumentType = 5;
   */
  toDocumentType = DocumentType.UNSPECIFIED;

  /**
   * @generated from field: string toEntityType = 6;
   */
  toEntityType = "";

  /**
   * @generated from field: string toEntityRef = 7;
   */
  toEntityRef = "";

  /**
   * @generated from field: string title = 8;
   */
  title = "";

  /**
   * @generated from field: bool replaceTitle = 9;
   */
  replaceTitle = false;

  /**
   * @generated from field: bool shouldClearOriginalContent = 10;
   */
  shouldClearOriginalContent = false;

  /**
   * @generated from field: string creationSourceName = 11;
   */
  creationSourceName = "";

  constructor(data?: PartialMessage<CopyDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CopyDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fromDocumentType", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 3, name: "fromEntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fromEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "toDocumentType", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 6, name: "toEntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "toEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "replaceTitle", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "shouldClearOriginalContent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "creationSourceName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyDocumentRequest {
    return new CopyDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyDocumentRequest {
    return new CopyDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyDocumentRequest {
    return new CopyDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CopyDocumentRequest | PlainMessage<CopyDocumentRequest> | undefined, b: CopyDocumentRequest | PlainMessage<CopyDocumentRequest> | undefined): boolean {
    return proto3.util.equals(CopyDocumentRequest, a, b);
  }
}

/**
 * @generated from message protogen.CopyDocumentResponse
 */
export class CopyDocumentResponse extends Message<CopyDocumentResponse> {
  /**
   * @generated from field: protogen.Document document = 1;
   */
  document?: Document;

  constructor(data?: PartialMessage<CopyDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CopyDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyDocumentResponse {
    return new CopyDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyDocumentResponse {
    return new CopyDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyDocumentResponse {
    return new CopyDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CopyDocumentResponse | PlainMessage<CopyDocumentResponse> | undefined, b: CopyDocumentResponse | PlainMessage<CopyDocumentResponse> | undefined): boolean {
    return proto3.util.equals(CopyDocumentResponse, a, b);
  }
}

