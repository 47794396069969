import ReactiveDialog from "../../common/ReactiveDialog";
import { Box, Typography } from "@mui/material";
import {
  useDeactivateFamily,
  useGetFamilyDetails,
} from "../../../services/advisor";
import Loading from "../../common/Loading";
import { Family } from "protogen/advisors_service_pb";
import { PlanUtilization } from "protogen/common_pb";
import { useEffect } from "react";

type Props = {
  familyRef: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: (family: Family) => void;
};

type DeactivationContentProps = {
  family: Family;
  planUtilization?: PlanUtilization;
};

const DeactivationContent = ({ planUtilization }: DeactivationContentProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Typography variant="bodyHeavy">
        Are you sure you want to deactivate this family's service?
        {planUtilization?.periodEndDate && (
          <Box component="span" display="block" mt={1}>
            The service will end on{" "}
            {new Date(planUtilization.periodEndDate).toLocaleDateString(
              "en-US",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
                timeZone: "UTC",
              },
            )}
            .
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default ({ familyRef, open, setOpen, onUpdate }: Props) => {
  const {
    data: familyData,
    request: requestFamily,
    loading: loadingFamily,
  } = useGetFamilyDetails();
  const { request, loading } = useDeactivateFamily();

  useEffect(() => {
    if (open) {
      requestFamily({ familyRef });
    }
  }, [open, familyRef]);

  const onDeactivation = async () => {
    if (loading || !familyData) return;
    const resp = await request({ familyRef: familyData?.family?.ref || "" });
    if (resp?.family) {
      onUpdate(resp.family);
    }
  };

  return (
    <ReactiveDialog
      open={open}
      onClose={() => setOpen(false)}
      title="Deactivate family"
      primaryActionName="Deactivate"
      primaryActionEnabled={!loading && !!familyData?.family}
      primaryAction={onDeactivation}
      primaryActionColor="error"
      fullWidthSize="sm"
    >
      {loadingFamily ? (
        <Box display="flex" justifyContent="center" p={3}>
          <Loading />
        </Box>
      ) : familyData?.family ? (
        <DeactivationContent
          family={familyData.family}
          planUtilization={familyData.planUtilization}
        />
      ) : null}
    </ReactiveDialog>
  );
};
