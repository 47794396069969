import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LucideIcon } from "lucide-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider } from "@mui/material";

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    border: "1px solid #D4D4D4",
    marginTop: theme.spacing(1),
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

type Option = {
  title: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  IconComponent?: LucideIcon;
  belowDivider?: boolean;
  disabled?: boolean;
};

interface Props extends ButtonProps {
  buttonContent: string | React.ReactNode;
  options: Option[];
  noIcon?: boolean;
  dropdownIcon?: boolean;
  variant?: "text" | "outlined" | "contained";
}

export default ({
  disabled,
  options,
  buttonContent,
  noIcon,
  dropdownIcon,
  variant = "outlined",
  ...otherProps
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fromOption = (option: Option) => (
    <MenuItem
      disabled={disabled || option.disabled}
      onClick={(e) => {
        option.onClick?.(e);
        handleClose();
      }}
      disableRipple
      key={option.title}
    >
      {!noIcon && option.IconComponent && (
        <option.IconComponent size={20} stroke={"#8E9598"} />
      )}

      {option.title}
    </MenuItem>
  );
  const topOptions = options.filter((option) => !option.belowDivider);
  const bottomOptions = options.filter((option) => option.belowDivider);
  return (
    <div>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant={variant}
        disableElevation
        onClick={handleClick}
        disabled={disabled}
        endIcon={dropdownIcon ? <KeyboardArrowDownIcon /> : undefined}
        {...otherProps}
        sx={{
          ...otherProps.sx,
        }}
      >
        {buttonContent}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {topOptions.map(fromOption)}
        {bottomOptions.length > 0 && <Divider sx={{ my: 0.5 }} />}
        {bottomOptions.map(fromOption)}
      </StyledMenu>
    </div>
  );
};
