import {
  Box,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  useListBillingCoupons,
  useListBillingProducts,
} from "services/billing";
import { useContext, useEffect, useState } from "react";
import { BillingCoupon, BillingProduct } from "protogen/billing_pb";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Errors,
  PlanDetailsForm,
  couponDescription,
} from "../nux/member/types";
import { CurrentUserContext } from "../context/RequireAuth";
import { hasRole, ROLE_ADMIN } from "../../common/userUtils";
import { ChevronDown } from "lucide-react";

const Products = ({
  value,
  onUpdate,
  stacked,
  disabled,
  isAdmin,
  widthPercentage,
  initialProductName,
}: {
  value?: BillingProduct;
  onUpdate: (p?: BillingProduct) => void;
  disabled: boolean;
  stacked: boolean;
  isAdmin: boolean;
  widthPercentage: number;
  initialProductName?: string;
}) => {
  const { request, data, loading } = useListBillingProducts();
  const fetchProducts = async () => {
    const resp = await request();
    if (resp && initialProductName) {
      const match = resp.products.find((p) => p.name === initialProductName);
      if (match) {
        onUpdate(match);
      }
    }
  };
  useEffect(() => {
    if (!disabled && !data) {
      fetchProducts();
    }
  }, [disabled]);
  const options = (data?.products || (value ? [value] : [])).sort(
    (a, b) => a.amountCents - b.amountCents,
  );
  const mainOfferings = new Set([
    // Prod.
    "prod_QGpeOl3cDuxaiZ", // Total Game Changer
    "prod_QKBrlo0j4DuINP", // Free Time Finder
    "prod_QTbbvlRkHU1GKk", // Peace of Mind
    // Dev.
    "prod_QZAxZCP1JFyGGW",
    "prod_QZAx7GkKumuhHy",
    "prod_QZAvacnqgiPvls",
  ]);
  const toProduct = (product: BillingProduct) => (
    <MenuItem
      key={product.id}
      value={product.id}
      disabled={!product.amountCents}
    >
      <div>
        <Typography
          variant="subtitle1"
          sx={{
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden",
          }}
        >
          <span style={{ fontWeight: "600", marginRight: "6px" }}>
            ${`${(product.amountCents / 100).toFixed(2)}`}/mo
          </span>
          {product.name}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden",
          }}
        >
          {product.description}
        </Typography>
      </div>
    </MenuItem>
  );
  const showAllProducts =
    isAdmin && options.filter((p) => !mainOfferings.has(p.id)).length > 0;
  return (
    <Select
      label="Service tier"
      margin="none"
      displayEmpty
      value={value?.id || ""}
      disabled={loading || disabled}
      onChange={(e) => {
        const val = e.target.value;
        onUpdate(options.find((p) => p.id === val) || undefined);
      }}
      sx={{
        "& .MuiInputBase-input": {
          marginTop: "0px !important",
        },
        width: stacked ? "100%" : `calc(${widthPercentage}% - 8px)`,
        height: "60px",
      }}
      IconComponent={ChevronDown}
    >
      <MenuItem key={""} value={""} disabled>
        Service tier
      </MenuItem>
      {options.filter((p) => mainOfferings.has(p.id)).map(toProduct)}
      {showAllProducts && <ListSubheader>Additional Offerings</ListSubheader>}
      {showAllProducts &&
        options.filter((p) => !mainOfferings.has(p.id)).map(toProduct)}
    </Select>
  );
};

const Coupons = ({
  value,
  onUpdate,
  stacked,
  disabled,
  isAdmin,
  widthPercentage,
  initialCouponId,
}: {
  value?: BillingCoupon;
  onUpdate: (b?: BillingCoupon) => void;
  stacked: boolean;
  disabled: boolean;
  isAdmin: boolean;
  widthPercentage: number;
  initialCouponId?: string;
}) => {
  const { request, data, loading } = useListBillingCoupons();
  const fetchCoupons = async () => {
    const resp = await request();
    if (resp && initialCouponId) {
      const match = resp.coupons.find((c) => c.id === initialCouponId);
      if (match) {
        onUpdate(match);
      }
    }
  };
  useEffect(() => {
    if (isAdmin && !disabled && !data) {
      fetchCoupons();
    }
  }, [disabled, isAdmin]);
  const options = (data?.coupons || (value ? [value] : [])).sort(
    (a, b) => a.amountCents - b.amountCents,
  );
  if (!isAdmin) {
    return null;
  }
  return (
    <Select
      label="Discount"
      margin="none"
      displayEmpty
      value={value?.id || ""}
      disabled={disabled || loading}
      onChange={(e) => {
        const val = e.target.value;
        onUpdate(options.find((c) => c?.id === val) || undefined);
      }}
      sx={{
        "& .MuiInputBase-input": {
          marginTop: "0px !important",
        },
        width: stacked ? "100%" : `calc(${widthPercentage}% - 8px)`,
        height: "60px",
      }}
      IconComponent={ChevronDown}
    >
      <MenuItem key={""} value={""}>
        Discount (optional)
      </MenuItem>
      {options.map((coupon: BillingCoupon) => (
        <MenuItem key={coupon.id} value={coupon.id}>
          <div>
            <Typography variant="subtitle1">{coupon.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {couponDescription(coupon)}
            </Typography>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};

export default ({
  planDetails,
  errors,
  onPlanDetailsChange,
  disabled = false,
  stacked = false,
  initialProductName,
  initialCouponId,
}: {
  planDetails: PlanDetailsForm;
  errors: Errors;
  onPlanDetailsChange: (planDetails: Partial<PlanDetailsForm>) => void;
  disabled?: boolean;
  stacked: boolean;
  initialProductName?: string;
  initialCouponId?: string;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const isAdmin = hasRole(currentUser, ROLE_ADMIN);
  const widthPercentage = isAdmin ? 33 : 50;
  const [dateOpen, setDateOpen] = useState(false);
  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Box sx={{ marginBottom: "4px" }}>
        <Typography variant="bodyHeavy">Plan details</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          flexDirection: stacked ? "column" : "row",
        }}
      >
        <DatePicker
          sx={{
            width: stacked ? "100%" : `calc(${widthPercentage}% - 8px)`,
            ".MuiButtonBase-root": { marginRight: "unset" },
          }}
          label="Starting date"
          defaultValue={planDetails.startDate}
          disabled={disabled}
          onChange={(date) => {
            if (date === null) return;
            onPlanDetailsChange({ startDate: date });
          }}
          format="MM/dd/yyyy"
          open={dateOpen}
          onClose={() => setDateOpen(false)}
          slotProps={{
            textField: {
              error: !!errors.startDate,
              helperText: errors.startDate,
              onClick: () => !disabled && setDateOpen(true),
            },
          }}
        />
        <Products
          disabled={disabled}
          stacked={stacked}
          value={planDetails.product}
          onUpdate={(product) => {
            onPlanDetailsChange({ product: product });
          }}
          isAdmin={isAdmin}
          widthPercentage={widthPercentage}
          initialProductName={initialProductName}
        />
        <Coupons
          disabled={disabled}
          stacked={stacked}
          value={planDetails.coupon}
          onUpdate={(coupon) => {
            onPlanDetailsChange({ coupon: coupon });
          }}
          isAdmin={isAdmin}
          widthPercentage={widthPercentage}
          initialCouponId={initialCouponId}
        />
      </Box>
    </Box>
  );
};
