import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Family, FamilyStatus } from "../../protogen/advisors_service_pb";
import ActivationDialog from "../family/ActivationDialog";
import { Box } from "@mui/system";
import useIsMobile from "../hooks/useIsMobile";
import CloseIcon from "@mui/icons-material/Close";

const PreactivatedContent = ({ family }: { family: Family }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AlertTitle sx={{ fontWeight: "bold", mb: "8px", color: "#198282" }}>
        Billing has not started
      </AlertTitle>
      <Typography sx={{ color: "#198282" }}>
        Start service to begin billing and track time spent on tasks.
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Button onClick={() => setOpen(true)} size="small" sx={{ mt: 2 }}>
          Start service
        </Button>
      </Box>
      <ActivationDialog family={family} open={open} setOpen={setOpen} />
    </>
  );
};

// const ProspectContent = ({ family }: { family: Family }) => {
//   const navigate = useNavigate();
//   const onInviteClick = () => {
//     navigate(`/families-invitation/${encodeURIComponent(family.ref)}`);
//   };
//   return (
//     <Alert severity="warning">
//       This family is a prospect and has not yet been activated.
//     </Alert>
//   );
// };

export default ({ family }: { family: Family }) => {
  const isMobile = useIsMobile();
  const [dismissed, setDismissed] = useState(false);
  if (dismissed) {
    return null;
  }
  let content = null;
  if (family.status === FamilyStatus.PREACTIVATION) {
    content = <PreactivatedContent family={family} />;
    // Not really clear if we need this upsell yet or could make it aware of sent invitations.
    // } else if (family.status === FamilyStatus.PROSPECT) {
    //   content = <ProspectContent family={family} />;
  } else {
    return null;
  }
  return (
    <Alert
      severity="warning"
      color="info"
      icon={false}
      sx={{
        margin: "12px 16px",
        borderRadius: "8px",
        position: "absolute",
        top: isMobile ? "185px" : "70px",
        backgroundColor: "#E3EFED",
        border: "solid 1px #198282",
      }}
    >
      {content}
      <IconButton
        aria-label="close"
        onClick={() => setDismissed(true)}
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: "#198282",
        }}
      >
        <CloseIcon />
      </IconButton>
    </Alert>
  );
};
