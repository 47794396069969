import React from "react";

import Goals from "./steps/Goals";
import Superpowers from "./steps/Superpowers";
import Household from "./steps/Household";
import JoinNow from "./steps/JoinNow";
import MembershipStep from "./steps/MembershipStep";
import PaymentStep from "./steps/PaymentStep";
import CalendlyStep from "./steps/CalendlyStep";
import SignupConfirmation from "./steps/SignupConfirmation";
import Fun from "./steps/followups/Fun";
import { StepProps, STEPS, SignupState, StepRef } from "./types";
import ConsultationConfirmation from "./steps/ConsultationConfirmation";
import ConsultationDetails from "./steps/ConsultationDetails";
import { memoize } from "lodash";
import LifeEvents from "./steps/followups/LifeEvents";
// import TodoList from "./steps/followups/TodoList";
import AnythingElse from "./steps/followups/AnythingElse";
import ReferralSource from "./steps/followups/ReferralSource";
import FollowupConfirmation from "./steps/followups/FollowupConfirmation";
import FamilyInfo from "./steps/followups/FamilyInfo";

type SignupStep = {
  name: STEPS;
  component: React.ForwardRefExoticComponent<
    StepProps & React.RefAttributes<StepRef>
  >;
  defaultBackStep: STEPS | null;
  nextStep?: (state: SignupState) => STEPS;
  isValid?: (state: SignupState) => boolean;
  completionStateName?: "consultation-scheduled" | "signup-complete";
  backDisabled?: boolean;
  whiteBackground?: boolean;
  signupTerminus?: boolean; // indicates that "signup" is complete at this step.
};

const SIGNUP_FLOW: SignupStep[] = [
  // Old steps
  {
    name: STEPS.GOALS,
    component: Goals,
    defaultBackStep: null,
    backDisabled: true,
    nextStep: () => STEPS.SUPERPOWERS,
    isValid: (state) => !!Object.keys(state?.goals || {}).length,
  },
  {
    name: STEPS.SUPERPOWERS,
    component: Superpowers,
    defaultBackStep: STEPS.GOALS,
    nextStep: () => STEPS.HOUSEHOLD,
    isValid: (state) => !!Object.keys(state?.superpowers || {}).length,
  },
  {
    name: STEPS.HOUSEHOLD,
    component: Household,
    defaultBackStep: STEPS.SUPERPOWERS,
    nextStep: () => STEPS.JOIN_NOW,
    isValid: (state) => !!Object.keys(state?.household || {}).length,
  },
  // Forks here.
  {
    name: STEPS.JOIN_NOW,
    component: JoinNow,
    defaultBackStep: STEPS.HOUSEHOLD,
    nextStep: (state: SignupState) =>
      state.flowPath === STEPS.MEMBERSHIP
        ? STEPS.MEMBERSHIP
        : STEPS.CONSULTATION_DETAILS,
  },
  // Branch 1: Self serve
  {
    name: STEPS.MEMBERSHIP,
    component: MembershipStep,
    defaultBackStep: STEPS.JOIN_NOW,
    // Escape path to consultation.
    nextStep: (state: SignupState) =>
      state.flowPath === STEPS.CONSULTATION_DETAILS
        ? STEPS.CONSULTATION_DETAILS
        : STEPS.PAYMENT,
  },
  {
    name: STEPS.PAYMENT,
    component: PaymentStep,
    defaultBackStep: STEPS.MEMBERSHIP,
    nextStep: () => STEPS.SIGNUP_CONFIRMATION,
    isValid: (state) => !!state?.payment?.paymentMethodId,
    completionStateName: "signup-complete",
  },
  {
    name: STEPS.SIGNUP_CONFIRMATION,
    component: SignupConfirmation,
    defaultBackStep: STEPS.PAYMENT,
    backDisabled: true,
    signupTerminus: true,
    nextStep: () => STEPS.FAMILY_INFO,
  },
  // Follow-up questions
  {
    name: STEPS.FAMILY_INFO,
    component: FamilyInfo,
    defaultBackStep: STEPS.SIGNUP_CONFIRMATION,
    nextStep: () => STEPS.FUN,
  },
  {
    name: STEPS.FUN,
    component: Fun,
    defaultBackStep: STEPS.FAMILY_INFO,
    nextStep: () => STEPS.LIFE_EVENTS,
  },
  {
    name: STEPS.LIFE_EVENTS,
    component: LifeEvents,
    defaultBackStep: STEPS.FUN,
    nextStep: () => STEPS.ANYTHING_ELSE,
  },
  // {
  //   name: STEPS.TODO_LIST,
  //   component: TodoList,
  //   defaultBackStep: STEPS.LIFE_EVENTS,
  //   nextStep: () => STEPS.ANYTHING_ELSE,
  // },
  {
    name: STEPS.ANYTHING_ELSE,
    component: AnythingElse,
    defaultBackStep: STEPS.LIFE_EVENTS,
    nextStep: () => STEPS.REFERRAL_SOURCE,
  },
  {
    name: STEPS.REFERRAL_SOURCE,
    component: ReferralSource,
    defaultBackStep: STEPS.ANYTHING_ELSE,
    nextStep: () => STEPS.FOLLOWUP_CONFIRMATION,
  },
  {
    name: STEPS.FOLLOWUP_CONFIRMATION,
    component: FollowupConfirmation,
    defaultBackStep: STEPS.REFERRAL_SOURCE,
    backDisabled: true,
  },

  // Branch 2: Consultation
  {
    name: STEPS.CONSULTATION_DETAILS,
    component: ConsultationDetails,
    defaultBackStep: STEPS.JOIN_NOW,
    nextStep: () => STEPS.CONSULTATION_SCHEDULE,
    isValid: (state) =>
      !!state?.consultationDetails?.firstName &&
      !!state?.consultationDetails?.lastName &&
      !!state?.consultationDetails?.zipCode &&
      !!state?.consultationDetails?.email &&
      !!state?.consultationDetails?.phone,
  },
  {
    name: STEPS.CONSULTATION_SCHEDULE,
    component: CalendlyStep,
    defaultBackStep: STEPS.CONSULTATION_DETAILS,
    isValid: (state) =>
      !!state?.consultation?.inviteeUri && !!state?.consultation?.eventUri,
    completionStateName: "consultation-scheduled",
    nextStep: () => STEPS.CONSULTATION_CONFIRMATION,
    whiteBackground: true,
  },
  {
    name: STEPS.CONSULTATION_CONFIRMATION,
    component: ConsultationConfirmation,
    defaultBackStep: STEPS.CONSULTATION_SCHEDULE,
    signupTerminus: true,
    // NOTE(Kip): We might want to disable this...but lets see if users go back/forth.
    // backDisabled: true,
  },
];

const getPathToStep = memoize((stepName: STEPS) => {
  const stepsByName = SIGNUP_FLOW.reduce(
    (acc, step) => {
      acc[step.name] = step;
      return acc;
    },
    {} as Record<STEPS, SignupStep>,
  );
  const firstStep = stepsByName[stepName];
  if (!firstStep) {
    return [];
  }
  const path: SignupStep[] = [firstStep];
  while (path[0].defaultBackStep) {
    const step = stepsByName[path[0].defaultBackStep];
    path.unshift(step);
  }
  return path;
});

const getLongestPathFromEnd = memoize(
  (
    graph: SignupStep[],
    endAtStep?: STEPS,
    signupTerminus?: boolean,
  ): number => {
    // Create a quick lookup: step name -> step definition
    const stepMap = new Map<STEPS, SignupStep>();
    for (const step of graph) {
      stepMap.set(step.name, step);
    }

    // Identify end states (no nextStep defined)
    const endStates = graph.filter((step) =>
      signupTerminus ? step.signupTerminus : step.nextStep === undefined,
    );

    // Helper: Count how many back-steps from a given end state -> start
    const distanceToStart = (name: STEPS): number | null => {
      let count = 0;
      let current = stepMap.get(name);
      while (current) {
        if (endAtStep && current.name === endAtStep) {
          return count;
        }
        if (!current.defaultBackStep) {
          if (!endAtStep) {
            return count;
          } else {
            return null;
          }
        }
        count++;
        current = stepMap.get(current.defaultBackStep);
      }
      return count;
    };

    // Compute the maximum distance among all end states
    let maxDistance = 0;
    for (const endState of endStates) {
      const dist = distanceToStart(endState.name);
      if (dist && dist > maxDistance) {
        maxDistance = dist;
      }
    }
    return maxDistance;
  },
  (graph, endAtStep, signupTerminus) =>
    JSON.stringify([graph.map((s) => s.name), endAtStep, signupTerminus]), // Custom cache key
);

export { SIGNUP_FLOW, getPathToStep, getLongestPathFromEnd };
