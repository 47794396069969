// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/url_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.FetchUrlMetadataRequest
 */
export class FetchUrlMetadataRequest extends Message<FetchUrlMetadataRequest> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<FetchUrlMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchUrlMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchUrlMetadataRequest {
    return new FetchUrlMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchUrlMetadataRequest {
    return new FetchUrlMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchUrlMetadataRequest {
    return new FetchUrlMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchUrlMetadataRequest | PlainMessage<FetchUrlMetadataRequest> | undefined, b: FetchUrlMetadataRequest | PlainMessage<FetchUrlMetadataRequest> | undefined): boolean {
    return proto3.util.equals(FetchUrlMetadataRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchUrlMetadataResponse
 */
export class FetchUrlMetadataResponse extends Message<FetchUrlMetadataResponse> {
  /**
   * @generated from field: bool success = 1;
   */
  success = false;

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string image = 4;
   */
  image = "";

  /**
   * @generated from field: string url = 5;
   */
  url = "";

  /**
   * @generated from field: string siteName = 6;
   */
  siteName = "";

  /**
   * @generated from field: string domain = 7;
   */
  domain = "";

  constructor(data?: PartialMessage<FetchUrlMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchUrlMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "siteName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchUrlMetadataResponse {
    return new FetchUrlMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchUrlMetadataResponse {
    return new FetchUrlMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchUrlMetadataResponse {
    return new FetchUrlMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchUrlMetadataResponse | PlainMessage<FetchUrlMetadataResponse> | undefined, b: FetchUrlMetadataResponse | PlainMessage<FetchUrlMetadataResponse> | undefined): boolean {
    return proto3.util.equals(FetchUrlMetadataResponse, a, b);
  }
}

