import { PhoneCall, PhoneCall_State } from "protogen/conversation_pb";
import { TranscriptionSegment } from "protogen/phone_service_pb";
import React, { ReactNode, useContext, useEffect, useRef } from "react";
import ScrollableActivityPanel from "./ScrollableActivityPanel";
import ActivityFeedEntry from "../activity/ActivityFeedEntry";
import MessageBubble from "../activity/MessageBubble";
import { ScrollContainerHandle } from "../common/ScrollContainer";
import useDeepgramStreamedTranscription from "../activity/useDeepgramStreamedTranscriptions";
import { getFormattedDuration } from "../../common/utils";
import { AccountStub } from "protogen/common_pb";
import { CurrentUserContext } from "../context/RequireAuth";
import {
  Button,
  CircularProgress,
  LinearProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { useStartTranscription } from "services/phone";
import HighlightProvider from "../context/HighlightProvider";
import { format } from "date-fns";

export type ActivityEntry = {
  track: string;
  startSec: number;
  text: string;
  maxTemperature: number;
  segments: TranscriptionSegment[];
};

export const aggregateEntries = (
  segments: TranscriptionSegment[],
): ActivityEntry[] => {
  const entries: ActivityEntry[] = [];
  const _toEntry = (segment: TranscriptionSegment): ActivityEntry => ({
    track: segment.track,
    startSec: segment.startSec,
    text: segment.text,
    maxTemperature: segment.temperature,
    segments: [segment],
  });
  const _mergeEntry = (
    entry: ActivityEntry,
    segment: TranscriptionSegment,
  ): ActivityEntry => ({
    ...entry,
    text: `${entry.text} ${segment.text}`,
    maxTemperature: Math.max(entry.maxTemperature, segment.temperature),
    segments: [...entry.segments, segment],
  });
  let activeEntry: ActivityEntry | null = null;
  for (const segment of segments) {
    if (activeEntry === null) {
      // Start an entry
      activeEntry = _toEntry(segment);
    } else if (activeEntry.track === segment.track && !segment.speechFinal) {
      // Add to entry
      activeEntry = _mergeEntry(activeEntry, segment);
    } else {
      // Close the entry
      entries.push(activeEntry);
      activeEntry = _toEntry(segment);
    }
  }
  // Close the entry
  if (activeEntry !== null) {
    entries.push(activeEntry);
  }
  // Close the entry
  return entries;
};

type Props = {
  phoneCall: PhoneCall;
  trackToUser: Record<string, AccountStub>;
  segmentRefs?: string[];
  refresh?: () => void;
  familyRef?: string;
};

const TranscriptionStatus = ({
  phoneCall,
  transcriptionFailed,
  streamStarted,
  refresh,
}: {
  phoneCall: PhoneCall;
  transcriptionFailed: boolean;
  streamStarted: boolean;
  refresh?: () => void;
}) => {
  let message = "";
  let icon: ReactNode = null;
  if (
    // This also covers the case of calls still being initiated which we handle in the component.
    phoneCall.state === PhoneCall_State.ACTIVE &&
    !phoneCall.transcriptionEnabled
  ) {
    return (
      <CallConfirmation
        call={phoneCall}
        onConfirmation={() => refresh && refresh()}
        // onConfirmation={() => setIsStreamStarted(true)}
      />
    );
  } else if (
    !phoneCall.transcriptionEnabled ||
    phoneCall.state === PhoneCall_State.FAILED ||
    phoneCall.state === PhoneCall_State.MISSED ||
    transcriptionFailed
  ) {
    icon = <WarningAmberIcon sx={{ height: 48, width: 48 }} />;
    message = "No Transcript Available";
  } else if (!streamStarted && phoneCall.state === PhoneCall_State.ACTIVE) {
    icon = <ElectricalServicesIcon sx={{ height: 48, width: 48 }} />;
    message = "Waiting for stream to start";
  } else {
    icon = <CircularProgress sx={{ height: 48, width: 48 }} />;
    message = "Processing transcription";
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}
    >
      {icon}
      <span style={{ marginLeft: "10px" }}>{message}</span>
    </div>
  );
};

const CallConfirmation = ({
  call,
  onConfirmation,
}: {
  call: PhoneCall;
  onConfirmation: () => void;
}) => {
  const { request, loading } = useStartTranscription();
  const onClick = async () => {
    const data = await request({
      callRef: call.ref,
    });
    if (data) {
      onConfirmation();
    }
  };
  const disabled = call.status !== "active";
  const hide = !!call.events.find(
    (e) => JSON.parse(e)?.transcription === "granted",
  );
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        ...(disabled && { opacity: 0.5 }),
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "end",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!hide && (
          <Box
            sx={{
              textAlign: "center",
              padding: "15px 32px",
            }}
          >
            <Typography variant="h4" component="h2" gutterBottom>
              Transcribe
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Confirm the client has consented to a recording before starting
              the transcription.
            </Typography>
            <Button
              disabled={loading || disabled}
              variant="contained"
              color="primary"
              sx={{ m: 1 }}
              onClick={onClick}
            >
              Confirm
            </Button>
          </Box>
        )}
        <Box
          sx={{
            marginBottom: "40px",
            width: "100%",
            padding: "0px 24px",
          }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </Box>
    </Box>
  );
};

const FeedEntry = ({
  entry,
  sender,
  phoneCall,
  segmentHash,
  familyRef,
  isCurrentUser,
}: {
  entry: ActivityEntry;
  sender: AccountStub;
  phoneCall: PhoneCall;
  segmentHash: Set<string> | null;
  familyRef?: string;
  isCurrentUser: boolean;
}) => {
  const [headerOpen, setHeaderOpen] = React.useState(false);
  const alignLeft = !sender.isAdvisor;
  const isHighlighted =
    segmentHash && entry.segments.findIndex((s) => segmentHash.has(s.ref)) >= 0;
  const loading = entry.segments.some((s) => !s.stable);
  return (
    <ActivityFeedEntry
      // Not Correct
      alignLeft={alignLeft}
      avatarGutter={false}
      headerLeftText={
        isCurrentUser ? "You" : `${sender.firstName} ${sender.lastName[0]}`
      }
      hideHeader={!headerOpen}
      headerRightText={getFormattedDuration(Number(entry.startSec))}
      highlighted={isHighlighted ? "rgba(255, 255, 0, 0.3)" : undefined}
      sxOverride={{
        padding: "2px 0",
      }}
    >
      <HighlightProvider
        familyRef={familyRef}
        entityRef={phoneCall.ref}
        entityType={"phonecall"}
      >
        <div
          onClick={() => {
            setHeaderOpen((o) => !o);
          }}
        >
          <MessageBubble
            alignLeft={alignLeft}
            // loading={loading}
            // I like this loading more...
            sx={loading ? { opacity: 0.5 } : {}}
            //   // Map max temp (0-100) to 0-2px blur.
            //   // For long and high temp segments, let's not blur them.
            //   !loading && entry.maxTemperature > 0 && entry.text.length < 64
            //     ? { filter: `blur(${entry.maxTemperature * 2}px)` }
            //     : {}
          >
            {entry.text}
          </MessageBubble>
        </div>
      </HighlightProvider>
    </ActivityFeedEntry>
  );
};

export default ({
  phoneCall,
  trackToUser,
  segmentRefs,
  refresh,
  familyRef,
}: Props) => {
  const hasHighlightScrollRun = useRef(false);
  const [scrollPercent, setScrollPercent] = React.useState(100);
  const currentUser = useContext(CurrentUserContext);
  const scrollRef = useRef<ScrollContainerHandle | null>(null);
  const {
    segments,
    complete: transcriptionCompleted,
    transcriptionFailed,
    streamStarted,
  } = useDeepgramStreamedTranscription(phoneCall);

  const entries = aggregateEntries(segments);
  const segmentHash = segmentRefs ? new Set(segmentRefs) : null;

  useEffect(() => {
    if (!hasHighlightScrollRun.current) {
      if (segmentHash && transcriptionCompleted && scrollRef.current) {
        scrollRef.current?.scrollToFirstMatch((entry: Element) => {
          return entry.getAttribute("data-is-highlighted") === "true";
        });
        hasHighlightScrollRun.current = true;
      }
    }
  }, [transcriptionCompleted, segmentHash]);
  const reversedEntries = [...entries].reverse();
  const scrollEntries = reversedEntries.map((entry, i) => (
    <FeedEntry
      key={i}
      entry={entry}
      sender={trackToUser[entry.track]}
      phoneCall={phoneCall}
      segmentHash={segmentHash}
      familyRef={familyRef}
      isCurrentUser={currentUser.ref === trackToUser[entry.track].ref}
    />
  ));
  return (
    <ScrollableActivityPanel
      sx={{ borderRadius: "0px" }}
      setScrollPercentage={setScrollPercent}
      scrollEntries={[
        // Counterintuitively, this is scroll reverse so pre-pend this.
        ...(phoneCall.state === PhoneCall_State.COMPLETED
          ? [
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                key={"call-end"}
                sx={{ margin: "16px 0" }}
              >
                <Typography variant="body" color="#6B6E7B">
                  Call ended at{" "}
                  {format(
                    new Date(Number(phoneCall.lastUpdatedSec) * 1000),
                    "h:mma",
                  ).toLowerCase()}
                </Typography>
              </Box>,
            ]
          : []),
        ...(scrollEntries.length
          ? scrollEntries
          : [
              <TranscriptionStatus
                key={1}
                phoneCall={phoneCall}
                transcriptionFailed={transcriptionFailed}
                streamStarted={streamStarted}
                refresh={refresh}
              />,
            ]),
      ]}
      scrollRef={scrollRef}
      aboveFooterPanel={
        <Box display="flex" flexDirection="column" gap="16px">
          <LinearProgress
            variant="determinate"
            value={scrollPercent}
            sx={{
              height: "2px",
            }}
          />
        </Box>
      }
    />
  );
};
