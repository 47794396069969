// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/reports_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message protogen.ReportColumn
 */
export class ReportColumn extends Message<ReportColumn> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  constructor(data?: PartialMessage<ReportColumn>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ReportColumn";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportColumn {
    return new ReportColumn().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportColumn {
    return new ReportColumn().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportColumn {
    return new ReportColumn().fromJsonString(jsonString, options);
  }

  static equals(a: ReportColumn | PlainMessage<ReportColumn> | undefined, b: ReportColumn | PlainMessage<ReportColumn> | undefined): boolean {
    return proto3.util.equals(ReportColumn, a, b);
  }
}

/**
 * @generated from message protogen.ReportRow
 */
export class ReportRow extends Message<ReportRow> {
  /**
   * @generated from field: repeated string values = 1;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<ReportRow>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ReportRow";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportRow {
    return new ReportRow().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportRow {
    return new ReportRow().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportRow {
    return new ReportRow().fromJsonString(jsonString, options);
  }

  static equals(a: ReportRow | PlainMessage<ReportRow> | undefined, b: ReportRow | PlainMessage<ReportRow> | undefined): boolean {
    return proto3.util.equals(ReportRow, a, b);
  }
}

/**
 * @generated from message protogen.ReportParameter
 */
export class ReportParameter extends Message<ReportParameter> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  /**
   * @generated from field: string label = 3;
   */
  label = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: string defaultValue = 5;
   */
  defaultValue = "";

  /**
   * if type is choice
   *
   * @generated from field: repeated string choices = 6;
   */
  choices: string[] = [];

  constructor(data?: PartialMessage<ReportParameter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ReportParameter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "defaultValue", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "choices", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportParameter {
    return new ReportParameter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportParameter {
    return new ReportParameter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportParameter {
    return new ReportParameter().fromJsonString(jsonString, options);
  }

  static equals(a: ReportParameter | PlainMessage<ReportParameter> | undefined, b: ReportParameter | PlainMessage<ReportParameter> | undefined): boolean {
    return proto3.util.equals(ReportParameter, a, b);
  }
}

/**
 * @generated from message protogen.ReportChart
 */
export class ReportChart extends Message<ReportChart> {
  /**
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * @generated from field: string xAxisKey = 2;
   */
  xAxisKey = "";

  /**
   *  string title = 3;
   *  string xAxisScale = 2; // band, linear etc
   *  string yAxisTitle = 4;
   *  string yAxisScale = 2; // band, linear etc
   *  repeated string series = 6;
   *
   * @generated from field: repeated string columnSeries = 5;
   */
  columnSeries: string[] = [];

  constructor(data?: PartialMessage<ReportChart>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ReportChart";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "xAxisKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "columnSeries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportChart {
    return new ReportChart().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportChart {
    return new ReportChart().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportChart {
    return new ReportChart().fromJsonString(jsonString, options);
  }

  static equals(a: ReportChart | PlainMessage<ReportChart> | undefined, b: ReportChart | PlainMessage<ReportChart> | undefined): boolean {
    return proto3.util.equals(ReportChart, a, b);
  }
}

/**
 * @generated from message protogen.Report
 */
export class Report extends Message<Report> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated protogen.ReportParameter explicitParameters = 4;
   */
  explicitParameters: ReportParameter[] = [];

  /**
   * @generated from field: repeated protogen.ReportColumn columns = 5;
   */
  columns: ReportColumn[] = [];

  /**
   * @generated from field: protogen.ReportChart chartDefinition = 6;
   */
  chartDefinition?: ReportChart;

  constructor(data?: PartialMessage<Report>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Report";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "explicitParameters", kind: "message", T: ReportParameter, repeated: true },
    { no: 5, name: "columns", kind: "message", T: ReportColumn, repeated: true },
    { no: 6, name: "chartDefinition", kind: "message", T: ReportChart },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Report {
    return new Report().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Report {
    return new Report().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Report {
    return new Report().fromJsonString(jsonString, options);
  }

  static equals(a: Report | PlainMessage<Report> | undefined, b: Report | PlainMessage<Report> | undefined): boolean {
    return proto3.util.equals(Report, a, b);
  }
}

/**
 * @generated from message protogen.ListReportsRequest
 */
export class ListReportsRequest extends Message<ListReportsRequest> {
  constructor(data?: PartialMessage<ListReportsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListReportsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReportsRequest {
    return new ListReportsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReportsRequest {
    return new ListReportsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReportsRequest {
    return new ListReportsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListReportsRequest | PlainMessage<ListReportsRequest> | undefined, b: ListReportsRequest | PlainMessage<ListReportsRequest> | undefined): boolean {
    return proto3.util.equals(ListReportsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListReportsResponse
 */
export class ListReportsResponse extends Message<ListReportsResponse> {
  /**
   * @generated from field: repeated protogen.Report reports = 1;
   */
  reports: Report[] = [];

  constructor(data?: PartialMessage<ListReportsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListReportsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reports", kind: "message", T: Report, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReportsResponse {
    return new ListReportsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReportsResponse {
    return new ListReportsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReportsResponse {
    return new ListReportsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListReportsResponse | PlainMessage<ListReportsResponse> | undefined, b: ListReportsResponse | PlainMessage<ListReportsResponse> | undefined): boolean {
    return proto3.util.equals(ListReportsResponse, a, b);
  }
}

/**
 * @generated from message protogen.GenerateReportRequest
 */
export class GenerateReportRequest extends Message<GenerateReportRequest> {
  /**
   * @generated from field: string reportRef = 1;
   */
  reportRef = "";

  /**
   * @generated from field: map<string, string> parameters = 2;
   */
  parameters: { [key: string]: string } = {};

  constructor(data?: PartialMessage<GenerateReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reportRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parameters", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateReportRequest {
    return new GenerateReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateReportRequest {
    return new GenerateReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateReportRequest {
    return new GenerateReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateReportRequest | PlainMessage<GenerateReportRequest> | undefined, b: GenerateReportRequest | PlainMessage<GenerateReportRequest> | undefined): boolean {
    return proto3.util.equals(GenerateReportRequest, a, b);
  }
}

/**
 * @generated from message protogen.GenerateReportResponse
 */
export class GenerateReportResponse extends Message<GenerateReportResponse> {
  /**
   * @generated from field: repeated protogen.ReportColumn columns = 1;
   */
  columns: ReportColumn[] = [];

  /**
   * @generated from field: repeated protogen.ReportRow rows = 2;
   */
  rows: ReportRow[] = [];

  constructor(data?: PartialMessage<GenerateReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "columns", kind: "message", T: ReportColumn, repeated: true },
    { no: 2, name: "rows", kind: "message", T: ReportRow, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateReportResponse {
    return new GenerateReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateReportResponse {
    return new GenerateReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateReportResponse {
    return new GenerateReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateReportResponse | PlainMessage<GenerateReportResponse> | undefined, b: GenerateReportResponse | PlainMessage<GenerateReportResponse> | undefined): boolean {
    return proto3.util.equals(GenerateReportResponse, a, b);
  }
}

