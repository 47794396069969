import { CircularProgress } from "@mui/material";
import React from "react";

export default ({ size = 26 }: { size?: number }) => {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <defs>
            <linearGradient
              id="paint0_linear_914_17203"
              x1="5.60855"
              y1="-20.806"
              x2="37.2224"
              y2="-2.81939"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#198282" />
              <stop offset="1" stopColor="#A0CDCD" />
            </linearGradient>
          </defs>
        </defs>
      </svg>
      <CircularProgress
        size={size}
        thickness={5}
        sx={{
          // marginTop: "1px",
          "svg circle": { stroke: "url(#paint0_linear_914_17203)" },
        }}
      />
    </>
  );
};
