import { Box, Button, FormControlLabel, Typography } from "@mui/material";
import { Family, FormErrors } from "./types";
import AdvisorSelect from "../creation/AdvisorSelect";
import React, { useContext, useState } from "react";
import { CurrentUserContext } from "../context/RequireAuth";
import { hasRole, ROLE_ADMIN } from "../../common/userUtils";
import { AccountStub, Advisor } from "protogen/common_pb";
import { UserAvatar } from "../common/CurrentUserAvatar";
import Checkbox from "../common/Checkbox";
import { defaultPermissions } from "./utils";

const AddDelegate = ({ onAdd }: { onAdd: (a: AccountStub) => void }) => {
  const [addingDelegate, setAddingDelegate] = useState<boolean>(false);
  // Add button
  // trigger drop down
  // add delegate with default permissions
  if (!addingDelegate) {
    return (
      <Button
        variant="text"
        sx={{ alignSelf: "start" }}
        onClick={() => setAddingDelegate(true)}
      >
        Add collaborator
      </Button>
    );
  }
  return (
    <AdvisorSelect
      title="Add Collaborator"
      disabled={false}
      error={null}
      selectedAdvisor={null}
      onChange={(v, a) => {
        setAddingDelegate(false);
        v && a && onAdd(a);
      }}
    />
  );
};

interface Props {
  familyData: Family;
  setFamilyData: (f: Family) => void;
  errors: FormErrors | null;
  primaryAdvisor: Advisor;
}

export default ({
  familyData,
  setFamilyData,
  errors,
  primaryAdvisor, // advisorPermissions,
}: Props) => {
  const currentUser = useContext(CurrentUserContext);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginTop: "16px",
        marginBottom: 4,
      }}
    >
      <Typography variant={"bodyHeavy"}>Advisor Details</Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"16px"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        {primaryAdvisor && <UserAvatar user={primaryAdvisor} size={40} />}
        <Box display={"flex"} flexDirection={"column"} gap={"4px"} flexGrow={1}>
          <Typography variant={"bodyHeavy"}>
            {primaryAdvisor?.displayName}
          </Typography>
          <Typography variant={"body1"}>Primary Advisor</Typography>
        </Box>
      </Box>
      {hasRole(currentUser, ROLE_ADMIN) && (
        <AdvisorSelect
          defaultSelf={true}
          isAdmin={true}
          title="Primary Advisor"
          disabled={false}
          error={errors?.advisorRef}
          selectedAdvisor={familyData.advisorRef || null}
          onChange={(v) => setFamilyData({ ...familyData, advisorRef: v! })}
        />
      )}
      {(hasRole(currentUser, ROLE_ADMIN) ||
        currentUser.ref === primaryAdvisor.ref) && (
        <>
          {familyData.permissions.map((permission, idx) => {
            const permissionActive =
              permission.reactivate ||
              (permission.active && !permission.deactivate);
            return (
              <Box display="flex" flexDirection="column" key={idx}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{
                    ...(!permissionActive && {
                      opacity: 0.7,
                    }),
                  }}
                >
                  <Typography variant="bodySmallHeavy">
                    {!permissionActive && "[Inactive] "}
                    {permission.account?.displayName}
                  </Typography>
                  {permissionActive && (
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: 500,
                        color: "#C14743",
                      }}
                      onClick={() => {
                        setFamilyData({
                          ...familyData,
                          permissions: familyData.permissions.map((p) => {
                            if (p.advisorRef !== permission.advisorRef) {
                              return p;
                            } else {
                              return {
                                ...p,
                                deactivate: true,
                              };
                            }
                          }),
                        });
                      }}
                    >
                      remove
                    </span>
                  )}
                  {!permissionActive && (
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: 500,
                        color: "#C14743",
                      }}
                      onClick={() => {
                        setFamilyData({
                          ...familyData,
                          permissions: familyData.permissions.map((p) => {
                            if (p.advisorRef !== permission.advisorRef) {
                              return p;
                            } else {
                              return {
                                ...p,
                                reactivate: true,
                              };
                            }
                          }),
                        });
                      }}
                    >
                      reactivate
                    </span>
                  )}
                </Box>
                <Box display="flex" justifyContent={"space-between"}>
                  {Object.keys(permission.actions)
                    .sort()
                    .map((key) => (
                      <FormControlLabel
                        key={key}
                        label={key}
                        disabled={!permissionActive}
                        control={
                          <Checkbox
                            checked={permission.actions[key]}
                            onChange={(e) => {
                              setFamilyData({
                                ...familyData,
                                permissions: familyData.permissions.map((p) => {
                                  if (p.advisorRef !== permission.advisorRef) {
                                    return p;
                                  } else {
                                    return {
                                      ...p,
                                      actions: {
                                        ...p.actions,
                                        [key]: e.target.checked,
                                      },
                                    };
                                  }
                                }),
                              });
                            }}
                          />
                        }
                      />
                    ))}
                </Box>
              </Box>
            );
          })}
          <AddDelegate
            onAdd={(account: AccountStub) => {
              setFamilyData({
                ...familyData,
                permissions: [
                  ...familyData.permissions,
                  defaultPermissions(account),
                ],
              });
            }}
          />
        </>
      )}
    </Box>
  );
};
