import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import Loading from "components/common/Loading";
import AddAnnotation from "components/engagement/notes/AddAnnotation";
import AnnotationCard from "components/engagement/notes/AnnotationCard";
import useIsMobile from "components/hooks/useIsMobile";
import { SignupSubmission } from "protogen/signup_service_pb";
import { useEffect } from "react";
import { useListLeadAnnotations } from "services/engagement";

type Props = {
  lead: SignupSubmission;
};

export default ({ lead }: Props) => {
  const { data: annotations, loading, request } = useListLeadAnnotations();
  const isMobile = useIsMobile();
  useEffect(() => {
    request({
      signupSessionRef: lead.ref,
    });
  }, [lead.ref]);

  const getNoteTypes = () => {
    switch (lead.pipelineStatus.toLowerCase()) {
      case "consultation-scheduled":
        return ["consultation", "note", "matchmaking"];
      case "awaiting-match":
      case "signup-complete":
        return ["matchmaking", "note", "consultation"];
      default:
        return ["note", "consultation", "matchmaking"];
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "24px",
        borderTop: "1px solid #E2E3E4",
        padding: isMobile ? "20px 0 0" : "20px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
        }}
      >
        <Typography variant="bodyHeavy">Notes</Typography>
        <AddAnnotation
          familyRef={""}
          signupSessionRef={lead.ref}
          placeholder="Add a note"
          noteTypes={getNoteTypes()}
          includeFlags={false}
          forceOpen={false}
          onUpdate={async () => {
            await request({
              signupSessionRef: lead.ref,
            });
          }}
        />
        {loading && <Loading />}
        {annotations?.annotations.map((a) => (
          <AnnotationCard key={a.ref} annotation={a} />
        ))}
      </Box>
    </Box>
  );
};
