import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { AccountType } from "../../protogen/common_pb";
import { useGlobalModalContext } from "components/context/GlobalModalContext";
import useLocalStorageState from "../hooks/useLocalStorageState";
import { X } from "lucide-react";

export default ({ accountType }: { accountType: AccountType }) => {
  const [dismissed, setDismissed] = useLocalStorageState<boolean>(
    "faye.webapp-install-prompt",
    false,
  );
  const [initialDismissed] = useLocalStorageState<boolean>(
    "faye.webapp-install-prompt",
    false,
  );
  const { setModalOpen } = useGlobalModalContext();
  const [themeBase] = useState(
    document.querySelector("meta[name=theme-color]")?.getAttribute("content") ||
      undefined,
  );
  const [onNextPage, setNextPage] = useState(false);

  const revertThemeColor = () => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", themeBase || "");
    }
  };

  useEffect(() => {
    setModalOpen(!dismissed);
    return () => setModalOpen(false);
  }, [dismissed, setModalOpen]);

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (!dismissed) {
      if (metaThemeColor) {
        if (accountType == AccountType.ADVISOR) {
          // Note(Kip) Not finalized.
          metaThemeColor.setAttribute("content", "#575F70");
        } else {
          // Note(Kip) Not finalized.
          metaThemeColor.setAttribute("content", "#707c74");
        }
      }
    } else {
      revertThemeColor();
    }
    return () => {
      revertThemeColor();
    };
  }, [open]);
  if (initialDismissed) return null;
  return (
    // Intro Dialog (page 1)
    <Dialog
      open={!dismissed}
      PaperComponent={Paper}
      PaperProps={{ style: { borderRadius: "16px" } }}
      disableEscapeKeyDown={true}
      onClose={(_, reason) => {
        if (reason && reason === "backdropClick") return;
        setDismissed(true);
      }}
      sx={{ "& .MuiDialog-paper": { margin: "16px" } }}
    >
      {!onNextPage
        ? page1(
            accountType,
            () => setDismissed(true),
            () => setNextPage(true),
          )
        : page2(accountType, () => setDismissed(true))}
    </Dialog>
  );
};

const numberBackgroundSx = {
  backgroundColor: "#198282",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "37px",
  height: "32px",
  borderRadius: "50px",
};

const page1 = (
  accountType: AccountType,
  setDismissed: () => void,
  onNextPage: () => void,
) => (
  <>
    <Box
      sx={{
        background:
          "linear-gradient(344deg, rgba(142, 198, 198, 0.60) 21.71%, rgba(226, 243, 240, 0.60) 109.71%), #FFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      <Box sx={{ padding: "16px" }}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setDismissed();
          }}
        >
          <X color="#8E9598" />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          padding: "0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid #D4D4D4",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {accountType === AccountType.ADVISOR ? (
            <img
              width="85%"
              src="/assets/images/app-install/phone-frame-advisor.png"
            />
          ) : (
            <img
              width="85%"
              src="/assets/images/app-install/phone-frame-client.png"
            />
          )}
        </Box>
      </DialogContent>
    </Box>

    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "32px 24px 24px 24px",
      }}
    >
      <Box sx={{ marginBottom: "24px" }}>
        <DialogTitle
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0",
            marginBottom: "12px",
          }}
        >
          <Typography variant="h3">Install the Faye app</Typography>
        </DialogTitle>
        <Typography variant="body">
          The web app makes tasks only a tap away and pesky emails can be
          replaced with notifications.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "12px" }}>
        <Button
          variant="contained"
          sx={{ width: "100%", flex: "4" }}
          onClick={(e) => {
            e.preventDefault();
            onNextPage();
          }}
        >
          Continue
        </Button>
        <Button
          variant="outlined"
          sx={{ width: "100%", flex: "1" }}
          onClick={(e) => {
            e.preventDefault();
            setDismissed();
          }}
        >
          Skip
        </Button>
      </Box>
    </DialogContent>
  </>
);

const page2 = (accountType: AccountType, setDismissed: () => void) => (
  <Box sx={{ padding: "12px 16px 16px 16px" }}>
    <DialogTitle
      component={"div"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
        marginBottom: "12px",
      }}
    >
      <Typography variant="h3">Install instructions</Typography>
      <Box sx={{ padding: "" }}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setDismissed();
          }}
        >
          <X color="#8E9598" />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "8px",
      }}
    >
      {/* First step */}
      <Box>
        <Box sx={{ display: "flex", gap: "12px" }}>
          <Typography variant="bodyHeavy" sx={numberBackgroundSx}>
            1
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Typography variant="body" color="#3D3D3D">
              Click the <b>Share</b> icon at the bottom
            </Typography>
            {accountType === AccountType.ADVISOR ? (
              <img
                width="100%"
                src="/assets/images/app-install/app-install-advisor-1.png"
              />
            ) : (
              <img
                width="100%"
                src="/assets/images/app-install/app-install-client-1.png"
              />
            )}
          </Box>
        </Box>
      </Box>

      {/* Second step */}
      <Box>
        <Box sx={{ display: "flex", gap: "12px" }}>
          <Typography variant="bodyHeavy" sx={numberBackgroundSx}>
            2
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Typography variant="body" color="#3D3D3D">
              Scroll down and click <b>Add to Home Screen</b>
            </Typography>
            {accountType === AccountType.ADVISOR ? (
              <img
                width="100%"
                src="/assets/images/app-install/app-install-advisor-2.png"
              />
            ) : (
              <img
                width="100%"
                src="/assets/images/app-install/app-install-client-2.png"
              />
            )}
          </Box>
        </Box>
      </Box>
      <Button
        variant="outlined"
        size="small"
        sx={{ width: "100%", flex: "1" }}
        onClick={(e) => {
          e.preventDefault();
          setDismissed();
        }}
      >
        Close
      </Button>
    </DialogContent>
  </Box>
);
