import { HTMLContent, JSONContent, useEditor } from "@tiptap/react";
import StyledEditorContent from "./StyledEditorContent";
import { getExtensions, renderInitialContent } from "./utils";
import { Attachment } from "protogen/common_pb";
import { Box } from "@mui/system";
import { IconButton, List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { SxProps } from "@mui/system/styleFunctionSx";
import AttachmentDialog, {
  isSupportedAttachment,
} from "../common/AttachmentDialog";
import { useContext, useState } from "react";
import { FileDown } from "lucide-react";
import { humanFileSize } from "../../common/utils";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdvisor } from "../../common/userUtils";

type Props = {
  content: string | JSONContent | HTMLContent;
  minHeight?: string;
  maxHeight?: string;
  attachments?: Attachment[];
  sx?: SxProps;
};

export default ({ content, minHeight, maxHeight, attachments, sx }: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const editor = useEditor({
    editable: false,
    extensions: getExtensions({
      editable: false,
      handleCommunications: currentUser && isUserAdvisor(currentUser),
      linkUnfurlEnabled: true,
    }),
    content: renderInitialContent(content, attachments || []),
  });
  const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
  const downloadAttachment = (attachment: Attachment) => {
    window.open(attachment?.url, "Download");
  };
  const onAttachmentClick = (attachment: Attachment) => {
    if (isSupportedAttachment(attachment)) {
      // Handle document...
      setOpenAttachment(attachment);
    } else {
      downloadAttachment(attachment);
    }
  };
  if (!editor) return null;
  return (
    <Box sx={sx}>
      <StyledEditorContent
        minHeight={minHeight}
        maxHeight={maxHeight}
        scrollable={!!maxHeight}
        editor={editor}
      />
      {attachments && (
        <List>
          {attachments
            .filter((a) => !a.inlineReference)
            .map((attachment, i) => (
              <ListItem
                key={i}
                sx={{
                  backgroundColor: "#F3F6F9",
                  marginTop: "8px",
                  borderRadius: "8px",
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadAttachment(attachment);
                  }}
                >
                  <FileDown height="24px" width="24px" />
                </IconButton>
                <ListItemText
                  primary={
                    <div
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        overflow: "hidden",
                        ...{ textWrap: "nowrap" },
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => onAttachmentClick(attachment)}
                    >
                      <span style={{ fontWeight: 600 }}>
                        {attachment.filename}
                      </span>
                      <span style={{ marginLeft: "5px", fontStyle: "italic" }}>
                        ({humanFileSize(attachment.fileSize)})
                      </span>
                    </div>
                  }
                />
              </ListItem>
            ))}
        </List>
      )}
      <AttachmentDialog
        attachment={openAttachment}
        onClose={() => setOpenAttachment(null)}
      />
    </Box>
  );
};
