import { FC, ReactNode, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Collapse,
  IconButton,
  Link,
  Chip,
  Divider,
} from "@mui/material";
import {
  Briefcase,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  MemoryStick,
  LucideProps,
  X,
  OctagonX,
  AlarmClock,
  Trash2,
  PartyPopper,
  House,
  BookUser,
  Phone,
  Mail,
  MapPin,
  ExternalLink,
  CircleOff,
  Ghost,
} from "lucide-react";
import useIsMobile from "../../components/hooks/useIsMobile";
import LinkRouter from "../../components/navigation/LinkRouter";
import DateDisplay from "../../components/common/DateDisplay";
import { SignupSubmission } from "../../protogen/signup_service_pb";
import MetadataSection from "../../components/common/MetadataSection";
import AddFamilyDialog from "../family/AddFamilyDialog";
import { leadToFamily, emptyUpdates, normalizeLeadState } from "./utils";
import { Family, Member } from "../family/types";
import { useUpdateSubmission } from "../../services/signup";
import { absoluteTime, formatUSPhoneNumber } from "../../common/utils";
import { format } from "date-fns";
import DropdownMenu from "../common/DropdownMenu";
import CopyButton from "../common/CopyButton";
import LeadFeed from "./LeadFeed";

const leftFlex = 3;
const rightFlex = 5;

const getStatus = (lead: SignupSubmission) => {
  const StatusChip = ({
    label,
    subtext,
    bgColor,
    textColor,
  }: {
    label: string;
    subtext?: string;
    bgColor: string;
    textColor: string;
  }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Chip
        label={label}
        variant="filled"
        sx={{ background: bgColor, color: textColor, width: "125px" }}
      />
      {subtext && <Chip label={subtext} variant="outlined" />}
    </Box>
  );

  switch (lead.pipelineStatus.toLowerCase()) {
    case "signup-complete":
      return (
        <StatusChip
          bgColor="#FFE0E0"
          textColor={"#910838"}
          label="Signup"
          subtext={"Needs matchmaking"}
        />
      );
    case "awaiting-match":
      return (
        <StatusChip
          bgColor="#FFE0E0"
          textColor={"#910838"}
          label="Consultation"
          subtext={"Needs matchmaking"}
        />
      );
    case "consultation-cancelled":
      return (
        <StatusChip
          label="Consultation"
          bgColor="#F5F5F5"
          textColor={"#6B6E7B"}
          subtext={"Canceled"}
        />
      );
    case "no-show":
      return (
        <StatusChip
          label="Consultation"
          bgColor="#F5F5F5"
          textColor={"#6B6E7B"}
          subtext={"No show"}
        />
      );
    case "consultation-scheduled":
      const showConsultation =
        lead.state?.consultationEventStartSec &&
        lead.state?.consultationEventStartSec > new Date().getTime() / 1000;
      const consultDate = new Date(
        Number(lead.state?.consultationEventStartSec) * 1000,
      );
      return (
        <StatusChip
          label="Consultation"
          bgColor={showConsultation ? "#EEF3FC" : "rgba(251, 242, 229, 0.95)"}
          textColor={showConsultation ? "#3061AF" : "#9A6518"}
          subtext={
            showConsultation ? `${format(consultDate, "MMM d")}` : "Completed"
          }
        />
      );
    case "converted":
      return (
        <StatusChip label="Converted" bgColor="#ECF4EC" textColor={"#198282"} />
      );
    case "declined":
      return (
        <StatusChip label="Declined" bgColor="#F5F5F5" textColor={"#6B6E7B"} />
      );
    case "maybe-later":
      return (
        <StatusChip
          label="Maybe later"
          bgColor="#F5F5F5"
          textColor={"#6B6E7B"}
        />
      );
    case "deleted":
      return (
        <StatusChip label="Deleted" bgColor="#F5F5F5" textColor={"#6B6E7B"} />
      );
    default:
      return (
        <StatusChip
          label={lead.pipelineStatus.toLowerCase()}
          bgColor="#FFF"
          textColor={"#6B6E7B"}
        />
      );
  }
};

const BasicDetail = ({
  label,
  content,
  alwaysShow = false,
  horizontal = false,
}: {
  label: string;
  content: ReactNode | string | undefined;
  alwaysShow?: boolean;
  horizontal?: boolean;
}) => {
  if (!content && !alwaysShow) return null;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
        gap: horizontal ? "8px" : "2px",
      }}
    >
      <Typography variant="bodyHeavy">{label}</Typography>
      {content ? (
        <Typography variant="body">{content}</Typography>
      ) : (
        <Typography variant="body" color="#8E9598">
          None provided
        </Typography>
      )}
    </Box>
  );
};

const ContactDetail = ({
  icon,
  content,
}: {
  icon: "Phone" | "Mail" | "MapPin" | undefined;
  content: ReactNode | string | undefined;
}) => {
  const contactIcons = {
    Phone: <Phone size={20} color={"#8E9598"} />,
    Mail: <Mail size={20} color={"#8E9598"} />,
    MapPin: <MapPin size={20} color={"#8E9598"} />,
  };

  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      {icon && contactIcons[icon]}
      <Typography variant="body" sx={{ width: "100%" }}>
        {content}
      </Typography>
    </Box>
  );
};

const MultiSelectDetail = ({
  label,
  items,
}: {
  label: string;
  Icon: FC<LucideProps>;
  items: string[] | undefined;
}) => {
  if (!items || !items.length) return null;
  return (
    <BasicDetail
      label={label}
      content={
        <Box>
          {items
            .filter((v) => v)
            .map((value) => (
              <Typography
                variant="body"
                key={value}
                sx={{ display: "flex", gap: "2px" }}
              >
                • {value.replace(/-/g, " ")}
              </Typography>
            ))}
        </Box>
      }
    />
  );
};

const TrackingDetails = ({ lead }: { lead: SignupSubmission }) => {
  const keys = Object.keys(lead.trackingParameters || {}).filter(
    (k) =>
      [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
      ].indexOf(k) !== -1,
  );
  if (keys.length === 0) return null;
  return (
    <BasicDetail
      label={"Tracking"}
      content={
        <Box display={"fex"} flexDirection={"column"}>
          <Typography
            variant="bodySmall"
            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            {keys
              .map((key) => {
                const value = lead.trackingParameters?.[key];
                if (!value) return null;
                return `${key}: ${value}`;
              })
              .join("; ")}
          </Typography>
        </Box>
      }
    />
  );
};

const LeadAnswers = ({ lead }: { lead: SignupSubmission }) => {
  const normalized = normalizeLeadState(lead);
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "24px",
        borderTop: "1px solid #E2E3E4",
        padding: isMobile ? "20px 0 0" : "20px 0",
      }}
    >
      {/* Left */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: leftFlex,
          gap: "20px",
        }}
      >
        <MultiSelectDetail
          label={"Goals"}
          Icon={CheckCircle}
          items={normalized.goals}
        />
        <MultiSelectDetail
          label={"Household"}
          Icon={Briefcase}
          items={normalized.household}
        />
        <MultiSelectDetail
          label={"Superpowers"}
          Icon={MemoryStick}
          items={normalized.superpowers}
        />
        <MultiSelectDetail
          label={"Life events"}
          Icon={House}
          items={normalized.lifeEvents}
        />
        <MultiSelectDetail
          label={"Fun"}
          Icon={PartyPopper}
          items={normalized.fun}
        />
      </Box>

      {/* Right */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: rightFlex,
          gap: "16px",
        }}
      >
        <BasicDetail label={"Family Info"} content={lead?.state?.familyInfo} />
        <BasicDetail
          label={"Anything else"}
          content={lead?.state?.anythingElse}
          alwaysShow={true}
        />
        <BasicDetail
          label={"Referral source"}
          content={lead?.state?.referralSource}
          alwaysShow={true}
        />

        <CopyButton
          variant="text"
          label="Copy summary"
          value={lead.blurb}
          sx={{ alignSelf: "start" }}
        />
      </Box>
    </Box>
  );
};

const LeadDetails = ({ lead }: { lead: SignupSubmission }) => {
  const isMobile = useIsMobile();
  const normalized = normalizeLeadState(lead);
  return (
    <Box sx={{ padding: "0", flex: leftFlex }}>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        gap="10px"
      >
        {isMobile && (
          <Typography
            variant="body"
            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            {/* Formatted date */}
            <DateDisplay date={new Date(Number(lead.completionSec) * 1000)} />
          </Typography>
        )}

        {/* Left */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {/* Family link */}
          {lead.family && (
            <Typography variant="body">
              <LinkRouter
                sx={{ color: "#198282" }}
                to={`/families/${encodeURIComponent(lead.family?.ref)}`}
              >
                {lead.family?.name} Family
              </LinkRouter>
            </Typography>
          )}

          {/* Contact info */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <Typography variant="bodyHeavy">Contact info</Typography>
            <ContactDetail
              icon={"Phone"}
              content={formatUSPhoneNumber(normalized.phone)}
            />
            <ContactDetail icon={"Mail"} content={normalized.email} />
            <ContactDetail
              icon={"MapPin"}
              content={
                <Link
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    normalized.location,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontWeight: 500 }}
                >
                  {normalized.location}
                </Link>
              }
            />
          </Box>

          <TrackingDetails lead={lead} />
        </Box>
      </Box>
    </Box>
  );
};

const LeadMetadata = ({ lead }: { lead: SignupSubmission }) => {
  const { request } = useUpdateSubmission();

  if (lead.metadata === undefined) return null;
  return (
    <MetadataSection
      metadata={lead.metadata}
      saveMetadata={async (metadata) => {
        const data = emptyUpdates(lead.ref);
        data.shouldUpdateMetadata = true;
        data.updatedMetadata = metadata;
        await request(data);
      }}
      entityType={"family"}
    />
  );
};

interface LeadCardProps {
  lead: SignupSubmission;
  forceOpen: boolean;
  onClose: () => void; // only relevant if forceOpen is true
  onOpen: () => void; // called when the card is opened
  refresh: () => Promise<void>;
}

export default ({
  lead,
  forceOpen,
  onClose,
  onOpen,
  refresh,
}: LeadCardProps) => {
  const isMobile = useIsMobile();
  const [convertData, setConvertData] = useState<
    [Partial<Family>, Partial<Member>[]] | null
  >(null);
  const [open, setOpen] = useState(forceOpen);
  const { request: updateRequest, loading } = useUpdateSubmission();

  const handleUpdate = async (status: string) => {
    if (loading) return;
    const data = emptyUpdates(lead.ref);
    data.updatedPipelineStatus = status;
    await updateRequest(data);
    await refresh();
  };

  const handleOpen = () => {
    setOpen(true);
    onOpen(); // Call onOpen instead of onClose
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const normalized = normalizeLeadState(lead);
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        width: "100%",
        background: open ? "#FBFBFB" : "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "start" : "center",
          gap: "12px",
          cursor: !open ? "pointer" : undefined,
          padding: isMobile ? "12px 20px" : "16px 24px",
        }}
        onClick={() => !open && handleOpen()}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: isMobile ? "space-between" : "",
          }}
        >
          <Box
            sx={{
              flex: isMobile ? "" : leftFlex,
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              overflow: "hidden",
            }}
          >
            <Typography variant={isMobile ? "h4" : "bodyHeavy"}>
              {`${normalized.firstName} ${normalized.lastName}`}
            </Typography>

            <Typography variant="body" color="text.secondary">
              {`${normalized.shortLocation}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: isMobile ? "" : rightFlex,
              justifyContent: "space-between",
            }}
          >
            {!isMobile && getStatus(lead)}
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {!isMobile && (
                <Typography variant="body">
                  {/* Formatted date */}
                  <DateDisplay
                    date={new Date(Number(lead.completionSec) * 1000)}
                  />
                </Typography>
              )}
              {/*<Box flex={2} display="flex" justifyContent="end">*/}
              {/*  /!*{getStatus(lead.state?.status || "active")}*!/*/}
              {/*</Box>*/}
              {forceOpen ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleClose()}
                >
                  <X />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <ChevronUp /> : <ChevronDown />}
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>

        {isMobile && getStatus(lead)}
      </Box>
      <Collapse in={open || forceOpen} timeout="auto" unmountOnExit>
        <Divider sx={{ borderColor: "#ECECEC" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "white",
            padding: "0px 24px 16px",
          }}
        >
          {/* Top half*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "24px",
              padding: "20px 0",
            }}
          >
            {/* Left side */}
            <LeadDetails lead={lead} />

            {/* Right side */}
            <Box
              sx={{
                flex: rightFlex,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {(normalized?.membershipName || normalized?.customerId) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography variant="bodyHeavy">Membership</Typography>

                    {normalized?.membershipName &&
                      normalized?.membershipHours && (
                        <Typography variant="body">
                          {normalized?.membershipName +
                            " - " +
                            normalized?.membershipHours}{" "}
                          hours
                        </Typography>
                      )}

                    {normalized?.customerId ? (
                      <Link
                        href={`https://dashboard.stripe.com/customers/${normalized.customerId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          display: "flex",
                          fontWeight: "600",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        Customer
                        <ExternalLink size={14} />
                      </Link>
                    ) : undefined}
                    {normalized?.couponId ? (
                      <Link
                        href={`https://dashboard.stripe.com/coupons/${normalized.couponId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          display: "flex",
                          fontWeight: "600",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        Coupon
                        <ExternalLink size={14} />
                      </Link>
                    ) : undefined}
                  </Box>
                )}

                <BasicDetail
                  label={"Consultation"}
                  horizontal={true}
                  content={
                    lead.state?.consultationEventStartSec
                      ? absoluteTime(
                          new Date(
                            Number(lead.state?.consultationEventStartSec) *
                              1000,
                          ),
                        )
                      : undefined
                  }
                />
                <BasicDetail
                  label={"Consultation host"}
                  horizontal={true}
                  content={lead.state?.consultationEventHost}
                />
                <LeadMetadata lead={lead} />
              </Box>

              {/* Actions */}
              <Box
                display="flex"
                flexDirection={isMobile ? "row-reverse" : "row"}
                gap={isMobile ? "24px" : "10px"}
                justifyContent={isMobile ? "start" : "end"}
              >
                {lead.pipelineStatus === "signup-complete" ||
                lead.pipelineStatus === "awaiting-match" ||
                lead.pipelineStatus === "consultation-scheduled" ? (
                  <>
                    {lead.pipelineStatus === "consultation-scheduled" ? (
                      <DropdownMenu
                        size="small"
                        fullWidth
                        buttonContent="Update"
                        dropdownIcon={true}
                        options={[
                          {
                            title: "Needs matchmaking",
                            IconComponent: BookUser,
                            onClick: async () => {
                              await handleUpdate("awaiting-match");
                            },
                          },
                          {
                            title: "Not for them",
                            IconComponent: OctagonX,
                            onClick: async () => {
                              await handleUpdate("declined");
                            },
                          },
                          {
                            title: "Maybe later",
                            IconComponent: AlarmClock,
                            onClick: async () => {
                              await handleUpdate("maybe-later");
                            },
                          },
                          {
                            title: "Canceled consultation",
                            IconComponent: CircleOff,
                            onClick: async () => {
                              await handleUpdate("consultation-cancelled");
                            },
                          },
                          {
                            title: "No show",
                            IconComponent: Ghost,
                            onClick: async () => {
                              await handleUpdate("no-show");
                            },
                          },
                          {
                            title: "Delete",
                            IconComponent: Trash2,
                            onClick: async () => {
                              await handleUpdate("deleted");
                            },
                            belowDivider: true,
                          },
                        ]}
                      />
                    ) : (
                      <Button
                        sx={{ justifyContent: "start" }}
                        onClick={async () => await handleUpdate("deleted")}
                        disabled={loading}
                        color="error"
                        variant="text"
                        size="small"
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      sx={{ justifyContent: "start" }}
                      onClick={async () => {
                        const _convertData = await leadToFamily(lead);
                        setConvertData(_convertData);
                      }}
                      size={"small"}
                      disabled={loading}
                    >
                      Assign
                    </Button>
                  </>
                ) : (
                  <DropdownMenu
                    size="small"
                    fullWidth
                    buttonContent="Update"
                    dropdownIcon={true}
                    options={[
                      {
                        title: "Needs matchmaking",
                        IconComponent: BookUser,
                        onClick: async () => {
                          await handleUpdate("awaiting-match");
                        },
                      },
                      {
                        title: "Delete",
                        IconComponent: Trash2,
                        onClick: async () => {
                          await handleUpdate("deleted");
                        },
                        belowDivider: true,
                      },
                    ]}
                  />
                )}
              </Box>
            </Box>
          </Box>

          {/* Bottom half */}
          <LeadAnswers lead={lead} />
          {open && <LeadFeed lead={lead} />}
        </Box>
      </Collapse>
      {!!convertData && (
        <AddFamilyDialog
          selectAdvisor={true}
          open={!!convertData}
          initialFamilyData={convertData?.[0]}
          initialMembers={convertData?.[1]}
          onClose={() => setConvertData(null)}
        />
      )}
    </Box>
  );
};
