// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/notes.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub, Attachment, UploadAttachment } from "./common_pb.ts";

/**
 * @generated from enum protogen.EntityType
 */
export enum EntityType {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  TYPE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_FAMILY = 1;
   */
  TYPE_FAMILY = 1,

  /**
   * @generated from enum value: TYPE_PHONE_CALL = 2;
   */
  TYPE_PHONE_CALL = 2,

  /**
   * @generated from enum value: TYPE_TASK_DETAILS = 3;
   */
  TYPE_TASK_DETAILS = 3,

  /**
   * @generated from enum value: TYPE_DOCUMENT = 4;
   */
  TYPE_DOCUMENT = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(EntityType)
proto3.util.setEnumType(EntityType, "protogen.EntityType", [
  { no: 0, name: "TYPE_UNSPECIFIED" },
  { no: 1, name: "TYPE_FAMILY" },
  { no: 2, name: "TYPE_PHONE_CALL" },
  { no: 3, name: "TYPE_TASK_DETAILS" },
  { no: 4, name: "TYPE_DOCUMENT" },
]);

/**
 * @generated from message protogen.NoteContent
 */
export class NoteContent extends Message<NoteContent> {
  /**
   * @generated from field: string contentType = 1;
   */
  contentType = "";

  /**
   * @generated from field: string payload = 2;
   */
  payload = "";

  /**
   * @generated from field: string textContent = 3;
   */
  textContent = "";

  /**
   * @generated from field: repeated protogen.Attachment attachments = 4;
   */
  attachments: Attachment[] = [];

  constructor(data?: PartialMessage<NoteContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.NoteContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attachments", kind: "message", T: Attachment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NoteContent {
    return new NoteContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NoteContent {
    return new NoteContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NoteContent {
    return new NoteContent().fromJsonString(jsonString, options);
  }

  static equals(a: NoteContent | PlainMessage<NoteContent> | undefined, b: NoteContent | PlainMessage<NoteContent> | undefined): boolean {
    return proto3.util.equals(NoteContent, a, b);
  }
}

/**
 * @generated from message protogen.ContentUpdates
 */
export class ContentUpdates extends Message<ContentUpdates> {
  /**
   * @generated from field: string contentType = 1;
   */
  contentType = "";

  /**
   * @generated from field: string payload = 2;
   */
  payload = "";

  /**
   * @generated from field: string textContent = 3;
   */
  textContent = "";

  /**
   * @generated from field: repeated protogen.UploadAttachment attachments = 4;
   */
  attachments: UploadAttachment[] = [];

  constructor(data?: PartialMessage<ContentUpdates>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ContentUpdates";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attachments", kind: "message", T: UploadAttachment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentUpdates {
    return new ContentUpdates().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentUpdates {
    return new ContentUpdates().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentUpdates {
    return new ContentUpdates().fromJsonString(jsonString, options);
  }

  static equals(a: ContentUpdates | PlainMessage<ContentUpdates> | undefined, b: ContentUpdates | PlainMessage<ContentUpdates> | undefined): boolean {
    return proto3.util.equals(ContentUpdates, a, b);
  }
}

/**
 * @generated from message protogen.Note
 */
export class Note extends Message<Note> {
  /**
   * @generated from field: protogen.NoteContent content = 1;
   */
  content?: NoteContent;

  /**
   * @generated from field: protogen.EntityType entityType = 2;
   */
  entityType = EntityType.TYPE_UNSPECIFIED;

  /**
   * @generated from field: string entityRef = 3;
   */
  entityRef = "";

  /**
   * @generated from field: string hash = 4;
   */
  hash = "";

  /**
   * @generated from field: protogen.AccountStub lastEditor = 5;
   */
  lastEditor?: AccountStub;

  /**
   * @generated from field: int64 lastEditSec = 6;
   */
  lastEditSec = protoInt64.zero;

  constructor(data?: PartialMessage<Note>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Note";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: NoteContent },
    { no: 2, name: "entityType", kind: "enum", T: proto3.getEnumType(EntityType) },
    { no: 3, name: "entityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "lastEditor", kind: "message", T: AccountStub },
    { no: 6, name: "lastEditSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Note {
    return new Note().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Note {
    return new Note().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Note {
    return new Note().fromJsonString(jsonString, options);
  }

  static equals(a: Note | PlainMessage<Note> | undefined, b: Note | PlainMessage<Note> | undefined): boolean {
    return proto3.util.equals(Note, a, b);
  }
}

