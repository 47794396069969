import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Box, Typography, Button, Link } from "@mui/material";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import Loading from "components/common/Loading";
import GridPage from "components/layout/GridPage";
import { useListTasksForAdvisor } from "services/tasks";
import { TaskState } from "protogen/tasks_pb";
import { SearchContext } from "components/context/SearchContextProvider";
import TasksTable from "components/tasks/TasksTable";
import { TaskFilter } from "components/tasks/FilterContext";
import useIsMobile from "components/hooks/useIsMobile";
import {
  AddFilterContext,
  FilterContext,
} from "components/tasks/FilterContext";
import useSessionStorageState from "components/hooks/useSessionStorageState";
import SearchBar from "components/forum/SearchBar";
import useTitle from "components/hooks/useTitle";
import { ReactComponent as IdeaIcon } from "../icons/Menu/Idea.svg";
import { TaskFilterDrawer } from "components/tasks/TaskFiltersDrawer";
import { buildRequest } from "components/tasks/utils";

const _Wrapped = ({ initialStates }: { initialStates: string[] }) => {
  const navigate = useNavigate();
  const { setTasksOnly } = useContext(SearchContext);
  const isMobile = useIsMobile();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const familyParam = queryParams.get("familyRefs");
  const familyRefs: string[] | undefined = familyParam
    ? familyParam.split(",")
    : undefined;
  const [singleFamilyRef] = useState<string | null>(
    familyRefs && familyRefs.length === 1 ? familyRefs[0] : null,
  );

  const [showFilters, setShowFilters] = useState(false);
  const [query, setQuery] = useState("");
  const { loading, data, request } = useListTasksForAdvisor(() => {
    setTasksOnly(true);
  });

  const fetchTasks = (filters: TaskFilter[]) => {
    request(buildRequest(filters));
  };

  const { upsertFilter } = useContext(FilterContext);
  const [storedPriorities, setStoredPriorities] = useSessionStorageState<
    string[]
  >("faye.tasks.filters-priorities", []);
  const [storedStates, setStoredStates] = useSessionStorageState<string[]>(
    "faye.tasks.filters-states",
    initialStates,
  );
  const [storedFamilies, setStoredFamilies] = useSessionStorageState<string[]>(
    "faye.tasks.filters-families",
    [],
  );
  const [selectedTimeRange, setSelectedTimeRange] =
    useSessionStorageState<string>("faye.tasks.filters-time-range", "");

  const stateSetters: {
    [key: string]: (values: string[]) => void;
  } = {
    priority: setStoredPriorities,
    status: setStoredStates,
    family: setStoredFamilies,
  };

  const stateGetters: {
    [key: string]: string[];
  } = {
    priority: storedPriorities,
    status: storedStates,
    family: storedFamilies,
  };

  const stateUpdaters: {
    [key: string]: (value: string) => void;
  } = {
    timeRange: setSelectedTimeRange,
  };

  const handleChange = (field: string, value: string, isChecked: boolean) => {
    const state = stateGetters[field];
    const newValues = isChecked
      ? state.includes(value)
        ? state
        : [...state, value]
      : state.filter((v: string) => v !== value);

    if (stateSetters[field] && (field !== "family" || !singleFamilyRef)) {
      stateSetters[field](newValues);
    }

    upsertFilter({
      field: field,
      operator: "oneOf",
      value: newValues,
    });
  };

  const handleUpdate = (field: string, operator: string, value: string) => {
    stateUpdaters[field](value);
    upsertFilter({
      field: field,
      operator: operator,
      value: value,
    });
  };

  const onAddTaskClick = () => {
    navigate("/tasks/add", { state: { families: data?.families } });
  };

  useEffect(() => {
    // update filter context when the component gets rendered based on local storage
    const fields = [
      { field: "priority", value: storedPriorities },
      { field: "status", value: storedStates },
      { field: "family", value: familyRefs ? familyRefs : storedFamilies },
    ];

    fields.forEach(({ field, value }) => {
      upsertFilter({
        field: field,
        operator: "oneOf",
        value: value,
      });
    });

    fetchTasks([
      { field: "status", operator: "oneOf", value: storedStates },
      { field: "priority", operator: "oneOf", value: storedPriorities },
      ...(familyRefs
        ? [{ field: "family", operator: "oneOf", value: familyRefs }]
        : [{ field: "family", operator: "oneOf", value: storedFamilies }]),
    ]);
  }, []);

  let resultingTasks = data?.tasks || [];
  if (query) {
    const queryPaths = ["title", "priority", "state", "family.name"];
    const regexPattern = new RegExp(
      query
        .split(" ")
        .map((sub) => `(${sub})`)
        .join("|"),
      "i",
    );

    resultingTasks = resultingTasks.filter((t) => {
      const searchableValues = queryPaths
        .map((path) => _.get(t, path))
        .join(" ");
      return regexPattern.test(searchableValues);
    });
  }
  return (
    <GridPage
      sx={{
        maxWidth: "1000px",
        margin: isMobile ? "" : "auto",
        padding: isMobile ? "32px 24px" : "48px",
        gap: "20px",
      }}
    >
      <Typography variant="display" id="tableTitle" component="div">
        Tasks
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          justifyContent: "space-between",
          gap: "8px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <SearchBar
            fullWidth={isMobile}
            onQuery={setQuery}
            onKeyPress={true}
            sx={{ marginLeft: "0px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            width: isMobile ? "100%" : "50%",
            justifyContent: "end",
          }}
        >
          <Button
            startIcon={
              <AdjustmentsHorizontalIcon
                height="20px"
                width="20px"
                stroke="#198282"
              />
            }
            variant="outlined"
            onClick={() => setShowFilters(true)}
            size="small"
            fullWidth={isMobile}
          >
            Filters
          </Button>
          <Button size="small" onClick={onAddTaskClick} fullWidth={isMobile}>
            New task
          </Button>
        </Box>
      </Box>

      <Alert
        icon={
          <IdeaIcon
            fill="#198282"
            strokeWidth="0.5"
            style={{ marginRight: "-4px" }}
          ></IdeaIcon>
        }
        sx={{
          borderRadius: "12px",
          background: "#f6f9f6",
          fontWeight: "500",
          color: "#198282",
          alignSelf: "stretch",
          padding: "12px 20px",
        }}
        action={
          <Button sx={{ height: "28px" }} variant="text" size="small">
            <Link href="/task-discovery">View</Link>
          </Button>
        }
      >
        See the tasks we're suggesting to clients
      </Alert>

      <Box
        sx={{
          width: "100%",
          background: "var(--base-white, #FFF)",
          display: "flex",
          flexDirection: "column",
          padding: "0px",
        }}
      >
        {!data ? (
          <Loading />
        ) : (
          <TasksTable
            loading={loading}
            fetchTasks={fetchTasks}
            tasks={resultingTasks}
            isSortable={true}
          />
        )}
      </Box>
      <TaskFilterDrawer
        open={showFilters}
        onClose={() => {
          setShowFilters(false);
        }}
        onChange={handleChange}
        onUpdate={handleUpdate}
        initialPriorites={storedPriorities}
        initialStates={storedStates}
        initialFamilies={familyRefs ? familyRefs : storedFamilies}
        initialTimeRange={selectedTimeRange}
        families={
          data?.families.map((f) => ({ label: f.name, value: f.ref })) || []
        }
      />
    </GridPage>
  );
};

export default () => {
  const initialStates = [
    TaskState.OPEN.toString(),
    TaskState.INPROGRESS.toString(),
  ];
  useTitle("Tasks");
  return (
    <AddFilterContext initialStates={initialStates}>
      <_Wrapped initialStates={initialStates} />
    </AddFilterContext>
  );
};
