import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  buildRequest,
  EphemeralEvent,
  FormErrors,
  validateEvent,
  createDefaultEphemeralEvent,
} from "../../types/calendars";
import ReactiveDialog from "../common/ReactiveDialog";
import useIsMobile from "../hooks/useIsMobile";
import {
  useCreateCalendarEvent,
  useCreateMemberCalendarEvent,
} from "services/calendar";
import EventForm from "./EventForm";
import { EventNotice } from "protogen/calendar_pb";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import useGetEmailAutocompleteEntries from "../email/useGetEmailAutocompleteEntries";

interface Props {
  open: boolean;
  onClose: () => void;
  familyRef?: string;
  selectFamily?: boolean;
  initialFormData?: EphemeralEvent;
  onCreated?: (t: EventNotice) => void;
  accountType?: "advisor" | "member";
}

const defaultData = (familyRef?: string): EphemeralEvent => ({
  ...createDefaultEphemeralEvent(),
  familyRef,
  allDay: true,
});

export default ({
  familyRef,
  open,
  onClose,
  initialFormData,
  selectFamily = false,
  onCreated,
  accountType = "advisor",
}: Props) => {
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formData, setFormData] = useState<EphemeralEvent>(
    initialFormData || defaultData(familyRef),
  );
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { autocompleteEntries } =
    accountType === "advisor"
      ? useGetEmailAutocompleteEntries({
          familyRef: "",
        })
      : { autocompleteEntries: [] };
  const createCalendarEventRequestFn =
    accountType === "advisor"
      ? useCreateCalendarEvent
      : useCreateMemberCalendarEvent;
  const { request, loading } = createCalendarEventRequestFn((r) => {
    if (onCreated) {
      onCreated(r.notice!);
    } else {
      navigate(`/calendar/${encodeURIComponent(r?.event?.ref!)}`);
    }
  });

  useEffect(() => {
    if (!open) {
      // Clear the form on close.
      setFormData(defaultData(familyRef));
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setFormData(initialFormData || defaultData(familyRef));
    }
  }, [initialFormData, familyRef]);

  const updateFormData = (t: EphemeralEvent) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...t,
    }));
  };

  const _validate = () => {
    const errors = validateEvent(formData);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleCreateEvent = () => {
    if (_validate()) {
      request(buildRequest(formData));
      onClose();
    }
  };

  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title={"New event"}
      primaryAction={handleCreateEvent}
      primaryActionName={
        !isMobile ? (
          "Create event"
        ) : (
          <PaperAirplaneIcon height={24} width={24} stroke="white" />
        )
      }
      noActionsFullScreen={true}
      unpad
      style={{
        width: "950px",
        maxWidth: "950px",
        padding: !isMobile ? "20px" : undefined,
      }}
    >
      <EventForm
        selectFamily={selectFamily}
        loading={loading}
        formData={formData}
        updateFormData={updateFormData}
        errors={formErrors}
        hideAction={isMobile}
        onSubmit={handleCreateEvent}
        onClose={onClose}
        attendeeAutocomplete={autocompleteEntries || []}
        accountType={accountType}
      />
    </ReactiveDialog>
  );
};
