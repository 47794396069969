import { forwardRef } from "react";
import { StepProps, StepRef } from "../../types";
import OpenTextStep from "../../components/OpenTextStep";

export default forwardRef<StepRef, StepProps>(
  ({ onNext, signupState }, _ref) => {
    return (
      <OpenTextStep
        title={"Lastly, how did you hear about us?"}
        subtitle={"We'd love to know who to thank!"}
        placeholder={"Instagram, Podcast, a friend, etc."}
        onNext={async (inputText) => {
          return onNext?.({ referralSource: inputText });
        }}
        initialText={signupState?.referralSource}
        skippable={true}
        buttonText={"Finish"}
      />
    );
  },
);
