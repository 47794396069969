import { Typography, ListItem, Box } from "@mui/material";
import { Task } from "protogen/tasks_pb";
import LinkRouter from "components/navigation/LinkRouter";
import useIsMobile from "../hooks/useIsMobile";
import { PriorityChip } from "../creation/PrioritySelect";
import { dateStringToDayAndDate } from "./utils";

export default ({
  tasks,
  sx,
  showBadges = true,
}: {
  tasks: Task[];
  sx?: React.CSSProperties;
  showBadges?: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {tasks.length === 0 && (
        <ListItem
          disablePadding
          sx={{
            padding: "20px",
            maxWidth: "900px",
          }}
        >
          <Typography
            variant="body"
            color="#6B6E7B"
            sx={{
              marginBottom: isMobile ? "8px" : "4px",
            }}
          >
            No tasks
          </Typography>
        </ListItem>
      )}
      {tasks.map((task, index) => {
        return (
          <LinkRouter
            sx={{ padding: "0px" }}
            key={index}
            to={`/tasks/${encodeURIComponent(task.ref)}`}
          >
            <Box
              sx={(theme) => ({
                width: "100%",
                padding: "16px 20px",
                border: `1px solid ${theme.palette.gray[4]}`,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                ...sx,
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  width: "100%",
                  gap: isMobile ? "10px" : "12px",
                }}
              >
                <Typography
                  variant="body"
                  color="text.primary"
                  sx={{ width: "100%" }}
                >
                  {task.title}
                </Typography>

                {showBadges && (
                  <Box
                    sx={{
                      display: "flex",
                      width: isMobile ? "100%" : "fit-content",
                      alignItems: "center",
                      flexDirection: isMobile ? "row" : "row-reverse",
                      gap: isMobile ? "8px" : "12px",
                    }}
                  >
                    <PriorityChip
                      priority={task.priority}
                      isMobile={isMobile}
                    />
                    {task.dueDate && (
                      <Typography
                        variant="bodySmall"
                        color="#6B6E7B"
                        sx={(theme) => ({
                          display: "flex",
                          flexShrink: "0",
                          borderRadius: "100px",
                          border: `1px solid ${theme.palette.gray[4]}`,
                          padding: isMobile ? "3px 12px" : "7px 16px",
                        })}
                      >
                        Due {dateStringToDayAndDate(task.dueDate, false)[1]}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </LinkRouter>
        );
      })}
    </Box>
  );
};
