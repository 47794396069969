import { CSSProperties, ReactNode } from "react";
import { Box } from "@mui/material";
import useIsMobile from "../hooks/useIsMobile";
import { PAGE_FRAME_HEIGHT } from "common/constants";

export default ({
  children,
  sx,
  leftAligned,
  fullHeight,
  headerPadding = true,
}: {
  children: ReactNode;
  sx?: CSSProperties;
  leftAligned?: boolean;
  fullHeight?: boolean;
  headerPadding?: boolean;
}) => {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height:
          fullHeight && headerPadding
            ? "calc(100vh - 50px)"
            : fullHeight && !headerPadding
              ? PAGE_FRAME_HEIGHT
              : "auto",
        alignItems: leftAligned ? "flex-start" : "center",
      }}
    >
      <Box
        sx={{
          padding: isMobile ? "32px 24px" : "48px 48px 32px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "950px",
          height: "100%",
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
