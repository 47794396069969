import React from "react";
import {
  AlertTriangle,
  Angry,
  CheckCircle,
  Clock,
  Coffee,
  Flame,
  Frown,
  Ghost,
  Handshake,
  Heart,
  Hourglass,
  Smile,
} from "lucide-react";

type Flag = {
  key: string;
  icon: React.ElementType;
  title: string;
  activeStatus: "success" | "error" | "warning";
};

const FLAGS: Flag[] = [
  {
    key: "shows_love",
    icon: Heart,
    title: "Shows Love",
    activeStatus: "success", // Good thing true
  },
  {
    key: "is_dissatisfied",
    icon: Frown,
    title: "Dissatisfied",
    activeStatus: "error",
  },
  {
    key: "is_ghost",
    icon: Ghost,
    title: "Ghost",
    activeStatus: "error",
  },
  {
    key: "ineffective_service",
    icon: AlertTriangle,
    title: "Ineffective",
    activeStatus: "warning",
  },
  {
    key: "stalled_engagement",
    icon: Hourglass,
    title: "Stalled",
    activeStatus: "warning",
  },
  {
    key: "is_active",
    icon: Smile,
    title: "Active",
    activeStatus: "success",
  },
  {
    key: "is_waiting",
    icon: Clock,
    title: "Waiting",
    activeStatus: "error",
  },
  {
    key: "idle_tasks",
    icon: Coffee,
    title: "Idle Tasks",
    activeStatus: "warning",
  },
  {
    key: "is_client_frustrated",
    icon: Angry,
    title: "Frustrated",
    activeStatus: "error",
  },
  {
    key: "is_effective",
    icon: CheckCircle,
    title: "Effective",
    activeStatus: "success",
  },
  {
    key: "client_disrespectful",
    icon: Flame,
    title: "Disrespectful",
    activeStatus: "error",
  },
  {
    key: "good_rapport",
    icon: Handshake,
    title: "Rapport",
    activeStatus: "success",
  },
];

const FLAGS_MAP = FLAGS.reduce(
  (acc, flag) => {
    acc[flag.key] = flag;
    return acc;
  },
  {} as Record<string, Flag>,
);

export { FLAGS, FLAGS_MAP };
