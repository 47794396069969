import { FunctionComponent } from "react";
import { Box, Skeleton, Link, Typography } from "@mui/material";
import { PublicAdvisor } from "protogen/common_pb";
import { ReactComponent as LinkedInIcon } from "../../icons/Advisor/LinkedIn.svg";
import { ReactComponent as InstagramIcon } from "../../icons/Advisor/Instagram.svg";
import useIsMobile from "../hooks/useIsMobile";
import { formatUSPhoneNumber } from "../../common/utils";

const ExternalLink = ({
  name,
  url,
  Icon,
}: {
  name: string;
  url: string;
  Icon: FunctionComponent | undefined;
}) => {
  if (!url) return <> </>;
  return (
    <Link
      href={url}
      target="_blank"
      rel="nopener"
      sx={{
        display: "flex",
        gap: "8px",
        fontSize: "14px",
        fontWeight: "600",
      }}
      underline="none"
      color="primary"
    >
      {Icon && <Icon />} {name}
    </Link>
  );
};

const getExternalLinkIcon = (name: string): FunctionComponent | undefined => {
  if (name === "LinkedIn") return LinkedInIcon;
  if (name === "Instagram") return InstagramIcon;
  return undefined;
};

export default ({
  advisor,
  loading,
  onLogout,
  hideSocials = false,
  isPublic = false,
}: {
  advisor: PublicAdvisor | undefined | null;
  loading?: boolean;
  onLogout?: () => void;
  hideSocials?: boolean;
  isPublic?: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        ...(isMobile ? { alignItems: "start" } : { justifyContent: "center" }),
        ...(loading ? { minWidth: "220px" } : {}),
      }}
    >
      {advisor && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="display">
              {advisor.firstName} {advisor.lastName}
            </Typography>

            {onLogout && (
              <Typography
                color="primary"
                onClick={onLogout}
                style={{
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Logout
              </Typography>
            )}
          </Box>
          <Typography variant="bodyHeavy">
            {advisor.city}, {advisor.state}
          </Typography>
          {!isPublic &&
            "isProfilePublic" in advisor &&
            advisor["isProfilePublic"] &&
            advisor.profilePath && (
              <Box>
                <Link target={"_new"} href={advisor.profilePath}>
                  {advisor.profilePath.replace("https://", "")}
                </Link>
              </Box>
            )}
          <Box sx={{ marginTop: "15px" }}>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              target={"_new"}
              href={`mailto:${advisor.username}`}
            >
              {advisor.username}
            </Link>
          </Box>
          {!isPublic && (
            <Box>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
                target={"_new"}
                href={`tel:${advisor.advisorPhone}`}
              >
                {formatUSPhoneNumber(advisor.advisorPhone)}
              </Link>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginTop: "16px",
            }}
          >
            {!hideSocials &&
              advisor?.externalLinks.map((link) => {
                return (
                  <ExternalLink
                    name={link.name}
                    url={link.url}
                    Icon={getExternalLinkIcon(link.name)}
                    key={link.name}
                  />
                );
              })}
          </Box>
        </>
      )}
      {!advisor && loading && (
        <>
          <Box
            sx={{
              fontFamily: "AlbertSans",
              fontWeight: "700",
              color: "#667085",
              marginBottom: "15px",
            }}
          >
            <Skeleton width="40%" />
          </Box>
          <Box>
            <Skeleton width="40%" />
          </Box>
          <Box>
            <Skeleton width="25%" />
          </Box>
        </>
      )}
    </Box>
  );
};
