import { MenuItem, IconButton } from "@mui/material";
import { Ellipsis } from "lucide-react";
import { useState, useRef } from "react";
import PopperMenu from "../common/PopperMenu";
import { useNavigate } from "react-router";
import { useConfirmationDialog } from "../common/ConfirmationDialog";
import { useDeletePhoneCall } from "../../services/phone";
import { PhoneCall, PhoneCall_State } from "../../protogen/conversation_pb";
import { useRunWorkflow } from "../../services/extraction";
import { SuggestionEntityType } from "../../protogen/suggestions_pb";

export default ({
  phoneCall,
  familyRef,
  refresh,
}: {
  phoneCall: PhoneCall;
  familyRef?: string;
  refresh: () => Promise<void>;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const confirmState = useConfirmationDialog();
  const { request: deleteRequest, loading: deleteLoading } = useDeletePhoneCall(
    () => {
      navigate(
        familyRef
          ? `/families/${encodeURIComponent(familyRef)}`
          : "/inbox/phone",
      );
    },
  );
  const onDelete = () => {
    confirmState.openDialog(() => {
      // Make request.
      deleteRequest({ callRef: phoneCall.ref });
    });
  };

  const { request: workflowRequest, loading: workflowLoading } =
    useRunWorkflow();
  const onUpdateSuggestions = async () => {
    await workflowRequest({
      workflowName: "process-phone-call",
      arguments: [],
      entityType: SuggestionEntityType.PHONECALL,
      entityRef: phoneCall.ref,
      runAsync: false,
    });
    await refresh();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        sx={{
          borderRadius: "50px",
          border: "2px solid #E2E3E4",
          padding: "4px",
        }}
      >
        <Ellipsis size={20} />
      </IconButton>
      <PopperMenu open={open} anchorRef={anchorRef} handleClose={handleClose}>
        {phoneCall.state !== PhoneCall_State.ACTIVE ? (
          <MenuItem
            sx={{
              color: "#C14743",
              padding: "8px 16px",
              minHeight: "inherit",
            }}
            onClick={onDelete}
            disabled={deleteLoading}
          >
            Delete call
          </MenuItem>
        ) : (
          <></>
        )}
        <MenuItem
          sx={{
            color: "#3D3D3D",
            padding: "8px 16px",
            minHeight: "inherit",
          }}
          onClick={onUpdateSuggestions}
          disabled={workflowLoading}
        >
          Refresh suggestions
        </MenuItem>
      </PopperMenu>
    </>
  );
};
