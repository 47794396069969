import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography,
  MenuItem,
  PopperPlacementType,
  IconButton,
  Menu,
} from "@mui/material";
import React, { ReactElement, RefObject, useState, useEffect } from "react";
import {
  ChevronRight,
  FilterX,
  Mail,
  Pencil,
  Pin,
  PinOff,
  Send,
  Trash2,
} from "lucide-react";
import useIsMobile from "components/hooks/useIsMobile";

export default ({
  open,
  anchorRef,
  handleClose,
  children,
  placement,
}: {
  open: boolean;
  anchorRef: RefObject<HTMLButtonElement>;
  handleClose: (event: Event) => void;
  children: ReactElement | ReactElement[];
  placement?: PopperPlacementType;
}) => {
  return (
    <Popper
      sx={{ zIndex: 1 }}
      open={open}
      anchorEl={anchorRef.current}
      placement={placement ? placement : "bottom-start"}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ]}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              borderRadius: "12px",
              border: "2px solid #EAEBEC",
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(151, 93, 51, 0.07)",
              overflow: "hidden",
              padding: "8px 4px",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList sx={{ width: "100%" }}>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const getOptionIcon = (iconName: string) => {
  switch (iconName) {
    case "pencil":
      return <Pencil size={20} color="#8E9598" />;
    case "trash":
      return <Trash2 size={20} color="#8E9598" />;
    case "pin":
      return <Pin size={20} color="#8E9598" />;
    case "pinOff":
      return <PinOff size={20} color="#8E9598" />;
    case "mail":
      return <Mail size={20} color="#8E9598" />;
    case "send":
      return <Send size={20} color="#8E9598" />;
    case "filterX":
      return <FilterX size={20} color="#8E9598" />;
    default:
      return null;
  }
};

export const createMenuOption = (
  title: string,
  onClick: () => void,
  iconName: string,
) => {
  return (
    <MenuItem
      sx={{
        display: "flex",
        gap: "12px",
        padding: "12px 16px",
        "&:hover": { background: "#FAF9FA" },
      }}
      onClick={onClick}
      key={title}
    >
      {getOptionIcon(iconName)}
      <Typography color="#3D3D3D" variant="body">
        {title}
      </Typography>
    </MenuItem>
  );
};

export const SubmenuMenuOption = ({
  title,
  onClick,
  submenuOptions,
  currentValue,
}: {
  title: string;
  onClick: (v: string) => void;
  submenuOptions: { label: string; value: string }[];
  currentValue?: string;
}) => {
  const [anchorRect, setAnchorRect] = useState<DOMRect | null>(null);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const handleSubmenuClose = () => {
    setAnchorRect(null);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      handleSubmenuClose();
    }, 200);
  };

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const isMobile = useIsMobile();

  return (
    <>
      <MenuItem
        onClick={(event) =>
          setAnchorRect(event.currentTarget.getBoundingClientRect())
        }
        sx={{
          padding: "12px 16px",
          display: "flex",
          gap: "12px",
          justifyContent: "space-between",
          background: Boolean(anchorRect) ? "#FAF9FA" : "none",
          "&:hover": { background: "#FAF9FA" },
        }}
        onMouseEnter={(event) => {
          setAnchorRect(event.currentTarget.getBoundingClientRect());
          handleMouseEnter();
        }}
        onMouseLeave={handleMouseLeave}
        key={title}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          {/*{getOptionIcon(iconName)}*/}
          <Typography color="#3D3D3D" variant="body">
            {(currentValue &&
              submenuOptions.find((o) => o.value === currentValue)?.label) ||
              title}
          </Typography>
        </div>
        <IconButton size="small">
          <ChevronRight size={20} />
        </IconButton>
      </MenuItem>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={
          anchorRect
            ? {
                top: isMobile ? anchorRect.bottom : anchorRect.top - 8,
                left: isMobile ? anchorRect.left : anchorRect.right + 4,
              }
            : undefined
        }
        open={Boolean(anchorRect)}
        onClose={handleSubmenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: 48 * 5.5,
              border: "2px solid #EAEBEC",
            },
          },
          // Remove the backdrop bottom so it doesn't cover the menu so
          // it doesn't trigger mouse leave
          root: {
            sx: {
              position: "absolute",
              bottom: "auto",
              ".MuiBackdrop-root": {
                position: "absolute",
                bottom: "auto",
              },
            },
          },
        }}
      >
        {submenuOptions.map((option) => (
          <MenuItem
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            key={option.value}
            onClick={() => {
              onClick(option.value);
              handleSubmenuClose();
            }}
            sx={{ "&:hover": { background: "#FAF9FA" } }}
          >
            <Typography color="#3D3D3D" variant="body">
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
