import { LinearProgress, Box } from "@mui/material";
import useIsMobile from "../../hooks/useIsMobile";

interface ProgressBarProps {
  numerator: number;
  denominator?: number;
  minimum?: number;
  sx?: React.CSSProperties;
}

export const ProgressBar = ({
  numerator,
  denominator = 100,
  minimum = 0, // Whole number percentage for a starting "nudge"
  sx,
}: ProgressBarProps) => {
  const progress = (numerator / denominator) * 100 || minimum;
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        zIndex: "100",
        position: "relative",
        width: isMobile ? "100vw" : "100%",
        padding: "8px 0 0",
        ...sx,
      }}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 8,
          backgroundColor: "#e0e0e0",
          "& .MuiLinearProgress-bar": {
            borderRadius: "0 2px 0 0",
            backgroundColor: "#198282",
          },
        }}
      />
    </Box>
  );
};
