import { Family, Medium } from "protogen/advisors_service_pb";
import { Box, Button, IconButton } from "@mui/material";
import { FeedFocusState } from "../../types/feed";
import { Mail, Phone, Search } from "lucide-react";
import SearchDialog from "../search/SearchDialog";
import { useState } from "react";
import useIsMobile from "components/hooks/useIsMobile";

type ContactSectionProps = {
  family: Family;
  feedFocusState: FeedFocusState;
  setFeedFocusState: (s: FeedFocusState) => void;
};
export default ({ feedFocusState, setFeedFocusState }: ContactSectionProps) => {
  const isMobile = useIsMobile();
  const [searchOpen, setSearchOpen] = useState(false);
  return (
    <Box>
      {/* Mobile buttons */}
      {isMobile && (
        <Box sx={{ display: "flex", gap: "8px" }}>
          <IconButton
            sx={{
              border: "1px solid #D3D3D3",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
              padding: "8px",
            }}
            onClick={() => {
              setFeedFocusState({
                ...feedFocusState,
                selectedMedium: Medium.PHONE,
              });
            }}
          >
            <Phone />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid #D3D3D3",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
              padding: "8px",
            }}
            onClick={() => {
              setFeedFocusState({
                ...feedFocusState,
                selectedMedium: Medium.EMAIL,
              });
            }}
          >
            <Mail />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid #D3D3D3",
              borderRadius: "50px",
              height: "40px",
              width: "40px",
              padding: "8px",
            }}
            size="small"
            onClick={() => setSearchOpen(true)}
          >
            <Search height="20px" width="20px" />
          </IconButton>
          <SearchDialog
            open={searchOpen}
            onClose={() => setSearchOpen(false)}
          />
        </Box>
      )}

      {/* Desktop buttons */}
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            padding: "16px 16px",
            gap: "12px",
          }}
        >
          <Button
            sx={{
              border: "1px solid #D4D4D4",
              borderRadius: "12px",
              padding: "8px 24px !important",
              flexGrow: "1",
              color: "#6B6E7B",
              backgroundColor: "#fff",
              fontSize: "14px",
            }}
            startIcon={<Phone height="20px" width="20px" />}
            variant="text"
            size="small"
            onClick={() => {
              setFeedFocusState({
                ...feedFocusState,
                selectedMedium: Medium.PHONE,
              });
            }}
          >
            Call
          </Button>

          <Button
            sx={{
              border: "1px solid #D4D4D4",
              borderRadius: "12px",
              padding: "8px 24px !important",
              flexGrow: "1",
              color: "#6B6E7B",
              backgroundColor: "#fff",
              fontSize: "14px",
            }}
            startIcon={<Mail height="20px" width="20px" />}
            variant="text"
            size="small"
            onClick={() => {
              setFeedFocusState({
                ...feedFocusState,
                selectedMedium: Medium.EMAIL,
              });
            }}
          >
            Email
          </Button>
          <IconButton
            sx={{
              border: "1px solid #D4D4D4",
              borderRadius: "12px",
              padding: "8px !important",
              flexGrow: "1",
              color: "#6B6E7B",
              backgroundColor: "#fff",
            }}
            size="small"
            onClick={() => setSearchOpen(true)}
          >
            <Search height="20px" width="20px" />
          </IconButton>
          <SearchDialog
            open={searchOpen}
            onClose={() => setSearchOpen(false)}
          />
        </Box>
      )}
    </Box>
  );
};
