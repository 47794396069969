import { useState, useEffect, useRef } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import AddPostDialog from "components/forum/AddPostDialog";
import useIsMobile from "components/hooks/useIsMobile";
import { useParams } from "react-router-dom";
import { capitalize } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import SearchBar from "components/forum/SearchBar";
import { PAGE_DEFS, TopicFeed } from "components/forum/constants";
import { ForumPost, ForumPostType } from "protogen/forum_service_pb";
import LinkRouter from "components/navigation/LinkRouter";
import { generateSearchQueryUrl } from "components/search/utils";
import HomePanel from "components/forum/HomePanel";
import RecommendationsPanel from "components/forum/RecommendationsPanel";
import ResourcesPanel from "components/forum/ResourcesPanel";
import useTitle from "components/hooks/useTitle";
import ScrollContainer, {
  ScrollContainerHandle,
} from "components/common/ScrollContainer";
import { useFetchForumPage } from "services/forum";
import Loading from "components/common/Loading";
import PostListItem from "components/forum/PostListItem";
import useIsVisible from "components/hooks/useIsVisible";
import { PAGE_FRAME_HEIGHT } from "common/constants";
import { SCROLL_TO_REF_KEY } from "components/forum/types";
const dedupeEntries = (entries: ForumPost[]) => {
  const result: ForumPost[] = [];
  const indexMap = new Map<string, number>();
  for (const item of entries) {
    // Create a unique string representation of the keys
    const key = `${item.ref}`;
    if (!indexMap.has(key)) {
      indexMap.set(key, result.length);
      result.push(item);
    }
  }
  return result;
};

const sortPostsBasic = (a: ForumPost, b: ForumPost) => {
  return Number(b.sortSec) - Number(a.sortSec);
};

const sortPostsPinned = (a: ForumPost, b: ForumPost) => {
  // Convert isPinned to a number for comparison: true to 1, false to 0
  // We subtract 'b' from 'a' to ensure pinned posts come first
  const pinComparison = Number(b.pinnedSec) - Number(a.pinnedSec);

  // If pinComparison is 0 (both posts have the same pin status),
  // we then sort by creation time (newer posts first)
  if (pinComparison === 0) {
    return sortPostsBasic(a, b);
  }

  // Otherwise, return the result of the pin comparison
  return pinComparison;
};

const panelForTreatment = (treatment: TopicFeed) => {
  switch (treatment) {
    case TopicFeed.Home:
      return <HomePanel />;
    case TopicFeed.Recommendations:
      return <RecommendationsPanel />;
    case TopicFeed.Resources:
      return <ResourcesPanel />;
    default:
      return null;
  }
};

type Props = {
  treatment?: TopicFeed;
};

const MAX_SCROLL_RETRIES = 10;

export default ({ treatment }: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [modalOpen, setModalOpen] = useState(false);
  const { isVisible } = useIsVisible({});
  const [posts, setPosts] = useState<ForumPost[]>([]);
  const [cursor, setCursor] = useState<string | null>(null);
  const [scrollToRef, setScrollToRef] = useState<string | null>(null);
  const scrollRetryCount = useRef(0);
  const scrollContainerRef = useRef<ScrollContainerHandle>(null);

  let params = useParams();
  const [topics, setTopics] = useState<string[]>([]);
  const [postType, setPostType] = useState<ForumPostType>(
    ForumPostType.POST_DISCUSSION,
  );
  const [title, setTitle] = useState<string | null>(null);
  const [subtitle, setSubtitle] = useState<string | null>(null);

  // Move state updates to useEffect
  useEffect(() => {
    let newTopics: string[] = [];
    let newPostType = ForumPostType.POST_DISCUSSION;
    if (treatment) {
      const pageDef = PAGE_DEFS[treatment];
      newPostType = pageDef.type;
      setTitle(pageDef.title);
      setSubtitle(pageDef.subtitle);
    } else if (params.topicQuery) {
      newTopics = decodeURIComponent(params.topicQuery).split(",");
      newPostType = ForumPostType.POST_TYPE_UNSPECIFIED;
      setTitle(newTopics.map(capitalize).join(", "));
    }
    setTopics(newTopics);
    setPostType(newPostType);

    setPosts([]);
    setCursor(null);
    request({
      anyTopics: newTopics,
      anyPostTypes:
        treatment !== TopicFeed.Home && newPostType ? [newPostType] : [],
      pageSize: 0,
      cursor: "",
      respectPinned: treatment !== TopicFeed.Home,
    });
  }, [treatment, params.topicQuery]);

  useTitle(title || "Advisor");

  const sortPosts =
    treatment !== TopicFeed.Home ? sortPostsPinned : sortPostsBasic;
  const { request, error, data } = useFetchForumPage(
    (r: { nextCursor: string; posts: ForumPost[] }) => {
      setCursor(r.nextCursor);
      setPosts((prevValue) =>
        dedupeEntries([...prevValue, ...r.posts]).sort(sortPosts),
      );
    },
  );

  // Separate effect for polling
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (isVisible) {
        request({
          anyTopics: topics || [],
          anyPostTypes: postType ? [postType] : [],
          pageSize: 0,
          cursor: "",
          respectPinned: treatment !== TopicFeed.Home,
        });
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [topics, postType]);

  useEffect(() => {
    const storedRef = sessionStorage.getItem(SCROLL_TO_REF_KEY);
    if (storedRef) {
      setScrollToRef(storedRef);
      sessionStorage.removeItem(SCROLL_TO_REF_KEY);
    }
  }, []);

  useEffect(() => {
    if (scrollToRef && posts.length > 0) {
      const postElement = document.getElementById(`post-${scrollToRef}`);
      if (postElement) {
        scrollContainerRef.current?.scrollToFirstMatch((element) => {
          return element.id === `post-${scrollToRef}`;
        });
        setScrollToRef(null);
        scrollRetryCount.current = 0;
      } else if (scrollRetryCount.current < MAX_SCROLL_RETRIES) {
        scrollRetryCount.current += 1;
        request({
          anyTopics: topics || [],
          anyPostTypes: postType ? [postType] : [],
          pageSize: 0,
          cursor: cursor || "",
          respectPinned: treatment !== TopicFeed.Home,
        });
      } else {
        // Reached the retries
        setScrollToRef(null);
        scrollRetryCount.current = 0;
      }
    }
  }, [scrollToRef, posts]);

  const nextPage = async () => {
    if (cursor) {
      await request({
        anyTopics: topics || [],
        anyPostTypes: postType ? [postType] : [],
        pageSize: 0,
        cursor: cursor,
        respectPinned: treatment !== TopicFeed.Home,
      });
    }
  };

  return (
    <Box sx={{ height: PAGE_FRAME_HEIGHT, overflow: "hidden" }}>
      <ScrollContainer
        ref={scrollContainerRef}
        isInverse={false}
        fetchMoreData={nextPage}
        hasMore={!!cursor && !scrollToRef}
        sx={{
          height: "100%",
          display: "flex",
          minWidth: "100%",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px",
          "& .infinite-scroll-component__outerdiv": {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1000px",
            padding: isMobile ? "32px 24px" : "48px",
            paddingBottom: "0px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              flex: "1 1 100%",
              marginBottom: "32px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" flexDirection="column">
              {treatment !== TopicFeed.Home && (
                <LinkRouter to={"/community"}>
                  <Typography
                    sx={{ color: "#198282", fontWeight: "700" }}
                    variant="body"
                  >
                    Community
                  </Typography>
                </LinkRouter>
              )}
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Typography variant="display" sx={{ lineHeight: 1 }}>
                  {treatment === TopicFeed.Home ? "Community" : title}
                </Typography>
                {subtitle && <Typography variant="body">{subtitle}</Typography>}
              </Box>
            </Box>
            {isMobile ? (
              <IconButton
                type="button"
                sx={{ p: "10px", marginRight: "10px" }}
                aria-label="search"
                onClick={() => {
                  navigate(generateSearchQueryUrl(null, topics, "community"));
                }}
              >
                <SearchIcon />
              </IconButton>
            ) : (
              <SearchBar topics={topics} />
            )}
          </Box>
          {treatment && panelForTreatment(treatment)}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "end",
              marginBottom: "24px",
            }}
          >
            <Typography variant="h3">Recent posts</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setModalOpen(true)}
            >
              New post
            </Button>
          </Box>
        </Box>
        {posts.map((post) => (
          <Box
            key={post.ref}
            id={`post-${post.ref}`}
            sx={{
              width: "100%",
              maxWidth: "1000px",
              marginBottom: "20px",
              padding: isMobile ? "0px 24px" : "0px 48px",
            }}
          >
            <PostListItem
              post={post}
              respectPinned={treatment !== TopicFeed.Home}
            />
          </Box>
        ))}
        {(error || !data) && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "1100px",
              padding: isMobile ? "0px 24px" : "0px 48px",
            }}
          >
            <Loading />
          </Box>
        )}
      </ScrollContainer>
      <AddPostDialog
        key={`post-${treatment}-${(topics || []).join("-")}`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        topics={topics}
        postType={postType}
      />
    </Box>
  );
};
