import useProtoMethod from "./useProtoMethod";
import {
  GetOrCreateSessionRequest,
  GetOrCreateSessionResponse,
  ListSubmissionsRequest,
  ListSubmissionsResponse,
  SignupSubmissionRequest,
  SignupSubmissionResponse,
  UpdateSessionRequest,
  UpdateSessionResponse,
  UpdateSubmissionRequest,
  UpdateSubmissionResponse,
} from "../protogen/signup_service_pb";

const useGetOrCreateSession = (
  callback?: (r: GetOrCreateSessionResponse) => void,
) => {
  return useProtoMethod<GetOrCreateSessionRequest, GetOrCreateSessionResponse>(
    "GetOrCreateSession",
    GetOrCreateSessionResponse,
    { callback },
  );
};

const useUpdateSession = (callback?: (r: UpdateSessionResponse) => void) => {
  return useProtoMethod<UpdateSessionRequest, UpdateSessionResponse>(
    "UpdateSession",
    GetOrCreateSessionResponse,
    { callback },
  );
};

const useSignupSubmission = (callback?: (r: UpdateSessionResponse) => void) => {
  return useProtoMethod<SignupSubmissionRequest, SignupSubmissionResponse>(
    "SignupSubmission",
    SignupSubmissionResponse,
    { callback },
  );
};

const useListSubmissions = (
  callback?: (r: ListSubmissionsResponse) => void,
) => {
  return useProtoMethod<ListSubmissionsRequest, ListSubmissionsResponse>(
    "ListSubmissions",
    ListSubmissionsResponse,
    { callback },
  );
};

const useUpdateSubmission = (
  callback?: (r: UpdateSubmissionResponse) => void,
) => {
  return useProtoMethod<UpdateSubmissionRequest, UpdateSubmissionResponse>(
    "UpdateSubmission",
    UpdateSubmissionResponse,
    { callback },
  );
};

export {
  useGetOrCreateSession,
  useUpdateSession,
  useSignupSubmission,
  useListSubmissions,
  useUpdateSubmission,
};
