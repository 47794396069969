import { PhoneCall, PhoneCall_State } from "protogen/conversation_pb";
import { Box, Typography } from "@mui/material";
import NotesEditor from "./NotesEditor";
import React, { useRef } from "react";
import { useRunWorkflow } from "services/extraction";
import { Note } from "protogen/notes_pb";
import { AccountStub } from "protogen/common_pb";
import { SuggestionEntityType, Suggestions } from "protogen/suggestions_pb";
import { SuggestionsList } from "../activity/Suggestions";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "../activity/SuggestionActions";
import Spinner from "../common/Spinner";

type SuggestionsSectionProps = {
  phoneCall: PhoneCall;
  suggestions: Suggestions;
  familyRef: string;
  title: string;
};

const Loader = () => (
  <Box
    display="flex"
    flexDirection="row"
    gap="10px"
    alignItems="center"
    sx={{
      width: "100%",
      border: "1px solid #D4D4D4",
      borderRadius: "12px",
      padding: "16px 12px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.08)",
    }}
  >
    <Spinner size={20} />
    <Typography color="#198282" variant="body" fontWeight={600}>
      Analyzing call
    </Typography>
  </Box>
);

const SuggestionsSection = ({
  phoneCall,
  suggestions,
  familyRef,
}: SuggestionsSectionProps) => {
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const { request: workflowRequest, loading } = useRunWorkflow();
  const isFreshlyCompleteWithoutSuggestions =
    phoneCall.state === PhoneCall_State.COMPLETED &&
    // Updated within the last 30 seconds
    Date.now() - Number(phoneCall.lastUpdatedSec) * 1000 < 30000 &&
    !suggestions.hasSuggestions;
  const handleClick = async () => {
    await workflowRequest({
      workflowName: "process-phone-call",
      arguments: [],
      entityType: SuggestionEntityType.PHONECALL,
      entityRef: phoneCall.ref,
      runAsync: false,
    });
  };
  return (
    <>
      {phoneCall.state === PhoneCall_State.ACTIVE && (
        <Typography variant="bodySmall">
          Summary and task suggestions will be generated at the end of the call.{" "}
          <span
            style={{
              color: "#198282",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            Try now?
          </span>
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap="24px"
        mb="16px"
        width="100%"
      >
        {(loading || isFreshlyCompleteWithoutSuggestions) && <Loader />}
        {!loading && suggestions?.hasSuggestions && (
          <SuggestionsList
            inlineMode={true}
            suggestions={suggestions}
            actions={{
              suggestTask: (t) => actionsRef.current?.suggestTask(t),
              suggestFact: (f) => actionsRef.current?.suggestFact(f),
              suggestEvent: (e) => actionsRef.current?.suggestEvent(e),
            }}
            isOpen={true}
            entityRef={phoneCall.ref}
            entityType={"phonecall"}
            familyRef={familyRef}
          />
        )}
      </Box>
      <SuggestionActions ref={actionsRef} familyRef={familyRef} />
    </>
  );
};

type Props = {
  phoneCall: PhoneCall;
  caller: AccountStub;
  recipient: AccountStub;
  callNotes: Note;
  familyRef: string;
  suggestions: Suggestions;
};

export default ({ phoneCall, callNotes, familyRef, suggestions }: Props) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"32px"}
      marginTop={"32px"}
      paddingRight="20px"
      paddingLeft="20px"
    >
      <SuggestionsSection
        phoneCall={phoneCall}
        suggestions={suggestions}
        familyRef={familyRef}
        title={"Smart Suggestions"}
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"12px"}
        marginBottom={"32px"}
      >
        <Typography variant="h4">Notes</Typography>
        <NotesEditor note={callNotes} title={"Notes"} initialContent={""} />
      </Box>
    </Box>
  );
};
