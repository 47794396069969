import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Family, FormErrors, prettyFamilyStatus } from "./types";
import { FamilyStatus } from "protogen/advisors_service_pb";
import { useState, useEffect, useContext } from "react";
import AdvisorSelect from "../creation/AdvisorSelect";
import AddressAutocomplete, { Location } from "../common/AddressAutocomplete";
import FamilyPlanDetails from "./FamilyPlanDetails";
import { hasRole, ROLE_ADMIN } from "../../common/userUtils";
import BillingInfo from "./BillingInfo";
import { CurrentUserContext } from "../context/RequireAuth";
import PlatformFeeSelect from "./PlatformFeeSelect";
import { DatePicker } from "@mui/x-date-pickers";
import { ChevronDown } from "lucide-react";
import CopyTextField from "../common/CopyTextField";
import ActionMenu from "./billing-management/ActionMenu";

const unobfuscateId = (obfuscatedId: string): number => {
  // Decode the Base64 string to a binary string
  const binaryString = atob(obfuscatedId.replace(/-/g, "+").replace(/_/g, "/"));
  // Convert the binary string to bytes
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  // Convert the bytes to an integer
  let result = 0;
  for (let i = 0; i < byteArray.length; i++) {
    result = (result << 8) | byteArray[i];
  }
  return result;
};

const EndDate = ({
  date,
  onDateChange,
  error,
  disabled = false,
}: {
  date: Date | null;
  onDateChange: (date: Date | null) => void;
  error?: string;
  disabled?: boolean;
}) => {
  const [dateOpen, setDateOpen] = useState(false);
  return (
    <DatePicker
      sx={{
        width: "calc(33% - 8px)",
        ".MuiButtonBase-root": { marginRight: "unset" },
      }}
      label="End date"
      defaultValue={date}
      disabled={disabled}
      onChange={(dte) => {
        if (dte === null) return;
        onDateChange(dte);
      }}
      format="MM/dd/yyyy"
      open={dateOpen}
      onClose={() => setDateOpen(false)}
      slotProps={{
        textField: {
          error: !!error,
          helperText: error,
          onClick: () => setDateOpen(true),
        },
      }}
    />
  );
};

interface Props {
  familyData: Family;
  setFamilyData: (f: Partial<Family>) => void;
  errors: FormErrors | null;
  selectAdvisor?: boolean;
  stacked?: boolean;
}

export const PlanDetailsForm = ({
  familyData,
  setFamilyData,
  errors,
  stacked = true,
}: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const isAdmin = hasRole(currentUser, ROLE_ADMIN);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <FamilyPlanDetails
        disabled={!isAdmin && familyData.status === FamilyStatus.ACTIVE}
        planDetails={{
          startDate: familyData.startDate
            ? new Date(familyData.startDate)
            : undefined,
          product: familyData.product,
          coupon: familyData.introCoupon,
        }}
        initialProductName={familyData.productName}
        initialCouponId={familyData.introCouponId}
        errors={{}}
        onPlanDetailsChange={(details) => {
          setFamilyData({
            ...("product" in details ? { product: details.product } : {}),
            ...("coupon" in details ? { introCoupon: details.coupon } : {}),
            ...("startDate" in details
              ? {
                  startDate: details.startDate
                    ? details.startDate.toLocaleDateString()
                    : null,
                }
              : {}),
          });
        }}
        stacked={stacked}
      />
      {isAdmin && (
        <>
          <Box display={"flex"} flexDirection={"row"} gap={"12px"}>
            {familyData.endDate ? (
              <EndDate
                disabled={true}
                date={new Date(familyData.endDate)}
                onDateChange={(d) =>
                  setFamilyData({
                    endDate:
                      d && !isNaN(d.getTime()) ? d.toLocaleDateString() : null,
                  })
                }
              />
            ) : null}
            <PlatformFeeSelect
              value={familyData.platformFeeSchedule}
              onUpdate={(v) =>
                setFamilyData({
                  platformFeeSchedule: v,
                })
              }
            />
            {familyData.billingInfo?.stripeCustomerId ? (
              <BillingInfo
                stripeCustomerId={familyData.billingInfo?.stripeCustomerId}
              />
            ) : (
              <TextField
                error={!!errors?.stripeCustomerId}
                helperText={errors?.stripeCustomerId}
                defaultValue={familyData.stripeCustomerId}
                onChange={(e) =>
                  setFamilyData({
                    stripeCustomerId: e.target.value,
                  })
                }
                label="Stripe ID"
                margin="normal"
                size="small"
                sx={{ marginTop: 0, width: "calc(33% - 8px)" }}
              />
            )}
          </Box>
          {isAdmin && familyData.status === FamilyStatus.ACTIVE && (
            <ActionMenu familyData={familyData} />
          )}
        </>
      )}
    </Box>
  );
};

export default ({
  familyData,
  setFamilyData,
  errors,
  selectAdvisor = false,
}: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const isAdmin = hasRole(currentUser, ROLE_ADMIN);
  const [isLoaded, setIsLoaded] = useState(false);
  const [parsedAddress, setParsedAddress] = useState<Location>();
  useEffect(() => {
    if (!familyData.address) return;
    setParsedAddress(JSON.parse(familyData.address));
    setIsLoaded(true);
  }, [familyData.address]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        marginTop: "16px",
      }}
    >
      <Typography variant={"h3"}>Family details</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "24px" }}>
        <TextField
          fullWidth
          error={!!errors?.name}
          helperText={errors?.name}
          value={familyData.name}
          onChange={(e) =>
            setFamilyData({ ...familyData, name: e.target.value })
          }
          label="Family Name"
          margin="none"
          size="small"
        />
        <FormControl fullWidth>
          <InputLabel id="select-label">Status</InputLabel>
          <Select
            fullWidth
            labelId="select-label"
            id="select-label"
            value={familyData.status}
            label="Status"
            IconComponent={ChevronDown}
            disabled={!isAdmin && familyData.status === FamilyStatus.ACTIVE}
            onChange={(e) =>
              setFamilyData({
                ...familyData,
                status: e.target.value as FamilyStatus,
              })
            }
          >
            <MenuItem value={FamilyStatus.ACTIVE}>
              {prettyFamilyStatus(FamilyStatus.ACTIVE)}
            </MenuItem>
            <MenuItem value={FamilyStatus.PROSPECT}>
              {prettyFamilyStatus(FamilyStatus.PROSPECT)}
            </MenuItem>
            <MenuItem value={FamilyStatus.DEMO}>
              {prettyFamilyStatus(FamilyStatus.DEMO)}
            </MenuItem>
            <MenuItem value={FamilyStatus.TEST}>
              {prettyFamilyStatus(FamilyStatus.TEST)}
            </MenuItem>
            <MenuItem value={FamilyStatus.DEACTIVATED}>
              {prettyFamilyStatus(FamilyStatus.DEACTIVATED)}
            </MenuItem>
            <MenuItem value={FamilyStatus.DEACTIVATED_PROSPECT}>
              {prettyFamilyStatus(FamilyStatus.DEACTIVATED_PROSPECT)}
            </MenuItem>
            <MenuItem value={FamilyStatus.PREACTIVATION}>
              {prettyFamilyStatus(FamilyStatus.PREACTIVATION)}
            </MenuItem>
          </Select>
        </FormControl>
        {isAdmin && familyData.ref && (
          <CopyTextField
            label="Family ID"
            value={`${unobfuscateId(familyData.ref)}`}
            sx={{
              border: "1px solid #D4D4D4",
              borderRadius: "12px",
            }}
          />
        )}
      </Box>
      {isLoaded && (
        <AddressAutocomplete
          label={"Address"}
          error={errors?.location}
          initialValue={parsedAddress?.formattedAddress || ""}
          setValue={(location) => {
            setParsedAddress(location);
            setFamilyData({
              ...familyData,
              address: JSON.stringify(location),
            });
          }}
          handleCopy={true}
        />
      )}
      {selectAdvisor && (
        <AdvisorSelect
          title="Advisor"
          disabled={false}
          isAdmin={true} // Can't get into this state without being an admin
          error={errors?.advisorRef}
          selectedAdvisor={familyData.advisorRef || null}
          onChange={(v) => setFamilyData({ ...familyData, advisorRef: v! })}
        />
      )}
    </Box>
  );
};
