import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "components/common/Loading";
import { useAdminListAllAdvisors } from "services/admin";
import LinkRouter from "components/navigation/LinkRouter";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import {
  ChevronDown,
  ChevronUp,
  Edit,
  ExternalLink,
  Ghost,
  MailCheck,
  Map as MapIcon,
  MessageSquare,
  Plus,
} from "lucide-react";

import {
  commaSeparatedEnglishList,
  formatUSPhoneNumber,
} from "../../common/utils";
import { Advisor, AdvisorStatus, FamilyStub } from "protogen/common_pb";
import {
  getFormattedLocation,
  getLatLng,
  getSearchableLocation,
} from "components/family/utils";
import MapList from "components/common/maps/MapList";
import { Treatment } from "components/common/maps/FMarker";
import SearchBar from "components/forum/SearchBar";
import AddAdvisorDialog from "components/advisor/AddAdvisorDialog";
import EditAdvisorDialog from "components/advisor/EditAdvisorDialog";
import { UserAvatar } from "components/common/CurrentUserAvatar";
import Collapse from "@mui/material/Collapse";
import InviteAdvisorDialog from "components/advisor/InviteAdvisorDialog";

import MetadataSection from "../../components/common/MetadataSection";
import { useUpdateAdvisor } from "../../services/advisor";
import { UpdateAdvisorRequest } from "../../protogen/advisors_service_pb";

const AdvisorMetadata = ({ advisor }: { advisor: Advisor }) => {
  const { request } = useUpdateAdvisor();
  if (advisor.metadata === undefined) return null;
  return (
    <Box
      sx={{
        marginTop: "36px",
        marginBottom: "12px",
      }}
    >
      <MetadataSection
        metadata={advisor.metadata}
        saveMetadata={async (metadata) => {
          await request(
            new UpdateAdvisorRequest({
              advisorRef: advisor.ref,
              shouldUpdateMetadata: true,
              updatedMetadata: metadata,
            }),
          );
        }}
        entityType={"advisor"}
      />
    </Box>
  );
};

type AdvisorCardProps = {
  advisor: Advisor;
  forceOpen: boolean;
  onEdit: () => void;
};

const AdvisorCard = ({ advisor, forceOpen, onEdit }: AdvisorCardProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const byline =
    commaSeparatedEnglishList(advisor.specialties) || advisor.blurbText;
  const location = `${advisor.city}, ${advisor.state}`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="12px"
        sx={{ cursor: !open ? "pointer" : undefined }}
        onClick={() => !open && setOpen(!open)}
      >
        <Box flex={0}>
          <UserAvatar user={advisor} size={48} clickable={false} />
        </Box>
        <Box flex={5}>
          <Box display="flex" flexDirection={"column"} gap="4px">
            <LinkRouter to={`/advisor/${encodeURIComponent(advisor.ref)}`}>
              <Typography
                variant="bodyHeavy"
                color="#262626"
              >{`${advisor.firstName} ${advisor.lastName}`}</Typography>
            </LinkRouter>
            <Typography
              variant="body"
              color="text.secondary"
              sx={{
                textWrap: "none",
                textOverflow: "ellipses",
                overflow: "hidden",
              }}
            >
              {isMobile ? location : byline}
            </Typography>
          </Box>
        </Box>
        <Box flex={2} justifyContent="end" display={isMobile ? "none" : "flex"}>
          <Typography
            variant="body"
            color="#262626"
            sx={{
              textAlign: "right",
            }}
          >
            {location}
          </Typography>
        </Box>
        <Box flex={2} display={isMobile ? "none" : "flex"} justifyContent="end">
          {getStatus(advisor.status)}
        </Box>
        <Box flex={0}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </Box>
      </Box>
      {isMobile && byline && (
        <Typography variant="bodySmall" color="#6B6E7B">
          {byline}
        </Typography>
      )}
      <Collapse in={open || forceOpen} timeout="auto" unmountOnExit>
        <div
          style={{
            marginTop: "12px",
            height: "1px",
            background: "#E2E3E4",
          }}
        />
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Box sx={{ padding: "24px 32px" }} flex={2}>
              <Typography variant="h3" marginBottom="20px">
                Details
              </Typography>
              <Box display="flex" flexDirection="column" gap="10px">
                <Box>
                  <Typography variant="bodyHeavy">Faye email</Typography>
                  <Typography variant="body">{advisor.username}</Typography>
                </Box>
                <Box>
                  <Typography variant="bodyHeavy">Faye number</Typography>
                  <Typography variant="body">
                    {formatUSPhoneNumber(advisor.advisorPhone)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodyHeavy">Families</Typography>
                  <Typography variant="body">
                    {advisor.families.length === 0 && <i>None</i>}
                    {advisor.families.map((f: FamilyStub, idx: number) => (
                      <Fragment key={f.ref}>
                        <LinkRouter
                          inline
                          to={`/families/${encodeURIComponent(f.ref)}`}
                          sx={{ color: "#198282" }}
                        >
                          {f.name}
                        </LinkRouter>
                        {idx < advisor.families.length - 1 ? ", " : ""}
                      </Fragment>
                    ))}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodyHeavy">Personal email</Typography>
                  <Typography variant="body">
                    {advisor.personalEmail}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodyHeavy">Personal phone</Typography>
                  <Typography variant="body">
                    {formatUSPhoneNumber(advisor.personalPhone)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodyHeavy">Home address</Typography>
                  <Typography variant="body">
                    {getFormattedLocation(advisor.address)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodyHeavy">
                    Email notifications
                  </Typography>
                  <Typography variant="body">
                    {advisor.emailNotificationsEnabled ? "On" : "Off"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodyHeavy">Roles</Typography>
                  <Typography variant="body">
                    {advisor.roles.length === 0 && <i>None</i>}
                    {advisor.roles.map((r) => (
                      <Chip size="small" key={r} variant="outlined" label={r} />
                    ))}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ padding: "24px 32px" }} flex={2}>
              <Typography variant="h3" marginBottom="20px">
                Actions
              </Typography>
              <Box display="flex" flexDirection="column">
                <Button
                  startIcon={<Edit />}
                  variant={"text"}
                  sx={{ justifyContent: "start" }}
                  onClick={onEdit}
                >
                  Edit advisor
                </Button>
                <Button
                  startIcon={<MessageSquare />}
                  variant={"text"}
                  sx={{ justifyContent: "start" }}
                  onClick={() => {
                    navigate(`/inbox/messages?recipientRefs=${advisor.ref}`);
                  }}
                >
                  Direct Message
                </Button>
                <Button
                  startIcon={<ExternalLink />}
                  variant={"text"}
                  sx={{ justifyContent: "start" }}
                  onClick={() => {
                    window.open(advisor.profilePath, "_blank");
                  }}
                  disabled={!advisor.isProfilePublic}
                >
                  View public profile
                </Button>
                <Button
                  startIcon={<Ghost />}
                  variant={"text"}
                  sx={{ justifyContent: "start" }}
                  onClick={() => {
                    navigate(
                      `/tools/impersonate-user?username=${encodeURIComponent(
                        advisor.username,
                      )}`,
                    );
                  }}
                >
                  Impersonate
                </Button>
                <Button
                  startIcon={<MailCheck />}
                  variant={"text"}
                  sx={{ justifyContent: "start" }}
                  onClick={() => {
                    navigate(
                      `/tools/manage-roles?username=${encodeURIComponent(
                        advisor.username,
                      )}`,
                    );
                  }}
                >
                  Manage roles
                </Button>
                <AdvisorMetadata advisor={advisor} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

const getStatus = (status: AdvisorStatus) => {
  switch (status) {
    case AdvisorStatus.ACTIVE:
      return <Chip variant="outlined" color="success" label={"Active"} />;
    case AdvisorStatus.PENDING:
      return <Chip variant="outlined" color="warning" label={"Pending"} />;
    case AdvisorStatus.DEACTIVATED:
      return <Chip variant="outlined" color="error" label={"Deactivated"} />;
    case AdvisorStatus.STAFF:
      return <Chip variant="outlined" label={"Staff"} />;
    case AdvisorStatus.TEST:
      return <Chip variant="outlined" label={"Test"} />;
    default:
      return <Chip variant="outlined" label={"?"} />;
  }
};

const getStateColor = (status: AdvisorStatus) => {
  switch (status) {
    case AdvisorStatus.ACTIVE:
      return "#198282";
    case AdvisorStatus.PENDING:
      return "#FFA500";
    case AdvisorStatus.DEACTIVATED:
      return "#FF0000";
    case AdvisorStatus.STAFF:
    case AdvisorStatus.TEST:
    default:
      return "#9E9E9E";
  }
};

export default () => {
  const isMobile = useIsMobile();
  const [mapOpen, setMapOpen] = useState(false);
  const [addAdvisorOpen, setAddAdvisorOpen] = useState(false);
  const [inviteAdvisorOpen, setInviteAdvisorOpen] = useState(false);
  const [editAdvisor, setEditAdvisor] = useState<Advisor | null>(null);
  const [query, setQuery] = useState(
    new URLSearchParams(window.location.search).get("query") || "",
  );
  const { request, data, loading } = useAdminListAllAdvisors();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    // Update URL whenever query changes
    setSearchParams(query ? { query } : {});
  }, [query, setSearchParams]);

  const allAdvisors = (data?.advisors || []).sort((a, b) =>
    a.displayName.localeCompare(b.displayName),
  );

  let resultingAdvisors = allAdvisors;
  if (query) {
    const cleanQuery = query.toLowerCase();
    resultingAdvisors = resultingAdvisors.filter(
      (a) =>
        a.displayName.toLowerCase().includes(cleanQuery) ||
        getSearchableLocation(a.address).includes(cleanQuery) ||
        a.families.some((f: FamilyStub) =>
          f.name.toLowerCase().includes(cleanQuery),
        ),
    );
  }
  const mappableAdvisors = resultingAdvisors.filter(
    (a) => !!getFormattedLocation(a.address),
  );
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "64px",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Advisor Administration</Typography>
            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              alignItems={"center"}
            >
              <SearchBar
                initialQuery={query}
                fullWidth={isMobile}
                autofocus={true}
                onQuery={setQuery}
                onKeyPress={true}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            gap="12px"
            marginTop="12px"
            justifyContent={"space-between"}
          >
            <Box display="flex" flexDirection="row" gap="12px" marginTop="12px">
              <Button
                variant="outlined"
                onClick={() => setAddAdvisorOpen(true)}
                sx={{
                  height: "36px",
                  minWidth: "unset",
                  padding: "0 15px",
                }}
                startIcon={<Plus />}
              >
                Add Advisor
              </Button>
              <Button
                variant="outlined"
                onClick={() => setInviteAdvisorOpen(true)}
                sx={{
                  height: "36px",
                  minWidth: "unset",
                  padding: "0 15px",
                }}
                startIcon={
                  <MailCheck
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                }
              >
                Invite Advisor
              </Button>
            </Box>
            <Box display="flex" flexDirection="row" gap="12px" marginTop="12px">
              <Button
                onClick={() => mappableAdvisors.length && setMapOpen(true)}
                sx={{
                  borderRadius: "100px",
                  border: "2px solid #EAEBEC",
                  color: "#616161",
                  backgroundColor: "unset",
                  fontWeight: 500,
                  "&:hover": {
                    backgroundColor: "#EAEBEC",
                  },
                }}
                startIcon={<MapIcon size={20} stroke="#8E9598" />}
              >
                Mapped
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "0" : undefined,
        }}
      >
        {!isMobile && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="end"
            padding="24px 32px 0 32px"
            mr="34px"
            ml="60px"
          >
            <Box flex={5} display="flex">
              <Typography variant="bodySmallHeavy" color="#262626">
                Name
              </Typography>
            </Box>
            {!isMobile && (
              <>
                <Box flex={2} display="flex" justifyContent="end">
                  <Typography
                    variant="bodySmallHeavy"
                    color="#262626"
                    alignSelf={"end"}
                  >
                    Location
                  </Typography>
                </Box>
                <Box flex={2} display="flex" justifyContent="end">
                  <Typography variant="bodySmallHeavy" color="#262626">
                    Status
                  </Typography>
                </Box>{" "}
              </>
            )}
          </Box>
        )}
        {loading && <Loading />}
        {resultingAdvisors.map((advisor) => (
          <AdvisorCard
            key={advisor.ref}
            advisor={advisor}
            onEdit={() => setEditAdvisor(advisor)}
            forceOpen={resultingAdvisors.length === 1}
          />
        ))}
      </Box>
      <InviteAdvisorDialog
        open={inviteAdvisorOpen}
        onClose={() => {
          setInviteAdvisorOpen(false);
        }}
      />
      {editAdvisor && (
        <EditAdvisorDialog
          advisor={editAdvisor}
          open={!!editAdvisor}
          onClose={(updated) => {
            setEditAdvisor(null);
            if (updated) {
              request();
            }
          }}
        />
      )}
      <AddAdvisorDialog
        open={addAdvisorOpen}
        onClose={(created) => {
          setAddAdvisorOpen(false);
          if (created) {
            request();
          }
        }}
      />
      <MapList
        open={mapOpen && mappableAdvisors.length > 0}
        onClose={() => setMapOpen(false)}
        locations={resultingAdvisors
          .filter((a) => a.status !== AdvisorStatus.DEACTIVATED)
          .map((a) => ({
            title: a.displayName,
            link: `/advisor/${encodeURIComponent(a.ref)}`,
            content: getFormattedLocation(a.address),
            color: getStateColor(a.status),
            treatment: Treatment.Pin,
            bounding: true,
            address: getFormattedLocation(a.address),
            ...getLatLng(a.address),
          }))}
      />
    </Box>
  );
};
