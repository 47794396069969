import { ButtonProps } from "@mui/material";
import { LucideIcon } from "lucide-react";
import { ReactNode, useState } from "react";
import { Menu } from "@mui/material";

interface EditIconProps extends ButtonProps {
  IconComponent?: LucideIcon;
  children?: ReactNode;
  isActive?: boolean;
  submenuItems?: {
    label: string;
    onClick: () => void;
    icon: LucideIcon;
    isActive?: boolean;
  }[];
}

export default ({
  IconComponent,
  isActive,
  children,
  submenuItems,
  ...otherProps
}: EditIconProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          border: "none",
          padding: "6px",
          height: "32px",
          width: "32px",
          color: "#8E9598",
          marginRight: "2px",
          // Needs a "pressed" state
          ...(isActive
            ? { backgroundColor: "#f0f0f0", borderRadius: "4px" }
            : { background: "none" }),
        }}
        onClick={submenuItems ? handleOpen : otherProps.onClick}
        {...otherProps}
      >
        {IconComponent && <IconComponent size={20} />}
        {children}
      </button>
      {submenuItems && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              marginTop: "8px",
              borderRadius: "0px !important",
              border: "1px solid #EAEBEC",
              background: "#FFF",
              boxShadow: "none",
              padding: "0",
            },
          }}
        >
          <div style={{ display: "flex", gap: "2px" }}>
            {submenuItems.map((item, index) => {
              const Icon = item.icon;
              return (
                <button
                  key={index}
                  onClick={() => {
                    item.onClick();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    border: "none",
                    padding: "6px",
                    height: "32px",
                    width: "32px",
                    color: item.isActive ? "#000" : "#8E9598",
                    backgroundColor: item.isActive ? "#f0f0f0" : "transparent",
                  }}
                >
                  <Icon size={20} />
                </button>
              );
            })}
          </div>
        </Menu>
      )}
    </>
  );
};
