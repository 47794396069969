import useProtoMethod from "./useProtoMethod";
import {
  CreateFamilyAnnotationRequest,
  CreateFamilyAnnotationResponse,
  FetchEngagementReportsRequest,
  FetchEngagementReportsResponse,
  ListFamilyAnnotationsRequest,
  ListFamilyAnnotationsResponse,
  ListLeadAnnotationsRequest,
  ListLeadAnnotationsResponse,
} from "protogen/engagement_service_pb";

const useFetchAdminEngagementReports = (
  callback?: (r: FetchEngagementReportsResponse) => void,
) => {
  return useProtoMethod<
    FetchEngagementReportsRequest,
    FetchEngagementReportsResponse
  >("FetchAdminEngagementReports", FetchEngagementReportsResponse, {
    callback,
  });
};

const useFetchAdvisorEngagementReports = (
  callback?: (r: FetchEngagementReportsResponse) => void,
) => {
  return useProtoMethod<
    FetchEngagementReportsRequest,
    FetchEngagementReportsResponse
  >("FetchAdvisorEngagementReports", FetchEngagementReportsResponse, {
    callback,
  });
};

const useListFamilyAnnotations = (
  callback?: (r: ListFamilyAnnotationsResponse) => void,
) => {
  return useProtoMethod<
    ListFamilyAnnotationsRequest,
    ListFamilyAnnotationsResponse
  >("ListFamilyAnnotations", ListFamilyAnnotationsResponse, {
    callback,
  });
};

const useListLeadAnnotations = (
  callback?: (r: ListLeadAnnotationsResponse) => void,
) => {
  return useProtoMethod<
    ListLeadAnnotationsRequest,
    ListLeadAnnotationsResponse
  >("ListLeadAnnotations", ListLeadAnnotationsResponse, {
    callback,
  });
};

const useCreateFamilyAnnotation = (
  callback?: (r: CreateFamilyAnnotationResponse) => void,
) => {
  return useProtoMethod<
    CreateFamilyAnnotationRequest,
    CreateFamilyAnnotationResponse
  >("CreateFamilyAnnotation", CreateFamilyAnnotationResponse, {
    callback,
  });
};

export {
  useFetchAdminEngagementReports,
  useFetchAdvisorEngagementReports,
  useListFamilyAnnotations,
  useListLeadAnnotations,
  useCreateFamilyAnnotation,
};
