import React, { useContext, useEffect, useState } from "react";
import { MicOff, Phone, Grid, LucideIcon, NotebookPen } from "lucide-react";
import { CallContext } from "../context/AddCallContext";
import { useGetPhoneCall, useTransferCallDevice } from "services/phone";
import { Device } from "protogen/phone_service_pb";
import { Box, Fade, IconButton, Tooltip, Typography } from "@mui/material";
import Duration from "../common/Duration";
import Dialpad from "./Dialpad";
import { commaSeparatedEnglishList } from "../../common/utils";
import useIsVisible from "../hooks/useIsVisible";
import useIsMobile from "../hooks/useIsMobile";
import useDraggablePosition from "../common/useDraggablePosition";
import { ReactComponent as EllipseIcon } from "icons/Menu/Ellipse.svg";
import { useNavigate } from "react-router";

const CallActionIcon = ({
  icon: Icon,
  onClick,
  active,
  title,
}: {
  icon: LucideIcon;
  onClick: () => void;
  active: boolean;
  title: string;
}) => {
  return (
    <Tooltip title={title}>
      <IconButton
        onClick={onClick}
        sx={{
          display: "flex",
          width: "40px",
          height: "40px",
          padding: "4px 9px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "100px",
          border: "1px solid #D4D4D4",
          "&:hover": { backgroundColor: "#e8e8e8" },
          ...(active ? { backgroundColor: "#e8e8e8" } : { background: "none" }),
        }}
      >
        <Icon className="icon" color="#6B6E7B" size={20} />
      </IconButton>
    </Tooltip>
  );
};

const isCallActive = (status: string) => {
  const statuses = new Set([
    "initiating",
    "calling",
    "forwarding",
    "active",
    "transferring",
  ]);
  return statuses.has(status);
};

const usePhoneCall = ({
  callRef,
  waitTime = 5000,
}: {
  callRef?: string;
  waitTime?: number;
}) => {
  const { data, request, loading } = useGetPhoneCall();
  const { isVisible } = useIsVisible({});
  let intervalId: NodeJS.Timer;

  const startLoop = async () => {
    if (callRef && isVisible) {
      await request({ callRef: callRef });
      intervalId = setInterval(async () => {
        if (isVisible && callRef) {
          const resp = await request({ callRef: callRef });
          if (!isCallActive(resp?.phoneCall?.status)) {
            stopLoop();
          }
        }
      }, waitTime);
    }
  };

  const stopLoop = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  useEffect(() => {
    return () => {
      stopLoop();
    };
  }, [callRef]);

  return { data, loading, startLoop, stopLoop };
};

const FloatingCallModule = ({
  position = "bottom-right",
}: {
  position?: "bottom-right" | "bottom-left" | "top-right" | "top-left";
}) => {
  const {
    device: { isMuted, endCall, muteCall, sendDigits },
    activeCall,
  } = useContext(CallContext);
  const isMobile = useIsMobile();
  const { data, startLoop } = usePhoneCall({ callRef: activeCall?.ref });
  const { request } = useTransferCallDevice();
  const [ended, setEnded] = useState(false);
  const [dialpadOpen, setDialpadOpen] = useState(false);
  const navigate = useNavigate();
  const { positionOffset, handleMouseDown, handleMouseMove, handleMouseUp } =
    useDraggablePosition(position, isMobile);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onTransfer = () => {
    request({
      callId: activeCall?.ref!,
      toDevice: Device.PHONE,
    });
  };
  useEffect(() => {
    if (activeCall?.ref) {
      startLoop();
    }
    setEnded(false);
  }, [activeCall?.ref]);
  const caller = data?.caller;
  const recipients = data?.recipients;
  const phoneCall = data?.phoneCall;
  const [isHovered, setIsHovered] = useState(false);
  const callerName = caller?.isAdvisor
    ? commaSeparatedEnglishList(
        (recipients || []).map((r) => r.displayName).sort(),
      )
    : caller?.displayName || "";

  const onHangup = () => {
    setEnded(true);
    endCall?.();
  };
  const onMute = () => {
    muteCall?.();
  };
  const onDialpadPress = (s: string) => {
    sendDigits?.(s);
  };
  return (
    <Fade in={isCallActive(phoneCall?.status)} timeout={2000}>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          left: positionOffset.left,
          right: positionOffset.right,
          top: positionOffset.top,
          bottom: positionOffset.bottom,
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={(e) => handleMouseDown(e)}
        onTouchMove={(e) => handleMouseMove(e)}
        onTouchEnd={handleMouseUp}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fff",
            borderRadius: "12px",
            padding: "16px 24px",
            width: isMobile ? "90vw" : "260px",
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            animation: "floatingCallModuleFadeIn 0.3s ease-out forwards",
          }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center">
              <div
                style={{
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="bodySmall"
                  display="flex"
                  flexDirection="row"
                  gap="4px"
                  alignItems="center"
                  sx={{
                    "svg circle": { fill: "#B3AFB6" },
                  }}
                  marginBottom="4px"
                >
                  Active call
                  <EllipseIcon height={4} width={4} />
                  {data?.phoneCall && (
                    <Duration
                      startTime={
                        new Date(Number(data.phoneCall.creationSec) * 1000)
                      }
                    />
                  )}
                </Typography>
                <Typography variant="bodyHeavy" marginBottom="8px">
                  {callerName}
                </Typography>
                <Box display="flex" flexDirection="row" gap="8px">
                  <CallActionIcon
                    onClick={onMute}
                    icon={MicOff}
                    active={isMuted}
                    title="Mute call"
                  />
                  {/* Note(Kip): Not working at the moment and may have been broken for awhile. /}
                  {/*<CallActionIcon*/}
                  {/*  icon={PhoneForwarded}*/}
                  {/*  active={false}*/}
                  {/*  title="Transfer to personal phone"*/}
                  {/*  onClick={onTransfer}*/}
                  {/*/>*/}
                  <CallActionIcon
                    onClick={() => setDialpadOpen((o) => !o)}
                    icon={Grid}
                    active={dialpadOpen}
                    title="Dialpad"
                  />
                  <CallActionIcon
                    onClick={() =>
                      navigate(`/calls/${encodeURIComponent(phoneCall?.ref)}`)
                    }
                    icon={NotebookPen}
                    active={false}
                    title="View / add notes"
                  />
                </Box>
              </div>
              <button
                disabled={ended}
                onClick={onHangup}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  marginLeft: "16px",
                  minWidth: "48px",
                  minHeight: "48px",
                  backgroundColor: isHovered ? "#EF4444" : "#F87171",
                  border: "none",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                  padding: 0,
                  opacity: ended ? 0.5 : 1,
                }}
              >
                <Phone size={24} color="#fff" />
              </button>
            </Box>
            <Dialpad open={dialpadOpen} onPress={onDialpadPress} />
          </Box>
        </Box>
        <style>
          {`
            @keyframes floatingCallModuleFadeIn {
              from {
                opacity: 0;
                transform: translateY(20px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }
          `}
        </style>
      </div>
    </Fade>
  );
};

export default () => {
  return <FloatingCallModule position={"top-right"} />;
};
