import GridPage from "components/layout/GridPage";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { KeyIcon } from "@heroicons/react/24/outline";
import { useImpersonation } from "services/admin";
import useIsMobile from "components/hooks/useIsMobile";
import { useLocation } from "react-router-dom";
import { useSnackpack } from "components/context/SnackpackContextProvider";

export default () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMobile = useIsMobile();
  const [username, setUsername] = useState(queryParams.get("username") || "");
  const { request, data, loading, error } = useImpersonation();
  const { pushSnack } = useSnackpack();
  const onSubmit = async () => {
    if (username !== "") {
      await request({ username });
    }
  };
  const onCopy = () => {
    if (data?.loginUrl) {
      navigator.clipboard.writeText(data?.loginUrl);
      pushSnack("Login link copied to clipboard");
    }
  };
  return (
    <GridPage
      sx={{
        margin: isMobile ? "" : "64px auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Typography variant="title" id="tableTitle">
        Impersonate User
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "600px",
          gap: 2,
          marginTop: "24px",
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          placeholder={"username or email address"}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="off"
          disabled={loading}
          fullWidth
          onClick={(event) => event.stopPropagation()}
          onKeyDown={(event) => {
            // Allow shift for newlines.
            if (event.key === "Enter") {
              onSubmit();
            }
          }}
        />
        <Button onClick={onSubmit} disabled={loading || username === ""}>
          Submit
        </Button>
        {error && (
          <Alert severity="error" sx={{ padding: "12px 24px" }}>
            Error fetching login link.
          </Alert>
        )}
        {data && !loading && (
          <Box>
            <Alert
              icon={<KeyIcon height="22px" width="22px" />}
              severity="info"
              sx={{ padding: "12px 24px" }}
            >
              <AlertTitle>Secure Login Link</AlertTitle>
              <div
                style={{
                  cursor: "pointer",
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  border: "1px solid #D4D4D4",
                  borderRadius: "8px",
                  padding: "15px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  marginTop: "10px",
                  maxWidth: "400px",
                }}
                onClick={onCopy}
              >
                {data.loginUrl}
              </div>
              <Typography variant="caption" onClick={onCopy}>
                Click to copy to clipboard.
              </Typography>
            </Alert>
          </Box>
        )}
      </Box>
    </GridPage>
  );
};
