import { Box, Typography } from "@mui/material";
import { UserAvatar } from "components/common/CurrentUserAvatar";
import DateDisplay from "components/common/DateDisplay";
import DocumentViewer from "components/editor/DocumentViewer";
import useIsMobile from "components/hooks/useIsMobile";
import { FamilyAnnotation } from "protogen/annotations_pb";
import FlagsDisplay from "./FlagsDisplay";

export default ({ annotation }: { annotation: FamilyAnnotation }) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexGrow: 1,
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            height: "inherit",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <UserAvatar user={annotation.author!} size={40} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FAF9FA",
          flexGrow: 1,
          borderRadius: "16px",
          padding: "16px 24px",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>
              {annotation.author?.displayName}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#6B6E7B" }}>
              #{annotation.noteType}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "4px", flexDirection: "column" }}>
            <DateDisplay
              date={new Date(Number(annotation.lastUpdatedSec) * 1000)}
              sx={{ fontSize: "14px", color: "#6B6E7B" }}
            />
          </Box>
        </Box>
        <Box>
          <DocumentViewer
            key={annotation.ref}
            content={JSON.parse(annotation.content)}
            attachments={annotation.attachments}
            minHeight="20px"
          />
        </Box>
        <FlagsDisplay flags={annotation.flags} />
      </Box>
    </Box>
  );
};
