import { PhoneCall, PhoneCall_State } from "protogen/conversation_pb";
import Duration from "../common/Duration";
import SvgIcon from "@mui/material/SvgIcon";
import FeedEntryBox from "../common/FeedEntryBox";
import { getFormattedDuration } from "../../common/utils";
import LinkRouter from "../navigation/LinkRouter";
import { Phone } from "lucide-react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AccountStub } from "../../protogen/common_pb";
import { ReactElement } from "react";

const subtitleForCall = (call: PhoneCall): string => {
  switch (call.state) {
    case PhoneCall_State.COMPLETED:
      return "Call Completed";
    case PhoneCall_State.ACTIVE:
      return "Active call";
    case PhoneCall_State.MISSED:
      return "Missed call";
    case PhoneCall_State.FAILED:
    default:
      return "Call failed";
  }
};

type CallPreviewProps = {
  call: PhoneCall;
  participants: AccountStub[];
};

export default ({ call, participants }: CallPreviewProps) => {
  const subtitle = subtitleForCall(call);
  const duration = getFormattedDuration(
    Number(call.lastUpdatedSec),
    Number(call.creationSec),
  );

  const suggestions = () => {
    if (!call.suggestions) {
      return (
        <LinkRouter to={`/calls/${encodeURIComponent(call.ref)}`}>
          <Typography
            variant={"bodySmall"}
            sx={{ cursor: "pointer", color: "#198282" }}
          >
            See details
          </Typography>
        </LinkRouter>
      );
    }
    return (
      <Box display="flex" flexDirection="column">
        <LinkRouter to={`/calls/${encodeURIComponent(call.ref)}`}>
          <Typography
            variant={"bodySmall"}
            sx={{ cursor: "pointer", color: "#198282" }}
          >
            See suggestions
          </Typography>
        </LinkRouter>
      </Box>
    );
  };

  const creationDate = new Date(Number(call.creationSec) * 1000);
  const maybeLink = (content: ReactElement) => {
    return call.state !== PhoneCall_State.COMPLETED ? (
      <LinkRouter to={`/calls/${encodeURIComponent(call.ref)}`}>
        {content}
      </LinkRouter>
    ) : (
      content
    );
  };

  return (
    // Don't hijack the click event if the call is completed
    maybeLink(
      <FeedEntryBox
        header={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="bodySmallHeavy">
              {participants.map((p) => p?.firstName).join(", ")}
            </Typography>
            <Typography variant="bodySmall">
              {call.state === PhoneCall_State.ACTIVE ? (
                <Duration startTime={creationDate} />
              ) : (
                duration
              )}
            </Typography>
          </Box>
        }
        subheader={
          call.state === PhoneCall_State.COMPLETED ? (
            suggestions()
          ) : (
            <Typography variant="bodySmall" color="textSecondary">
              {subtitle}
            </Typography>
          )
        }
        icon={
          <SvgIcon sx={{ fontSize: 20, color: "#6B6E7B" }}>
            <Phone />
          </SvgIcon>
        }
      />,
    )
  );
};
