import { Box, Typography } from "@mui/material";
import ResourceSection from "components/forum/ResourceSection";
import {
  GeneralOperations,
  PlatformBasics,
  WorkingWithClients,
} from "components/forum/ResourcesPanel";
import useIsDesktop from "components/hooks/useIsDesktop";
import FlexPage from "components/layout/FlexPage";

export default () => {
  const columnStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "32px",
  };
  const isDesktop = useIsDesktop();

  return (
    <FlexPage sx={{ gap: "24px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography variant="display">Help</Typography>
        <Typography variant="body">
          Common resources to help & support for advisors, please feel free to
          contact us directly or drop a note to{" "}
          <a
            href={`/inbox/email/create?email=${encodeURIComponent(
              "support@findfaye.com",
            )}`}
            style={{
              textDecoration: "none",
              color: "#198282",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            support@findfaye.com
          </a>{" "}
          for additional needs.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
          gap: "32px",
          marginBottom: "32px",
        }}
      >
        {/* Left column */}
        <Box sx={columnStyle}>
          <ResourceSection
            title="Common issues"
            links={[
              {
                name: "Request billing change",
                href: "https://docs.google.com/forms/d/e/1FAIpQLSfgHC0RPgxIr_cUm7Z0Lflai2FNBs-QfaK0Yb2jgC-4uQE-gQ/viewform?usp=preview",
                targetNew: true,
              },
              {
                name: "Report bug",
                href: "https://forms.gle/Px85SswETmsT2JCK8",
                targetNew: true,
              },
              {
                name: "Share an idea or feature request",
                href: "https://forms.gle/6tyZNtAMXSTCbHsu8",
                targetNew: true,
              },
              // {
              //   name: "Request support with the Client Health Team",
              //   href: "#",
              // },
            ]}
          />
        </Box>

        {/* Left column */}
        <Box sx={columnStyle}>
          <GeneralOperations />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
          gap: "32px",
          marginBottom: "32px",
        }}
      >
        {/* Left column */}
        <Box sx={columnStyle}>
          <PlatformBasics />
        </Box>
        {/* Right column */}
        <Box sx={columnStyle}>
          <WorkingWithClients />
        </Box>
      </Box>
    </FlexPage>
  );
};
