import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Drawer, IconButton } from "@mui/material";
import { HomeIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import MemberSecrets from "components/member/MemberSecrets";
import Loading from "components/common/Loading";
import useIsMobile from "components/hooks/useIsMobile";
import { useGetMemberDetails } from "services/member";
import Secret from "components/secrets/Secret";
import Address from "components/family/Address";
import FlexPage from "components/layout/FlexPage";
import Breadcrumbs from "components/common/Breadcrumbs";
import { SecretStub } from "protogen/secrets_pb";
import { ReactComponent as CloseIcon } from "../icons/Close.svg";

enum PanelView {
  Secret = "Secret",
  Address = "Address",
}
const MemberInfoSection = ({
  address,
  handleClick,
}: {
  address: string;
  handleClick: (address: string) => void;
}) => {
  const hasAddress = !!address;
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "20px" }}
      >
        <Box display="flex" alignItems="center">
          <HomeIcon
            height="24px"
            width="24px"
            stroke="#6B6E7B"
            strokeWidth="2px"
          />
          <Typography
            variant="h3"
            sx={{ fontSize: "20px", fontWeight: 700, marginLeft: "8px" }}
          >
            Home address
          </Typography>
        </Box>
        {hasAddress && (
          <Typography
            onClick={() => {
              handleClick(address);
            }}
            variant="bodySmall"
            sx={{
              color: "#6B6E7B",
              cursor: "pointer",
            }}
          >
            edit
          </Typography>
        )}
      </Box>
      {hasAddress && (
        <Box
          sx={{
            border: "1px solid #D4D4D4",
            borderRadius: "8px",
            display: "flex",
            padding: "20px",
            flexDirection: "column",
            fontWeight: "500",
            gap: "20px",
          }}
        >
          <AddressView address={address || ""} />
        </Box>
      )}
      {!hasAddress && (
        <Button
          variant="outlined"
          onClick={() => {
            handleClick("");
          }}
        >
          Add address
        </Button>
      )}
    </Box>
  );
};

const SecretSection = ({
  secrets,
  handleClick,
}: {
  secrets: SecretStub[];
  handleClick: (secretRet: string) => void;
}) => {
  const sortedSecrets = secrets.sort((a, b) => {
    if (a.valueType < b.valueType) return -1;
    if (a.valueType > b.valueType) return 1;

    return Number(a.creationTimeSec - b.creationTimeSec);
  });
  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ marginBottom: "12px" }}>
        <LockClosedIcon
          height="24px"
          width="24px"
          stroke="#6B6E7B"
          strokeWidth="2px"
        />
        <Typography variant="h3" sx={{ marginLeft: "8px" }}>
          Secrets and documents
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "20px" }}>
        <Typography
          variant="bodySmall"
          sx={{
            color: "#6B6E7B",
          }}
        >
          This is a secure, encrypted space to store sensitive data and
          passwords.
        </Typography>
      </Box>
      <MemberSecrets secrets={sortedSecrets} handleSecretClick={handleClick} />
    </Box>
  );
};

const AddressView = ({ address }: { address: string }) => {
  try {
    const parsed = JSON.parse(address);
    return (
      <Box>
        <Box>{parsed.formattedAddress}</Box>
      </Box>
    );
  } catch (error) {
    return <></>;
  }
};

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { data, request } = useGetMemberDetails();
  const [showSecondPanel, setShowSecondPanel] = useState(false);
  const [secondPanelView, setSecondPanelView] = useState("");
  const [selectedSecret, setSelectedSecret] = useState("");
  useEffect(() => {
    request();
  }, []);

  const handleClickSecret = (secretRef: string) => {
    setSecondPanelView(PanelView.Secret);
    if (isMobile) {
      navigate(`/secret/${encodeURIComponent(secretRef)}`);
    } else {
      setSelectedSecret(secretRef);
      setShowSecondPanel(true);
    }
  };

  const handleClickAddress = (address: string) => {
    setSecondPanelView(PanelView.Address);
    if (isMobile) {
      navigate("/family-info/address", {
        state: { familyAddress: address, familyRef: data?.family?.ref },
      });
    } else {
      setShowSecondPanel(true);
    }
  };

  const closeSecondPanel = (doRefresh?: boolean) => {
    setSelectedSecret("");
    setShowSecondPanel(false);
    if (doRefresh) {
      request();
    }
  };

  const onSaveHandler = () => closeSecondPanel(true);
  const onCancelHandler = () => closeSecondPanel();

  // Only on initial load.
  if (!data) {
    return <Loading />;
  }

  const { secrets } = data;
  return (
    <FlexPage leftAligned>
      {!data ? (
        <Loading />
      ) : (
        <Box>
          <Breadcrumbs
            breadcrumbs={[{ name: "Home", link: "/" }, { name: "Family info" }]}
          />
          <Box sx={{ marginBottom: isMobile ? "24px" : "28px" }}>
            <Typography variant="display">{data.family?.name}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column-reverse" : "row",
              gap: isMobile ? "32px" : "64px",
              width: "100%",
            }}
          >
            <Box sx={{ width: isMobile ? "100%" : "50%" }}>
              <SecretSection
                secrets={secrets}
                handleClick={handleClickSecret}
              />
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "50%" }}>
              <MemberInfoSection
                address={data.family?.address || ""}
                handleClick={handleClickAddress}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box>
        <React.Fragment key={"right"}>
          <Drawer
            onClose={onCancelHandler}
            sx={{
              "& .MuiDrawer-paper": {
                boxShadow: "-2px 0px 4px 0px rgba(0, 0, 0, 0.15)",
              },
            }}
            anchor={"right"}
            open={showSecondPanel}
          >
            <Box
              sx={{ width: "426px", paddingTop: isMobile ? "24px" : "16px" }}
            >
              {secondPanelView === PanelView.Secret ? (
                <Box>
                  <Box
                    sx={{
                      marginTop: "32px",
                      marginLeft: "24px",
                      marginRight: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Breadcrumbs
                      breadcrumbs={[{ name: "New secret", link: "/" }]}
                    />
                    <IconButton
                      onClick={onCancelHandler}
                      sx={{
                        height: "36px",
                        width: "36px",
                        padding: 0,
                        border: "2px solid #EDEDED",
                        borderRadius: "100px",
                      }}
                    >
                      <CloseIcon height="12px" width="12px" stroke="#8E9598" />
                    </IconButton>
                  </Box>
                  <Secret
                    secretRef={selectedSecret}
                    onSaveHandler={onSaveHandler}
                    onCancelHandler={onCancelHandler}
                    family={data.family}
                  />
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      marginTop: "32px",
                      marginLeft: "24px",
                      marginRight: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                    }}
                  >
                    <Breadcrumbs breadcrumbs={[{ name: "Family info" }]} />
                    <IconButton
                      onClick={onCancelHandler}
                      sx={{
                        height: "36px",
                        width: "36px",
                        padding: 0,
                        border: "2px solid #EDEDED",
                        borderRadius: "100px",
                      }}
                    >
                      <CloseIcon height="12px" width="12px" stroke="#8E9598" />
                    </IconButton>
                  </Box>
                  <Address
                    familyRef={data.family?.ref || ""}
                    address={data.family?.address || ""}
                    onSaveHandler={onSaveHandler}
                    onCancelHandler={onCancelHandler}
                  />
                </Box>
              )}
            </Box>
          </Drawer>
        </React.Fragment>
      </Box>
    </FlexPage>
  );
};
