import { ReactNode, useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  ButtonGroup,
  IconButton,
  MenuItem,
  ListItem,
} from "@mui/material";
import { ChevronLeft, ChevronRight, Plus, Settings } from "lucide-react";
import { View } from "react-big-calendar";
import useIsMobile from "components/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import { StyledMenu } from "components/common/DropdownMenu";
import { StatusContext } from "../context/StatusContextProvider";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";
import Select from "../common/Select";

export interface BaseToolbarProps {
  onNavigate: (action: "PREV" | "NEXT" | "TODAY") => void;
  label: string;
  onView: (view: View) => void;
  view: View;
}
export interface CustomToolbarProps extends BaseToolbarProps {
  minimalHeader?: boolean;
  filtersDelegate?: ReactNode | null;
  handleNewEvent?: () => void;
  handleRefresh?: () => void;
}

export default ({
  onNavigate,
  label,
  onView,
  view,
  minimalHeader,
  filtersDelegate,
  handleNewEvent,
  handleRefresh,
}: CustomToolbarProps) => {
  const { datebookUnreadCount } = useContext(StatusContext);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const modeSelection = minimalHeader ? (
    <Select
      value={view}
      onChange={(e) => {
        const val = e.target.value as View;
        onView(val);
      }}
      sx={{
        ".MuiInputBase-input": {
          paddingRight: "5px !important",
          minWidth: "36px",
        },
      }}
    >
      <MenuItem value="week">
        <ListItem
          component="div"
          disablePadding
          sx={(theme) => ({ color: theme.palette.text.primary })}
        >
          Week
        </ListItem>
      </MenuItem>
      <MenuItem value="month">
        <ListItem
          component="div"
          disablePadding
          sx={(theme) => ({ color: theme.palette.text.primary })}
        >
          Month
        </ListItem>
      </MenuItem>
      <MenuItem value="agenda">
        <ListItem
          component="div"
          disablePadding
          sx={(theme) => ({ color: theme.palette.text.primary })}
        >
          List
        </ListItem>
      </MenuItem>
    </Select>
  ) : (
    <ButtonGroup fullWidth sx={{ height: "48px" }}>
      <Button
        onClick={() => onView("week")}
        variant={view === "week" ? "buttonGroupSelected" : "buttonGroup"}
      >
        Week
      </Button>
      <Button
        onClick={() => onView("month")}
        variant={view === "month" ? "buttonGroupSelected" : "buttonGroup"}
      >
        Month
      </Button>
      <Button
        onClick={() => onView("agenda")}
        variant={view === "agenda" ? "buttonGroupSelected" : "buttonGroup"}
      >
        List
        {datebookUnreadCount > 0 && (
          <Box>
            {/* Do not remove wrapping Box/div, it prevents the svg from shrinking when screensizes are small */}
            <EllipseIcon height={8} width={8} style={{ marginLeft: "5px" }} />
          </Box>
        )}
      </Button>
    </ButtonGroup>
  );

  const addEvent = handleNewEvent && (
    <IconButton
      onClick={handleNewEvent}
      sx={{
        borderRadius: "50px",
        border: "2px solid #d4d4d4",
        padding: "9px",
        borderWidth: "1px",
      }}
    >
      <Plus size={20} />
    </IconButton>
  );

  if (isMobile) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="12px"
        alignItems="center"
        padding={minimalHeader ? "0px 0px" : "8px 0px"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={minimalHeader ? "space-between" : "center"}
          width="100%"
          alignItems="center"
          gap="12px"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h2Serif">{label}</Typography>
            <IconButton onClick={handleClick}>
              <Settings color="#6B6E7B" strokeWidth="1.5" />
            </IconButton>
            <StyledMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuItem
                disabled={false}
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/settings/calendars");
                }}
                disableRipple
                sx={{
                  gap: "12px",
                }}
              >
                Settings
              </MenuItem>
              <MenuItem
                disabled={false}
                onClick={() => {
                  setAnchorEl(null);
                  handleClose();
                }}
                disableRipple
                sx={{
                  gap: "12px",
                }}
              >
                Refresh
              </MenuItem>
            </StyledMenu>
          </Box>
          {minimalHeader && modeSelection}
        </Box>
        {!minimalHeader && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={!minimalHeader ? "space-between" : "center"}
            width="100%"
            alignItems="center"
            gap="12px"
          >
            {modeSelection}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: isMobile ? "0" : "12px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              width: "100%",
            }}
          >
            <Button
              variant={isMobile ? "text" : "outlinedSecondary"}
              size="small"
              style={{ color: "#3D3D3D", borderWidth: "1px" }}
              onClick={() => onNavigate("TODAY")}
            >
              Today
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => onNavigate("PREV")}
              sx={{ minWidth: minimalHeader ? "32px" : "48px" }}
            >
              <ChevronLeft color="#6B6E7B" />
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => onNavigate("NEXT")}
              sx={minimalHeader ? { minWidth: "32px" } : undefined}
            >
              <ChevronRight color="#6B6E7B" />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {filtersDelegate}
            {addEvent}
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "12px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {addEvent}
          {/* Forward and back */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant={minimalHeader ? "text" : "outlinedSecondary"}
              size="small"
              style={{ color: "#3D3D3D", borderWidth: "1px" }}
              onClick={() => onNavigate("TODAY")}
            >
              Today
            </Button>

            <Button
              variant="text"
              onClick={() => onNavigate("PREV")}
              sx={minimalHeader ? { minWidth: "32px" } : undefined}
            >
              <ChevronLeft color="#6B6E7B" />
            </Button>
            <Button
              variant="text"
              onClick={() => onNavigate("NEXT")}
              sx={minimalHeader ? { minWidth: "32px" } : undefined}
            >
              <ChevronRight color="#6B6E7B" />
            </Button>
          </Box>
        </Box>

        {/* Date range */}
        <Typography variant="h2Serif">{label}</Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "12px",
          }}
        >
          {filtersDelegate}
          <IconButton onClick={handleClick}>
            <Settings color="#6B6E7B" strokeWidth="1.5" />
          </IconButton>
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem
              disabled={false}
              onClick={() => {
                setAnchorEl(null);
                navigate("/settings/calendars");
              }}
              disableRipple
              sx={{
                gap: "12px",
              }}
            >
              Settings
            </MenuItem>
            {handleRefresh && (
              <MenuItem
                disabled={false}
                onClick={() => {
                  handleRefresh();
                  setAnchorEl(null);
                  handleClose();
                }}
                disableRipple
                sx={{
                  gap: "12px",
                }}
              >
                Refresh
              </MenuItem>
            )}
          </StyledMenu>
          {modeSelection}
        </Box>
      </Box>
    </Box>
  );
};
