import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
} from "react";
import TextField from "@mui/material/TextField";
import set from "lodash/set";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FactFormHandle, FactFormProps } from "../types";
import DragNDrop from "../../common/DragNDrop";
import { useUploader } from "../../creation/FileUploader";
import { PlainMessage } from "@bufbuild/protobuf";
import { UploadAttachment } from "protogen/common_pb";
import AttachmentList from "../../editor/AttachmentList";
import UploadDocumentsButton from "../UploadDocumentsButton";
import { cleanPhone, formatUSPhoneNumber } from "../../../common/utils";
import PhoneInput from "../../creation/PhoneInput";
import FactSectionSelect from "../FactSectionSelect";
import EditableCopyTextField from "components/common/EditableCopyTextField";
import AddressAutocomplete, {
  Location,
} from "components/common/AddressAutocomplete";
import LinkRouter from "components/navigation/LinkRouter";

export const renderTextContact = (value: string): string => {
  const parsed: ContactForm = value === "" ? {} : JSON.parse(value);
  const parts = [];
  for (const key in parsed) {
    if (parsed[key]) {
      parts.push([`${key}: ${parsed[key]}`]);
    }
  }
  return parts.join(" / ");
};

const getFormattedLocation = (address?: string): string => {
  if (!address) return "";
  try {
    const parsed = JSON.parse(address);
    return parsed?.formattedAddress || "";
  } catch (e) {
    return address;
  }
};

export const previewTextContact = (value: string): string => {
  const parsed: ContactForm = value === "" ? {} : JSON.parse(value);
  const parts = [];
  if (parsed.phone) {
    parts.push(formatUSPhoneNumber(parsed.phone));
  }
  if (parsed.email) {
    parts.push(parsed.email);
  }
  if (parsed.address) {
    parts.push(getFormattedLocation(parsed.address));
  }
  if (parsed.website) {
    parts.push(parsed.website);
  }
  if (parsed.notes) {
    parts.push(parsed.notes);
  }
  return parts.join(", ");
};

type ContactForm = {
  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  address?: string;
  notes?: string;
  [key: string]: string | undefined;
};

export default forwardRef<FactFormHandle, FactFormProps>(
  ({ fact, sections, updateFormData, errors }: FactFormProps, ref) => {
    const [dragState, setDragState] = React.useState(false);
    const {
      onUpload,
      fileUploads,
      uploadPercentage,
      removeUpload,
      uploadsInProgress,
      withAttachments,
    } = useUploader({
      initialAttachments: fact.attachments || [],
    });
    useImperativeHandle(ref, () => ({
      isValid: () => {
        const notUploading = !uploadsInProgress || uploadPercentage === 100;
        const hasName = fact.name !== "";

        return notUploading && hasName;
      },
    }));
    const parsed: ContactForm = fact.value === "" ? {} : JSON.parse(fact.value);

    const formatPhoneForLink = (phone: string) => {
      const cleanedNumber = phone.replace(/\D/g, "");
      if (cleanedNumber.length === 10) {
        return `+1${cleanedNumber}`;
      } else if (cleanedNumber.length === 11 && cleanedNumber.startsWith("1")) {
        return `+${cleanedNumber}`;
      }
      return null;
    };

    // Use the useCallback here because draft can change while we are uploading so we need to make sure it isn't stale.
    // Upon further reflection, I'm not sure I need useCallback nor if the state I'm listening to is relevant. This
    // is a duct tape solution that seems to be fine.
    const setAttachments = useCallback(
      (attachments: PlainMessage<UploadAttachment>[]) => {
        // Note - there could be a race condition here if the user is uploading a file AND updating text fields.
        updateFormData({ ...fact, uploads: attachments });
      },
      [fact, fileUploads],
    );

    useEffect(() => {
      withAttachments(fact.attachments || []);
    }, [fact.attachments]);

    const createChangeHandler = (
      path: string[],
      event: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      updateFormData((fd) => {
        const updatedParsed: ContactForm =
          fd.value === "" ? {} : JSON.parse(fd.value);
        const value = typeof event === "string" ? event : event.target.value;
        set(updatedParsed, path, value);
        return {
          ...fd,
          value: JSON.stringify(updatedParsed),
        };
      });
    };

    return (
      <DragNDrop
        setHover={setDragState}
        onUpload={(files) => {
          onUpload(files, undefined, setAttachments);
        }}
      >
        <Box
          sx={{
            ...(dragState ? { backgroundColor: "#e8f4f8" } : {}),
          }}
        >
          <FactSectionSelect
            sections={sections}
            selectedSectionRef={fact.sectionRef}
            onChange={(sectionRef) => {
              updateFormData({ ...fact, sectionRef: sectionRef });
            }}
          />
          <EditableCopyTextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={fact.name}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => updateFormData({ ...fact, name: e.target.value })}
          />
          <PhoneInput
            value={parsed.phone || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            handleCopy={true}
            onChange={(phone) => {
              createChangeHandler(
                ["phone"],
                cleanPhone(
                  typeof phone == "string" ? phone : phone.target.value,
                ),
              );
            }}
            label="Phone"
            fullWidth
            size="small"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            autoComplete="off"
          />
          {parsed.phone && formatPhoneForLink(parsed.phone as string) && (
            <Typography variant="caption" sx={{ display: "block" }}>
              <LinkRouter
                to={`/inbox/phone/create?phone=${encodeURIComponent(
                  formatPhoneForLink(parsed.phone as string) || "",
                )}`}
                sx={{ color: "#198282", fontWeight: "700", marginLeft: "16px" }}
              >
                Call{parsed.name ? ` ${fact.name}` : ""}
              </LinkRouter>
            </Typography>
          )}
          <EditableCopyTextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            autoComplete="off"
            value={parsed.email || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["email"], e)}
          />
          {parsed.email && (
            <Typography variant="caption" sx={{ display: "block" }}>
              <LinkRouter
                to={`/inbox/email/create?email=${encodeURIComponent(
                  parsed.email,
                )}`}
                sx={{ color: "#198282", fontWeight: "700", marginLeft: "16px" }}
              >
                Email{parsed.name ? ` ${fact.name}` : ""}
              </LinkRouter>
            </Typography>
          )}
          <EditableCopyTextField
            margin="dense"
            label="Website"
            type="url"
            autoComplete="off"
            fullWidth
            variant="outlined"
            value={parsed.website || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["website"], e)}
          />
          {parsed.website && (
            <Typography
              variant="caption"
              sx={{ display: "block", marginBottom: "4px" }}
            >
              <a
                href={
                  parsed.website.startsWith("http")
                    ? parsed.website
                    : `https://${parsed.website}`
                }
                style={{
                  color: "#198282",
                  fontWeight: "700",
                  marginLeft: "16px",
                  textDecoration: "none",
                }}
                target="_blank"
              >
                Visit website
              </a>
            </Typography>
          )}
          <AddressAutocomplete
            label="Address"
            name="Address"
            autoComplete="off"
            initialValue={getFormattedLocation(parsed.address)}
            error={errors?.value}
            setValue={(location: Location) => {
              createChangeHandler(["address"], JSON.stringify(location));
            }}
          />
          {getFormattedLocation(parsed.address) && (
            <Typography
              variant="caption"
              sx={{ display: "block", marginTop: "4px" }}
            >
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  getFormattedLocation(parsed.address),
                )}`}
                style={{
                  color: "#198282",
                  fontWeight: "700",
                  marginLeft: "16px",
                  textDecoration: "none",
                }}
                target="_blank"
              >
                View on maps
              </a>
            </Typography>
          )}
          <TextField
            rows={3}
            multiline
            margin="dense"
            label="Notes"
            type="text"
            fullWidth
            variant="outlined"
            value={parsed.notes || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["notes"], e)}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            sx={{ marginTop: "24px" }}
          >
            <Typography variant="bodyHeavy">Attachments</Typography>
            <AttachmentList
              attachments={fileUploads}
              onDelete={(filename) => removeUpload(filename, setAttachments)}
            />
            <UploadDocumentsButton
              uploadsInProgress={uploadsInProgress}
              uploadPercentage={uploadPercentage}
              onChange={(files) => onUpload(files, undefined, setAttachments)}
            />
          </Box>
        </Box>
      </DragNDrop>
    );
  },
);
