import * as React from "react";
import ListItem from "@mui/material/ListItem";
import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LinkRouter from "../navigation/LinkRouter";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";

export type NavItemProps = {
  selected: boolean;
  open: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
  url?: string;
  secondaryIcon?: React.ReactNode;
  actionIndicator?: boolean;
  children?: React.ReactNode;
};

export default ({
  selected,
  onClick,
  icon,
  text,
  url,
  secondaryIcon,
  actionIndicator,
  children,
}: NavItemProps) => {
  return (
    <>
      <ListItem disablePadding sx={{ display: "block" }}>
        <Box
          sx={{
            padding: "0 12px",
          }}
        >
          <LinkRouter to={url} onClick={() => onClick()}>
            <ListItemButton
              selected={selected}
              sx={{
                padding: "4px 8px 4px 12px",
                gap: "12px",
                marginBottom: "2px",
                borderRadius: "8px",
                "&.Mui-selected": {
                  background: "#E2EEEC",
                },
                "&:hover": {
                  background: "inherit",
                },
              }}
            >
              {icon}
              <ListItemText
                primary={
                  <>
                    {text}
                    {actionIndicator && (
                      <EllipseIcon
                        height={8}
                        width={8}
                        style={{ marginLeft: "6px" }}
                      />
                    )}
                  </>
                }
                sx={{
                  "& span": {
                    color: "var(--Text-Primary, #3D3D3D)",
                    fontWeight: selected ? "700" : "500",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  },
                }}
              />
              {secondaryIcon}
            </ListItemButton>
          </LinkRouter>
        </Box>
      </ListItem>
      {children}
    </>
  );
};
