import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
} from "react";
import set from "lodash/set";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FactFormHandle, FactFormProps } from "../types";
import DragNDrop from "../../common/DragNDrop";
import { useUploader } from "../../creation/FileUploader";
import { PlainMessage } from "@bufbuild/protobuf";
import { UploadAttachment } from "protogen/common_pb";
import AttachmentList from "../../editor/AttachmentList";
import UploadDocumentsButton from "../UploadDocumentsButton";
import { cleanPhone } from "../../../common/utils";
import PhoneInput from "../../creation/PhoneInput";
import FactSectionSelect from "../FactSectionSelect";

export const renderTextOccupation = (value: string): string => {
  const parsed: OccupationForm = value === "" ? {} : JSON.parse(value);
  const parts = [];
  for (const key in parsed) {
    if (parsed[key]) {
      parts.push([`${key}: ${parsed[key]}`]);
    }
  }
  return parts.join(" / ");
};

type OccupationForm = {
  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  address?: string;
  notes?: string;
  [key: string]: string | undefined;
};

export default forwardRef<FactFormHandle, FactFormProps>(
  ({ fact, sections, updateFormData, errors }: FactFormProps, ref) => {
    const [dragState, setDragState] = React.useState(false);
    const {
      onUpload,
      fileUploads,
      uploadPercentage,
      removeUpload,
      uploadsInProgress,
      withAttachments,
    } = useUploader({
      initialAttachments: fact.attachments || [],
    });
    useImperativeHandle(ref, () => ({
      isValid: () => {
        const notUploading = !uploadsInProgress || uploadPercentage === 100;
        const hasName = fact.name !== "";

        return notUploading && hasName;
      },
    }));
    const parsed: OccupationForm =
      fact.value === "" ? {} : JSON.parse(fact.value);

    // Use the useCallback here because draft can change while we are uploading so we need to make sure it isn't stale.
    // Upon further reflection, I'm not sure I need useCallback nor if the state I'm listening to is relevant. This
    // is a duct tape solution that seems to be fine.
    const setAttachments = useCallback(
      (attachments: PlainMessage<UploadAttachment>[]) => {
        // Note - there could be a race condition here if the user is uploading a file AND updating text fields.
        updateFormData({ ...fact, uploads: attachments });
      },
      [fact, fileUploads],
    );

    useEffect(() => {
      withAttachments(fact.attachments || []);
    }, [fact.attachments]);

    const createChangeHandler = (
      path: string[],
      event: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const updatedParsed = { ...parsed };
      const value = typeof event === "string" ? event : event.target.value;

      set(updatedParsed, path, value);

      const form = {
        ...fact,
        value: JSON.stringify(updatedParsed),
      };
      updateFormData(form);
    };

    return (
      <DragNDrop
        setHover={setDragState}
        onUpload={(files) => {
          onUpload(files, undefined, setAttachments);
        }}
      >
        <Box
          sx={{
            ...(dragState ? { backgroundColor: "#e8f4f8" } : {}),
          }}
        >
          <FactSectionSelect
            sections={sections}
            selectedSectionRef={fact.sectionRef}
            onChange={(sectionRef) => {
              updateFormData({ ...fact, sectionRef: sectionRef });
            }}
          />
          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={fact.name}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => updateFormData({ ...fact, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Position or title"
            type="text"
            fullWidth
            variant="outlined"
            autoComplete="off"
            value={parsed.position || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["position"], e)}
          />
          <PhoneInput
            value={parsed.phone || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(phone) => {
              createChangeHandler(
                ["phone"],
                cleanPhone(
                  typeof phone == "string" ? phone : phone.target.value,
                ),
              );
            }}
            label="Phone"
            fullWidth
            size="small"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            autoComplete="off"
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            autoComplete="off"
            value={parsed.email || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["email"], e)}
          />
          <TextField
            margin="dense"
            label="Address"
            type="text"
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={parsed.address || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["address"], e)}
          />
          <TextField
            rows={3}
            multiline
            margin="dense"
            label="Notes"
            type="text"
            fullWidth
            variant="outlined"
            value={parsed.notes || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["notes"], e)}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            sx={{ marginTop: "24px" }}
          >
            <Typography variant="bodyHeavy">Attachments</Typography>
            <AttachmentList
              attachments={fileUploads}
              onDelete={(filename) => removeUpload(filename, setAttachments)}
            />
            <UploadDocumentsButton
              uploadsInProgress={uploadsInProgress}
              uploadPercentage={uploadPercentage}
              onChange={(files) => onUpload(files, undefined, setAttachments)}
            />
          </Box>
        </Box>
      </DragNDrop>
    );
  },
);
