import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  Collapse,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import useIsMobile from "components/hooks/useIsMobile";
import Breadcrumbs from "components/common/Breadcrumbs";
import { ExternalLink, MoveDown, MoveUp, Download } from "lucide-react";
import LinkRouter from "components/navigation/LinkRouter";
import Loading from "components/common/Loading";
import { useListInvoices, useUpdateInvoice } from "services/billing";
import {
  ListInvoicesRequest,
  Invoice,
  UpdateInvoiceRequest,
} from "protogen/billing_service_pb";
import { feeSchedule } from "components/family/utils";
import InvoiceFilters, {
  InvoiceFilter,
} from "components/payments/InvoiceFilters";
import InvoiceStatusSelect from "components/payments/InvoiceStatusSelect";
import WithDividers from "components/helpers/WithDividers";
import { PartialMessage } from "@bufbuild/protobuf";
import AdvisorSelect from "../../components/creation/AdvisorSelect";
import SearchBar from "../../components/forum/SearchBar";
import { useSearchParams } from "react-router-dom";
import { formatDateRange, formatPrice, shortDate } from "common/utils";

type SortFields = { field: string; asc: boolean }[];

const sortInvoicesByFields = (invoices: Invoice[], sortFields: SortFields) => {
  return invoices.sort((a, b) => {
    for (const sort of sortFields) {
      let valueA: string | number | bigint | boolean | undefined;
      let valueB: string | number | bigint | boolean | undefined;

      // Determine which field to sort by based on "name"
      if (sort.field === "status") {
        valueA = a.status;
        valueB = b.status;
      } else if (sort.field === "advisor-family") {
        valueA = `${a.advisor?.displayName.toLowerCase()}|${a.family?.name.toLowerCase()}`;
        valueB = `${b.advisor?.displayName.toLowerCase()}|${b.family?.name.toLowerCase()}`;
      } else if (sort.field === "familyName") {
        valueA = a.family?.name.toLowerCase();
        valueB = b.family?.name.toLowerCase();
      } else if (sort.field === "advisorName") {
        valueA = a.advisor?.displayName.toLowerCase();
        valueB = b.advisor?.displayName.toLowerCase();
      } else if (sort.field === "periodStartSec") {
        // Reversed since we want the most recent activity first.
        valueA = b.periodStartSec;
        valueB = a.periodStartSec;
      } else if (sort.field === "fee") {
        valueA = b.feePercent;
        valueB = a.feePercent;
      } else if (sort.field === "payment") {
        valueA = b.paymentCents;
        valueB = a.paymentCents;
      }

      if (valueA !== valueB) {
        if (valueA === undefined) return sort.asc ? -1 : 1;
        if (valueB === undefined) return sort.asc ? 1 : -1;

        if (valueA < valueB) return sort.asc ? -1 : 1;
        if (valueA > valueB) return sort.asc ? 1 : -1;
      }
    }
    return 0; // If all fields are equal
  });
};

const HeaderColumn = ({
  title,
  sortKey,
  sorts,
  setSorts,
  flex = "1",
  alignCenter = true,
}: {
  title: string;
  sortKey: string;
  sorts: SortFields;
  setSorts: (s: SortFields) => void;
  flex?: string;
  alignCenter?: boolean;
}) => {
  const arrowSx = {
    height: "16px",
    width: "16px",
    paddingTop: "3px",
    marginRight: "3px",
  };
  const currentSortVal = sorts.find((s) => s.field === sortKey);
  const upArrow = currentSortVal && !currentSortVal.asc;

  const onClick = () => {
    const newSorts = sorts.filter((s) => s.field !== sortKey);
    // First sort should always be ascending if not set yet.
    if (upArrow || !currentSortVal) {
      newSorts.unshift({ field: sortKey, asc: true });
    } else {
      newSorts.unshift({ field: sortKey, asc: false });
    }
    setSorts(newSorts);
  };

  return (
    <Box
      flex={flex}
      display="flex"
      flexDirection="column"
      alignItems={alignCenter ? "center" : undefined}
    >
      <Typography
        variant="bodySmallHeavy"
        color="#262626"
        onClick={onClick}
        sx={{
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        {upArrow ? <MoveUp style={arrowSx} /> : <MoveDown style={arrowSx} />}
        {title}
      </Typography>
    </Box>
  );
};

const Header = ({
  sorts,
  setSorts,
}: {
  sorts: SortFields;
  setSorts: (s: SortFields) => void;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <HeaderColumn
        setSorts={setSorts}
        sorts={sorts}
        title="Invoice"
        flex="2"
        sortKey={"advisor-family"}
        alignCenter={false}
      />
      <HeaderColumn
        setSorts={setSorts}
        sorts={sorts}
        title="Service Month"
        sortKey={"periodStartSec"}
      />
      <HeaderColumn
        setSorts={setSorts}
        sorts={sorts}
        title="Fee %"
        sortKey={"fee"}
      />
      <HeaderColumn
        setSorts={setSorts}
        sorts={sorts}
        title="Payment"
        sortKey={"payment"}
      />
      <HeaderColumn
        setSorts={setSorts}
        sorts={sorts}
        title="Action"
        sortKey={"status"}
      />
    </Box>
  );
};

// -- Edit Invoice Modal
const EditInvoice = ({
  invoice,
  onClose,
  update,
  loading,
}: {
  invoice: Invoice;
  onClose: () => void;
  update: (data: PartialMessage<UpdateInvoiceRequest>) => void;
  loading: boolean;
}) => {
  const [paymentDollars, setPaymentDollars] = useState(
    invoice.paymentCents / 100 ?? 0,
  );
  const [changeNotes, setChangeNotes] = useState("");
  const [advisorRef, setAdvisorRef] = useState<string | null>(
    invoice.advisor?.ref,
  );

  const onSave = () => {
    // Convert back to original units when saving
    update({
      paymentCents:
        Math.round(paymentDollars * 100) !== invoice.paymentCents
          ? Math.round(paymentDollars * 100)
          : undefined,
      changeNotes,
      advisorRef:
        advisorRef && advisorRef !== invoice.advisor?.ref
          ? advisorRef
          : undefined,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="16px" padding="16px">
      <AdvisorSelect
        isAdmin={true}
        selectedAdvisor={advisorRef}
        title={"Payment recipient"}
        onChange={(ref) => setAdvisorRef(ref)}
      />
      <TextField
        label="Payment"
        type="number"
        value={paymentDollars}
        onChange={(e) => setPaymentDollars(parseFloat(e.target.value))}
        inputProps={{ min: 0, step: 0.01 }}
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        label="Change Notes"
        multiline
        rows={4}
        value={changeNotes}
        onChange={(e) => setChangeNotes(e.target.value)}
        fullWidth
      />
      <Box justifyContent="end" display="flex" flexDirection="row" gap="8px">
        <Button onClick={onClose} variant="text" disabled={loading}>
          Close
        </Button>
        <Button onClick={onSave} variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </Box>
    </Box>
  );
};

// -- Invoice Change History
const InvoiceHistory = ({
  invoice,
  onClose,
}: {
  invoice: Invoice;
  onClose: () => void;
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box display="flex" flexDirection="column" gap="8px">
        <Box display="flex" flexDirection="row">
          <Box flex={"1"}>
            <Typography variant={"bodyHeavy"}>Date</Typography>
          </Box>
          <Box flex={"1"}>
            <Typography variant={"bodyHeavy"}>Change Notes</Typography>
          </Box>
          <Box flex={"1"}>
            <Typography variant={"bodyHeavy"}>Changes</Typography>
          </Box>
        </Box>
        <WithDividers>
          {invoice.changeEvents.map((changeEvent, i) => {
            const eventData = JSON.parse(changeEvent.eventJson);
            const regex = /^\[(.+)\s*,\s*(.+)\]$/;

            const changedKeys: string[] = Object.keys(eventData).filter(
              (key) =>
                typeof eventData[key] === "string" &&
                eventData[key].match(regex),
            );
            return (
              <Box key={i} display="flex" flexDirection="row">
                <Box flex={"1"}>
                  <Typography sx={{ color: "text.secondary" }}>
                    {eventData["timestamp"]}
                  </Typography>
                </Box>
                <Box flex={"1"}>
                  <Typography sx={{ color: "text.secondary" }}>
                    {eventData["change_notes"] || "N/A"}
                  </Typography>
                </Box>
                <Box flex={"1"}>
                  {changedKeys.map((k) => (
                    <Box key={k}>
                      <Typography variant="bodyHeavy" sx={{ fontWeight: 500 }}>
                        {k}
                      </Typography>
                      <Typography variant="body">
                        From: "{eventData[k].match(regex)![1]}", to: "
                        {eventData[k].match(regex)![2]}"
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          })}
        </WithDividers>
      </Box>
      <Box justifyContent="end" display="flex" flexDirection="row">
        <Button onClick={onClose} variant="text">
          Close
        </Button>
      </Box>
    </Box>
  );
};

// -- Single Invoice Card
const InvoiceCard = ({
  invoice,
  refresh,
  setAdvisorRef,
  setFamilyRef,
}: {
  invoice: Invoice;
  refresh: () => void;
  setAdvisorRef: (r: string) => void;
  setFamilyRef: (r: string) => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [viewMode, setViewMode] = useState<"edit" | "history" | null>(null);
  const isMobile = useIsMobile();
  const { request, loading } = useUpdateInvoice();

  const update = async (data: PartialMessage<UpdateInvoiceRequest>) => {
    if (loading) return;
    await request(
      new UpdateInvoiceRequest({
        invoiceRef: invoice.ref,
        ...data,
      }),
    );
    refresh();
  };

  const byline = (
    <LinkRouter
      targetNew={true}
      to={`https://dashboard.stripe.com/invoices/${invoice.stripeId}`}
    >
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Typography variant="body" sx={{ fontWeight: 500, marginRight: "4px" }}>
          {formatPrice(invoice.invoiceAmountCents, invoice.invoiceCurrency)}
        </Typography>
        <Typography variant="body">
          {invoice.periodStartSec
            ? `(${formatDateRange(
                invoice.periodStartSec,
                invoice.periodEndSec,
              )})`
            : ""}
        </Typography>
        <ExternalLink size={16} style={{ marginLeft: "8px" }} />
      </Box>
    </LinkRouter>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        borderRadius: "8px",
        border: "1px solid #ECECEC",
        background: "#FFF",
        width: "100%",
        padding: "16px 24px",
      }}
    >
      {!invoice.isAdvisorPayable && (
        <Alert severity="error" sx={{ backgroundColor: "#FDEDED" }}>
          {invoice.advisor?.ref
            ? "Advisor is not linked to a bank account for payments."
            : "Invoice not associated with an advisor."}
        </Alert>
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="12px"
      >
        <Box flex="2">
          <Box display="flex" flexDirection={"column"} gap="4px">
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              gap="4px"
              alignItems={"center"}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="4px"
              >
                <Typography
                  variant="bodyHeavy"
                  color="#262626"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setAdvisorRef(invoice.advisor?.ref)}
                >
                  {invoice.advisor?.firstName} {invoice.advisor?.lastName}
                </Typography>
                <LinkRouter to={`/advisor/${invoice.advisor?.ref}`}>
                  <ExternalLink size={12} />
                </LinkRouter>
              </Box>
              {!isMobile && <> &middot; </>}
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="4px"
              >
                <Typography
                  sx={{ cursor: "pointer" }}
                  color="text.secondary"
                  onClick={() => setFamilyRef(invoice.family?.ref)}
                >
                  {invoice.family?.name}
                </Typography>
                <LinkRouter to={`/families/${invoice.family?.ref}#tab-5`}>
                  <ExternalLink size={12} />
                </LinkRouter>
              </Box>
            </Box>
            <Typography
              variant="body"
              color="text.secondary"
              sx={{ textWrap: "none" }}
            >
              {byline}
            </Typography>
          </Box>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body" color="text.secondary">
            {invoice.serviceMonth}
          </Typography>
          <Typography variant="bodySmall" color="text.tertiary">
            {invoice.family?.startDate &&
              `Started ${shortDate(invoice.family?.startDate)}`}
          </Typography>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body" color="text.secondary">
            {invoice.feePercent * 100}%
          </Typography>
          <Typography variant="bodySmall" color="text.tertiary">
            {feeSchedule(invoice.family?.platformFeeSchedule)}
          </Typography>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="body"
            color="#262626"
            sx={{
              textAlign: "right",
              textDecoration: "underline",
              fontWeight: 500,
            }}
          >
            {formatPrice(invoice.paymentCents, "USD")}
          </Typography>
          <Typography variant="bodySmall" color="text.tertiary">
            ({formatPrice(invoice.subtotalCents, invoice.invoiceCurrency)} -{" "}
            {invoice.feePercent * 100}%)
          </Typography>
          {invoice.invoiceAmountCents !== invoice.subtotalCents && (
            <Typography variant="bodySmall" color="text.tertiary">
              Paid amount{" "}
              {formatPrice(invoice.invoiceAmountCents, invoice.invoiceCurrency)}
            </Typography>
          )}
        </Box>
        <Box flex="1" display="flex" flexDirection="column" alignItems="center">
          <InvoiceStatusSelect
            paymentDisabled={
              invoice.status === "pending_approval" && !invoice.isAdvisorPayable
            }
            status={invoice.status}
            setStatus={(s) => update({ status: s })}
          />
          <Box style={{ marginTop: "6px" }}>
            <span
              onClick={() => {
                setExpanded(true);
                setViewMode("edit");
              }}
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "14px",
                margin: "0 6px",
              }}
            >
              overrides
            </span>
            &middot;
            <span
              onClick={() => {
                setExpanded(true);
                setViewMode("history");
              }}
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "14px",
                margin: "0 6px",
              }}
            >
              history
            </span>
          </Box>
        </Box>
      </Box>
      <Collapse in={expanded}>
        {viewMode === "edit" && (
          <EditInvoice
            invoice={invoice}
            onClose={() => setExpanded(false)}
            update={update}
            loading={loading}
          />
        )}
        {viewMode === "history" && (
          <InvoiceHistory
            invoice={invoice}
            onClose={() => setExpanded(false)}
          />
        )}
      </Collapse>
    </Box>
  );
};

const generateCsv = (invoices: Invoice[]) => {
  if (!invoices || !invoices.length) {
    return "No data";
  }
  // Example CSV headers:
  const headers = [
    "InvoiceRef",
    "AdvisorName",
    "FamilyName",
    "FeePercent",
    "PaymentCents",
    "Status",
    "StripeId",
    "PeriodStart",
    "PeriodEnd",
    "InvoiceAmountCents",
    "InvoiceCurrency",
    "ServiceMonth",
  ];
  // Convert each invoice to a CSV row
  const rows = invoices.map((i) => [
    i.ref,
    (i.advisor?.displayName || "").replace(/,/g, ""), // strip commas
    (i.family?.name || "").replace(/,/g, ""),
    (i.feePercent * 100).toString(),
    i.paymentCents.toString(),
    i.status,
    i.stripeId,
    i.periodStartSec?.toString() || "",
    i.periodEndSec?.toString() || "",
    i.invoiceAmountCents.toString(),
    i.invoiceCurrency,
    i.serviceMonth,
  ]);

  // Join together
  const csvContent = [
    headers.join(","), // header line
    ...rows.map((fields) => fields.join(",")),
  ].join("\n");
  return csvContent;
};

// ADDED: function to download a string as a file
const downloadFile = (content: string, filename: string) => {
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleDownloadCsv = async (invoices: Invoice[]) => {
  // Generate CSV from the "all" invoices data
  const csvContent = generateCsv(invoices);
  // Copy to clipboard
  try {
    await navigator.clipboard.writeText(csvContent);
  } catch (e) {
    console.error("Clipboard copy failed: ", e);
  }
  // Then download as well
  downloadFile(csvContent, "invoices.csv");
};

type Props = {};
export default ({}: Props) => {
  const hasLoadedOnce = useRef(false);
  const isMobile = useIsMobile();
  // Manage Snackbar for "CSV Copied" message
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { request, loading, data } = useListInvoices();
  const [sorts, setSorts] = useState<SortFields>([]);
  const [, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(
    new URLSearchParams(window.location.search).get("query") || "",
  );
  const [filter, setFilter] = useState<InvoiceFilter>({
    statuses: [""],
    createdSinceSeconds: null,
    advisorRef: "",
    familyRef: "",
  });
  const fetchInvoices = async () => {
    await request(
      new ListInvoicesRequest({
        ...(filter.statuses && {
          statuses: filter.statuses.filter((s) => !!s),
        }),
        ...(filter.createdSinceSeconds && {
          createdSecStart: BigInt(filter.createdSinceSeconds),
        }),
        ...(filter.advisorRef && { advisorRef: filter.advisorRef }),
        ...(filter.familyRef && { familyRef: filter.familyRef }),
      }),
    );
    hasLoadedOnce.current = true;
  };
  useEffect(() => {
    fetchInvoices();
  }, [filter]);
  useEffect(() => {
    // Update URL whenever query changes
    setSearchParams(query ? { query } : {});
  }, [query, setSearchParams]);

  const refresh = () => {
    fetchInvoices();
  };
  const sortedInvoices = sortInvoicesByFields(data?.invoices || [], sorts);
  let resultingInvoices = sortedInvoices;
  if (query) {
    const cleanQuery = query.toLowerCase();
    resultingInvoices = sortedInvoices.filter(
      (a) =>
        `${a.advisor?.firstName} ${a.advisor?.lastName}`
          .toLowerCase()
          .includes(cleanQuery) ||
        (a.family?.name || "").toLowerCase().includes(cleanQuery),
    );
  }
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "auto",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "64px",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              { name: "Home", link: "/" },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="12px"
              alignContent="center"
            >
              <Typography variant="display">Advisor Payments</Typography>
              <IconButton
                onClick={async () => {
                  await handleDownloadCsv(data?.invoices || []);
                  setSnackbarOpen(true); // Show the Snackbar here
                }}
                disabled={!data?.invoices}
              >
                <Download size={16} />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              alignItems={"center"}
            >
              <SearchBar
                initialQuery={query}
                fullWidth={isMobile}
                autofocus={true}
                onQuery={setQuery}
                onKeyPress={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <InvoiceFilters setFilter={setFilter} filter={filter} loading={loading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        {!isMobile && <Header sorts={sorts} setSorts={setSorts} />}
        {loading && !hasLoadedOnce.current && <Loading />}
        {resultingInvoices.map((invoice) => (
          <InvoiceCard
            key={invoice.ref}
            invoice={invoice}
            refresh={refresh}
            setAdvisorRef={(r) => setFilter({ ...filter, advisorRef: r })}
            setFamilyRef={(r) => setFilter({ ...filter, familyRef: r })}
          />
        ))}
        {hasLoadedOnce.current && data?.invoices.length === 0 && (
          <Typography variant="body" color="text.tertiary">
            No invoices found
          </Typography>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        message="CSV copied to clipboard!"
      />
    </Box>
  );
};
